<template>
  <div class="value-wrapper">
    <div class="value-title">
      {{ title }}
    </div>
    <div class="value-data">
      <span class="value-value">{{ value }}</span>
      <span class="value-unit"> {{ unit }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Nom du champ',
    },
    value: {
      type: null,
      default: 'valeur',
    },
    unit: {
      type: String,
      default: '',
    },
  },

};
</script>
<style lang="scss" scoped>
    .value-wrapper {
     display: grid;
      grid-template-rows: 2rem 2rem;
      width:150px;

      .value-icon {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
        color: $app-blue;
      }

      .value-title {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;

        font-weight: 300;
        font-size: 0.9rem;

        justify-self: center;
        align-self: end;
      }
      .value-data {
        grid-column-start: 1;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;

        justify-self: center;
        align-self: start;

        .value-value {
          font-weight: 500;
          font-size: 1.2rem;
        }

        .value-unit {
          font-weight: 400;
          font-size: 0.7rem;
        }
      }

    }
</style>
