<template>
  <BaseCard :loading="loading">
    <template v-slot:title>
      Informations générales du site
    </template>
    <template v-slot:content>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle class="flex">
            <div class="flex-item">
              <div class="icon-info">
                <v-icon>mdi-information</v-icon>
              </div>
              <div>
                <div>Code du site: {{featuredItem.codeSite}}</div>
                <div v-if="zoneClimatique">Zone climatique détaillée:
                  {{zoneClimatique.data.zoneClimatiqueDetaillee}}
                </div>
                <div>Assujetti au décret BACS :
                  {{ getBacs() }}
                </div>
              </div>
            </div>
            <div class="flex-item">
              <div class="icon-adress">
                <v-icon>mdi-map-marker</v-icon>
              </div>
              <div>
                <div>{{featuredItem.adresse}}</div>
                <div>{{featuredItem.codePostal}} {{featuredItem.ville}}</div>
              </div>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </BaseCard>

</template>

<script>
// import Axios from 'axios';
import BaseCard from '../BaseCard.vue';


export default {
  components: { BaseCard },

  props: ['featuredItem', 'loading'],

  data() {
    return {
      zoneClimatique: '',
    };
  },

  async created() {
    if (this.featuredItem.zoneClimatique) {
      try {
        this.zoneClimatique = await this.$store.dispatch('listeOptions/fetchByCodeOption', {
          missionId: this.featuredItem.mission,
          codeOption: this.featuredItem.zoneClimatique,
        });
      } catch (e) {
        this.zoneClimatique = '';
      }
    }
  },

  methods: {
    getBacs() {
      let bacs = '';
      if (this.featuredItem.hasBacsPrefere) {
        bacs = this.getValueBacs(this.featuredItem.valeurBacsPrefere);
      } else {
        bacs = this.getValueBacs(this.featuredItem.concernedByBacs);
      }
      return bacs;
    },
    getValueBacs(item) {
      let value = 'Non';
      if (item) {
        value = 'Oui';
      }
      return value;
    },
  },

};
</script>

<style lang="scss" scoped>

  .flex{
    display: flex;
    flex-flow: row wrap;
    padding: 0.5rem;
    justify-content: space-around;
    font-size: 18px;
  }

  .flex-item{
    display: flex;
    flex-flow: row nowrap;
    padding: 1rem;
    justify-content: space-around;
  }

  .icon-info{
    margin-right: 0.8rem;
    vertical-align: bottom;
  }

  .icon-adress{
    margin-right: 0.5rem;
  }

</style>
