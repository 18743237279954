<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <div class="dialog-wrapper">
      <div class="dialog-title">
        Importer des inspections de type {{inspectionType}}
      </div>

      <div class="dialog-content">
        <div class="upper-fix-block">
          <!-- chose site select -->
          <div class="field-title">Choisissez un site</div>
          <v-select
            v-model="chosenSiteId"
            :loading="loadingSites"
            :items="getSites"
            item-value="id"
            item-text="nom"
            label="Site"
            solo
            @change="fetchBatiments($event);fetchInspections($event)"
            attach
          >
          </v-select>

          <!-- chose batiment select -->
          <div class="field-title" v-if="chosenSiteId &&
          inspectionType ==='batiments'">Choisissez un bâtiment</div>
          <v-select
            v-if="chosenSiteId &&
             inspectionType ==='batiments'"
            v-model="chosenBatimentId"
            :loading="loadingBatiments"
            :items="batiments"
            item-value="id"
            item-text="nom"
            label="Bâtiment"
            solo
            @change="fetchInspections($event)"
            attach
          >
          </v-select>
        </div>

        <!-- chose inspections table select -->
        <v-card
          class="lower-scroll-block"
          v-if="chosenBatimentId || (chosenSiteId && inspectionType === 'sites')"
        >
          <InspectionSelectTable
            v-if="!loadingInspections"
            :inspections="inspections"
            @new-selection="chosenInspections = $event"
          >
          </InspectionSelectTable>
          <!-- loader for inspections -->
          <div class="inspections-loader">
            <v-progress-circular
              width="7"
              size="70"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-card>

      </div>

      <div class="dialog-footer">
        <v-btn
          text
          large
          color="primary"
          @click="$emit('close')"
        >
          Annuler
        </v-btn>

        <v-btn
          depressed
          large
          color="primary"
          :disabled="!chosenInspections.length"
          :loading="saving"
          @click.native="handleImport()"
        >
          Importer
        </v-btn>
      </div>
    </div>

  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import InspectionSelectTable from '@/components/inspections/InspectionSelectTable.vue';

export default {
  name: 'ImportInspectionModal',
  components: {
    InspectionSelectTable,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    inspectionType: {
      type: String,
    },
  },
  data() {
    return {
      saving: false,
      chosenSiteId: null,
      chosenBatimentId: null,
      chosenInspections: [],
      loadingSites: false,
      loadingBatiments: false,
      loadingInspections: false,
    };
  },
  computed: {
    ...mapState({
      allBatiments: state => state.batiments.batiments,
      allInspections: state => state.inspections,
    }),
    ...mapGetters({
      sites: 'sites/selectedMissionSites',
      batiment: 'batiments/selected',
      site: 'sites/selected',
      batimentInspections: 'inspections/selectedBatimentInspections',
      sitesInspections: 'inspections/selectedSiteInspections',
    }),

    batiments() {
      if (this.allBatiments && this.allBatiments.length && this.chosenSiteId
        && this.inspectionType === 'batiments') {
        return this.allBatiments
          .filter(bat => bat.site === this.chosenSiteId && bat.id !== this.batiment.id);
      }
      return [];
    },
    getSites() {
      if (this.sites && this.sites.length) {
        if (this.inspectionType === 'batiments') {
          return this.sites;
        }
        if (this.inspectionType === 'sites') {
          return this.sites
            .filter(site => site.id !== this.site.id);
        }
      }
      return [];
    },
    inspections() {
      if (this.allInspections && this.allInspections.inspections.length && this.chosenBatimentId) {
        return this.allInspections.inspections.filter(inspection => inspection
          .batiment === this.chosenBatimentId);
      } if (this.allInspections && this.allInspections.inspections.length
        && this.chosenSiteId) {
        return this.allInspections.inspections.filter(inspection => inspection
          .site === this.chosenSiteId && inspection.site !== this.site.id
          && inspection.batiment === '');
      }
      return [];
    },
  },
  methods: {
    async handleImport() {
      this.saving = true;
      const inspectionTypes = this.inspectionType === 'batiments'
        ? this.batimentInspections : this.sitesInspections;
      const completed = new Promise((resolve) => {
        this.chosenInspections.forEach(async (chosen, index, array) => {
          const insp = inspectionTypes.find(inspect => inspect
            .inspectionType === chosen.inspectionType);
          if (inspectionTypes.find(inspect => inspect
            .inspectionType === chosen.inspectionType)) {
            const temp = { ...insp };
            temp.constats = [];
            temp.constats = { ...chosen.constats };
            await this.saveInspection(temp);
          } else {
            await this.createOneInspection(chosen);
          }
          if (index === array.length - 1) resolve();
        });
      });
      completed.then(async () => {
        if (this.inspectionType === 'batiments') {
          await this.$store.dispatch('inspections/fetchAllByBatiment', this.batiment.id);
        } else {
          await this.$store.dispatch('inspections/fetchAllBySite', this.site.id);
        }
        this.saving = false;
        this.$store.dispatch('snackbar/displaySnack', {
          message: 'importation terminée avec succès',
          type: 'succes',
        });
        this.$emit('close');
      });
    },

    async fetchBatiments(siteId) {
      if (siteId) {
        this.loadingBatiments = true;
        await this.$store.dispatch('batiments/fetchAllBySite', siteId);
        this.loadingBatiments = false;
      }
    },
    async saveInspection(newInspection) {
      try {
        const inspectionToSave = { ...newInspection };
        delete inspectionToSave.id;
        // transform all equipements selected and save them on the batiment
        await this.$store.dispatch('inspections/save', {
          id: newInspection.id,
          data: inspectionToSave,
        });
      } catch (error) {
        await this.$store.dispatch('snackbar/displaySnack', {
          message: 'erreur l\'ors de l\'importation',
          type: 'error',
        }).then(() => {
          this.saving = false;
          this.$emit('close');
        });

        // setTimeout(() => , 1000);
      }
    },
    async createOneInspection(inspectionCopy) {
      const payload = {
        idType: inspectionCopy.inspectionType,
        nomType: inspectionCopy.nomType,
        codeType: inspectionCopy.codeType,
      };
      // handle site of batiment inspections
      if (this.inspectionType === 'batiments') {
        payload.idBatiment = this.batiment.id;
      } else if (this.inspectionType === 'sites') {
        payload.idSite = this.site.id;
      }
      const newInspection = await this.$store.dispatch('inspections/createNew', payload);
      const inspectionToSave = { ...newInspection };
      inspectionToSave.constats = { ...inspectionCopy.constats };
      inspectionToSave.id = newInspection.id;
      await this.saveInspection(inspectionToSave);
    },

    async fetchInspections(batimentId) {
      if (batimentId) {
        this.loadingInspections = true;
        if (this.inspectionType === 'batiments') {
          await this.$store.dispatch('inspections/fetchAllByBatiment', batimentId);
        } else {
          await this.$store.dispatch('inspections/fetchAllBySite', batimentId);
        }
        this.loadingInspections = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.dialog-wrapper {
  height: 100%;

  display: grid;
  grid-template-columns: 7rem 1fr 7rem;
  grid-template-rows: 4rem 1fr 4rem;
  grid-template-areas:
      "header header header"
      "content content content"
      "footer footer footer";

  background-color: $app-grey;

  .dialog-title {
    grid-area: header;

    font-size: 2rem;
    font-weight: 500;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    background-color: $app-blue;
    color: $app-grey;
  }

  .dialog-content {
    grid-area: content;
    justify-self: center;

    overflow: hidden;
    width: 100%;
    max-width: 1200px;
    padding: 1rem 2rem 1rem 2rem;

    display: grid;
    grid-template-rows: minmax(15rem, 1fr) 3fr;
    grid-template-areas:
        "upper"
        "lower";

    .upper-fix-block {
      grid-area: upper;
    }

    .lower-scroll-block {
      grid-area: lower;
      overflow: hidden;
    }
  }

  .dialog-footer {
    grid-area: footer;

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;

    padding: 0 1rem 0 1rem;

    > * {
      margin-right: 1rem;
    }
  }
}
.inspections-loader {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}
.field-title {
  font-weight: 300;
  font-size: 1.2rem;
  padding-bottom: 0.5rem;
  padding-left: 0.2rem;
}

</style>
