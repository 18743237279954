import { applyAuthTokenInterceptor } from 'axios-jwt';
import axios from 'axios';
// import http from '../store/modules/api';

const axiosInstance = axios.create();


const requestRefresh = refresh => axios.post('/api/refresh', { refresh_token: refresh })
  .then(tokens => tokens.data.token);

// 3. Apply interceptor
// Notice that this uses the axiosInstance instance.  <-- important
applyAuthTokenInterceptor(axiosInstance, { requestRefresh });

// 3. Apply interceptor
// Notice that this uses the axiosInstance instance.  <-- important
applyAuthTokenInterceptor(axiosInstance, { requestRefresh });

export default axiosInstance;
