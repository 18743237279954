<template>
  <div v-if="(selectedSite || selectedBatiment) && !loading" class="rapport-view">
      <h3>{{ title }}</h3>
      <SiteBatimentTabs
        :structures="structures"
        :isDownload="isDownload"
        :itemUrl="itemUrl"
        @download="downloadReport($event)"
        >
      </SiteBatimentTabs>
    <DownloadReportDialog
      :show="showDownloadDialog"
      @close="showDownloadDialog = false"
    >
    </DownloadReportDialog>
  </div>
    <div v-else-if="!loading && selectedMission && !selectedSite
    && !selectedBatiment" class="rapport-view">
      <h3>{{ title }}</h3>
      <MissionTabs
      :structures="structures"
      @updateName="updateName($event)"
      @edit="addStructure($event)"
      @delete="deleteStruct($event)"
      @addStructure="addStructure($event, true)"
      @download="downloadStruct($event)"
      >
      </MissionTabs>

  </div>
    <!-- Loader -->
  <div v-else class="rapport-view">
    <template>
      <h4>Récupération des données d'affichage...</h4>
      <v-progress-circular
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      >
      </v-progress-circular>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import axiosInstance from '../plugins/axios';


import DownloadReportDialog from '../components/rapports/DownloadReportDialog.vue';
import MissionTabs from '../components/rapports/MissionTabs.vue';
import SiteBatimentTabs from '../components/rapports/SiteBatimentTabs.vue';

export default {
  components: {
    DownloadReportDialog,
    MissionTabs,
    SiteBatimentTabs,
  },

  data() {
    return {
      isDownload: false,
      loading: true,
      showDownloadDialog: false,
      structures: null,
      apiUrl: null,
      logFileName: null,
      file: null,
      nom: null,
    };
  },

  computed: {
    ...mapGetters('missions', { selectedMission: 'selected' }),
    ...mapGetters('sites', { selectedSite: 'selected' }),
    ...mapGetters('batiments', { selectedBatiment: 'selected' }),
    ...mapGetters('clients', { selectedClient: 'selected' }),
    selectedView: {
      get() {
        return this.$store.state.selectedView;
      },
      set(value) {
        this.$store.commit('setSelectedView', value);
      },
    },

    title() {
      switch (this.rapportType) {
        case 'Site':
          return `Rapport du site : ${this.selectedSite.nom}`;
        case 'Batiment':
          return `Rapport du bâtiment : ${this.selectedBatiment.nom}`;
        case 'missionBatiment':
          return 'Structure de rapport bâtiment';
        case 'missionSite':
          return 'Structure de rapport site';
        default:
          return 'Pas de rapport disponible pour ce type d\'objet';
      }
    },

    missionId() {
      return this.$route.params.missionId;
    },

    rapportType() {
      if (this.$route.name === 'siteRapport') {
        return 'Site';
      }

      if (this.$route.name === 'batimentRapport') {
        return 'Batiment';
      }

      if (this.$route.name === 'missionBatimentRapport') {
        return 'missionBatiment';
      }

      if (this.$route.name === 'missionSiteRapport') {
        return 'missionSite';
      }

      return 'wrong type';
    },

    itemUrl() {
      switch (this.rapportType) {
        case 'Site':
          return `/api/sites/${this.selectedSite.id}`;
        case 'Batiment':
          return `/api/batiments/${this.selectedBatiment.id}`;
        default:
          return 'error';
      }
    },
  },

  async created() {
    (this.$route.params.siteId || this.$route.params.batimentId) ? this.selectedView = 'Restituer' : this.selectedView = 'Importer';
    await this.$store.dispatch('equipements/fetchAllCategories', { missionId: this.selectedMission.id, majRef: false });
    await this.$store.dispatch('inspections/fetchTypesInspectionByMission', {
      idMission: this.selectedMission.id,
    });
    this.loading = true;
    if (this.rapportType) {
      switch (this.rapportType) {
        case 'Site':
          await this.$store.dispatch('changeSelectedItem', { type: 'sites', id: this.$route.params.siteId });
          break;
        case 'Batiment':
          await this.$store.dispatch('changeSelectedItem', { type: 'batiments', id: this.$route.params.batimentId });
          break;
        default:
          break;
      }
    }
    this.getReportStructure();
  },

  methods: {
    downloadStruct(struct) {
      axiosInstance.axiosInstance.get(`/api/rapport-structures/${struct.id}`).then((response) => {
        const json = JSON.stringify(response.data.structureValue, null, 2);
        const blob = new Blob([json], { type: 'application/JSON' });
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        link.setAttribute('download', `${struct.nom}.json`);
        document.body.appendChild(link);
        link.click();
      }).catch((err) => {
        let errorMessage = 'Erreur lors du téléchargement du JSON.';
        if (err.response && err.response.status === 403) {
          errorMessage = "Vous n'avez pas les droits pour cette action";
        }
        this.$store.dispatch('snackbar/displaySnack',
          {
            message: errorMessage,
            type: 'error',
          });
      });
    },
    deleteStruct(structId) {
      axiosInstance.axiosInstance.delete(`/api/rapport-structures/${structId}`).then(() => {
        this.$store.dispatch('missions/refreshItem', this.selectedMission.id);
        this.getReportStructure();
        this.$store.dispatch('snackbar/displaySnack',
          {
            message: 'Structure supprimée avec succès',
            type: 'succes',
          });
      }).catch((err) => {
        let errorMessage = 'Erreur lors de la suppression';
        if (err.response && err.response.status === 403) {
          errorMessage = 'Vous n\'avez pas les droits pour cette action';
        }
        this.$store.dispatch('snackbar/displaySnack',
          {
            message: errorMessage,
            type: 'error',
          });
      });
    },
    addStructure(fields, create = false) {
      let idStruct = 'new';
      if (fields.id) {
        idStruct = fields.id;
      }
      let apiUrl = null;
      let logFileName = null;
      switch (this.rapportType) {
        case 'missionSite':
          apiUrl = `/api/missions/${this.selectedMission.id}/Site/${fields.nom}/${idStruct}/import-structureRapport`;
          logFileName = 'Logs_Intégration_Structure_Rapport_Site';
          break;
        case 'missionBatiment':
          apiUrl = `/api/missions/${this.selectedMission.id}/Batiment/${fields.nom}/${idStruct}/import-structureRapport`;
          logFileName = 'Logs_Intégration_Structure_Rapport_Bâtiment';
          break;
        default:
          break;
      }
      const formData = new FormData();
      // formData.append('file', fields.file[0]);
      if (fields.file === null && create) {
        const newTrame = {};
        const json = JSON.stringify(newTrame);
        const blob = new Blob([json], { type: 'application/JSON' });
        formData.append('file', blob);
      } else {
        formData.append('file', fields.file);
      }
      // call the import api with the formData
      // think to change the path/api 'url' for <import-site-batiment> when the back-task is donne
      axiosInstance.axiosInstance.post(apiUrl, formData)
        .then((responseImport) => {
          const blob = new Blob([responseImport.data], { type: 'text/plain' });
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(blob);

          // create a file with the report and download it
          link.href = url;
          link.setAttribute('download', `${logFileName + new Date().getDate()}.txt`);
          document.body.appendChild(link);
          link.click();

          if (responseImport.data.match(/Les données ont été correctement intégrées.*/)) {
            this.$store.dispatch('missions/refreshItem', this.selectedMission.id);
            this.getReportStructure();
            this.$store.dispatch('snackbar/displaySnack', { message: 'Données importées avec succès !', type: 'succes' });
          } else {
            this.$store.dispatch('snackbar/displaySnack', {
              message: 'Echec de l\'importation des données, veuillez consulter le fichiers de logs.',
              type: 'error',
            });
          }
        })
        .catch((err) => {
          this.nom = null;
          this.file = null;
          let errorMessage = 'Echec de l\'importation des données.';
          if (err.response && err.response.status === 403) {
            errorMessage = 'Vous n\'avez pas les droits pour cette action';
          }
          this.$store.dispatch('snackbar/displaySnack',
            {
              message: errorMessage,
              type: 'error',
            });
        });
    },
    downloadReport(idStruct) {
      this.isDownload = true;
      this.showDownloadDialog = true;
      let reportDoc = '';
      let rapportTypeUrl = '';
      if (this.rapportType === 'Site') {
        reportDoc = `${this.selectedClient.nom} - ${this.selectedMission.nom} -
        ${this.selectedSite.codeSite} - ${this.selectedSite.nom}`;
        rapportTypeUrl = 'sites';
      } else {
        const codeBatiment = this.selectedBatiment.idClient ? this.selectedBatiment.idClient : '';
        reportDoc = `${this.selectedClient.nom} - ${this.selectedMission.nom}
        - ${this.selectedSite.codeSite} - ${this.selectedSite.nom} - ${codeBatiment} -
      ${this.selectedBatiment.nom}`;
        rapportTypeUrl = 'batiments';
      }
      reportDoc = reportDoc.replace(/\s+/g, ' ');
      reportDoc.replaceAll('[^a-zA-Z0-9]', '');
      if (reportDoc.length >= 200) {
        reportDoc = reportDoc.substring(0, 200);
      }
      axiosInstance.axiosInstance.get(`${this.itemUrl}/${idStruct}/rapport`).then(async (response) => {
        let code = {
          data: {
            exitCode: -1,
          },
        };

        const callExecJobs = () => { //  create a loop function
          setTimeout(async () => { //  call a 30s setTimeout when the loop is called
            code = await axiosInstance.axiosInstance.get(`/api/exec-jobs/${response.data.id}`);
            if (code.data.exitCode === null || code.data.exitCode === -1) {
              callExecJobs(); //  ..  again which will trigger another
            } else {
              if (code.data.exitCode === 0) {
                axiosInstance.axiosInstance.get(`/api/${rapportTypeUrl}/${response.data.id}/rapport/download`, { responseType: 'blob' }).then((report) => {
                  // create a invisible 'a' tag with the file in the url and click on it
                  const url = window.URL.createObjectURL(new Blob([report.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', `${reportDoc}.docx`);
                  document.body.appendChild(link);
                  link.click();
                });
              } else {
                this.$store.dispatch('snackbar/displaySnack', { message: 'Erreur lors du téléchargement de votre rapport!', type: 'error' });
              }
              this.isDownload = false;
            }
          }, 30000);
        };
        callExecJobs(); //  start the loop
      });
    },
    updateName(event) {
      return axiosInstance.axiosInstance.put(`/api/rapport-structures/${event.struct.id}`, {
        ...event.struct,
        nom: event.nom,
      }).then(() => {
        this.getReportStructure();
        this.$store.dispatch('snackbar/displaySnack', { message: 'Nom modifié avec succès !', type: 'succes' });
      }).catch((err) => {
        let errorMessage = 'Echec de la modification';
        if (err.response && err.response.status === 403) {
          errorMessage = 'Vous n\'avez pas les droits pour cette action';
        }
        this.$store.dispatch('snackbar/displaySnack',
          {
            message: errorMessage,
            type: 'error',
          });
      });
    },
    getReportStructure() {
      let rapType = this.rapportType;
      if (this.rapportType === 'missionBatiment') {
        rapType = 'Batiment';
      } else if (this.rapportType === 'missionSite') {
        rapType = 'Site';
      }
      axiosInstance.axiosInstance.get(`/api/missions/${this.missionId}/rapport-structures`, {
        params: {
          typeRapport: rapType,
        },
      }).then((response) => {
        if (response.data.length) {
          this.structures = response.data;
        } else {
          this.$store.dispatch('snackbar/displaySnack', { message: 'aucune structure trouvée pour ce type de rapport', type: 'error' });
        }
        this.loading = false;
      });
    },

  },
};
</script>

<style lang="scss" scoped>
  .rapport-view {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    padding: 1rem;

    > * {
      margin-bottom: 2rem;
    }
  }
</style>
