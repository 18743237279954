<template>
  <div class="table-wrapper">

    <div class="table-title-block">
      <div class="table-title">Interventions</div>
      <div class="table-filter">
        <v-select
          v-model="filter"
          :items="['toutes', 'selectionées']"
          label="Voir"
          outlined
          hide-details
          dense
          attach
        ></v-select>
      </div>
    </div>

    <v-data-table
      class="interventions-table"
      v-model="interventionsSelected"
      :items="tableInterventions"
      :headers="headers"
      :single-select="singleSelect"
      show-select
      disable-pagination
      hide-default-footer
      @item-selected="touched = true"
      @toggle-select-all=" touched = true"
    >
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    interventions: {
      type: Array,
      default: () => [],
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    initialListInter: {
      type: Array,
      default: () => [],
    },
    initialList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      filter: 'toutes',
      headers: [
        { text: 'source', value: 'source' },
        { text: 'investissemnent (EUR HT)', value: 'invest' },
        { text: 'gain EF (%)', value: 'gainEf' },
        { text: 'gain Co2 (%)', value: 'gainCo2' },
        { text: 'TRI (brut)', value: 'tri' },
      ],
      interventionsSelected: [],
      touched: false,
    };
  },

  computed: {
    mappedInterventions() {
      return this.interventions.map(inter => this.mapIntervention(inter));
    },

    tableInterventions() {
      if (this.filter === 'selectionées') {
        return this.mappedInterventions
          .filter(inter => this.interventionsSelected
            .find(selectedInter => selectedInter.id === inter.id));
      }
      return this.mappedInterventions;
    },
  },

  created() {
    if (this.initialListInter.length && this.interventions.length) {
      const filteredList = this.interventions
        .filter(inter => this.initialListInter.indexOf(inter.id) >= 0);
      this.interventionsSelected = filteredList.map(inter => this.mapIntervention(inter));
    }
    if (this.initialList.length && this.interventions.length) {
      const filteredList = this.interventions
        .filter(inter => this.initialList.indexOf(inter.id) >= 0);
      this.interventionsSelected = filteredList.map(inter => this.mapIntervention(inter));
    }
  },

  watch: {
    interventionsSelected() {
      this.$emit('new-selection', {
        interventions: this.interventionsSelected,
        touched: this.touched,
      });
    },
  },

  methods: {
    mapIntervention(intervention) {
      return {
        id: intervention.id,
        source: intervention.typeInterventionLibelle ? intervention.typeInterventionLibelle
          : intervention.typeIntervention,
        invest: intervention.calculations.investissementHt,
        gainEf: intervention.calculations.gainConsommationEfRelatif,
        gainCo2: intervention.calculations.gainEmissionCo2Relatif,
        tri: intervention.calculations.tempsRetourInvestissementBrut,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .table-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    height: 100%;

    .table-title-block {
      flex: 0 0 auto;
      padding: 0.5rem;
      height: 3.5rem;

      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;


      .table-title {
        font-weight: 300;
        font-size: 1.4rem;
      }

      .table-filter {
        margin-right: 0;
        margin-left: auto;
        width: 9.5rem;
      }
    }

    .interventions-table {
      flex: 1 1 auto;
      height: 100%;
      overflow-y: auto;
      padding-bottom: 5rem;
    }
  }
</style>
