<template>
  <div>
    <div class="client-detail-wrapper">
      <transition appear name="fade">
        <ClientFatCard
          v-if="selectedClient"
          :client="selectedClient"
          @show-edit-item="sheetEditStatus = true"
          :loading="loadingClient"
        ></ClientFatCard>
      </transition>

      <transition appear name="slide-fade-down">
        <v-card
          class="big-card"
          v-if="selectedClient"
          :loading="loadingMissions || loadingPatrimoines"
        >
      <v-tabs
        v-model="tab"
        background-color="primary"
        fixed-tabs
        dark
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#tab-1">
          Missions
          <v-icon>mdi-folder-marker</v-icon>
        </v-tab>
        <v-tab href="#tab-2">
          Patrimoines
          <v-icon>mdi-city</v-icon>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="i in 2"
          :key="i"
          :value="'tab-' + i"
        >
        <div v-if="i === 1" class="inputSearch">
              <transition appear name="fade">
                <v-text-field
                  v-model="search"
                  outlined
                  rounded
                  placeholder="Rechercher un site dans la liste"
                  label="Recherche"
                  append-icon="mdi-magnify"
                  class="missions-search-field"
                  color="tertiary"
                >
                </v-text-field>
              </transition>
            </div>
        <div v-if="i === 1">
        <v-card-subtitle>Missions</v-card-subtitle>
          <MissionList
            v-if="!loading && clientMissions && clientMissions.length"
            :missions="filteredMissions"
            @mission-clicked="goToMission($event)"
          ></MissionList>
          <p
            v-if="loadingMissions"
            class="missions-card-message"
          >
            <v-skeleton-loader
              v-for="(mission, i) in selectedClient.missions"
              :key="`skel_${i}`"
              type="list-item"
            >
            </v-skeleton-loader>
          </p>

          <p
            v-if="!loading && (!clientMissions || !clientMissions.length) "
            class="missions-card-message"
          >
            Aucune mission sur ce client
          </p>
        </div>
        <div v-if="i === 2">
          <v-card-subtitle>Patrimoines</v-card-subtitle>
          <PatrimoineList
            v-if="!loading && clientPatrimoines"
            :patrimoines="clientPatrimoines"
            @patrimoine-clicked="goToPatrimoine($event)"
          ></PatrimoineList>
          <p
            v-if="loadingPatrimoines"
            class="missions-card-message"
          >
            <v-skeleton-loader
              v-for="(patrimoine, i) in selectedClient.patrimoines"
              :key="`skel_${i}`"
              type="list-item"
            >
            </v-skeleton-loader>
          </p>

          <p
            v-if="!loading && (!clientPatrimoines || !clientPatrimoines.length) "
            class="missions-card-message"
          >
            Aucun patrimoine sur ce client
          </p>
        </div>
        </v-tab-item>
      </v-tabs-items>

        </v-card>
      </transition>
    </div>

      <!-- <transition appear name="scale-rotate"> -->
        <BaseFab
          class="clients-fab"
          :options="fabOptions"
          key='clientDetail_fab'
          @option-selected="handlerFabButton"
        >
        </BaseFab>
      <!-- </transition> -->

      <!--<BottomSheetAddItem
        v-if="selectedClient"
        :sheet="showBottomSheetNewMission"
        @close-sheet="showBottomSheetNewMission = false"
        typeItem="missions"
        :defaultItem="{ client: selectedClient.id, createdBy: me.username  }"
      >
      </BottomSheetAddItem>-->
      <BottomSheetAddMissionStepper
      :sheet="showBottomSheetNewMission"
      @close-sheet="showBottomSheetNewMission = false"
      :defaultItem="{ client: selectedClient.id, createdBy: me.username  }">
      </BottomSheetAddMissionStepper>

      <BottomSheetAddItem
        v-if="selectedClient"
        :sheet="showBottomSheetNewPatrimoine"
        @close-sheet="showBottomSheetNewPatrimoine = false"
        typeItem="patrimoines"
        :defaultItem="{ client: selectedClient.id }"
      >
      </BottomSheetAddItem>

      <BottomSheetEditItem
        :sheet="sheetEditStatus"
        @close-sheet="sheetEditStatus = false"
        :item="selectedClient"
        typeItem="clients"
      >
      </BottomSheetEditItem>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import ClientFatCard from '../components/clients/ClientFatCard.vue';
import MissionList from '../components/missions/MissionList.vue';
import PatrimoineList from '../components/patrimoines/PatrimoineList.vue';

import BaseFab from '../components/BaseFab.vue';
import BottomSheetAddItem from '../components/BottomSheetAddItem.vue';
import BottomSheetEditItem from '../components/BottomSheetEditItem.vue';
import BottomSheetAddMissionStepper from '../components/missions/BottomSheetAddMissionStepper.vue';

export default {
  components: {
    ClientFatCard,
    MissionList,
    PatrimoineList,
    BaseFab,
    BottomSheetAddItem,
    BottomSheetEditItem,
    BottomSheetAddMissionStepper,
  },

  props: ['clientId'],

  data() {
    return {
      tab: null,
      loadingClient: false,
      loadingMissions: false,
      loadingPatrimoines: false,
      fabOptions: [{
        icon: 'mdi-folder-marker',
        name: 'newMission',
        tooltip: 'Nouvelle mission',
        color: 'green',
      },
      {
        icon: 'mdi-city',
        name: 'newPatrimoine',
        tooltip: 'Nouveau patrimoine',
        color: 'green',
      }],
      showBottomSheetNewMission: false,
      showBottomSheetNewPatrimoine: false,
      sheetEditStatus: false,
      search: '',
    };
  },

  computed: {
    ...mapGetters('clients', { selectedClient: 'selected' }),
    ...mapState('missions', ['missions']),
    ...mapState('patrimoines', ['patrimoines']),
    ...mapGetters({ me: 'me/me' }),
    filteredMissions() {
      if (this.search && this.search.length > 2) {
        return this.clientMissions.filter(
          mission => mission.nom.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(this.search.toLowerCase()) || mission.nom.toLowerCase().includes(this.search.toLowerCase()),
        );
      }
      return this.clientMissions;
    },
    clientMissions() {
      if (this.selectedClient) {
        return this.missions.filter(mission => mission.client === this.selectedClient.id);
      }
      return [];
    },

    clientPatrimoines() {
      if (this.selectedClient) {
        return this.patrimoines.filter(patrimoine => patrimoine.client === this.selectedClient.id);
      }
      return [];
    },
    loading() {
      return this.loadingClient || this.loadingMissions || this.loadingPatrimoines;
    },
  },

  async created() {
    this.$store.dispatch('clearSelectedView');
    this.loadingClient = true;
    await this.$store.dispatch('changeSelectedItem', { type: 'clients', id: this.clientId });
    this.loadingClient = false;
    if (this.selectedClient.missions
      && this.selectedClient.missions.length && !this.clientMissions.length) {
      this.loadingMissions = true;
      await this.$store.dispatch('missions/fetchAllByClient', this.clientId);
      this.loadingMissions = false;
    }
    if (this.selectedClient.patrimoines
      && this.selectedClient.patrimoines.length && !this.clientPatrimoines.length) {
      this.loadingPatrimoines = true;
      await this.$store.dispatch('patrimoines/fetchAllByClient', this.clientId);
      this.loadingPatrimoines = false;
    }
  },

  methods: {
    // Handle multiple actions from BaseFab and redirect to right action
    handlerFabButton(val) {
      if (val === this.fabOptions[0].name) {
        this.showBottomSheetNewMission = true;
      }

      if (val === this.fabOptions[1].name) {
        this.showBottomSheetNewPatrimoine = true;
      }
    },
    async goToMission(mission) {
      await this.$store.dispatch('missions/refreshItem', mission.id)
        .then(() => {
          this.$router.push({ name: 'missionDetail', params: { missionId: mission.id } });
        });
    },

    goToPatrimoine(patrimoine) {
      this.$store.dispatch('clients/refreshItem', this.selectedClient.id).then(() => {
        this.$router.push({ name: 'patrimoineDetail', params: { patrimoineId: patrimoine.id } });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .client-detail-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    padding: 1rem;

    > * {
      max-width: 700px;
      width: 100%;
      margin-bottom: 1rem;
    }
  }

  .missions-card-message {
    margin: 0 auto 2rem auto;
    text-align: center;
  }

  .clients-fab {
    position: fixed;
    right: 4rem;
    bottom: 4rem;
  }
</style>
