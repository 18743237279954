<template>
  <div class="list-wrapper">

    <v-expansion-panels
      accordion
      class="batiments-list"
    >
      <v-expansion-panel
        v-for="batiment in batiments"
        :key="`bat_${batiment.id}`"
      >
        <v-expansion-panel-header v-if="hasBatimentScenarios(batiment.id)">
          <div class="header-wrapper">
            <div class="header-title">{{ batiment.nom }}</div>
            <div class="header-scenario-name">{{ getScenarioName(batiment.id) }}</div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ScenarioSelectTable
           :batiment="batiment"
           :initialSelection="getScenario(batiment.id)"
           @new-selection="handleChangeSelected(batiment.id, $event)"
          >
          </ScenarioSelectTable>
        </v-expansion-panel-content>
      </v-expansion-panel>
            <v-expansion-panel
      >
        <v-expansion-panel-header>
          <div class="header-wrapper">
            <div class="header-title">Liste des interventions</div>
            <div class="header-scenario-name"></div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <InterventionsSelectTable
            :initialListInter="initialListInter"
            :interventions="siteInterventionSites"
            @new-selection="handleChangeInterventions($event)"
          >
          </InterventionsSelectTable>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ScenarioSelectTable from './ScenarioSelectTable.vue';
import InterventionsSelectTable from '../interventions/InterventionsSelectTable.vue';

export default {
  components: {
    ScenarioSelectTable,
    InterventionsSelectTable,
  },
  props: {
    initialList: {
      type: Array,
      default: () => [],
    },
    initialListInter: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      scenariosSelected: [],
      interventionSitesSelected: [],
      touched: false,
    };
  },

  computed: {
    ...mapGetters({
      site: 'sites/selected',
      batiments: 'batiments/selectedSiteBatiments',
      scenarios: 'scenarios/selectedSiteScenarios',
    }),
    ...mapState('interventionSites', ['interventionSites']),
    siteInterventionSites() {
      if (this.interventionSites) {
        return this.interventionSites
          .filter(interventionSite => interventionSite.site === this.site.id);
      }
      return [];
    },
  },

  async created() {
    this.loading = true;
    await this.$store.dispatch('batiments/fetchAllBySite', this.site.id);
    await Promise.all(
      this.batiments.map(bat => this.$store.dispatch('scenarios/fetchAllByBatiment', bat.id)),
    );
    await Promise.all([
      this.$store.dispatch('interventionSites/fetchAllBySite', this.site.id),
    ]);
    this.loading = false;

    if (this.initialList.length) {
      this.scenariosSelected = this.scenarios
        .filter(scenar => this.initialList.includes(scenar.id));
    }
    if (this.initialListInter.length) {
      this.interventionSitesSelected = this.interventionSites
        .filter(inter => this.initialListInter.includes(inter.id));
    }
  },

  watch: {
    scenariosSelected() {
      this.$emit('new-selection', {
        scenarios: this.scenariosSelected,
        touched: this.touched,
      });
    },
    interventionSitesSelected() {
      this.$emit('new-selection-interventions', {
        interventionSites: this.interventionSitesSelected,
        touched: this.touched,
      });
    },
  },

  methods: {
    hasBatimentScenarios(batimentId) {
      const nbScenario = this.scenarios.filter(scenar => scenar.batiment === batimentId);
      if (nbScenario.length !== 0) {
        return true;
      }
      return false;
    },
    handleChangeInterventions({ interventions, touched }) {
      this.touched = this.touched || touched;
      if (this.touched) {
        this.interventionSitesSelected = interventions;
      }
    },
    getScenario(batimentId) {
      return this.scenariosSelected.find(scenar => scenar.batiment === batimentId);
    },

    getScenarioName(batimentId) {
      const scenario = this.getScenario(batimentId);
      if (scenario) {
        return scenario.nom;
      }
      return '';
    },

    handleChangeSelected(batimentId, { scenario, touched }) {
      // first remove previous selection if there is one
      const previousSelectionIndex = this.scenariosSelected
        .findIndex(scenar => scenar.batiment === batimentId);
      if (previousSelectionIndex >= 0) {
        this.scenariosSelected.splice(previousSelectionIndex, 1);
      }

      // then update selection list and touched boolean
      this.touched = this.touched || touched;
      if (scenario) {
        this.scenariosSelected.push(scenario);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .list-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    height:55%;

    .list-title-block {
      flex: 0 0 auto;
      padding: 0.5rem;
      height: 3.5rem;

      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;


      .list-title {
        font-weight: 300;
        font-size: 1.4rem;
      }
    }

    .batiments-list {
      overflow-y: auto;
      padding-bottom: 5rem;
    }

    .header-wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;

      width: 100%;

      .header-scenario-name {
        margin-left: auto;
        margin-right: 1rem;
        width: 7rem;
        text-align: right;

        color: $app-blue;
      }
    }
  }
</style>
