/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  patrimoinesEndpoint, sitesEndpoint, clientsEndpoint, patrimoinesUrl,
} from './apiUrls';

export default {
  db: new PouchDB('patrimoines'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch
    const _id = obj._id || obj.id.toString();

    return {
      ...obj,
      _id,
      meta: obj.meta || {},
      id: String(obj.id),
      description: obj.description,
      nom: obj.nom,
      numero: obj.numero,
      client: obj.client.replace(`${clientsEndpoint}/`, ''),
      sites: obj.sites.map(site => site.replace(`${sitesEndpoint}/`, '')),
    };
  },

  create(patrimoine) {
    return new Promise((resolve, reject) => {
      const patrimoineToSave = { ...patrimoine };
      delete patrimoineToSave.image;
      axiosInstance.axiosInstance.post(patrimoinesEndpoint, patrimoineToSave)
        .then((response) => {
          resolve(this.convertBackendObject(response.data));
        })
        .catch((error) => { reject(error); });
    });
  },

  update(patrimoineId, patrimoine) {
    return new Promise((resolve, reject) => {
      const patrimoineToSave = { ...patrimoine };
      axiosInstance.axiosInstance.put(`${patrimoinesEndpoint}/${patrimoineId}`, patrimoineToSave)
        .then((response) => {
          resolve(this.convertBackendObject(response.data));
        })
        .catch((error) => { reject(error); });
    });
  },

  async delete(patrimoineId) {
    // delete the patrimoine from pouchDB
    try {
      await pouchUtil.deleteDoc(patrimoineId, this.db);
    } catch (e) {
      console.log(e.response);
    }
    // if the delete from pouchDB is sucessfull, delete from the server
    return axiosInstance.axiosInstance.delete(`${patrimoinesEndpoint}/${patrimoineId}`);
  },

  /**
   * get a patrimoine by his id
   * @param {String} patrimoineId - the id of the patrimoine to get
   */
  async get(patrimoineId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${patrimoinesEndpoint}/${patrimoineId}`);
      const patrimoine = this.convertBackendObject(response.data);

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDoc(patrimoine, this.db);
    } catch (e) {
      console.error('patrimoinesApi get error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getDoc(patrimoineId, this.db);
    }
  },

  /**
   * get a patrimoine by his id for offline use
   * @param {String} patrimoineId - the id of the patrimoine to get
   */
  async fetchOffline(patrimoineId) {
    const response = await axiosInstance.axiosInstance.get(`${patrimoinesEndpoint}/${patrimoineId}`);
    const patrimoine = this.convertBackendObject(response.data);
    // if get is successful,  store data into pouchdb and return it
    return pouchUtil.addDoc(patrimoine, this.db);
  },

  /**
   * get all patrimoines of the application
   */
  async getAll() {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${patrimoinesEndpoint}`);
      const patrimoines = response.data.map(patrimoine => this.convertBackendObject(patrimoine));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(patrimoines, this.db);
    } catch (e) {
      console.error('patrimoinesApi getAll error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocs(this.db);
    }
  },

  /**
 * get all patrimoines of a parent client
 * @param {String} clientId - id of the parent client
 */
  async getAllByClient(clientId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${clientsEndpoint}/${clientId}${patrimoinesUrl}`);
      const patrimoines = response.data.map(patrimoine => this.convertBackendObject(patrimoine));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(patrimoines, this.db);
    } catch (e) {
      console.error('patrimoinesApi getAll error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByField('client', clientId, this.db);
    }
  },

  /**
   * Fetch all patrimoines stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocs(this.db);
  },
};
