<template>
  <div class="wrapper" v-if="!loading && batimentEquipements.length">
    <div v-if="showHeaderFields" class="headerFields">
      <div class="parameter-lists">
        <v-autocomplete
          class="parameter"
          outlined
          dense
          :items="this.attributesList"
          item-text="libelle"
          return-object
          v-model="firstParameter"
          label="Paramètre 1"
          @change="majParameters($event)"
        >
        </v-autocomplete>
        <v-autocomplete
          outlined
          dense
          :items="this.attributesList"
          item-text="libelle"
          return-object
          v-model="secondParameter"
          label="Paramètre 2"
          @change="majParameters($event)"
        >
        </v-autocomplete>
      </div>
      <div class="research">
        <v-text-field
          v-model="search"
          prepend-inner-icon="mdi-magnify"
          label="Rechercher un équipement"
          flat
          dense
          hide-details
          clearable
          outlined
          clear-icon="mdi-close-circle-outline"
        ></v-text-field>
      </div>
    </div>
    <v-btn class="btn-display-header"
      dark color="#2D4388" small
      @click="displayHeaders()">{{buttonTextDisplay}}
      <v-icon x-small v-if="!showHeaderFields">mdi-plus</v-icon>
      <v-icon x-small v-else>mdi-minus</v-icon>
    </v-btn>
    <v-treeview
      v-model="checkedEquipements"
      :items="fullTable"
      item-key="id"
      open-on-click
      transition
      selectable
      :search="search"
      selection-type="leaf"
      :open.sync="openIds"
      :active.sync="active"
      activatable
      :open-all="expanded"
      ref="myTreeview"
      @update:open="onOpen()"
      expand-icon="mdi-chevron-down"
    >
      <template v-slot:prepend="{ item }">
        <v-icon
          v-if="item.levelParam === 1 && checkNecessaryNotBlankFirstParam(item)"
          style="color: orange">mdi-alert-circle</v-icon>
        <v-icon
          v-if="item.levelParam === 2 && checkNecessaryNotBlankSecondParam(item)"
          style="color: orange" >mdi-alert-circle</v-icon>
          <v-icon
          v-if="!item.children && item.inAlert"
          style="color: orange" >mdi-alert-circle</v-icon>
      </template>
      <template v-slot:label="{ item }">
          <span @click="openPanel()" v-if="item.levelParam === 0"
            class="root-label">{{ item.name }}</span>
          <span v-if="item.levelParam === 1" class="first-param-label"
          :class="{ 'undefined-label': item.name === '(Non défini)'}"
          >{{ item.name }}</span>
          <span v-if="item.levelParam === 2" class="second-param-label"
          :class="{ 'undefined-label': item.name === '(Non défini)'}"
          >{{ item.name }}</span>
          <ListItemWithButtons
            v-if="!item.children"
            withAvatar
            :selected="selected && item.id === selected.id"
            typeItem="equipement"
            @click-inter="$emit('create-inter', item)"
            @click-edit="$emit('edit-equipement', item)"
            @click-delete="$emit('delete-equipement', item.id)"
            @click-duplicate="$emit('duplicate-equipement', item)"
          >
            <template #avatar>
              <v-avatar v-if="item.imagePrincipale">
                <v-btn icon
                  color="primary"
                  @click="changeFavoritePic(item.id)"
                >
                  <img
                    :src="`${item.imagePrincipale}/file?size=xsmall`"
                  >
                </v-btn>
              </v-avatar>
              <v-avatar v-else>
                <v-btn icon
                  color="primary"
                  @click="changeFavoritePic(item.id)"
                >
                  <v-icon>mdi-camera-plus</v-icon>
                </v-btn>
              </v-avatar>
              <input
                :ref="item.id"
                type=file
                accept="image/*"
                style="display: none;"
                @change="majFavoritePic(item, $event);"
              />
            </template>
            <template #title>
              <v-text-field
                class="nom"
                :value="item.nom"
                @focus="$event.target.select()"
                @change="majNom(item, $event)"
                :loading="loadingPerfVet"
                :disabled="loadingPerfVet"
                :key="componentKey"
                >
              </v-text-field>
            </template>
            <template #propertyA>
              <div>
                <v-tooltip bottom v-if=" !item.levelParam && item.levelParam !==0 ">
                  <template v-slot:activator="{ on }">
                    <v-icon v-on="on" style="color: #2D4388">mdi-information-variant-box</v-icon>
                  </template>
                  <span> {{item.libelleDomaine}} / {{item.libelleCategorie}} /
                    {{item.libelleType}}</span>
              </v-tooltip>
              </div>
            </template>
            <template #propertyB>
              <div>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  :color="getPerformance(item).color"
                    @click="showDialog('performance', item)"
                  :loading="loadingPerfVet"
                >
                {{getPerformance(item).text}}
                </v-btn>

              </div>
            </template>
            <template #propertyC>
              <div>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  small
                  :color="getVetuste(item).color"
                    @click="showDialog('vetuste', item)"
                  :loading="loadingPerfVet"
                >{{getVetuste(item).text}}
                </v-btn>
              </div>
            </template>
            <template #propertyD>
              <div class="field-cle-repart">
                <v-text-field
                  :value="getRepartition(item)"
                  suffix="%"
                  type='number'
                  @focus="$event.target.select()"
                  @change="majRepartition(item, $event)"
                  :loading="loadingPerfVet"
                  :disabled="loadingPerfVet || !screenRepartition(item)"
                  prepend-icon="mdi-chart-arc"
                  >
                </v-text-field>
              </div>
            </template>
          </ListItemWithButtons>
      </template>
      <template v-slot:append="{ item }">

        <div v-if="item.levelParam === 0" class="header-button-group">
          <div>
            <v-btn
              icon
              color="#2D4388"
              :disabled = "!checkedEquipements.length "
              @click="duplicateCheckedEquipements()"
            >
              <v-icon>
                mdi-content-duplicate
              </v-icon>
            </v-btn>
            <v-btn
              icon
              color="error"
              :disabled = "!checkedEquipements.length "
              @click="deleteCheckedEquipements()"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>
            <v-btn
              icon
              @click="openPanel()">
              <v-icon>
                {{arrow}}
              </v-icon>
            </v-btn>
          </div>
        </div>
        <div v-if="item.levelParam === 1">
          <span v-if="getFirstParamRepartition(item)" class="repartition-counter">
            <v-icon
              color="#2D4388">mdi-chart-arc
            </v-icon>
            <span class="label-counter">{{ getFirstParamRepartition(item) }}% </span>
          </span>
          <span>
            <span class="label-counter">{{ countFirstParamEquipements(item) }} </span>
            <span class="litle-label">  équipement(s)</span>
          </span>
          <v-icon v-if="openIds.includes(item.id)">
            mdi-chevron-up
          </v-icon>
          <v-icon v-else>
            mdi-chevron-down
          </v-icon>
        </div>
        <div v-if="item.levelParam === 2">
          <span v-if="getSecondParamRepartition(item)" class="repartition-counter">
            <v-icon
              color="#2D4388">mdi-chart-arc
            </v-icon>
            <span class="label-counter">{{ getSecondParamRepartition(item) }}% </span>
          </span>
          <span>
            <span class="label-counter">{{ item.children.length }} </span>
            <span class="litle-label">  équipement(s)</span>
          </span>
          <v-icon v-if="openIds.includes(item.id)">
            mdi-chevron-up
          </v-icon>
          <v-icon v-else>
            mdi-chevron-down
          </v-icon>
        </div>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import api from '@/api';
import ImageResizeMixin from '@/mixins/ImageResizeMixin';
import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';
import ListItemWithButtons from './ListItemWithButtons.vue';

export default {
  mixins: [
    UpdateItemAlertMixin,
    ImageResizeMixin,
  ],
  components: {
    ListItemWithButtons,
  },
  props: ['parameters', 'whichList', 'deselectItem'],
  computed: {
    ...mapState({
      loadingPerfVet: 'loadingPerfVet',
    }),
    ...mapState('equipements', { equipementTypes: 'types' }),
    ...mapState('equipements', { equipementDomaines: 'domaines' }),
    ...mapState('equipements', { equipementCategories: 'categories' }),
    ...mapGetters({
      selectedBatiment: 'batiments/selected',
      selectedMission: 'missions/selected',
      batimentEquipements: 'equipements/selectedBatimentEquipements',
      selectedEquipement: 'equipements/selected',
      getEquipementFormTemplate: 'formTemplates/getEquipementFormTemplate',
    }),
    selected() {
      if (!this.active.length) return undefined;
      const idItem = this.active[0];
      if (this.selectedEquipementId !== this.active[0]) {
        this.$store.dispatch('changeSelectedItem', {
          type: 'equipements',
          id: idItem,
        });
      }
      return this.batimentEquipements.find(equipement => equipement.id === idItem);
    },
    selectedEquipementId() {
      if (this.selectedEquipement) {
        return this.selectedEquipement.id;
      }
      return null;
    },
    attributesList() {
      const list = [];
      if (!this.batimentEquipements.length) {
        return list;
      }
      this.batimentEquipements.forEach((equipement) => {
        const equipementsTypeSelected = this.equipementTypes.filter(
          item => item.id === equipement.type,
        );
        if (equipementsTypeSelected[0]
          && equipementsTypeSelected[0].formTemplate[0] && equipement.attributs) {
          Object.keys(equipement.attributs).forEach((attribut) => {
            const attributType = equipementsTypeSelected[0].formTemplate[0].fields.filter(
              item => item.name === attribut,
            );
            if (attributType[0]) {
              list.push({
                typeAttribut: 'spécifique',
                libelle: attributType[0].label,
                code: attribut,
              });
            }
          });
        }
      });
      list.push({ typeAttribut: 'général', libelle: 'Description', code: 'description' });
      list.push({ typeAttribut: 'général', libelle: 'En alerte', code: 'inAlert' });
      list.push({ typeAttribut: 'général', libelle: 'Catégorie', code: 'libelleCategorie' });
      list.push({ typeAttribut: 'général', libelle: 'Domaine', code: 'libelleDomaine' });
      list.push({ typeAttribut: 'général', libelle: 'Type', code: 'libelleType' });
      list.push({ typeAttribut: 'général', libelle: 'Localisation local', code: 'local' });
      list.push({ typeAttribut: 'général', libelle: 'Niveau', code: 'niveau' });
      list.push({ typeAttribut: 'général', libelle: 'Performance', code: 'performance' });
      list.push({ typeAttribut: 'général', libelle: 'Vétusté', code: 'vetuste' });
      list.push({ typeAttribut: 'général', libelle: 'Nom', code: 'nom' });
      return list;
    },
    identicName() {
      const valueArr = this.batimentEquipements.map(item => item.nom);
      const findDuplicates = test => test.filter((item, index) => test.indexOf(item) !== index);
      return [...new Set(findDuplicates(valueArr))];
    },
    hasPontThermique() {
      const valueArr = this.batimentEquipements.filter(item => item.type.includes('batipontsthermiquespontsthermiques'));
      if (valueArr.length) {
        return true;
      }
      return false;
    },
    fullTable() {
      if (!this.batimentEquipements.length) {
        return [];
      }
      let i = 1;
      const tree = [];
      const indexes = new Map();
      this.batimentEquipements.forEach((equipement) => {
        let parametreUn = equipement.libelleDomaine;
        let parametreDeux = equipement.libelleCategorie;
        // extract libelle
        if (this.firstParameter) {
          if (this.firstParameter.typeAttribut === 'général') {
            parametreUn = equipement[this.firstParameter.code];
          } else {
            parametreUn = equipement.attributs[this.firstParameter.code];
          }
        }
        if (this.secondParameter) {
          if (this.secondParameter.typeAttribut === 'général') {
            parametreDeux = equipement[this.secondParameter.code];
          } else {
            parametreDeux = equipement.attributs[this.secondParameter.code];
          }
        }


        // if the parametreUn doesn't exist in the table
        // add it and store his index the map
        if (parametreUn === null || parametreUn === undefined) {
          parametreUn = '(Non défini)';
        }
        if (indexes.get(parametreUn) === undefined) {
          const parametreUnObject = {
            id: i += 1,
            name: parametreUn,
            children: [],
            levelParam: 1,
          };
          const paramUnIndex = tree.push(parametreUnObject) - 1;
          indexes.set(parametreUn, paramUnIndex);
        }

        // if the parametreDeux doesn't exist in the table
        // add it and store his index in the map
        if (parametreDeux === null || parametreDeux === undefined) {
          parametreDeux = '(Non défini)';
        }
        const parameters = `${parametreUn}:${parametreDeux}`;
        if (indexes.get(parameters) === undefined) {
          const parametreDeuxObject = {
            id: i += 1,
            name: parametreDeux,
            children: [],
            levelParam: 2,
          };
          const paramDeuxIndex = tree[indexes.get(parametreUn)]
            .children.push(parametreDeuxObject) - 1;
          indexes.set(parameters, paramDeuxIndex);
        }

        // add the equipement in the right parametreUn and in the right parametreDeux
        const equipementForSearch = {
          ...equipement,
          name: equipement.nom,
        };
        tree[indexes.get(parametreUn)]
          .children[indexes.get(parameters)]
          .children.push(equipementForSearch);
      });
      const treeFull = [{
        id: 1, name: 'Sélectionner tout', children: tree, levelParam: 0,
      }];
      return treeFull;
    },
  },
  data() {
    return {
      buttonTextDisplay: 'Afficher détails',
      showHeaderFields: false,
      expanded: false,
      active: [],
      arrow: 'mdi-chevron-down',
      firstParameter: null,
      secondParameter: null,
      checkedEquipements: [],
      componentKey: 0,
      warnings: [],
      search: null,
      openIds: [1],
      loading: false,
      linkedEquipementsSelect: [],
    };
  },
  async created() {
    this.$emit('loadingTreeView', true);
    this.$emit('fabButtonLoading', true);
    this.populateWarninIdenticName();
    this.loading = true;
    this.firstParameter = this.parameters.paramUn;
    // { typeAttribut: 'général', libelle: 'Domaine', code: 'libelleDomaine' };
    this.secondParameter = this.parameters.paramDeux;
    // { typeAttribut: 'général', libelle: 'Catégorie', code: 'libelleCategorie' };
    await this.$store.dispatch('equipements/fetchAllByBatiment', this.selectedBatiment.id);
    this.loading = false;
    await this.$store.dispatch('equipements/fetchAllRef', { missionId: this.selectedMission.id, majRef: false });
    this.$emit('loadingTreeView', false);
    this.$emit('fabButtonLoading', false);
  },
  watch: {
    deselectItem() {
      this.active = [];
    },
    hasPontThermique() {
      this.populateWarninIdenticName();
    },
    identicName() {
      this.populateWarninIdenticName();
    },
    async selectedEquipement(newValue) {
      this.linkedEquipementsSelect = await this.getEquipementByDomaine(newValue);
      this.$emit('linked-equipements', this.linkedEquipementsSelect);
    },
  },
  methods: {
    displayHeaders() {
      this.showHeaderFields = !this.showHeaderFields;
      if (this.showHeaderFields) {
        this.buttonTextDisplay = 'Masquer détails';
      } else {
        this.buttonTextDisplay = 'Afficher détails';
      }
    },
    onOpen() {
      if (!this.openIds.includes(1)) {
        this.openIds.push(1);
      }
    },
    changeFavoritePic(key) {
      this.$refs[key].click();
    },
    async getLocalPicture(pictureId) {
      const blobFile = await api.pictures.getFile(pictureId);
      return URL.createObjectURL(blobFile);
    },
    async majFavoritePic(equipement, evt) {
      const pictureList = [];
      // this.isLoading = true;
      // convert picture to formData and rezise it
      const file = evt.target.files[0];
      const resizedImage = await this.resizeImageMx(file, 1200, 1200);
      const savedImage = await api.pictures
        .create(resizedImage, 'equipements', equipement.id);

      this.$store.dispatch('snackbar/displaySnack',
        {
          message: 'photo ajoutée avec succès',
          type: 'succes',
        });
      // this.isLoading = false;

      // if the save failed
      if (savedImage._id) {
        // get the local picture from pouchDB and udpate the list
        const savedLocalUrl = await this.getLocalPicture(savedImage._id);
        pictureList.push({
          id: savedImage._id,
          url: savedLocalUrl,
        });
        this.$store.dispatch('updatePicturesToSync');
      } else {
        // else juste take the contentUrl field from the server response
        pictureList.push({
          id: savedImage.contentUrl.slice(0, -5),
          url: savedImage.contentUrl,
        });
        this.$store.dispatch(
          'equipements/save',
          {
            id: equipement.id,
            data: {
              ...equipement,
              imagePrincipale: savedImage.contentUrl.slice(0, -5),
            },
          },
        );
      }
    },
    showDialog(item, equipement) {
      this.$emit('showDialogPerfVet', { itemType: item, selectedEquipement: equipement });
    },
    majNom(equipement, event) {
      if (event === '') {
        this.$store.dispatch('snackbar/displaySnack',
          {
            message: 'Erreur le nom ne doit pas être vide.',
            type: 'error',
          });
        this.$store.dispatch('changeLoadingPerfVet', false);
        this.componentKey += 1;
      } else {
        this.$store.dispatch(
          'equipements/save',
          {
            id: equipement.id,
            data: {
              ...equipement,
              nom: event,
            },
          },
        ).then(() => {
          this.$store.dispatch('snackbar/displaySnack',
            { message: 'Sauvegardé avec succès !', type: 'succes' });
          this.$store.dispatch('changeLoadingPerfVet', false);
        }).catch(() => {
          this.$store.dispatch('snackbar/displaySnack',
            {
              message: 'Echec de sauvegarde',
              type: 'error',
            });
          this.$store.dispatch('changeLoadingPerfVet', false);
        });
      }
    },
    majRepartition(equipement, event) {
      this.$store.dispatch('changeLoadingPerfVet', true);
      if (equipement.attributs) {
        let findAlert = false;
        if (event === '') {
          findAlert = true;
        } else if (equipement.inAlert) {
          const fieldNecessary = [];
          this.getEquipementFormTemplate(equipement, this.selectedMission.id).fieldGroups.forEach(
            fieldgroup => fieldgroup.fields.forEach(
              (field) => {
                if (field.rules && field.rules.includes('necessary') && !field.name.startsWith('clederepartition')) {
                  fieldNecessary.push(field.name);
                }
              },
            ),
          );

          let i = 0;
          while (i < fieldNecessary.length && !findAlert) {
            if (fieldNecessary[i] !== 'performance'
                && fieldNecessary[i] !== 'vetuste'
                && equipement.attributs
                && equipement.attributs[fieldNecessary[i]] === null) {
              findAlert = true;
            } else if (!equipement.performance) {
              findAlert = true;
            } else if (!equipement.vetuste) {
              findAlert = true;
            }
            i += 1;
          }
        }
        const payload = {
          ...equipement.attributs,
        };
        if (equipement.attributs.clederepartitionsv !== undefined) {
          payload.clederepartitionsv = parseInt(event, 10);
        }
        if (equipement.attributs.clederepartitionsop !== undefined) {
          payload.clederepartitionsop = parseInt(event, 10);
        }
        if (equipement.attributs.clederepartitionsph !== undefined) {
          payload.clederepartitionsph = parseInt(event, 10);
        }
        if (equipement.attributs.clederepartitionspb !== undefined) {
          payload.clederepartitionspb = parseInt(event, 10);
        }
        if (equipement.attributs.clederepartition !== undefined) {
          payload.clederepartition = parseInt(event, 10);
        }
        this.$store.dispatch(
          'equipements/save',
          {
            id: equipement.id,
            data: {
              ...equipement,
              attributs: {
                ...payload,
              },
              inAlert: findAlert,
            },
          },
        ).then(() => {
          this.$store.dispatch('snackbar/displaySnack',
            { message: 'Sauvegardé avec succès !', type: 'succes' });
          this.$store.dispatch('changeLoadingPerfVet', false);
          this.majBatimentAlert(equipement.batiment, 'equipements');
          // this.$store.dispatch('equipements/fetchAllByBatiment', this.selectedBatiment.id);
        }).catch(() => {
          this.$store.dispatch('snackbar/displaySnack',
            {
              message: 'Echec de sauvegarde',
              type: 'error',
            });
          this.$store.dispatch('changeLoadingPerfVet', false);
        });
      }
    },

    checkNecessaryNotBlankSecondParam(secondParam) {
      let i = 0;
      let findAlert = false;
      while (i < secondParam.children.length && !findAlert) {
        if (secondParam.children[i].inAlert) {
          findAlert = true;
        }
        i += 1;
      }
      return findAlert;
    },

    checkNecessaryNotBlankFirstParam(firstParam) {
      let i = 0;
      let findAlert = false;
      while (i < firstParam.children.length && !findAlert) {
        if (this.checkNecessaryNotBlankSecondParam(firstParam.children[i])) {
          findAlert = true;
        }
        i += 1;
      }
      return findAlert;
    },

    screenRepartition(equipement) {
      if (equipement.attributs && typeof equipement.attributs.clederepartitionsv !== 'undefined') {
        return true;
      }
      if (equipement.attributs && typeof equipement.attributs.clederepartitionsop !== 'undefined') {
        return true;
      }
      if (equipement.attributs && typeof equipement.attributs.clederepartitionsph !== 'undefined') {
        return true;
      }
      if (equipement.attributs && typeof equipement.attributs.clederepartitionspb !== 'undefined') {
        return true;
      }
      if (equipement.attributs && typeof equipement.attributs.clederepartition !== 'undefined') {
        return true;
      }

      return false;
    },

    getRepartition(equipement) {
      if (equipement.attributs) {
        if (equipement.attributs.clederepartitionsv) {
          return equipement.attributs.clederepartitionsv;
        }
        if (equipement.attributs.clederepartitionsop) {
          return equipement.attributs.clederepartitionsop;
        }
        if (equipement.attributs.clederepartitionsph) {
          return equipement.attributs.clederepartitionsph;
        }
        if (equipement.attributs.clederepartitionspb) {
          return equipement.attributs.clederepartitionspb;
        }
        if (equipement.attributs.clederepartition) {
          return equipement.attributs.clederepartition;
        }
      }
      return false;
    },

    getPerformance(equipement) {
      if (equipement.performance) {
        switch (equipement.performance) {
          case '0-performance':
            return { color: 'red', text: 'P0' };
          case '1-performance':
            return { color: 'orange', text: 'P1' };
          case '2-performance':
            return { color: 'yellow', text: 'P2' };
          case '3-performance':
            return { color: 'green', text: 'P3' };
          case 'NC-performance':
            return { color: 'gray', text: 'NC' };
          default:
            return { color: null, text: null };
        }
      }
      return { color: null, text: null };
    },

    getVetuste(equipement) {
      if (equipement.vetuste) {
        switch (equipement.vetuste) {
          case '0- À remplacer':
            return { color: 'red', text: 'V0' };
          case '1- État d\'usage':
            return { color: 'orange', text: 'V1' };
          case '2- Bon état':
            return { color: 'yellow', text: 'V2' };
          case '3- État neuf':
            return { color: 'green', text: 'V3' };
          case 'NC- Non concerné':
            return { color: 'gray', text: 'NC' };
          default:
            return false;
        }
      }
      return false;
    },

    getSecondParamRepartition(item) {
      if (item.children) {
        const eqpsWithRepartition = item.children
          .filter(eqp => this.getRepartition(eqp));
        if (eqpsWithRepartition.length !== 0) {
          return item.children
            .map(eqp => this.getRepartition(eqp))
            .reduce((prev, cur) => prev + cur);
        }
      }
      return false;
    },

    getFirstParamRepartition(item) {
      if (item.children) {
        const catsWithRepartition = item.children
          .filter(cat => this.getSecondParamRepartition(cat));
        if (catsWithRepartition.length !== 0) {
          const ret = item.children
            .map(cat => this.getSecondParamRepartition(cat))
            .reduce((prev, cur) => prev + cur);
          return Math.round(ret / catsWithRepartition.length);
        }
      }
      return false;
    },
    getEquipementByDomaine(equipement) {
      let equiDomaine = null;
      if (equipement) {
        equiDomaine = this.batimentEquipements.filter(equip => equip
          .libelleDomaine === equipement.libelleDomaine);
      }
      return equiDomaine;
    },
    countFirstParamEquipements(item) {
      return item.children
        .map(child => child.children)
        .flat()
        .length;
    },
    deleteCheckedEquipements() {
      this.$emit('delete-equipements', this.checkedEquipements);
    },
    duplicateCheckedEquipements() {
      this.$emit('duplicate-equipements', this.checkedEquipements);
    },
    openPanel() {
      if (!this.panel) {
        this.expanded = true;
        this.panel = true;
        this.arrow = 'mdi-chevron-up';
        this.$refs.myTreeview.updateAll(this.expanded);
      } else {
        this.expanded = false;
        this.$refs.myTreeview.updateAll(this.expanded);
        this.openIds = [1];
        this.panel = false;
        this.arrow = 'mdi-chevron-down';
      }
    },

    populateWarninIdenticName() {
      this.warnings = [];
      if (!this.hasPontThermique) {
        this.warnings.push('Attention, l\'inventaire ne contient pas de ponts thermiques.');
      }
      if (this.identicName.length) {
        let warningIdenticName = 'Attention, plusieurs équipements disposent du même nom : ';
        this.identicName.forEach((nameItem) => {
          warningIdenticName = `${warningIdenticName}${nameItem}, `;
        });
        warningIdenticName = warningIdenticName.substring(0, warningIdenticName.length - 2);
        warningIdenticName = `${warningIdenticName}. Afin de pouvoir les différencier, il est conseillé de modifier leur nom.`;
        this.warnings.push(warningIdenticName);
      }
      this.$emit('warnings', this.warnings);
    },

    majParameters(event) {
      if (event === '') {
        this.$store.dispatch('snackbar/displaySnack',
          {
            message: 'Erreur le paramètre ne doit pas être vide.',
            type: 'error',
          });
      } else {
        const organisationListeEquipement = JSON.parse(JSON.stringify(this.selectedBatiment
          .organisationListeEquipement));
        if (this.whichList === 1) {
          organisationListeEquipement.paramUnListeUne = this.firstParameter;
          organisationListeEquipement.paramDeuxListeUne = this.secondParameter;
        } else if (this.whichList === 2) {
          organisationListeEquipement.paramUnListeDeux = this.firstParameter;
          organisationListeEquipement.paramDeuxListeDeux = this.secondParameter;
        }
        const batiment = this.selectedBatiment;
        this.$store.dispatch(
          'batiments/save',
          {
            id: batiment.id,
            data: {
              ...batiment,
              organisationListeEquipement,
            },
          },
        ).then(() => {
          this.$store.dispatch('snackbar/displaySnack',
            { message: 'Sauvegardé avec succès !', type: 'succes' });
          this.$store.dispatch('changeLoadingPerfVet', false);
        }).catch(() => {
          this.$store.dispatch('snackbar/displaySnack',
            {
              message: 'Echec de sauvegarde',
              type: 'error',
            });
          this.$store.dispatch('changeLoadingPerfVet', false);
        });
      }
    },
  },
};
</script>
<style lang="scss">
  .btn-display-header {
    width: 12rem;
    border-radius: 0;
    padding:10rem;
  }

  button.v-treeview-node__toggle {
    display: none !important;
  }
  .v-input__icon--prepend .v-icon {
    color: $app-blue;
  }
  .v-treeview-node__root {
   box-shadow: 0px -5px $app-grey;
   background-color: white;
  }
  .v-treeview-node__root:hover {
    background-color: rgba(45, 67, 136, .1);
  }
  .v-treeview-node__toggle {
  }
  .parameter {
    margin-right: 1rem !important;
  }
  .parameter-lists {
    display:flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    margin: 1rem 1rem 0 1rem !important;
  }
  .wrapper {
    height: 100%;
    width: 100%;
    padding: 0 0 6rem 0;
    background-color: $app-grey;
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: stretch;
  }

  .field-cle-repart {
    width: 5rem;
    text-align: left;
  }

  .delete-group {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-left: 54px;
  }

  .header-button-group {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  .litle-label {
    font-size: 10px;
    color: grey;
  }
  .repartition-counter {
    margin:40px;
  }
  .label-counter {
    font-weight: bold;
  }
  .root-label {
    font-weight: 300;
    font-size: 0.9rem;
    color: rgba(0, 0, 0, .87);
    width: 100%;
    display:block;
  }
  .first-param-label {
    font-weight: 500;
    font-size: 1.3rem;
  }
  .second-param-label {
    font-size: 1.1rem;
  }
  .research {
    margin: 0 1rem 1rem 1rem;
  }
  .v-input--checkbox .v-label {
    font-size: 12px;
  }
  .undefined-label {
    font-style: italic;
    font-weight: 300;
  }
  .headerFields {
    background-color: white;
    border-left: 2px solid #2D4388;
    border-bottom: 2px solid #2D4388;
    border-right: 2px solid #2D4388;
  }
</style>
