 <template>
  <v-dialog
    v-model="show"
    width="500"
  >
    <v-card>
      <v-card-title class="headline">Téléchargement du rapport en cours</v-card-title>

      <v-card-text>
        Votre rapport est en cours de téléchargement, ceci peut prendre plusieurs minutes.
        Vous pouvez continuer d'utiliser SMART DIAG en parallèle.
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          depressed
          @click="$emit('close')"
        >
          ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
