<template>
  <v-bottom-sheet
    v-model="sheet"
    persistent
    fullscreen
  >

    <v-sheet class="sheet-wrapper">
      <h2 class="fixed-title">Ajouter un nouvel équipement</h2>
      <v-autocomplete class="search-field" ref="searchField"
        v-model="selectedType"
        :items="equipementTypes"
        :loading="isLoading"
        hide-no-data
        hide-selected
        item-text="nom"
        item-value="id"
        label="Rechercher un type d'équipement"
        placeholder="Saisissez du texte pour rechercher un type"
        prepend-inner-icon="mdi-database-search"
        return-object
        :hint="hint"
        :persistent-hint="isLoading"
        clear-icon="mdi-close-circle-outline"
        outlined
        :filter="customFilter"
      >
        <template #item="{ item }">
          <div>{{ item.nom }} ({{getDomaine(item)}})</div>
        </template>
      </v-autocomplete>
      <div class="card-list">
        <DomainesList  v-if="afficherDomaine"></DomainesList>
        <CategoriesList v-if="afficherCategory"></CategoriesList>
        <TypesList v-if="afficherType"></TypesList>

      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-form class="form" v-if="selectedType">
          <MultiPicturesField
          @addPicturesNewEquipements="addPicsNewEquipement($event)">
          </MultiPicturesField>
          <form-builder
            :itemType="'equipements'"
            :addEquipements="true"
            :item="{ type: selectedType.id.toLowerCase(), nom: selectedType.nom }"
            :key="`form_${selectedType.id}`"
          >
          </form-builder>
        </v-form>
        <div class="bottom-sheet-footer">
          <v-btn
            class="action-button"
            text
            color="primary"
            v-if="goBackDomainList()"
            @click="clearSelectedDomaine()"
          >
            Retour
          </v-btn>
          <v-btn
            class="action-button"
            text
            color="primary"
            v-if="goBackCategoryList()"
            @click="clearSelectedCategory()"
          >
            Retour
          </v-btn>
          <v-btn
            class="action-button"
            text
            color="primary"
            v-if="goBackTypeList()"
            @click="clearSelectedType()"
          >
            Retour
          </v-btn>
          <v-btn
            class="action-button"
            text
            color="primary"
            @click="cancel()"
          >
            Annuler
          </v-btn>
          <v-btn
            class="action-button"
            depressed
            color="primary"
            @click.native="saveForm(false)"
            :disabled="invalid"
            :loading="saving"
            v-if="goBackTypeList()"
          >
            Créer
          </v-btn>
          <v-btn
            class="action-button"
            depressed
            color="primary"
            @click.native="saveForm(true)"
            :disabled="invalid"
            :loading="saving"
            v-if="goBackTypeList()"
          >
            Créer et éditer
            </v-btn>
          </div>
      </ValidationObserver>
      </div>
  </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import api from '@/api';
import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';
import MultiPicturesField from './fields/multi-pictures-field-add-eqp.vue';
import DomainesList from './DomainesList.vue';
import CategoriesList from './CategoriesList.vue';
import TypesList from './TypesList.vue';
import FormBuilder from './form-builder.vue';


export default {
  mixins: [
    UpdateItemAlertMixin,
  ],
  mounted() {
    if (this.isComputer()) {
      this.$refs.searchField.focus();
    }
  },
  computed: {
    ...mapState(['currentForm', 'selectedDomaine', 'selectedCategorie']),
    ...mapState('equipements', { equipementTypes: 'types' }),
    ...mapState('equipements', { domaines: 'domaines' }),
    ...mapState('equipements', { categories: 'categories' }),
    ...mapGetters({
      selectedMission: 'missions/selected',
      selectedBatiment: 'batiments/selected',
      getEquipementFormTemplate: 'formTemplates/getEquipementFormTemplate',
    }),

    hint() {
      return this.isLoading ? 'récupération de la liste...' : '';
    },

    /* listeTypeByMission() {
      const equipementMission = this.equipementTypes
        .filter(equipementType => equipementType.mission === this.selectedMission.id);
      //  Filter type of equipment on categories
      let equipementTypes = [];
      this.domaines.forEach((domaine) => {
        equipementTypes = equipementTypes.concat(equipementMission
          .filter(equipementType => domaine.categories.find(categorie => categorie
                                     === equipementType.categorie)
                                    && equipementType.formTemplate.length > 0
                                    && typeof equipementType.formTemplate.find(attribute =>
                                    attribute.name === 'attributsspecifiques') !== 'undefined'));
      });
      return equipementTypes;
    }, */
    selectedType: {
      get() {
        return this.$store.state.selectedType;
      },
      set(value) {
        this.$store.commit('setSelectedType', value);
      },
    },
    selectedCategorie: {
      get() {
        return this.$store.state.selectedCategorie;
      },
      set(value) {
        this.$store.commit('setSelectedCategorie', value);
      },
    },
    selectedDomaine: {
      get() {
        return this.$store.state.selectedDomaine;
      },
      set(value) {
        this.$store.commit('setSelectedDomaine', value);
      },
    },


    afficherDomaine() {
      return (!this.isLoading
      && !this.selectedType
      && !this.selectedDomaine);
    },

    afficherCategory() {
      return (!this.isLoading
      && !this.selectedType
      && !this.selectedCategorie
      && this.selectedDomaine);
    },

    afficherType() {
      return (!this.isLoading
      && !this.selectedType
      && this.selectedCategorie
      && this.selectedDomaine);
    },
  },
  components: {
    DomainesList,
    TypesList,
    CategoriesList,
    FormBuilder,
    ValidationObserver,
    MultiPicturesField,
  },
  data() {
    return {
      isLoading: false,
      saving: false,
      picturesList: Object,
      imagesId: [],
    };
  },
  props: ['sheet'],
  methods: {
    saveForm(edit) {
      this.saving = true;

      // convert all form fields to string
      const formCopy = JSON.parse(JSON.stringify(this.currentForm));

      const newEquipementPayload = {
        batimentId: this.selectedBatiment.id,
        equipementType: this.selectedType,
      };

      this.$store.dispatch('equipements/createEquipementOnBatiment', newEquipementPayload)
        .then((newItem) => {
          this.$store.dispatch('formTemplates/fetchEquipementTemplate', {
            type: newItem.type,
            missionId: this.selectedMission.id,
          }).then((response) => {
            const attributes = [];
            const fieldNecessary = [];
            let findBlank = false;
            response.formTemplate[0].fields.forEach((field) => {
              attributes[field.name] = null;
              if (field.rules && field.rules.includes('necessary')) {
                fieldNecessary.push(field.name);
              }
            });
            let i = 0;
            if (edit) {
              while (i < fieldNecessary.length && !findBlank) {
                if (fieldNecessary[i] !== 'performance'
                  && fieldNecessary[i] !== 'vetuste'
                  && formCopy.attributs
                  && formCopy.attributs[fieldNecessary[i]] === null) {
                  findBlank = true;
                } else if (!formCopy.performance) {
                  findBlank = true;
                } else if (!formCopy.vetuste) {
                  findBlank = true;
                }
                i += 1;
              }
            } else if (!formCopy.performance) {
              findBlank = true;
            } else if (!formCopy.vetuste) {
              findBlank = true;
            }
            this.$store.dispatch(
              'equipements/save',
              {
                id: newItem.id,
                data: {
                  ...formCopy,
                  libelleDomaine: newItem.libelleDomaine,
                  libelleCategorie: newItem.libelleCategorie,
                  libelleType: newItem.libelleType,
                  linkedEquipements: [],
                  inAlert: findBlank,
                  attributs: {
                    ...attributes,
                  },
                },
              },
            ).then(async (result) => {
              this.$store.dispatch('snackbar/displaySnack', { message: 'créé avec succès !', type: 'succes' });
              this.majBatimentAlert(result.batiment, 'equipements');

              this.clearSelectedDomaine();
              this.clearSelectedCategory();
              this.clearSelectedType();

              await this.$store.dispatch('changeSelectedItem', { id: result.id, type: 'equipements' }).then(() => {
                if (this.pictures) {
                  this.saveImages(result.id, edit);
                } else if (edit) {
                  this.$emit('open-edit-modal', result.id);
                  this.$emit('close-sheet');
                } else {
                  this.$emit('close-sheet');
                }
              });
            }).finally(() => {
              this.saving = false;
            });
          });
        });
    },

    async saveImages(id, edit) {
      await Promise.all(this.pictures.map(async (picture) => {
        await api.pictures.create(picture, 'equipements', id);
      }));
      this.$store.dispatch('equipements/refreshItem', id);
      this.$store.dispatch('updatePicturesToSync');
      if (edit) {
        this.$emit('open-edit-modal', id);
      }
      this.$emit('close-sheet');
    },

    async getLocalPicture(pictureId) {
      const blobFile = await api.pictures.getFile(pictureId);
      return URL.createObjectURL(blobFile);
    },

    cancel() {
      this.clearSelectedDomaine();
      this.clearSelectedCategory();
      this.clearSelectedType();
      this.$emit('close-sheet');
      this.$store.dispatch('changeSelectedItem', { id: this.selectedBatiment.id, type: 'batiments' });
    },

    clearSelectedDomaine() {
      this.$store.dispatch('selectDomaine', '');
    },

    clearSelectedCategory() {
      this.$store.dispatch('selectCategorie', '');
    },

    clearSelectedType() {
      this.$store.dispatch('selectType', '');
    },

    goBackTypeList() {
      return (this.selectedDomaine && this.selectedCategorie && this.selectedType);
    },

    goBackCategoryList() {
      return (this.selectedDomaine && this.selectedCategorie && !this.selectedType);
    },

    goBackDomainList() {
      return (this.selectedDomaine && !this.selectedCategorie);
    },

    addPicsNewEquipement(picturesList) {
      this.pictures = picturesList;
    },

    isComputer() {
      const mediaQuery = window.matchMedia('(min-width: 1024px)');

      return mediaQuery.matches;
    },
    getDomaine(type) {
      const categorie = this.categories.find(e => e.id === type.categorie);
      if (categorie) {
        const domaine = this.domaines.find(e => e.id === categorie.domaine);
        if (domaine) {
          return domaine.nom;
        }
      }
      return null;
    },
    customFilter(item, queryText) {
      const itemNormalized = item.nom.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      const searchText = queryText.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '');
      return itemNormalized.includes(searchText);
    },
  },
  watch: {
    sheet(newValue, oldValue) {
      // on each sheet opening
      if (newValue && !oldValue) {
        // this.$store.dispatch('clearSelectedItem');

        if (!this.equipementTypes.length) {
          this.isLoading = true;
          this.$store.dispatch('equipements/fetchAllTypes', { missionId: this.selectedMission.id, majRef: false })
            .then(() => {
              this.isLoading = false;
            });
        }
      }
    },
    selectedType(newValue) {
      const categorie = this.categories.find(e => e.id === newValue.categorie);
      if (categorie) {
        this.selectedCategorie = categorie;
        const domaine = this.domaines.find(e => e.id === categorie.domaine);
        if (domaine) {
          this.selectedDomaine = domaine;
        }
      }
    },

  },
};
</script>

<style lang="scss" scoped>
.form {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    > * {
      //flex-grow: 4;
      width:40%;
    }
}
  .sheet-wrapper {
    // padding: 1rem;
    height: 100%;
    overflow: auto;

    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  .card-list {
    min-height: 70vh;
    width: 100%;
  }

  .bottom-sheet-footer {
    min-height: 3rem;
    bottom: 0;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    text-align: center;
  }

  .fixed-title{
    position: fixed;
    height: 64px;
    top: 0;
    margin-top: 0;
    width:100%;
    background-color: #2D4388;
    z-index: 10;
    color:white !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .action-button {
    margin-right: 1rem;
  }

  .search-field {
    width: 80%;
    margin: 80px 0 20px 0;
    display:flex;
    justify-content: center;
    align-items: center;
  }
</style>
