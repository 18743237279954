import api from '@/api';

/* eslint no-console: ["error", { allow: ["error", "log"] }] */
/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

// const apiUrl = 'http://prediag-dev.westeurope.cloudapp.azure.com/api';

const state = {

  // Types of inspections
  types: [
  ],

  // All inspections
  inspections: [
  ],

  FormTemplates: [

  ],

  selectedId: null,
};

const getters = {
  toSync: state => state.inspections
    .filter(inspection => inspection.meta
      && (inspection.meta.toCreate || inspection.meta.toUpdate)),

  nbToSync: (state, getters) => {
    if (getters.toSync && getters.toSync.length) {
      return getters.toSync.length;
    }
    return 0;
  },

  selected: state => state.inspections.find(inspection => inspection.id === state.selectedId),

  selectedBatimentInspections: (state, getters, rootState, rootGetters) => {
    const batiment = rootGetters['batiments/selected'];
    if (batiment) {
      return state.inspections.filter(insp => insp.batiment === batiment.id);
    }
    return [];
  },

  selectedSiteInspections: (state, getters, rootState, rootGetters) => {
    const site = rootGetters['sites/selected'];
    if (site) {
      return state.inspections.filter(insp => insp.site === site.id && !insp.batiment);
    }
    return [];
  },

  selectedMissionBatimentInspectionTypes: (state, getters, rootState, rootGetters) => {
    const mission = rootGetters['missions/selected'];
    if (state.types && state.types.length) {
      return state.types.filter(type => type.mission === mission.id && type.objet === 'batiment');
    }
    return [];
  },

  selectedMissionSiteInspectionTypes: (state, getters, rootState, rootGetters) => {
    const mission = rootGetters['missions/selected'];
    if (state.types && state.types.length) {
      return state.types.filter(type => type.mission === mission.id && type.objet === 'site');
    }
    return [];
  },
};


const actions = {

  createNew({ commit }, parameters) {
    return new Promise((resolve) => {
      if (parameters.idBatiment) {
        const newInspection = {
          id: `tempInsp${Math.random()}`,
          inspectionType: parameters.idType,
          batiment: parameters.idBatiment,
          nomType: parameters.nomType,
          codeType: parameters.codeType,
          constats: [],
        };
        commit('addInspections', [newInspection]);
        resolve(newInspection);
      } else {
        const newInspection = {
          id: `tempInsp${Math.random()}`,
          inspectionType: parameters.idType,
          site: parameters.idSite,
          batiment: null,
          nomType: parameters.nomType,
          codeType: parameters.codeType,
          constats: [],
        };
        commit('addInspections', [newInspection]);
        resolve(newInspection);
      }
    });
  },

  save({ commit }, payload) {
    return new Promise((resolve, reject) => {
      if (payload.id.startsWith('tempInsp')) {
        api.inspections.create(payload.data, payload.id).then((response) => {
          commit('deleteInspection', payload.id);
          commit('addInspections', [response]);
          resolve({ ...response });
        }, error => reject(error));
      // else update it by put
      } else {
        api.inspections.update(payload.id, payload.data).then((response) => {
          commit('addInspections', [response]);
          resolve({ ...response });
        }, error => reject(error));
      }
    });
  },

  async synchroniseAll({ commit, dispatch }) {
    const syncInspections = await api.inspections.synchroniseAll();
    commit('clearInspections');
    await dispatch('loadOffline');
    return syncInspections;
  },

  async delete({ commit }, inspection) {
    // Delete equipment from the server & from the store
    await api.inspections.delete(inspection.id);
    commit('deleteInspection', inspection.id);
  },

  fetchTypesInspectionByMission({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      api.refInspectionTypes.getAllByMission(parameters.idMission)
        .then((response) => {
          commit('addTypeInspections', response);
          resolve([...response]);
        }, error => reject(error));
    });
  },

  fetchAllInspections({ commit }) {
    return api.inspections.getAll()
      .then(response => commit('addInspections', response));
  },

  fetchAllByBatiment({ commit }, batimentId) {
    return api.inspections.getAllByBatiment(batimentId)
      .then(response => commit('addInspections', response));
  },

  fetchAllBySite({ commit }, siteId) {
    return api.inspections.getAllBySite(siteId)
      .then(response => commit('addInspections', response));
  },

  clearTypes({ commit }) {
    commit('clearTypes');
  },

  /**
   * Fetch one inspection by it's id fron the server and update the store
   */
  refreshItem({ commit }, inspectionId) {
    return new Promise((resolve) => {
      api.inspections.get(inspectionId).then((response) => {
        commit('addInspections', [response]);
        resolve({ ...response });
      });
    });
  },

  /**
   * Load all niveaux stored offline in pouchDB
   */
  loadOffline({ commit }) {
    return api.inspections.loadOffline().then(response => commit('addInspections', response));
  },

  async changeSelected({
    state, getters, commit, dispatch, rootState,
  },
  inspectionId) {
    if (state.selectedId !== inspectionId) {
      commit('changeSelectedId', inspectionId);

      if (!getters.selected) {
        await dispatch('refreshItem', inspectionId);
      }

      if (getters.selected.site) {
        if (!rootState.sites.selected
          || rootState.sites.selected.id !== getters.selected.site) {
          await dispatch('sites/changeSelected', getters.selected.site, { root: true });
        }
      } else if (getters.selected.batiment) {
        if (!rootState.batiments.selected
          || rootState.batiments.selected.id !== getters.selected.batiment) {
          await dispatch('batiments/changeSelected', getters.selected.batiment, { root: true });
        }
      }
    }
  },

  async clearSelected({ commit }) {
    await commit('changeSelectedId', null);
  },
};

const mutations = {

  /* ----------------- inspections ----------------- */
  clearTypes(state) {
    state.types = [];
  },

  clearInspections(state) {
    state.inspections = [];
  },

  addInspections(state, newInspections) {
    newInspections.forEach((newInspection) => {
      const stateIndex = state.inspections.findIndex(
        stateInspection => stateInspection.id === newInspection.id,
      );
      if (stateIndex >= 0) {
        state.inspections.splice(stateIndex, 1, newInspection);
      } else {
        state.inspections.push(newInspection);
      }
    });
  },

  deleteInspection(state, inspectionId) {
    const index = state.inspections.findIndex(
      stateInspection => stateInspection.id === inspectionId,
    );
    if (index >= 0) {
      state.inspections.splice(index, 1);
    } else {
      console.error(`no inspection to delete with id : ${inspectionId}`);
    }
  },

  changeSelectedId(state, inspectionId) {
    state.selectedId = inspectionId;
  },

  /* ----------------- type inspections ----------------- */

  addTypeInspections(state, newTypeInspections) {
    newTypeInspections.forEach((newTypeInspection) => {
      const stateIndex = state.types.findIndex(
        stateTypeInspection => stateTypeInspection.id === newTypeInspection.id,
      );
      if (stateIndex >= 0) {
        state.types.splice(stateIndex, 1, newTypeInspection);
      } else {
        state.types.push(newTypeInspection);
      }
    });
  },

  deleteTypeInspection(state, typeId) {
    state.inspections.splice(state.types.findIndex(
      stateTypeInspection => stateTypeInspection.id === typeId,
    ), 1);
  },

};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
