/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  sitesEndpoint,
  interventionSitesEndpoint,
  interventionSitesUrl,
} from './apiUrls';

export default {
  db: new PouchDB('interventionSites'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch
    const _id = obj._id || obj.id.toString();

    const convertedObject = {
      ...obj,
      _id,
      meta: obj.meta || {},
      id: String(obj.id),
      site: obj.site ? obj.site.replace(`${sitesEndpoint}/`, '') : '',
      description: obj.description,
    };

    return convertedObject;
  },

  async create(interventionSite, interventionSiteId) {
    // on créé un objet uniquement pour la sauvegarde locale si pas de connexion
    const localInterventionSite = {
      id: interventionSiteId,
      ...interventionSite,
    };

    let savedInterventionSite = null;

    try {
      // on essaye de sauvegarder via l'API
      const response = await axiosInstance.axiosInstance
        .post(interventionSitesEndpoint, interventionSite);
      savedInterventionSite = this.convertBackendObject(response.data);
    } catch (e) {
      if (e.response
        && e.response.status >= 400
        && e.response.status < 500) {
        throw new Error(e);
      }
      // si la sauvegarde en ligne a échouée, on enregistre les données dans
      // le cache avec le flag 'toCreate' à true afin de relancer la synchro
      // quand on aura à nouveau une connexion
      return pouchUtil.addDoc(localInterventionSite, this.db, { toCreate: true });
    }

    // si la sauvegarde en ligne a fonctionné
    // si on avait un intervention avec un id temporaire dans pouchDB on le supprime
    if (interventionSiteId.startsWith('temp')) {
      try {
        await pouchUtil.deleteDoc(interventionSiteId, this.db);
      } catch (e) {
        // do nothing
      }
    }

    // enfin on sauvegarde dans pouchDB le nouveau intervention et on le retourne
    return pouchUtil.addDoc(savedInterventionSite, this.db);
  },

  async update() {
    // TODO
  },

  /**
   * synchronise a interventionSite with the server, creating or updating it
   */
  async synchronise() {
    // TODO
  },

  /**
   * synchronise with the server all interventions that need to be saved
   */
  async synchroniseAll() {
    // get all interventions in PouchDB with meta.create === true or meta.update === true
    // TODO
  },


  /**
   * delete a intervention from pouchDB and the server
   */
  async delete(InterventionSiteId) {
    // delete the intervention from pouchDB
    await pouchUtil.deleteDoc(InterventionSiteId, this.db);
    // if the delete from pouchDB is sucessfull, delete from the server
    return axiosInstance.axiosInstance.delete(`${interventionSitesEndpoint}/${InterventionSiteId}`);
  },

  /**
   * get one intervention by his id
   * @param {String} InterventionSiteId - the id of the intervention to get
   */
  async get(InterventionSiteId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${interventionSitesEndpoint}/${InterventionSiteId}`);
      const intervention = this.convertBackendObject(response.data);

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDoc(intervention, this.db);
    } catch (e) {
      console.error('interventionSitesApi get error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getDoc(InterventionSiteId, this.db);
    }
  },

  /**
   * get all children interventions of a batiment
   * @param {String} siteId - id of the parent batiment
   */
  async getAllBySite(siteId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${sitesEndpoint}/${siteId}${interventionSitesUrl}`);
      const interventionSites = response.data.map(
        interventionSite => this.convertBackendObject(interventionSite),
      );

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(interventionSites, this.db);
    } catch (e) {
      console.error('interventionSitesApi getAllBySite error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByField('batiment', siteId, this.db);
    }
  },

};
