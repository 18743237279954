<template>
  <div>
    <v-img
      class="picture-object"
      :src="src"
      :lazy-src="lazySrc"
      @click="openGallery()"
    >
      <!-- spinner loader for the picture -->
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
          :v-if="isLoading"
          :indeterminate="isLoading"
          color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>

      <!-- counter for pictures number -->
      <div
        class="picture-counter"
        v-if="pictureList && pictureList.length > 1"
      >
        <v-icon color="white" x-small>mdi-plus</v-icon>
        {{ pictureList.length - 1 }}
      </div>
    </v-img>

    <v-btn
      color="primary"
      class="btn-add-pics"
      @click.native="takePicture()"
    >
      Ajouter <v-icon class="button-icon">mdi-camera</v-icon>
    </v-btn>

    <!-- hidden input to upload photo -->
    <input
      ref="inputFile"
      type=file
      accept="image/*"
      style="display: none;"
      @change="handleFileChange($event);"
    />

    <!-- gallery to display pictures in fullscreen -->
    <Gallery
      :arrayPics="pictureList"
      :selectedPic="url"
      :openGalleryOne="openGallerySheet"
      parentKey="field"
      @closeGalleryOne="closeGallery"
      key="fieldGallery"
    >
    </Gallery>

  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import Gallery from '../gallery-pictures.vue';
import ImageResizeMixin from '../../mixins/ImageResizeMixin';

export default {
  name: 'multi-pictures-field',
  components: {
    Gallery,
  },
  mixins: [
    ImageResizeMixin,
  ],
  props: {
    defaultValue: Array,
  },
  data() {
    return {
      openGallerySheet: false,
      pictureList: [],
      isLoading: false,
      picturesToAddAfter: [],
    };
  },

  computed: {
    ...mapGetters(['selectedItem']),
    ...mapState(['selectedItemType']),

    picturesInArray() {
      return !!this.pictureList && !!this.pictureList.length;
    },

    src() {
      if (this.picturesInArray) {
        return this.pictureList[0].url;
      }
      return '';
    },

    // smaller version of the image to use whille loading 'big' image
    lazySrc() {
      if (this.picturesInArray && !this.pictureList[0].url.startsWith('blob')) {
        return `${this.pictureList[0].url}?size=xsmall`;
      }
      return undefined;
    },

    // url of the principal image
    url() {
      if (this.picturesInArray) {
        return `${this.pictureList[0].url}`;
      }
      return null;
    },
  },

  methods: {

    closeGallery() {
      this.openGallerySheet = false;
    },

    openGallery() {
      if (this.picturesInArray) {
        this.openGallerySheet = true;
      }
    },

    takePicture() {
      this.$refs.inputFile.click();
    },

    async handleFileChange(evt) {
      this.isLoading = true;
      // convert picture to formData and rezise it
      const file = evt.target.files[0];
      const resizedImage = await this.resizeImageMx(file, 1200, 1200);
      await this.picturesToAddAfter.push(resizedImage);
      await this.pictureList.push({
        id: URL.createObjectURL(resizedImage).slice(0, -5),
        url: URL.createObjectURL(resizedImage),
      });
      // update the form
      this.$emit('addPicturesNewEquipements', this.picturesToAddAfter);
    },
  },
};

</script>

<style scoped>
.picture-object {
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  width: 35rem;
  height: 15rem;
}
.btn-add-pics {
  margin: 1rem 0.3rem 1rem 0.3rem;
}
.button-icon {
  margin-left:1rem;
}

.picture-counter {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
}
</style>
