import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  planActionsEndpoint,
  patrimoinesEndpoint,
  programmesEndpoint,
} from './apiUrls';

export default {
  db: new PouchDB('planActions'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch
    const _id = obj._id || obj.id.toString();

    return {
      ...obj,
      _id,
      meta: obj.meta || {},
      id: String(obj.id),
      description: obj.description,
      nom: obj.nom,
      numero: obj.numero,
      patrimoine: obj.patrimoine.replace(`${patrimoinesEndpoint}/`, ''),
      programmes: obj.programmes.map(programme => programme.replace(`${programmesEndpoint}/`, '')),
    };
  },

  async create(planAction) {
    const planActionToCreate = { ...planAction };
    delete planActionToCreate.id;
    delete planActionToCreate._id;
    delete planActionToCreate.meta;

    const response = await axiosInstance.axiosInstance.post(
      planActionsEndpoint, planActionToCreate,
    );
    const savedPlanAction = this.convertBackendObject(response.data);

    return pouchUtil.addDoc(savedPlanAction, this.db);
  },

  async update(planAction) {
    const planActionToUpdate = { ...planAction };
    delete planActionToUpdate.id;
    delete planActionToUpdate._id;
    delete planActionToUpdate.meta;

    const response = await axiosInstance.axiosInstance.put(`${planActionsEndpoint}/${planAction.id}`, planActionToUpdate);
    const savedPlanAction = this.convertBackendObject(response.data);

    // si la sauvegarde en ligne à reussie, on sauvegarde dans pouchDB et on retourne le resultat
    return pouchUtil.addDoc(savedPlanAction, this.db, { updated: true });
  },

  async delete(planActionId) {
    await pouchUtil.deleteDoc(planActionId, this.db);
    return axiosInstance.axiosInstance.delete(`${planActionsEndpoint}/${planActionId}`);
  },

  async getAllByPatrimoine(patrimoineId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${planActionsEndpoint}?site=${patrimoineId}`);
      const planActions = response.data
        .map(planAction => this.convertBackendObject(planAction));

      return pouchUtil.addDocs(planActions, this.db);
    } catch (e) {
      return pouchUtil.getAllDocsByField('site', patrimoineId, this.db);
    }
  },
};
