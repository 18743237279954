<template>
  <div>
  <!-- Root element of PhotoSwipe. Must have class pswp. -->
    <div :ref="parentKey" class="pswp" tabindex="-1" role="dialog" aria-hidden="true">
        <!-- Background of PhotoSwipe.
            It's a separate element as animating opacity is faster than rgba(). -->
        <div class="pswp__bg"></div>
        <!-- Slides wrapper with overflow:hidden. -->
        <div class="pswp__scroll-wrap">
            <!-- Container that holds slides.
                PhotoSwipe keeps only 3 of them in the DOM to save memory.
                Don't modify these 3 pswp__item elements, data is added later on. -->
            <div class="pswp__container">
                <div class="pswp__item"></div>
                <div class="pswp__item"></div>
                <div class="pswp__item"></div>
            </div>
            <!-- Default (PhotoSwipeUI_Default)
            interface on top of sliding area. Can be changed. -->
            <div class="pswp__ui pswp__ui--hidden">
                <div class="pswp__top-bar">
                    <!--  Controls are self-explanatory. Order can be changed. -->
                    <div class="pswp__counter"></div>
                    <button class="pswp__button pswp__button--close" title="Close (Esc)"
                    @click.prevent>
                    </button>
                    <!--<button class="pswp__button pswp__button--share" title="Share"></button>
                    <button class="pswp__button pswp__button--fs"
                     title="Toggle fullscreen"></button>
                    <button class="pswp__button pswp__button--zoom" title="Zoom in/out"></button>-->
                    <!-- Preloader demo https://codepen.io/dimsemenov/pen/yyBWoR -->
                    <!-- element will get class pswp__preloader--active
                    when preloader is running -->
                    <div class="pswp__preloader">
                        <div class="pswp__preloader__icn">
                          <div class="pswp__preloader__cut">
                            <div class="pswp__preloader__donut"></div>
                          </div>
                        </div>
                    </div>
                </div>
                <div class="pswp__share-modal pswp__share-modal--hidden pswp__single-tap">
                    <div class="pswp__share-tooltip"></div>
                </div>
                <button
                  class="pswp__button pswp__button--arrow--left"
                  title="Previous (arrow left)"
                  @click.prevent
                >
                </button>
                <button class="pswp__button pswp__button--arrow--right" title="Next (arrow right)"
                  @click.prevent>
                </button>
                <div class="pswp__caption">
                    <div class="pswp__caption__center"></div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import PhotoSwipe from 'photoswipe/dist/photoswipe';
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default';
import 'photoswipe/dist/photoswipe.css';
import 'photoswipe/dist/default-skin/default-skin.css';

export default {
  props: {
    arrayPics: null,
    selectedPic: null,
    openGalleryTwo: null,
    openGalleryOne: null,
    parentKey: null,
  },
  watch: {
    openGalleryTwo(newvalue) {
      if (newvalue === true) {
        this.photoswipe(this.arrayPics, this.selectedPic);
      }
    },
    openGalleryOne(newvalue) {
      if (newvalue === true) {
        this.photoswipe(this.arrayPics, this.selectedPic);
      }
    },
  },
  methods: {
    photoswipe(arrayPics, selectedPic) {
      const imgs = [];
      const options = {
        history: false,
        focus: false,
        showAnimationDuration: 0,
      };
      let arrayFull = false;
      const long = arrayPics.length;
      if (long === 0) {
        arrayFull = true;
      }
      let currentPicture = -1;
      arrayPics.forEach((element) => {
        if (element.url === selectedPic) {
          currentPicture = element;
        }
      });
      let startIndex = arrayPics.indexOf(currentPicture);
      if (startIndex === -1) {
        startIndex = 0;
      }
      let index = arrayPics.indexOf(currentPicture);
      if (index === -1) {
        index = 0;
      }
      while (!arrayFull) {
        imgs.push({ src: `${arrayPics[index].url}`, w: 800, h: 800 });
        index += 1;
        if (index === long) {
          index = 0;
        }
        if (index === startIndex) {
          arrayFull = true;
        }
      }
      const pswpElement = this.$refs[this.parentKey];
      const gallery = new PhotoSwipe(pswpElement, PhotoSwipeUIDefault,
        imgs, options);
      gallery.init();
      gallery.listen('click', (event) => {
        event.preventDefault();
      });
      gallery.listen('close', () => {
        this.$emit('closeGalleryOne');
        this.$emit('closeGalleryTwo');
      });
    },
  },
};
</script>

<style scoped>
.picture-object {
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  height: 15rem;
}
</style>
