import { render, staticRenderFns } from "./InterventionGainForm.vue?vue&type=template&id=97ea4e54&scoped=true&"
import script from "./InterventionGainForm.vue?vue&type=script&lang=js&"
export * from "./InterventionGainForm.vue?vue&type=script&lang=js&"
import style0 from "./InterventionGainForm.vue?vue&type=style&index=0&id=97ea4e54&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97ea4e54",
  null
  
)

export default component.exports