import MissionsView from '../views/MissionsView.vue';
import MissionDetailView from '../views/MissionDetailView.vue';
import SiteDetailView from '../views/SiteDetailView.vue';
import BatimentDetailView from '../views/BatimentDetailView.vue';
import RapportView from '../views/RapportView.vue';
import InterventionView from '../views/InterventionView.vue';
import InterventionSiteView from '../views/InterventionSiteView.vue';
import PosteView from '../views/PosteView.vue';
import batimentAnalyseView from '../views/BatimentAnalyseView.vue';
import ScenariosView from '../views/ScenariosView.vue';
import MissionGestionInspectionView from '../views/MissionGestionInspectionView.vue';
import MissionGestionParamListView from '../views/MissionGestionParamListView.vue';
import BatimentCollectView from '../views/BatimentCollectView.vue';
import SiteCollectView from '../views/SiteCollectView.vue';
import ProgrammesView from '../views/ProgrammesView.vue';
import SiteAnalyseView from '../views/SiteAnalyseView.vue';
import MissionGestionModeleView from '../views/MissionGestionModeleView.vue';

export default [
  {
    path: '/missions',
    name: 'missions',
    meta: {
      libelle: 'MISSIONS',
      requiresAuth: true,
    },
    // component: () => import(/* webpackChunkName: "missions" */ '@/views/MissionsView'),
    component: MissionsView,
  },
  {
    path: '/mission/:missionId',
    name: 'missionDetail',
    meta: {
      libelle: 'selectedMission',
      requiresAuth: true,
    },
    // component: () => import(/* webpackChunkName: "missions" */ '@/views/MissionDetailView'),
    component: MissionDetailView,
    props: true,
  },
  {
    path: '/mission/:missionId/batiment/rapport',
    name: 'missionBatimentRapport',
    meta: {
      libelle: 'selectedMission',
      requiresAuth: true,
    },
    // component: () => import(/* webpackChunkName: "rapport" */ '@/views/RapportView'),
    component: RapportView,
  },
  {
    path: '/mission/:missionId/site/rapport',
    name: 'missionSiteRapport',
    meta: {
      libelle: 'selectedMission',
      requiresAuth: true,
    },
    // component: () => import(/* webpackChunkName: "rapport" */ '@/views/RapportView'),
    component: RapportView,
  },
  {
    path: '/mission/:missionId/site/:siteId/rapport',
    name: 'siteRapport',
    meta: {
      libelle: 'selectedSite',
      requiresAuth: true,
    },
    // component: () => import(/* webpackChunkName: "rapport" */ '@/views/RapportView'),
    component: RapportView,
  },
  {
    path: '/mission/:missionId/site/:siteId',
    name: 'site',
    meta: {
      libelle: 'selectedSite',
      requiresAuth: true,
    },
    // component: () => import(/* webpackChunkName: "sites" */ '@/views/SiteDetailView'),
    component: SiteDetailView,
    props: true,
  },
  {
    path: '/mission/:missionId/site/:siteId/batiment/:batimentId',
    name: 'batiment',
    meta: {
      libelle: 'selectedBatiment',
      requiresAuth: true,
    },
    // component: () => import(/* webpackChunkName: "batiments" */ '@/views/BatimentDetailView'),
    component: BatimentDetailView,
    props: true,
  },
  {
    path: '/mission/:missionId/site/:siteId/collecte',
    name: 'collectSite',
    meta: {
      libelle: 'selectedSite',
      requiresAuth: true,
    },
    component: SiteCollectView,
    props: true,
  },
  {
    path: '/mission/:missionId/site/:siteId/analyse',
    name: 'analyseSite',
    meta: {
      libelle: 'selectedSite',
      requiresAuth: true,
    },
    component: SiteAnalyseView,
    props: true,
  },
  {
    path: '/mission/:missionId/site/:siteId/interventionSites',
    name: 'siteInterventions',
    meta: {
      libelle: 'selectedSite',
      requiresAuth: true,
    },
    component: InterventionSiteView,
    props: true,
  },
  {
    path: '/mission/:missionId/site/:siteId/programmes',
    name: 'programmes',
    meta: {
      libelle: 'selectedSite',
      requiresAuth: true,
    },
    component: ProgrammesView,
    props: true,
  },
  {
    path: '/mission/:missionId/site/:siteId/batiment/:batimentId/collecte',
    name: 'collect',
    meta: {
      libelle: 'selectedBatiment',
      requiresAuth: true,
    },
    component: BatimentCollectView,
    props: true,
  },
  {
    path: '/mission/:missionId/site/:siteId/batiment/:batimentId/postes',
    name: 'batimentPostes',
    meta: {
      libelle: 'selectedBatiment',
      requiresAuth: true,
    },
    component: PosteView,
    props: true,
  },
  {
    path: '/mission/:missionId/site/:siteId/batiment/:batimentId/interventions',
    name: 'batimentInterventions',
    meta: {
      libelle: 'selectedBatiment',
      requiresAuth: true,
    },
    component: InterventionView,
    props: true,
  },
  {
    path: '/mission/:missionId/site/:siteId/batiment/:batimentId/scenarios',
    name: 'scenarios',
    meta: {
      libelle: 'selectedBatiment',
      requiresAuth: true,
    },
    component: ScenariosView,
    props: true,
  },
  {
    path: '/mission/:missionId/site/:siteId/batiment/:batimentId/analyse',
    name: 'batimentAnalyse',
    meta: {
      libelle: 'selectedBatiment',
      requiresAuth: true,
    },
    component: batimentAnalyseView,
    props: true,
  },
  {
    path: '/mission/:missionId/site/:siteId/batiment/:batimentId/rapport',
    name: 'batimentRapport',
    meta: {
      libelle: 'selectedBatiment',
      requiresAuth: true,
    },
    // component: () => import(/* webpackChunkName: "rapport" */ '@/views/RapportView'),
    component: RapportView,
  },
  {
    path: '/mission/:missionId/inspections',
    name: 'gestionInspections',
    meta: {
      libelle: 'selectedMission',
      requiresAuth: true,
    },
    component: MissionGestionInspectionView,
  },
  {
    path: '/mission/:missionId/listeParam',
    name: 'gestionListeParam',
    meta: {
      libelle: 'selectedMission',
      requiresAuth: true,
    },
    component: MissionGestionParamListView,
  },
  {
    path: '/mission/:missionId/gestionModele',
    name: 'gestionModele',
    meta: {
      libelle: 'selectedMission',
      requiresAuth: true,
    },
    component: MissionGestionModeleView,
  },

];
