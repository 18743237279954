/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  equipementCategoriesUrl,
  // missionsEndpoint,
  equipementDomainesUrl,
  apiUrl,
  refUrl,
  missionsUrl,
} from './apiUrls';

export default {
  db: new PouchDB('refEquipementDomaines'),

  convertBackendObject(obj, missionId) {
    // const idMission = obj.mission.replace(`${missionsEndpoint}/`, '');
    const idMission = missionId;
    // set an _id attribute for pouch
    const _id = obj._id || `${idMission}_${obj.id.toString()}`; /* eslint-disable-line no-underscore-dangle */
    const meta = {};


    return {
      _id,
      meta,
      id: String(obj.id),
      nom: obj.nom,
      code: obj.code,
      icone: obj.icone,
      categories: obj.categories.map(cat => cat.replace(`${apiUrl}${refUrl}${missionsUrl}/${missionId}${equipementCategoriesUrl}/`, '')),
      mission: idMission,
    };
  },

  /**
   * get all equipementDomaines for a mission
   * @param {String} missionId - id of the parent mission
   */
  async getAllByMission(missionId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${apiUrl}${refUrl}${missionsUrl}/${missionId}${equipementDomainesUrl}`);
      const equipementDomaines = response.data
        .map(equipementDomaine => this.convertBackendObject(equipementDomaine, missionId));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(equipementDomaines, this.db);
    } catch (e) {
      console.error('refEquipementDomainesApi getAllByMission error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByIdPrefix(`${missionId}_`, this.db);
    }
  },

  /**
   * get all equipementDomaines for a mission for offline use
   * @param {String} missionId - id of the parent mission
   */
  async fetchOffline(missionId) {
    const response = await axiosInstance.axiosInstance.get(`${apiUrl}${refUrl}${missionsUrl}/${missionId}${equipementDomainesUrl}`);
    const equipementDomaines = response.data
      .map(equipementDomaine => this.convertBackendObject(equipementDomaine, missionId));
    // if get is successful,  store data into pouchdb and return it
    return pouchUtil.addDocs(equipementDomaines, this.db);
  },

  /**
   * Fetch all equipementDomaines stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocs(this.db);
  },
};
