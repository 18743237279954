<template>
  <div class="table-wrapper">

    <div class="table-title-block">
      <div class="table-title">Inspections</div>
      <div class="table-filter">
        <v-select
          v-model="filter"
          :items="['tous', 'sélectionnés']"
          label="Voir"
          outlined
          hide-details
          dense
          attach
        ></v-select>
      </div>
    </div>

    <v-data-table
      class="inspections-table"
      v-model="inspectionsSelected"
      :items="tableInspections"
      :headers="headers"
      :single-select="singleSelect"
      show-select
      disable-pagination
      hide-default-footer
      @item-selected="touched = true"
      @toggle-select-all=" touched = true"
    >
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: 'InspectionSelectTable',
  props: {
    inspections: {
      type: Array,
      default: () => [],
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    initialList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      filter: 'toutes',
      headers: [
        { text: 'nom', value: 'nomType' },
      ],
      inspectionsSelected: [],
      touched: false,
    };
  },

  computed: {
    mappedInterventions() {
      return this.interventions.map(inter => this.mapIntervention(inter));
    },

    tableInspections() {
      if (this.filter === 'sélectionnés') {
        return this.inspections
          .filter(inspection => this.inspectionsSelected
            .find(selectedInsp => selectedInsp.id === inspection.id));
      }
      return this.inspections;
    },
  },

  watch: {
    inspectionsSelected() {
      this.$emit('new-selection', this.inspectionsSelected);
    },
  },
};
</script>

<style lang="scss" scoped>
.table-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  height: 100%;

  .table-title-block {
    flex: 0 0 auto;
    padding: 0.5rem;
    height: 3.5rem;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;


    .table-title {
      font-weight: 300;
      font-size: 1.4rem;
    }

    .table-filter {
      margin-right: 0;
      margin-left: auto;
      width: 9.5rem;
    }
  }
  .inspections-table {
    flex: 1 1 auto;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 5rem;
  }
}
</style>
