/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import pouchUtil from './pouchUtil';

export default {
  db: new PouchDB('offlineSiteList'),

  /**
   * add a site to the list of offline sites
   * @param {String} siteId - the id of the site to add
   */
  add(siteId) {
    const site = {
      id: siteId,
    };
    return pouchUtil.addDoc(site, this.db);
  },

  /**
   * remove a site from the list of offline sites
   * @param {String} siteId - the id of the site to remove
   */
  async remove(siteId) {
    const _id = siteId.toString();
    const { _rev } = await this.db.get(_id);
    return this.db.remove(_id, _rev);
  },

  /**
   * Load the list of offline sites
   */
  async getAll() {
    return pouchUtil.getAllDocs(this.db);
  },
};
