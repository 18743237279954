<template>
  <div>
    <v-speed-dial
      v-model="active"
      direction="top"
      transition="scale-transition"
      class="fab-speed-dial"
    >
      <template v-slot:activator>
        <!-- Floating action button -->
        <transition appear name="scale-rotate">
          <v-btn
            v-model="active"
            fab
            large
            @click="checkIfOnline()"
            color="primary"
            :loading="loading"
            :disabled="loading"
          >
            <v-icon
              class="fab-button-icon"
              :class="{ 'fab-button-icon--active': active }"
            >
              mdi-plus
            </v-icon>
          </v-btn>
        </transition>
      </template>

      <template
        v-for="(option, index) in options"
      >
        <v-tooltip
          :key="'opt_'+index"
          :value="tooltips"
          :attach="`#fabBtn_${index}`"
          left
          color="grey lighten-4"
          content-class="fab-tooltip"
          transition="slide-x-transition"
        >
          <template v-slot:activator="{ }">
            <!-- Menu buttons -->
            <v-btn
              :id="`fabBtn_${index}`"
              class="white--text"
              fab
              :disabled='option.disable'
              :color="option.color"
              @click.stop="handleButtonClick(option.name)">
              <v-icon :class="[{'white--text': !option.disable }]">{{option.icon}}</v-icon>
            </v-btn>
          </template>

          <span>{{option.tooltip}}</span>

        </v-tooltip>
      </template>

    </v-speed-dial>
    <!-- invible dismiss area strech to screen, close speed-dial on click -->
    <div class="fab-dismiss" v-if="active" @click="close()"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false,
      tooltips: false,
    };
  },
  props: {
    loading: Boolean,
    options: {
      type: Array,
      validator: prop => prop.map(obj => (
        obj.icon && typeof obj.icon === 'string'
        && obj.name && typeof obj.name === 'string'
        && obj.tooltip && typeof obj.tooltip === 'string'
        && obj.color && typeof obj.color === 'string'
      )).every(item => item === true),
    },
  },
  methods: {
    handleButtonClick(name) {
      this.$emit('option-selected', name);
      this.close();
    },
    checkIfOnline() {
      this.$emit('check-online');
    },
    close() {
      this.active = false;
    },
  },
  watch: {
    active(val) {
      this.tooltips = false;

      // display tooltip with 250ms delay
      if (val) {
        setTimeout(() => {
          this.tooltips = true;
        }, 250);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

  // rotate icon back on close
  .fab-button-icon {
    transform: rotate(0deg);
    transition: transform 0.2s linear;
  }

  // rotate icon on click
  .fab-button-icon--active {
    transform: rotate(225deg);
    transition: transform 0.2s linear;
  }

  // grey tooltip with same box shadow as fab
  .fab-tooltip {
    color: #424242;
    box-shadow:
      0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14),
      0px 1px 18px 0px rgba(0, 0, 0, 0.12);

    top: 0px !important;
    left: 0px !important;
    transform: translate(-110%, 30%);
  }

  // invisible surface, just under the speed dial, strech to screen
  .fab-dismiss {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 98;
  }

  // set speed-dial z-index over fab-dismiss
  .fab-speed-dial {
    z-index: 99;
  }

</style>
