import api from '@/api';

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
/* eslint no-console: ["error", { allow: ["error", "log"] }] */

// initial state
const state = {
  programmes: [],
};

const getters = {
  selectedPatrimoineProgrammes: (state, getters, rootState, rootGetters) => {
    const patrimoine = rootGetters['patrimoines/selected'];
    if (patrimoine) {
      return state.programmes.filter(prog => patrimoine.sites.includes(prog.site));
    }
    return [];
  },

  selectedSiteProgrammes: (state, getters, rootState, rootGetters) => {
    const site = rootGetters['sites/selected'];
    if (site) {
      return state.programmes.filter(prog => prog.site === site.id);
    }
    return [];
  },
};

// actions
const actions = {
  /**
   * Create a new programme and add it to the store
   */
  createProgrammeOnSite({ commit }, siteId) {
    const newProgramme = {
      id: `tempProg_${Math.random()}`,
      site: siteId,
    };
    commit('addProgrammes', [newProgramme]);
    return { ...newProgramme };
  },

  /**
   * Save a programme on the server (automatically select POST or PUT) and update the store
   */
  save({ commit }, programme) {
    return new Promise((resolve, reject) => {
      // if the programme doesn't exhist on the backend, create it by post
      if (programme.id.startsWith('tempProg')) {
        api.programmes.create(programme)
          .then((response) => {
            commit('deleteProgramme', programme.id);
            commit('addProgrammes', [response]);
            resolve({ ...response });
          })
          .catch((error) => {
            commit('deleteProgramme', programme.id);
            reject(error);
          });
      // else update it by put
      } else {
        api.programmes.update(programme)
          .then((response) => {
            commit('addProgrammes', [response]);
            resolve({ ...response });
          })
          .catch(error => reject(error));
      }
    });
  },

  /**
   * Fetch all children programmes of a site
   * @param {String} siteId - id of the parent site
   */
  fetchAllBySite({ commit }, siteId) {
    return api.programmes.getAllBySite(siteId)
      .then(response => commit('addProgrammes', response));
  },

  /**
   * Fetch one programme by it's id fron the server and update the store
   */
  refreshItem({ commit }, programmeId) {
    return new Promise((resolve) => {
      api.programmes.get(programmeId).then((response) => {
        commit('addProgrammes', [response]);
        resolve({ ...response.data });
      });
    });
  },

  /**
   * Delete a programme from the server & from the store
   */
  delete({ commit }, programme) {
    api.programmes.delete(programme.id)
      .then(() => {
        commit('deleteProgramme', programme.id);
      });
  },
};

// mutations
const mutations = {
  addProgrammes(state, newProgrammes) {
    newProgrammes.forEach((newProgramme) => {
      const stateIndex = state.programmes
        .findIndex(stateProgramme => stateProgramme.id === newProgramme.id);
      if (stateIndex >= 0) {
        state.programmes.splice(stateIndex, 1, newProgramme);
      } else {
        state.programmes.push(newProgramme);
      }
    });
  },

  deleteProgramme(state, programmeId) {
    state.programmes
      .splice(state.programmes.findIndex(programme => programme.id === programmeId), 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
