/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  clientsEndpoint,
  missionsEndpoint,
  sitesEndpoint,
  rapportStructuresEndpoint,
  auditTypesEndpoint,
  missionsUrl,
} from './apiUrls';

export default {
  db: new PouchDB('missions'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch
    const _id = obj._id || obj.id.toString();

    return {
      _id,
      meta: obj.meta || {},
      id: String(obj.id),
      nom: obj.nom,
      client: obj.client.replace(`${clientsEndpoint}/`, ''),
      referentielMd5Version: obj.referentielMd5Version,
      coeffPonderation: obj.coeffPonderation,
      txActualisationEnergies: obj.txActualisationEnergies,
      txTVA: obj.txTVA,
      prixCEE: obj.prixCEE,
      sites: obj.sites ? obj.sites.map(site => site.replace(`${sitesEndpoint}/`, '')) : null,
      rapportStructures: obj.rapportStructures ? obj.rapportStructures.map(rs => rs.replace(`${rapportStructuresEndpoint}/`, '')) : null,
      typesAudits: obj.typesAudits ? obj.typesAudits.map(typeAudit => typeAudit.replace(`${auditTypesEndpoint}/`, '')) : null,
      codeRP: obj.codeRP,
      authorizedInspectionSite: obj.authorizedInspectionSite,
      authorizedInspectionBatiment: obj.authorizedInspectionBatiment,
      createdBy: obj.createdBy,
      agence: obj.agence,
      nbSites: obj.nbSites,
      refVersion: obj.refVersion ? parseInt(obj.refVersion.replace('/api/referentiel-jsons/', ''), 10) : null,
      organisationListeEquipement: obj.organisationListeEquipement,
      siteModele: obj.siteModele,
    };
  },

  create(mission) {
    return new Promise((resolve, reject) => {
      axiosInstance.axiosInstance.post(`${missionsEndpoint}/add-mission`, mission)
        .then((response) => {
          resolve(this.convertBackendObject(response.data));
        })
        .catch((error) => { reject(error); });
    });
  },

  update(missionId, mission) {
    return axiosInstance.axiosInstance.put(`${missionsEndpoint}/${missionId}`, mission)
      .then(response => this.convertBackendObject(response.data))
      .catch(error => error);
  },

  updateController(missionId, mission) {
    return axiosInstance.axiosInstance.put(`${missionsEndpoint}/${missionId}/edit-mission`, mission)
      .then(response => this.convertBackendObject(response.data))
      .catch(error => error);
  },

  async delete(missionId) {
    // delete the mission from pouchDB
    try {
      await pouchUtil.deleteDoc(missionId, this.db);
    } catch (e) {
      console.log(e.response);
    }
    // if the delete from pouchDB is sucessfull, delete from the server
    return axiosInstance.axiosInstance.delete(`${missionsEndpoint}/${missionId}`);
  },

  /**
   * get one mission by his id
   * @param {String} missionId - the id of the mission to get
   */
  async get(missionId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${missionsEndpoint}/${missionId}`);
      const mission = this.convertBackendObject(response.data);

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDoc(mission, this.db);
    } catch (e) {
      console.error('missionsApi get error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getDoc(missionId, this.db);
    }
  },

  /**
   * get one mission by his id for offline use
   * @param {String} missionId - the id of the mission to get
   */
  async fetchOffline(missionId) {
    const response = await axiosInstance.axiosInstance.get(`${missionsEndpoint}/${missionId}`);
    const mission = this.convertBackendObject(response.data);
    // if get is successful,  store data into pouchdb and return it
    return pouchUtil.addDoc(mission, this.db);
  },

  /**
   * get all missions of the application
   */
  async getAll() {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${missionsEndpoint}`);
      const missions = response.data.map(mission => this.convertBackendObject(mission));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(missions, this.db);
    } catch (e) {
      console.error('missionsApi getAll error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocs(this.db);
    }
  },

  /**
   * get all missions of a parent client
   * @param {String} clientId - id of the parent client
   */
  async getAllByClient(clientId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${clientsEndpoint}/${clientId}${missionsUrl}`);
      const missions = response.data.map(mission => this.convertBackendObject(mission));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(missions, this.db);
    } catch (e) {
      console.error('missionsApi getAll error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByField('client', clientId, this.db);
    }
  },

  /**
   * Fetch all missions stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocs(this.db);
  },
};
