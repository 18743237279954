<template>
  <v-bottom-sheet
    v-model="sheet"
    fullscreen
  >
    <v-sheet class="sheet-wrapper">
      <h1>Edition de l'historique des travaux</h1>
      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <div v-for="(travaux, index) in localValue"
          :key="'work' + index"
          class="historique"
        >
          <ValidationProvider
            :rules="yearRules"
            v-slot="{ errors }"
            class="year-historic"
          >
            <v-text-field
              :error-messages="errors"
              label="Année *"
              outlined
              type="number"
              v-model="travaux.annee"
            >
            </v-text-field>
          </ValidationProvider>
          <ValidationProvider
            :rules="natureRules"
            v-slot="{ errors }"
            class="nature-historic"
          >
            <v-textarea
              label="Nature des travaux réalisés *"
              :error-messages="errors"
              outlined
              v-model="travaux.nature"
            >
            </v-textarea>
          </ValidationProvider>
          <div @click="removeLine(index)">
            <v-icon>
                mdi-close-box
            </v-icon>
          </div>
        </div>

        <div class="contenair-add">
          <v-btn @click="addLine" text class="btn-add-historic">
            <v-icon left>mdi-plus-box-outline</v-icon>
            Ajouter
          </v-btn>
        </div>
        <p class="required-fields-hint">* Champs requis </p>
        <div class="bottom-sheet-footer">
          <v-btn
            text
            color="primary"
            @click="$emit('close-sheet')"
          >
            Annuler
          </v-btn>
          <v-btn
            depressed
            color="primary"
            @click="$emit('change', localValue)"
            :disabled="invalid"
          >
            Valider
          </v-btn>
        </div>
      </ValidationObserver>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  props: {
    sheet: Boolean,
    defaultValue: null,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  methods: {
    addLine() {
      return this.localValue.push({
        annee: null,
        nature: null,
      });
    },
    removeLine(lineId) {
      this.localValue.splice(lineId, 1);
    },
  },
  watch: {
    sheet(newValue, oldValue) {
      if (newValue === true && oldValue === false) {
        if (this.defaultValue !== null) {
          this.localValue = [...this.defaultValue];
        } else {
          this.localValue = [];
        }
      }
    },
  },
  data() {
    return {
      localValue: [],
      blockRemoval: true,
      yearRules: {
        length: 4,
        minVal: 0,
        required: 'required',
      },
      natureRules: {
        required: 'required',
      },
    };
  },
};
</script>
<style lang="scss" scoped>
  .sheet-wrapper {
    padding: 1rem;
    height: 100%;
    overflow: auto;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }
</style>

<style scoped>
.historique{
  display: flex;
  width: 85%;
  padding-right: 5rem;
  max-width: 1000px;
}
.nature-historic{
  flex: 1 0 auto;
  width: 85%;
  margin-right: 2rem;
}
.contenair-add{
  width: 90%;
  max-width: 1000px;
}
.year-historic{
  flex: 0 0 5.5rem;
  width: 95%;
  margin-right: 2rem;
}
.bottom-sheet-footer {
  width: 100%;
  height: 3rem;
  margin-bottom: 0;
  margin-top: auto;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}
.btn-add-historic{
  margin-left: 0px;
}
</style>
