export default {
  /**
   * Create or update a document on a pouchDatabase
   * @param {Object} doc - the document to save
   * @param {PouchDB} db - the pouchDatabase where to save the document
   * @param {Object} options - object containing options
   */
  async addDoc(doc, db, {
    toCreate = false,
    toUpdate = false,
    updated = false,
    error = false,
    errorMessage = null,
  } = {}) {
    // set the _id and meta
    const docToSave = {
      _id: doc._id || doc.id.toString(),
      ...doc,
      meta: {
        toCreate,
        toUpdate,
        error,
        errorMessage,
      },
    };

    try {
      // check if the doc is allready on pouch
      const pouchdDoc = await db.get(docToSave._id);

      // if a version is on pouch with change not yet updated, do not crush it
      // if the toUpdate flag is true on the docToSave, its a local update, so crush it
      if (!toUpdate && pouchdDoc.meta && pouchdDoc.meta.toUpdate && !updated) {
        return pouchdDoc;
      }
      // get the revision from the pouchDocument
      docToSave._rev = pouchdDoc._rev;
    } catch (e) {
      // there's no version allready on pouch, continue
    }

    // save the doc on pouch and retrieve the revision
    const { rev } = await db.put(docToSave);
    // return the saved doc
    return {
      ...docToSave,
      _rev: rev,
    };
  },

  /**
   * Create or update a array of documents on a pouchDatabase
   * @param {Object[]} docs - the array of documents to save
   * @param {PouchDB} db - the pouchDatabase where to save the document
   * @param {Object} options - object containing options
   */
  async addDocs(docs, db, options) {
    // call addDoc for all docs and wait for results
    const newDocs = await Promise.all(
      docs.map(doc => this.addDoc(doc, db, options)),
    );
    // return the results
    return newDocs.filter(doc => doc);
  },

  /**
   * Delete a document from a pouchDatabase
   * @param {String} docId - the _id of the doc to delete
   * @param {PouchDB} db - the pouchDatabase whete to delete the document
   */
  async deleteDoc(docId, db) {
    try {
      const result = await db.get(docId);
      return db.remove(docId, result._rev);
    } catch (e) {
      throw new Error(`pouchUtil.deleteDoc : ${e}`);
    }
  },

  /**
 * Destroy a pouchDatabase
 */
  async destroyDb(db) {
    try {
      return db.destroy();
    } catch (e) {
      throw new Error(`pouchUtil.destroyDb : ${e}`);
    }
  },

  /**
   * Retrieve a document from a pouchDatabase - return null if nothing is found
   * @param {*} docId - the _id of the document to get
   * @param {*} db - the pouchDatabase to querry
   */
  async getDoc(docId, db) {
    const result = await db.get(docId);
    if (result) {
      return result;
    }
    return null;
  },

  /**
   * Retrieve all documents from a pouchDatabase
   * @param {PouchDB} db - the database to query
   */
  async getAllDocs(db) {
    const result = await db.allDocs({ include_docs: true });
    if (result.rows.length) {
      return result.rows.map(r => r.doc);
    }
    return [];
  },

  /**
   * get all docs that are flaged toCreate or toUpdate on a pouchDatabase
   * @param {PouchDB} db - the database to query
   */
  async getAllDocsToSync(db) {
    const result = await db.allDocs({ include_docs: true });
    if (result.rows.length) {
      return result.rows
        .map(r => r.doc)
        .filter(doc => doc.meta.toUpdate || doc.meta.toCreate);
    }
    return [];
  },

  /**
   * get all docs from a pouchDatabase and filter them based on a field and an field value
   * @param {String} fieldName - the name of the field to querry
   * @param {any} fieldValue - the value of the field to querry
   * @param {PouchDB} db - the database to query
   */
  async getAllDocsByField(fieldName, fieldValue, db) {
    const result = await db.allDocs({ include_docs: true });
    if (result.rows.length) {
      return result.rows
        .map(r => r.doc)
        .filter(doc => doc[fieldName] === fieldValue);
    }
    return [];
  },

  /**
   * Get all documents filtered by _id prefix
   * @param {String} prefix - the prefix to filter _ids
   * @param {PouchDB} db - the database to querry
   */
  async getAllDocsByIdPrefix(prefix, db) {
    const result = await db.allDocs({
      include_docs: true,
      startkey: `${prefix}`,
      endkey: `${prefix}\uffff`,
    });

    if (result.rows.length) {
      return result.rows.map(r => r.doc);
    }
    return [];
  },
};
