<template>
  <v-text-field
    v-bind="fieldOptions"
    :value="defaultValue"
    tabindex="-1"
  >
    <template v-slot:label>
      <slot name="field-label"></slot>
    </template>
  </v-text-field>
</template>

<script>
export default {
  name: 'display-field',
  props: {
    commonOptions: Object,
    defaultValue: null,
    formField: Object,
  },
  computed: {
    fieldOptions() {
      return {
        ...this.commonOptions,
        readonly: true,
        suffix: this.formField.unit,
        filled: true,
        dense: true,
        persistentHint: true,
      };
    },
  },
};
</script>
