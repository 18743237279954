<template>
  <div class="home-view">
<div>
    <transition appear name="slide-fade-up">
       <v-img
       src="/img/smartdiag.png"
        max-width="40vw"
        max-height="100px"
        float="left"
        contain
        alt="Bienvenue"
      ></v-img>
    </transition>
  </div>
    <!--<h1 class="display-1 font-weight-thin mb-4" transition="slide-x-transition">
    <strong class="blue--text text--darken-4">Odete</strong>
    <span class="primary--text"> SMART DIAG</span>
    </h1>-->

    <transition  appear name="fade">
      <h3 class="compagnon">Votre compagnon d'audit</h3>
    </transition>
     <transition appear name="fade">
      <v-img
        src="/img/home_illustration.png"
        max-width="100vw"
        max-height="250px"
        contain
        alt="Bienvenue"
      >
      </v-img>
    </transition>

    <transition appear name="fade">
      <h2 class="font-weight-thin mt-7">Bonjour {{ this.me.givenName }} </h2>
    </transition>
    <transition appear name="fade">
      <h2 class="font-weight-thin">Par quoi voulez-vous commencer ?</h2>
    </transition>

    <div class="home-cards-container">
      <transition appear name="slide-fade-left">
      <v-card
        transition="fade-transition"
        shaped
        outlined
        to="/clients"
      >
        <v-card-title>Mes clients</v-card-title>
        <v-card-text>Rechercher un client dans la liste</v-card-text>
      </v-card>
      </transition>

      <transition appear name="slide-fade-right">
      <v-card
        shaped
        outlined
        to="/missions"
      >
        <v-card-title>Mes missions</v-card-title>
        <v-card-text>Aller directement à une mission</v-card-text>
      </v-card>
      </transition>
    </div>
    <transition appear name="fade">
    <div class="container-logo">
      <p :class="RibonClass">Version : {{version}}</p>
      <h3 class="subtitle-1" >Une solution développée par Cyrisea.</h3>
      <v-img
        src="/img/logo_cyrisea_rvb.png"
        max-width="25vw"
        max-height="50px"
        contain
        alt="Bienvenue"
        align="center"
      >
      </v-img>
    </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import axiosInstance from '../plugins/axiosWithHydra';


export default {
  data() {
    return {
      version: '',
      environnement: '',
      RibonClass: '',
    };
  },

  computed: {
    ...mapGetters({
      me: 'me/me',
    }),
  },
  created() {
    this.$store.dispatch('clearSelectedView');
    this.$store.dispatch('clearSelectedItem');
    this.environnement = window.location.hostname;
    let RibonClass = 'blue';
    if (this.environnement.includes('dev')) {
      RibonClass = 'blue';
      this.environnement = 'Developpement';
    }
    if (this.environnement.includes('rec')) {
      RibonClass = 'red';
      this.environnement = 'Recette';
    }
    if (this.environnement.includes('preprod')) {
      RibonClass = 'green';
      this.environnement = 'Pré-Production';
    }
    this.RibonClass = `ribbon ${RibonClass}`;

    // retrieve the app version number from the json file in public folder
    axiosInstance.get('/version.json').then((response) => {
      const { appVersion } = response.data;
      this.version = appVersion;
    });
    this.version = 'test';
  },
};
</script>

<style lang="scss" scoped>
  .home-view {
    // width: 100vw;
    height: 100%;
    // padding: 3rem;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  .page-title {
    text-align: center;
  }

  // .btn-search-client {
  //   margin-top: 4rem;
  //   margin-bottom: 1rem;
  // }

  .home-cards-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    > * {
      margin: 1rem;
      flex: 0 1 auto;
    }
  }
  .container-logo {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    .subtitle-1{
      text-align: center;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  }
  .ribbon.blue{
    color: $app-blue;
    font-style: italic;
    text-align: center;
    padding: 0.5rem;
    margin-top: 0.5rem;
    background-color: transparent !important;
  }
  .ribbon.red{
    color: $nav-red;
    font-style: italic;
    text-align: center;
    padding: 0.5rem;
    margin-top: 0.5rem;
    background-color: transparent !important;
  }
  .ribbon.green{
    color: $nav-green;
    font-style: italic;
    text-align: center;
    padding: 0.5rem;
    margin-top: 0.5rem;
    background-color: transparent !important;
  }
  .compagnon{
    margin-top:26px;
  }
</style>
