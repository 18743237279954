<template>
  <v-form>
    <div>
        <div>
          <div  class="attr-gen-wrapper">
            <div class="card">
              <v-icon
                class="iconExpend"
                color="primary"
                @click="showForm = !showForm"
                large
                >
                {{ showForm ? `mdi-minus-circle-outline`:`mdi-plus-circle-outline` }}
              </v-icon>
              <div class="title">
                <p class="fields-group-title"> Attributs génériques </p>
              </div>
              <div v-if="showForm">
                <div class="attr-gen-mini-wrap">
                  <v-select
                    v-model="form.fields.motivation"
                    :items="motivations"
                    label="Motivation"
                    item-text="libelle"
                    item-value="codeOption"
                    outlined
                    dense
                    @change="
                    handleFormUpdate($event)"
                  ></v-select>

                  <v-textarea
                    class="text-area"
                    outlined
                    rows="2"
                    :maxlength=650
                    :rules="textAreaRules"
                    label="Description"
                    v-model="form.fields.description"
                    @change="
                    handleFormUpdate($event)"
                  >
                    <!-- suffix refresh button -->
                    <template v-slot:append-outer>
                      <v-icon
                        class="rounded-icon"
                        color="primary"
                        v-ripple
                        @click="refreshDescription()"
                      >
                        mdi-refresh
                      </v-icon>
                    </template>
                  </v-textarea>
                </div>
                <div class="attr-gen-mini-wrap">
                  <v-textarea
                    class="text-area"
                    outlined
                    rows="2"
                    label="Précision"
                    :maxlength=650
                    :rules="textAreaRules"
                    v-model="form.fields.precisions"
                    @change="handleFormUpdate($event)"
                  >
                  </v-textarea>
                  <v-textarea
                    class="text-area"
                    outlined
                    rows="2"
                    label="Note interne"
                    :maxlength=650
                    :rules="textAreaRules"
                    v-model="form.fields.noteInterne"
                    @change="handleFormUpdate($event)"
                  >
                  </v-textarea>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="column-wrapper">
          <div class="cards-column" v-if="equipementInter">
            <InterventionGainForm
              ref="gainForm"
              :intervention="intervention"
              :creation="creation"
              @form-updated="handleGainFormUpdate($event)"
              @energieAp-updated="handleChangeEnergieAp($event)"
              :updatedCalulation="updatedCalulation"
              :errorConso="errorConso"
            >
            </InterventionGainForm>
          </div>
          <div class="cards-column">
            <InterventionCoutForm
              ref="coutForm"
              :batiment="selectedBatiment"
              :intervention="intervention"
              :creation="creation"
              @form-updated="handleCoutFormUpdate($event)"
            >
            </InterventionCoutForm>
          </div>
          <div class="cards-column">
            <InterventionCeeForm
            ref="ceeForm"
              :intervention="intervention"
              :creation="creation"
              :itemIdForCreation="itemIdForCreation"
              @form-updated="handleCeeFormUpdate($event)"
            >
            </InterventionCeeForm>
          </div>
        </div>
    </div>
  </v-form>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import InterventionGainForm from './InterventionGainForm.vue';
import InterventionCoutForm from './InterventionCoutForm.vue';
import InterventionCeeForm from './InterventionCeeForm.vue';

export default {
  components: {
    InterventionGainForm,
    InterventionCoutForm,
    InterventionCeeForm,
  },
  props: ['intervention', 'creation', 'itemIdForCreation', 'updatedCalulation', 'errorConso'],
  data() {
    return {
      showForm: false,
      selectedEnergieAp: null,
      form: {
        valid: true,
        touched: true,
        fields: {
          precisions: null,
          noteInterne: null,
          description: null,
          motivation: null,
        },
      },
      gainForm: {},
      coutForm: {},
      ceeForm: {},
      textAreaRules: [v => (!v || v.length < 650) || 'Vous avez atteint les 650 caractères autorisés'],
    };
  },
  computed: {
    ...mapState('repartitions', ['repartitions']),
    ...mapState('listeOptions', { listesOptions: 'all' }),
    ...mapGetters('missions', { selectedMission: 'selected' }),
    ...mapGetters({
      selectedBatiment: 'batiments/selected',
      postes: 'listeOptions/selectedMissionPostes',
      energies: 'listeOptions/selectedMissionEnergies',
      motivations: 'listeOptions/selectedMissionMotivations',
    }),

    equipementInter() {
      return this.intervention.equipement && !this.intervention.inspection;
    },

    interventionType() {
      if (this.listesOptions) {
        return this.listesOptions.find(opt => opt.codeOption === this.intervention.option);
      }
      return null;
    },

    gainFormValid() {
      let ret = this.form.valid;
      if (typeof this.$refs.gainForm !== 'undefined') {
        ret = this.$refs.gainForm.form.valid;
      }
      return ret;
    },

    coutFormValid() {
      let ret = this.form.valid;
      if (typeof this.$refs.coutForm !== 'undefined') {
        ret = this.$refs.coutForm.form.valid;
      }
      return ret;
    },

    ceeFormValid() {
      let ret = this.form.valid;
      if (typeof this.$refs.ceeForm !== 'undefined') {
        ret = this.$refs.ceeForm.form.valid;
      }
      return ret;
    },

    gainFormTouched() {
      let ret = this.form.touched;
      if (typeof this.$refs.gainForm !== 'undefined') {
        ret = this.$refs.gainForm.form.touched;
      }
      return ret;
    },

    coutFormTouched() {
      let ret = this.form.touched;
      if (typeof this.$refs.coutForm !== 'undefined') {
        ret = this.$refs.coutForm.form.touched;
      }
      return ret;
    },

    ceeFormTouched() {
      let ret = this.form.touched;
      if (typeof this.$refs.ceeForm !== 'undefined') {
        ret = this.$refs.ceeForm.form.touched;
      }
      return ret;
    },

  },

  created() {
    this.form.fields.motivation = this.intervention.motivation;
    this.form.fields.precisions = this.intervention.precisions;
    this.form.fields.noteInterne = this.intervention.noteInterne;
    if (this.creation && this.interventionType) {
      this.showForm = true;
      const {
        description,
        motivation,
      } = this.interventionType.data;
      this.form.fields.description = description;
      this.form.fields.motivation = motivation;
    } else {
      this.form.fields.description = this.intervention.description;
    }
    this.form.fields.hasCoutForfaitairePreferee = this.intervention.hasCoutForfaitairePreferee;
    this.$store.dispatch('listeOptions/fetchAllByType',
      {
        missionId: this.selectedMission.id,
        codeType: 'motivation',
      });
  },

  methods: {
    handleChangeEnergieAp(energieAp) {
      this.selectedEnergieAp = energieAp;
    },
    handleFormUpdate() {
      if (!this.form.touched) {
        this.form.touched = true;
      }
      this.emitUpatedForm();
    },

    handleGainFormUpdate(updatedGainForm) {
      this.gainForm = { ...updatedGainForm };
      this.emitUpatedForm();
    },

    handleCoutFormUpdate(updatedCoutForm) {
      this.coutForm = { ...updatedCoutForm };
      this.emitUpatedForm();
    },


    handleCeeFormUpdate(updatedCeeForm) {
      this.ceeForm = { ...updatedCeeForm };
      this.emitUpatedForm();
    },

    emitUpatedForm() {
      let valid = false;
      if (this.coutFormValid && this.gainFormValid && this.ceeFormValid) {
        valid = true;
      }
      this.$emit('form-updated', {
        valid: this.form.valid && valid,
        touched: this.form.touched || this.gainFormTouched || this.coutFormTouched
        || this.ceeFormTouched,
        fields: {
          ...this.form.fields,
          ...this.gainForm.fields,
          ...this.coutForm.fields,
          ...this.ceeForm.fields,
        },
      });
    },

    refreshDescription() {
      if (this.creation && this.interventionType) {
        const {
          description,
        } = this.interventionType.data;
        this.form.fields.description = description;
      } else if (this.intervention.referentielValues.option_description) {
        this.form.fields.description = this.intervention.referentielValues.option_description;
        this.handleFormUpdate();
      }
    },

  },
};

</script>

<style lang="scss" scoped>

  .column-wrapper {
    display: flex;
    flex-flow: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    padding-top: 1rem;
    padding-bottom: 8rem;

    .cards-column {
      margin: 1rem;
      padding:1rem;
      flex-grow: 4;
      align-items: center;
      border-radius: 5px;

      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      background-color: white;

    }
  }

.content-wrapper form{
  padding: 2rem;
}

.fields-group-title {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1rem;

  color: $app-blue;
}

.title{
  display: flex;
  justify-content: space-around;
}

.attr-gen-wrapper {
  border-radius: 5px;
  margin-left: 1rem;
  margin-right: 1rem;
  padding:1rem;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  background-color: white;
}
.attr-gen-mini-wrap {
  /*display: flex;
  flex-flow: row wrap;
  width: 100%;
      > * {
    margin-left: 1rem;
    margin-right: 1rem;
    min-width:300px;
  }*/
}

.iconExpend {
  float: right;
}
.card {
  width: 100%;
}
.formCard {
  display:flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
}
</style>
