<template>
  <ValidationProvider
    :name="formField.name"
    :rules="formField.rules"
    v-slot="{ errors }">

      <v-text-field
        v-bind="fieldOptions"
        :error-messages="errors"
        v-model="localValue"
        @change="$emit('change', localValue)"
        @keyup="changeToUpperCase(formField.rules);"
      >

        <template v-slot:label>
          <slot name="field-label"></slot>
        </template>
    </v-text-field>

  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'text-field',
  components: {
    ValidationProvider,
  },
  props: {
    commonOptions: Object,
    defaultValue: null,
    formField: Object,
  },
  data() {
    return {
      localValue: this.defaultValue,
    };
  },
  computed: {
    fieldOptions() {
      return {
        ...this.commonOptions,
        placeholder: this.formField.placeholder,
      };
    },
  },
  watch: {
    defaultValue(newValue) {
      this.localValue = newValue;
    },
  },
  methods: {
    changeToUpperCase(rules) {
      if (typeof (rules) !== 'undefined') {
        if (rules.indexOf('isUppercase') >= 0 && this.localValue) {
          this.localValue = this.localValue.toUpperCase();
        }
      }
      this.$emit('keyup', this.localValue);
    },
  },
};
</script>
