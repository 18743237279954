import api from '@/api';

/* eslint no-console: ["error", { allow: ["error", "log"] }] */
/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */


const state = {
  batiments: [],
  selectedId: null,
};

const getters = {
  toSync: state => state.batiments
    .filter(batiment => batiment.meta && (batiment.meta.toCreate || batiment.meta.toUpdate)),

  nbToSync: (state, getters) => {
    if (getters.toSync && getters.toSync.length) {
      return getters.toSync.length;
    }
    return 0;
  },

  selected: state => state.batiments.find(batiment => batiment.id === state.selectedId),

  selectedSiteBatiments: (state, getters, rootState, rootGetters) => {
    const site = rootGetters['sites/selected'];
    if (site) {
      return state.batiments.filter(bat => bat.site === site.id);
    }
    return [];
  },
};

const actions = {
  /**
   * Create a new Batiment and add it to the store
   */
  createNew({ commit }) {
    const newBatiment = {
      id: `tempBat${Math.random()}`,
      image: null,
      nom: 'Nouveau Batiment',
      dateReleve: null,
      adresse: '',
      typeUsage: '',
      anneeConstruction: null,
      idClient: '',
      surfaceDeclaree: null,
      surface2: null,
      typeSurface: null,
      typeSurface2: null,
      nbNiveau: null,
      anneeRehabilitation: null,
      accompagnant: false,
      contraintesArchi: false,
      presenceErp: false,
    };

    commit('addBatiments', [newBatiment]);

    return { ...newBatiment };
  },

  /**
   * Save a batiment on the server (automatically select POST or PUT) and update the store
   */
  save({ dispatch, commit, state }, payload) {
    return new Promise(async (resolve, reject) => {
      // function to save batiment
      const saveBatFonction = (form, batimentId) => new Promise((resolveBatiment) => {
        // if the batiment doesn't exhist on the backend, create it by post
        if (batimentId.startsWith('tempBat')) {
          api.batiments.create(form, batimentId)
            .then((response) => {
              commit('deleteBatiment', batimentId);
              commit('addBatiments', [response]);
              if (state.selectedId === batimentId) {
                commit('changeSelectedId', response.id);
              }
              resolveBatiment({ ...response });
            })
            .catch(error => reject(error));
        // else update it by put
        } else {
          api.batiments.update(batimentId, form)
            .then((response) => {
              commit('addBatiments', [response]);
              dispatch('niveaux/fetchAllByBatiment', response.id, { root: true });
              resolveBatiment({ ...response });
            })

            /* finally funcion ruta api/batiment/niveau/idniveau */
            .catch(error => reject(error));
        }
      });

      // save batiment and update store
      const savedBat = await saveBatFonction(payload.data, payload.id);

      // resolve promise
      resolve(savedBat);
    });
  },

  /**
   * Fetch all batiments from the backend and update the store
   */
  fetchAll({ commit }) {
    return api.batiments.getAll().then(response => commit('addBatiments', response));
  },

  /**
   * Fetch all the children batiments of a site
   * @param {String} siteId - id of the parent site
   */
  fetchAllBySite({ commit }, siteId) {
    return api.batiments.getAllBySite(siteId).then(response => commit('addBatiments', response));
  },

  /**
   * Load all batiments stored offline in pouchDB
   */
  loadOffline({ commit }) {
    return api.batiments.loadOffline().then(response => commit('addBatiments', response));
  },

  /**
   * Fetch one batiment by it's id fron the server and update the store
   */
  refreshItem({ commit }, batimentId) {
    return new Promise((resolve) => {
      api.batiments.get(batimentId).then((response) => {
        commit('addBatiments', [response]);
        resolve({ ...response });
      });
    });
  },

  /**
   * get batiment by his id
   */
  getById({ commit }, batimentId) {
    return api.batiments.get(batimentId).then((response) => {
      commit('addBatiments', [response]);
      return response;
    });
  },

  /**
   * Delete a batiment from the server & from the store
   */
  delete({ commit }, batiment) {
    api.batiments.delete(batiment.id).then(() => {
      commit('deleteBatiment', batiment.id);
    });
  },

  /**
   * Synchronise all batiments with the server and re-load the list contained in pouchDB
   */
  async synchroniseAll({ commit, dispatch }) {
    const syncBatiments = await api.batiments.synchroniseAll();
    commit('clearBatiments');
    await dispatch('loadOffline');
    return syncBatiments;
  },

  async changeSelected({
    state, getters, commit, dispatch, rootState,
  },
  batimentId) {
    if (state.selectedId !== batimentId) {
      commit('changeSelectedId', batimentId);

      if (!getters.selected) {
        await dispatch('refreshItem', batimentId);
      }

      if (!rootState.sites.selected
        || rootState.sites.selected.id !== getters.selected.site) {
        await dispatch('sites/changeSelected', getters.selected.site, { root: true });
      }
    }
  },

  async clearSelected({ commit }) {
    await commit('changeSelectedId', null);
  },
};


const mutations = {
  updateBatimentImage(state, payload) {
    const batiment = state.batiments.find(item => item.id === payload.batId);
    batiment.image = payload.image.id;
  },

  addBatiments(state, newBatiments) {
    newBatiments.forEach((newBatiment) => {
      const stateIndex = state.batiments
        .findIndex(stateBatiment => stateBatiment.id === newBatiment.id);
      if (stateIndex >= 0) {
        state.batiments.splice(stateIndex, 1, newBatiment);
      } else {
        state.batiments.push(newBatiment);
      }
    });
  },

  deleteBatiment(state, batimentId) {
    state.batiments.splice(state.batiments.findIndex(batiment => batiment.id === batimentId), 1);
  },

  clearBatiments(state) {
    state.batiments = [];
  },

  changeSelectedId(state, batimentId) {
    state.selectedId = batimentId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
