/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  inspectionTypesEndpoint,
} from './apiUrls';

export default {
  db: new PouchDB('refInspectionsTypes'),

  convertBackendObject(obj, missionId) {
    const idMission = missionId;
    // set an _id attribute for pouch
    const _id = obj._id || `${idMission}_${obj.id.toString()}`; /* eslint-disable-line no-underscore-dangle */
    const meta = {};

    return {
      _id,
      meta,
      id: obj.id.toString(),
      nom: obj.nom,
      code: obj.code,
      // attrDefinition: obj.attrDefinition,
      mission: idMission,
      objet: obj.objet,
    };
  },

  /**
   * get all inspectionTypes for a mission
   * @param {String} missionId - id of the parent mission
   */
  async getAllByMission(missionId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${inspectionTypesEndpoint}?mission=${missionId}&active=true`);
      const inspectionTypes = response.data
        .map(inspectionType => this.convertBackendObject(inspectionType, missionId));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(inspectionTypes, this.db);
    } catch (e) {
      console.error('refInspectionTypesApi getAllByMission error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByIdPrefix(`${missionId}_`, this.db);
    }
  },

  /**
   * get all inspectionTypes for a mission for offline use
   * @param {String} missionId - id of the parent mission
   */
  async fetchOffline(missionId) {
    const response = await axiosInstance.axiosInstance.get(`${inspectionTypesEndpoint}?mission=${missionId}`);
    const inspectionTypes = response.data
      .map(inspectionType => this.convertBackendObject(inspectionType, missionId));
    // if get is successful,  store data into pouchdb and return it
    return pouchUtil.addDocs(inspectionTypes, this.db);
  },

  /**
   * Fetch all inspectionTypes stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocs(this.db);
  },
};
