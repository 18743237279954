<template>
  <v-bottom-sheet
    v-model="sheet"
    fullscreen
  >
    <v-sheet class="sheet-wrapper">
        <div id="app" class="content-wrapper">
          <h2 class="fixed-title">Ajout d'une nouvelle mission</h2>
          <v-app id="inspire">
            <v-stepper v-model="e1">
              <v-stepper-header>
                <v-stepper-step
                  :complete="e1 > 1"
                  step="1"
                  :rules="[() => saveOk]"
                >
                  Formulaire
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step
                  :complete="e1 > 2"
                  step="2"
                >
                  Organisation équipements
                </v-stepper-step>

                <v-divider></v-divider>

                <v-stepper-step step="3">
                  Sélection inspections
                </v-stepper-step>
              </v-stepper-header>

              <v-stepper-items>
                <v-stepper-content step="1">
                  <ValidationObserver ref="obs" v-slot="{ invalid }">
                    <v-form  class="form">
                      <form-builder
                        :item="defaultItem"
                        :duplicate="true"
                        itemType="missions"
                        :key="formBuilderKey"
                        :onlyRequired="false"
                        class="form-wrapper"
                      >
                      </form-builder>
                    </v-form>


                    <div class="stepper-footer">
                      <v-btn
                        text
                        large
                        color="primary"
                        @click="closeSheet()"
                      >
                        Annuler
                      </v-btn>

                      <v-btn
                        depressed
                        large
                        color="primary"
                        @click.native="saveForm()"
                        :disabled="invalid"
                        :loading="saving"
                      >
                      <!--:loading="saving"-->
                        Suivant
                      </v-btn>
                    </div>
                  </ValidationObserver>
                </v-stepper-content>

                <v-stepper-content step="2">
                  <MissionChangeParamList
                    v-if="selectedMission"
                    :listTypeEquipement="equipementTypesALL"
                    :organisationListeEquipement="selectedMission.organisationListeEquipement"
                    @listsChange="saveListParam($event)">

                  </MissionChangeParamList>
                  <div class="stepper-footer">
                    <v-btn
                      color="primary"
                      class="action-button"
                      @click="e1 = 3"
                    >
                      Continuer
                    </v-btn>
                  </div>
                </v-stepper-content>

                <v-stepper-content step="3">
                  <MissionChangeInspectionList
                    v-if="selectedMission"
                    :mission="selectedMission"
                    @listsChange="saveInspection($event)">
                  </MissionChangeInspectionList>
                  <div class="stepper-footer">
                    <v-btn
                      color="primary"
                      @click="closeSheet()"
                    >
                      Terminer
                    </v-btn>
                  </div>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-app>
        </div>
    </v-sheet>

  </v-bottom-sheet>
</template>

<script>
import api from '@/api';
import { mapGetters, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import FormBuilder from '../form-builder.vue';
import MissionChangeParamList from './MissionChangeParamList.vue';
import MissionChangeInspectionList from './MissionChangeInspectionList.vue';

export default {
  components: {
    FormBuilder,
    ValidationObserver,
    MissionChangeParamList,
    MissionChangeInspectionList,
  },
  computed: {
    ...mapGetters({
      selectedMission: 'missions/selected',
    }),
    ...mapState(['currentForm']),
    ...mapState('equipements', { equipementTypes: 'types' }),
  },
  props: {
    sheet: Boolean,
    defaultItem: { type: Object },
  },
  watch: {
    sheet(newValue, oldValue) {
      // on sheet oppening
      if (newValue && !oldValue) {
        this.$store.dispatch('clearCurrentForm');
        this.formBuilderKey += 1;
      }
    },
  },
  data() {
    return {
      e1: 1,
      formBuilderKey: 0,
      saveOk: true,
      saving: false,
      equipementTypesALL: [],
    };
  },
  methods: {
    closeSheet() {
      this.$emit('close-sheet');
      this.e1 = 1;
    },
    saveInspection(event) {
      this.saving = true;
      const payload = { };
      payload.id = this.selectedMission.id;
      payload.data = {
        authorizedInspectionSite: event.siteInspection,
        authorizedInspectionBatiment: event.batimentInspection,
      };

      this.$store.dispatch(
        'missions/save',
        {
          id: payload.id,
          ...payload,
        },
      ).then((res) => {
        if (res.isAxiosError) {
          const message = 'Impossible de sauvegarder les changements';
          this.$store.dispatch('snackbar/displaySnack',
            {
              message,
              type: 'error',
            });
        } else {
          this.$store.dispatch('snackbar/displaySnack', { message: 'Sauvegardé avec succès !', type: 'succes' });
        }
      }).catch(() => {
        const message = 'Impossible de sauvegarder les changements';
        this.$store.dispatch('snackbar/displaySnack',
          {
            message,
            type: 'error',
          });
      }).finally(() => {
        this.saving = false;
      });
    },
    saveListParam(event) {
      this.saving = true;
      const payload = { };
      payload.id = this.selectedMission.id;
      payload.data = {
        organisationListeEquipement: event,
      };

      this.$store.dispatch(
        'missions/save',
        {
          id: payload.id,
          ...payload,
        },
      ).then(async (res) => {
        if (res.isAxiosError) {
          const message = 'Impossible de sauvegarder les changements';
          this.$store.dispatch('snackbar/displaySnack',
            {
              message,
              type: 'error',
            });
        } else {
          this.$store.dispatch('snackbar/displaySnack', { message: 'Sauvegardé avec succès !', type: 'succes' });
        }
      }).catch(() => {
        const message = 'Impossible de sauvegarder les changements';
        this.$store.dispatch('snackbar/displaySnack',
          {
            message,
            type: 'error',
          });
      }).finally(() => {
        this.saving = false;
      });
    },
    saveForm2() {
      this.e1 = 2;
    },
    saveForm() {
      this.saving = true;
      // payload construction //
      const payload = { };
      // convert all form fields to string
      const formCopy = JSON.parse(JSON.stringify(this.currentForm));
      // if there's a photo to save, put it in a separate field in the payload
      if (this.currentForm.image) {
        payload.photo = this.currentForm.image;
        delete formCopy.photo;
      }
      payload.data = formCopy;

      this.$store.dispatch('missions/createNew', {}).then((newItem) => {
        this.$store.dispatch(
          'missions/save',
          {
            id: newItem.id,
            ...payload,
          },
        ).then(async (result) => {
          this.$store.dispatch('snackbar/displaySnack', { message: 'créé avec succès !', type: 'succes' });
          this.e1 = 2;
          this.saveOk = true;
          await this.$store.dispatch('changeSelectedItem', { id: result.id, type: 'missions' });
          this.equipementTypesALL = await api
            .refEquipementTypes.getAllByMission(this.selectedMission.id);
          await Promise.all([
            this.$store.dispatch('inspections/fetchTypesInspectionByMission',
              {
                idMission: this.selectedMission.id,
              }),
          ]);
        })
          .catch((response) => {
            let message = 'Impossible de sauvegarder la missions';
            if (response.code === 'ERR_BAD_RESPONSE' || response.isAxiosError) {
              this.saveOk = false;
              if (response.response.data === 'Le contrôleur est déjà utilisé par un autre utilisateur.') {
                message = 'Un autre utilisateur modifie également une mission, merci de réessayer dans quelques instants.';
              }
              this.$store.dispatch('snackbar/displaySnack',
                {
                  message,
                  type: 'error',
                });
            }
          }).finally(() => {
            this.saving = false;
          // this.closeSheet();
          });
      });
    },
  },
};

</script>
<style lang="scss" scoped>
  .sheet-wrapper {
    padding: 100px 3rem 100px 3rem;
    overflow: hidden;
    height: 100%;
    width:100%;
    overscroll-behavior: none;
    // background-color: #f9f9f9;
  }
  .content-wrapper {
    // height: 95%;
    //width:100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    > * {
      margin-bottom: 3rem;
    }
  }
  .fixed-title{
    position: fixed;
    height: 64px;
    top: 0;
    margin-top: 0;
    width:100%;
    background-color: #2D4388;
    z-index: 10;
    color:white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.2rem;
  }
.form {
  overflow-y: scroll;
  height: 28em;
}
.stepper-footer {
  text-align: right;
}
</style>
