<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <div class="dialog-wrapper">
      <div class="dialog-title">
        <template>Associer des sites</template>
      </div>
      <div class="dialog-content">
        <v-card>
          <PatrimoineSiteList
            v-if="show"
            :patrimoine="patrimoine"
            :clientMissions="clientMissions"
            @associate="handleChangeSites($event)"
          >
          </PatrimoineSiteList>
        </v-card>
      </div>
        <div class="dialog-footer">
          <v-btn
              depressed
              large
              color="primary"
              :loading="saving"
              @click.native="$emit('close')"
            >
              Annuler
          </v-btn>
          <v-btn
              depressed
              large
              color="primary"
              :loading="saving"
              @click.native="saveForm()"
            >
              Associer
          </v-btn>
        </div>
    </div>

  </v-dialog>
</template>

<script>
import { mapState } from 'vuex';
import PatrimoineSiteList from './PatrimoineSiteList.vue';

export default {
  components: {
    PatrimoineSiteList,
  },

  props: ['show', 'patrimoine', 'client'],

  data() {
    return {
      saving: false,
      newSitesSelection: [],
    };
  },

  computed: {
    ...mapState('missions', ['missions']),
    clientMissions() {
      if (this.client) {
        return this.missions.filter(mission => mission.client === this.client.id);
      }
      return [];
    },
  },

  created() {
    if (this.client) {
      this.missions.filter(mission => mission.client === this.client.id);
    }
  },
  methods: {
    handleChangeSites(sites) {
      this.newSitesSelection = sites;
    },

    async saveForm() {
      this.saving = true;
      let patrimoineToSave = {};
      patrimoineToSave = {
        id: this.patrimoine.id,
        data: {
          ...this.patrimoine,
          sites: this.newSitesSelection,
        },
      };
      await this.$store.dispatch('patrimoines/save', patrimoineToSave).then((res) => {
        if (res.isAxiosError) {
          this.$store.dispatch('snackbar/displaySnack',
            {
              message: 'Impossible de sauvegarder.',
              type: 'error',
            });
        } else {
          this.$store.dispatch('snackbar/displaySnack', { message: 'Sauvegardé avec succès !', type: 'succes' });
        }
      }).catch(() => {
        this.$store.dispatch('snackbar/displaySnack',
          {
            message: 'Impossible de sauvegarder.',
            type: 'error',
          });
      }).finally(() => {
        this.saving = false;
        this.$emit('close');
      });
    },

    async handleQuit() {
      await this.saveForm();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  .dialog-wrapper {
    background-color: $app-white;
    height: 100%;
    overflow: hidden;

    .dialog-title {
      grid-area: header;

      font-size: 2rem;
      font-weight: 500;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;

      background-color: $app-blue;
      color: $app-grey;
    }

    .dialog-content {
      justify-self: center;
      width: 100%;
      height: 80%;
      overflow: scroll;
    }
    .dialog-footer {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      align-items: center;

      padding: 1rem 1rem 0 1rem;

      > * {
        margin-right: 1rem;
      }
    }

  }

</style>
