import { required } from 'vee-validate/dist/rules';
import { extend } from 'vee-validate';

extend('required', {
  ...required,
  message: 'Le champ est obligatoire.',
});

extend('necessary', {
  validate() {
    return true;
  },
});

extend('create-only', {
  ...required,
  message: 'Les champs sont en create only',
});

extend('minVal', {
  params: ['min'],
  validate: (value, { min }) => Number(value) > min,
  message: (value, { min }) => `Doit être strictement supérieur à ${min}.`,
});

extend('minEqVal', {
  params: ['min'],
  validate: (value, { min }) => Number(value) >= min,
  message: (value, { min }) => `Doit être supérieur ou égal à ${min}.`,
});

extend('maxEqVal', {
  params: ['max'],
  validate: (value, { max }) => Number(value) <= max,
  message: (value, { max }) => `Doit être inférieur ou égal à ${max}.`,
});

extend('integer', {
  validate: value => Number.isInteger(value),
  message: 'Doit être un nombre entier.',
});

extend('email', {
  validate: v => /.+@.+\..+/.test(v),
  message: 'Doit être une adresse mail.',
});

extend('phone', {
  validate: (v => /^[0-9]{10}$/.test(v)),
  message: 'Doit être un numero de téléphone.',
});

extend('length', {
  params: ['minMax'],
  validate(value, { minMax }) {
    const { length } = value.toString();
    if (length < minMax) {
      return `${minMax} caractères minimum.`;
    }
    if (length > minMax) {
      return `${minMax} caractères maximum.`;
    }
    return true;
  },
});

extend('maxLength', {
  params: ['minMax'],
  validate(value, { minMax }) {
    const { length } = value.toString();
    if (length > minMax) {
      return `${minMax} caractères maximum.`;
    }
    return true;
  },
});

extend('isUppercase', {
  validate(value) {
    if (value.toUpperCase() !== value) {
      return 'Le QUAD doit être en majuscule.';
    }
    return true;
  },
});
