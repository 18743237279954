<template>
  <div class="category-card">
    <h2><slot name="title"></slot></h2>
  </div>
</template>

<script>
export default {
  props: ['categorie'],
};
</script>

<style lang="scss" scoped>
 .category-card {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem 1rem 2rem;
    margin-bottom: 0.5rem;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;

    width: 21rem;
    height: 5.5rem;
    cursor: pointer;

    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }
  }

  .category-card h2 {
    font-size: 1.3em;
    text-align: center;
  }

  .category-card-enabled h2 {
    font-weight: bolder;
  }

  .category-card-disabled {
    opacity: 0.5;
    pointer-events: none;
  }
</style>
