<template>
  <div class="type-wrapper">
    <p v-if="noTypeFound">Aucun type d'inspection trouvé pour cette mission.</p>
    <v-sheet v-if="loading"
             color="transparent"
             class="message-sheet"
    >
    <v-progress-circular
      v-if="loading"
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
    </v-sheet>
    <InspectionTypeCard
      v-for="type in typeByMission"
      :key="type.code"
      @click.native="selectType(type)"
    >
      <template v-slot:title>
        {{ type.nom }}
      </template>
      <template v-slot:content>
      </template>
    </InspectionTypeCard>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import InspectionTypeCard from './InspectionTypeCard.vue';

export default {
  components: {
    InspectionTypeCard,
  },
  props: ['item'],
  data() {
    return {
      loading: false,
      inspectionTypes: [],
      activeDomaines: [],
    };
  },
  computed: {
    ...mapGetters({
      batimentInspectionsType: 'inspections/selectedMissionBatimentInspectionTypes',
      siteInspectionsType: 'inspections/selectedMissionSiteInspectionTypes',
    }),
    ...mapGetters('missions', { selectedMission: 'selected' }),

    typeByMission() {
      let listInspectionsType = this.batimentInspectionsType;
      let authorizedInspection = this.selectedMission.authorizedInspectionBatiment;
      if (this.item === 'site') {
        listInspectionsType = this.siteInspectionsType;
        authorizedInspection = this.selectedMission.authorizedInspectionSite;
      }
      return listInspectionsType
        .filter(type => authorizedInspection.includes(type.code));
    },
    noTypeFound() {
      return !this.loading && (!this.typeByMission || !this.typeByMission.length);
    },
  },

  async created() {
    if (!this.typeByMission.length) {
      this.loading = true;
      await Promise.all([
        this.$store.dispatch('inspections/fetchTypesInspectionByMission',
          {
            idMission: this.selectedMission.id,
          }),
      ]);
      this.loading = false;
    }
  },
  methods: {
    selectType(type) {
      this.$emit('type-inspection-selected', type);
    },
  },
};
</script>

<style lang="scss" scoped>
  .message-sheet {
    width: 100px;
    height: 100px;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;

    margin: auto;
    font-weight: 300;
    font-size: 1.6rem;
    text-align: center;
    background-color: transparent !important;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 15px;
  }
  .type-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
</style>
