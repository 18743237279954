<template>
  <ValidationProvider
    :name="formField.name"
    :rules="formField.rules"
    v-slot="{ errors }"
  >
    <v-textarea
      v-bind="fieldOptions"
      :error-messages="errors"
      :maxlength=650
      :rules="textAreaRules"
      v-model="localValue"
      @change="$emit('change', localValue)"
    >
      <template v-slot:label>
        <slot name="field-label"></slot>
      </template>
    </v-textarea>

  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'text-area-field',
  components: {
    ValidationProvider,
  },
  props: {
    commonOptions: Object,
    defaultValue: null,
    formField: Object,
  },
  data() {
    return {
      localValue: this.defaultValue,
      textAreaRules: [v => (!v || v.length < 650) || 'Vous avez atteint les 650 caractères autorisés'],
    };
  },
  computed: {
    fieldOptions() {
      return {
        ...this.commonOptions,
        placeholder: this.formField.placeholder,
        rows: this.formField.lines,
        autoGrow: true,
      };
    },
  },
  watch: {
    defaultValue(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>
