import api from '@/api';

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
/* eslint no-console: ["error", { allow: ["error", "log"] }] */

// initial state
const state = {
  interventionSites: [],
  selectedId: null,
};

const getters = {
  toSync: state => state.interventionSites
    .filter(interventionSite => interventionSite.meta
      && (interventionSite.meta.toCreate || interventionSite.meta.toUpdate)),

  nbToSync: (state, getters) => {
    if (getters.toSync && getters.toSync.length) {
      return getters.toSync.length;
    }
    return 0;
  },

  selected: state => state.interventionSites.find(inter => inter.id === state.selectedId),

  selectedSiteInterventionSites: (state, getters, rootState, rootGetters) => {
    const site = rootGetters['sites/selected'];
    if (site) {
      return state.interventionSites.filter(inter => inter.site === site.id);
    }
    return [];
  },
};

// actions
const actions = {
  /**
   * Create a new intervention and add it to the store
   */
  createInterventionSite({ commit }, interventionSite) {
    const newInterventionSite = {
      id: `tempInt${Math.random()}`,
      site: interventionSite.siteId,
      option: interventionSite.codeType,
      description: interventionSite.textArea,
    };
    commit('addInterventionSites', [newInterventionSite]);
    return { ...newInterventionSite };
  },

  /**
   * Save a intervention on the server (automatically select POST or PUT) and update the store
   */
  save({ commit }, payload) {
    return new Promise((resolve, reject) => {
      // if the Interventions doesn't exhist on the backend, create it by post
      if (payload.id.startsWith('tempInt')) {
        api.interventionSites.create(payload.data, payload.id)
          .then((response) => {
            commit('deleteInterventionSite', payload.id);
            commit('addInterventionSites', [response]);
            resolve({ ...response });
          })
          .catch((error) => {
            commit('deleteInterventionSite', payload.id);
            reject(error);
          });
      // else update it by put
      } else {
        api.interventionSites.update(payload.id, payload.data)
          .then((response) => {
            commit('addInterventionSites', [response]);
            resolve({ ...response });
          })
          .catch(error => reject(error));
      }
    });
  },

  /**
   * Fetch all Interventions from the server and update the store
   */
  fetchAll({ commit }) {
    return new Promise((resolve) => {
      api.interventionSites.getAll().then((response) => {
        commit('addInterventionSites', response);
        resolve();
      });
    });
  },

  /**
   * Fetch all children interventionSites of a site
   * @param {String} siteId - id of the parent site
   */
  fetchAllBySite({ commit }, siteId) {
    return api.interventionSites.getAllBySite(siteId)
      .then(response => commit('addInterventionSites', response));
  },

  /**
   * Load all interventionSites stored offline in pouchDB
   */
  loadOffline({ commit }) {
    return api.interventionSites.loadOffline().then(response => commit('addInterventionSites', response));
  },

  /**
   * Fetch one intervention by it's id fron the server and update the store
   */
  refreshItem({ commit }, InterventionSiteId) {
    return new Promise((resolve) => {
      api.interventionSites.get(InterventionSiteId).then((response) => {
        commit('addInterventionSites', [response]);
        resolve({ ...response.data });
      });
    });
  },

  /**
   * Delete a interventionSite from the server & from the store
   */
  async delete({ commit }, interventionSite) {
    await api.interventionSites.delete(interventionSite.id)
      .then(() => {
        commit('deleteInterventionSite', interventionSite.id);
      });
  },


  async changeSelected({
    state, getters, commit, dispatch, rootState,
  },
  interventionSiteId) {
    if (state.selectedId !== interventionSiteId) {
      commit('changeSelectedId', interventionSiteId);

      if (!getters.selected) {
        await dispatch('refreshItem', interventionSiteId);
      }

      if (!rootState.sites.selected
        || rootState.sites.selected.id !== getters.selected.site) {
        await dispatch('sites/changeSelected', getters.selected.site, { root: true });
      }
    }
  },

  async clearSelected({ commit }) {
    await commit('changeSelectedId', null);
  },
};

// mutations
const mutations = {

  addInterventionSites(state, newInterventionSites) {
    newInterventionSites.forEach((newInterventionSite) => {
      const stateIndex = state.interventionSites
        .findIndex(stateInterventionSite => stateInterventionSite.id === newInterventionSite.id);
      if (stateIndex >= 0) {
        state.interventionSites.splice(stateIndex, 1, newInterventionSite);
      } else {
        state.interventionSites.push(newInterventionSite);
      }
    });
  },

  deleteInterventionSite(state, interventionSiteId) {
    state.interventionSites
      .splice(
        state.interventionSites.findIndex(
          interventionSite => interventionSite.id === interventionSiteId,
        ), 1,
      );
  },

  changeSelectedId(state, interventionSiteId) {
    state.selectedId = interventionSiteId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
