/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  sitesEndpoint, clientsEndpoint, patrimoinesEndpoint, missionsEndpoint, compteursEndpoint,
} from './apiUrls';

export default {
  db: new PouchDB('clients'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch
    const _id = obj._id || obj.id.toString();

    return {
      _id,
      meta: obj.meta || {},
      id: String(obj.id),
      nom: obj.nom,
      adresse: obj.adresse,
      nomContact: obj.nomContact,
      prenomContact: obj.prenomContact,
      fonctionContact: obj.fonctionContact,
      telContact: obj.telContact,
      mailContact: obj.mailContact,
      missions: obj.missions ? obj.missions.map(mission => mission.replace(`${missionsEndpoint}/`, '')) : null,
      patrimoines: obj.patrimoines ? obj.patrimoines.map(patrimoine => patrimoine.replace(`${patrimoinesEndpoint}/`, '')) : null,
      compteurs: obj.compteurs ? obj.compteurs.map(compteur => compteur.replace(`${compteursEndpoint}/`, '')) : null,
      sites: obj.sites ? obj.sites.map(site => site.replace(`${sitesEndpoint}/`, '')) : null,
      image: obj.image,
      codePostal: obj.codePostal,
      ville: obj.ville,
      serviceDemandeur: obj.serviceDemandeur,
    };
  },

  create(client) {
    return new Promise((resolve, reject) => {
      const clientToSave = { ...client };
      delete clientToSave.image;
      axiosInstance.axiosInstance.post(clientsEndpoint, clientToSave)
        .then((response) => {
          resolve(this.convertBackendObject(response.data));
        })
        .catch((error) => { reject(error); });
    });
  },

  update(clientId, client) {
    return new Promise((resolve, reject) => {
      const clientToSave = { ...client };
      delete clientToSave.image;
      axiosInstance.axiosInstance.put(`${clientsEndpoint}/${clientId}`, clientToSave)
        .then((response) => {
          resolve(this.convertBackendObject(response.data));
        })
        .catch((error) => { reject(error); });
    });
  },

  async delete(clientId) {
    // delete the client from pouchDB
    try {
      await pouchUtil.deleteDoc(clientId, this.db);
    } catch (e) {
      console.log(e.response);
    }
    // if the delete from pouchDB is sucessfull, delete from the server
    return axiosInstance.axiosInstance.delete(`${clientsEndpoint}/${clientId}`);
  },

  /**
   * get a client by his id
   * @param {String} clientId - the id of the client to get
   */
  async get(clientId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${clientsEndpoint}/${clientId}`);
      const client = this.convertBackendObject(response.data);

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDoc(client, this.db);
    } catch (e) {
      console.error('clientsApi get error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getDoc(clientId, this.db);
    }
  },

  /**
   * get a client by his id for offline use
   * @param {String} clientId - the id of the client to get
   */
  async fetchOffline(clientId) {
    const response = await axiosInstance.axiosInstance.get(`${clientsEndpoint}/${clientId}`);
    const client = this.convertBackendObject(response.data);
    // if get is successful,  store data into pouchdb and return it
    return pouchUtil.addDoc(client, this.db);
  },

  /**
   * get all clients of the application
   */
  async getAll() {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${clientsEndpoint}`);
      const clients = response.data.map(client => this.convertBackendObject(client));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(clients, this.db);
    } catch (e) {
      console.error('clientsApi getAll error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocs(this.db);
    }
  },

  /**
   * get all clients of the application
   */
  async getAllListeOption() {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get('/api/clients/list-option');
      const clients = response.data.map(client => this.convertBackendObject(client));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(clients, this.db);
    } catch (e) {
      console.error('clientsApi getAll error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocs(this.db);
    }
  },

  /**
   * Fetch all clients stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocs(this.db);
  },
};
