/* eslint-disable no-await-in-loop */
/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import pouchUtil from './pouchUtil';
import axiosInstance from '../plugins/axios';
import {
  mediaEndpoint,
} from './apiUrls';

export default {
  db: new PouchDB('pictures'),

  async create(picture, itemType, itemId) {
    const property = (itemType === 'equipements' || itemType === 'inspections' || itemType === 'batiments') ? 'images' : 'image';

    // on créé un objet uniquement pour la sauvegarde locale si pas de connexion
    const localPicture = {
      _id: `tempImg_${new Date().toJSON()}`,
      _attachments: {
        imageFile: {
          content_type: picture.type,
          data: picture,
        },
      },
      meta: {},
      itemType,
      itemId,
    };

    // si l'objet est dépendant d'un objet supérieur pas encore créé
    // alors on ne tente même pas une sauvegarde serveur
    // et on le sauvegarde directement sur PouchDB
    if (itemId.startsWith('temp')) {
      await this.db.put(localPicture);
      return this.db.get(localPicture._id, { attachments: true });
    }

    try {
      // try an server save
      const formData = new FormData();
      formData.append('file', picture);
      formData.append('content-type', 'multipart/form-data');
      const response = await axiosInstance.axiosInstance.post(`${mediaEndpoint}/${itemType}/${itemId}/${property}`, formData);
      return response.data;
    } catch (e) {
      // if server save fail, store in pouch and return the pouch document
      console.error('picturesApi create error', e);
      await this.db.put(localPicture);
      return localPicture;
    }
  },

  async getFile(pictureId) {
    // return this.db.get(pictureId, { attachments: true });
    return this.db.getAttachment(pictureId, 'imageFile');
  },

  async getAllByItem(itemId) {
    const childrens = await pouchUtil.getAllDocsByField('itemId', itemId, this.db);

    // if theres some pictures for this item
    if (childrens.length) {
      return Promise.all(childrens.map(async child => ({
        id: child._id,
        blob: await this.db.getAttachment(child._id, 'imageFile'),
      })));
    }
    return [];
  },

  /**
   * synchronise with the server all pictures that need to be saved
   */
  async synchroniseAll() {
    const result = await this.db.allDocs({ include_docs: true });
    if (result.rows.length) {
      const docs = result.rows.map(r => r.doc);

      for (let i = 0; i < docs.length; i += 1) {
        // if the parent object is still temp, save on pouch with error
        if (docs[i].itemId.startsWith('temp')) {
          await this.db.put({
            ...docs[i],
            meta: {
              error: true,
              errorMessage: 'Objet parent non synchronisé',
            },
          });
        } else {
          try {
            const blobFile = await this.db.getAttachment(docs[i]._id, 'imageFile');
            const property = docs[i].itemType === 'equipements' ? 'images' : 'image';

            const formData = new FormData();
            formData.append('file', blobFile);
            formData.append('content-type', 'multipart/form-data');
            await axiosInstance.axiosInstance.post(`${mediaEndpoint}/${docs[i].itemType}/${docs[i].itemId}/${property}`, formData);
            await pouchUtil.deleteDoc(docs[i]._id, this.db);
          } catch (e) {
            // if create fail and functionnal error -> save on pouch with error
            if (e.response
              && e.response.status >= 400
              && e.response.status < 500) {
              await this.db.put({
                ...docs[i],
                meta: {
                  error: true,
                  errorMessage: e.response.data.detail,
                },
              });
            }
            // if it's not a functionnal error, stop synchro and throw error
            throw new Error(e);
          }
        }
      }
    }
  },

  async numberToSync() {
    const result = await this.db.allDocs();
    return result.rows.length;
  },

  async picturesToSync() {
    return pouchUtil.getAllDocs(this.db);
  },

  /**
   * When a new batiment/equipement/site/inspection is created,
   * if this item has unsaved pictures children
   * update those pictures to replace the tempId wit the new id
   */
  async handleParentCreated(tempItemId, newItemId) {
    // get all the pictures related to this item id
    const childrens = await pouchUtil.getAllDocsByField('itemId', tempItemId, this.db);

    if (childrens.length) {
      // update the children with the new item id
      const updatedChildrens = childrens.map(children => ({
        ...children,
        itemId: newItemId,
      }));
      // save them back on PouchDB
      await this.db.bulkDocs(updatedChildrens);
    }
  },

  /**
   * When a new batiment/equipement/site is deleted,
   * if this item has unsaved pictures children
   * delete those pictures from pouchDB
   */
  async handleParentDeleted(tempItemId) {
    const childrens = await pouchUtil.getAllDocsByField('itemId', tempItemId, this.db);
    if (childrens.length) {
      await Promise.all(childrens.map(item => pouchUtil.deleteDoc(item._id, this.db)));
    }
  },

  /**
   * delete a picture from pouchDB or from the server
   */
  async delete(pictureId) {
    if (pictureId.startsWith('temp')) {
      return pouchUtil.deleteDoc(pictureId, this.db);
    }
    return axiosInstance.axiosInstance.delete(pictureId);
  },
};
