<template>
  <v-bottom-sheet
    v-model="sheet"
    persistent
    fullscreen
  >
    <v-sheet class="sheet-wrapper">

      <div class="column-wrapper">
        <div class="wrapper-form">
          <div class="wrapper-titles">
            <h2 class="title">{{ inter.item.typeInterventionLibelle }}</h2>
            <h3 v-if="inter.source.type === 'equipement'"
            >équipement : <a class="wrapper-title-link" @click="selectEquipement()"
            >{{ inter.source.name }}<v-icon color="primary">mdi-link-variant</v-icon></a></h3>
            <h3 v-if="inter.source.type === 'inspection'" v-on:click="goTo(inter.item)"
            >inspection : {{ inter.source.name }}</h3>
          </div>
          <div class="wrapper-content">
            <interventionAutoSaveForm
              v-if="inter.item"
              :intervention="inter.item"
              :calculation="calculation"
              class="form"
              :key="formKey"
              @saving="saving = $event"
              @refreshSheet="refresh"
            >
            </interventionAutoSaveForm>
          </div>
        </div>

        <div class="right-fixed-bar">
          <ShutterBar
           :intervention="inter.item"
           @quit="handleQuit()"
           :refreshGains="saving"
           :sourceType="inter.source.type"
          >
          </ShutterBar>
        </div>
      </div>
    </v-sheet>
    <BottomSheetEditItem
      v-if="showEditSheet"
      :sheet="showEditSheet"
      :item="selectedEquipement"
      :typeItem="selectedItemType"
      :interventions="interventions"
      :equipements="equipementByDomaine"
      @close-sheet="handleOnClose()"
    >
    </BottomSheetEditItem>
  </v-bottom-sheet>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import axios from '@/plugins/axios';
import { interventionsEndpoint } from '@/api/apiUrls';
import BottomSheetEditItem from '@/components/BottomSheetEditItem.vue';
import InterventionAutoSaveForm from './interventionAutoSaveForm.vue';
import ShutterBar from './ShutterBar.vue';

export default {
  props: ['sheet', 'inter', 'interventions'],
  components: {
    InterventionAutoSaveForm,
    ShutterBar,
    BottomSheetEditItem,
  },
  data() {
    return {
      formKey: 0,
      saving: false,
      needToQuit: false,
      showEditSheet: false,
      updateData: null,
      refresh: false,
      calculation: null,
      equipmentId: null,
      equipementByDomaine: [],
    };
  },
  computed: {
    ...mapState({
      selectedItemType: state => state.selectedItemType,
    }),
    ...mapGetters('batiments', { selectedBatiment: 'selected' }),
    ...mapGetters({
      selectedItem: 'selectedItem',
      selectedEquipement: 'equipements/selected',
      batimentEquipements: 'equipements/selectedBatimentEquipements',
      selectedMission: 'missions/selected',
      getEquipementFormTemplate: 'formTemplates/getEquipementFormTemplate',
    }),
    selectedItemId() {
      if (this.selectedItem) {
        return this.selectedItem.id;
      }
      return '';
    },
    // intervention() {
    //   if (this.inter && this.inter.intervention) {
    //     return this.inter.intervention;
    //   }
    //   return null;
    // },
    // itemType() {
    //   if (this.inter && this.inter.itemType) {
    //     return this.inter.itemType;
    //   }
    //   return '';
    // },
    // itemName() {
    //   if (this.inter && this.inter.itemName) {
    //     return this.inter.itemName;
    //   }
    //   return '';
    // },
  },
  async created() {
    await this.$store
      .dispatch('changeSelectedItem', {
        type: 'equipements',
        id: this.inter.item.equipement,
      });
    await this.$store.dispatch('formTemplates/fetchEquipementTemplate', {
      type: this.selectedEquipement.type,
      missionId: this.selectedMission.id,
    });
    this.equipementByDomaine = this.batimentEquipements.filter(equip => equip
      .libelleDomaine === this.selectedEquipement.libelleDomaine);
  },
  watch: {
    sheet(newValue, oldValue) {
      // on sheet openning
      if (newValue && !oldValue) {
        this.formKey += 1;
      }
    },
    showEditSheet(newValue) {
      if (!newValue) {
        axios.get(`${interventionsEndpoint}/${this.inter.item.id}`)
          .then(((response) => {
            this.calculation = response.data;
          }));
      }
    },
    saving(newValue) {
      if (!newValue && this.needToQuit) {
        this.closeModal();
      }
    },
  },
  methods: {
    handleQuit() {
      if (!this.saving) {
        this.closeModal();
      } else {
        this.needToQuit = true;
      }
    },
    handleOnClose() {
      this.showEditSheet = false;
      this.$emit('close-sheet');
      this.closeModal();
    },

    async selectEquipement() {
      await this.$store
        .dispatch('equipements/changeSelected', this.inter
          .item.equipement).then(() => {
          this.showEditSheet = true;
        });
    },
    closeModal() {
      this.needToQuit = false;
      this.showEditSheet = false;
      this.$emit('close-sheet');
    },
  },
};
</script>
<style lang="scss" scoped>
  .column-wrapper {
    display: flex;
    flex-flow: row wrap;
  }

  .right-fixed-bar {
    margin-top:60px;
    position: absolute;
    width: 20rem;
    right: 0rem;
    height: 100%;
    background-color: #f9f9f9;
    padding: 1rem;
    overflow: auto;
  }

  .sheet-wrapper {
    // padding: 1rem;
    height: 100%;
    overflow: auto;
    background-color: #f9f9f9;

  }

  .wrapper-form{
    width: 100%;
    text-align: center;
    padding-right: 20rem;
  }

  .title {
    text-align: center;
  }
  .wrapper-titles{
    position: fixed;
    text-align: center;
    width: 100%;
    background-color: #2D4388;
    z-index: 10;
    color:white !important;
    height: 64px;
  }

  .wrapper-content {
    margin-top: 5rem;
  }

  .wrapper-title-link {
    background-color: white;
    text-decoration: underline;
    padding:0 0.5rem 0 0.5rem;
  }

  a:hover {
    background-color: rgb(234, 146, 66);
  }
  </style>
