import api from '@/api';
/* eslint no-console: ["error", { allow: ["error", "log"] }] */
/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = {
  compteurs: [],
};

const getters = { };

const actions = {
  createNew({ commit }) {
    const newCompteur = {
      id: `tempCompt${Math.random()}`,
      nomCompteur: 'Nouveau Compteur',
      adresse: '',
      numeroCompteur: '',
      pdl: null,
      energie: '',
      coefCo2: null,
      iriRCU: '',
    };

    commit('addCompteurs', [newCompteur]);
    // dispatch('changeSelectedItem', { item: newBatiment, type: 'batiments' }, { root: true });

    return newCompteur;
  },

  /**
   * Fetch one compteur by it's id from the server and update the store
   */
  refreshItem({ commit }, compteurId) {
    return new Promise((resolve) => {
      api.compteurs.get(compteurId).then((response) => {
        commit('addCompteurs', [response]);
        resolve({ ...response });
      });
    });
  },

  /**
   * Fetch all compteurs from the server and update the store
   */
  fetchAll({ commit }) {
    return api.compteurs.getAll().then(response => commit('addCompteurs', response));
  },

  /**
   * Fetch all compteurs by parent client id
   * @param {String} clientId - id of the parent client
   */
  fetchAllByClient({ commit }, clientId) {
    return api.compteurs.getAllByClient(clientId).then(response => commit('addCompteurs', response));
  },

  /**
   * Fetch all compteurs by site
   * @param {String} siteId - id of the site
   */
  fetchAllBySite({ commit }, siteId) {
    return api.compteurs.getAllBySite(siteId).then(response => commit('addCompteurs', response));
  },

  /**
   * Load all compteurs stored offline in pouchDB
   */
  loadOffline({ commit }) {
    return api.compteurs.loadOffline().then(response => commit('addCompteurs', response));
  },

  /**
   * Save a compteur on the server (by PUT) and update the store
   */
  save({ commit }, payload) {
    return new Promise((resolve, reject) => {
      if (typeof (payload.id) === 'string' && payload.id.startsWith('tempCompt')) {
        api.compteurs.create(payload.data, payload.id).then((response) => {
          commit('deleteNewCompteurs', payload.id);
          commit('addCompteurs', [response]);
          resolve({ ...response });
        }).catch(error => reject(error));
      } else {
        api.compteurs.update(payload.id, payload).then((response) => {
          commit('addCompteurs', [response]);
          resolve({ ...response });
        });
      }
    });
  },

  /** Delete factures in Compteur
   */
  deleteFactures({ dispatch }, payload) {
    api.compteurs.deleteFactures(payload.compteurId, payload.facturesIds).then(() => {
      payload.facturesIds.facture.forEach((factureId) => {
        dispatch('factures/deleteFactureByCompteur', factureId, { root: true });
      });
    });
  },
};

const mutations = {
  deleteNewCompteurs(state, tempIdCompteur) {
    state.compteurs = state.compteurs.filter(item => item.id !== tempIdCompteur);
  },

  addCompteurs(state, newCompteurs) {
    newCompteurs.forEach((newCompteur) => {
      const stateIndex = state.compteurs
        .findIndex(stateCompteur => stateCompteur.id === newCompteur.id);

      if (stateIndex >= 0) {
        state.compteurs.splice(stateIndex, 1, newCompteur);
      } else {
        state.compteurs.push(newCompteur);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
