<template>
  <ValidationObserver
    class="form-wrapper"
    tag="div"
    ref="formObserver"
  >
    <div class="form-row-1">
      <ValidationProvider
        v-slot="{ errors }"
        name="numero"
        rules="required"
        slim
      >
        <v-text-field
          class="field-numero"
          v-model.number="form.fields.numero"
          outlined
          dense
          type="number"
          label="numéro"
          :error-messages="errors"
          @change="handleFormUpdate()"
        >
        </v-text-field>
      </ValidationProvider>

      <ValidationProvider
        v-slot="{ errors }"
        name="nom"
        rules="required"
        slim
      >
        <v-text-field
          class="field-nom"
          v-model="form.fields.nom"
          outlined
          dense
          label="nom"
          :error-messages="errors"
          @change="handleFormUpdate()"
        >
        </v-text-field>
      </ValidationProvider>
    </div>

    <div class="form-row-2">
      <ValidationProvider
        v-slot="{ errors }"
        name="description"
        rules="maxLength:650"
        slim
      >
        <v-textarea
          v-model="form.fields.description"
          outlined
          dense
          label="description"
          counter="650"
          :error-messages="errors"
          @change="handleFormUpdate()"
        >
        </v-textarea>
      </ValidationProvider>
    </div>

    <div class="form-row-3"
      v-if="!creation"
    >
      <v-icon x-large color="primary">mdi-lightning-bolt-circle</v-icon>
      <v-icon x-large color="primary">mdi-molecule-co2</v-icon>
      <v-icon x-large color="primary">mdi-file-document-multiple-outline</v-icon>
      <v-icon x-large color="primary">mdi-currency-eur</v-icon>
      <v-icon x-large color="primary">mdi-clock-fast</v-icon>
    </div>
    <div class="form-row-3"
      v-if="!creation"
    >
      <ValueWithoutIcon
        title="Gain énergétique"
        :value="parseFloat(gain).toLocaleString('fr')"
        unit="% (kWhEF)"
      >
      </ValueWithoutIcon>

      <ValueWithoutIcon
        title="Gain CO2"
        :value="parseFloat(gainCo2).toLocaleString('fr')"
        unit="% (kgéqCO2)"
      >
      </ValueWithoutIcon>

      <ValueWithoutIcon
        title="Gain (€TTC/an)"
        :value="parseFloat(gainTtc).toLocaleString('fr')"
        unit="% (TTC)"
      >
      </ValueWithoutIcon>

      <ValueWithoutIcon
        title="Investissement"
        :value="parseFloat(invest).toLocaleString('fr')"
        unit="€HT"
      >
      </ValueWithoutIcon>

      <ValueWithoutIcon
        title="TRI Brut"
        :value="parseFloat(tpsRetour).toLocaleString('fr')"
        unit="an(s)"
      >
      </ValueWithoutIcon>

    </div>
    <div class="form-row-3"
      v-if="!creation"
    >
      <ValueWithoutIcon
        title="Ratio énergétique"
        :value="parseFloat(ratioGain).toLocaleString('fr')"
        unit="kWhEF/m²SP"
      >
      </ValueWithoutIcon>

      <ValueWithoutIcon
        title="Ratio CO2"
        :value="parseFloat(ratioGainCo2).toLocaleString('fr')"
        unit="kgéqCO2/m²SP"
      >
      </ValueWithoutIcon>

      <ValueWithoutIcon
        title="Ratio facture"
        :value="parseFloat(ratioGainTtc).toLocaleString('fr')"
        unit="€TTC/m²SP"
      >
      </ValueWithoutIcon>

      <ValueWithoutIcon
        title="Ratio d'investissement"
        :value="parseFloat(ratioInvest).toLocaleString('fr')"
        unit="€HT/m²SP"
      >
      </ValueWithoutIcon>

      <ValueWithoutIcon
        title="TRI Actualisé"
        :value="parseFloat(ratioTpsRetour).toLocaleString('fr')"
        unit="an(s)"
      >
      </ValueWithoutIcon>

    </div>
  </ValidationObserver>
</template>

<script>
import { mapState } from 'vuex';

import { ValidationObserver, ValidationProvider } from 'vee-validate';
import ValueWithoutIcon from '../ValueWithoutIcon.vue';

export default {
  components: {
    ValueWithoutIcon,
    ValidationObserver,
    ValidationProvider,
  },

  props: {
    scenario: {
      type: Object,
      default: () => {},
    },
    creation: {
      type: Boolean,
      default: false,
    },
    duplicate: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      form: {
        valid: true,
        touched: false,
        fields: {
          numero: null,
          nom: null,
          description: null,
        },
      },
    };
  },

  computed: {
    // ...mapState('scenarios'
    //   scenarios: 'scenarios/scenarios',
    // }),
    ...mapState('batiments', ['batiments']),
    ...mapState('scenarios', ['scenarios']),

    currentScenario() {
      if (this.scenario) {
        return this.scenarios.find(scenar => scenar.id === this.scenario.id);
      }
      return null;
    },

    invest() {
      if (
        this.currentScenario
        && this.currentScenario.interventions.length
        && this.currentScenario.calculations
        && this.currentScenario.calculations.investissementHt
      ) {
        return this.currentScenario.calculations.investissementHt;
      }
      return '-';
    },

    gain() {
      if (
        this.currentScenario
        && this.currentScenario.calculations
        && this.currentScenario.interventions.length
        && this.currentScenario.calculations.gainConsommationEfRelatif
      ) {
        return this.currentScenario.calculations.gainConsommationEfRelatif;
      }
      return '-';
    },

    tpsRetour() {
      if (
        this.currentScenario
        && this.currentScenario.calculations
        && this.currentScenario.interventions.length
        && this.currentScenario.calculations.tempsRetourBrut
      ) {
        return this.currentScenario.calculations.tempsRetourBrut;
      }
      return '-';
    },

    gainCo2() {
      if (
        this.currentScenario
        && this.currentScenario.calculations
        && this.currentScenario.interventions.length
        && this.currentScenario.calculations.gainEmissionCo2Relatif
      ) {
        return this.currentScenario.calculations.gainEmissionCo2Relatif;
      }
      return '-';
    },

    gainTtc() {
      if (
        this.currentScenario
        && this.currentScenario.calculations
        && this.currentScenario.interventions.length
        && this.currentScenario.calculations.gainCoutTtcRelatif
      ) {
        return this.currentScenario.calculations.gainCoutTtcRelatif;
      }
      return '-';
    },

    ratioInvest() {
      if (
        this.currentScenario
        && this.currentScenario.interventions.length
        && this.currentScenario.calculations
        && this.currentScenario.calculations.ratioInvestissementHtSurfacePlancher
      ) {
        return this.currentScenario.calculations.ratioInvestissementHtSurfacePlancher;
      }
      return '-';
    },

    ratioGain() {
      if (
        this.currentScenario
        && this.currentScenario.calculations
        && this.currentScenario.interventions.length
        && this.currentScenario.calculations.ratioConsommationEfSurfacePlancher
      ) {
        return this.currentScenario.calculations.ratioConsommationEfSurfacePlancher;
      }
      return '-';
    },

    ratioTpsRetour() {
      if (
        this.currentScenario
        && this.currentScenario.calculations
        && this.currentScenario.interventions.length
        && this.currentScenario.calculations.tempsRetourActualise
      ) {
        return this.currentScenario.calculations.tempsRetourActualise;
      }
      return '-';
    },

    ratioGainCo2() {
      if (
        this.currentScenario
        && this.currentScenario.calculations
        && this.currentScenario.interventions.length
        && this.currentScenario.calculations.ratioEmissionCo2SurfacePlancher
      ) {
        return this.currentScenario.calculations.ratioEmissionCo2SurfacePlancher;
      }
      return '-';
    },

    ratioGainTtc() {
      if (
        this.currentScenario
        && this.currentScenario.calculations
        && this.currentScenario.interventions.length
        && this.currentScenario.calculations.ratioCoutTtcSurfacePlancher
      ) {
        return this.currentScenario.calculations.ratioCoutTtcSurfacePlancher;
      }
      return '-';
    },
  },

  created() {
    if (this.scenario && !this.creation) {
      if (this.duplicate && this.scenario.nextValue) {
        this.form.fields.numero = this.scenario.nextValue;
      } else {
        this.form.fields.numero = this.scenario.numero;
      }
      if (this.duplicate) {
        this.form.fields.nom = `${this.scenario.nom}_copie`;
      } else {
        this.form.fields.nom = this.scenario.nom;
      }

      this.form.fields.description = this.scenario.description;
    }
  },

  async mounted() {
    this.form.valid = await this.$refs.formObserver.validate({ silent: true });
    this.$emit('form-updated', this.form);
  },

  methods: {
    async handleFormUpdate() {
      if (!this.form.touched) {
        this.form.touched = true;
      }
      this.form.valid = await this.$refs.formObserver.validate({ silent: true });
      this.$emit('form-updated', this.form);
    },
  },
};
</script>

<style lang="scss" scoped>

  .form-wrapper {
    height: 100%;
    width: 100%;

    padding: 1rem;

    .form-row-1 {
      width: 100%;
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;

      > * {
        margin-right: 1rem;
      }

      .field-numero {
        max-width: 6rem;
      }

      .field-nom {
        max-width: 20rem;
      }

    }

    .form-row-2 {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;
    }

    .form-row-3 {
      width: 100%;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
    }
  }
</style>
