<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >

    <div class="dialog-wrapper">
      <div class="dialog-title">
        <template v-if="create">Création d'un programme</template>
        <template v-else>Edition d'un programme</template>
      </div>


      <div class="dialog-content">
        <div class="uppper-fix-block">
          <ProgrammeForm
            v-if="show"
            :programme="programme"
            :creation="create"
            :interSelected="interSelected"
            @form-updated="handleFormUpdated($event)"
          ></ProgrammeForm>
        </div>

        <v-card outlined class="lower-scroll-block">
          <BatimentsScenariosList
            v-if="show"
            :initialList="programme.scenarios"
            :initialListInter="programme.interventionSites"
            @new-selection="handleChangeScenarios($event)"
            @new-selection-interventions="handleChangeInterventions($event)"
          >
          </BatimentsScenariosList>
        </v-card>
      </div>

      <div class="dialog-footer">
        <template v-if="create">
          <v-btn
            text
            large
            color="primary"
            @click="$emit('close')"
          >
            Annuler
          </v-btn>

          <v-btn
            depressed
            large
            color="primary"
            :disabled="!form.valid"
            :loading="saving"
            @click.native="handleQuit()"
          >
            Créer
          </v-btn>
        </template>

        <v-btn class="btn-close"
            v-else
            depressed
            large
            color="primary"
            :loading="saving"
            @click.native="$emit('close')"
          >
            Fermer
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import ProgrammeForm from './ProgrammeForm.vue';
import BatimentsScenariosList from '../scenarios/BatimentsScenariosList.vue';

export default {
  components: {
    ProgrammeForm,
    BatimentsScenariosList,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    programme: {
      type: Object,
      default: () => {},
    },
    create: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      interSelected: false,
      saving: false,
      form: {
        valid: true,
        touched: false,
        fields: {},
      },
    };
  },

  computed: {
    ...mapGetters({
      site: 'sites/selected',
    }),
  },

  watch: {
    show(newValue) {
      if (!newValue) {
        this.form = {
          valid: true,
          touched: false,
          fields: {},
        };
      }
    },
  },

  methods: {
    handleFormUpdated({ valid, touched, fields }) {
      this.form = {
        valid,
        touched: this.form.touched || touched,
        fields: {
          ...this.form.fields,
          ...fields,
        },
      };

      if (!this.create && this.form.touched && this.form.valid) {
        this.saveForm();
      }
    },

    handleChangeScenarios({ scenarios, touched }) {
      this.form.touched = this.form.touched || touched;
      this.form.fields.scenarios = scenarios.map(scenar => scenar.id);

      if (!this.create && this.form.touched && this.form.valid) {
        this.saveForm();
      }
    },

    handleChangeInterventions({ interventionSites, touched }) {
      this.form.touched = this.form.touched || touched;
      this.form.fields.interventionSites = interventionSites.map(scenar => scenar.id);

      if (!this.create && this.form.touched && this.form.valid) {
        this.saveForm();
      }
    },

    async saveForm() {
      this.saving = true;
      let programmeToSave = {};
      if (this.create) {
        this.form.fields.estImporte = false;
        const newProgramme = await this.$store.dispatch('programmes/createProgrammeOnSite', this.site.id);
        programmeToSave = {
          ...newProgramme,
          ...this.form.fields,
        };
      } else {
        programmeToSave = {
          ...this.programme,
          ...this.form.fields,
        };
      }

      await this.$store.dispatch('programmes/save', programmeToSave);
      if (programmeToSave.interventionSites && programmeToSave.interventionSites.length) {
        this.interSelected = true;
      } else {
        this.interSelected = false;
      }

      this.saving = false;
    },

    async handleQuit() {
      await this.saveForm();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  .dialog-wrapper {
    height: 100%;
    display: grid;
    grid-template-columns: 7rem 1fr 7rem;
    grid-template-rows: 4rem 1fr 4rem;
    grid-template-areas:
      "header header header"
      "content content content"
      "footer footer footer";
    background-color: $app-white;

    .dialog-title {
      grid-area: header;
      font-size: 2rem;
      font-weight: 500;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      background-color: $app-blue;
      color: $app-grey;
    }

    .dialog-content {
      grid-area: content;
      justify-self: center;
      overflow: hidden;
      width: 100%;
      max-width: 1200px;

      .lower-scroll-block {
        height: 100%;
        grid-area: lower;
        overflow: hidden;
        margin: 2rem;
      }
    }

    .dialog-footer {
      position: fixed;
      width: 100%;
      height: 64px;
      bottom: 0;
      margin-bottom: 0;
      -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      background-color: white;
      margin-right: 1.2rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > * {
        margin-right: 1rem;
      }
    }
  }
</style>
