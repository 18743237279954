<template>
  <div class="item-batiment">
    <div class="div-alert">
      <v-icon v-if="loopAlert(this.batiment.inAlert)" class="icon-warning">
        mdi-alert-circle
      </v-icon>
    </div>
    <v-list-item two-line ripple>
      <v-list-item-avatar>
        <v-img :src="imgUrl"></v-img>
      </v-list-item-avatar>

      <v-list-item-content>

        <v-list-item-title>
          {{ this.batiment.nom }}
        </v-list-item-title>
        <!-- <v-list-item-subtitle>
          {{ batiment.typeUsage }},
        </v-list-item-subtitle> -->
        <v-list-item-subtitle>
          {{ batiment.adresse }}
        </v-list-item-subtitle>
      </v-list-item-content>

      <v-list-item-action>
        <v-list-item-action-text v-text="typeUsage"></v-list-item-action-text>
      </v-list-item-action>
    </v-list-item>
  </div>
</template>

<script>
import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';

export default {
  props: ['batiment', 'typeUsage'],
  mixins: [
    UpdateItemAlertMixin,
  ],
  computed: {
    imgUrl() {
      if (this.batiment && this.batiment.imagePrincipale) {
        return `${this.batiment.imagePrincipale}/file`;
      }
      return '/img/icons/pictoCamera.jpg';
    },
  },
};
</script>

<style  lang="scss" scoped>
.item-batiment{
  display: flex;
}

.div-alert{
  width:2em;
  display: flex;
}

.icon-warning{
  color:orange;
}

</style>
