<template>
    <div>
      <h3 class="title">Arborescence des consommations énergétiques en kWhEF</h3>
      <div class="chart">
        <canvas id="chart" width="100" height="34"></canvas>
    </div>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import { Chart, LinearScale } from 'chart.js';
import { SankeyController, Flow } from 'chartjs-chart-sankey';
import ColorEnergieMixing from '@/mixins/ColorEnergieMixing';

Chart.register(LinearScale, SankeyController, Flow);

export default {
  name: 'App',
  mixins: [
    ColorEnergieMixing,
  ],
  computed: {
    ...mapState({
      sites: state => state.sites.sites,
      batiments: state => state.batiments.batiments,
      loading: state => state.loading.loading,
      repartitions: state => state.repartitions.repartitions,
      compteurs: state => state.compteurs.compteurs,
    }),
    ...mapState('repartitions', ['repartitions']),
    ...mapGetters({
      selectedSite: 'sites/selected',
      selectedMission: 'missions/selected',
      batimentsSite: 'batiments/selectedSiteBatiments',
    }),
    selectedView: {
      get() {
        return this.$store.state.selectedView;
      },
      set(value) {
        this.$store.commit('setSelectedView', value);
      },
    },
    siteCompteurs() {
      if (this.compteurs && this.selectedSite) {
        return this.compteurs
          .filter(compteur => this.selectedSite.compteurs.includes(compteur.id));
      }
      return [];
    },

  },
  // props: ['data', 'compteurs'],
  data() {
    return {
      dataSankey: [],
      listeOptionsPostes: [],
    };
  },

  mounted() {
    // this.populateGraph();
  },

  created() {
    this.selectedView = 'Analyser';
    this.$store.dispatch('listeOptions/fetchAllByType',
      {
        missionId: this.selectedMission.id,
        codeType: 'poste',
      }).then((result) => {
      this.listeOptionsPostes = result;
      this.$store.dispatch('compteurs/fetchAllBySite', this.selectedSite.id)
        .then(() => {
          this.siteCompteurs.forEach((compteur) => {
            this.$store.dispatch('repartitions/fetchRepartitionByIdCompteur', compteur.id)
              .finally(() => {
                if (compteur === this.siteCompteurs.slice(-1)[0]) {
                  this.populateGraph();
                }
              });
          });
        });
    });
  },

  methods: {
    populateGraph() {
      const ctx = document.getElementById('chart').getContext('2d');
      const datas = [];
      const colorData = [];
      this.repartitions.forEach((repartCompteurBat) => {
        const batiment = this.batimentsSite
          .filter(batimentSite => batimentSite.id === repartCompteurBat.batiment);
        const compteur = this.compteurs
          .filter(siteCompteur => siteCompteur.id === repartCompteurBat.compteur);
        if (compteur[0] && batiment[0]) {
          const color = this.getColorEnergie(compteur[0].energie);
          colorData.push(color);
          datas.push(
            {
              from: `${compteur[0].nomCompteur} (${compteur[0].energieLibelle})`,
              to: batiment[0].nom,
              flow: parseFloat(repartCompteurBat.calculations.consommationEfAnneeReference),
            },
          );
          Object.entries(repartCompteurBat.postes).forEach(([key, value]) => {
            const poste = key.split('-');
            const posteFiltered = this.listeOptionsPostes.filter(loPoste => loPoste.codeOption === `${poste[0]}-poste`);
            const consommationEf = parseFloat(
              repartCompteurBat.calculations.consommationEfAnneeReference,
            )
            * (parseFloat(value) / 100);
            if (parseFloat(value) !== 0) {
              colorData.push(color);
              datas.push(
                {
                  from: batiment[0].nom,
                  to: `${posteFiltered[0].libelle} - ${Math.round(consommationEf)} kWhEf (${batiment[0].nom})`,
                  flow: consommationEf,
                },
              );
            }
          });
        }
      });
      if (window.mySankey) {
        window.mySankey.destroy();
      }
      window.mySankey = new Chart(ctx, {
        type: 'sankey',
        data: {
          datasets: [
            {
              data: datas,
              options: {
                tooltips: {
                  enabled: true,
                },
              },
              colorFrom: c => colorData[c.dataIndex],
              colorTo: c => colorData[c.dataIndex],
              colorMode: 'gradient',
              labels: {
                a: 'Label A',
                b: 'Label B',
                c: 'Label C',
              },
            },
          ],
        },
      });
    },
  },

};
</script>

<style lang='scss' scoped>
.chart{
  margin:1em;
}

.title{
  text-align: center;
}
</style>
