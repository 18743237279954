<template>
  <ValidationProvider
    :name="formField.name"
    :rules="getRules()"
    v-slot="{ errors }">
    <span @click="insideInput()">
    <v-autocomplete
      :id="`select_${id}`"
      :items="entries"
      v-bind="fieldOptions"
      :error-messages="errors"
      v-model="localValue"
      @change="$emit('change', localValue)"
      :loading="loading"
      ref="selectRef"
      attach
      :required="editItem"
      :disabled="isDisabled()"
    >
      <template v-slot:label>
        <slot name="field-label"></slot>
      </template>
    </v-autocomplete>
    </span>
  </ValidationProvider>
</template>

<script>
import api from '@/api';
import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';
import axiosInstance from '../../plugins/axios';

export default {
  name: 'select-field',
  components: {
    ValidationProvider,
  },
  props: {
    commonOptions: Object,
    defaultValue: null,
    formField: Object,
    editItem: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.defaultValue,
      entries: [],
      loading: false,
      storeObject: false,
      id: 0,
      menuActive: false,
      forbidenRoles: [],
    };
  },
  computed: {
    ...mapGetters({
      selectedMission: 'missions/selected',
      selectedBatiment: 'batiments/selected',
      batimentInspectionsType: 'inspections/selectedMissionBatimentInspectionTypes',
      siteInspectionsType: 'inspections/selectedMissionSiteInspectionTypes',
      me: 'me/me',
    }),
    fieldOptions() {
      return {
        ...this.commonOptions,
        placeholder: this.formField.placeholder,
        itemText: 'libelle',
        itemValue: 'codeOption',
        returnObject: this.storeObject,
      };
    },
  },
  getActivatedMenu() {
    return this.menuActive;
  },
  async mounted() {
    this.$parent.$parent
      .$parent.$parent.$parent.$el.addEventListener('change', () => {
        if (this.$refs.selectRef && this.$refs.selectRef.isMenuActive && this.$refs
          .selectRef.selectedValues.length > 0) {
          this.$refs.selectRef.isMenuActive = false;
          this.menuActive = false;
        }
        if (this.$refs.selectRef) {
          if (this.menuActive) {
            this.$refs.selectRef.isMenuActive = true;
          } else {
            this.$refs.selectRef.isMenuActive = false;
          }
        }
      });
  },
  watch: {
    defaultValue(newValue) {
      this.localValue = newValue;
    },
  },
  created() {
    this.populateEntries();
    this.id = Math.random();
  },
  methods: {
    isDisabled() {
      let disabled = false;
      if (this.formField.permission) {
        this.forbidenRoles = this.$getRoleHierarchy(this.formField.permission);
        if (this.forbidenRoles.includes(this.me.roles[0])) {
          disabled = true;
        }
      }
      return disabled;
    },
    // gestion attribut refVersion de mission non obligatoire en edition mais obligatoire en créa
    getRules() {
      let rules = null;
      if (this.formField.options === 'allRefs' && this.formField.rules && this.editItem) {
        rules = this.formField.rules.replace('|required|', '|').replace('required|', '').replace('|required', '').replace('required', '');
      } else {
        return this.formField.rules;
      }
      return rules;
    },
    insideInput() {
      this.menuActive = !this.menuActive;
    },
    // build the array of possible values for the select
    async populateEntries() {
      if (Array.isArray(this.formField.options)) {
        this.entries = this.formField.options;
      }
      if (typeof this.formField.options === 'string') {
        this.loading = true;
        if (this.formField.options.startsWith('/api/')) {
          // if the options represent an api call
          // call the api and populate entries with the result
          this.storeObject = true;

          // extract the code type from the url
          // ex url : /api/ref/liste-options?codeType=performance
          const url = this.formField.options;
          const codeType = url.slice(url.lastIndexOf('=') + 1);

          const missionId = this.selectedMission.id;

          this.$store.dispatch('listeOptions/fetchAllByType', { missionId, codeType })
            .then((response) => { this.entries = response; })
            .finally(() => { this.loading = false; });
        } else {
          this.getCustomOptions(this.formField.options).then((result) => {
            this.entries = result;
            this.loading = false;
          });
        }
      }
    },

    // handle custom options code that represent complex dynamic request
    getCustomOptions(code) {
      return new Promise(async (resolve) => {
        if (code === 'idLinkedStructBat') {
          await axiosInstance.axiosInstance.get(`/api/missions/${this.selectedMission.id}/rapport-structures`, {
            params: {
              typeRapport: 'Batiment',
            },
          }).then((response) => {
            if (response.data.length) {
              this.structures = response.data;
              resolve(
                response.data
                  .map(struct => ({ libelle: struct.nom, codeOption: struct.id })),
              );
            }
          });
        }

        if (code === 'equipementCategorieCode') {
          await this.$store.dispatch('equipements/fetchAllDomaines', { missionId: this.selectedMission.id, majRef: false });
          await this.$store.dispatch('equipements/fetchAllCategories', { missionId: this.selectedMission.id, majRef: false });

          resolve(
            this.$store.state.equipements.activesCategories
              .filter(categ => categ.mission === this.selectedMission.id)
              .map(categ => ({
                libelle: `${categ.nom}
              (${this.$store.state.equipements.activesDomaines
                  .filter(domain => domain.id === categ.domaine)[0].nom})`,
                codeOption: categ.code,
              })),
          );
        }

        if (code === 'inspectionTypeCode-site') {
          resolve(
            this.siteInspectionsType
              .filter(inspec => inspec.mission === this.selectedMission.id)
              .map(inspec => ({ libelle: inspec.nom, codeOption: inspec.code })),
          );
        }

        if (code === 'inspectionTypeCode-batiment') {
          resolve(
            this.batimentInspectionsType
              .filter(inspec => inspec.mission === this.selectedMission.id)
              .map(inspec => ({ libelle: inspec.nom, codeOption: inspec.code })),
          );
        }

        if (code === 'attribut1' || code === 'attribut2') {
          const equipementTypesALL = await api
            .refEquipementTypes.getAllByMission(this.selectedMission.id);
          const list = [];
          equipementTypesALL.forEach((type) => {
            if (type
          && type.formTemplate[0]) {
              Object.keys(type.formTemplate[0].fields).forEach((attribut) => {
                if (type.formTemplate[0].fields[attribut]) {
                  list.push({
                    typeAttribut: 'spécifique',
                    libelle: type.formTemplate[0].fields[attribut].label,
                    code: type.formTemplate[0].fields[attribut].name,
                  });
                }
              });
            }
          });
          list.push({ typeAttribut: 'général', libelle: 'Description', code: 'description' });
          list.push({ typeAttribut: 'général', libelle: 'En alerte', code: 'inAlert' });
          list.push({ typeAttribut: 'général', libelle: 'Catégorie', code: 'libelleCategorie' });
          list.push({ typeAttribut: 'général', libelle: 'Domaine', code: 'libelleDomaine' });
          list.push({ typeAttribut: 'général', libelle: 'Type', code: 'libelleType' });
          list.push({ typeAttribut: 'général', libelle: 'Localisation local', code: 'local' });
          list.push({ typeAttribut: 'général', libelle: 'Niveau', code: 'niveau' });
          list.push({ typeAttribut: 'général', libelle: 'Performance', code: 'performance' });
          list.push({ typeAttribut: 'général', libelle: 'Vétusté', code: 'vetuste' });
          list.push({ typeAttribut: 'général', libelle: 'Nom', code: 'nom' });
          resolve(
            list.map(item => ({ libelle: item.libelle, codeOption: item.code })),
          );
        }

        if (code === 'annees') {
          const currentYear = new Date().getFullYear();
          const listAnnees = [];
          for (let i = currentYear; i >= 1979; i -= 1) {
            listAnnees.push(i);
          }
          resolve(
            listAnnees.map(annee => ({ libelle: annee.toString(), codeOption: annee.toString() })),
          );
        }

        if (code === 'batimentNiveaux') {
          // if (!this.$store.state.niveaux.niveaux.length) {
          //   await this.$store.dispatch('niveaux/fetchAll');
          // }
          await this.$store.dispatch('niveaux/fetchAllByBatiment', this.selectedBatiment.id);
          // return the list of niveaux of the selected batiment
          resolve(
            this.$store.state.niveaux.niveaux
              .filter(niveau => niveau.batiment === this.selectedBatiment.id)
              .map(niveau => ({ libelle: niveau.nom, codeOption: niveau.id })),
          );
        }

        if (code === 'batimentLocaux') {
          // if (!this.$store.state.equipements.equipements.length) {
          //   await this.$store.dispatch('equipements/fetchAll');
          // }

          await this.$store.dispatch('equipements/fetchAllByBatiment', this.selectedBatiment.id);
          // get the list of niveaux of the selected batiment
          // and map the local
          const batimentLocaux = this.$store.state.equipements.equipements
            .filter(equipement => equipement.batiment === this.selectedBatiment.id)
            .map(equipement => equipement.local);
          // return the deduplicated list of locaux
          resolve(
            batimentLocaux.filter((local, index) => batimentLocaux.indexOf(local) === index),
          );
        }

        if (code === 'allClients') {
          // if (!this.$store.state.clients.clients.length) {
          //   await this.$store.dispatch('clients/fetchAll');
          // }
          await this.$store.dispatch('clients/fetchAllListeOption');
          resolve(
            this.$store.state.clients.clients
              .map(client => ({ libelle: client.nom, codeOption: client.id })),
          );
        }

        if (code === 'allRefs') {
          let allref = [];
          await axiosInstance.axiosInstance.get('/api/referentiel-jsons').then((response) => {
            allref = response.data;
            if (!this.editItem) {
              const defaultRef = allref.filter(ref => ref.refCommun === true);
              this.localValue = defaultRef[0].id;
              this.$emit('change', this.localValue);
            }
          });
          resolve(
            allref.map(ref => ({ libelle: ref.version, codeOption: ref.id })),
          );
        }

        if (code === 'allCompteurs') {
          if (!this.$store.state.compteurs.backendCompteurs.length) {
            await this.$store.dispatch('compteurs/fetchAllCompteurs');
          }

          resolve(
            this.$store.getters['compteurs/allCompteurs']
              .map(compteur => ({ libelle: `${compteur.nomCompteur} / ${compteur.numeroCompteur}`, codeOption: compteur['@id'] })),
          );
        }

        if (code === 'allMissions') {
          // if (!this.$store.state.missions.missions.length) {
          //   await this.$store.dispatch('missions/fetchAll');
          // }
          await this.$store.dispatch('missions/fetchAll');
          resolve(
            this.$store.state.missions.missions
              .map(mission => ({ libelle: mission.nom, codeOption: mission.id })),
          );
        }

        if (code === 'allMissionSites') {
          // if (!this.$store.state.sites.sites.length
          // ) {
          //   await this.$store.dispatch('sites/fetchAll');
          // }
          await this.$store.dispatch('sites/fetchAllByMission', this.selectedMission.id);
          resolve(
            this.$store.state.sites.sites
              .filter(site => site.mission === this.selectedMission.id)
              .map(site => ({ libelle: site.nom, codeOption: site.id })),
          );
        }

        if (code === 'allTypesAudits') {
          // if (!this.$store.state.missions.typesAudits.length) {
          //   await this.$store.dispatch('missions/fetchAllTypesAudits');
          // }
          await this.$store.dispatch('missions/fetchAllTypesAudits');
          resolve(
            this.$store.state.missions.typesAudits
              .filter(type => type.afficher === true)
              .map(type => ({ libelle: type.nom, codeOption: type.id })),
          );
        }
        resolve([]);
      });
    },
  },
};
</script>
