<template>
  <div class="type-card">
    <h3><slot name="title"></slot></h3>
    <slot name="content"></slot>
  </div>
</template>

<script>
export default {
  props: ['typeInspection'],
};
</script>

<style lang="scss" scoped>
 .type-card {
    padding: 1rem 2rem 1rem 2rem;
    margin-bottom: 0.5rem;
    cursor: pointer;

    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;

    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    width: 20rem;
    height: 7rem;
  }

  .type-card h3 {
    font-weight: normal;
  }
</style>
