/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  sitesEndpoint,
  programmesEndpoint,
  scenariosEndpoint,
  interventionSitesEndpoint,
} from './apiUrls';

export default {
  db: new PouchDB('programmes'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch

    const _id = obj._id || obj.id.toString();
    const meta = {};
    const site = obj.site ? obj.site.replace(`${sitesEndpoint}/`, '') : '';

    return {
      ...obj,
      _id,
      meta,
      id: String(obj.id),
      site,
      scenarios: obj.scenarios.map(inter => inter.replace(`${scenariosEndpoint}/`, '')),
      interventionSites: obj.interventionSites.map(inter => inter.replace(`${interventionSitesEndpoint}/`, '')),
    };
  },

  async create(programme) {
    // si l'objet est dépendant d'un objet supérieur pas encore créé
    const siteId = programme.site || '';
    if (siteId.startsWith('temp')) {
      throw Error('vous ne pouvez sauvegarder un programme sur un site non encore syncronisé');
    }

    const programmeToCreate = { ...programme };
    delete programmeToCreate.id;
    delete programmeToCreate._id;
    delete programmeToCreate.meta;

    const response = await axiosInstance.axiosInstance.post(programmesEndpoint, programmeToCreate);
    const savedProgramme = this.convertBackendObject(response.data);

    return pouchUtil.addDoc(savedProgramme, this.db);
  },

  async update(programme) {
    const programmeToUpdate = { ...programme };
    delete programmeToUpdate.id;
    delete programmeToUpdate._id;
    delete programmeToUpdate.meta;

    const response = await axiosInstance.axiosInstance.put(`${programmesEndpoint}/${programme.id}`, programmeToUpdate);
    const savedProgramme = this.convertBackendObject(response.data);

    // si la sauvegarde en ligne à reussie, on sauvegarde dans pouchDB et on retourne le resultat
    return pouchUtil.addDoc(savedProgramme, this.db, { updated: true });
  },

  /**
   * delete a programme from pouchDB and the server
   */
  async delete(programmeId) {
    // delete the intervention from pouchDB
    await pouchUtil.deleteDoc(programmeId, this.db);
    // if the delete from pouchDB is sucessfull, delete from the server
    return axiosInstance.axiosInstance.delete(`${programmesEndpoint}/${programmeId}`);
  },

  /**
   * get all programmes for a given site
   * @param {String} siteId - id of the parent site
   */
  async getAllBySite(siteId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${programmesEndpoint}?site=${siteId}`);
      const programmes = response.data
        .map(programme => this.convertBackendObject(programme));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(programmes, this.db);
    } catch (e) {
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByField('site', siteId, this.db);
    }
  },
};
