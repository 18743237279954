import api from '@/api';

/* eslint no-console: ["error", { allow: ["error", "log"] }] */
/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = {
  clients: [],

  selectedId: null,
};

const getters = {
  selected: state => state.clients.find(client => client.id === state.selectedId),
};

const actions = {

  /**
   * Create a new Client and add it to the state
   */
  createNew({ commit }) {
    const newClient = {
      id: `tempCli${Math.random()}`,
      nom: 'Nouveau Client',
      adresse: '',
      codePostal: '',
      ville: '',
      nomContact: '',
      prenomContact: '',
      fonctinContact: '',
      telContact: '',
      mailContact: '',
      serviceDemandeur: '',
    };

    // Add new client and change featured item in Store
    commit('addClients', [newClient]);
    return newClient;
  },

  save({ commit }, payload) {
    return new Promise((resolve, reject) => {
      // if the client doesn't exhist on the backend, create it by post
      if (payload.id.startsWith('tempCli')) {
        api.clients.create(payload.data)
          .then((response) => {
            commit('deleteClient', payload.id);
            commit('addClients', [response]);
            resolve({ ...response });
          })
          .catch(error => reject(error));
      // else update it by put
      } else {
        api.clients.update(payload.id, payload.data)
          .then((response) => {
            commit('addClients', [response]);
            resolve({ ...response });
          })
          .catch(error => reject(error));
      }
    });
  },

  /**
   * Fetch all clients from the backend and update the store
   */
  fetchAll({ commit }) {
    return api.clients.getAll().then(response => commit('addClients', response));
  },

  fetchAllListeOption({ commit }) {
    return api.clients.getAllListeOption().then(response => commit('addClients', response));
  },

  delete({ commit }, client) {
    return api.clients.delete(client.id)
      .then(() => commit('deleteClient', client.id));
  },

  refreshItem({ commit }, clientId) {
    return api.clients.get(clientId).then((response) => {
      commit('addClients', [response]);
      return { ...response };
    });
  },

  /**
   * Load all clients stored offline in pouchDB
   */
  loadOffline({ commit }) {
    return api.clients.loadOffline().then(response => commit('addClients', response));
  },

  async changeSelected({
    commit, getters, dispatch, state,
  },
  clientId) {
    if (state.selectedId !== clientId) {
      commit('changeSelectedId', clientId);

      if (!getters.selected) {
        await dispatch('refreshItem', clientId);
      }
    }
  },

  async clearSelected({ commit }) {
    await commit('changeSelectedId', null);
  },
};

const mutations = {
  addClients(state, newClients) {
    newClients.forEach((newClient) => {
      const stateIndex = state.clients.findIndex(stateClient => stateClient.id === newClient.id);
      if (stateIndex >= 0) {
        state.clients.splice(stateIndex, 1, newClient);
      } else {
        state.clients.push(newClient);
      }
    });
  },

  deleteClient(state, clientId) {
    state.clients.splice(state.clients.findIndex(client => client.id === clientId), 1);
  },

  changeSelectedId(state, clientId) {
    state.selectedId = clientId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
