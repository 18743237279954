<template>
  <div class="value-icon-wrapper">
    <v-icon :x-large="large" class="value-icon-icon">{{ icon }}</v-icon>
    <div :class="[large ? 'value-icon-title' : 'value-icon-title-small']">
      {{ title }}
    </div>
    <div class="value-icon-data">
      <span :class="[large ? 'value-icon-value' : 'value-icon-value-small']">{{ value }}</span>
      <span :class="[large ? 'value-icon-unit' : 'value-icon-unit-small']"> {{ unit }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: 'Nom du champ',
    },
    value: {
      type: null,
      default: 'valeur',
    },
    unit: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'mdi-progress-question',
    },
    large: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss" scoped>
    .value-icon-wrapper {
      display: grid;
      grid-template-columns: 60px auto;
      grid-template-rows: 2rem 2rem;

      .value-icon-icon {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 3;
        color: $app-blue;
      }

      .value-icon-title {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;

        font-weight: 300;
        font-size: 1rem;

        justify-self: start;
        align-self: end;
      }

      .value-icon-title-small {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 1;
        grid-row-end: 2;

        font-weight: 300;
        font-size: 0.8rem;

        justify-self: start;
        align-self: end;
      }

      .value-icon-data {
        grid-column-start: 2;
        grid-column-end: 3;
        grid-row-start: 2;
        grid-row-end: 3;

        justify-self: start;
        align-self: start;

        .value-icon-value {
          font-weight: 500;
          font-size: 1.4rem;
        }

        .value-icon-value-small {
          font-weight: 500;
          font-size: 0.8rem;
        }

        .value-icon-unit {
          font-weight: 400;
          font-size: 0.8rem;
        }

        .value-icon-unit-small {
          font-weight: 400;
          font-size: 0.7rem;
        }
      }

    }
</style>
