<template>
  <div class="content-wrapper">
    <div
      v-if="itemType === 'equipement'"
      class="diag-row-1"
    >
      <span><v-icon>mdi-toolbox-outline</v-icon> Équipement</span>
      <span class="diag-row-1-right">{{ item.nom }}</span>
    </div>

    <div
      v-if="itemType === 'inspection'"
      class="diag-row-1"
    >
      <span><v-icon>mdi-notebook-outline</v-icon> Inspection</span>
      <span class="diag-row-1-right">{{ item.nomType }}</span>
    </div>

    <div
      v-if="itemType === true"
      class="diag-row-1"
    >
      <span><v-icon>mdi-notebook-outline</v-icon> Importée</span>
    </div>

    <div class="diag-row-2">
      <ValueWithIcon
        title="Gain kWhEF"
        :value="parseFloat(inter.calculations.gainConsommationEfRelatif).toLocaleString('fr')"
        unit="%"
        icon="mdi-lightning-bolt-circle"
      >
      </ValueWithIcon>

      <ValueWithIcon
        title="Gain CO2"
        :value="parseFloat(inter.calculations.gainEmissionCo2Relatif).toLocaleString('fr')"
        unit="%"
        icon="mdi-molecule-co2"
      >
      </ValueWithIcon>

      <ValueWithIcon
        title="Gain (€TTC/an)"
        :value="parseFloat(inter.calculations.gainCoutTtcRelatif).toLocaleString('fr')"
        unit="%"
        icon="mdi-file-document-multiple-outline"
      >
      </ValueWithIcon>

      <ValueWithIcon
        title="Investissement"
        :value="parseFloat(inter.calculations.investissementHt).toLocaleString('fr')"
        unit="€HT"
        icon="mdi-currency-eur"
      >
      </ValueWithIcon>

      <ValueWithIcon
        title="TRI Brut"
        :value="parseFloat(inter.calculations.tempsRetourInvestissementBrut).toLocaleString('fr')"
        unit="an(s)"
        icon="mdi-clock-fast"
      >
      </ValueWithIcon>
    </div>
  </div>
</template>

<script>
import ValueWithIcon from '../ValueWithIcon.vue';

export default {
  components: {
    ValueWithIcon,
  },
  props: ['inter', 'item', 'itemType'],
  data() {
    return {
    };
  },
};
</script>
<style lang="scss" scoped>
  .content-wrapper{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
  }

  .diag-row-1 {
    display: flex;
    flex-flow: row-wrap;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 1rem;

    font-weight: 300;
    font-size: 1rem;

    diag-row-1-right {
      margin-left: auto;
      margin-right: 1rem;
      text-align: right;
    }
  }

  .diag-row-2 {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;

  }
</style>
