<template>
  <div class="card">
    <v-icon
      class="iconExpend"
      color="primary"
      @click="showForm = !showForm"
      large
      :disabled="getCasIntervention !== 6 ? false : true"
      >
      {{ showForm ? `mdi-minus-circle-outline`:`mdi-plus-circle-outline` }}
    </v-icon>
    <ValidationObserver ref="observer">
      <TitleWithInformations
        title="Attributs de gains"
        :precision="form.fields.precisionTechnique"
        :showParentInformation="showForm"
      >
      </TitleWithInformations>
      <div class="form-wrapper" v-if="getCasIntervention !== 6">
        <v-form v-if="showForm">
          <div>
            <p class="errorConso" v-if="consoError">
            Attention, les gains générés sur "l'énergie avant l'intervention"
            sont supérieurs à la consommation affectée à cet usage et à cette
            énergie de votre bâtiment. Veuillez remettre en cohérence la clé de
            répartition de l'équipement et la répartition des compteurs d'énergie.</p>
          </div>
          <!-- TAUX DE COUVERTURE -->
          <ValidationProvider
            v-slot="{ errors }"
            name="tauxCouverture"
            rules="minEqVal:0|maxEqVal:100"
          >
            <v-text-field
              v-bind="fieldOptions"
              label="Taux de couverture"
              type='number'
              suffix='%'
              v-model.number="form.fields.tauxCouverture"
              :error-messages="errors"
              @change="handleNumberNull('tauxCouverture', $event)"
            >
              <template v-slot:append-outer>
                <v-icon :class="form.fields.tauxCouverture ?
                  'field-icon-success':'field-icon-warning'">
                  mdi-alert-circle
                </v-icon>
              </template>
            </v-text-field>
          </ValidationProvider>

          <!-- VALEUR DE PERFORMANCE -->
          <v-card
            v-if="getCasIntervention !== 8"
            elevation="1"
            class="mb-8">
            <v-text-field
              v-bind="fieldOptions"
              label="Valeur de performance"
              type='number'
              class='pa-1'
              :suffix="uniteValeurPerformance"
              :disabled="true"
              v-model.number="form.fields.valeurPerformance"
            >
            </v-text-field>

            <div class="radio-field-wrapper mt-n12 mb-n4 pa-1 fields-switch-label">
              <slot name="field-label">Définir une valeur de performance préférée : </slot>
              <v-switch
                v-model="form.fields.hasPerformanceFinale"
                @change="reInitPerfFinalField()"
              ></v-switch>
            </div>
            <ValidationProvider
              v-slot="{ errors }"
              name="performanceFinale"
              rules="minEqVal:0"
            >
              <div v-if="form.fields.hasPerformanceFinale">
                <v-text-field
                  v-bind="fieldOptions"
                  label="Valeur de performance préférée"
                  type="number"
                  class="mt-n4 pa-1 mb-n2"
                  v-model.number="form.fields.performanceFinale"
                  :error-messages="errors"
                  @change="handleNumberNull('performanceFinale', $event)"
                >
                </v-text-field>
              </div>
            </ValidationProvider>
          </v-card>

          <!-- USAGE CONCERNE -->
            <v-select
              v-bind="fieldOptions"
              label="Usage concerné"
              :items="postes"
              item-text="libelle"
              item-value="codeOption"
              v-model="form.fields.usage"
              @change="changeConsoUsageDefault()"
              :loading="loadingPostes"
            >
              <template v-slot:append-outer>
                <v-icon :class="form.fields.usage ?
                  'field-icon-success':'field-icon-warning'">
                  mdi-alert-circle
                </v-icon>
              </template>
            </v-select>

          <!-- CONSOMMATION DE L'USAGE -->
          <v-card
            v-if="getCasIntervention !== 8"
            class="mb-8">
            <v-text-field
              v-bind="fieldOptions"
              label="Consommation de l'usage"
              type='number'
              suffix='kWh'
              class='pa-1'
              v-model.number="this.consoUsage"
              :disabled="true"
            >
            </v-text-field>
            <!-- CONSOMMATION DE L'USAGE PREFEREE -->
            <div class="radio-field-wrapper mt-n12 mb-n4 pa-1 fields-switch-label">
              <slot name="field-label">Définir une consommation d'usage préférée : </slot>
              <v-switch
                v-model="form.fields.hasConsoUsagePreferee"
                @change="reInitConsoUsageFinalField()"
              >
              </v-switch>
            </div>
            <ValidationProvider
              v-slot="{ errors }"
              name="consoUsagePreferee"
              rules="minEqVal:0"
            >
              <div v-if="form.fields.hasConsoUsagePreferee">
                <v-text-field
                  v-bind="fieldOptions"
                  label="Consommation de l'usage préférée"
                  type='number'
                  suffix="kWh"
                  class="mt-n4 pa-1 mb-n2"
                  v-model.number="form.fields.consoUsagePreferee"
                  :error-messages="errors"
                  @change="handleNumberNull('consoUsagePreferee', $event)"
                >
                </v-text-field>
              </div>
            </ValidationProvider>
            <v-expansion-panels>
              <v-expansion-panel class="expansion-panel">
                <v-expansion-panel-header @click="toggleValue()"
                  class="expansion-panel-header" disable-icon-rotate>
                  <template v-slot:actions >
                    <p v-if="!toggle" class="expansion-panel-header-text">
                      <a>Plus de détails...</a>
                    </p>
                    <p v-else class="expansion-panel-header-text">
                      <a> Moins de détails</a>
                    </p>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <!-- VALEUR DE CLE REPARTITION -->

                  <v-text-field
                    v-bind="fieldOptions"
                    label="Valeur de clé de répartition"
                    type='number'
                    class="mt-n4 pa-1 mb-n2"
                    :disabled="true"
                    v-model.number="form.fields.cleRepartitionEquipement"
                  >
                  </v-text-field>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="performanceFinale"
                    rules="minEqVal:0"
                    :error-messages="errors"
                  >
                  </ValidationProvider>
                  <!-- VALEUR DE LA CONSOMMATION IMPACTEE -->
                  <v-text-field
                    v-bind="fieldOptions"
                    label="Valeur de la consommation impactée"
                    type='number'
                    class='pa-1'
                    :disabled="true"
                    v-model.number="form.fields.consommationEfImpactee"
                  >
                  </v-text-field>
                  <ValidationProvider
                    v-slot="{ errors }"
                    name="performanceFinale"
                    rules="minEqVal:0"
                    :error-messages="errors"
                  >
                  </ValidationProvider>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
          <!-- COEF DE PONDERATION -->
          <ValidationProvider
            v-slot="{ errors }"
            name="coefPonderation"
          >
            <v-text-field
              v-if="getCasIntervention !== 8"
              v-bind="fieldOptions"
              label="Coefficient de pondération du gain"
              type='number'
              v-model.number="form.fields.coefPonderation"
              :error-messages="errors"
              @change="handleNumberNull('coefPonderation', $event)"
            >
            </v-text-field>
          </ValidationProvider>

          <!-- TYPE ENERGIE AVANT INTER -->
            <v-select
              v-bind="fieldOptions"
              label="Type d'énergie avant l'intervention"
              :items="energies"
              item-text="libelle"
              item-value="codeOption"
              v-model="form.fields.energieAv"
              @change="handleFormUpdate()"
              :loading="loadingEnergies"
              attach
            >
              <template v-slot:append-outer>
                <v-icon :class="form.fields.energieAv ?
                  'field-icon-success':'field-icon-warning'">
                    mdi-alert-circle
                </v-icon>
              </template>
            </v-select>

          <!-- TYPE ENERGIE APRES INTER -->
          <v-select
            v-bind="fieldOptions"
            label="Type d'énergie après l'intervention"
            :items="energies"
            item-text="libelle"
            item-value="codeOption"
            v-model="form.fields.energieAp"
            @change="handleChangeEnergieAp()"
            :loading="loadingEnergies"
            attach
          >
            <template v-slot:append-outer>
              <v-icon :class="form.fields.energieAp ?
                'field-icon-success':'field-icon-warning'">
                  mdi-alert-circle
              </v-icon>
            </template>
          </v-select>
        </v-form>
      </div>
      <div v-else> <p>Cette intervention n'est pas génératrice de gain énergétique.</p></div>
    </ValidationObserver>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { mapState, mapGetters } from 'vuex';
import axios from '@/plugins/axios';
import { interventionsEndpoint } from '@/api/apiUrls';
import TitleWithInformations from '../fields/TitleWithInformations-field.vue';

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    TitleWithInformations,
  },

  props: ['intervention', 'creation', 'updatedCalulation', 'errorConso'],
  data() {
    return {
      errors: false,
      showForm: true,
      consoError: false,
      showTooltipCoeff: false,
      toggle: false,
      form: {
        valid: true,
        touched: false,
        fields: {
          tauxCouverture: null,
          valeurPerformance: null,
          performanceFinale: null,
          hasPerformanceFinale: null,
          consoUsagePreferee: null,
          hasConsoUsagePreferee: null,
          usage: null,
          coefPonderation: null,
          energieAv: null,
          energieAp: null,
          cleRepartitionEquipement: null,
          consommationEfImpactee: null,
        },
      },
      updatedDetails: false,
      fieldOptions: {
        outlined: true,
        dense: true,
      },
      loadingPostes: false,
      loadingEnergies: false,
    };
  },
  computed: {
    ...mapState('listeOptions', { listesOptions: 'all' }),
    ...mapState('equipements', { batimentEquipement: 'equipements' }),

    ...mapGetters({
      selectedBatiment: 'batiments/selected',
      postes: 'listeOptions/selectedMissionPostes',
      energies: 'listeOptions/selectedMissionEnergies',
    }),

    interventionType() {
      if (this.listesOptions) {
        return this.listesOptions.find(opt => opt.codeOption === this.intervention.option);
      }
      return null;
    },
    getEquipement() {
      return this.batimentEquipement
        .find(equipement => equipement.id === this.intervention.equipement);
    },
    getCasIntervention() {
      if (this.intervention.referentielValues) {
        return parseInt(this.intervention.referentielValues.option_casIntervention, 10);
      }
      return null;
    },
    consoUsage() {
      return parseFloat(this.getConsoUsageDefault());
    },

    uniteValeurPerformance() {
      if (this.intervention && this.intervention.referentielValues) {
        return this.intervention.referentielValues.option_unite;
      }
      return '';
    },
  },

  async created() {
    if (!this.postes.length) {
      this.loadingPostes = true;
      await this.$store.dispatch('listeOptions/fetchAllByType', { codeType: 'poste' });
      this.loadingPostes = false;
    }
    if (!this.energies.length) {
      this.loadingEnergies = true;
      await this.$store.dispatch('listeOptions/fetchAllByType', { codeType: 'energie' });
      this.loadingEnergies = false;
    }
    if (this.creation) {
      this.initFormValues();
      this.getPlusDetails();
    } else {
      this.consoError = this.errorConso;
      this.form.fields = {
        tauxCouverture: this.intervention.tauxCouverture,
        valeurPerformance: this.intervention.referentielValues.typeIntervention_valeurPerformance,
        performanceFinale: this.intervention.performanceFinale,
        hasPerformanceFinale: this.intervention.hasPerformanceFinale,
        usage: this.intervention.usage,
        consoUsagePreferee: this.intervention.consoUsagePreferee,
        hasConsoUsagePreferee: this.intervention.hasConsoUsagePreferee,
        coefPonderation: this.intervention.coefPonderation,
        energieAv: this.intervention.energieAv,
        energieAp: this.intervention.energieAp,
        unite: this.intervention.referentielValues.unite,
        precisionTechnique: this.intervention.referentielValues.option_precisionTechnique,
      };
      this.getPlusDetails();
    }
  },
  watch: {
    errorConso(newValue) {
      this.consoError = newValue;
    },
    updatedCalulation(newValue) {
      this.updatedConsommation(newValue);
    },
  },
  methods: {
    handleChangeEnergieAp() {
      this.$emit('energieAp-updated', this.form.fields.energieAp);
      this.handleFormUpdate();
    },
    reInitPerfFinalField() {
      if (!this.form.fields.hasPerformanceFinale) {
        this.form.fields.performanceFinale = null;
      }
      this.handleFormUpdate();
    },
    reInitConsoUsageFinalField() {
      if (!this.form.fields.hasConsoUsagePrefereee) {
        this.form.fields.consoUsagePreferee = null;
      }
      this.handleFormUpdate();
    },

    handleNumberNull(nameField, newValue) {
      if (newValue === false || newValue === '') {
        this.form.fields[nameField] = null;
      }

      this.handleFormUpdate();
    },

    getConsoUsageDefault() {
      const { usage } = this.form.fields;
      if (usage
        && this.selectedBatiment
        && this.selectedBatiment.calculations.consommationEfPostes
        && this.selectedBatiment.calculations.consommationEfPostes[usage]
      ) {
        return this.selectedBatiment.calculations.consommationEfPostes[usage];
      }
      return 0;
    },
    getPlusDetails() {
      this.setCleRepartition();
      if (this.updatedDetails) {
        this.form.fields.consommationEfImpactee = this.intervention
          .calculations.consommationEfImpactee;
        this.$forceUpdate();
      } else if (this.intervention
        .calculations && this.intervention
        .calculations.consommationEfImpactee) {
        this.form.fields.consommationEfImpactee = this.intervention
          .calculations.consommationEfImpactee;
      }
    },
    // L'equipement de type bati possède plusieurs clé de repartition
    setCleRepartition() {
      if (this.getEquipement.attributs.clederepartitionsop) {
        this.form.fields.cleRepartitionEquipement = this.getEquipement
          .attributs.clederepartitionsop;
      } else if (this.getEquipement.attributs.clederepartitionsph) {
        this.form.fields.cleRepartitionEquipement = this.getEquipement
          .attributs.clederepartitionsph;
      } else if (this.getEquipement.attributs.clederepartitionsv) {
        this.form.fields.cleRepartitionEquipement = this.getEquipement
          .attributs.clederepartitionsv;
      } else if (this.getEquipement.attributs.clederepartitionspb) {
        this.form.fields.cleRepartitionEquipement = this.getEquipement
          .attributs.clederepartitionspb;
      } else if (this.getEquipement.attributs.clederepartition) {
        this.form.fields.cleRepartitionEquipement = this.getEquipement
          .attributs.clederepartition;
      } else if (!this.getEquipement.attributs.clederepartition) {
        this.form.fields.cleRepartitionEquipement = null;
      }
    },
    updatedConsommation(value) {
      this.setCleRepartition();
      this.updatedDetails = true;
      this.form.fields.consommationEfImpactee = value.calculations.consommationEfImpactee;
      this.$forceUpdate();
    },
    changeConsoUsageDefault() {
      const { usage } = this.form.fields;
      if (usage
        && this.selectedBatiment
        && this.selectedBatiment.calculations.consommationEfPostes
        && this.selectedBatiment.calculations.consommationEfPostes[usage]
      ) {
        // eslint-disable-next-line max-len
        this.form.fields.consoUsage = parseFloat(this.selectedBatiment.calculations.consommationEfPostes[usage]);
      } else {
        this.form.fields.consoUsage = 0;
      }
      this.handleFormUpdate();
    },
    initFormValues() {
      if (this.interventionType) {
        const {
          tauxCouverture,
          valeurPerformance,
          performanceFinale,
          hasPerformanceFinale,
          consoUsagePreferee,
          hasConsoUsagePreferee,
          usage,
          energieInitiale,
          energieFinale,
          precisionTechnique,
        } = this.interventionType.data;
        if (tauxCouverture) {
          this.form.fields.tauxCouverture = parseInt(tauxCouverture, 10);
        }
        let energieInit = null;
        if (energieInitiale) {
          energieInit = energieInitiale;
        }
        this.form.fields.valeurPerformance = valeurPerformance;
        this.form.fields.performanceFinale = performanceFinale;
        this.form.fields.hasPerformanceFinale = hasPerformanceFinale;
        this.form.fields.consoUsagePreferee = consoUsagePreferee;
        this.form.fields.hasConsoUsagePreferee = hasConsoUsagePreferee;
        this.form.fields.usage = usage;
        this.form.fields.consoUsage = parseFloat(this.getConsoUsageDefault());
        this.form.fields.coefPonderation = 1;
        this.form.fields.energieAv = energieInit;
        this.form.fields.precisionTechnique = precisionTechnique;
        if (energieFinale) {
          this.form.fields.energieAp = energieFinale;
        } else if (energieInitiale) {
          this.form.fields.energieAp = energieInitiale;
        }
      }
      this.$emit('form-updated', this.form);
    },

    async handleFormUpdate() {
      if (!this.form.touched) {
        this.form.touched = true;
      }

      this.form.valid = await this.$refs.observer.validate({ silent: true });
      this.$emit('form-updated', this.form);
      setTimeout(() => {
        if (this.intervention) {
          axios.axiosInstance.get(`${interventionsEndpoint}/${this.intervention.id}`)
            .then(((response) => {
              // eslint-disable-next-line max-len
              this.form.fields.consommationEfImpactee = response.data.calculations.consommationEfImpactee;
              this.$forceUpdate();
            }));
        }
      }, 1000);
    },
    toggleValue() {
      this.toggle = !this.toggle;
    },
  },
};

</script>

<style lang="scss" scoped>
.wrap{
  display: flex;
}

.field-icon-warning-text{
  color:orange;

}

.field-icon-success-text{
  color:green;

}
.field-icon-warning{
  color:orange;

}

.field-icon-success{
  color:green;

}
.form-wrapper {
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
}

.fields-switch-label {
  font-size: 0.8rem;
}
.radio-field-wrapper {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}
.expansion-panel{
  margin-top: 0;
  .expansion-panel-header{
    height: 5px !important;
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    .expansion-panel-header-text{
      margin-top: 0;
      font-size: 15px;
    }
  }

}

.errorConso {
  color: red;
  font-size: 15px;
  text-align: center;
  width: 30em;
  margin: 0 auto;
  margin-bottom: 20px;
}

.iconExpend {
  float: right;
}
.card {
  width: 100%;
}
.formCard {
  display:flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
}
</style>
