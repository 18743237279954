<template>
  <v-bottom-sheet
    v-model="sheet"
    persistent
    fullscreen
  >
    <v-overlay v-if="changed">
    <v-progress-circular
      indeterminate
      size="64"
    ></v-progress-circular>
    </v-overlay>
    <v-sheet class="sheet-wrapper">

      <h1 class="bottom-sheet-title">Administration des photos</h1>
      <transition-group class="gallery-flex-wrapper" name="list-pics" tag="div">
        <div
          class="list-pics-item"
          v-for="(picture) in localValue"
          :key="picture.id"
        >
          <!-- image bloc -->
          <v-img
            class="picture-object"
            :class="{'picture-object-selected ': selectedPic === picture.url }"
            :src="picture.url"
            :lazy-src="getPictureLazySrc(picture)"
            @click="selectPicture(picture)"
          >
            <!-- spinner loader for the picture -->
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-0"
                align="center"
                justify="center"
              >
                <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
              </v-row>
            </template>

            <!-- star icon for principal picture -->
            <v-icon
              v-if="newPrincipalPic===picture.url"
              large
              class="selected-icon-star">
                mdi-star
            </v-icon>
          </v-img>
          <!-- action bar for selected picture -->
          <v-fade-transition>
            <div class="picture-actions" v-if="selectedPic === picture.url">
              <v-btn
                icon
                color="primary"
                @click="openGallery(picture)"
              >
                <v-icon>mdi-eye</v-icon>
              </v-btn>
              <v-btn
                icon
                color="primary"
                @click="showEditor = true"
              >
                <v-icon>mdi-circle-edit-outline</v-icon>
              </v-btn>

              <v-btn
                icon
                color="primary"
                :disabled="newPrincipalPic === picture.url"
                @click="changePrincipalPic(picture)"
              >
                <v-icon>mdi-star</v-icon>
              </v-btn>

              <v-btn
                icon
                color="red"
                class="delete-button"
                :disabled="localValue.length > 1 && newPrincipalPic===picture.url"
                @click="showDeleteDialog()"
              >
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </v-fade-transition>

        </div>
      </transition-group>

      <div class="bottom-sheet-footer">
        <v-btn
          class="bottom-sheet-button"
          depressed
          color="primary"
          @click="deselectItem(), $emit('close-sheet')"
        >
          Fermer
        </v-btn>
      </div>
<!-- delete image princiaple -->
      <DialogDeletePicture
        :dialog="deleteDialog"
        :isFavouritePicture=true
        @delete-item="removePic(selectedPic)"
        @close-dialog="closeDeleteDialog()"
      >
      </DialogDeletePicture>
<!-- delete image non principale -->
      <DialogDeletePicture
        :dialog="deleteDialog"
        :isPicture=true
        @delete-item="removePic(selectedPic)"
        @close-dialog="closeDeleteDialog()"
      >
      </DialogDeletePicture>

      <BottomSheetPictureEditor
        v-if="showEditor"
        :sheet="showEditor"
        :options="getOptions(selectedPic)"
        :useDefaultUI="true"
        @close-sheet="showEditor = false"
        @close-sheet-with-saving="saveAndClose($event)">
      </BottomSheetPictureEditor>

    </v-sheet>

    <!-- gallery object to visualize pictures -->
    <Gallery
      :arrayPics="localValue"
      :selectedPic="selectedPic"
      :openGalleryTwo="openGallerySheet"
      parentKey="bottom"
      @closeGalleryTwo="closeGallery"
      key="bottomSheetGallery">
    </Gallery>
  </v-bottom-sheet>
</template>
<script>
import Gallery from './gallery-pictures.vue';
import DialogDeletePicture from './DialogDeleteConfirmation.vue';
import BottomSheetPictureEditor from './BottomSheetPictureEditor.vue';

export default {
  components: {
    Gallery,
    DialogDeletePicture,
    BottomSheetPictureEditor,
  },
  props: {
    sheet: Boolean,
    defaultValue: null,
    principalPic: null,
  },
  data() {
    return {
      changed: false,
      localValue: [],
      newPrincipalPic: null,
      openGallerySheet: false,
      selectedPic: null,
      picturesToDelete: [],
      deleting: false,
      deleteDialog: false,
      useDefaultUI: true,
      showEditor: false,
    };
  },
  watch: {
    sheet(newValue, oldValue) {
      if (newValue && !oldValue) {
        if (this.defaultValue !== null) {
          this.localValue = [...this.defaultValue];
          this.newPrincipalPic = this.principalPic;
        } else {
          this.localValue = [];
          this.newPrincipalPic = null;
        }
        this.picturesToDelete = [];
      }
    },
    defaultValue() {
      this.localValue = [...this.defaultValue];
      this.changed = false;
    },
  },
  methods: {
    saveAndClose(file) {
      this.changed = true;
      this.showEditor = false;
      this.$emit('save-edited-pic', file);
    },
    getOptions(image) {
      const options = {
        // for tui-image-editor component's "options" prop
        cssMaxWidth: 700,
        cssMaxHeight: 500,
        includeUI: {
          loadImage: {
            path: image,
            name: 'SampleImage',
          },
        },
      };
      return options;
    },
    closeGallery() {
      this.openGallerySheet = false;
    },
    openGallery() {
      this.openGallerySheet = true;
    },

    selectPicture(picture) {
      this.selectedPic = picture.url;
    },

    deselectItem() {
      this.selectedPic = null;
    },

    changePrincipalPic(picture) {
      if (!picture.url.startsWith('blob')) {
        this.newPrincipalPic = picture.url;
        this.$emit('change-favorite-pic', this.newPrincipalPic);
      }
    },

    async removePic(picture) {
      this.changed = true;
      let removedPicture;
      if (typeof picture !== 'object') {
        removedPicture = this.localValue.find(image => image.url === picture);
      } else {
        removedPicture = picture;
      }
      this.deselectItem();
      this.localValue = this.localValue.filter(image => image.id !== removedPicture.id);
      this.$emit('remove-pic', removedPicture.id);
    },

    getPictureLazySrc(picture) {
      if (picture.url.startsWith('blob')) {
        return '';
      }
      return `${picture.url}?size=xsmall`;
    },

    showDeleteDialog() {
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.selected-icon-star {
  color: gold;
  position: absolute;
  right: 10px;
  top: 10px;
}

.selected-icon-change {
  color: rgb(25, 118, 210);
}

.selected-icon-remove {
  pointer-events:none;
  color:#AAA;
  background:#F5F5F5;
}

.sheet-wrapper {
  padding: 1rem;
  height: 100%;
  max-height: 100%;
  overflow: auto;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.bottom-sheet-title {
  flex: 0 0 auto;
}

.gallery-flex-wrapper {
  flex: 1 0 auto;
  max-height: 100%;
  overflow: auto;

  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;

  height: 15rem;
  width: 100%;
  > * {
    margin: 2rem;
    }
}

.bottom-sheet-footer {
  flex: 0 0 auto;

  width: 100%;
  height: 3rem;
  margin-bottom: 0;
  margin-top: auto;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;

  .bottom-sheet-button {
    margin-right: 1rem;
  }
}


.picture-object {
  cursor: pointer;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  width: 12rem;
  height: 12rem;
  border-radius: 5px;

  &-selected {
    border: 2px solid rgb(25, 118, 210);
  }
}

.picture-actions {
  margin-top: 0.5rem;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;

  > * {
    margin-right: 0.5rem;
  }

  > .delete-button {
    margin-left: auto;
  }
}

.list-pics-item {
  transition: all 0.5s;
}

.list-pics-leave-active {
  position: absolute;
}
.list-pics-enter, .list-pics-leave-to {
  opacity: 0;
  transform: translateX(0px);
}
</style>
