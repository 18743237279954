<template>
  <v-bottom-sheet
    v-model="sheet"
    fullscreen
  >
    <v-sheet class="sheet-wrapper">
      <h1>{{title}}</h1>

      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-form>
          <MultiPicturesField
            v-if="typeItem === 'equipements'"
            @addPicturesNewEquipements="addPicsNewEquipement($event)">
          </MultiPicturesField>
          <form-builder
            :item="defaultItem"
            :duplicate="true"
            :itemType="typeItem"
            :key="formBuilderKey"
            :onlyRequired="onlyRequired"
            class="form-wrapper"
          >
          </form-builder>
        </v-form>


      <div class="bottom-sheet-footer">
        <v-btn
          class="action-button"
          text
          large
          color="primary"
          @click="closeSheet()"
        >
          Annuler
        </v-btn>

        <v-btn
          class="action-button"
          depressed
          large
          color="primary"
          @click.native="checkBeforeSave()"
          :disabled="invalid"
          :loading="saving"
        >
          Créer
        </v-btn>
      </div>
      </ValidationObserver>
      <v-dialog
        persistent
        v-model="alertFacture"
        max-width="500px"
      >
        <v-card>
          <v-card-title class="headline">
            <span>
              <v-icon style="color:red;font-size:30px;">mdi-alert-outline</v-icon>
            <span>Attention cette facture existe déjà!</span></span>
          </v-card-title>

          <v-card-text class="card-text">
            <p> <span>Une facture avec le même numéro et les mêmes dates de début
                et de fin existent déjà pour ce compteur.
              Si vous validez, les données que vous venez de saisir
                viendront écraser celles déjà existante.
              Êtes-vous sûr(e) de vouloir continuer?</span></p>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              @click="closeDialog()"
            >
              Annuler
            </v-btn>
            <v-btn
              class="delete-button"
              depressed
              color='red'
              @click.native="saveForm(true)"
            >
              Valider
            </v-btn>
            </v-card-actions>
          </v-card>
      </v-dialog>
    </v-sheet>

  </v-bottom-sheet>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import api from '@/api';
import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';
import FormBuilder from './form-builder.vue';
import MultiPicturesField from './fields/multi-pictures-field-add-eqp.vue';

export default {
  components: {
    FormBuilder,
    ValidationObserver,
    MultiPicturesField,
  },
  mixins: [
    UpdateItemAlertMixin,
  ],

  props: {
    factures: { type: Array },
    sheet: { type: Boolean },
    typeItem: { type: String },
    defaultItem: { type: Object },
    parentItem: { type: Object },
  },

  data() {
    return {
      alertFacture: false,
      saving: false,
      obs: this.$refs.obs,
      formBuilderKey: 0,
      itemToEdit: null,
      picturesList: Object,
    };
  },

  computed: {
    ...mapState(['currentForm']),
    ...mapState('equipements', { equipementTypes: 'types' }),
    ...mapGetters({
      selectedBatiment: 'batiments/selected',
      selectedEquipement: 'equipements/selected',
      selectedEquipementType: 'equipements/selectedEquipementType',
      selectedMission: 'missions/selected',
      getEquipementFormTemplate: 'formTemplates/getEquipementFormTemplate',
    }),

    title() {
      switch (this.typeItem) {
        case 'missions':
          return 'Ajout d\'une nouvelle mission';
        case 'factures':
          return 'Ajout d\'une nouvelle facture';
        default:
          return `Ajout d'un nouveau ${this.typeItem.slice(0, -1).toLowerCase()} : `;
      }
    },

    onlyRequired() {
      return this.typeItem === 'batiments'
        || this.typeItem === 'niveaux'
        || this.typeItem === 'sites';
    },
  },

  watch: {
    sheet(newValue, oldValue) {
      // on sheet oppening
      if (newValue && !oldValue) {
        this.$store.dispatch('clearCurrentForm');
        this.formBuilderKey += 1;
      }
    },
  },
  methods: {
    closeDialog() {
      this.alertFacture = false;
      this.saving = false;
    },
    getFullMonth(month) {
      // because january is 0
      if (month < 9) {
        return `0${month + 1}`;
      }
      return month + 1;
    },
    getFullDay(day) {
      if (day < 10) {
        return `0${day}`;
      }
      return day;
    },
    checkBeforeSave() {
      this.saving = true;
      if (this.typeItem === 'factures') {
        const dayDebut = this.getFullDay(this.currentForm.dateDebut.getDate());
        const monthDebut = this.getFullMonth(this.currentForm.dateDebut.getMonth());
        const yearDebut = this.currentForm.dateDebut.getFullYear();
        const dateDebut = `${yearDebut}-${monthDebut}-${dayDebut}`;
        const dayFin = this.getFullDay(this.currentForm.dateFin.getDate());
        const monthFin = this.getFullMonth(this.currentForm.dateFin.getMonth());
        const yearFin = this.currentForm.dateFin.getFullYear();
        const dateFin = `${yearFin}-${monthFin}-${dayFin}`;
        let factureExist = null;
        const numFacture = this.currentForm.numerofacture;
        factureExist = this.factures
          .filter(facture => facture.numerofacture === numFacture
          && facture.dateDebutToString === dateDebut
          && facture.dateFinToString === dateFin
          && facture.numeroCompteur === this.parentItem.numeroCompteur);
        if (factureExist.length > 0) {
          this.itemToEdit = factureExist;
          this.alertFacture = true;
        } else {
          this.saveForm();
        }
      } else {
        this.saveForm();
      }
    },
    saveForm(edit = false) {
      // payload construction //
      const payload = { };
      // convert all form fields to string
      const formCopy = JSON.parse(JSON.stringify(this.currentForm));
      // if there's a photo to save, put it in a separate field in the payload
      if (this.currentForm.image) {
        payload.photo = this.currentForm.image;
        delete formCopy.photo;
      }
      // add the form to the payload
      let method = 'createNew';
      payload.data = formCopy;
      let newEquipementPayload = {};
      if (this.typeItem === 'equipements') {
        let findBlank = false;
        const fieldNecessary = [];
        this.getEquipementFormTemplate(this.defaultItem,
          this.selectedMission.id).fieldGroups.forEach(
          fieldgroup => fieldgroup.fields.forEach(
            (field) => {
              if (field.rules && field.rules.includes('necessary')) {
                fieldNecessary.push(field.name);
              }
            },
          ),
        );

        let i = 0;
        while (i < fieldNecessary.length && !findBlank) {
          if (fieldNecessary[i] !== 'performance'
            && fieldNecessary[i] !== 'vetuste'
            && formCopy.attributs
            && formCopy.attributs[fieldNecessary[i]] === null) {
            findBlank = true;
          } else if (!formCopy.performance) {
            findBlank = true;
          } else if (!formCopy.vetuste) {
            findBlank = true;
          }
          i += 1;
        }
        method = 'createEquipementOnBatiment';

        const eqttype = this.equipementTypes.find(eqt => eqt.id === this.defaultItem.type);
        newEquipementPayload = {
          batimentId: this.selectedBatiment.id,
          equipementType: eqttype,
        };
        payload.data = {
          ...formCopy,
          inAlert: findBlank,
          libelleDomaine: this.defaultItem.libelleDomaine,
          libelleCategorie: this.defaultItem.libelleCategorie,
          libelleType: this.defaultItem.libelleType,
          linkedEquipements: [],
        };
        if (payload.data.niveau === '') {
          delete payload.data.niveau;
        }
      } else if (this.typeItem === 'factures') {
        payload.data = {
          ...formCopy,
          compteur: this.parentItem.id,
          numeroCompteur: this.parentItem.numeroCompteur,
        };
      } else if (this.typeItem === 'sites') {
        payload.data = {
          ...formCopy,
          mission: this.selectedMission.id,
        };
      }
      if (edit) {
        this.$store.dispatch(
          `${this.typeItem}/save`,
          {
            id: this.itemToEdit[0].id,
            ...payload,
          },
        ).then((response) => {
          if (this.typeItem === 'factures') {
            this.$store.dispatch('factures/refreshItem', response.id);
          }

          this.$store.dispatch('snackbar/displaySnack', { message: 'créé avec succès !', type: 'succes' });
          if (this.typeItem === 'batiments') {
            this.majSiteAlert(response.site, 'batiments');
          }
        }).finally(() => {
          this.itemToEdit = null;
          this.saving = false;
          this.closeSheet();
        });
      } else {
        this.$store.dispatch(`${this.typeItem}/${method}`, newEquipementPayload).then((newItem) => {
          this.$store.dispatch(
            `${this.typeItem}/save`,
            {
              id: newItem.id,
              ...payload,
            },
          ).then((response) => {
            let message = `Impossible de sauvegarder le ${this.typeItem.slice(0, -1).toLowerCase()}`;
            if (response.code === 'ERR_BAD_RESPONSE' || response.isAxiosError) {
              if (response.response.data === 'Le contrôleur est déjà utilisé par un autre utilisateur.') {
                message = 'Un autre utilisateur modifie également une mission, merci de réessayer dans quelques instants.';
              } else if (this.typeItem === 'equipements') {
                message = `Impossible de sauvegarder l'${this.typeItem.slice(0, -1).toLowerCase()}`;
              }
              this.$store.dispatch('snackbar/displaySnack',
                {
                  message,
                  type: 'error',
                });
            } else {
              this.majBatimentAlert(response.batiment, 'equipements');
              if (this.typeItem === 'factures') {
                this.$store.dispatch('factures/refreshItem', response.id);
              }

              this.$store.dispatch('snackbar/displaySnack', { message: 'créé avec succès !', type: 'succes' });
              if (this.typeItem === 'batiments') {
                this.majSiteAlert(response.site, 'batiments');
              }
            }
            if (this.typeItem === 'equipements') {
              if (this.pictures) {
                this.saveImages(response.id, edit);
              }
            }
          }).finally(() => {
            this.saving = false;
            this.closeSheet();
          });
        });
      }
    },

    closeSheet() {
      this.formBuilderKey += 1;
      this.$emit('close-sheet');
    },

    addPicsNewEquipement(picturesList) {
      this.pictures = picturesList;
    },

    async saveImages(id, edit) {
      await Promise.all(this.pictures.map(async (picture) => {
        await api.pictures.create(picture, 'equipements', id);
      }));
      this.$store.dispatch('equipements/refreshItem', id);
      this.$store.dispatch('updatePicturesToSync');
      if (edit) {
        this.$emit('open-edit-modal', id);
      }
      this.$emit('close-sheet');
    },
  },
};
</script>

<style lang="scss" scoped>
  .delete-button {
    color: white;
  }
  .sheet-wrapper {
    padding: 1rem;
    height: 100%;
    overflow: auto;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .bottom-sheet-footer {
    width: 100%;
    height: 3rem;
    margin-bottom: 0;
    margin-top: auto;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
  }

  .action-button {
    margin-right: 1rem;
  }

  .form-wrapper {
    width: 37rem;
    max-width: 37rem;
  }
</style>
