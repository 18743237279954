<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >

    <div class="dialog-wrapper">
      <div class="dialog-title">
        <template v-if="create">Création d'un plan d'action</template>
        <template v-else>Edition d'un plan d'action</template>
      </div>


      <div class="dialog-content">
        <div class="uppper-fix-block">
          <PlanActionForm
            v-if="show"
            :planAction="planAction"
            :creation="create"
            @form-updated="handleFormUpdated($event)"
          ></PlanActionForm>
        </div>

        <v-card outlined class="lower-scroll-block">
          <SitesProgrammesList
            v-if="show"
            :initialList="planAction.programmes"
            @new-selection="handleChangeProgrammes($event)"
          >
          </SitesProgrammesList>
        </v-card>
      </div>

      <div class="dialog-footer">
        <template v-if="create">
          <v-btn
            text
            large
            color="primary"
            @click="$emit('close')"
          >
            Annuler
          </v-btn>

          <v-btn
            depressed
            large
            color="primary"
            :disabled="!form.valid"
            :loading="saving"
            @click.native="handleQuit()"
          >
            Créer
          </v-btn>
        </template>

        <v-btn
            v-else
            depressed
            large
            color="primary"
            :loading="saving"
            @click.native="$emit('close')"
          >
            Fermer
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import PlanActionForm from './PlanActionForm.vue';
import SitesProgrammesList from '../programmes/SitesProgrammesList.vue';

export default {
  components: {
    PlanActionForm,
    SitesProgrammesList,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    planAction: {
      type: Object,
      default: () => {},
    },
    create: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      saving: false,
      form: {
        valid: true,
        touched: false,
        fields: {},
      },
    };
  },

  computed: {
    ...mapGetters({
      patrimoine: 'patrimoines/selected',
    }),
  },

  watch: {
    show(newValue) {
      if (!newValue) {
        this.form = {
          valid: true,
          touched: false,
          fields: {},
        };
      }
    },
  },

  methods: {
    handleFormUpdated({ valid, touched, fields }) {
      this.form = {
        valid,
        touched: this.form.touched || touched,
        fields: {
          ...this.form.fields,
          ...fields,
        },
      };

      if (!this.create && this.form.touched && this.form.valid) {
        this.saveForm();
      }
    },

    handleChangeProgrammes({ programmes, touched }) {
      this.form.touched = this.form.touched || touched;
      this.form.fields.programmes = programmes.map(prog => prog.id);

      if (!this.create && this.form.touched && this.form.valid) {
        this.saveForm();
      }
    },

    async saveForm() {
      this.saving = true;
      let planToSave = {};
      if (this.create) {
        const newPlan = await this.$store.dispatch('planActions/createPlanActionOnPatrimoine', this.patrimoine.id);
        planToSave = {
          ...newPlan,
          ...this.form.fields,
        };
      } else {
        planToSave = {
          ...this.planAction,
          ...this.form.fields,
        };
      }

      await this.$store.dispatch('planActions/save', planToSave);
      this.saving = false;
    },

    async handleQuit() {
      await this.saveForm();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  .dialog-wrapper {
    height: 100%;

    display: grid;
    grid-template-columns: 7rem 1fr 7rem;
    grid-template-rows: 4rem 1fr 4rem;
    grid-template-areas:
      "header header header"
      "content content content"
      "footer footer footer";

    background-color: $app-white;

    .dialog-title {
      grid-area: header;

      font-size: 2rem;
      font-weight: 500;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;

      background-color: $app-blue;
      color: $app-grey;
    }

    .dialog-content {
      grid-area: content;
      justify-self: center;

      overflow: hidden;
      width: 100%;
      max-width: 1200px;

      display: grid;
      grid-template-rows: minmax(25rem, 1fr) 3fr;
      grid-template-areas:
        "upper"
        "lower";

      .lower-scroll-block {
        grid-area: lower;
        overflow: hidden;
        margin: 1rem;
      }
    }

    .dialog-footer {
      grid-area: footer;

      display: flex;
      flex-flow: row wrap;
      justify-content: flex-end;
      align-items: center;

      padding: 0 1rem 0 1rem;

      > * {
        margin-right: 1rem;
      }
    }
  }
</style>
