<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >

    <div class="dialog-wrapper">
      <div class="dialog-title">
        <template v-if="create">Création d'un scénario</template>
        <template v-else>Edition d'un scénario</template>
      </div>

      <div class="dialog-content">
        <div class="uppper-fix-block">
          <ScenarioForm
            v-if="show"
            :scenario="scenario"
            :creation="create"
            :duplicate="duplicate"
            @form-updated="handleFormUpdated($event)"
          ></ScenarioForm>
        </div>

        <v-card outlined class="lower-scroll-block">
          <InterventionsSelectTable
            v-if="show && batimentInterventions.length"
            :initialList="scenario.interventions"
            :interventions="batimentInterventions"
            @new-selection="handleChangeInterventions($event)"
          >
          </InterventionsSelectTable>
        </v-card>
      </div>

      <div class="dialog-footer">
        <template v-if="create || duplicate">
          <v-btn
            text
            large
            color="primary"
            @click="$emit('close')"
          >
            Annuler
          </v-btn>

          <v-btn
            depressed
            large
            color="primary"
            :disabled="!form.valid"
            :loading="saving"
            @click.native="handleQuit()"
          >
            Créer
          </v-btn>
        </template>

        <v-btn class="btn-close"
            v-else
            depressed
            large
            color="primary"
            :loading="saving"
            @click.native="$emit('close')"
          >
            Fermer
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import ScenarioForm from './ScenarioForm.vue';
import InterventionsSelectTable from '../interventions/InterventionsSelectTable.vue';

export default {
  components: {
    ScenarioForm,
    InterventionsSelectTable,
  },

  props: ['show', 'scenario', 'create', 'duplicate'],

  data() {
    return {
      invalid: false,
      saving: false,
      form: {
        valid: true,
        touched: false,
        fields: {},
      },
    };
  },

  computed: {
    ...mapGetters({
      batimentInterventions: 'interventions/selectedBatimentInterventions',
      batiment: 'batiments/selected',
    }),
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.$store.dispatch('interventions/fetchAllByBatiment', this.batiment.id);
      }

      if (!newValue) {
        this.form = {
          valid: true,
          touched: false,
          fields: {},
        };
      }
    },
  },

  methods: {
    handleFormUpdated(updatedForm) {
      this.form = {
        valid: updatedForm.valid,
        touched: this.form.touched || updatedForm.touched,
        fields: {
          ...this.form.fields,
          ...updatedForm.fields,
        },
      };

      if (!this.create && !this.duplicate && this.form.touched && this.form.valid) {
        this.saveForm();
      }
    },

    handleChangeInterventions({ interventions, touched }) {
      this.form.touched = this.form.touched || touched;
      this.form.fields.interventions = interventions.map(inter => inter.id);

      if (!this.create && !this.duplicate && this.form.touched && this.form.valid) {
        this.saveForm();
      }
    },

    async saveForm() {
      this.saving = true;
      let scenarioToSave = {};
      if (this.create || this.duplicate) {
        const newScenario = await this.$store.dispatch('scenarios/createScenarioOnBatiment', this.batiment.id);
        scenarioToSave = {
          ...newScenario,
          ...this.form.fields,
        };
      } else {
        scenarioToSave = {
          ...this.scenario,
          ...this.form.fields,
        };
      }

      await this.$store.dispatch('scenarios/save', scenarioToSave);
      this.saving = false;
    },

    async handleQuit() {
      await this.saveForm();
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  v-dialog {
    overflow: hidden !important;
  }

  .dialog-wrapper {
    height: 130%;
    display: grid;
    grid-template-columns: 7rem 1fr 7rem;
    grid-template-rows: 4rem 1fr 4rem;
    grid-template-areas:
      "header header header"
      "content content content"
      "footer footer footer";
    background-color: $app-white;

    .dialog-title {
      grid-area: header;
      font-size: 2rem;
      font-weight: 500;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;
      background-color: $app-blue;
      color: $app-grey;
    }

    .dialog-content {
      grid-area: content;
      justify-self: center;
      overflow: hidden;
      width: 100%;
      max-width: 1200px;
      display: grid;
      grid-template-rows: minmax(25rem, 1fr) 3fr;
      grid-template-areas:
        "upper"
        "lower";

      .upper-fix-block {
        grid-area: upper;
      }

      .lower-scroll-block {
        grid-area: lower;
        overflow: hidden;
        margin: 2rem;
      }
    }

    .dialog-footer {
      position: fixed;
      width: 100%;
      height: 64px;
      bottom: 0;
      margin-bottom: 0;
      -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      background-color: white;
      margin-right: 1.2rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      > * {
        margin-right: 1rem;
      }

      .btn-close {
      height: 36px;
      min-width: 64px;
      padding: 0 16px;
      bottom: 0px;
      }
    }
  }
    // Media Query pour les tablettes
@media screen and (min-width: 768px) and (max-width: 1180px) {
  .dialog-wrapper {
    height: 110%;
    display: grid;
    grid-template-columns: 7rem 1fr 7rem;
    grid-template-rows: 4rem 1fr 4rem;
    grid-template-areas:
      "header header header"
      "content content content"
      "footer footer footer";
    background-color: $app-white;

    .dialog-title {
      grid-area: header;
      font-size: 2rem;
      font-weight: 500;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      align-items: center;

      background-color: $app-blue;
      color: $app-grey;
    }

    .dialog-content {
      grid-area: content;
      justify-self: center;
      overflow: hidden;
      width: 100%;
      max-width: 1200px;
      display: grid;
      grid-template-rows: minmax(25rem, 1fr) 3fr;
      grid-template-areas:
        "upper"
        "lower";

      .upper-fix-block {
        grid-area: upper;
      }

      .lower-scroll-block {
        grid-area: lower;
        overflow: hidden;
        margin: 2rem;
      }
    }

    .dialog-footer {
      position: fixed;
      width: 100%;
      height: 64px;
      bottom: 0;
      margin-bottom: 0;
      -webkit-box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      background-color: white;
      margin-right: 1.2rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;


      > * {
        margin-right: 1rem;
      }
      .btn-close {
      height: 36px;
      min-width: 64px;
      padding: 0 16px;
      bottom: 0px;
      }
    }
  }
}
</style>
