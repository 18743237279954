<template>
  <v-bottom-sheet
    v-model="sheet"
    persistent
    fullscreen
  >
  <v-sheet class="sheet-wrapper">
    <tui-image-editor class="imageeditor"
      ref="editorRef" :include-ui="useDefaultUI" :options="options">
      </tui-image-editor>
        <div class="bottom-sheet-footer">
          <v-btn
            class="bottom-sheet-button"
            outlined
            @click="deselectItem(), $emit('close-sheet')"
          >
            Annuler
          </v-btn>
          <v-btn
            depressed
            color="white"
            @click="saveEditedPicture()"
          >
            VALIDER
          </v-btn>
        </div>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
// import { ImageEditor } from '@toast-ui/vue-image-editor';
import ImageEditor from '@toast-ui/vue-image-editor/src/ImageEditor.vue';
import 'tui-color-picker/dist/tui-color-picker.css';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageResizeMixin from '../mixins/ImageResizeMixin';

export default {
  components: {
    'tui-image-editor': ImageEditor,
  },
  mixins: [
    ImageResizeMixin,
  ],
  props: {
    sheet: Boolean,
    options: null,
    useDefaultUI: Boolean,
  },
  methods: {
    async saveEditedPicture() {
      const data = this.$refs.editorRef.invoke('toDataURL');
      const blob = this.dataURItoBlob(data);
      const file = new File([blob], 'edited-', {
        type: 'image/jpeg',
        lastModified: new Date(),
      });
      const resizedImage = await this.resizeImageMx(file, 1200, 1200);
      this.$emit('close-sheet-with-saving', resizedImage);
    },

    dataURItoBlob(dataURI) {
      const byteString = atob(dataURI.split(',')[1]);
      const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

      const ab = new ArrayBuffer(byteString.length);
      const ia = new Uint8Array(ab);
      for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ab], { type: mimeString });
    },
    deselectItem() {
      this.selectedPic = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.sheet-wrapper {
  height: 100%;
  max-height: 100%;
  overflow: auto;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}
.bottom-sheet-footer {
  background-color: #2D4388;
  flex: 0 0 auto;
  bottom: 0;
  height: 64px;

  width: 100%;
  margin: 0 0 auto 0;

  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .bottom-sheet-button {
    margin-right: 1rem;
    color:white
  }
}
</style>
<style>
.tui-image-editor-header{
  background-color: #2D4388 !important;
}
.use-default{
  fill: white !important;
  stroke:white !important;
}
.tui-image-editor-menu{
  background-color: #2D4388 !important;
}
.tui-image-editor-header-buttons {
  display: none;
}
.tui-image-editor-header-logo {
  visibility: hidden;
}
.tie-btn-resize {
  display: none !important;
}
.tui-image-editor-submenu {
  height: 0;
}
.tie-btn-flip {
  display: none !important;
}
.tui-image-editor-container .tui-image-editor-submenu .tui-image-editor-submenu-style {
  opacity: 0.6;
}
.use-default.active {
  fill: blue !important;
  stroke:blue !important;
}
</style>
