/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  // equipementTypesUrl,
  equipementCategoriesUrl,
  // missionsEndpoint,
  equipementDomainesUrl,
  apiUrl,
  refUrl,
  missionsUrl,
} from './apiUrls';

export default {
  db: new PouchDB('refEquipementCategories'),

  convertBackendObject(obj, missionId) {
    // const idMission = obj.mission.replace(`${missionsEndpoint}/`, '');
    const idMission = missionId;
    // set an _id attribute for pouch
    const _id = obj._id || `${idMission}_${obj.id.toString()}`; /* eslint-disable-line no-underscore-dangle */
    const meta = {};

    return {
      _id,
      meta,
      id: String(obj.id),
      nom: obj.nom,
      code: obj.code,
      domaine: obj.domaine.replace(`${apiUrl}${refUrl}${missionsUrl}/${missionId}${equipementDomainesUrl}/`, ''),
      // types: obj.types.map(type => type.replace(`${equipementTypesUrl}/`, '')),
      types: obj.types,
      attrDefinition: obj.attrDefinition,
      mission: idMission,
    };
  },

  /**
   * get all equipementCategories for a mission
   * @param {String} missionId - id of the parent mission
   */
  async getAllByMission(missionId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${apiUrl}${refUrl}${missionsUrl}/${missionId}${equipementCategoriesUrl}`);
      const equipementCategories = response.data
        .map(equipementCategorie => this.convertBackendObject(equipementCategorie, missionId));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(equipementCategories, this.db);
    } catch (e) {
      console.error('refEquipementCategoriesApi getAllByMission error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByIdPrefix(`${missionId}_`, this.db);
    }
  },

  /**
   * get all equipementCategories for a mission for offline use
   * @param {String} missionId - id of the parent mission
   */
  async fetchOffline(missionId) {
    const response = await axiosInstance.axiosInstance.get(`${apiUrl}${refUrl}${missionsUrl}/${missionId}${equipementCategoriesUrl}`);
    const equipementCategories = response.data
      .map(equipementCategorie => this.convertBackendObject(equipementCategorie, missionId));
    // if get is successful,  store data into pouchdb and return it
    return pouchUtil.addDocs(equipementCategories, this.db);
  },

  /**
   * Fetch all equipementCategories stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocs(this.db);
  },
};
