<template>
  <div>
    <v-tabs vertical class="onglet" color="tertiary">
      <v-tab
        v-for="(struct,s) in structures"
        :key="s"
        class="onglet-item"
        @change="changeCurrentStruct(struct)">
        <v-icon left>
          mdi-format-list-text
        </v-icon>
        {{ struct.nom }}
        <v-icon v-if="struct.structureLiee.length" right>
          mdi-link-variant
        </v-icon>
      </v-tab>
      <v-tab>
        <v-icon left>
          mdi-plus-thick
        </v-icon>
        AJOUTER
      </v-tab>
      <v-tab-item
      v-for="(struct,s) in structures"
        :key="s">
        <v-card flat>
          <v-card-text>
            <!-- Report Blocs -->
            <div class="en-tete-struct">
              <h3>
                <v-text-field
                v-model="currentStruct"
                @change="$emit('updateName', { nom: $event, struct: struct })"
                ></v-text-field></h3>
                <input
                  type="file"
                  :ref="`fileInput${struct.id}`"
                  style="display:none"
                  @change="handleFileEdit($event, struct)"
                >
              <div class="btn-struct">
                <v-btn
                  @click="$emit('download', struct)"
                  color="primary"
                >
                  Télécharger
                  <v-icon>
                    mdi-download
                  </v-icon>
                </v-btn>
                <v-btn
                  @click="showEditDialog(struct)"
                  color="primary"
                >
                  Editer
                  <v-icon>
                    mdi-circle-edit-outline
                  </v-icon>
                </v-btn>
                <v-btn
                  @click="showDeleteDialog(struct)"
                  color="error"
                  :disabled="struct.structureLiee.length > 0"
                >
                  Supprimer
                  <v-icon>
                    mdi-delete
                  </v-icon>
                </v-btn>
              </div>
            </div>
            <div class="bloc-list">
              <v-list class="list-struct">
                <v-list-item-group
                  v-if="struct.structure.length"
                >
                  <template
                    v-for="(block,i) in struct.structure"
                  >
                  <div :key="i">
                    <v-list-item-content v-if="removeEmptyInspection(block)">
                      <div class="container">
                        <div class="parent">
                        <h5 class="subHeader inline-block-child">
                          {{getLevelTitle(block, struct.structure)}}</h5>
                          <h2 v-if="displayBlocTitle(block)"
                            class="titleHeader inline-block-child">
                        {{ getBlocTitle(block) }}</h2> </div>
                        <h5 class="subHeader">{{getLevelTitle(block, struct.structure)}}
                          {{ block.label }}
                          {{getBlocCodeLabel(block)}}</h5>
                      </div>
                      <v-divider></v-divider>
                    </v-list-item-content>
                  </div>
                  </template>
                </v-list-item-group>
              </v-list>
            </div>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <DialogDeleteConfirmation
        :item="structToDelete"
        :dialog="deleteDialog"
        @delete-item="$emit('delete', structToDelete.id)"
        @close-dialog="closeDeleteDialog()"
      >
      </DialogDeleteConfirmation>
      <BottomSheetEditTrameRapportVue
      :sheet="showEditTrame"
      :struct="structToEdit"
      :typeRapport="typeRapportStruct"
      @close-sheet="showEditTrame = false"
      @handleTrameEdit="handleTrameEdit($event)">
      </BottomSheetEditTrameRapportVue>
      <v-tab-item class="onglet-add-item">
        <h3>Ajout d'une nouvelle structure de rapport </h3>
        <v-form class="add-struct">
          <v-text-field
            label="Nom de la structure"
            type="text"
            class="mt-n4 pa-1 mb-n2"
            outlined
            dense
            v-model="nom"
          >
          </v-text-field>
          <input
            type="file"
            ref="fileInput"
            @change="handleFileChange($event)"
          >
          Si vous n'ajoutez pas de fichier vous partirez d'une structure vierge.
        </v-form>
        <v-btn
          class="action-add-button"
          depressed
          large
          color="primary"
          :disabled="!this.nom"
          @click.native="$emit('addStructure', { nom, file }) && resetField()"
          >
            Valider
          </v-btn>
      </v-tab-item>
    </v-tabs>

  </div>
</template>

<script>
import MethodsStructureMixin from '@/mixins/MethodsStructureMixin';
import DialogDeleteConfirmation from '../DialogDeleteConfirmation.vue';
import BottomSheetEditTrameRapportVue from './BottomSheetEditTrameRapport.vue';


export default {
  components: {
    DialogDeleteConfirmation,
    BottomSheetEditTrameRapportVue,
  },
  props: ['structures'],
  data() {
    return {
      file: null,
      nom: null,
      deleteDialog: false,
      structToDelete: this.structures ? this.structures[0] : null,
      currentStruct: this.structures ? this.structures[0].nom : null,
      typeRapportStruct: this.structures ? this.structures[0].typeRapport : null,
      showEditTrame: false,
      structToEdit: null,
    };
  },
  mixins: [
    MethodsStructureMixin,
  ],
  methods: {
    changeCurrentStruct(struct) {
      this.currentStruct = struct.nom;
    },
    nameValue(struct) {
      return struct.nom;
    },
    handleFileEdit(event, struct) {
      if (typeof event.target.files[0] !== 'undefined') {
        this.$emit('edit', { nom: struct.nom, file: event.target.files, id: struct.id });
      }
    },
    handleTrameEdit(blob) {
      this.$emit('edit', { nom: this.structEditedValues.nom, file: blob, id: this.structEditedValues.id });
      this.showEditTrame = false;
    },
    showEditDialog(struct) {
      this.structToEdit = Object.values(struct.structureValue);
      this.structEditedValues = struct;
      this.showEditTrame = true;
      // this.$refs[`fileInput${struct.id}`][0].click();
    },
    showDeleteDialog(struct) {
      this.deleteDialog = true;
      this.structToDelete = struct;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
    },
    handleFileChange(event) {
      if (typeof event.target.files[0] !== 'undefined') {
        [this.file] = event.target.files;
      }
    },
    resetField() {
      this.nom = null;
      this.file = null;
    },
  },
};

</script>
<style lang="scss" scoped>
  .onglet{
    max-width: 1000px;
  }
  .onglet-item{
    justify-content: left;
  }
  .onglet-add-item {
    margin:2rem;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  .add-struct{
    margin:2rem;
    padding: 1rem;
    display: flex;
    flex-flow: column nowrap;
    > * {
      padding: 2rem 0 2rem 0;
    }
  }
  .action-add-button{
    width: 10rem;
  }
  .btn-struct{
      align-self: stretch;

      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;

      button {
        width: 150px;
        margin: 0.2rem 0 0.2rem 0;
      }
  }
  .onglet{
    max-width: 1000px;
  }
  .en-tete-struct{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    }
  .bloc-list {
    height: 34rem;
    overflow-y: scroll;
    width: 1000px;
  }
  @media (max-width: 1600px) {
    .bloc-list {
      height: 20rem;
      width: 750px;
    }
  }

  @media (max-width: 1000px) {
    .bloc-list {
      height: 50rem;
      width: 500px;
    }
  }
  .list-struct{
    cursor: default !important;
  }
  .container{
    //white-space: pre;
    padding: 0;
    cursor: default !important;
  }
  .parent{
    cursor: default !important;
  }
  .subHeader{
    white-space: pre;
    font-style: italic;
    cursor: default !important;
  }
  .titleHeader{
    padding-left: 0;
    left: 0;
  }
</style>
