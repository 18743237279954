<template>
  <ValidationProvider
    :name="formField.name"
    :rules="formField.rules"
    v-slot="{ errors }"
  >
  {{ formField.label }}
    <vue-editor
      v-bind="fieldOptions"
      :error-messages="errors"
      :editor-toolbar="customToolbar"
      maxlength="1000"
      :rules="textAreaRules"
      v-model="localValue"
      @input="change(localValue)"
      class="vue_edeitor_height"
      :class="{ 'exceeding-limit': isExceedingLimit }"
    >
    </vue-editor>
    <p class="erreur" v-if="errors[0] && errors[0].length > 0">
          {{ errors[0] }}
    </p>
  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { VueEditor } from 'vue2-editor';


export default {
  name: 'text-area-field-editor',
  components: {
    ValidationProvider,
    VueEditor,
  },
  props: {
    commonOptions: Object,
    defaultValue: null,
    formField: Object,
  },
  data() {
    return {
      localValue: this.defaultValue,
      customToolbar: [
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
      textAreaRules: [v => (!v || v.length < 1000) || 'Vous avez atteint les 1000 caractères autorisés'],
      isExceedingLimit: false,

    };
  },
  computed: {
    fieldOptions() {
      return {
        ...this.commonOptions,
        placeholder: this.formField.placeholder,
        rows: this.formField.lines,
      };
    },
  },
  watch: {
    defaultValue(newValue) {
      this.localValue = newValue;
      this.isExceedingLimit = newValue.length > 1000;
    },
  },
  methods: {
    change(value) {
      const newValue = value.replaceAll('<br>', '<br/>');
      this.$emit('change', newValue);
    },
  },
};
</script>

<style >

.erreur {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

.exceeding-limit {
  border: 1px solid red;
}
 /* .vue_edeitor_height{
  width:532px;
 } */
</style>
