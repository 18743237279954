/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  listeOptionsUrl,
  apiUrl,
  refUrl,
  missionsUrl,
} from './apiUrls';

export default {
  db: new PouchDB('listeOptions'),

  convertBackendObject(obj, missionId) {
    // const idMission = obj.mission.replace(`${missionsEndpoint}/`, '');
    const idMission = missionId;
    // set an _id attribute for pouch
    const _id = obj._id || `${idMission}_${obj.codeType.toString()}#_${obj.codeOption.toString()}`; /* eslint-disable-line no-underscore-dangle */
    const meta = {};


    return {
      _id,
      meta,
      id: _id,
      codeOption: obj.codeOption,
      codeType: obj.codeType,
      data: obj.data,
      libelle: obj.libelle,
      mission: idMission,
    };
  },

  /**
   * get all listeOptions for a mission
   * @param {String} missionId - id of the parent mission
   */
  async getAllByMission(missionId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${apiUrl}${refUrl}${missionsUrl}/${missionId}${listeOptionsUrl}`);
      const options = response.data
        .map(option => this.convertBackendObject(option, missionId));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(options, this.db);
    } catch (e) {
      console.error('listeOptions getAllByMission error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByIdPrefix(`${missionId}_`, this.db);
    }
  },

  /**
   * get all listeOptions for a given mission and codeType
   * @param {String} missionId - id of the parent mission
   * @param {String} codeType - id of the codeType to filter
   */
  async getAllByType(missionId, codeType) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${apiUrl}${refUrl}${missionsUrl}/${missionId}${listeOptionsUrl}?codeType=${codeType}`);
      const options = response.data
        .map(option => this.convertBackendObject(option, missionId));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(options, this.db);
    } catch (e) {
      console.error('listeOptions getAllByMission error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByIdPrefix(`${missionId}_${codeType}#`, this.db);
    }
  },

  /**
   * get one listeOption entry for a given mission and codeOption/id
   * @param {String} missionId - id of the parent mission
   * @param {String} codeOption - codeOption/id of the entry
   */
  async getByCodeOption(missionId, codeOption) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${apiUrl}${refUrl}${missionsUrl}/${missionId}${listeOptionsUrl}/${codeOption}`);
      const option = this.convertBackendObject(response.data, missionId);

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDoc(option, this.db);
    } catch (e) {
      console.error('listeOptions getByCodeOption error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      const list = await pouchUtil.getAllDocsByField('codeOption', codeOption, this.db);
      const filteredList = list.filter(option => option.mission === missionId);
      if (filteredList && filteredList.length === 1) {
        return filteredList[0];
      }
      return null;
    }
  },

  /**
   * get all listeOptions for a mission for offline use
   * @param {String} missionId - id of the parent mission
   */
  async fetchOffline(missionId) {
    const response = await axiosInstance.axiosInstance.get(`${apiUrl}${refUrl}${missionsUrl}/${missionId}${listeOptionsUrl}`);
    const options = response.data
      .map(option => this.convertBackendObject(option, missionId));
    // if get is successful,  store data into pouchdb and return it
    return pouchUtil.addDocs(options, this.db);
  },

  /**
   * Fetch all equipementDomaines stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocs(this.db);
  },
};
