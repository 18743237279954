import {
  applyAuthTokenInterceptor, setAuthTokens, clearAuthTokens, isLoggedIn,
} from 'axios-jwt';
import axios from 'axios';
// import http from '../store/modules/api';

/* const http = axios.create({
  headers: { accept: 'application/json' },
}); */

const axiosInstance = axios.create({ headers: { accept: 'application/json' } });


// 2. Define token refresh function.
// Notice that this is the global axios instance, not the axiosInstance!  <-- important
const requestRefresh = refresh => axios.post('/api/refresh', { refresh_token: refresh })
  .then(tokens => tokens.data.token);

// 3. Apply interceptor
// Notice that this uses the axiosInstance instance.  <-- important
applyAuthTokenInterceptor(axiosInstance, { requestRefresh });

// 4. Logging in
const login = async (params) => {
  await axiosInstance.post('/api/login', params).then(async (tokens) => {
    // save tokens to storage
    setAuthTokens({
      accessToken: tokens.data.token,
      refreshToken: tokens.data.refresh_token,
    });
  });
  // save tokens to storage
};

// 5. Clear the auth tokens from localstorage
const logout = () => clearAuthTokens();

// interceptor for all the request
axiosInstance.interceptors.request.use((config) => {
  // add pagination=false on all the GET requests
  if (config.method === 'get') {
    return {
      ...config,
      params: {
        pagination: false,
        ...config.params,
      },
    };
  }

  return config;
});

const isloggedin = () => isLoggedIn();


export default {
  axiosInstance, login, logout, isloggedin,
};
