<template>
  <div>
    <v-scroll-x-transition>
      <SiteHeader
        v-if="selectedSite && selectedMission"
        :loading="!(selectedSite && selectedMission)"
        class="header-card"
        :featuredItem="selectedSite"
        @show-edit-item="sheetEditStatus = true"

      >
      </SiteHeader>
    </v-scroll-x-transition>
    <div>
      <div class="column-wrapper">
        <div class="cards-column">
            <SiteGlobalData
            v-if="selectedSite && selectedMission"
            :featuredItem="selectedSite"
            :loading="!(selectedSite && selectedMission)"
            >
            </SiteGlobalData>

            <SiteActions
            v-if="selectedSite && selectedMission"
            :featuredItem="selectedSite"
            :loading="!(selectedSite && selectedMission)"
            >
            </SiteActions>

            <CalculateDataCard
            v-if="selectedSite && selectedMission"
            :featuredItem="selectedSite"
            :typeItem="'site'"
            :loading="!(selectedSite && selectedMission)"
            >
            </CalculateDataCard>
        </div>
        <div class="cards-column">
            <SiteCompteursOptions
            v-if="selectedSite && selectedMission"
            :featuredItem="selectedSite"
            :compteurs="siteCompteurs"
            :loading="loadingCompteurs"
            @select-compteur="changeSelectedCompteur($event)"
            @qualify="openQualifyDialog()"
            @allocate="openAllocateDialog()"
            @open-sheet-compteur="showBottomSheetCompteurs = true"
            @disaffect="showDisaffectDialog()"
            >
            </SiteCompteursOptions>

            <SiteBatimentsList
            v-if="selectedSite && selectedMission"
            :featuredItem="selectedSite"
            :loading="!(selectedSite && selectedMission)"
            >
            </SiteBatimentsList>
        </div>
      </div>
    </div>
    <!-- Action button and bottomSheets -->
    <BaseFab
      class="add-button"
      :options="fabOptions"
      @option-selected="handlerFabButton"
    >
    </BaseFab>
    <BottomSheetAddItem
      v-if="selectedSite && selectedMission"
      :sheet="showBottomSheet"
      @close-sheet="showBottomSheet = false"
      :typeItem="typeItem"
      :defaultItem="defaultItem"
    >
    </BottomSheetAddItem>
    <BottomSheetAddCompteur
      v-if="selectedSite && selectedMission"
      :sheet="showBottomSheetCompteurs"
      @close-sheet="showBottomSheetCompteurs = false"
    >
    </BottomSheetAddCompteur>
    <BottomSheetEditItem
      v-if="selectedSite && selectedMission"
      :sheet="sheetEditStatus"
      @close-sheet="sheetEditStatus = false"
      :item="selectedSite"
      typeItem="sites"
    >
    </BottomSheetEditItem>
    <BottomSheetQualify
      v-if="selectedSite && selectedMission"
      :compteur="selectedCompteur"
      :sheet="sheetQualifyStatus"
      @close-sheet="sheetQualifyStatus = false"
      @refresh-batiments="refreshBatiments()"
      @select-compteur="changeSelectedCompteur($event)"
    >
    </BottomSheetQualify>
    <BottomSheetAllocate
      v-if="selectedSite && selectedMission"
      :sheet="sheetAllocateStatus"
      @close-sheet="sheetAllocateStatus = false"
      :compteur="selectedCompteur"
      @refresh-batiments="refreshBatiments()"
    >
    </BottomSheetAllocate>
    <DialogDeleteConfirmation
      :item="selectedCompteur"
      :dialog="disaffectDialog"
      :disaffect="true"
      @delete-item="disaffectCompteur()"
      @close-dialog="closeDisaffectDialog()"
    >
    </DialogDeleteConfirmation>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';

import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';
import BaseFab from '../components/BaseFab.vue';
import SiteHeader from '../components/sites/SiteHeader.vue';
import SiteCompteursOptions from '../components/sites/SiteCompteursOptions.vue';
import SiteGlobalData from '../components/sites/SiteGlobalData.vue';
import CalculateDataCard from '../components/CalculateDataCard.vue';
import SiteActions from '../components/sites/SiteActions.vue';
import SiteBatimentsList from '../components/sites/SiteBatimentsList.vue';
import BottomSheetAddItem from '../components/BottomSheetAddItem.vue';
import BottomSheetAddCompteur from '../components/BottomSheetAddCompteur.vue';
import BottomSheetEditItem from '../components/BottomSheetEditItem.vue';
import BottomSheetQualify from '../components/BottomSheetQualify.vue';
import BottomSheetAllocate from '../components/BottomSheetAllocate.vue';
import DialogDeleteConfirmation from '../components/DialogDeleteConfirmation.vue';


// import BatimentCard from '../components/BatimentCard.vue';


export default {
  components: {
    BaseFab,
    BottomSheetAddItem,
    SiteHeader,
    SiteGlobalData,
    CalculateDataCard,
    SiteActions,
    SiteBatimentsList,
    SiteCompteursOptions,
    BottomSheetAddCompteur,
    BottomSheetEditItem,
    BottomSheetQualify,
    BottomSheetAllocate,
    DialogDeleteConfirmation,
  },
  props: ['missionId', 'siteId'],
  mixins: [
    UpdateItemAlertMixin,
  ],
  data() {
    return {
      fabOptions: [
        {
          icon: 'mdi-home-city',
          name: 'batiments',
          tooltip: 'Ajouter un bâtiment',
          color: 'green',
        },
        {
          icon: 'mdi-calculator',
          name: 'compteurs',
          tooltip: 'Affecter un compteur',
          color: 'green',
        },
      ],
      showBottomSheet: false,
      showBottomSheetCompteurs: false,
      sheetEditStatus: false,
      typeItem: 'sites',
      sheetQualifyStatus: false,
      sheetAllocateStatus: false,
      loadingCompteurs: false,
      selectedCompteur: null,
      disaffectDialog: false,
    };
  },

  computed: {
    ...mapState({
      listeOptions: state => state.listeOptions.all,
      sites: state => state.sites.sites,
      batiments: state => state.batiments.batiments,
      loading: state => state.loading.loading,
      compteurs: state => state.compteurs.compteurs,
    }),
    ...mapGetters({
      selectedItem: 'selectedItem',
      selectedSite: 'sites/selected',
      selectedMission: 'missions/selected',
      batimentsSite: 'batiments/selectedSiteBatiments',
    }),

    siteCompteurs() {
      if (this.compteurs && this.selectedSite && this.selectedSite.compteurs) {
        return this.compteurs
          .filter(compteur => this.selectedSite.compteurs.includes(compteur._id)).map(compteur => ({
            ...compteur,
            consoAnneeRefDisplay: parseFloat(compteur.consoAnneeRef).toLocaleString('fr'),
            coutAnneeRefDisplay: parseFloat(compteur.coutAnneeRef).toLocaleString('fr'),
            puDisplay: parseFloat(compteur.calculations.prixUnitaireCents).toLocaleString('fr'),
          }));
      }
      return [];
    },

    selectedItemId() {
      return this.selectedItem ? this.selectedItem.id : '';
    },

    defaultItem() {
      return { site: this.siteId };
    },
  },

  async created() {
    this.$store.dispatch('clearSelectedView');
    this.$store.dispatch('changeLoading', true);
    await this.$store.dispatch('changeSelectedItem', { type: 'sites', id: this.siteId });
    await Promise.all([
      this.$store.dispatch('inspections/fetchTypesInspectionByMission',
        {
          idMission: this.selectedMission.id,
        }),

      this.$store.dispatch('batiments/fetchAllBySite', this.siteId),
    ]);
    await this.$store.dispatch('listeOptions/fetchAllByType',
      {
        missionId: this.selectedMission.id,
        codeType: 'ref_typeusage',
      });
    this.$store.dispatch('changeLoading', false);

    this.loadingCompteurs = true;
    this.$store.dispatch('compteurs/fetchAllBySite', this.selectedSite.id)
      .finally(() => {
        this.loadingCompteurs = false;
      });
  },

  methods: {
    // Handle multiple actions from BaseFab and redirect to right action
    handlerFabButton(val) {
      if (val === 'batiments') {
        this.typeItem = val;
        this.showBottomSheet = true;
      } else {
        this.showBottomSheetCompteurs = true;
      }
    },
    refreshBatiments() {
      if (this.batimentsSite) {
        this.batimentsSite.forEach((bat) => {
          this.$store.dispatch('batiments/refreshItem', bat.id);
        });
      }
    },

    changeSelectedCompteur(event) {
      this.selectedCompteur = event;
      this.$store.dispatch('selectCompteur', event);
    },

    openQualifyDialog() {
      this.sheetQualifyStatus = true;
    },

    openAllocateDialog() {
      this.sheetAllocateStatus = true;
    },

    showDisaffectDialog() {
      this.disaffectDialog = true;
    },

    closeDisaffectDialog() {
      this.disaffectDialog = false;
    },

    disaffectCompteur() {
      if (this.selectedSite && this.selectedCompteur) {
        this.loadingCompteurs = true;

        // separate items to not touch on vuex State (important)
        const siteToSave = { ...this.selectedSite };
        const compteursAvaible = [...siteToSave.compteurs];

        // delete compteur from site collection then save site
        compteursAvaible.splice(siteToSave.compteurs.indexOf(this.selectedCompteur.id), 1);
        siteToSave.compteurs = compteursAvaible;
        this.$store.dispatch('sites/save', siteToSave).then(() => {
          this.$store.dispatch('sites/refreshItem', this.selectedSite.id);
        }).finally(() => {
          // have to delete all repartitions concerned by this compteur and all bats site
          this.$store.dispatch('repartitions/fetchRepartitionByIdCompteur', this.selectedCompteur.id)
            .then((response) => {
              const promiseList = [];
              response.forEach((repar) => {
                this.selectedSite.batiments.forEach((bat) => {
                  if (bat === repar.batiment) {
                    promiseList.push(this.$store.dispatch('repartitions/delete', repar));
                    this.majBatimentAlert(bat, 'repartitions');
                  }
                });
              });
              // Have to reload all batiments when repartitions are deleted
              Promise.all(promiseList).then(() => {
                this.selectedSite.batiments.forEach((bat) => {
                  this.$store.dispatch('batiments/refreshItem', bat);
                });
              }).finally(() => {
                // display message to end transaction
                this.$store.dispatch('snackbar/displaySnack', { message: 'Compteur désaffecté avec succès', type: 'succes' });
                this.loadingCompteurs = false;
              });
            });
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
  .column-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;

    padding-top: 1rem;
    padding-bottom: 8rem;

    .cards-column {
      padding-left: 1rem;
      padding-right: 1rem;
      min-width: 750px;

      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;

      > * {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }

  .add-button {
    position: fixed;
    right: 1rem;
    bottom: 5rem;
  }

</style>
