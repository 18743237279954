<template>
  <div class="wiew-wrapper">
    <v-overlay v-if="isDeleting">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <div>
      <div
        class="rapport-view"
        v-if="siteInterventionSites.length"
      >
        <h3>Interventions liées au site
          {{ selectedSite.nom }}</h3>
          <div class="header-button-group">
            <div class="delete-group">
              <v-checkbox
              v-model="selectAllInterventionSite"
              @click.native="selectAll($event)">
              </v-checkbox>
              <v-btn
              color="error"
              class="intervention-delete-selected"
              :disabled = "!selectedInterventions.length "
              @click.stop="handleDeleteRequests(selectedInterventions)"
              >
              supprimer
              <v-icon right>
                mdi-delete
              </v-icon>
              </v-btn>
            </div>
            <v-btn
            @click="openPanel(treeInterventionSites)">
              <v-icon>
                {{arrow}}
              </v-icon>
            </v-btn>
          </div>
        <v-expansion-panels v-model='openedPanelDomaine' accordion multiple>
          <v-expansion-panel
            v-for="domaine in treeInterventionSites"
            :key="domaine.libelle"
          >
          <v-expansion-panel-header>
            <div class="panel-header-wrapper">
              <v-checkbox
                class="custom-checkbox"
                :value = "domaine.libelle"
                v-model="selectedDomaines"
                @click.native="selectDomaine($event, domaine)">
                >

              </v-checkbox>
              <span class="panel-header-title-big">
                {{ domaine.libelle }}
              </span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels
              v-model="openedPanelCategorie[domaine.libelle]" accordion multiple>
              <v-expansion-panel
                v-for="categorie in domaine.categories"
                :key="categorie.libelle"
              >
                <v-expansion-panel-header>
                  <div class="panel-header-wrapper">
                    <v-checkbox
                      class="custom-checkbox"
                      :value = "categorie.libelle"
                      v-model="selectedCategories"
                      @click.native="selectCategorie($event, categorie, domaine)">
                    >
                    </v-checkbox>
                    <span class="panel-header-title">
                      {{ categorie.libelle }}
                    </span>
                    <div class="panel-header-sufix">
                      <span>{{ categorie.interventionSites.length }}</span>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-expansion-panels
                    v-model='openedPanelIntervention[categorie.libelle]'
                    accordion
                    multiple
                    popout
                  >
                    <v-expansion-panel
                      v-for="inter in categorie.interventionSites"
                      :key="inter.id"
                      class="exp-panel"
                    >
                      <v-expansion-panel-header>
                        <template v-slot:default="{ open }">
                          <div class="intervention-header-wrapper">
                            <v-checkbox
                              class="custom-checkbox"
                              :value = "inter"
                              v-model="selectedInterventions"
                              @click.native="selectIntervention($event, inter, categorie, domaine)">
                            </v-checkbox>
                            <span class="intervention-header-title">
                              {{ inter.typeIntervention }}
                            </span>
                            <div class="intervention-header-button-group" v-if="open">
                              <v-btn icon
                                @click.stop="handleDeleteRequest(inter)"
                                class="intervention-delete-button"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                              <!--<v-btn icon
                                class="intervention-edit-button"
                                @click.stop="editIntervention(inter)"
                              >
                                <v-icon>mdi-circle-edit-outline</v-icon>
                              </v-btn>-->
                            </div>
                          </div>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <InterventionOverview
                          :inter="inter"
                          :itemType="inter.estImporte"
                        >
                        </InterventionOverview>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      </div>
    </div>
          <!-- Loaders and no data messages -->
      <div class="no-data" v-if="!siteInterventionSites.length">
        <v-sheet v-if="loading"
          elevation="1"
          class="message-sheet"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            width="4"
            size="45"
          ></v-progress-circular>
          Récupération des données du site...
        </v-sheet>

        <v-sheet v-else
          elevation="1"
          class="message-sheet"
        >
          Il n'y a pas encore d'interventions sur ce site
        </v-sheet>
      </div>
    <DialogDeleteConfirmation
      v-if="deleteDialog"
      :dialog="deleteDialog"
      :item="interToDelete"
      :items="intersToDelete"
      :multiple="isMultiple"
      @delete-item="deleteIntervention()"
      @delete-items="deleteInterventions()"
      @close-dialog="deleteDialog = false"
    >
    </DialogDeleteConfirmation>

  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import InterventionOverview from '../components/interventions/InterventionOverview.vue';
import DialogDeleteConfirmation from '../components/DialogDeleteConfirmation.vue';

export default {
  props: ['missionId', 'siteId'],
  components: {
    InterventionOverview,
    DialogDeleteConfirmation,
  },

  data() {
    return {
      isMultiple: false,
      deleteDialog: false,
      interToDelete: null,
      intersToDelete: [],
      arrow: 'mdi-chevron-down',
      selectAllInterventionSite: false,
      selectedDomaines: [],
      selectedCategories: [],
      selectedInterventions: [],
      openedPanelDomaine: [],
      openedPanelCategorie: {},
      openedPanelIntervention: {},
      isDeleting: false,
    };
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
    }),
    ...mapGetters('sites', { selectedSite: 'selected' }),
    ...mapState('interventionSites', ['interventionSites']),
    selectedView: {
      get() {
        return this.$store.state.selectedView;
      },
      set(value) {
        this.$store.commit('setSelectedView', value);
      },
    },

    siteInterventionSites() {
      if (this.interventionSites) {
        return this.interventionSites
          .filter(interventionSite => interventionSite.site === this.selectedSite.id);
      }
      return [];
    },

    treeInterventionSites() {
      if (!this.siteInterventionSites.length) {
        return [];
      }
      const tree = [];
      const indexes = new Map();
      this.siteInterventionSites.forEach((interventionSite) => {
        // extract domaine & categorie libelle
        if (interventionSite.domaine && interventionSite.categorie) {
          const { domaine, categorie } = interventionSite;
          // const categorie = interventionSite.categorie;
          const domaineCategorie = `${domaine}:${categorie}`;

          // if the domaine doesn't exist in the table
          // add it and store his index the map
          if (indexes.get(domaine) === undefined) {
            const domObject = {
              libelle: domaine,
              categories: [],
            };
            const domIndex = tree.push(domObject) - 1;
            indexes.set(domaine, domIndex);
          }

          // if the categorie doesn't exist in the table
          // add it and store his index in the map
          if (indexes.get(domaineCategorie) === undefined) {
            const catObject = {
              libelle: categorie,
              interventionSites: [],
            };
            const catIndex = tree[indexes.get(domaine)].categories.push(catObject) - 1;
            indexes.set(domaineCategorie, catIndex);
          }

          // add the equipement in the right domaine and in the right categorie
          tree[indexes.get(domaine)]
            .categories[indexes.get(domaineCategorie)]
            .interventionSites.push(interventionSite);
        }
      });
      return tree;
    },

  },
  async created() {
    this.selectedView = 'Intervenir';
    this.$store.dispatch('changeLoading', true);
    await Promise.all([
      this.$store.dispatch('changeSelectedItem', { type: 'sites', id: this.siteId }),
      this.$store.dispatch('interventionSites/fetchAllBySite', this.siteId),
    ]);
    this.$store.dispatch('changeLoading', false);
  },

  methods: {
    deleteIntervention() {
      this.$store.dispatch('interventionSites/delete', this.interToDelete)
        .then(() => {
          this.$store.dispatch('snackbar/displaySnack',
            { message: 'L\'intervention a été supprimée avec succès !', type: 'succes' });
        });
      this.interToDelete = null;
    },

    async deleteInterventions() {
      const deletePromises = [];
      this.isDeleting = true;
      for (let i = 0; i < this.intersToDelete.length; i += 1) {
        deletePromises.push(this.$store.dispatch('interventionSites/delete', this.intersToDelete[i]));
      }
      await Promise.all(deletePromises)
        .then(() => {
          this.$store.dispatch('snackbar/displaySnack',
            { message: 'Les interventions ont été supprimé avec succès !', type: 'succes' });
          this.isDeleting = false;
        });
      this.intersToDelete = [];
      this.selectedInterventions = [];
    },

    handleDeleteRequest(inter) {
      this.isMultiple = false;
      this.interToDelete = inter;
      this.deleteDialog = true;
      const index = this.selectedInterventions.indexOf(inter);
      if (index > -1) {
        this.selectedInterventions.splice(index, 1);
      }
    },

    handleDeleteRequests(inters) {
      if (inters.length > 1) {
        this.isMultiple = true;
        this.intersToDelete = inters;
        this.deleteDialog = true;
      } else {
        this.isMultiple = false;
        this.handleDeleteRequest(inters[0]);
      }
    },

    selectAll(e) {
      e.cancelBubble = true;
      this.selectedDomaines = [];
      this.selectedCategories = [];
      this.selectedInterventions = [];
      if (this.selectAllInterventionSite) {
        this.treeInterventionSites.forEach((domaine) => {
          this.selectedDomaines.push(domaine.libelle);
          this.selectDomaine(e, domaine);
        });
      }
    },

    selectDomaine(e, domaine) {
      e.cancelBubble = true;
      if (this.selectedDomaines.includes(domaine.libelle)) {
        domaine.categories.forEach((categorie) => {
          if (!this.selectedCategories.includes(categorie.libelle)) {
            this.selectedCategories.push(categorie.libelle);
            this.selectCategorie(e, categorie, domaine);
          }
        });
      } else {
        domaine.categories.forEach((categorie) => {
          this.selectedCategories.splice(this.selectedCategories.indexOf(categorie.libelle), 1);
          categorie.interventionSites.forEach((interventionSites) => {
            if (this.selectedInterventions.includes(interventionSites)) {
              this.selectedInterventions
                .splice(this.selectedInterventions.indexOf(interventionSites), 1);
            }
          });
          this.selectAllInterventionSite = false;
        });
      }
    },

    selectCategorie(e, categorie, domaine) {
      e.cancelBubble = true;
      if (this.selectedCategories.includes(categorie.libelle)) {
        categorie.interventionSites.forEach((interventionSites) => {
          if (!this.selectedInterventions.includes(interventionSites)) {
            this.selectedInterventions.push(interventionSites);
          }
        });
      } else {
        categorie.interventionSites.forEach((interventionSites) => {
          if (this.selectedInterventions.includes(interventionSites)) {
            this.selectedInterventions
              .splice(this.selectedInterventions.indexOf(interventionSites), 1);
          }
        });
        if (this.selectedDomaines.includes(domaine.libelle)) {
          this.selectedDomaines.splice(this.selectedDomaines.indexOf(domaine.libelle), 1);
        }
        this.selectAllInterventionSite = false;
      }
    },

    selectIntervention(e, intervention, categorie, domaine) {
      e.cancelBubble = true;
      if (!this.selectedInterventions.includes(intervention)) {
        if (!this.selectedCategories.includes(categorie.libelle)) {
          this.selectedCategories.push(categorie.libelle);
        }
        this.selectedCategories.splice(this.selectedCategories.indexOf(categorie.libelle), 1);
        if (!this.selectedDomaines.includes(domaine.libelle)) {
          this.selectedDomaines.splice(this.selectedDomaines.indexOf(domaine.libelle), 1);
        }
        this.selectAllInterventionSite = false;
      }
    },

    openPanel(InterventionSite) {
      if (!this.panel) {
        for (let i = 0; i < InterventionSite.length; i += 1) {
          const domaine = InterventionSite[i];
          this.openedPanelDomaine.push(i);
          if (domaine.libelle) {
            for (let j = 0; j < domaine.categories.length; j += 1) {
              const categorie = domaine.categories[j];
              if (this.openedPanelCategorie[domaine.libelle]) {
                this.openedPanelCategorie[domaine.libelle].push(j);
              } else {
                this.openedPanelCategorie[domaine.libelle] = [j];
              }
              if (categorie.libelle) {
                for (let k = 0; k < categorie.interventionSites.length; k += 1) {
                  if (this.openedPanelIntervention[categorie.libelle]) {
                    this.openedPanelIntervention[categorie.libelle].push(k);
                  } else {
                    this.openedPanelIntervention[categorie.libelle] = [k];
                  }
                }
              }
            }
          }
        }
        this.panel = true;
        this.arrow = 'mdi-chevron-up';
      } else {
        this.openedPanelDomaine = [];
        this.openedPanelCategorie = {};
        this.openedPanelIntervention = {};
        this.panel = false;
        this.arrow = 'mdi-chevron-down';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .wiew-wrapper {
    height: 100%;

    .rapport-view {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;

      padding: 1rem;

      > * {
        margin-bottom: 2rem;
      }
    }

    .icon-alert-contener{
      width: 30px;
    }

    .no-data {
      height: 100%;
      padding: 20%  1rem 0 1rem;

      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .exp-panel {
    margin-top: 1rem;
  }

  .intervention-header-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    .intervention-header-title {
      font-weight: 300;
      font-size: 1rem;
    }
    .intervention-header-button-group {
      margin-left: auto;
      margin-right: 5px;
      min-width: 75px;
      .intervention-edit-button {
        color: $app-blue !important;
      }
      .intervention-delete-button {
        color: $custom-red;
      }
    }

  }

  .header-button-group{
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;
  }

  .delete-group {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-left: 54px;
  }

  .custom-checkbox {
    padding-top: 0px;
    margin-top: 0px;
    height: 24px;
  }
  .message-sheet {
    height: 8rem;
    border-radius: 10px;
    padding: 1rem;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center;

    font-size: 1.2rem;
    font-weight: 300;
    text-align: center;
  }

  .add-button {
    z-index: 99;
    position: fixed;
    right: 4rem;
    bottom: 4rem;
  }

    .panel-header-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    .panel-header-title {
      font-weight: 300;
      font-size: 1.1rem;
    }

    .panel-header-title-big {
      font-weight: 400;
      font-size: 1.1rem;
    }

    .panel-header-sufix {
      margin-left: auto;
      margin-right: 5px;
      min-width: 150px;

      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
    }

  }

</style>
