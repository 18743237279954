<template>
  <div class="content-wrapper">
    <div class="diag-row-1" v-if="scenar.calculations">
      <ValueWithIcon
        title="Gain (kWhEF)"
        :value="parseFloat(scenar.calculations.gainConsommationEfRelatif).toLocaleString('fr')"
        unit="%"
        icon="mdi-lightning-bolt-circle"
      >
      </ValueWithIcon>

      <ValueWithIcon
        title="Gain CO2"
        :value="parseFloat(scenar.calculations.gainEmissionCo2Relatif).toLocaleString('fr')"
        unit="%"
        icon="mdi-molecule-co2"
      >
      </ValueWithIcon>

      <ValueWithIcon
        title="Gain (€TTC/an)"
        :value="parseFloat(scenar.calculations.gainCoutTtcRelatif).toLocaleString('fr')"
        unit="%"
        icon="mdi-file-document-multiple-outline"
      >
      </ValueWithIcon>

      <ValueWithIcon
        title="Investissement"
        :value="parseFloat(scenar.calculations.investissementHt).toLocaleString('fr')"
        unit="€HT"
        icon="mdi-currency-eur"
      >
      </ValueWithIcon>

      <ValueWithIcon
        title="TRI Brut"
        :value="parseFloat(scenar.calculations.tempsRetourBrut).toLocaleString('fr')"
        unit="an(s)"
        icon="mdi-clock-fast"
      >
      </ValueWithIcon>
    </div>
    <v-textarea
      v-if="scenar.description"
      :value="scenar.description"
      :key="scenar.id"
      label="Description"
      auto-grow
      filled
      class="scenar-description"
    ></v-textarea>
  </div>
</template>

<script>
import ValueWithIcon from '../ValueWithIcon.vue';

export default {
  components: {
    ValueWithIcon,
  },
  props: ['scenar'],
};
</script>
<style lang="scss" scoped>
  .content-wrapper{
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
  }

  .diag-row-1 {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;

    margin-bottom: 2rem;
  }
</style>
