<template>
  <div
    class="programmes-view"
    v-if="site"
  >
    <v-overlay v-if="loading">
    <v-progress-circular
      indeterminate
      size="64"
    ></v-progress-circular>
    </v-overlay>
    <h3>Programmes du site {{ site.nom }}</h3>

    <div
      v-if="!loading && !programmes.length"
    >
      Il n'y a pas encore de programme pour ce site.
      Vous pouvez cliquer sur le bouton + en bas de page pour en créer un.
    </div>


    <v-expansion-panels
      popout
      multiple
      v-model="openedPanels"
      class="bloc-list"
    >
      <v-expansion-panel
        v-for="programme in programmes"
        :key="programme.id"
      >
        <v-expansion-panel-header>
          <template v-slot:default="{ open }">
            <div class="programme-header-wrapper">
              <span class="programme-header-prefix">{{ programme.numero }}</span>
              <span class="programme-header-title">{{ programme.nom }}</span>
              <div class="programme-header-button-group" v-if="open">
                <v-btn icon
                  class="programme-edit-button"
                  @click.stop="editProgramme(programme)"
                >
                  <v-icon>mdi-circle-edit-outline</v-icon>
                </v-btn>
                <v-btn icon
                  @click.stop="handleDeleteRequest(programme)"
                  class="programme-delete-button"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>

          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
        <ProgrammeOverview
            :programme="programme"
            :key="`prog_content_${programme.id}`"
          >
          </ProgrammeOverview>
        </v-expansion-panel-content>

      </v-expansion-panel>
    </v-expansion-panels>

    <v-fab-transition>
      <v-btn
        v-if="!deleteDialog && !editModal"
        color="primary"
        fab
        large
        class="fab-add-button"
        @click="createProgramme()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <DialogDeleteConfirmation
      v-if="deleteDialog"
      :dialog="deleteDialog"
      :item="progToDelete"
      @delete-item="deleteProgramme()"
      @close-dialog="deleteDialog = false; progToDelete = null;"
    >
    </DialogDeleteConfirmation>

    <ProgrammeEditModal
      :show="editModal"
      :programme="progToEdit"
      :create="modalCreateMode"
      @close="closeEditModal()"
    >
    </ProgrammeEditModal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogDeleteConfirmation from '../components/DialogDeleteConfirmation.vue';
import ProgrammeOverview from '../components/programmes/ProgrammeOverview.vue';
import ProgrammeEditModal from '../components/programmes/ProgrammeEditModal.vue';

export default {
  props: ['siteId'],

  components: {
    DialogDeleteConfirmation,
    ProgrammeOverview,
    ProgrammeEditModal,
  },

  data() {
    return {
      loading: false,
      deleteDialog: false,
      progToDelete: null,
      editModal: false,
      progToEdit: null,
      modalCreateMode: false,
      openedPanels: [],
    };
  },

  computed: {
    ...mapGetters({
      site: 'sites/selected',
      programmes: 'programmes/selectedSiteProgrammes',
    }),
    selectedView: {
      get() {
        return this.$store.state.selectedView;
      },
      set(value) {
        this.$store.commit('setSelectedView', value);
      },
    },

  },

  async created() {
    this.selectedView = 'Programmer';
    this.loading = true;
    await this.$store.dispatch('changeSelectedItem', { type: 'sites', id: this.siteId });
    await this.$store.dispatch('programmes/fetchAllBySite', this.siteId);
    let i = 0;
    while (i < this.programmes.length) {
      this.openedPanels.push(i);
      i += 1;
    }
    this.loading = false;
  },

  methods: {
    createProgramme() {
      this.progToEdit = {};
      this.modalCreateMode = true;
      this.editModal = true;
    },

    deleteProgramme() {
      this.$store.dispatch('programmes/delete', this.progToDelete);
      this.progToDelete = null;
    },

    handleDeleteRequest(programme) {
      this.progToDelete = programme;
      this.deleteDialog = true;
    },

    editProgramme(programme) {
      this.progToEdit = programme;
      this.editModal = true;
    },

    closeEditModal() {
      this.editModal = false;
      this.progToEdit = null;
      if (this.modalCreateMode) {
        this.modalCreateMode = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .programmes-view {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    padding: 1rem;

    > * {
      margin-bottom: 2rem;
    }
  }

  .programme-header-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    .programme-header-prefix {
      width: 2rem;
    }

    .programme-header-title {
      font-weight: 300;
      font-size: 1.3rem;
    }

    .programme-header-button-group {
      margin-left: auto;
      margin-right: 5px;
      min-width: 75px;
      .programme-edit-button {
        color: $app-blue !important;
      }
      .programme-delete-button {
        color: $custom-red;
      }
    }

  }

  .bloc-list {
    max-width: 1000px;
  }

  .fab-add-button {
    position: fixed;
    bottom: 2rem;
    right: 3rem;
    z-index: 500;
  }
</style>
