<template>
  <div class="field-wrapper">
    <div class="field-label">
      <slot name="field-label"></slot>
    </div>
    <div class="radio-wrapper">
      <v-radio-group
        row
        v-model="localValue"
        @change="$emit('change', localValue)"
      >
      <v-radio
          v-for="entry in entries"
          :key="entry"
          :label="entry"
          :value="entry"></v-radio>
      </v-radio-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'multi-radio-field',
  props: {
    formField: Object,
    defaultValue: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      localValue: this.defaultValue,
      entries: [],
    };
  },
  created() {
    this.populateEntries();
  },
  methods: {
    populateEntries() {
      if (Array.isArray(this.formField.options)) {
        this.entries = this.formField.options;
      }
    },
  },
  watch: {
    defaultValue(newValue) {
      this.localValue = newValue;
    },

    localValue(newValue) {
      this.$emit('change', newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
  .field-wrapper{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }

  .radio-wrapper {
    max-width: 22rem;
  }

  .field-label {
    width: 180px;
  }
</style>
