import api from '@/api';

/* eslint no-console: ["error", { allow: ["error", "log"] }] */
/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = {
  repartitions: [],
};

const getters = { };

const actions = {

  /**
   * Create a new Repartition and add it to the store
   */
  createNew({ commit }) {
    const newRepartition = {
      id: `tempRep${Math.random()}`,
      repartition: 0,
      batiment: null,
      compteur: null,
      modeRepartition: 'mode repa',
      postes: [],
    };

    commit('addRepartitions', [newRepartition]);
    return { ...newRepartition };
  },

  /**
   * Save a repartition on the server (automatically select POST or PUT) and update the store
   */
  save({ commit }, payload) {
    return new Promise((resolve, reject) => {
      // if the repartition doesn't exhist on the backend, create it by post
      if (payload.id.startsWith('tempRep')) {
        api.repartitions.create(payload.data)
          .then((response) => {
            commit('deleteRepartition', payload.id);
            commit('addRepartitions', [response]);
            resolve({ ...response });
          })
          .catch(error => reject(error));
      // else update it by put
      } else {
        api.repartitions.update(payload.id, payload.data)
          .then((response) => {
            commit('addRepartitions', [response]);
            resolve({ ...response });
          })
          .catch(error => reject(error));
      }
    });
  },

  /**
   * Fetch all batiments from the backend and update the store
   */
  fetchAll({ commit }) {
    return api.repartitions.getAll().then(response => commit('addRepartitions', response));
  },


  /**
   * Fetch all repartitions by compteur id from the backend and update the store
   */
  fetchRepartitionByIdCompteur({ commit }, compteurId) {
    return new Promise((resolve, reject) => {
      api.repartitions.getAllByCompteur(compteurId)
        .then((response) => {
          commit('addRepartitions', response);
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  /**
   * Fetch all repartitions by batiment id from the backend and update the store
   */
  fetchRepartitionByIdBatiment({ commit }, batimentId) {
    return new Promise((resolve, reject) => {
      api.repartitions.getAllByBatiment(batimentId)
        .then((response) => {
          commit('addRepartitions', response);
          resolve(response);
        })
        .catch(error => reject(error));
    });
  },

  /**
   * Load all repartitions stored offline in pouchDB
   */
  loadOffline({ commit }) {
    return api.repartitions.loadOffline().then(response => commit('addRepartitions', response));
  },

  /**
   * Fetch one repartitions by it's id fron the server and update the store
   */
  refreshItem({ commit }, repartitionId) {
    return new Promise((resolve) => {
      api.repartitions.get(repartitionId).then((response) => {
        commit('addRepartitions', [response]);
        resolve({ ...response });
      });
    });
  },

  /**
   * Delete a repartition from the server & from the store
   */
  async delete({ commit }, repartition) {
    return api.repartitions.delete(repartition.id)
      .then(() => {
        commit('deleteRepartition', repartition.id);
      });
  },

};

const mutations = {
  addRepartitions(state, newRepartitions) {
    newRepartitions.forEach((newRepartition) => {
      const stateIndex = state.repartitions
        .findIndex(stateRepartition => stateRepartition.id === newRepartition.id);

      if (stateIndex >= 0) {
        state.repartitions.splice(stateIndex, 1, newRepartition);
      } else {
        state.repartitions.push(newRepartition);
      }
    });
  },

  deleteRepartition(state, clientId) {
    state.repartitions.splice(
      state.repartitions.findIndex(repartition => repartition.id === clientId),
      1,
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
