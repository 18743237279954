<template>
  <div>
    <div class="clients-view-wrapper">
      <!-- Search Field -->
      <transition appear name="fade">
        <v-text-field
          v-model="search"
          outlined
          rounded
          placeholder="Rechercher un client dans la liste"
          label="Recherche"
          append-icon="mdi-magnify"
          class="clients-search-field"
          color="tertiary"
        >
        </v-text-field>
      </transition>
      <!-- Client List Card -->
      <transition appear name="slide-fade-down">
        <v-card
          class="clients-list-card"
          :loading="loading"
        >
          <v-expand-transition>
            <ClientSortedList
              class="client-list-wrapper"
              v-if="!loading && clients.length"
              :clients="filteredClients"
              @client-clicked="goToClient($event)"
            >
            </ClientSortedList>
          </v-expand-transition>

          <v-card-text
            v-if="loading"
            class="card-message"
          >
            Récuperation de la liste des clients
          </v-card-text>
          <v-card-text
            v-if="!loading && !clients.length"
            class="card-message"
          >
            Erreur lors de la récupération de la liste des clients
          </v-card-text>
        </v-card>
      </transition>
    </div>
    <BaseFab
      :options="fabOptions"
      @option-selected="handlerFabButton"
      key="clientView_fab"
      class="add-button"
    >
    </BaseFab>
    <BottomSheetAddItem
      :sheet="showBottomSheet"
      @close-sheet="showBottomSheet = false"
      typeItem="clients"
    >
    </BottomSheetAddItem>
  </div>
</template>
<script>
import { mapState } from 'vuex';

import BaseFab from '../components/BaseFab.vue';
import BottomSheetAddItem from '../components/BottomSheetAddItem.vue';
import ClientSortedList from '../components/clients/ClientSortedList.vue';

export default {
  components: {
    BottomSheetAddItem,
    BaseFab,
    ClientSortedList,
  },

  data() {
    return {
      search: '',
      loading: false,
      showBottomSheet: false,
      fabOptions: [
        {
          icon: 'mdi-account',
          name: 'Ajout',
          tooltip: 'Nouveau client',
          color: 'green',
        },
      ],
    };
  },

  computed: {
    ...mapState('clients', ['clients']),

    filteredClients() {
      if (this.search && this.search.length > 2) {
        return this.clients.filter(
          client => client.nom.toLowerCase().includes(this.search.toLowerCase()),
        );
      }
      return this.clients;
    },
  },

  async created() {
    this.$store.dispatch('clearSelectedView');
    this.loading = true;
    await this.$store.dispatch('clearSelectedItem');
    await this.$store.dispatch('clients/fetchAll');
    this.loading = false;
  },

  methods: {
    // Handle multiple actions from BaseFab and redirect to right action
    handlerFabButton(val) {
      if (val === this.fabOptions[0].name) {
        this.addNewClient();
      }
    },

    // Show botton sheet and add New Client in Store
    addNewClient() {
      this.showBottomSheet = true;
      // this.$store.dispatch('clients/createNewClient');
    },

    async goToClient(client) {
      await this.$store.dispatch('clients/refreshItem', client.id)
        .then(() => {
          this.$router.push({ name: 'clientDetail', params: { clientId: client.id } });
        });
    },
  },

};
</script>

<style lang="scss" scoped>
  .clients-view-wrapper {
    padding: 2rem 1rem;
    max-width: 1000px;
    margin: 0 auto;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;

    padding-bottom: 9rem;
  }

  .client-list-wrapper {
    margin-top: 1rem;
  }

  .clients-list-card {
    border-radius: 18px !important;
    padding: 0 1rem 1rem 1rem;
  }

  .card-message {
    margin: 1rem auto;
    text-align: center;
  }

  .add-button {
    position: fixed;
    bottom: 4rem;
    right: 4rem;
  }
</style>
