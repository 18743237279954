/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  sitesEndpoint,
  batimentsEndpoint,
  batimentsUrl,
  niveauxEndpoint,
  equipementsEndpoint,
  repartitionsEndpoint,
} from './apiUrls';

import niveauxApi from './niveauxApi';
import repartitionApi from './repartitionsApi';
import equipementsApi from './equipementsApi';
import inspectionsApi from './inspectionsApi';
import interventionsApi from './interventionsApi';
import picturesApi from './picturesApi';


export default {
  db: new PouchDB('batiments'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch
    const _id = obj._id || obj.id.toString();

    return {
      _id,
      meta: obj.meta || {},
      id: String(obj.id),
      nom: obj.nom,
      adresse: obj.adresse,
      typeUsage: obj.typeUsage,
      typeUsageDetaille: obj.typeUsageDetaille,
      anneeConstruction: obj.anneeConstruction,
      idClient: obj.idClient,
      surfaceDeclaree: obj.surfaceDeclaree,
      surface2: obj.surface2,
      typeSurface: obj.typeSurface,
      typeSurface2: obj.typeSurface2,
      nbNiveau: obj.nbNiveau,
      rehabilitation: obj.rehabilitation,
      contraintesArchi: obj.contraintesArchi,
      descriptionArchi: obj.descriptionArchi,
      presenceErp: obj.presenceErp,
      typeERP: obj.typeERP,
      categorieERP: obj.categorieERP,
      coordGPS: obj.coordGPS,
      nbOccupants: obj.nbOccupants,
      surfaceChauffee: obj.surfaceChauffee,
      auditeur: obj.auditeur,
      commentaireVisite: obj.commentaireVisite,
      accompagnant: obj.accompagnant,
      identAccompagnant: obj.identAccompagnant,
      fonctionAccompagnant: obj.fonctionAccompagnant,
      organismeAccompagnant: obj.organismeAccompagnant,
      energiesChauffage: obj.energiesChauffage,
      // TODO voir conversion date
      dateReleve: obj.dateReleve,
      niveaux: obj.niveaux.map(niv => niv.replace(`${niveauxEndpoint}/`, '')),
      equipements: obj.equipements.map(eqp => eqp.replace(`${equipementsEndpoint}/`, '')),
      /* inspections: obj.inspections.map(insp => insp.replace(`${inspectionsEndpoint}/`, '')),
      interventions: obj.interventions.map(inter =>
        inter.replace(`${interventionsEndpoint}/`, '')), */
      site: obj.site.replace(`${sitesEndpoint}/`, ''),
      repartitionCompteurBatiments: obj.repartitionCompteurBatiments.map(rep => rep.replace(`${repartitionsEndpoint}/`, '')),
      calculations: obj.calculations,
      images: obj.images,
      imagePrincipale: obj.imagePrincipale,
      hasSurfacePreferee: obj.hasSurfacePreferee,
      plancherBas: obj.plancherBas,
      plancherHaut: obj.plancherHaut,
      paroiOpaque: obj.paroiOpaque,
      paroiVitree: obj.paroiVitree,
      volumePrefere: obj.volumePrefere,
      inAlert: obj.inAlert,
      usageCee: obj.usageCee,
      organisationListeEquipement: obj.organisationListeEquipement,
    };
  },

  async create(batiment, batimentId) {
    // on créé un objet uniquement pour la sauvegarde locale si pas de connexion
    const localBatiment = {
      id: batimentId,
      ...batiment,
      inAlert: {
        inAlertBatiment: true,
        inAlertRepartition: false,
        inAlertCollectEquipement: false,
        inAlertCollectInspection: false,
        inAlertIntervention: false,
      },
    };
    // si l'objet est dépendant d'un objet supérieur pas encore créé
    // alors on ne tente même pas une sauvegarde serveur
    // et on le sauvegarde directement sur PouchDB
    if (batiment.site.startsWith('temp')) {
      return pouchUtil.addDoc(localBatiment, this.db, { toCreate: true });
    }

    let savedBatiment = null;

    try {
      const batimentToCreate = {
        ...batiment,
        inAlert: {
          inAlertBatiment: true,
          inAlertRepartition: false,
          inAlertCollectEquipement: false,
          inAlertCollectInspection: false,
          inAlertIntervention: false,
        },
      };
      delete batimentToCreate.images;
      // on essaye de sauvegarder via l'API
      const response = await axiosInstance.axiosInstance.post(batimentsEndpoint, batimentToCreate);
      savedBatiment = this.convertBackendObject(response.data);
    } catch (e) {
      if (e.response
        && e.response.status >= 400
        && e.response.status < 500) {
        throw new Error(e);
      }
      // si la sauvegarde en ligne a échouée, on enregistre les données dans
      // le cache avec le flag 'toCreate' à true afin de relancer la synchro
      // quand on aura à nouveau une connexion
      return pouchUtil.addDoc(localBatiment, this.db, { toCreate: true });
    }

    // si la sauvegarde en ligne a fonctionné
    // si on avait un batiment avec un id temporaire dans pouchDB on le supprime
    if (batimentId.startsWith('temp')) {
      try {
        await pouchUtil.deleteDoc(batimentId, this.db);
      } catch (e) {
        // do nothing
      }
    }

    // update batiment childrens with the new id
    await this.updateChildren(batimentId, savedBatiment.id);

    // enfin on sauvegarde dans pouchDB le nouveau batiment et on le retourne
    return pouchUtil.addDoc(savedBatiment, this.db);
  },

  async update(batimentId, batiment) {
    let savedBatiment = null;

    try {
      const batimentToSave = { ...batiment };
      delete batimentToSave.images;
      // on essaye de sauvegarder via l'API
      const response = await axiosInstance.axiosInstance.put(`${batimentsEndpoint}/${batimentId}`, batimentToSave);
      savedBatiment = this.convertBackendObject(response.data);
    } catch (e) {
      if (e.response
        && e.response.status >= 400
        && e.response.status < 500) {
        throw new Error(e);
      }
      // si la sauvegarde en ligne a échouée, on enregistre les données dans
      // le cache avec le flag 'toUpdate' à true afin de relancer la synchro
      // quand on aura à nouveau une connexion
      return pouchUtil.addDoc({ id: batimentId, ...batiment }, this.db, { toUpdate: true });
    }

    // si la sauvegarde en ligne à reussie, on sauvegarde dans pouchDB et on retourne le resultat
    return pouchUtil.addDoc(savedBatiment, this.db, { updated: true });
  },

  /**
   * synchronise a batiment with the server, creating or updating it
   */
  async synchronise(batiment) {
    // creation
    if (batiment.meta.toCreate) {
      if (batiment.site.startsWith('temp')) {
        const pouchBatiment = await pouchUtil.addDoc(batiment, this.db, {
          toCreate: true,
          error: true,
          errorMessage: 'Site parent non synchronisé',
        });
        return { savedItem: pouchBatiment };
      }

      try {
        // make a copy of batiment with a removed id field for the POST request
        const batimentToCreate = { ...batiment };
        delete batimentToCreate.id;
        delete batimentToCreate.image;

        // create batiment on the server
        const response = await axiosInstance.axiosInstance
          .post(batimentsEndpoint, batimentToCreate);
        const savedBatiment = this.convertBackendObject(response.data);

        // delete temp batiment in pouch
        if (batiment._id.startsWith('temp')) {
          try {
            await pouchUtil.deleteDoc(batiment._id, this.db);
          } catch (e) {
            // do nothing
          }
        }

        // update batiment childrens with the new id
        await this.updateChildren(batiment._id, savedBatiment.id);

        // save batiment in pouch and return it
        const pouchBatiment = await pouchUtil.addDoc(savedBatiment, this.db);
        return {
          oldId: batiment._id,
          savedItem: pouchBatiment,
        };
      } catch (e) {
        // if create fail and functionnal error -> save on pouch with error
        if (e.response
          && e.response.status >= 400
          && e.response.status < 500) {
          const pouchBatiment = await pouchUtil.addDoc(batiment, this.db, {
            toCreate: true,
            error: true,
            errorMessage: e.response.data.detail,
          });
          return { savedItem: pouchBatiment };
        }
        // if it's not a functionnal error, stop synchro and throw error
        throw new Error(e);
      }
    }

    // update
    if (batiment.meta.toUpdate) {
      try {
        const batimentToUpdate = { ...batiment };
        delete batimentToUpdate.image;

        // update batiment on the server
        const response = await axiosInstance.axiosInstance.put(`${batimentsEndpoint}/${batiment.id}`, batimentToUpdate);
        const savedBatiment = this.convertBackendObject(response.data);

        // save batiment in pouch and return it
        const pouchBatiment = await pouchUtil.addDoc(savedBatiment, this.db, { updated: true });
        return { savedItem: pouchBatiment };
      } catch (e) {
        // if update fail and functionnal error -> save on pouch with error
        if (e.response
          && e.response.status >= 400
          && e.response.status < 500) {
          const pouchBatiment = await pouchUtil.addDoc(batiment, this.db, {
            toUpdate: true,
            error: true,
            errorMessage: e.response.data.detail,
          });
          return { savedItem: pouchBatiment };
        }
        // if it's not a functionnal error, stop synchro and throw error
        throw new Error(e);
      }
    }

    throw new Error('no update/create flag on this batiment');
  },

  /**
   * synchronise with the server all batiment that need to be saved
   */
  async synchroniseAll() {
    // get all batiments in PouchDB with meta.create === true or meta.update === true
    const batimentsToSave = await pouchUtil.getAllDocsToSync(this.db);
    return Promise.all(batimentsToSave.map(batiment => this.synchronise(batiment)));
  },

  /**
   * When a newSite is created, if this site has unsaved batiments children
   * update those batiments to replace the tempSiteId wit the new id
   */
  async handleParentCreated(tempSiteId, newSiteId) {
    // get all the batiments that are in the site
    const childrens = await pouchUtil.getAllDocsByField('site', tempSiteId, this.db);

    if (childrens.length) {
      // update the children with the new site id
      const updatedChildrens = childrens.map(children => ({
        ...children,
        site: newSiteId,
      }));
      // save them back on PouchDB
      await this.db.bulkDocs(updatedChildrens);
    }
  },

  async updateChildren(tempBatimentId, newBatimentId) {
    await Promise.all([
      niveauxApi.handleParentCreated(tempBatimentId, newBatimentId),
      repartitionApi.handleParentCreated(tempBatimentId, newBatimentId),
      equipementsApi.handleParentCreated(tempBatimentId, newBatimentId),
      inspectionsApi.handleParentCreated(tempBatimentId, newBatimentId, 'batiment'),
      interventionsApi.handleParentCreated(tempBatimentId, newBatimentId),
      picturesApi.handleParentCreated(tempBatimentId, newBatimentId),
    ]);
  },

  /**
   * delete a batiment from pouchDB and the server
   */
  async delete(batimentId) {
    // delete the batiment from pouchDB
    await pouchUtil.deleteDoc(batimentId, this.db);
    // delete any picture not yet syncronized
    await picturesApi.handleParentDeleted(batimentId);

    // only delete from server if it's not a temp item
    if (!batimentId.startsWith('temp')) {
      // if the delete from pouchDB is sucessfull, delete from the server
      return axiosInstance.axiosInstance.delete(`${batimentsEndpoint}/${batimentId}`);
    }
    return true;
  },

  /**
   * get one batiment by his id
   * @param {String} batimentId - the id of the batiment to get
   */
  async get(batimentId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${batimentsEndpoint}/${batimentId}`);
      const batiment = this.convertBackendObject(response.data);

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDoc(batiment, this.db);
    } catch (e) {
      console.error('batimentsApi get error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getDoc(batimentId, this.db);
    }
  },

  /**
   * get all batiments of the application
   */
  async getAll() {
    // first try to fetch data from API
    try {
      const response = await axiosInstance.axiosInstance.get(`${batimentsEndpoint}`);
      const batiments = response.data.map(batiment => this.convertBackendObject(batiment));

      // if get is successful,  store data into pouchdb and return it
      const results = await pouchUtil.addDocs(batiments, this.db);

      return results;
    } catch (e) {
      console.error('batimentsApi getAll error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocs(this.db);
    }
  },

  /**
   * get all children batiments of a site
   * @param {String} siteId - id of the parent site
   */
  async getAllBySite(siteId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${sitesEndpoint}/${siteId}${batimentsUrl}`);
      const batiments = response.data.map(batiment => this.convertBackendObject(batiment));
      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(batiments, this.db);
    } catch (e) {
      console.error('batimentsApi getAllBySite error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByField('site', siteId, this.db);
    }
  },

  /**
   * get all children batiments of a site for offline use
   * @param {String} siteId - id of the parent site
   */
  async fetchOffline(siteId) {
    const response = await axiosInstance.axiosInstance.get(`${sitesEndpoint}/${siteId}${batimentsUrl}`);
    const batiments = response.data.map(batiment => this.convertBackendObject(batiment));
    return pouchUtil.addDocs(batiments, this.db);
  },

  /**
   * Fetch all batiments stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocsToSync(this.db);
  },
};
