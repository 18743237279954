import api from '@/api';

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
/* eslint no-console: ["error", { allow: ["error", "log"] }] */

// initial state
const state = {
  niveaux: [],
  selectedId: null,
};

// getters
const getters = {
  toSync: state => state.niveaux
    .filter(niveau => niveau.meta && (niveau.meta.toCreate || niveau.meta.toUpdate)),

  nbToSync: (state, getters) => {
    if (getters.toSync && getters.toSync.length) {
      return getters.toSync.length;
    }
    return 0;
  },

  selected: state => state.niveaux.find(niveau => niveau.id === state.selectedId),

  selectedBatimentNiveaux: (state, getters, rootState, rootGetters) => {
    const batiment = rootGetters['batiments/selected'];
    if (batiment) {
      return state.niveaux.filter(niveau => niveau.batiment === batiment.id);
    }
    return [];
  },

};

// actions
const actions = {
  /**
   * Create a new niveau and add it to the store
   */
  createNiveauOnBatiment({ commit }, inputBatimentId) {
    const newNiveau = {
      id: `tempNiv${Math.random()}`,
      batiment: inputBatimentId,
      nom: 'Niv.',
      perimetre: null,
      hsp: null,
      txPbExt: null,
      txPhExt: null,
      txSv: null,
    };
    commit('addNiveaux', [newNiveau]);


    return { ...newNiveau };
  },

  /**
   * Save a niveau on the server (automatically select POST or PUT) and update the store
   */
  save({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      // function to save niveau
      const saveNiveauFonction = (form, niveauId) => new Promise((resolveNiveau) => {
        // if the niveaux doesn't exhist on the backend, create it by post
        if (niveauId.startsWith('tempNiv')) {
          const stateNiveau = state.niveaux.find(newniv => newniv.id === payload.id);
          const niveauToSave = {
            ...form,
            batiment: stateNiveau.batiment,
          };
          api.niveaux.create(niveauToSave, niveauId)
            .then((response) => {
              commit('deleteNiveau', niveauId);
              commit('addNiveaux', [response]);
              resolveNiveau({ ...response });
            })
            .catch(error => reject(error));
        // else update it by put
        } else {
          const stateNiveau = state.niveaux.find(backequip => backequip.id === payload.id);
          const niveauToSave = {
            ...form,
            batiment: stateNiveau.batiment,
          };
          api.niveaux.update(niveauToSave, niveauId)
            .then((response) => {
              commit('addNiveaux', [response]);
              resolveNiveau({ ...response });
            })
            .catch(error => reject(error));
        }
      });
      // save the equipement
      const savedNiveau = await saveNiveauFonction(payload.data, payload.id);

      // resolve promise
      resolve(savedNiveau);
    });
  },

  /**
   * Synchronise all niveaux with the server and re-load the list contained in pouchDB
   */
  async synchroniseAll({ commit, dispatch }) {
    const syncNiveaux = await api.niveaux.synchroniseAll();
    commit('clearNiveaux');
    await dispatch('loadOffline');
    return syncNiveaux;
  },

  /**
   * Fetch all niveaux from the server and update the store
   */
  fetchAll({ commit }) {
    return api.niveaux.getAll()
      .then(response => commit('addNiveaux', response));
  },

  /**
   * Fetch all children niveaux of a batiment
   * @param {String} batimentId - id of the parent batiment
   */
  fetchAllByBatiment({ commit }, batimentId) {
    return api.niveaux.getAllByBatiment(batimentId)
      .then(response => commit('addNiveaux', response));
  },

  /**
   * Load all niveaux stored offline in pouchDB
   */
  loadOffline({ commit }) {
    return api.niveaux.loadOffline().then(response => commit('addNiveaux', response));
  },

  /**
   * Fetch one niveau by it's id fron the server and update the store
   */
  refreshItem({ commit }, niveauId) {
    return api.niveaux.get(niveauId)
      .then((response) => {
        commit('addNiveaux', [response]);
        return { ...response };
      });
  },

  /**
   * Delete a niveau from the server & from the store
   */
  delete({ commit }, niveau) {
    return api.niveaux.delete(niveau.id)
      .then(() => commit('deleteNiveau', niveau.id));
  },

  async changeSelected({
    state, getters, commit, dispatch, rootState,
  },
  niveauId) {
    if (state.selectedId !== niveauId) {
      commit('changeSelectedId', niveauId);

      if (!getters.selected) {
        await dispatch('refreshItem', niveauId);
      }

      if (!rootState.batiments.selected
        || rootState.batiments.selected.id !== getters.selected.batiment) {
        await dispatch('batiments/changeSelected', getters.selected.batiment, { root: true });
      }
    }
  },

  async clearSelected({ commit }) {
    await commit('changeSelectedId', null);
  },
};

// mutations
const mutations = {

  addNiveaux(state, newNiveaux) {
    newNiveaux.forEach((newNiveau) => {
      const stateIndex = state.niveaux.findIndex(stateNiveau => stateNiveau.id === newNiveau.id);
      if (stateIndex >= 0) {
        state.niveaux.splice(stateIndex, 1, newNiveau);
      } else {
        state.niveaux.push(newNiveau);
      }
    });
  },

  deleteNiveau(state, niveauId) {
    state.niveaux.splice(state.niveaux.findIndex(niveau => niveau.id === niveauId), 1);
  },

  clearNiveaux(state) {
    state.niveaux = [];
  },

  changeSelectedId(state, niveauId) {
    state.selectedId = niveauId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
