import api from '@/api';

/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
/* eslint no-console: ["error", { allow: ["error", "log"] }] */

// initial state
const state = {
  equipements: [],

  types: [],
  categories: [],
  domaines: [],
  activesDomaines: [],
  activesCategories: [],

  selectedId: null,
};

// getters
const getters = {
  toSync: state => state.equipements.filter(
    equipement => equipement.meta && (equipement.meta.toCreate || equipement.meta.toUpdate),
  ),

  nbToSync: (state, getters) => {
    if (getters.toSync && getters.toSync.length) {
      return getters.toSync.length;
    }
    return 0;
  },

  selected: state => state.equipements.find(equipement => equipement.id === state.selectedId),

  selectedBatimentEquipements: (state, getters, rootState, rootGetters) => {
    const batiment = rootGetters['batiments/selected'];
    if (batiment) {
      return state.equipements.filter(eqp => eqp.batiment === batiment.id);
    }
    return [];
  },

  selectedEquipementType: (state, getters) => {
    const equipement = getters.selected;
    if (equipement) {
      return state.types.find(type => type.id === getters.selected.type);
    }
    return [];
  },

  selectedMissionDomaines: (state, getters, rootState, rootGetters) => {
    const mission = rootGetters['missions/selected'];
    if (mission) {
      return state.domaines.filter(dom => dom.mission === mission.id);
    }
    return [];
  },

  selectedMissionCategories: (state, getters, rootState, rootGetters) => {
    const mission = rootGetters['missions/selected'];
    if (mission) {
      return state.categories.filter(cat => cat.mission === mission.id);
    }
    return [];
  },

  selectedMissionTypes: (state, getters, rootState, rootGetters) => {
    const mission = rootGetters['missions/selected'];
    if (mission) {
      return state.types.filter(type => type.mission === mission.id);
    }
    return [];
  },
};

// actions
const actions = {
  /**
   * Create a new equipement and add it to the store
   */
  createEquipementOnBatiment({ commit, getters }, payload) {
    // find equipement type, domaine and catégorie in the store
    const equipCat = getters.selectedMissionCategories
      .find(cat => cat.id === payload.equipementType.categorie);
    const equipDom = getters.selectedMissionDomaines
      .find(dom => dom.id === equipCat.domaine);

    const newEquipement = {
      id: `tempEqp${Math.random()}`,
      batiment: payload.batimentId,
      nom: `${payload.equipementType.nom}`,
      type: payload.equipementType.id,
      libelleType: payload.equipementType.nom,
      libelleCategorie: equipCat.nom,
      libelleDomaine: equipDom.nom,
      images: [],
    };

    commit('addEquipements', [newEquipement]);

    return { ...newEquipement };
  },

  /**
   * Save an equipement on the server (automatically select POST or PUT) and update the store
   */
  save({ commit }, payload) {
    return new Promise(async (resolve, reject) => {
      // function to save equipement
      const saveEquipFonction = (form, equipementId) => new Promise((resolveEquipement) => {
        // if the equipement doesn't exhist on the backend, create it by post
        if (equipementId.startsWith('tempEqp')) {
          const stateEquipement = state.equipements.find(newequip => newequip.id === payload.id);
          const equipementToSave = {
            ...form,
            type: stateEquipement.type,
            batiment: stateEquipement.batiment,
          };

          api.equipements.create(equipementToSave, equipementId)
            .then((response) => {
              commit('deleteEquipement', equipementId);
              commit('addEquipements', [response]);
              resolveEquipement({ ...response });
            })
            .catch(error => reject(error));
        // else update it by put
        } else {
          const stateEquipement = state.equipements.find(backequip => backequip.id === payload.id);
          const equipementToSave = {
            ...form,
            id: stateEquipement.id,
            type: stateEquipement.type,
            batiment: stateEquipement.batiment,
          };

          // update equipement
          api.equipements.update(equipementToSave, equipementId)
            .then((response) => {
              commit('addEquipements', [response]);
              resolveEquipement({ ...response });
            })
            .catch(error => reject(error));
        }
      });

      // save the equipement
      const savedEquipement = await saveEquipFonction(payload.data, payload.id);

      // resolve promise
      resolve(savedEquipement);
    });
  },

  async synchroniseAll({ commit, dispatch }) {
    const syncEquipements = await api.equipements.synchroniseAll();
    commit('clearEquipements');
    await dispatch('loadOffline');
    return syncEquipements;
  },

  /**
   * Fetch all equipements from the server and update the store
   */
  fetchAll({ commit }) {
    return api.equipements.getAll()
      .then(response => commit('addEquipements', response));
  },

  /**
   * Fetcha all children equipements of a batiment
   * @param {String} batimentId - id of the parent batiment
   */
  fetchAllByBatiment({ commit }, batimentId) {
    return api.equipements.getAllByBatiment(batimentId)
      .then(response => commit('addEquipements', response));
  },

  /**
   * Load all equiepements stored offline in pouchDB
   */
  loadOffline({ commit }) {
    return api.equipements.loadOffline().then(response => commit('addEquipements', response));
  },

  /**
   * Fetch one equipement by it's id fron the server and update the store
   */
  refreshItem({ commit }, equipementId) {
    return api.equipements.get(equipementId)
      .then((response) => {
        commit('addEquipements', [response]);
        return { ...response };
      });
  },

  /**
   * Delete an equipement from the server & from the store
   * and delete its linked interventions
   */
  async delete({ commit, dispatch }, equipementId) {
    // Delete equipment from the server & from the store
    await api.equipements.delete(equipementId);
    commit('deleteEquipement', equipementId);
    // Delete linked interventions for offline use (local storage) and from the store
    await dispatch('interventions/deleteByEquipementOffline', equipementId, { root: true });
  },

  async changeSelected({
    state, getters, commit, dispatch, rootState,
  },
  equipementId) {
    if (state.selectedId !== equipementId) {
      commit('changeSelectedId', equipementId);

      if (!getters.selected) {
        await dispatch('refreshItem', equipementId);
      }

      if (!rootState.batiments.selected
        || rootState.batiments.selected.id !== getters.selected.batiment) {
        await dispatch('batiments/changeSelected', getters.selected.batiment, { root: true });
      }
    }
  },

  async clearSelected({ commit }) {
    await commit('changeSelectedId', null);
  },

  fetchAllRef({ dispatch }, missionId) {
    return Promise.all([
      // dispatch('fetchAllTypes', missionId),
      dispatch('fetchAllDomaines', missionId),
      dispatch('fetchAllCategories', missionId),
    ]);
  },

  updateRefMission({ dispatch }, data) {
    return Promise.all([
      // dispatch('fetchAllTypes', missionId),
      dispatch('fetchAllDomaines', data),
      dispatch('fetchAllCategories', data),
    ]);
  },

  loadAllRefOffline({ dispatch }) {
    return Promise.all([
      dispatch('loadAllTypesOffline'),
      dispatch('loadAllDomainesOffline'),
      dispatch('loadAllCategoriesOffline'),
    ]);
  },

  fetchAllTypes({ commit }, data) {
    return api.refEquipementTypes.getAllByMission(data.missionId)
      .then((response) => {
        commit('addTypes', { backendTypes: response, missionId: data.missionId, majRef: data.majRef });
      });
  },

  /**
   * Load all types stored offline in pouchDB
   */
  loadAllTypesOffline({ commit }) {
    return api.refEquipementTypes.loadOffline()
      .then(response => commit('addTypes', { backendTypes: response, missionId: null, majRef: null }));
  },

  /* fetchAllDomaines({ commit }, missionId) {
    return api.refEquipementDomaines.getAllByMission(missionId)
      .then(response => commit('addDomaines', response));
  }, */

  fetchAllDomaines({ commit, dispatch }, data) {
    return api.refEquipementDomaines.getAllByMission(data.missionId)
      .then(async (response) => {
        const activeDomaines = [];
        await dispatch('fetchAllTypes', data);
        response.forEach((domaine) => {
          const equipements = state.types
            .filter(equipementType => domaine.categories.find(categorie => categorie
            === equipementType.categorie));
          if (equipements.length > 0) {
            activeDomaines.push(domaine);
          }
        });
        commit('addDomaines', { backendDomaines: response, missionId: data.missionId, majRef: data.majRef });
        commit('addActivesDomaines', { backendActivesDomaines: activeDomaines, missionId: data.missionId, majRef: data.majRef });
      });
  },


  /**
   * Load all domaines stored offline in pouchDB
   */
  loadAllDomainesOffline({ commit }) {
    return api.refEquipementDomaines.loadOffline()
      .then(response => commit('addDomaines', response));
  },

  fetchAllCategories({ commit, dispatch }, data) {
    return api.refEquipementCategories.getAllByMission(data.missionId)
      .then(async (response) => {
        const activeCategorie = [];
        if (state.types.length === 0) {
          await dispatch('fetchAllTypes', data);
        }
        response.forEach((categorie) => {
          const equipements = state.types
            .filter(equipementType => categorie.id
            === equipementType.categorie);
          if (equipements.length > 0) {
            activeCategorie.push(categorie);
          }
        });
        commit('addCategories', { backendCategories: response, missionId: data.missionId, majRef: data.majRef });
        commit('addActivesCategories', { backendActivesCategories: activeCategorie, missionId: data.missionId, majRef: data.majRef });
      });
  },

  /**
   * Load all categories stored offline in pouchDB
   */
  loadAllCategoriesOffline({ commit }) {
    return api.refEquipementCategories.loadOffline()
      .then(response => commit('addCategories', response));
  },
};

// mutations
const mutations = {
  addEquipements(state, newEquipements) {
    newEquipements.forEach((newEquipement) => {
      const stateIndex = state.equipements
        .findIndex(stateEquipement => stateEquipement.id === newEquipement.id);

      if (stateIndex >= 0) {
        state.equipements.splice(stateIndex, 1, newEquipement);
      } else {
        state.equipements.push(newEquipement);
      }
    });
  },

  deleteEquipement(state, equipementId) {
    state.equipements.splice(
      state.equipements.findIndex(equipement => equipement.id === equipementId),
      1,
    );
  },

  clearEquipements(state) {
    state.equipements = [];
  },

  changeSelectedId(state, equipementId) {
    state.selectedId = equipementId;
  },

  addImageToEquipement(state, payload) {
    // get equipement in the store
    const equipement = state.equipements.find(item => item.id === payload.equipId);
    // add the imageId to the equipement 'images' array
    (equipement.images = equipement.images || []).push(payload.imageId);
  },

  addTypes(state, data) {
    if (data.majRef) {
      state.types = state.types.filter(type => type.mission !== data.missionId);
    }
    data.backendTypes.forEach((backendType) => {
      const stateIndex = state.types
        .findIndex(stateType => stateType._id === backendType._id);

      if (stateIndex >= 0) {
        state.types.splice(stateIndex, 1, backendType);
      } else {
        state.types.push(backendType);
      }
    });
  },

  addCategories(state, data) {
    if (data.majRef) {
      state.categories = state.categories.filter(categorie => categorie.mission !== data.missionId);
    }
    data.backendCategories.forEach((backendCategorie) => {
      const stateIndex = state.categories
        .findIndex(stateCategorie => stateCategorie._id === backendCategorie._id);

      if (stateIndex >= 0) {
        state.categories.splice(stateIndex, 1, backendCategorie);
      } else {
        state.categories.push(backendCategorie);
      }
    });
  },

  addActivesCategories(state, data) {
    if (data.majRef) {
      state.activesCategories = state.activesCategories.filter(categorie => categorie.mission
        !== data.missionId);
    }
    data.backendActivesCategories.forEach((backendActivesCategorie) => {
      const stateIndex = state.activesCategories
        .findIndex(stateActivesCategorie => stateActivesCategorie._id
          === backendActivesCategorie._id);

      if (stateIndex >= 0) {
        state.activesCategories.splice(stateIndex, 1, backendActivesCategorie);
      } else {
        state.activesCategories.push(backendActivesCategorie);
      }
    });
  },

  addDomaines(state, data) {
    if (data.majRef) {
      state.domaines = state.domaines.filter(domaine => domaine.mission !== data.missionId);
    }
    data.backendDomaines.forEach((backendDomaine) => {
      const stateIndex = state.domaines
        .findIndex(stateDomaine => stateDomaine._id === backendDomaine._id);

      if (stateIndex >= 0) {
        state.domaines.splice(stateIndex, 1, backendDomaine);
      } else {
        state.domaines.push(backendDomaine);
      }
    });
  },

  addActivesDomaines(state, data) {
    if (data.majRef) {
      state.activesDomaines = state.activesDomaines.filter(domaine => domaine.mission
        !== data.missionId);
    }
    data.backendActivesDomaines.forEach((backendActivesDomaine) => {
      const stateIndex = state.activesDomaines
        .findIndex(stateActivesDomaine => stateActivesDomaine._id === backendActivesDomaine._id);

      if (stateIndex >= 0) {
        state.activesDomaines.splice(stateIndex, 1, backendActivesDomaine);
      } else {
        state.activesDomaines.push(backendActivesDomaine);
      }
    });
  },

  setTypes(state, items) {
    state.types = items;
  },

  setCategories(state, items) {
    state.categories = items;
  },

  setActivesCategories(state, items) {
    state.activesCategories = items;
  },

  setDomaines(state, items) {
    state.domaines = items;
  },

  setActivesDomaines(state, items) {
    state.activesDomaines = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
