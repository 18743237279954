<template>
  <div class="labox">
    <ValidationProvider
      :name="formField.name"
      :rules="formField.rules"
      v-slot="{ errors }"
      class="grow2">
      <span @click="insideInput()">
      <v-combobox
        :id="`selectLearning_${id}`"
        :items="entries"
        v-bind="fieldOptions"
        :error-messages="errors"
        :search-input.sync="localValue"
        @change="onChange(localValue)"
        @keyup="onChange(localValue)"
        v-model="localValue"
        ref="selectRef"
        attach
        class="learning-field"
      >
        <template v-slot:label>
          <slot name="field-label"></slot>
        </template>
      </v-combobox>
      </span>
    </ValidationProvider>
    <v-checkbox class="case grow1" v-model="modifyAll" @change="onChange(localValue)"></v-checkbox>
    <div class="grow1">
      <v-tooltip bottom >
        <template v-slot:activator="{ on }">
          <v-icon v-on="on" style="color: #2D4388; margin-top:9px">
            mdi-information-variant-box</v-icon>
        </template>
        <span> Applique le changement sur tout les champs {{ formField.name}} du bâtiment</span>
    </v-tooltip>
    </div>
  </div>

</template>
  //TODO : ajouter le fonctionnement

<script>

import { ValidationProvider } from 'vee-validate';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'select-learning-field',
  components: {
    ValidationProvider,
  },
  props: {
    commonOptions: Object,
    defaultValue: null,
    formField: Object,
  },
  data() {
    return {
      localValue: this.defaultValue,
      entries: [],
      loading: false,
      storeObject: false,
      id: 0,
      menuActive: false,
      modifyAll: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedMission: 'missions/selected',
      selectedBatiment: 'batiments/selected',
    }),
    ...mapState('equipements', ['equipements']),

    fieldOptions() {
      return {
        ...this.commonOptions,
        placeholder: this.formField.placeholder,
        itemText: 'libelle',
        itemValue: 'codeOption',
        returnObject: this.storeObject,
      };
    },
  },
  getActivatedMenu() {
    return this.menuActive;
  },
  async mounted() {
    this.$parent.$parent
      .$parent.$parent.$parent.$el.addEventListener('change', () => {
        if (this.$refs.selectRef && this.$refs.selectRef.isMenuActive && this.$refs
          .selectRef.selectedValues.length > 0) {
          this.$refs.selectRef.isMenuActive = false;
          this.menuActive = false;
        }
        if (this.$refs.selectRef) {
          if (this.menuActive) {
            this.$refs.selectRef.isMenuActive = true;
          } else {
            this.$refs.selectRef.isMenuActive = false;
          }
        }
      });
  },
  watch: {
    defaultValue(newValue) {
      this.localValue = newValue;
    },
  },
  created() {
    this.populateEntries();
    this.id = Math.random();
  },
  methods: {
    onChange(localValue) {
      this.$emit('change', { localValue, modifyAll: this.modifyAll });
    },
    insideInput() {
      this.menuActive = !this.menuActive;
    },
    // retourne la liste des valeurs de l'attribut pour le batiment séléctioné
    getUserAttr() {
      if (this.formField.name === 'local') {
        // get the list of niveaux of the selected batiment
        // and map the local
        const batimentLocaux = this.equipements
          .filter(equipement => equipement.batiment === this.selectedBatiment.id)
          .map(equipement => equipement.local);
        // return the deduplicated list of locaux
        if (batimentLocaux.length) {
          return batimentLocaux.filter(
            (local, index) => (
              batimentLocaux.indexOf(local) === index
              && local !== undefined
              && local !== ''
              && local !== null
            ),
          );
        }
        return batimentLocaux;
      }
      const userAttrRaw = this.equipements
        .filter(equipement => equipement.batiment === this.selectedBatiment.id)
        .map(equipement => equipement.attributs[this.formField.name]);
      if (userAttrRaw.length) {
        const userAttr = userAttrRaw.filter(
          (attribut, index) => (
            userAttrRaw.indexOf(attribut) === index
                && attribut !== null
                && attribut !== undefined
                && attribut !== ''
          ),
        );
        return userAttr;
      }
      return [];
    },
    // remplis la liste de l'attribut (attribut de l'utilisateur + options du referentiel)
    populateEntries() {
      this.entries = this.getUserAttr().concat(this.formField.options);
    },
  },
};
</script>

<style lang="scss">

.labox {
display: flex;
flex-direction: row;
justify-content: flex-start;

}

.case {
  margin-top: 5px;
}

.learning-field {

}

.grow1 {
  flex: 0;
}
.grow2 {
  flex: 1;
  margin-right: 1em;
}
</style>
