/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';

import {
  equipementsEndpoint,
  niveauxEndpoint,
  batimentsEndpoint,
  equipementsUrl,
} from './apiUrls';

import interventionsApi from './interventionsApi';
import picturesApi from './picturesApi';
import equipementLieApi from './equipementLieApi';

export default {
  db: new PouchDB('equipements'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch
    const _id = obj._id || obj.id.toString(); /* eslint-disable-line no-underscore-dangle */
    const meta = {};

    return {
      _id,
      meta,
      id: String(obj.id),
      nom: obj.nom,
      description: obj.description,
      local: obj.local,
      niveau: obj.niveau ? obj.niveau.replace(`${niveauxEndpoint}/`, '') : null,
      batiment: obj.batiment.replace(`${batimentsEndpoint}/`, ''),
      performance: obj.performance,
      vetuste: obj.vetuste,
      attributs: obj.attributs,
      type: obj.type,
      images: obj.images,
      linkedEquipements: obj.linkedEquipements,
      imagePrincipale: obj.imagePrincipale,
      libelleType: obj.libelleType,
      libelleCategorie: obj.libelleCategorie,
      libelleDomaine: obj.libelleDomaine,
      calculations: obj.calculations,
      referentielValues: obj.referentielValues,
      inAlert: obj.inAlert,
    };
  },

  async create(equipement, equipementId) {
    // on créé un objet uniquement pour la sauvegarde locale si pas de connexion
    const localEquipement = {
      id: equipementId,
      ...equipement,
    };
    const equipementOffLine = {
      id: equipementId,
      nom: equipement.nom,
      linkedEquipements: equipement.linkedEquipements,
    };

    const equipementToSave = { ...equipement };
    delete equipementToSave.id;
    delete equipementToSave._id;
    delete equipementToSave._rev;
    delete equipementToSave.meta;
    delete equipementToSave.images;

    // si l'objet est dépendant d'un objet supérieur pas encore créé
    // alors on ne tente même pas une sauvegarde serveur
    // et on le sauvegarde directement sur PouchDB
    if (equipement.batiment.startsWith('temp')) {
      await equipementLieApi.create(equipementOffLine, equipementId);
      return pouchUtil.addDoc(localEquipement, this.db, { toCreate: true });
    }

    let savedEquipement = null;

    try {
      // on essaye de sauvegarder via l'API
      const response = await axiosInstance.axiosInstance
        .post(equipementsEndpoint, equipementToSave);
      savedEquipement = this.convertBackendObject(response.data);
    } catch (e) {
      if (e.response
        && e.response.status >= 400
        && e.response.status < 500) {
        throw new Error(e);
      }
      // gérer la creaction des liaision lié a u batiment hors ligne
      await equipementLieApi.create(equipementOffLine, equipementId);
      // si la sauvegarde en ligne a échouée, on enregistre les données dans
      // le cache avec le flag 'toCreate' à true afin de relancer la synchro
      // quand on aura à nouveau une connexion
      return pouchUtil.addDoc(localEquipement, this.db, { toCreate: true });
    }

    // si la sauvegarde en ligne a fonctionné
    // si on avait un equipement avec un id temporaire dans pouchDB on le supprime
    if (equipementId.startsWith('temp')) {
      try {
        await pouchUtil.deleteDoc(equipementId, this.db);
      } catch (e) {
        // do nothing
      }
    }

    // update intervention & childrens with the new id
    await this.updateIntervention(equipementId, savedEquipement.id);
    await this.updateChildrens(equipementId, savedEquipement.id);

    // enfin on sauvegarde dans pouchDB le nouvel equipement et on le retourne
    return pouchUtil.addDoc(savedEquipement, this.db);
  },

  async update(equipement, equipementId) {
    let savedEquipement = null;
    const equipementToSave = { ...equipement };
    const equipementOffLine = {
      id: equipementId,
      nom: equipement.nom,
      linkedEquipements: equipement.linkedEquipements,
    };

    delete equipementToSave.images;

    try {
      // on essaye de sauvegarder via l'API
      const response = await axiosInstance.axiosInstance.put(`${equipementsEndpoint}/${equipementId}`, equipementToSave);
      savedEquipement = this.convertBackendObject(response.data);
    } catch (e) {
      if (e.response
        && e.response.status >= 400
        && e.response.status < 500) {
        throw new Error(e);
      }
      await equipementLieApi.update(equipementOffLine, equipementId);
      // si la sauvegarde en ligne a échouée, on enregistre les données dans
      // le cache avec le flag 'toUpdate' à true afin de relancer la synchro
      // quand on aura à nouveau une connexion
      return pouchUtil.addDoc({ id: equipementId, ...equipement }, this.db, { toUpdate: true });
    }

    // si la sauvegarde en ligne à reussie, on sauvegarde dans pouchDB et on retourne le resultat
    return pouchUtil.addDoc(savedEquipement, this.db, { updated: true });
  },

  /**
   * synchronise a equipement with the server, creating or updating it
   */
  async synchronise(equipement) {
    // creation
    if (equipement.meta.toCreate) {
      if (equipement.batiment.startsWith('temp')) {
        const pouchEquipement = await pouchUtil.addDoc(equipement, this.db, {
          toCreate: true,
          error: true,
          errorMessage: 'Bâtiment parent non synchronisé',
        });
        return { savedItem: pouchEquipement };
      }

      try {
        // make a copy of equipement with a removed id field for the POST request
        const equipementToCreate = { ...equipement };
        delete equipementToCreate.id;
        delete equipementToCreate.images;
        delete equipementToCreate.linkedEquipements;


        // create equipement on the server
        const response = await axiosInstance.axiosInstance
          .post(equipementsEndpoint, equipementToCreate);
        const savedEquipement = this.convertBackendObject(response.data);
        // delete temp equipement in pouch
        if (equipement._id.startsWith('temp')) {
          try {
            await pouchUtil.deleteDoc(equipement._id, this.db);
          } catch (e) {
            // do nothing
          }
        }

        // update intervention and childrens with the new id
        await this.updateIntervention(equipement._id, savedEquipement.id);
        await this.updateChildrens(equipement._id, savedEquipement.id);
        // update equipements liés and childrens with the new id
        await this.updateEquipementsLies(equipement._id, savedEquipement.id);

        // save equipement in pouch and return it
        const pouchEquipement = await pouchUtil.addDoc(savedEquipement, this.db);
        return {
          oldId: equipement._id,
          savedItem: pouchEquipement,
        };
      } catch (e) {
        // if create fail and functionnal error -> save on pouch with error
        if (e.response
          && e.response.status >= 400
          && e.response.status < 500) {
          const pouchEquipement = await pouchUtil.addDoc(equipement, this.db, {
            toCreate: true,
            error: true,
            errorMessage: e.response.data.detail,
          });
          return { savedItem: pouchEquipement };
        }
        // if it's not a functionnal error, stop synchro and throw error
        throw new Error(e);
      }
    }

    // update
    if (equipement.meta.toUpdate) {
      try {
        const equipementToUpdate = { ...equipement };
        delete equipementToUpdate.images;
        delete equipementToUpdate.linkedEquipements;

        // update equipement on the server
        const response = await axiosInstance.axiosInstance.put(`${equipementsEndpoint}/${equipement.id}`, equipementToUpdate);
        const savedEquipement = this.convertBackendObject(response.data);
        // update equipements liés and childrens with the new id
        await this.updateEquipementsLies(equipement._id, savedEquipement.id);


        // save equipement in pouch and return it
        const pouchEquipement = await pouchUtil.addDoc(savedEquipement, this.db, { updated: true });
        return { savedItem: pouchEquipement };
      } catch (e) {
        // if update fail and functionnal error -> save on pouch with error
        if (e.response
          && e.response.status >= 400
          && e.response.status < 500) {
          const pouchEquipement = await pouchUtil.addDoc(equipement, this.db, {
            toUpdate: true,
            error: true,
            errorMessage: e.response.data.detail,
          });
          return { savedItem: pouchEquipement };
        }
        // if it's not a functionnal error, stop synchro and throw error
        throw new Error(e);
      }
    }

    throw new Error('no update/create flag on this equipement');
  },

  /**
   * synchronise with the server all equipement that need to be saved
   */
  async synchroniseAll() {
    // get all equipements in PouchDB with meta.create === true or meta.update === true
    const equipementsToSave = await pouchUtil.getAllDocsToSync(this.db);
    return Promise.all(equipementsToSave.map(equipement => this.synchronise(equipement)));
  },

  /**
   * When a new batiment is created, if this batiment has unsaved equipements children
   * update those equipements to replace the tempBatimentId wit the new id
   */
  async handleParentCreated(tempBatimentId, newBatimentId) {
    // get all the equipements that are in the batiment
    const childrens = await pouchUtil.getAllDocsByField('batiment', tempBatimentId, this.db);

    if (childrens.length) {
      // update the children with the new site id
      const updatedChildrens = childrens.map(children => ({
        ...children,
        batiment: newBatimentId,
      }));
      // save them back on PouchDB
      await this.db.bulkDocs(updatedChildrens);
    }
  },

  async handleNiveauCreated(tempNiveauId, newNiveauId) {
    // get all the equipements that are in the niveau
    const childrens = await pouchUtil.getAllDocsByField('niveau', tempNiveauId, this.db);

    if (childrens.length) {
      // update the children with the new site id
      const updatedChildrens = childrens.map(children => ({
        ...children,
        niveau: newNiveauId,
      }));

      // save them back on PouchDB
      await this.db.bulkDocs(updatedChildrens);
    }
  },

  async updateIntervention(tempEquipementId, newEquipementId) {
    await interventionsApi.handleLinkCreated(tempEquipementId, newEquipementId, 'equipement');
  },
  async updateEquipementsLies(tempEquipementId, newEquipementId) {
    await equipementLieApi.handleLinkCreated(tempEquipementId, newEquipementId);
    await equipementLieApi.handleLinkChildrenCreated(tempEquipementId, newEquipementId);
  },
  async updateChildrens(tempEquipementId, newEquipementId) {
    await picturesApi.handleParentCreated(tempEquipementId, newEquipementId);
  },

  /**
   * delete a equipement from pouchDB and the server
   */
  async delete(equipementId) {
    // delete the equipement from pouchDB
    await pouchUtil.deleteDoc(equipementId, this.db);
    // delete any picture not yet syncronized
    await picturesApi.handleParentDeleted(equipementId);

    if (!equipementId.startsWith('temp')) {
      // if the delete from pouchDB is sucessfull, delete from the server
      return axiosInstance.axiosInstance.delete(`${equipementsEndpoint}/${equipementId}`);
    }

    return true;
  },

  /**
   * get one equipement by his id
   * @param {String} equipementId - the id of the equipement to get
   */
  async get(equipementId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${equipementsEndpoint}/${equipementId}`);
      const equipement = this.convertBackendObject(response.data);

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDoc(equipement, this.db);
    } catch (e) {
      console.error('equipementsApi get error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getDoc(equipementId, this.db);
    }
  },

  /**
   * get all equipements of the application
   */
  async getAll() {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${equipementsEndpoint}`);
      const equipements = response.data.map(equipement => this.convertBackendObject(equipement));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(equipements, this.db);
    } catch (e) {
      console.error('equipementsApi getAll error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocs(this.db);
    }
  },

  /**
   * get all children equipements of a batiment
   * @param {String} batimentId - id of the parent batiment
   */
  async getAllByBatiment(batimentId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${batimentsEndpoint}/${batimentId}${equipementsUrl}`);
      const equipements = response.data.map(equipement => this.convertBackendObject(equipement));
      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(equipements, this.db);
    } catch (e) {
      console.error('equipementsApi getAllByBatiment error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByField('batiment', batimentId, this.db);
    }
  },

  /**
   * get all children equipements of a batiment for offline use
   * @param {String} batimentId - id of the parent batiment
   */
  async fetchOffline(batimentId) {
    const response = await axiosInstance.axiosInstance.get(`${batimentsEndpoint}/${batimentId}${equipementsUrl}`);
    const equipements = response.data.map(equipement => this.convertBackendObject(equipement));
    // if get is successful,  store data into pouchdb and return it
    return pouchUtil.addDocs(equipements, this.db);
  },

  /**
   * Fetch all equipements stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocsToSync(this.db);
  },
};
