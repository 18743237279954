<template>
  <v-list v-if="sites && sites.length">
    <template
      v-for="(site, i) in sites"
    >
      <v-list-item
        ripple
        :key="`site${i}`"
        @click.native="$emit('site-clicked', site)"
      >
      <div class="div-alert">
        <v-icon v-if="loopAlert(site.inAlert)" class="icon-warning">
          mdi-alert-circle
        </v-icon>
      </div>
        <v-list-item-content>
          <v-list-item-title class="pointer">
              {{ site.nom }} - {{ site.codeSite }}</v-list-item-title>
          <v-list-item-subtitle class="pointer">
             {{ site.ville }} - {{ site.codePostal }}</v-list-item-subtitle>
          <v-list-item-subtitle  class="pointer">
            {{ site.adresse }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-icon
          v-if="isOffline(site.id)"
          class="offline-icon"
        >
          mdi-cloud-off-outline
        </v-icon>

        <v-list-item-action>
          <v-list-item-action-text>{{ site.batiments.length}} Bâtiments</v-list-item-action-text>
        </v-list-item-action>
      </v-list-item>

      <v-divider
        v-if="i + 1 < sites.length"
        :key="`dvd_${i}`"
        class="mission-list-divider"
      ></v-divider>
    </template>
  </v-list>
</template>

<script>
import { mapState } from 'vuex';

import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';

export default {
  props: ['sites'],
  mixins: [
    UpdateItemAlertMixin,
  ],
  computed: {
    ...mapState('offlineSiteList', { offlineSites: 'all' }),
  },

  methods: {
    isOffline(siteId) {
      return this.offlineSites.findIndex(site => site.id === siteId) >= 0;
    },
  },
};
</script>

<style lang="scss" scoped>
  .mission-list-divider {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .pointer{
   cursor: pointer;
  }
  .div-alert{
  width:2em;
 // display: flex;
}

.icon-warning{
  color:orange;
}
</style>
