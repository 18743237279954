<template>
  <v-list>
    <template v-for="(letter) in alphabet">
      <template v-if="missionsByLetter(letter).length">

        <v-divider class="mission-divider" :key="`div_${letter}`" inset></v-divider>

        <v-subheader class="mission-subheader" :key="`sub_${letter}`" inset>
          {{ letter.toUpperCase() }}
        </v-subheader>

        <v-list-item
          v-for="(mission) in missionsByLetter(letter)"
          :key="`${mission.id}`"
          @click="$emit('mission-clicked', mission)"
        >
          <v-list-item-content>
            <v-list-item-title class="mission-name">{{ mission.nom }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action-text>
            <v-icon
              v-if="isOffline(mission.id)"
              class="offline-icon"
            >
              mdi-cloud-off-outline
            </v-icon>
            {{ mission.nbSites }} site(s)
          </v-list-item-action-text>
        </v-list-item>

      </template>
    </template>
  </v-list>
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: ['missions'],

  data() {
    return {
      alphabet: Array.from('aàbcçdeéèêfghijklmnopqrstuvwxyz0123456789"!&_/-@%.?,*#():;'),
    };
  },

  computed: {
    ...mapState('offlineMissionList', { offlineMissions: 'all' }),

    sortedMissions() {
      return [...this.missions].sort((a, b) => a.nom.localeCompare(b.nom, a - b, 'fr', { ignorePunctuation: true }));
    },
  },

  created() {
    this.$store.dispatch('offlineMissionList/loadOffline');
  },

  methods: {
    /* getMissionSitesNumber(mission) {
      if (mission.sites) {
        return mission.sites.length;
      }
      return 0;
    }, */

    missionsByLetter(letter) {
      return this.sortedMissions.filter(mission => mission.nom.toLowerCase().startsWith(letter));
    },

    isOffline(missionId) {
      return this.offlineMissions.findIndex(mission => mission.id === missionId) >= 0;
    },
  },
};
</script>

<style lang="scss" scoped>
  .mission-divider {
    margin-left: 0 !important;
    padding: 0 !important;
    max-width: 100% !important;
  }

  .mission-subheader {
    margin-left: 0 !important;
    padding: 0 !important;
  }

  .offline-icon {
    margin-right: 1rem;
  }
</style>
