<template>
  <v-card :loading="this.loading">
    <v-card-title>
      {{ mission.nom }}
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-subtitle>client : {{ nomClient }}</v-card-subtitle>

    <div class="mission-detail">
      <p class="types-audit-title">Types d'audit :</p>
        <div class="types-audits-group">
          <v-chip
            class="type-audit-chip"
            v-for="type in missionTypesAudits"
            :key="type.id"
          >
            {{type.nom}}
          </v-chip>
        </div>
    </div>

    <v-card-actions class="missions-actions">
      <div class="menu-missions">
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <div class="action-button-wrapper">
              <v-btn
                v-bind="attrs"
                v-on="on"
                outlined fab small color="primary"
                :loading="importing"
                >
                <v-icon>mdi-cloud-upload </v-icon>
              </v-btn>
              <div class="button-title">Importer</div>
            </div>
          </template>
          <v-list>
            <v-list-item
              @click.native="triggerUpload('site-batiment')">
              <v-list-item-title>Sites et bâtiments</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click.native="triggerUpload('inventaire')">
              <v-list-item-title>Inventaires</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click.native="triggerUpload('actions')">
              <v-list-item-title>Action(s)</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu>
          <template v-slot:activator="{ on, attrs }">
            <div class="action-button-wrapper">
              <v-btn
                v-bind="attrs"
                v-on="on"
                outlined fab small color="primary"
                :loading="importing"
                >
                <v-icon>mdi-cog </v-icon>
              </v-btn>
              <div class="button-title">Paramètres</div>
            </div>
          </template>
          <v-list>
            <v-list-item
              @click.native="goToReport('site')">
              <v-list-item-title>Rapport site</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click.native="goToReport('batiment')">
              <v-list-item-title>Rapport batiment</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click.native="goToGestionInspection()">
              <v-list-item-title>Gestion Inspection</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click.native="goToGestionListParam()">
              <v-list-item-title>Gestion listes Paramètres</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click.native="goToGestionModele()">
              <v-list-item-title>Gestion Modèle</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      <input
        type="file"
        ref="fileInput"
        style="display:none"
        @change="handleFileChange($event)"
      >
      <div class="side-actions">
        <v-btn
          color="primary"
          class="white--text"
          @click="$emit('show-edit-item')"
        >
          Editer
          <v-icon right>
            mdi-circle-edit-outline
          </v-icon>
        </v-btn>
        <v-btn
          color="error"
          class="white--text"
          @click="showDeleteDialog()"
        >
          supprimer
          <v-icon right>
            mdi-delete
          </v-icon>
        </v-btn>
      </div>
      <v-dialog
        v-model="show"
        width="500"
      >
    <v-card>
      <v-card-title class="headline">Votre fichier est en cours d'importation</v-card-title>

      <v-card-text>
        Votre fichier est en cours d'importation, ceci peut prendre plusieurs minutes.
        Vous pouvez continuer d'utiliser SMART DIAG en parallèle.
        Un fichier de log sera automatiquement téléchargé à la fin de
        l'importation pour vous informer si tout s'est bien déroulé.
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          depressed
          @click="show = false"
        >
          ok
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
    </v-card-actions>

    <DialogDeleteConfirmation
      :item="mission"
      :dialog="deleteDialog"
      @delete-item="deleteMission()"
      @close-dialog="closeDeleteDialog()"
    >
    </DialogDeleteConfirmation>
  </v-card>
</template>

<script>
import axiosInstance from '../../plugins/axios';
import DialogDeleteConfirmation from '../DialogDeleteConfirmation.vue';


export default {
  components: {
    DialogDeleteConfirmation,
  },
  props: ['mission', 'nomClient', 'loading'],
  data() {
    return {
      deleteDialog: false,
      importing: false,
      importItem: null,
      show: false,
    };
  },
  computed: {
    missionTypesAudits() {
      return this.$store.state.missions.typesAudits
        .filter(audit => this.mission.typesAudits.includes(audit.id));
    },
  },

  methods: {
    showDeleteDialog() {
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
    },

    deleteMission() {
      this.$store.dispatch('missions/delete', this.mission).then(() => {
        this.$router.back();
        this.$store.dispatch('snackbar/displaySnack', { message: 'Mission supprimée avec succès', type: 'succes' });
      });
    },

    triggerUpload(importItem) {
      this.importItem = importItem;
      this.$refs.fileInput.click();
    },

    goToReport(item) {
      this.$router.push({ path: `/mission/${this.mission.id}/${item}/rapport` });
    },

    goToGestionInspection() {
      this.$router.push({ path: `/mission/${this.mission.id}/inspections` });
    },

    goToGestionListParam() {
      this.$router.push({ path: `/mission/${this.mission.id}/listeParam` });
    },

    goToGestionModele() {
      this.$router.push({ path: `/mission/${this.mission.id}/gestionModele` });
    },

    handleFileChange(event) {
      this.show = true;
      if (typeof event.target.files[0] !== 'undefined') { //  fix api calls if file is not selected
        let apiUrl = null;
        let logFileName = null;
        let asynchrone = true;
        this.importing = true;
        switch (this.importItem) {
          case 'site-batiment':
            asynchrone = true;
            apiUrl = `/api/missions/${this.mission.id}/import/siteBatiment`;
            logFileName = 'Logs_Intégration_Site_Bâtiment';
            break;
          case 'inventaire':
            asynchrone = true;
            apiUrl = `/api/missions/${this.mission.id}/import/inventaire`;
            logFileName = 'Logs_Intégration_inventaire';
            break;
          case 'rapport-site':
            asynchrone = false;
            apiUrl = `/api/missions/${this.mission.id}/Site/import-structureRapport`;
            logFileName = 'Logs_Intégration_Structure_Rapport_Site';
            break;
          case 'rapport-batiment':
            asynchrone = false;
            apiUrl = `/api/missions/${this.mission.id}/Batiment/import-structureRapport`;
            logFileName = 'Logs_Intégration_Structure_Rapport_Bâtiment';
            break;
          case 'actions':
            asynchrone = true;
            apiUrl = `/api/missions/${this.mission.id}/import/action`;
            logFileName = 'Logs_Intégration_Actions';
            break;
          default:
            break;
        }

        // create a formData with the file selected
        const formData = new FormData();
        formData.append('file', event.target.files[0]);
        if (asynchrone) {
          axiosInstance.axiosInstance.post(apiUrl, formData).then(async (response) => {
            let code = {
              data: {
                exitCode: -1,
              },
            };

            const callExecJobs = () => { //  create a loop function
              setTimeout(async () => { //  call a 30s setTimeout when the loop is called
                code = await axiosInstance.axiosInstance.get(`/api/exec-jobs/${response.data.id}`);
                if (code.data.exitCode === null || code.data.exitCode === -1) {
                  callExecJobs(); //  ..  again which will trigger another
                } else {
                  if (code.data.exitCode === 0) {
                    axiosInstance.axiosInstance.get(`/api/missions/import/log/${response.data.id}`, { responseType: 'blob' }).then((logResponse) => {
                      const blob = new Blob([logResponse.data], { type: 'text/plain' });
                      const link = document.createElement('a');
                      const url = window.URL.createObjectURL(blob);

                      // create a file with the report and download it
                      link.href = url;
                      link.setAttribute('download', `${logFileName + new Date().getDate()}.txt`);
                      document.body.appendChild(link);
                      link.click();
                    });
                  } else {
                    this.$store.dispatch('snackbar/displaySnack', { message: 'Erreur lors du téléchargement de votre rapport!', type: 'error' });
                  }
                  this.isDownload = false;
                  this.importing = false;
                }
              }, 30000);
            };
            callExecJobs(); //  start the loop
          });
        } else {
        // call the import api with the formData
        // think to change the path/api 'url' for <import-site-batiment> when the back-task is donne
          axiosInstance.axiosInstance.post(apiUrl, formData)
            .then((responseImport) => {
              const blob = new Blob([responseImport.data], { type: 'text/plain' });
              const link = document.createElement('a');
              const url = window.URL.createObjectURL(blob);

              // create a file with the report and download it
              link.href = url;
              link.setAttribute('download', `${logFileName + new Date().getDate()}.txt`);
              document.body.appendChild(link);
              link.click();

              if (responseImport.data.match(/Les données ont été correctement intégrées.*/)) {
                this.$store.dispatch('sites/fetchAllByMission', this.mission.id);
                this.$store.dispatch('missions/refreshItem', this.mission.id);
                this.$store.dispatch('snackbar/displaySnack',
                  { message: 'Données importées avec succès !', type: 'succes' });
              } else {
                this.$store.dispatch('snackbar/displaySnack', {
                  message: 'Echec de l\'importation des données, veuillez consulter le fichiers de logs.',
                  type: 'error',
                });
              }
            })
            .catch((err) => {
              let errorMessage = 'Echec de l\'importation des données.';
              if (err.response && err.response.status === 403) {
                errorMessage = 'Vous n\'avez pas les droits pour cette action';
              }
              this.$store.dispatch('snackbar/displaySnack',
                {
                  message: errorMessage,
                  type: 'error',
                });
            })
            .finally(() => {
              this.importing = false;
              this.$refs.fileInput.value = '';
            });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .menu-missions{
    display: flex;
    flex-flow: row nowrap;
  }
  .missions-actions{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
  }
  .mission-detail {
    padding: 16px; // to match vuetify card padding
  }

  .types-audit-title {
    font-size: 1rem;
    font-weight: 500;
  }

  .types-audits-group {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: flex-start;

    .type-audit-chip {
    margin: 0 0.5rem 0.5rem 0;
    }
  }

  .pushed-right {
    margin-left: auto !important;
    margin-right: 0;
  }

  .action-button-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  .button-title {
    margin: 0.2rem;
    color: #2D4388;
  }

  .side-actions {
    align-self: stretch;
    padding: 0.2rem;

    display: flex;
    flex-flow: column;
    justify-content: flex-end;

    button {
      width: 150px;
      margin: 0.2rem 0 0.2rem 0 !important;
    }
  }
</style>
