<template>
  <ValidationProvider
    :name="formField.name"
    :rules="formField.rules"
    v-slot="{ errors }"
  >

    <v-combobox
      :items="getSelectOptions()"
      v-bind="fieldOptions"
      :error-messages="errors"
      v-model="localValue"
      @change="$emit('change', localValue)"
    >

      <template v-slot:label>
        <slot name="field-label"></slot>
      </template>
    </v-combobox>

  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'combobox-field',
  components: {
    ValidationProvider,
  },
  props: {
    commonOptions: Object,
    defaultValue: null,
    formField: Object,
  },
  data() {
    return {
      localValue: this.defaultValue,
    };
  },
  computed: {
    ...mapGetters({
      selectedBatiment: 'batiments/selected',
    }),
    ...mapState('niveaux', ['niveaux']),
    ...mapState('equipements', ['equipements']),

    fieldOptions() {
      return {
        ...this.commonOptions,
        placeholder: this.formField.placeholder,
      };
    },
  },
  watch: {
    defaultValue(newValue) {
      this.localValue = newValue;
    },
  },
  methods: {
    // return the array of possible values for the select
    getSelectOptions() {
      if (Array.isArray(this.formField.options)) {
        return this.formField.options;
      }
      if (typeof this.formField.options === 'string') {
        return this.getCustomOptions(this.formField.options);
      }

      this.$store.dispatch('snackbar/displaySnack', {
        message: `impossible de récupérer le référentiel du champ ${this.formField.label}`,
        type: 'error',
      });
      return [''];
    },

    // handle custom options code that represent complex dynamic request
    getCustomOptions(code) {
      if (code === 'batimentNiveaux') {
        // return the list of niveaux of the selected batiment
        return this.niveaux.filter(niveau => niveau.batiment === this.selectedBatiment.id);
      }
      if (code === 'batimentLocaux') {
        // get the list of niveaux of the selected batiment
        // and map the local
        const batimentLocaux = this.equipements
          .filter(equipement => equipement.batiment === this.selectedBatiment.id)
          .map(equipement => equipement.local);
        // return the deduplicated list of locaux
        if (batimentLocaux.length) {
          return batimentLocaux.filter(
            (local, index) => (
              batimentLocaux.indexOf(local) === index
              && local !== undefined
              && local !== ''
              && local !== null
            ),
          );
        }
      }
      return [''];
    },
  },
};
</script>
