<template>
  <BaseCard class="toolbar-card">
    <template v-slot:title>
      Compteurs
    </template>
    <template v-slot:content>
      <v-data-table
        class="compteurs-table"
        v-model="selectedCompteurs"
        hide-default-footer
        disable-pagination
        disable-filtering
        disable-sort
        single-select
        :headers="headers"
        :items="compteurs"
        item-key="id"
        :loading="loading"
        loading-text="Récupération des compteurs..."
        no-data-text="Aucun compteur affecté à ce site"
        @click:row="selectCompteur($event)"
      >
        <template
        v-slot:[`item.nomCompteur`]="{ item }">
          <div class="name-num-compt">
            <div class="name-compteur">
              <div class="icons">
                <v-icon v-if="item.inAlert" class="icon-alert">
                  mdi-alpha-q-circle</v-icon>
                <v-icon v-if="test(item)" class="icon-alert">
                  mdi-alpha-v-circle</v-icon>
              </div>
              <div class="nom">{{item.nomCompteur}}</div>
            </div>
            <div class="subnum">{{item.numeroCompteur}}</div>
          </div>
        </template>

        <template
          v-slot:[`item.energieLibelle`]="{ item }">
          <div v-if="item.energieLibelle">
            <v-chip :color="getColor(item.energie)">{{item.energieLibelle}}</v-chip></div>
        </template>

        <template v-slot:footer>
          <div class="compteurs-table-footer">
            <v-btn
              class="compteurs-table-footer-btn pushed"
              depressed
              small
              :disabled="!isCompteurSelected"
              @click.prevent='disaffectCompteur(selectedCompteurs[0])'

            >
              Désaffecter
            </v-btn>
            <v-btn
              class="compteurs-table-footer-btn"
              depressed
              small
              :disabled="!isCompteurSelected"
              @click.prevent="$emit('qualify', selectedCompteurs[0])"

            >
              Qualifier
            </v-btn>
            <v-btn
              class="compteurs-table-footer-btn"
              depressed
              small
              :disabled="!isCompteurQualified"
              @click="$emit('allocate', selectedCompteurs[0])"
            >
              Ventiler
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </template>
  </BaseCard>
</template>

<script>
import { mapGetters } from 'vuex';
import ColorEnergieMixing from '@/mixins/ColorEnergieMixing';
import BaseCard from '../BaseCard.vue';


export default {
  components: {
    BaseCard,
  },
  mixins: [
    ColorEnergieMixing,
  ],

  props: ['featuredItem', 'loading', 'compteurs'],
  data() {
    return {
      selectedCompteurs: [],
      headers: [
        {
          text: 'Compteur',
          align: 'end',
          sortable: false,
          value: 'nomCompteur',
        },
        { text: 'Energie', value: 'energieLibelle', align: 'end' },
        { text: 'Consommations (kWh)', value: 'consoAnneeRefDisplay', align: 'end' },
        { text: 'Coût (€TTC)', value: 'coutAnneeRefDisplay', align: 'end' },
        { text: 'PU (c€TTC/kWh)', value: 'puDisplay', align: 'end' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      selectedSite: 'sites/selected',
      batimentsSite: 'batiments/selectedSiteBatiments',
    }),

    isCompteurQualified() {
      let compteurIsQualified = false;
      if (this.selectedCompteurs.length) {
        const currentCompteur = this.selectedCompteurs[0];
        compteurIsQualified = currentCompteur.consoQualif
        || currentCompteur.anneesQualif.length;
      }

      /* Do not ventile if no compteur, if selectedcompteur is not qualified
       and if there is no bat, into selected site */
      return this.selectedCompteurs.length
      && compteurIsQualified
      && this.selectedSite.batiments.length;
    },

    isCompteurSelected() {
      return this.selectedCompteurs.length;
    },
  },
  methods: {
    test(item) {
      let findAlert = true;
      let i = 0;
      while (i < this.batimentsSite.length && findAlert) {
        const commonArray = this.batimentsSite[i].repartitionCompteurBatiments.filter(
          repart => item.repartitionCompteurBatiments.includes(repart),
        );
        if (commonArray.length) {
          findAlert = false;
        }
        i += 1;
      }
      return findAlert;
    },
    selectCompteur(item) {
      // of the new item selected id different form the old one
      if (!this.selectedCompteurs[0]
        || (this.selectedCompteurs[0] && item.id !== this.selectedCompteurs[0].id)) {
        this.selectedCompteurs = [item];
        this.$emit('select-compteur', item);
      }
    },
    disaffectCompteur(compteur) {
      this.$emit('disaffect', compteur);
      this.selectedCompteurs = [];
    },
    getColor(codeEnergie) {
      return this.getColorEnergie(codeEnergie);
    },
  },

  watch: {
    compteurs(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.selectCompteurs) {
          const item = newValue.find(compteur => compteur.id === this.selectedCompteurs[0].id);
          this.selectedCompteurs = [item];
          this.$emit('select-compteur', item);
        }
      }
    },
  },
};

</script>

<style lang="scss" scoped>
  .compteurs-table {
    border: 1px solid rgba(0, 0, 0, .1);
    margin-left: auto;
    margin-right: auto;
  }

  .compteurs-table-footer {
    display: flex;
    flex-flow: row wrap;

    padding: 0.3rem 0 0 0;
  }

  .pushed {
    margin-left: auto;
  }

  .compteurs-table-footer-btn {
    margin-bottom: 0.3rem;
    margin-right: 0.3rem;
  }
  .subnum{
    font-size: 10px;
    font-style: italic;
  }

  .name-compteur{
    display: flex;
    justify-content: space-between;
  }

  .name-num-compt{
    width: 10rem;
  }

  .icon-alert {
    color: orange;
  }

  .icons {
    width: 4em;
    display: flex;
  }

</style>
