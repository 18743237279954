<template>
  <div>
    <v-img
      class="picture-object"
      :src="src"
      :lazy-src="lazySrc"
      @click="openGallery()"
    >
      <!-- spinner loader for the picture -->
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
          :v-if="isLoading"
          :indeterminate="isLoading"
          color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>

      <!-- counter for pictures number -->
      <div
        class="picture-counter"
        v-if="pictureList && pictureList.length > 1"
      >
        <v-icon color="white" x-small>mdi-plus</v-icon>
        {{ pictureList.length - 1 }}
      </div>
    </v-img>

    <v-btn
    v-if="!hasTouchScreen"
      color="primary"
      class="btn-add-pics"
      @click.native="takePicture()"
    >
      Ajouter une photo<v-icon class="button-icon">mdi-camera</v-icon>
    </v-btn>
    <!-- hidden input to upload photo -->
    <input
      ref="inputFile"
      type=file
      accept="image/*"
      style="display: none;"
      multiple="multiple"
      @change="handleFileChange($event);"
    />
    <v-btn
      v-if="!capturing && hasTouchScreen"
      color="primary"
      class="btn-add-pics"
      @click="startCapture"
    >
      Prendre des photos<v-icon class="button-icon">mdi-camera</v-icon>
    </v-btn>
    <v-btn
      color="primary"
      class="btn-add-pics"
      @click="showBottomSheet = true"
      v-if="picturesInArray"
    >
      Gérer <v-icon class="button-icon">mdi-cog</v-icon>
    </v-btn>

    <div class="fullscreen" id="fullscreen">
    <video autoplay
      ref="camera"
      style="width:100%"
      v-show="capturing"
    ></video>
    <v-btn
      v-if="capturing"
      class="btn-take-pic"
      @click="capturePhoto"
    >
      <v-icon large>mdi-camera</v-icon>
    </v-btn>
    <v-btn
      v-if="capturing"
      color="primary"
      class="btn-retour-pic"
      @click="stopCapture"
    >
      Retour <!--<v-icon class="button-icon">mdi-camera-off</v-icon>-->
    </v-btn>
    </div>
    <!-- gallery to display pictures in fullscreen -->
    <Gallery
      :arrayPics="pictureList"
      :selectedPic="url"
      :openGalleryOne="openGallerySheet"
      parentKey="field"
      @closeGalleryOne="closeGallery"
      key="fieldGallery"
    >
    </Gallery>

    <!-- bottomsheet to manage pictures (delete & setPrincipal) -->
    <BottomSheetSettingsPictures
      :sheet="showBottomSheet"
      :defaultValue="pictureList"
      :principalPic="url"
      @close-sheet="showBottomSheet = false"
      @save-edited-pic="handleFileChange($event, true)"
      @remove-pic="removePic($event)"
      @change-favorite-pic="handleChangePrincipalPic($event)"
    >
    </BottomSheetSettingsPictures>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import api from '@/api';
import BottomSheetSettingsPictures from '../BottomSheetSettingsPictures.vue';
import Gallery from '../gallery-pictures.vue';
import ImageResizeMixin from '../../mixins/ImageResizeMixin';

export default {
  name: 'multi-pictures-field',
  components: {
    BottomSheetSettingsPictures,
    Gallery,
  },
  mixins: [
    ImageResizeMixin,
  ],
  props: {
    defaultValue: Array,
    formField: Object,
  },
  data() {
    return {
      showBottomSheet: false,
      openGallerySheet: false,
      pictureList: [],
      isLoading: false,
      capturing: false,
      mediaStream: null,
      hasTouchScreen: false,
    };
  },
  watch: {
    defaultValue(newValue) {
      this.populatePictureArray(newValue);
    },
  },

  computed: {
    ...mapGetters(['selectedItem']),
    ...mapState(['selectedItemType']),

    picturesInArray() {
      return !!this.pictureList && !!this.pictureList.length;
    },

    src() {
      if (this.picturesInArray) {
        return this.pictureList[0].url;
      }
      return '';
    },

    // smaller version of the image to use whille loading 'big' image
    lazySrc() {
      if (this.picturesInArray && !this.pictureList[0].url.startsWith('blob')) {
        return `${this.pictureList[0].url}?size=xsmall`;
      }
      return undefined;
    },

    // url of the principal image
    url() {
      if (this.picturesInArray) {
        return `${this.pictureList[0].url}`;
      }
      return null;
    },
  },

  async created() {
    if (this.defaultValue && this.defaultValue.length) {
      this.populatePictureArray(this.defaultValue);
    } else {
      this.populatePictureArray([]);
    }
    if (!navigator.mediaDevices && !navigator.mediaDevices.getUserMedia) {
      this.hasTouchScreen = false;
    } else if ('maxTouchPoints' in navigator) {
      this.hasTouchScreen = navigator.maxTouchPoints > 0;
    } else if ('msMaxTouchPoints' in navigator) {
      this.hasTouchScreen = navigator.msMaxTouchPoints > 0;
    } else {
      const mQ = matchMedia?.('(pointer:coarse)');
      if (mQ?.media === '(pointer:coarse)') {
        this.hasTouchScreen = !!mQ.matches;
      } else if ('orientation' in window) {
        this.hasTouchScreen = true; // deprecated, but good fallback
      } else {
        // Only as a last resort, fall back to user agent sniffing
        const UA = navigator.userAgent;
        this.hasTouchScreen = /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA)
      || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
      }
    }
  },

  methods: {
    startCapture() {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ audio: false, video: { facingMode: { exact: 'environment' } } })
          .then((stream) => {
            this.mediaStream = stream;
            this.$refs.camera.srcObject = stream;
            // Mettre la vidéo en plein écran par défaut
            this.$nextTick(() => {
              const container = document.getElementById('fullscreen');
              container.requestFullscreen();
              this.capturing = true;
            // this.$refs.camera.requestFullscreen();
            });
          })
          .catch(() => {
            this.$store.dispatch('snackbar/displaySnack',
              {
                message: 'Caméra inaccessible',
                type: 'error',
              });
          });
      } else {
        this.hasTouchScreen = false;
      }
    },
    stopCapture() {
      this.capturing = false;
      if (this.mediaStream) {
        this.mediaStream.getTracks().forEach(track => track.stop());
        this.$refs.camera.srcObject = null;
      }
      // Sortir du mode plein écran
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    },
    async onVideoLoaded() {
      this.$refs.camera.style.display = 'block'; // Rendre l'élément vidéo visible
    },
    async capturePhoto() {
      this.$refs.camera.pause();
      this.$refs.camera.classList.add('highlight-border');
      const canvas = document.createElement('canvas');
      /* canvas.width = this.$refs.camera.videoWidth;
      canvas.height = this.$refs.camera.videoHeight; */
      // const dataURL = canvas.toDataURL('image/jpeg');
      // let img;
      /* canvas.toBlob(async (blob) => {
        const files = new File([blob], 'fileName.jpg', { type: 'image/jpeg' });
        img = await this.resizeImageMx(files, 1200, 1200);
      }, 'image/jpeg');
      console.log('Captured photo:', img); */
      const ratio = Math.min(1200 / this.$refs.camera.videoWidth,
        1200 / this.$refs.camera.videoHeight);
      canvas.width = this.$refs.camera.videoWidth * ratio;
      canvas.height = this.$refs.camera.videoHeight * ratio;
      canvas.getContext('2d').drawImage(this.$refs.camera, 0, 0, canvas.width, canvas.height);
      const image = await new Promise((resolve) => {
        canvas.getContext('2d').canvas.toBlob((blob) => {
          const img2 = new File([blob], 'test', {
            type: 'image/jpeg',
            lastModified: Date.now(),
          });
          resolve(img2);
        }, 'image/jpeg', 1);
      });
      this.handleFileChange(image, true);
      this.$refs.camera.play();
      setTimeout(() => {
        this.$refs.camera.classList.remove('highlight-border');
      }, 0);
    },
    async removePic(idPic) {
      await api.pictures.delete(idPic);
      const removedPic = this.pictureList.findIndex(picture => picture.id === idPic);
      this.pictureList.splice(removedPic, 1);
      this.handleSettingsChange('deleted');
    },
    closeGallery() {
      this.openGallerySheet = false;
    },

    openGallery() {
      if (this.picturesInArray) {
        this.openGallerySheet = true;
      }
    },

    takePicture() {
      this.$refs.inputFile.click();
    },

    async populatePictureArray(pictureIdList) {
      // convert pictures id into proper Url
      const pictureUrlList = pictureIdList.map(picId => ({ id: picId, url: `${picId}/file` }));

      // check on pouchDB to see if there's some local picture to add
      const localPictureList = await api.pictures.getAllByItem(this.selectedItem.id);
      const localPictureUrlList = localPictureList.map(picture => ({
        id: picture.id,
        url: window.URL.createObjectURL(picture.blob),
      }));

      // merge the two arrays into pictureList
      this.pictureList = [...pictureUrlList, ...localPictureUrlList];
    },

    async getLocalPicture(pictureId) {
      const blobFile = await api.pictures.getFile(pictureId);
      return URL.createObjectURL(blobFile);
    },

    async handleSettingsChange(change) {
      const newDefault = this.pictureList
        .map(pic => pic.id)
        .filter(id => !id.startsWith('temp'));
      this.$emit('change', newDefault);
      if (change === 'deleted' && newDefault.length === 0) {
        // no image then no favorite image
        await this.$store.dispatch('updateFormField', { value: null, name: 'imagePrincipale' });
      }
    },

    async handleChangePrincipalPic(newPrincipale) {
      // move the new principal picture at the begining of the array
      const principalIndex = this.pictureList.findIndex(picture => picture.url === newPrincipale);
      const removedPics = this.pictureList.splice(principalIndex, 1);
      this.pictureList.unshift(removedPics[0]);
      // update the form
      await this.$store.dispatch('updateFormField', { value: removedPics[0].id, name: 'imagePrincipale' });
      this.handleSettingsChange('changed');
    },

    async handleFileChange(evt, alreadyFile) {
      this.isLoading = true;
      let resizedImage = null;
      if (alreadyFile) {
        resizedImage = evt;
        this.saveFile(resizedImage);
      } else {
        // convert picture to formData and rezise it
        // const file = evt.target.files[0];
        Array.from(evt.target.files).forEach(async (file) => {
          resizedImage = await this.resizeImageMx(file, 1200, 1200);
          await this.saveFile(resizedImage);
        });
      }
    },

    async saveFile(fileToSave) {
      const savedImage = await api.pictures
        .create(fileToSave, this.selectedItemType, this.selectedItem.id);

      this.$store.dispatch('snackbar/displaySnack',
        {
          message: 'Photo ajoutée avec succès',
          type: 'succes',
        });
      this.isLoading = false;

      // if the save failed
      if (savedImage._id) {
        // get the local picture from pouchDB and udpate the list
        const savedLocalUrl = await this.getLocalPicture(savedImage._id);
        this.pictureList.push({
          id: savedImage._id,
          url: savedLocalUrl,
        });
        this.$store.dispatch('updatePicturesToSync');
      } else {
        // else juste take the contentUrl field from the server response
        this.pictureList.push({
          id: savedImage.contentUrl.slice(0, -5),
          url: savedImage.contentUrl,
        });
      }
    },
  },
};

</script>

<style scoped>
/* ... Autres styles ... */

.shrink-and-hide {
  transform: scale(0);
  opacity: 0;
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}
.highlight-border {
  border: 6px solid rgb(252, 252, 229); /* Couleur de la surbrillance */
  transition: border 0.5s ease-out; /* Transition pour la surbrillance */
}
.picture-object {
  cursor: pointer;
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  border-radius: 5px;
  width: 35rem;
  height: 15rem;
  margin-right: auto;
  margin-left: auto;
}
.btn-add-pics {
  margin: 1rem 0.3rem 1rem 0.3rem;
}
.btn-take-pic {
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;

  background-color: #2D4388 !important;
  color: white;
  height: 100px !important;
  width: 100px !important;
  text-align: center !important;
  border-radius: 50% !important;
  border: 2px solid white !important;
}
.btn-retour-pic {
  position: absolute;
  bottom: 2em;
  right: 2em;
}
.button-icon {
  margin-left:1rem;
}

.picture-counter {
  position: absolute;
  right: 10px;
  top: 10px;
  color: white;
}

/* Masquer la durée de la vidéo et l'icône de sortie de plein écran en plein écran */
video::-webkit-media-controls {
  display: none !important;
}
</style>
