
import ClientsView from '../views/ClientsView.vue';
import ClientDetailView from '../views/ClientDetailView.vue';

export default [
  {
    path: '/clients',
    name: 'clients',
    meta: {
      libelle: 'CLIENTS',
      requiresAuth: true,
    },
    // component: () => import(/* webpackChunkName: "clients" */ '@/views/ClientsView'),
    component: ClientsView,
  },
  {
    path: '/client/:clientId',
    name: 'clientDetail',
    meta: {
      libelle: 'selectedClient',
      requiresAuth: true,
    },
    // component: () => import(/* webpackChunkName: "clients" */ '@/views/ClientDetailView'),
    component: ClientDetailView,
    props: true,
  },
];
