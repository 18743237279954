import api from '@/api';

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
/* eslint no-console: ["error", { allow: ["error", "log"] }] */

// initial state
const state = {
  interventions: [],
  selectedId: null,
};

const getters = {
  toSync: state => state.interventions
    .filter(intervention => intervention.meta
      && (intervention.meta.toCreate || intervention.meta.toUpdate)),

  nbToSync: (state, getters) => {
    if (getters.toSync && getters.toSync.length) {
      return getters.toSync.length;
    }
    return 0;
  },

  selected: state => state.interventions.find(inter => inter.id === state.selectedId),

  selectedBatimentInterventions: (state, getters, rootState, rootGetters) => {
    const batiment = rootGetters['batiments/selected'];
    if (batiment) {
      return state.interventions.filter(inter => inter.batiment === batiment.id);
    }
    return [];
  },
};

// actions
const actions = {
  /**
   * Create a new intervention and add it to the store
   */
  createInterventionOnBatiment({ commit }, intervention) {
    const newIntervention = {
      id: `tempInt${Math.random()}`,
      batiment: intervention.batimentId,
      option: intervention.codeType,
      precisions: intervention.textArea,
      noteInterne: intervention.textArea,
      inAlert: intervention.inAlert,
    };
    if (intervention.itemType === 'equipement') {
      newIntervention.equipement = intervention.itemId;
    } else {
      newIntervention.inspection = intervention.itemId;
    }
    commit('addInterventions', [newIntervention]);
    return { ...newIntervention };
  },

  /**
   * Save a intervention on the server (automatically select POST or PUT) and update the store
   */
  save({ commit }, payload) {
    return new Promise((resolve, reject) => {
      // if the Interventions doesn't exhist on the backend, create it by post
      if (payload.id.startsWith('tempInt')) {
        api.interventions.create(payload.data, payload.id)
          .then((response) => {
            commit('deleteIntervention', payload.id);
            commit('addInterventions', [response]);
            resolve({ ...response });
          })
          .catch((error) => {
            commit('deleteIntervention', payload.id);
            reject(error);
          });
      // else update it by put
      } else {
        api.interventions.update(payload.id, payload.data)
          .then((response) => {
            commit('addInterventions', [response]);
            resolve({ ...response });
          })
          .catch(error => reject(error));
      }
    });
  },

  /**
   * Fetch all Interventions from the server and update the store
   */
  fetchAll({ commit }) {
    return new Promise((resolve) => {
      api.interventions.getAll().then((response) => {
        commit('addInterventions', response);
        resolve();
      });
    });
  },

  /**
   * Fetch all children interventions of a batiment
   * @param {String} batimentId - id of the parent batiment
   */
  fetchAllByBatiment({ commit }, batimentId) {
    return api.interventions.getAllByBatiment(batimentId)
      .then(response => commit('addInterventions', response));
  },

  /**
   * Load all interventions stored offline in pouchDB
   */
  loadOffline({ commit }) {
    return api.interventions.loadOffline().then(response => commit('addInterventions', response));
  },

  /**
   * Fetch one intervention by it's id fron the server and update the store
   */
  refreshItem({ commit }, InterventionId) {
    return new Promise((resolve) => {
      api.interventions.get(InterventionId).then((response) => {
        commit('addInterventions', [response]);
        resolve({ ...response.data });
      });
    });
  },

  /**
   * Delete a intervention from the server & from the store
   */
  async delete({ commit }, intervention) {
    await api.interventions.delete(intervention.id)
      .then(() => {
        commit('deleteIntervention', intervention.id);
      });
  },

  /**
   * delete all children interventions linked to an equipment
   * for offline use (local storage) and from the store
   * @param {equipment} equipement - the equipment
   */
  deleteByEquipementOffline({ commit }, equipementId) {
    //  delete interventions linked to equipment from local storage
    api.interventions.deleteByEquipementOffline(equipementId)
      .then(() => {
        //  delete interventions linked to equipment from the store
        commit('deleteByEquipement', equipementId);
      });
  },

  async changeSelected({
    state, getters, commit, dispatch, rootState,
  },
  interventionId) {
    if (state.selectedId !== interventionId) {
      commit('changeSelectedId', interventionId);

      if (!getters.selected) {
        await dispatch('refreshItem', interventionId);
      }

      if (!rootState.batiments.selected
        || rootState.batiments.selected.id !== getters.selected.batiment) {
        await dispatch('batiments/changeSelected', getters.selected.batiment, { root: true });
      }
    }
  },

  async clearSelected({ commit }) {
    await commit('changeSelectedId', null);
  },
};

// mutations
const mutations = {

  addInterventions(state, newInterventions) {
    newInterventions.forEach((newIntervention) => {
      const stateIndex = state.interventions
        .findIndex(stateIntervention => stateIntervention.id === newIntervention.id);
      if (stateIndex >= 0) {
        state.interventions.splice(stateIndex, 1, newIntervention);
      } else {
        state.interventions.push(newIntervention);
      }
    });
  },

  deleteIntervention(state, InterventionId) {
    state.interventions
      .splice(state.interventions.findIndex(intervention => intervention.id === InterventionId), 1);
  },

  async deleteByEquipement(state, equipementId) {
    state.interventions = state.interventions
      .filter(intervention => intervention.equipement !== equipementId);
  },

  changeSelectedId(state, interventionId) {
    state.selectedId = interventionId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
