import { render, staticRenderFns } from "./SiteGlobalData.vue?vue&type=template&id=687c426d&scoped=true&"
import script from "./SiteGlobalData.vue?vue&type=script&lang=js&"
export * from "./SiteGlobalData.vue?vue&type=script&lang=js&"
import style0 from "./SiteGlobalData.vue?vue&type=style&index=0&id=687c426d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "687c426d",
  null
  
)

export default component.exports