<template>
  <v-bottom-sheet
    v-model="sheet"
    fullscreen
    persistent
  >
    <v-sheet class="sheet-wrapper">
      <h1>Affecter un compteur à un site</h1>
      <template v-if="!showBottomSheetNewCompteurs">
        <div class="contenair-add">
          <v-btn @click="addNewCompteur()" text class="btn-add-historic">
            <v-icon left>mdi-plus-box-outline</v-icon>
            Ajouter un compteur
          </v-btn>
        </div>
        <p>OU</p>
        <p>Selectionner un compteur pour ce site</p>
        <div class="inputSearch">
          <transition appear name="fade">
            <v-text-field
              v-model="search"
              outlined
              rounded
              placeholder="Rechercher un compteur"
              label="Recherche"
              append-icon="mdi-magnify"
              class="missions-search-field"
              color="tertiary"
            >
            </v-text-field>
          </transition>
        </div>

        <div class="searchCounter" v-if="searchCounter >= 1"><h6>
        Voici {{ partialSearchCounter }} des {{searchCounter}} résultats.
        Soyez plus précis pour affiner la recherche.</h6></div>
        <transition appear name="slide-fade-down">
          <v-card
            class="big-card"
            :loading="searching"
          >
            <v-card-subtitle>Compteurs</v-card-subtitle>

              <CompteurList
                :compteurs="items"
                @compteur-clicked="selectCompteurToLink($event)"
              ></CompteurList>

            <p v-if="searching" class="compteurs-card-message">
              <v-skeleton-loader
                v-for="(item, i) in items"
                :key="`skel_${i}`"
                type="list-item"
              >
              </v-skeleton-loader>
            </p>

            <p v-if="selectedSite && items &&
            !items.length && search" class="compteurs-card-message">
              Aucun résultat disponible pour cette recherche.
            </p>

          </v-card>
        </transition>

        <div class="bottom-sheet-footer">
          <v-btn
          text
          color="primary"
          @click="showBottomSheetNewCompteurs=false,$emit('close-sheet')"
          >
            Annuler
          </v-btn>
          <v-btn
            depressed
            color="primary"
            :disabled="selectedCompteur == null"
            @click="linkSiteWithCompteur"
            :loading="saving"
          >
            Valider
          </v-btn>
        </div>
      </template>
      <template v-if="showBottomSheetNewCompteurs">
        <ValidationObserver ref="obs" v-slot="{ invalid }">
          <div class="form-compteur" v-if="showBottomSheetNewCompteurs">
            <p>Nouveau compteur:</p>
            <v-form>
              <form-builder
                :item="{ client: selectedMission.client }"
                :itemType="typeItem"
                :onlyRequired="onlyRequired"
                class="form-wrapper"
              >
              </form-builder>
            </v-form>
          </div>
          <div class="bottom-sheet-footer">
            <v-btn
              text
              color="primary"
              @click="showBottomSheetNewCompteurs=false,$emit('close-sheet')"
            >
              Annuler
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="showBottomSheetNewCompteurs=false"
            >
              Retour
            </v-btn>
            <v-btn
              v-if="showBottomSheetNewCompteurs"
              depressed
              color="primary"
              @click="saveForm()"
              :loading="saving"
              :disabled="invalid"
            >
              Sauvegarder
            </v-btn>
          </div>
        </ValidationObserver>
      </template>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>

import { mapState, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';

import axiosInstance from '../plugins/axiosWithHydra';
import { clientsEndpoint, compteursUrl } from '../api/apiUrls';
import FormBuilder from './form-builder.vue';
import CompteurList from './compteurs/CompteurList.vue';


export default {
  components: {
    FormBuilder,
    ValidationObserver,
    CompteurList,
  },
  props: {
    sheet: { type: Boolean },
  },
  data() {
    return {
      saving: false,
      typeItem: 'compteurs',
      showBottomSheetNewCompteurs: false,
      onlyRequired: false,
      items: [],
      search: '',
      searching: false,
      needToSearch: false,
      searchCounter: 0,
      partialSearchCounter: 0,
      choosenCompteur: null,
      compteursClient: [],
      nbCompteursClient: 0,
    };
  },

  computed: {
    ...mapState('compteurs', ['compteurs']),
    ...mapGetters('missions', { selectedMission: 'selected' }),
    ...mapGetters('sites', { selectedSite: 'selected' }),
    ...mapState(['currentForm']),

    selectedCompteur: {
      get() {
        return this.$store.state.selectedCompteur;
      },
      set(value) {
        this.$store.commit('setSelectedCompteur', value);
      },
    },
  },

  created() {
    axiosInstance.get(`${clientsEndpoint}/${this.selectedMission.client}${compteursUrl}`)
      .then(((response) => {
        this.nbCompteursClient = response.data['hydra:totalItems'];
        this.compteursClient = response.data['hydra:member'];
        this.populateItems(this.compteursClient);
        this.searchCounter = this.nbCompteursClient;
        if (this.searchCounter >= 30) {
          this.partialSearchCounter = 30;
        } else {
          this.partialSearchCounter = this.searchCounter;
        }
      }));
  },

  watch: {
    search(newValue) {
      this.populateItems(this.compteursClient);
      if (newValue && newValue.length > 0) {
        // Items have already been requested
        if (this.searching) {
          this.needToSearch = true;
        } else {
          this.callSearch(newValue);
        }
      }
    },

    choosenCompteur(newValue) {
      if (newValue && newValue.id) {
        this.$store.dispatch('compteurs/refreshItem', newValue.id)
          .then((compteur) => {
            this.selectedCompteur = compteur;
          });
      }
    },
  },

  methods: {
    selectCompteurToLink(compteur) {
      this.selectedCompteur = compteur;
      this.linkSiteWithCompteur();
    },

    callSearch() {
      this.searching = true;
      // replace accents
      const accent = [
        /[\300-\306]/g, /[\340-\346]/g, // A, a
        /[\310-\313]/g, /[\350-\353]/g, // E, e
        /[\314-\317]/g, /[\354-\357]/g, // I, i
        /[\322-\330]/g, /[\362-\370]/g, // O, o
        /[\331-\334]/g, /[\371-\374]/g, // U, u
        /[\321]/g, /[\361]/g, // N, n
        /[\307]/g, /[\347]/g, // C, c
      ];
      const noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c'];

      let str = this.search;
      for (let i = 0; i < accent.length; i += 1) {
        str = str.replace(accent[i], noaccent[i]);
      }

      const urlOptions = {
        headers: {
          accept: 'application/ld+json',
        },
        params: {
          searchField: str.toLocaleLowerCase(),
          pagination: true,
          page: 1,
        },
      };

      axiosInstance.get(`${clientsEndpoint}/${this.selectedMission.client}${compteursUrl}`, urlOptions)
        .then(((response) => {
          this.searchCounter = response.data['hydra:totalItems'];
          if (this.searchCounter >= 30) {
            this.partialSearchCounter = 30;
          } else {
            this.partialSearchCounter = this.searchCounter;
          }
          this.populateItems(response.data['hydra:member']);
        }))
        .finally(() => {
          this.searching = false;
          this.handleSearchQueue();
        });
    },

    populateItems(searchResults) {
      if (searchResults.length) {
        this.items = searchResults.map(compteur => ({
          id: String(compteur.id),
          nomCompteur: compteur.nomCompteur,
          numeroCompteur: compteur.numeroCompteur,
          adresse: compteur.adresse,
          energieLibelle: compteur.energieLibelle,
        }));
      } else {
        this.items = [];
        this.searchCounter = 0;
      }
    },
    // launch a new save if needToSave is true
    handleSearchQueue() {
      if (!this.searching && this.needToSearch) {
        this.needToSearch = false;
        this.callSearch();
      }
    },

    addNewCompteur() {
      this.$store.dispatch('clearCurrentForm');
      this.showBottomSheetNewCompteurs = true;
    },

    randomNumber() {
      let i = 0;
      let randomNumber = 'VIRTUEL';
      while (i < 12) {
        i += 1;
        randomNumber = `${randomNumber}${Math.floor(Math.random() * Math.floor(9))}`;
      }
      return randomNumber;
    },

    saveForm() {
      // payload construction //
      const payload = { };
      // convert all form fields to string
      const formCopy = JSON.parse(JSON.stringify(this.currentForm));
      if (formCopy.numeroCompteur === null) {
        formCopy.numeroCompteur = this.randomNumber();
      }
      // add the form to the payload
      payload.data = formCopy;
      if (formCopy.numeroCompteur === null) {
        formCopy.numeroCompteur = this.randomNumber();
      }
      this.saving = true;
      this.$store.dispatch(`${this.typeItem}/createNew`).then((newItem) => {
        this.$store.dispatch(
          `${this.typeItem}/save`,
          {
            ...payload,
            id: newItem.id,
          },
        ).then((response) => {
          this.selectedCompteur = response;
          this.$store.dispatch('snackbar/displaySnack', { message: 'créé avec succès !', type: 'succes' });
        }).catch((error) => {
          this.$store.dispatch('snackbar/displaySnack', {
            message: `impossible de sauvegarder le compteur : ${error}`,
            type: 'error',
          });
        }).finally(() => {
          this.showBottomSheetNewCompteurs = false;
          this.saving = false;
          this.linkSiteWithCompteur();
        });
      });
    },

    linkSiteWithCompteur() {
      if (this.selectedSite && this.selectedCompteur) {
        this.saving = true;
        // payload construction //
        const payload = { };
        // convert all form fields to string
        const formCopy = JSON.parse(JSON.stringify(this.selectedSite));
        formCopy.compteurs = formCopy.compteurs.concat(this.selectedCompteur.id);
        // add the form to the payload
        payload.data = formCopy;
        this.$store.dispatch('sites/save',
          {
            ...payload,
            id: this.selectedSite.id,
          }).then((result) => {
          this.$store.dispatch('changeSelectedItem', {
            id: result.id,
            type: 'sites',
          });
          this.$store.dispatch('compteurs/fetchAllBySite', this.selectedSite.id);
          this.$store.dispatch('snackbar/displaySnack', { message: 'Compteur affecté avec succès !', type: 'succes' });
        }).finally(() => {
          this.saving = false;
          this.$emit('close-sheet');
          this.selectedCompteur = null;
        });
      }
    },
  },
};
</script>
<style scoped>
  .sheet-wrapper {
    padding: 1rem;
    height: 100%;
    overflow: auto;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .search-field{
    width: 50vw;
  }

  .bottom-sheet-footer {
    width: 100%;
    height: 3rem;
    margin-bottom: 0;
    margin-top: auto;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
  }

  .compteurs-card-message {
    margin: 2rem;
    text-align: center;
  }
</style>
