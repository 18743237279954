import Vue from 'vue';
import VueRouter from 'vue-router';
import { getAccessToken } from 'axios-jwt';
import store from '../store';

import CommonRoutes from './common';
import MissionRoutes from './missions';
import ClientRoutes from './clients';
import SettingsRoutes from './settings';

const routes = [];
Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    ...routes,
    ...CommonRoutes,
    ...MissionRoutes,
    ...ClientRoutes,
    ...SettingsRoutes,
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (getAccessToken()) {
      store.dispatch('selectPathToGo', to.fullPath);
      next();
      return;
    }
    next({
      path: '/login',
      query: { redirect: to.fullPath },
    });
  } else {
    next();
  }
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.guest)) {
    if (getAccessToken()) {
      next('/');
      return;
    }
    next();
  } else {
    next();
  }
});

export default router;
