/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  sitesEndpoint,
  patrimoinesEndpoint,
  batimentsEndpoint,
  missionsEndpoint,
  compteursEndpoint,
  apiUrl,
  sitesUrl,
} from './apiUrls';

export default {
  db: new PouchDB('sites'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch
    const _id = obj._id || obj.id.toString();
    return {
      _id,
      meta: obj.meta || {},
      id: String(obj.id),
      nom: obj.nom,
      adresse: obj.adresse,
      ville: obj.ville,
      commune: obj.commune,
      codePostal: obj.codePostal,
      codeSite: obj.codeSite,
      temperatureBaseExterieur: obj.temperatureBaseExterieur,
      temperatureBaseInterieur: obj.temperatureBaseInterieur,
      batiments: obj.batiments.map(bat => bat.replace(`${batimentsEndpoint}/`, '')),
      mission: obj.mission.replace(`${missionsEndpoint}/`, ''),
      patrimoine: obj.patrimoine ? obj.patrimoine.replace(`${patrimoinesEndpoint}/`, '') : null,
      compteurs: obj.compteurs ? obj.compteurs.map(compteur => compteur.replace(`${compteursEndpoint}/`, '')) : null,
      image: obj.image,
      estConsommationEfImportee:
      obj.estConsommationEfImportee ? obj.estConsommationEfImportee : false,
      estUtiliseeConsommationImportee:
      obj.estUtiliseeConsommationImportee ? obj.estUtiliseeConsommationImportee : false,
      calculations: obj.calculations ? obj.calculations : null,
      inAlert: obj.inAlert,
      zoneClimatique: obj.zoneClimatique,
      zoneClimatiqueLibelle: obj.zoneClimatiqueLibelle,
      concernedByBacs: obj.concernedByBacs,
      hasBacsPrefere: obj.hasBacsPrefere,
      valeurBacsPrefere: obj.valeurBacsPrefere,
    };
  },

  create(site) {
    const siteToCreate = {
      ...site,
      inAlert: {
        inAlertBatiment: false,
        inAlertCollectInspection: false,
        inAlertSite: false,
      },
    };
    delete siteToCreate.image;

    return new Promise((resolve, reject) => {
      axiosInstance.axiosInstance.post(sitesEndpoint, siteToCreate)
        .then((response) => {
          resolve(this.convertBackendObject(response.data));
        })
        .catch((error) => { reject(error); });
    });
  },

  update(siteId, site) {
    const siteToCreate = { ...site };
    delete siteToCreate.image;

    return new Promise((resolve, reject) => {
      axiosInstance.axiosInstance.put(`${sitesEndpoint}/${siteId}`, siteToCreate)
        .then((response) => {
          resolve(this.convertBackendObject(response.data));
        })
        .catch((error) => { reject(error); });
    });
  },

  updatePicture(siteId, picture) {
    return new Promise((resolve, reject) => {
      axiosInstance.axiosInstance.post(`${apiUrl}/media/site/${siteId}/image`, picture)
        .then((response) => { resolve(response.data); })
        .catch((error) => { reject(error); });
    });
  },

  async delete(siteId) {
    // delete the site from pouchDB
    try {
      await pouchUtil.deleteDoc(siteId, this.db);
    } catch (e) {
      console.log(e.response);
    }
    // if the delete from pouchDB is sucessfull, delete from the server
    return axiosInstance.axiosInstance.delete(`${sitesEndpoint}/${siteId}`);
  },

  /**
   * get a site by his id
   * @param {String} siteId - the id of the site to get
   */
  async get(siteId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${sitesEndpoint}/${siteId}`);
      const site = this.convertBackendObject(response.data);

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDoc(site, this.db);
    } catch (e) {
      console.error('sitesApi get error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getDoc(siteId.toString(), this.db);
    }
  },

  /**
   * get all sites of the application
   */
  async getAll() {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${sitesEndpoint}`);
      const sites = response.data.map(site => this.convertBackendObject(site));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(sites, this.db);
    } catch (e) {
      console.error('sitesApi getAll error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocs(this.db);
    }
  },

  /**
   * get all children site of a mission
   * @param {String} missionId - id of the parent mission
   */
  async getAllByMission(missionId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${missionsEndpoint}/${missionId}${sitesUrl}`);
      const sites = response.data.map(site => this.convertBackendObject(site));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(sites, this.db);
    } catch (e) {
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByField('mission', missionId, this.db);
    }
  },

  /**
 * get all children site of a mission
 * @param {String} patrimoineId - id of the parent mission
 */
  async getAllByPatrimoine(patrimoineId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${patrimoinesEndpoint}/${patrimoineId}${sitesUrl}`);
      const sites = response.data.map(site => this.convertBackendObject(site));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(sites, this.db);
    } catch (e) {
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByField('patrimoine', patrimoineId, this.db);
    }
  },

  /**
   * get all children site of a mission for offline use
   * @param {String} missionId - id of the parent mission
   */
  async fetchOffline(missionId) {
    const response = await axiosInstance.axiosInstance.get(`${missionsEndpoint}/${missionId}${sitesUrl}`);
    const sites = response.data.map(site => this.convertBackendObject(site));
    return pouchUtil.addDocs(sites, this.db);
  },

  /**
   * Fetch all sites stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocs(this.db);
  },
};
