<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="600"
  >
    <!-- <template v-slot:activator="{ on }">
      <v-icon v-on="on">mdi-delete</v-icon>
    </template> -->
    <v-card>
      <v-card-title class="headline">
        <span>
          <v-icon style="color:red;font-size:30px;">mdi-alert-outline</v-icon>
        <span v-html="title"></span></span>
      </v-card-title>

      <v-card-text class="card-text">
        <p> <span v-html="text"></span></p>
        <p v-if="!disaffect && !offline">Cette action sera
          <span style="color:red; font-weight: bold;">définitive.</span>
        </p>
        <p v-if="disaffect && !offline">
        Cette action <span style="color:red; font-weight: bold;">supprimera</span> également
          toutes les répartitions des bâtiments de ce site.</p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="close()"
        >
          Annuler
        </v-btn>
        <v-btn
          class="delete-button"
          depressed
          color='red'
          @click.native="deleteItem()"
          :disabled="offline"
        >
          {{libelleBtn}}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      offline: false,
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    disaffect: {
      type: Boolean,
      default: false,
    },
    isFavouritePicture: {
      type: Boolean,
      default: false,
    },
    isPicture: {
      type: Boolean,
      default: false,
    },
    isFacture: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    itemName() {
      if (this.item) {
        if (this.item.typeInterventionLibelle) {
          return this.item.typeInterventionLibelle;
        }
        if (this.item.typeIntervention) {
          return this.item.typeIntervention;
        }
        if (this.item.name) {
          return this.item.name;
        }
        if (this.item.nom) {
          return this.item.nom;
        }
        if (this.item.nomCompteur) {
          return this.item.nomCompteur;
        }
      }
      return 'name not found';
    },

    text() {
      if (this.offline) {
        return 'Cette suppression est <span style="color:red; font-weight: bold;">impossible hors connexion</span>. Réessayez lorsque vous disposerez d\'un accès au réseau.';
      }
      if (this.disaffect) {
        return `Êtes-vous sûr de vouloir <span style="color:red; font-weight: bold;">désaffecter</span> le compteur <span style="font-weight:bold;">${this.itemName} </span>?`;
      }
      if (this.multiple) {
        return `Êtes-vous sûr de vouloir <span style="color:red; font-weight: bold;">supprimer</span> les <span style="color:red; font-weight: bold;">${this.items.length} éléments</span> sélectionnés?`;
      }
      if (this.isFavouritePicture) {
        return 'Attention, vous allez <span style="color:red; font-weight: bold;">supprimer</span> la photo principale, cette action est définitive, confirmez-vous ?';
      }
      if (this.isPicture) {
        return 'Attention, vous allez <span style="color:red; font-weight: bold;">supprimer</span> cette photo, cette action est définitive, confirmez-vous ?';
      }
      if (this.isFacture) {
        return 'Êtes-vous sûr de vouloir <span style="color:red; font-weight: bold;">supprimer</span> ? </span>';
      }
      return `Êtes-vous sûr de vouloir <span style="color:red; font-weight: bold;">supprimer</span> <span style="font-weight:bold;">: ${this.itemName} </span>?`;
    },

    title() {
      if (this.offline) {
        return '<span style="color:red; font-weight: bold;">Impossible</span> de supprimer cet élément';
      }
      if (this.disaffect) {
        return 'Vous êtes sur le point de <span style="color:red; font-weight: bold;">désaffecter</span> <br/>ce compteur de ce site.';
      }
      if (this.multiple) {
        return `Vous êtes sur le point de <span style="color:red; font-weight: bold;">supprimer</span> <br/>${this.items.length} éléments.`;
      }
      if (this.isFacture) {
        return 'Vous êtes sur le point de <span style="color:red; font-weight: bold;">supprimer</span> cette/ces<br/> facture(s).';
      }
      return 'Vous êtes sur le point de <span style="color:red; font-weight: bold;">supprimer</span> cet élément.';
    },

    libelleBtn() {
      return this.disaffect ? 'Désaffecter' : 'Supprimer';
    },
  },

  created() {
    if (this.item && typeof this.item.id === 'string' && !this.item.id.startsWith('temp') && !navigator.onLine) {
      this.offline = true;
    }
  },
  methods: {
    deleteItem() {
      this.multiple ? this.$emit('delete-items') : this.$emit('delete-item');
      this.close();
      // this.dialog = false;
    },


    close() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-button {
  color: white;
}

.card-text{
  font-size: 20px;
}
</style>
