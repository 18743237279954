// import PouchDB from 'pouchdb-browser';
// import pouchdbDebug from 'pouchdb-debug';

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './registerServiceWorker';
import vuetify from './plugins/vuetify';
import './plugins/vee-validate';

// entry point for generic styles
import './styles/main.scss';

Vue.config.productionTip = false;
Vue.config.devtools = true;

// pouch db debug mode
// PouchDB.plugin(pouchdbDebug);
// PouchDB.debug.enable('*');

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app');
