import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import fr from 'vuetify/es5/locale/fr';

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: { fr },
    current: 'fr',
  },
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    light: true,
    themes: {
      light: {
        primary: '#2D4388',
        secondary: '#424242',
        tertiary: '#EA9242',
        accent: '#4CAF50',
        error: '#f44336',
        warning: '#ffeb3b',
        info: '#2196f3',
        success: '#4caf50',
        customred: '#00ccee',
      },
    },
    // options: {
    //   customProperties: true,
    // },
  },
});
