import api from '@/api';
/* eslint no-console: ["error", { allow: ["error", "log"] }] */
/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = {
  factures: [],
};

const getters = { };

const actions = {
  createNew({ commit }) {
    const newFacture = {
      id: `tempFact${Math.random()}`,
      dateDebut: null,
      dateFin: null,
      consommationEf: null,
      coutTotal: null,
      sourceDonnee: 'SAISIE',
    };
    commit('addFactures', [newFacture]);

    return newFacture;
  },

  /**
   * Fetch one facture by it's id from the server and update the store
   */
  refreshItem({ commit }, factureId) {
    return new Promise((resolve) => {
      api.factures.get(factureId).then((response) => {
        commit('addFactures', [response]);
        resolve({ ...response });
      });
    });
  },

  /**
   * Fetch all factures by compteur
   * @param {String} compteurId - id of the compteur
   */
  fetchAllByCompteur({ commit }, compteurId) {
    return api.factures.getAllByCompteur(compteurId).then(response => commit('addFactures', response));
  },

  deleteFactureByCompteur({ commit }, factureId) {
    commit('deleteFacture', factureId);
  },
  /**
   * Load all factures stored offline in pouchDB
   */
  loadOffline({ commit }) {
    return api.factures.loadOffline().then(response => commit('addFactures', response));
  },

  /**
   * Save a facture on the server (by PUT) and update the store
   */
  save({ commit }, payload) {
    return new Promise((resolve, reject) => {
      if (typeof (payload.id) === 'string' && payload.id.startsWith('tempFact')) {
        api.factures.create(payload.data, payload.id).then((response) => {
          commit('deleteNewFactures', payload.id);
          commit('addFactures', [response]);
          resolve({ ...response });
        }).catch(error => reject(error));
      } else {
        api.factures.update(payload.id, payload.data).then((response) => {
          commit('addFactures', [response]);
          resolve({ ...response });
        });
      }
    });
  },
  /**
   * Delete a facture on the server and the store
   */
  async delete({ commit }, facture) {
    // Cast en string
    await api.factures.delete(String(facture))
      .then(() => {
        commit('deleteFacture', facture);
      });
  },
};

const mutations = {
  deleteNewFactures(state, tempIdFacture) {
    state.factures = state.factures.filter(item => item.id !== tempIdFacture);
  },

  addFactures(state, newFactures) {
    newFactures.forEach((newFacture) => {
      const stateIndex = state.factures
        .findIndex(stateFacture => stateFacture.id === newFacture.id);

      if (stateIndex >= 0) {
        state.factures.splice(stateIndex, 1, newFacture);
      } else {
        state.factures.push(newFacture);
      }
    });
  },

  deleteFacture(state, idFacture) {
    state.factures.splice(
      state.factures.filter(item => item.id === idFacture), 1,
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
