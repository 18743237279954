<template>
  <v-bottom-sheet
    v-model="sheet"
    fullscreen
  >

    <v-sheet class="sheet-wrapper">
    <ValidationObserver class="observer-wrapper" ref="obs" v-slot="{ invalid }">
      <div class="wrapper-titles"><h1 class="title-edit-item">{{title}}
        <div v-if="item.libelleCategorie">
          <h4 class="textSubtitle">
           {{item.libelleDomaine}} / {{item.libelleCategorie}} / {{item.libelleType}}
          </h4></div></h1>
        </div>
        <div class="form-wrapper">
          <v-form>
            <form-builder
              :item="item"
              :itemType="typeItem"
              :key="formBuilderKey"
              :onlyRequired="false"
              :onlyEdit="true"
              @changeAllEquipement="changeAllEquipement($event)"
            >
            </form-builder>
          </v-form>
          <div class="divers-lists">
            <div v-if="equipementsList.length">
              <MultiSelectLinkedEquipement
                @select-equipment="equipementSelected($event)"
                @linked-chosen="handlerEquipementSelected($event)"
                :selectedEquipement="selectedLinkedEquipements"
                :equipements="equipementsList"
                :navigate="clickable"
                :loading="waiting"
              >
              </MultiSelectLinkedEquipement>
            </div>
            <div class="intervention-list" v-if="interventionList.length">
              <p class="title">Interventions affectées </p>
              <v-list-item v-for="intervention in interventionList"
                          :key="intervention.typeInterventionLibelle">
                <v-list-item-content>
                  <v-list-item-title>
                    <a @click="editIntervention(intervention)"
                >{{intervention.typeInterventionLibelle}} </a></v-list-item-title>
                </v-list-item-content>
              </v-list-item>

            </div>
          </div>
        </div>
      <div class="bottom-sheet-footer">
        <v-btn
          class="action-button"
          text
          large
          color="primary"
          @click="closeSheet('cancel')"
        >
          Annuler
        </v-btn>

        <v-btn
          class="action-button"
          depressed
          large
          color="primary"
          @click.native="saveForm()"
          :disabled="invalid"
          :loading="saving"
        >
          Valider
        </v-btn>
      </div>
      </ValidationObserver>
    </v-sheet>
    <BottomSheetEditIntervention
      v-if="editSheet"
      :sheet="editSheet"
      :inter="interToEdit"
      :interventions="interventions"
      @close-sheet="closeEditInterSheet()"
    >
    </BottomSheetEditIntervention>
    <BottomSheetEditItem
      v-if="showEditSheet"
      :sheet="showEditSheet"
      :item="selectedItem"
      :typeItem="selectedItemType"
      :interventions="interventions"
      :equipements="equipements"
      @close-sheet="handleClose()"
    >
    </BottomSheetEditItem>

  </v-bottom-sheet>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';
import FormBuilder from './form-builder.vue';
import MultiSelectLinkedEquipement from './equipements/MultiSelectLinkedEquipement.vue';


export default {
  components: {
    FormBuilder,
    ValidationObserver,
    BottomSheetEditIntervention: () => import('./interventions/BottomSheetEditIntervention.vue'),
    // BottomSheetEditItem: () => import('./BottomSheetEditItem.vue'),
    MultiSelectLinkedEquipement,
  },
  mixins: [
    UpdateItemAlertMixin,
  ],
  props: {
    sheet: { type: Boolean },
    item: { type: Object },
    typeItem: { type: String },
    interventions: { type: Array },
    equipements: { type: Array },
  },
  async created() {
    if (this.typeItem === 'equipements') {
      await this.$store.dispatch('equipements/refreshItem', this.item.id).then(() => {
        if (this.typeItem === 'equipements' && typeof this.item.linkedEquipements !== 'undefined') {
          this.item.linkedEquipements.forEach((element) => {
            // deal with api/equipements/8059
            if (element && element.length) {
              const parts = element.split('/');
              const id = parts[parts.length - 1];
              const equi = this.equipements.find(equipement => equipement.id === id);
              this.selectedLinkedEquipements.push(equi.id);
            } else {
              const equi = this.equipements.find(equipement => equipement.id === element.id);
              this.selectedLinkedEquipements.push(equi.id);
            }
          });
        }
        this.waiting = false;
      });
    }
  },
  data() {
    return {
      defaultValuesLearningField: {},
      saving: false,
      editSheet: false,
      interToEdit: null,
      obs: this.$refs.obs,
      formBuilderKey: 0,
      linkedEquipements: [],
      selectedLinkedEquipements: [],
      addLinkedEquipements: [],
      removedLinkedEquipements: [],
      clickable: true,
      showEditSheet: false,
      selectedItem: null,
      selectedItemType: 'equipements',
      waiting: true,
      changeAllEquipementFields: [],
    };
  },

  computed: {
    ...mapGetters({
      selectedMission: 'missions/selected',
      getEquipementFormTemplate: 'formTemplates/getEquipementFormTemplate',
      getFormTemplate: 'formTemplates/getFormTemplate',
      batimentEquipements: 'equipements/selectedBatimentEquipements',
      batimentInspections: 'inspections/selectedBatimentInspections',
      batimentsSite: 'batiments/selectedSiteBatiments',
      sitesInspections: 'inspections/selectedSiteInspections',
    }),
    ...mapState(['currentForm']),
    title() {
      switch (this.typeItem) {
        case 'missions':
          return 'Edition d\'une mission';
        case 'inspections':
          return 'Edition d\'une inspection';
        case 'batiments':
          return 'Edition d\'un bâtiment';
        case 'imageCatalogues':
          return 'Edition d\'une image';
        case 'factures':
          return 'Edition d\'une facture';
        case 'equipements':
          return 'Edition d\'un équipement';
        default:
          return `Edition d'un ${this.typeItem.slice(0, -1).toLowerCase()}`;
      }
    },
    interventionList() {
      if (this.interventions) {
        return this.interventions
          .filter(intervention => intervention.equipement === this.item.id);
      }
      return [];
    },
    equipementsList() {
      if (this.equipements && this.equipements.length && this.typeItem !== 'niveau' && this.typeItem !== 'inspections') {
        return this.equipements.filter(equip => equip.id !== this.item.id);
      }
      return [];
    },
  },
  watch: {
    linkedEquipements(newValue) {
      if (newValue) {
        this.clickable = false;
        this.removedLinkedEquipements = this.selectedLinkedEquipements
          .filter(x => newValue.indexOf(x.id) === -1);
      }
    },
    selectedItem(newValue) {
      if (newValue.linkedEquipements.length) {
        newValue.linkedEquipements.forEach((element) => {
          // deal with api/equipements/8059 to get just the ID
          const parts = element.split('/');
          const id = parts[parts.length - 1];
          const equi = this.equipements.find(equipement => equipement.id === id);

          if (element) this.selectedLinkedEquipements.push(equi.id);
        });
      }
    },
    showEditSheet(newValue) {
      if (!newValue) {
        this.editSheet = false;
      }
    },
  },
  methods: {
    changeAllEquipement(event) {
      this.changeAllEquipementFields[event.field] = event.value;
    },
    closeSheet(event) {
      this.formBuilderKey += 1;
      if (event === 'cancel') {
        this.changeAllEquipementFields = [];
        this.defaultValuesLearningField = {};
        if (this.typeItem === 'imageCatalogues') {
          this.$emit('cancel-image');
        } else {
          this.closeEditInterSheet();
        }
      } else {
        this.closeEditInterSheet();
      }
    },
    equipementSelected(equipement) {
      if (this.clickable) {
        this.selectedItem = equipement;
        this.$store.dispatch('equipements/changeSelected', equipement.id).then(() => {
          this.showEditSheet = true;
        });
      }
    },
    editIntervention(inter) {
      this.interToEdit = {
        item: inter,
        source: {
          type: 'equipement',
        },
      };
      this.interToEdit.source.name = this.item.nom;
      this.editSheet = true;
    },
    handleClose() {
      this.closeSheet();
      this.showEditSheet = false;
    },
    closeEditInterSheet() {
      this.editSheet = false;
      this.interToEdit = null;
      this.$emit('close-sheet');
    },
    // add the elements to link
    handlerEquipementSelected(equipements) {
      // deal wtih undefined value
      this.selectedLinkedEquipements = this.selectedLinkedEquipements
        .filter(element => element != null);
      const filteredEquipement = equipements.filter(element => element != null);
      this.selectedLinkedEquipements = filteredEquipement;
      this.addLinkedEquipements = filteredEquipement
        .filter(x => !this.selectedLinkedEquipements.some(equi => equi.id === x));
    },
    // Add and remove link for HL mode
    majLinkedEquipement(equipement, operation) {
      this.$store.dispatch('equipements/refreshItem', equipement)
        .then((res) => {
          const response = res;
          if (!response.linkedEquipements.includes(this.item.id) && operation === 'push') {
            response.linkedEquipements.push(this.item.id);
          } else if (response.linkedEquipements.length && operation === 'pop') {
            response.linkedEquipements = response
              .linkedEquipements.filter(equiId => !equiId.includes(this.item.id));
          }
          if (response.attributs) {
            const payload = {
              ...response.attributs,
            };
            this.$store.dispatch(
              'equipements/save',
              {
                id: response.id,
                data: {
                  ...response,
                  attributs: {
                    ...payload,
                  },
                },
                libelleDomaine: response.libelleDomaine,
                libelleCategorie: response.libelleCategorie,
                libelleType: response.libelleType,
                nom: response.nom,
                performance: response.performance,
                vetuste: response.vetuste,
                linkedEquipements: response.linkedEquipements,
              },
            );
          }
        });
    },
    saveForm() {
      this.saving = true;

      let findBlank = false;

      // payload construction //
      const payload = { };
      // convert all form fields to string
      const formCopy = JSON.parse(JSON.stringify(this.currentForm));
      // if there's a photo to save, put it in a separate field in the payload
      if (this.currentForm.photo) {
        payload.photo = this.currentForm.photo;
        delete formCopy.photo;
      }
      if (this.typeItem === 'inspections') {
        // check if inspection is in alert
        const fieldNecessary = [];
        this.getFormTemplate('inspections', this.item).fieldGroups.forEach(
          fieldgroup => fieldgroup.fields.forEach(
            (field) => {
              if (field.rules && field.rules.includes('necessary')) {
                fieldNecessary.push(field.name);
              }
            },
          ),
        );

        let i = 0;
        // findBlank = true;
        while (i < fieldNecessary.length && !findBlank) {
          if (formCopy.constats
            && formCopy.constats[fieldNecessary[i]] === null) {
            findBlank = true;
          }
          i += 1;
        }
        // populate data to save
        payload.data = {
          ...this.item,
          ...formCopy,
          inAlert: findBlank,
        };
      } else if (this.typeItem === 'equipements') {
        const keys = Object.keys(this.changeAllEquipementFields);
        keys.forEach((key) => {
          if (this.item.attributs && Object.hasOwn(this.item.attributs, key)) {
            this.defaultValuesLearningField[key] = this.item.attributs[key];
          } else if (this.item && Object.hasOwn(this.item, key)) {
            this.defaultValuesLearningField[key] = this.item[key];
          }
        });
        // check if equipement is in alert
        const fieldNecessary = [];
        this.getEquipementFormTemplate(this.item, this.selectedMission.id).fieldGroups.forEach(
          fieldgroup => fieldgroup.fields.forEach(
            (field) => {
              if (field.rules && field.rules.includes('necessary')) {
                fieldNecessary.push(field.name);
              }
            },
          ),
        );

        let i = 0;
        while (i < fieldNecessary.length && !findBlank) {
          if (fieldNecessary[i] !== 'performance'
            && fieldNecessary[i] !== 'vetuste'
            && formCopy.attributs
            && formCopy.attributs[fieldNecessary[i]] === null) {
            findBlank = true;
          } else if (!formCopy.performance) {
            findBlank = true;
          } else if (!formCopy.vetuste) {
            findBlank = true;
          }
          i += 1;
        }
        // populate data to save
        payload.data = {
          ...formCopy,
          inAlert: findBlank,
          libelleDomaine: this.item.libelleDomaine,
          libelleCategorie: this.item.libelleCategorie,
          linkedEquipements: this.selectedLinkedEquipements,
        };
        // add the link equipement to the other equipement
        if (!navigator.onLine && this.addLinkedEquipements.length) {
          this.addLinkedEquipements.forEach((element) => {
            this.majLinkedEquipement(element, 'push');
          });
        }
        // remove the link equipement to the other equipement
        if (!navigator.onLine && this.removedLinkedEquipements.length) {
          this.removedLinkedEquipements.forEach((element) => {
            this.majLinkedEquipement(element.id, 'pop');
          });
        }
      } else if (this.typeItem === 'batiments') {
        // check if batiment is in alert
        const fieldNecessary = [];
        this.getFormTemplate('batiments', this.item).fieldGroups.forEach(
          fieldgroup => fieldgroup.fields.forEach(
            (field) => {
              if (field.rules && field.rules.includes('necessary')) {
                fieldNecessary.push(field.name);
              }
            },
          ),
        );
        let i = 0;
        while (i < fieldNecessary.length && !findBlank) {
          if (formCopy[fieldNecessary[i]] === null) {
            findBlank = true;
          }
          i += 1;
        }
        const alertBati = this.item.inAlert;
        // populate data to save
        payload.data = {
          ...formCopy,
          typeSurface2: (formCopy.surface2 !== null) ? 'SP' : null,
          inAlert: {
            ...alertBati,
            inAlertBatiment: findBlank,
          },
        };
        if (!navigator.onLine && (!payload.data.images || !payload.data.energiesChauffage)) {
          payload.data.images = [];
          payload.data.energiesChauffage = [];
        }
      } else if (this.typeItem === 'missions') {
        payload.data = {
          ...formCopy,
          coeffPonderation: parseFloat(formCopy.coeffPonderation),
          txActualisationEnergies: parseFloat(formCopy.txActualisationEnergies),
          txTVA: parseFloat(formCopy.txTVA),
        };
        payload.byController = true;
      } else {
        payload.data = formCopy;
      }

      this.$store.dispatch(
        `${this.typeItem}/save`,
        {
          id: this.item.id,
          ...payload,
        },
      ).then(async (res) => {
        let message = `Impossible de sauvegarder le ${this.typeItem.slice(0, -1).toLowerCase()}`;
        if (res.code === 'ERR_BAD_RESPONSE' || res.isAxiosError) {
          if (res.response.data === 'Le contrôleur est déjà utilisé par un autre utilisateur.') {
            message = 'Un autre utilisateur modifie également une mission, merci de réessayer dans quelques instants.';
          } else if (this.typeItem === 'equipements') {
            message = `Impossible de sauvegarder l'${this.typeItem.slice(0, -1).toLowerCase()}`;
          }
          this.$store.dispatch('snackbar/displaySnack',
            {
              message,
              type: 'error',
            });
        } else {
          if (this.typeItem === 'missions') {
            await this.$store.dispatch('equipements/updateRefMission', { missionId: this.selectedMission.id, majRef: true });
          }
          await this.$store.dispatch(`${this.typeItem}/refreshItem`, this.item.id);
          this.$store.dispatch('snackbar/displaySnack', { message: 'Sauvegardé avec succès !', type: 'succes' });
          if (this.typeItem === 'equipements') {
            if (this.changeAllEquipementFields) {
              await this.$store.dispatch('equipements/fetchAllByBatiment', this.item.batiment).then(() => {
                const keys = Object.keys(this.changeAllEquipementFields);
                let save = false;
                this.batimentEquipements.forEach((equip) => {
                  const equipementToModify = JSON.parse(JSON.stringify(equip));
                  keys.forEach((key) => {
                    const value = this.changeAllEquipementFields[key];
                    if (equip.attributs && Object.hasOwn(equip.attributs, key)
                      && equip.attributs[key] === this.defaultValuesLearningField[key]) {
                      equipementToModify.attributs[key] = value;
                      save = true;
                    } else if (equip && Object.hasOwn(equip, key)
                    && equip[key] === this.defaultValuesLearningField[key]) {
                      equipementToModify[key] = value;
                      save = true;
                    }
                  });
                  if (save) {
                    save = false;
                    this.$store.dispatch(
                      `${this.typeItem}/save`,
                      {
                        id: equipementToModify.id,
                        data: {
                          ...equipementToModify,
                        },
                      },
                    );
                  }
                });
              });
            }
            await this.majBatimentAlert(this.item.batiment, 'equipements');
          } else if (this.typeItem === 'inspections' && this.item.batiment) {
            await this.majBatimentAlert(this.item.batiment, 'inspections');
          } else if (this.typeItem === 'inspections') {
            await this.majSiteAlert(this.item.site, 'inspections');
          } else if (this.typeItem === 'batiments') {
            await this.majSiteAlert(this.item.site, 'batiments');
          } else if (this.typeItem === 'missions') {
            await this.$store.dispatch('inspections/clearTypes');
          }
        }
      }).catch(() => {
        let message = 'Impossible de sauvegarder le ';
        if (this.typeItem === 'equipements') {
          message = 'Impossible de sauvegarder l\'';
        }
        if (this.typeItem === 'missions') {
          message = 'Impossible de sauvegarder la';
        }
        this.$store.dispatch('snackbar/displaySnack',
          {
            message: `${message}${this.typeItem.slice(0, -1).toLowerCase()}`,
            type: 'error',
          });
      }).finally(() => {
        this.saving = false;
        this.closeSheet('save');
        this.changeAllEquipementFields = [];
        this.defaultValuesLearningField = {};
      });
    },

  },
};

</script>
<style lang="scss" scoped>
.title-edit-item{
  text-align: center;
  font-size: 1.5rem;
}
  .observer-wrapper {
    // height: 95%;
    //width:100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    > * {

    }
  }
  .wrapper-titles{
    position: fixed;
    height: 64px;
    top: 0;
    margin-top: 0;
    width:100%;
    background-color: #2D4388;
    z-index: 10;
    color:white !important;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    margin-right: 1.2rem;
  }
  .sheet-wrapper {
    // padding: 100px 3rem 100px 3rem;
    overflow: auto;
    height: 100%;
    width:100%;
    overscroll-behavior: none;
    background-color: #f9f9f9;
  }

  .bottom-sheet-footer {
    width: 100%;
    height: 64px;
    bottom: 0;
    margin-bottom: 0;
    position: fixed;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background-color: white;
    margin-right: 1.2rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

    .textSubtitle{
      text-align: center;
      font-size: 16px;
    }

  .form-wrapper {
    margin-top: 5rem;
    margin-bottom: 5rem;
    .intervention-list{
      .title {
        font-size: 1.5rem !important;
        font-weight: 300;
        margin-bottom: 1rem;
        color: #2D4388;
      }
      width: 35em;
    }

  }

  .divers-lists {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    width: 100%;
    > * {
      margin: 1rem;
      padding:1rem;
      flex-grow: 4;
      align-items: center;
      border-radius: 5px;

      display: flex;
      flex-flow: column nowrap;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      background-color: white;
    }
  }
</style>
