<template>
<div>
  <h1 class="title">Liste des référentiels</h1>
  <v-data-table
    :headers="headersVersion"
    :items="referentials"
    hide-default-footer
    no-data-text="Aucune version n'est disponible"
    disable-pagination
    dense
    multi-sort
    class="table"
    single-select
    show-select
    v-model="selectedRow"
  >
    <template v-slot:[`body.prepend`]>
      <tr>
        <td></td>
        <td>
          <v-text-field
            v-model="referentials.version"
            type="text"
            label="Search.."
          ></v-text-field>
        </td>
        <td>
          <v-text-field
            v-model="referentials.description"
            type="text"
            label="Search.."
          ></v-text-field>
        </td>
        <td></td>
      </tr>
    </template>
    <template v-slot:[`item.delete`]="{ item }">
      <v-icon
        color="error"
        small
        @click="showDeleteDialog(item.id)"
        :disabled="item.nbMissions>0"
      >
        mdi-delete
      </v-icon>
  </template>
  </v-data-table>
  <v-dialog
    persistent
    v-model="showDialogAddRef"
    max-width="600px"
  >
    <ValidationObserver
    ref="obs"
    v-slot="{ invalid }"
    >
    <v-card>
      <v-card-title class="headline">
        <span>
        <span>Ajouter une nouvelle version de référentiels</span></span>
      </v-card-title>
      <ValidationProvider
        v-slot="{ errors }"
        name="numero"
        rules="required"
        slim
      >
        <v-text-field
          outlined
          label="Numéro de version *"
          v-model="numVersion"
          dense
          :error-messages="errors"
          class="numVersion"
        >
        </v-text-field>
        </ValidationProvider>
        <v-textarea
        outlined
        label="Description"
        v-model="description"
        dense
        class="numVersion"
      ></v-textarea>
        <input
          type="file"
          ref="fileInput"
          class="numVersion"
          @change="handleFileChange($event)"
        >
      <v-card-text class="card-text">
      <p class="required-fields-hint">* Champs requis </p>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          text
          @click="showDialogAddRef = false"
        >
          Annuler
        </v-btn>
        <v-btn
          class="delete-button"
          depressed
          color='primary'
          @click.native="saveForm()"
          :loading="loadBtn"
          :disabled="invalid || !zipRef"
        >
          Valider
        </v-btn>
        </v-card-actions>
      </v-card>
    </ValidationObserver>
  </v-dialog>
  <v-fab-transition>
  <v-btn
    color="primary"
    fab
    large
    class="fab-add-button"
    @click="addNewRef()"
  >
    <v-icon>mdi-plus</v-icon>
  </v-btn>
  </v-fab-transition>
    <DialogDeleteConfirmation
    :dialog="deleteDialog"
    @delete-item="deleteRef()"
    @close-dialog="closeDeleteDialog()"
  >
  </DialogDeleteConfirmation>
</div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import axiosInstance from '../../plugins/axios';
import DialogDeleteConfirmation from '../../components/DialogDeleteConfirmation.vue';


export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    DialogDeleteConfirmation,
  },
  data() {
    return {
      selectedRow: [],
      headersVersion: [
        {
          text: 'Version',
          align: 'left',
          value: 'version',
        },
        {
          text: 'Description',
          value: 'description',
        },
        {
          text: 'Nombre de missions',
          value: 'nbMissions',
        },
        {
          text: '',
          value: 'delete',
        },
      ],
      referentials: [],
      showDialogAddRef: false,
      numVersion: null,
      description: '',
      zipRef: null,
      loadBtn: false,
      deleteDialog: false,
      refToDelete: null,
    };
  },

  created() {
    axiosInstance.axiosInstance.get('/api/referentiel-jsons').then((response) => {
      // ne pas afficher al ligne "ancienne version"
      this.referentials = response.data.filter(ref => ref.id !== 1);
      this.selectedRow = response.data.filter(ref => ref.refCommun === true);
    });
  },
  watch: {
    description(newDescription) {
      if (this.zipRef) {
        this.zipRef.set('description', newDescription);
      }
    },
    selectedRow(newValue) {
      this.changeRefCommun(newValue[0].id);
    },
  },
  methods: {
    showDeleteDialog(id) {
      this.deleteDialog = true;
      this.refToDelete = id;
    },
    closeDeleteDialog() {
      this.deleteDialog = false;
      this.refToDelete = null;
    },
    deleteRef() {
      axiosInstance.axiosInstance.delete(`api/referentiel-jsons/${this.refToDelete}`).then(() => {
        this.$store.dispatch('snackbar/displaySnack', { message: 'Référentiel supprimé avec succès', type: 'succes' });
        this.refToDelete = null;
        axiosInstance.axiosInstance.get('/api/referentiel-jsons').then((response) => {
          // ne pas afficher al ligne "ancienne version"
          this.referentials = response.data.filter(ref => ref.id !== 1);
          this.selectedRow = response.data.filter(ref => ref.refCommun === true);
        });
      }).catch(() => {
        this.$store.dispatch('snackbar/displaySnack', { message: 'Echec de la suppression', type: 'error' });
      });
    },
    changeRefCommun(id) {
      axiosInstance.axiosInstance.get(`api/referentiel-jsons/${id}/change-commun`, this.zipRef);
    },
    addNewRef() {
      this.numVersion = null;
      this.description = '';
      this.zipRef = null;
      this.showDialogAddRef = true;
      this.$refs.fileInput.value = null;
    },
    saveForm() {
      this.loadBtn = true;
      axiosInstance.axiosInstance.post(`api/referentiel-jsons/${this.numVersion}/import-zip`, this.zipRef).then((response) => {
        this.referentials.push(response.data);
        this.showDialogAddRef = false;
        this.numVersion = null;
        this.description = '';
        this.zipRef = null;
        this.loadBtn = false;
      }).catch((err) => {
        this.showDialogAddRef = false;
        this.numVersion = null;
        this.description = '';
        this.zipRef = null;
        this.loadBtn = false;
        let errorMessage = err.response.data;
        if (err.response && err.response.status === 403) {
          errorMessage = "Vous n'avez pas les droits pour cette action";
        }
        this.$store.dispatch('snackbar/displaySnack',
          {
            message: errorMessage,
            type: 'error',
          });
      });
    },
    handleFileChange(event) {
      if (typeof event.target.files[0] !== 'undefined') {
        this.zipRef = new FormData();
        this.zipRef.append('description', this.description);
        this.zipRef.append('file', event.target.files[0]);
      }
    },
  },
};
</script>
<style scoped>
.fab-add-button {
  position: fixed;
  bottom: 4rem;
  right: 3rem;
}
.numVersion {
  margin: 2em;
}
.title{
  text-align: center;
}
.table{
  margin:5em;
}
</style>
