<template>
  <div class="collec-view-wrapper">
    <v-overlay class="overlay" v-if="loading || loadingEquipementList || isProcessing">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <div v-if="selectedBatiment"
      class="collect-view-tab-wrapper"
    >

      <div class="collec-tab-container">
        <v-tabs-items v-model="currentTab"
          class="collect-tab-content"
          touchless>
          <v-tab-item>
            <NiveauxList
              v-if="currentTab === NIVEAUX_TAB"
              @edit-niveau="handleEdit('niveaux', $event)"
              @delete-niveau="handleDelete('niveaux', $event)"
            ></NiveauxList>
          </v-tab-item>
          <v-tab-item>
            <div v-if="defaultVue">
            <EquipementsList
              v-if="currentTab === EQUIPEMENTS_TAB"
              :clearItem="clearItem"
              @create-inter="handleCreateInter('equipement', $event)"
              @edit-equipement="handleEditEquipement('equipements', $event)"
              @delete-equipement="handleDelete('equipements', $event)"
              @delete-equipements="handleDeletes($event)"
              @duplicate-equipement="handleDuplicate('equipements', $event)"
              @duplicate-equipements="duplicateItems($event)"
              @linked-equipements="handleLinked($event)"
              @showDialogPerfVet="showDialogPerfVet($event)"
              @loadingEquipementList="loadingEquipementList = $event"
              @fabButtonLoading="fabButtonLoading($event)"
            ></EquipementsList>
            </div>
          </v-tab-item>
          <v-tab-item>
            <InspectionsList
              v-if="currentTab === INSPECTIONS_TAB"
              typeItem="batiments"
              @create-inter="handleCreateInter('inspection', $event)"
              @edit-inspection="handleEdit('inspections', $event)"
              @show-fabInspection="handleShowFab($event)"
              @delete-inspection="handleDelete('inspections', $event)"
            ></InspectionsList>
          </v-tab-item>
        </v-tabs-items>

        <v-fab-transition>
          <v-btn
            v-if="currentTab === NIVEAUX_TAB"
            :key="currentTab"
            color="primary"
            fab
            large
            class="fab-add-button"
            @click="addItem()"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>

        </v-fab-transition>

        <BaseFab
          v-if="currentTab === EQUIPEMENTS_TAB"
          class="fab-add-button"
          :options="equipementFabOptions"
          :key="currentTab"
          @option-selected="handlerFabButton"
          @check-online="handlerDisable"
          :loading="fabLoading"
        >
        </BaseFab>

        <BaseFab
          v-if="currentTab === INSPECTIONS_TAB && showFabInspection"
          class="fab-add-button"
          :options="inspestionFabOptions"
          :key="currentTab"
          @option-selected="handlerFabButton"
          @check-online="handlerDisable"
        >
        </BaseFab>

      </div>
      <v-tabs
        v-model="currentTab"
        grow
        class="collect-tab-bar"
      >
        <v-tab>
         niveaux <v-icon right>mdi-layers-outline</v-icon>
        </v-tab>

        <v-tab>
          <v-icon
            v-if="selectedBatiment.inAlert.inAlertCollectEquipement"
          class="alert-icon">mdi-alert-circle</v-icon>
          equipements <v-icon right>mdi-toolbox-outline</v-icon>
        </v-tab>

        <v-tab>
          <v-icon
            v-if="selectedBatiment.inAlert.inAlertCollectInspection"
          class="alert-icon">mdi-alert-circle</v-icon>
          inspections <v-icon right>mdi-notebook-outline</v-icon>
        </v-tab>
      </v-tabs>
    </div>

    <BottomSheetAddEquipement
      v-if="showEquipementSheet"
      :sheet="showEquipementSheet"
      @close-sheet="showEquipementSheet = false"
      @open-edit-modal="handleEdit('equipements', $event)"
    >
    </BottomSheetAddEquipement>

    <BottomSheetAddInspection
      :typeItem="'batiment'"
      v-if="showInspectionSheet"
      :sheet="showInspectionSheet"
      @close-sheet="showInspectionSheet = false"
      @open-edit-modal="handleEdit('inspections', $event)"
    >
    </BottomSheetAddInspection>

    <ImportEquipementModal
      :show="showImportEquipementSheet"
      :fromMission ="fromMission"
      :chosenClientParam = "chosenClient"
      :chosenMissionParam = "chosenMission"
      :chosenSiteParam = "chosenSite"
      @close="handleCloseImportModal($event)"
    >
    </ImportEquipementModal>

    <ImportInspectionModal
      :show="showImportInspectionSheet"
      :inspectionType="inspectionType"
      @close="handleCloseImportInspectionModal($event)"
    >
    </ImportInspectionModal>
    <BottomSheetAddNiveau
        v-if="showAddNiveauSheet"
        :sheet="showAddNiveauSheet"
        @close-sheet="showAddNiveauSheet = false"
        :batimentId="selectedBatiment.id"
      >
    </BottomSheetAddNiveau>

    <BottomSheetEditItem
      v-if="showEditSheet"
      :sheet="showEditSheet"
      :item="selectedItem"
      :typeItem="selectedItemType"
      :interventions="interventions"
      :equipements="getEquipementByDomaine"
      @close-sheet="handleClose()"
    >
    </BottomSheetEditItem>

    <BottomSheetAddItem
      v-if="showDuplicate"
        :sheet="showDuplicate"
        :defaultItem="itemDuplicated"
        :typeItem="selectedItemType"
        @close-sheet="showDuplicate = false"
    >
    </BottomSheetAddItem>

    <BottomSheetAddIntervention
      v-if="addInter"
      :sheet="addInter"
      :idAddItem="idAddInter"
      :typeAddItem="typeAddInter"
      @close-sheet="addInter = false"
    >
    </BottomSheetAddIntervention>

    <DialogDeleteConfirmation
      v-if="showDeleteDialog"
      :dialog="showDeleteDialog"
      :item="selectedItem"
      :items="checkedEquipements"
      :multiple="isMultiple"
      @delete-item="deleteItem()"
      @delete-items="deleteItems()"
      @close-dialog="showDeleteDialog = false"
    >
    </DialogDeleteConfirmation>

    <DialogPerformanceVetuste
      :dialog="dialogPerfVet"
      :item="perfOrVest"
      :selectedEquipement="selectedEquipement"
      @close-dialog="dialogPerfVet"
      @closeDialogPerfVet="dialogPerfVet = false">
    </DialogPerformanceVetuste>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';
import NiveauxList from '../components/NiveauxList.vue';
import EquipementsList from '../components/EquipementsList.vue';
import InspectionsList from '../components/InspectionsList.vue';
import BottomSheetAddEquipement from '../components/BottomSheetAddEquipement.vue';
import BottomSheetAddInspection from '../components/inspections/BottomSheetAddInspection.vue';
import ImportEquipementModal from '../components/equipements/ImportEquipementModal.vue';
import ImportInspectionModal from '../components/inspections/ImportInspectionModal.vue';
import BottomSheetAddNiveau from '../components/BottomSheetAddNiveau.vue';
import BottomSheetEditItem from '../components/BottomSheetEditItem.vue';
import DialogDeleteConfirmation from '../components/DialogDeleteConfirmation.vue';
import BottomSheetAddItem from '../components/BottomSheetAddItem.vue';
import BaseFab from '../components/BaseFab.vue';
import DialogPerformanceVetuste from '../components/DialogPerformanceVetuste.vue';
import BottomSheetAddIntervention from '../components/interventions/BottomSheetAddIntervention.vue';

export default {
  components: {
    NiveauxList,
    EquipementsList,
    InspectionsList,
    BottomSheetAddEquipement,
    BottomSheetAddInspection,
    ImportEquipementModal,
    ImportInspectionModal,
    BottomSheetAddNiveau,
    BottomSheetEditItem,
    DialogDeleteConfirmation,
    BottomSheetAddItem,
    BaseFab,
    DialogPerformanceVetuste,
    BottomSheetAddIntervention,
  },
  mixins: [
    UpdateItemAlertMixin,
  ],
  props: ['batimentId'],
  data() {
    return {
      chosenClient: null,
      chosenMission: null,
      chosenSite: null,
      clearItem: false,
      fabLoading: false,
      loading: false,
      loadingEquipementList: false,
      defaultVue: true,
      typeAddInter: null,
      idAddInter: null,
      addInter: false,
      selectedEquipement: null,
      dialogPerfVet: false,
      perfOrVest: null,
      currentTab: 1,
      NIVEAUX_TAB: 0,
      EQUIPEMENTS_TAB: 1,
      INSPECTIONS_TAB: 2,
      fromMission: false,
      showEquipementSheet: false,
      showInspectionSheet: false,
      showImportEquipementSheet: false,
      showImportInspectionSheet: false,
      showFabInspection: false,
      showAddNiveauSheet: false,
      showEditSheet: false,
      showDeleteDialog: false,
      showDuplicate: false,
      equipements: [],
      inspectionType: 'batiments',
      isMultiple: false,
      isProcessing: false,
      checkedEquipements: [],
      equipementFabOptions: [
        {
          icon: 'mdi-arrow-down-bold',
          name: 'importEquipementsBiblio',
          tooltip: 'Importer modèle',
          color: 'red',
          disable: false,
        },
        {
          icon: 'mdi-arrow-down-bold',
          name: 'importEquipements',
          tooltip: 'Importer des équipements',
          color: 'yellow',
          disable: false,
        },
        {
          icon: 'mdi-toolbox',
          name: 'createEquipement',
          tooltip: 'Ajouter un équipement',
          color: 'green',
          disable: false,
        },
      ],
      inspestionFabOptions: [
        {
          icon: 'mdi-arrow-down-bold',
          name: 'importInspections',
          tooltip: 'Importer des inspections',
          color: 'yellow',
          disable: false,
        },
        {
          icon: 'mdi-toolbox',
          name: 'createInspection',
          tooltip: 'Ajouter une Inspection',
          color: 'green',
          disable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      selectedItemType: state => state.selectedItemType,
    }),
    ...mapState('equipements', { equipementTypes: 'types' }),
    ...mapState('interventions', ['interventions']),
    ...mapGetters({
      batimentEquipements: 'equipements/selectedBatimentEquipements',
      selectedItem: 'selectedItem',
      selectedBatiment: 'batiments/selected',
      selectedMission: 'missions/selected',
    }),
    selectedView: {
      get() {
        return this.$store.state.selectedView;
      },
      set(value) {
        this.$store.commit('setSelectedView', value);
      },
    },
    itemDuplicated() {
      let itemDupli = {};
      if (this.selectedItem) {
        itemDupli = { ...this.selectedItem };
        itemDupli.nom = `${itemDupli.nom}_copie`;
        if (this.selectedItemType === 'equipements') {
          itemDupli.local = '';
          itemDupli.niveau = '';
          itemDupli.images = [];
        }
      }
      return itemDupli;
    },
    getEquipementByDomaine() {
      return this.equipements;
    },
    selectedItemId() {
      if (this.selectedItem) {
        return this.selectedItem.id;
      }
      return '';
    },
  },
  async created() {
    this.selectedView = 'Collecter';
    this.loading = true;
    if (this.selectedMission && this.selectedMission.siteModele) {
      this.selectedSiteModel = await this.$store.dispatch('sites/getById', this.selectedMission.siteModele);
      const missionModele = await this.$store.dispatch('missions/getById', this.selectedSiteModel.mission);
      const clientModele = await this.$store.dispatch('clients/refreshItem', missionModele.client);
      this.chosenClient = clientModele.id;
      this.chosenMission = missionModele.id;
      this.chosenSite = this.selectedMission.siteModele;
    }

    await this.$store.dispatch('changeSelectedItem', { type: 'batiments', id: this.batimentId });
    if (!this.batimentEquipements.length) {
      await this.$store.dispatch('equipements/fetchAllByBatiment', this.selectedBatiment.id);
    }
    await this.$store.dispatch('interventions/fetchAllByBatiment', this.batimentId);
    this.loading = false;
  },
  methods: {
    showDialogPerfVet(event) {
      if (event.itemType === 'performance') {
        this.dialogPerfVet = true;
        this.selectedEquipement = event.selectedEquipement;
        this.perfOrVest = 'performance';
      } else if (event.itemType === 'vetuste') {
        this.dialogPerfVet = true;
        this.selectedEquipement = event.selectedEquipement;
        this.perfOrVest = 'vetuste';
      }
    },
    fabButtonLoading(loading) {
      this.fabLoading = loading;
    },
    async handlerFabButton(option) {
      this.clearItem = !this.clearItem;
      switch (option) {
        case 'createEquipement':
          this.showEquipementSheet = true;
          break;
        case 'importEquipements':
          this.fromMission = false;
          this.showImportEquipementSheet = true;
          break;
        case 'importEquipementsBiblio':
          this.fromMission = true;
          this.showImportEquipementSheet = true;
          break;
        case 'importInspections':
          this.showImportInspectionSheet = true;
          break;
        case 'createInspection':
          this.showInspectionSheet = true;
          break;
        default:
          this.$store.dispatch('snackbar/displaySnack', {
            message: 'option non reconnue',
            type: 'error',
          });
      }
    },
    handlerDisable() {
      if (!navigator.onLine) {
        // this.equipementFabOptions[0].disable = true;
        this.equipementFabOptions[1].disable = true;
        this.inspestionFabOptions[0].disable = true;
      }
    },
    addItem() {
      if (this.currentTab === this.NIVEAUX_TAB && !this.showAddNiveauSheet) {
        this.showAddNiveauSheet = true;
      }
    },
    handleCreateInter(itemType, itemId) {
      this.addInter = true;
      this.idAddInter = itemId;
      this.typeAddInter = itemType;
    },
    async handleEditEquipement(itemType, item) {
      await this.$store.dispatch('formTemplates/fetchEquipementTemplate', {
        type: item.type,
        missionId: this.selectedMission.id,
      });
      if (
        itemType === this.selectedItemType
        && item.id === this.selectedItemId
      ) {
        this.showEditSheet = true;
      }
    },

    handleEdit(itemType, itemId) {
      if (
        itemType === this.selectedItemType
        && itemId === this.selectedItemId
      ) {
        this.showEditSheet = true;
      }
    },
    handleDelete(itemType, itemId) {
      if (
        itemType === this.selectedItemType
        && itemId === this.selectedItemId
      ) {
        this.isMultiple = false;
        this.showDeleteDialog = true;
      }
    },
    async handleDeletes(checkedEquipements) {
      this.checkedEquipements = checkedEquipements;
      if (this.checkedEquipements.length > 1) {
        this.isMultiple = true;
        this.showDeleteDialog = true;
      } else {
        this.isMultiple = false;
        await this.$store.dispatch('changeSelectedItem', { type: 'equipements', id: this.checkedEquipements[0] });
        this.handleDelete('equipements', this.checkedEquipements[0]);
      }
    },
    handleShowFab(showFab) {
      this.showFabInspection = showFab;
    },
    handleClose() {
      this.showEditSheet = false;
    },
    handleDuplicate(itemType, item) {
      if (

        itemType === this.selectedItemType
        && item.id === this.selectedItemId
      ) {
        this.showDuplicate = true;
      }
    },

    handleLinked(equipments) {
      this.equipements = equipments;
    },
    async deleteItem() {
      if (this.selectedItemType === 'equipements') {
        await this.$store.dispatch('equipements/delete', this.selectedItem.id);
        await this.majBatimentAlert(this.selectedBatiment.id, 'equipements').then(async () => {
          await this.$store.dispatch('batiments/refreshItem', this.selectedBatiment.id).then(() => {
            this.majBatimentAlert(this.selectedBatiment.id, 'interventions');
            this.checkedEquipements = [];
          });
        });
      } else if (this.selectedItemType === 'niveaux') {
        await this.$store.dispatch('niveaux/delete', this.selectedItem);
      } else if (this.selectedItemType === 'inspections') {
        await this.$store.dispatch('inspections/delete', this.selectedItem);
        await this.majBatimentAlert(this.selectedBatiment.id, 'inspections');
      }
    },

    async deleteItems() {
      const deletePromises = [];
      this.isProcessing = true;
      for (let i = 0; i < this.checkedEquipements.length; i += 1) {
        deletePromises.push(this.$store.dispatch('equipements/delete', this.checkedEquipements[i]));
      }
      await Promise.all(deletePromises)
        .then(async () => {
          this.$store.dispatch('snackbar/displaySnack',
            { message: 'Les équipements ont été supprimé avec succès !', type: 'succes' });
          await this.majBatimentAlert(this.selectedBatiment.id, 'equipements').then(async () => {
            await this.$store.dispatch('batiments/refreshItem', this.selectedBatiment.id).then(() => {
              this.majBatimentAlert(this.selectedBatiment.id, 'interventions');
            });
          });
          this.checkedEquipements = [];
          this.isProcessing = false;
        });
    },
    async duplicateItems(checkedEquipements) {
      try {
        this.isProcessing = true;
        this.checkedEquipements = checkedEquipements;
        const equipementsToDuplicate = [];
        const createPromises = this.checkedEquipements.map(async (equipement) => {
          const getEquipement = await this.batimentEquipements.find(
            eqt => eqt.id === equipement,
          );
          equipementsToDuplicate.push(getEquipement);
          const eqttype = this.equipementTypes.find(eqt => eqt.id === getEquipement.type);
          return this.$store.dispatch('equipements/createEquipementOnBatiment', {
            batimentId: this.selectedBatiment.id,
            equipementType: eqttype,
          });
        });
        const newItems = await Promise.all(createPromises);
        const updatePromises = newItems.map((newItem, index) => {
          // Create a copy of the equipementsToDuplicate[index] object without the 'id' property
          const updatedData = Object.assign({}, equipementsToDuplicate[index]);
          delete updatedData.id;
          delete updatedData._id;
          delete updatedData._rev;
          delete updatedData.imagePrincipale;
          delete updatedData.calculations;
          delete updatedData.referentielValues;
          return this.$store.dispatch('equipements/save', {
            id: newItem.id,
            data: {
              ...updatedData,
              nom: `${equipementsToDuplicate[index].nom}_copie`,
              image: [],
            },
          });
        });
        await Promise.all(updatePromises);
        await this.$store.dispatch('selectDomaine', '');
        await this.$store.dispatch('selectCategorie', '');
        await this.$store.dispatch('selectType', '');
        await this.majBatimentAlert(this.selectedBatiment.id, 'equipements');
        this.checkedEquipements = [];
        this.$store.dispatch('snackbar/displaySnack',
          { message: 'copié avec succès !', type: 'succes' });
      } catch (error) {
        this.$store.dispatch('snackbar/displaySnack',
          { message: 'Une erreur est survenue', type: 'error' });
      } finally {
        this.isProcessing = false;
      }
    },

    async handleCloseImportModal(equipement) {
      this.showImportEquipementSheet = false;
      // if the import is a succès, automaticaly open edit modal for the last equipement created
      if (equipement && equipement.id) {
        await this.$store.dispatch('changeSelectedItem', { type: 'equipements', id: equipement.id });
        this.handleEditEquipement('equipements', equipement);
      }
    },
    async handleCloseImportInspectionModal(equipement) {
      this.showImportInspectionSheet = false;
      // if the import is a succès, automaticaly open edit modal for the last equipement created
      if (equipement && equipement.id) {
        await this.$store.dispatch('changeSelectedItem', { type: 'equipements', id: equipement.id });
        this.handleEditEquipement('equipements', equipement);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  z-index:100 !important;
}
.collec-view-wrapper {
  height: 100%;

    .no-data {
      height: 100%;
      padding: 20%  1rem 0 1rem;

      .view-loader {
        height: 100%;

        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
      }
    }
}

.collect-view-tab-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;

  width: 100%;
  height: 100%;
}

.collec-tab-container {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0px;

  .collect-tab-content {
    height: 100%;
    background-color: $app-grey;
    overflow: auto;
  }
}

.collect-tab-bar {
  flex: 0 0 auto;
}

.fab-add-button {
  position: absolute;
  bottom: 4rem;
  right: 3rem;
}

.alert-icon {
  color:orange !important;
  margin-right:8px;
}
</style>
