<template>
<div>
  <v-list v-if="compteurs && compteurs.length">
    <template
      v-for="(compteur, i) in compteurs"
    >
      <v-list-item
        ripple
        :key="`compteur${i}`"
        @click.native="$emit('compteur-clicked', compteur)"
      >
        <v-list-item-content>
          <v-list-item-title class="pointer">
              {{ compteur.nomCompteur }} - {{ compteur.energieLibelle }}</v-list-item-title>
          <v-list-item-subtitle class="pointer">
             {{ compteur.numeroCompteur }}</v-list-item-subtitle>
          <v-list-item-subtitle  class="pointer">
            {{ compteur.adresse }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-divider
        v-if="i + 1 < compteurs.length"
        :key="`dvd_${i}`"
        class="compteur-list-divider"
      ></v-divider>
    </template>
  </v-list>
</div>
</template>

<script>

export default {
  props: ['compteurs'],
};
</script>

<style lang="scss" scoped>
  .compteur-list-divider {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }
  .pointer{
   cursor: pointer;
  }
</style>
