/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  auditTypesEndpoint,
} from './apiUrls';

export default {
  db: new PouchDB('audit-types'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch
    const _id = obj._id || obj.id.toString();

    return {
      _id,
      meta: obj.meta || {},
      id: String(obj.id),
      code: obj.code,
      nom: obj.nom,
      afficher: obj.afficher,
    };
  },

  // create(auditType) {
  //   return new Promise((resolve, reject) => {
  //     axiosInstance.axiosInstance.post(auditTypesEndpoint, auditType)
  //       .then((response) => {
  //         resolve(this.convertBackendObject(response.data));
  //       })
  //       .catch((error) => { reject(error); });
  //   });
  // },

  // update(auditTypeId, auditType) {
  //   return new Promise((resolve, reject) => {
  //     axiosInstance.axiosInstance.put(`${auditTypesEndpoint}/${auditTypeId}`, auditType)
  //       .then((response) => {
  //         resolve(this.convertBackendObject(response.data));
  //       })
  //       .catch((error) => { reject(error); });
  //   });
  // },

  // delete(auditTypeId) {
  //   return axiosInstance.axiosInstance.delete(`${auditTypesEndpoint}/${auditTypeId}`);
  // },

  /**
   * get one auditType by his id
   * @param {String} auditTypeId - the id of the auditType to get
   */
  async get(auditTypeId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${auditTypesEndpoint}/${auditTypeId}`);
      const auditType = this.convertBackendObject(response.data);

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDoc(auditType, this.db);
    } catch (e) {
      console.error('auditTypesApi get error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getDoc(auditTypeId, this.db);
    }
  },

  /**
   * get all auditTypes of the application
   */
  async getAll() {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${auditTypesEndpoint}`);
      const auditTypes = response.data.map(auditType => this.convertBackendObject(auditType));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(auditTypes, this.db);
    } catch (e) {
      console.error('auditTypesApi getAll error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocs(this.db);
    }
  },

  /**
   * get all auditTypes of the application for offline use
   */
  async fetchOffline() {
    const response = await axiosInstance.axiosInstance.get(`${auditTypesEndpoint}`);
    const auditTypes = response.data.map(auditType => this.convertBackendObject(auditType));
    // if get is successful,  store data into pouchdb and return it
    return pouchUtil.addDocs(auditTypes, this.db);
  },

  /**
   * Fetch all auditTypes stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocs(this.db);
  },
};
