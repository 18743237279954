import api from '@/api';

/* eslint no-console: ["error", { allow: ["error", "log"] }] */
/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = {
  me: '',
};

const getters = {
  me: state => state.me,
};

const actions = {

  /**
   * Fetch all clients from the backend and update the store
   */
  getMe({ commit }) {
    return api.me.get().then((response) => {
      commit('setMe', response);
    });
  },

  clearMe({ commit }) {
    return api.me.destroy().then(() => {
      commit('clearMe');
    });
  },
  /**
   * Load all clients stored offline in pouchDB
   */
  loadOffline({ commit }) {
    return api.me.loadOffline().then(response => commit('setMe', response));
  },

};

const mutations = {
  setMe(state, me) {
    state.me = me;
  },

  clearMe() {
    state.me = null;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
