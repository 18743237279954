<template>
  <ValidationProvider
    :name="formField.name"
    :rules="formField.rules"
    v-slot="{ errors }">

    <v-autocomplete
      :id="`select_${id}`"
      :items="entries"
      v-bind="fieldOptions"
      :error-messages="errors"
      v-model="localValue"
      @change="$emit('change', localValue)"
      :loading="loading"
      attach
    >

      <template v-slot:label>
        <slot name="field-label"></slot>
      </template>
    </v-autocomplete>

  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import axiosInstance from '../../plugins/axios';

export default {
  name: 'type-usage-field',
  components: {
    ValidationProvider,
  },
  props: {
    commonOptions: Object,
    defaultValue: null,
    formField: Object,
  },
  data() {
    return {
      localValue: this.defaultValue,
      entries: [],
      loading: false,
      storeObject: false,
      id: 0,
    };
  },
  computed: {
    fieldOptions() {
      return {
        ...this.commonOptions,
        placeholder: this.formField.placeholder,
        itemText: 'libelle',
        itemValue: 'codeOption',
        returnObject: this.storeObject,
      };
    },
    typeUsage() {
      return this.$store.state.currentForm.typeUsage;
    },
  },
  watch: {
    defaultValue(newValue) {
      this.localValue = newValue;
    },
    typeUsage() {
      this.updateListOption();
    },
  },
  created() {
    this.updateListOption();
  },
  methods: {
    updateListOption() {
      this.loading = true;
      axiosInstance.axiosInstance.get('/api/ref/liste-options?codeType=ref_typeusage').then((response) => {
        const result = response.data;
        let trouve = false;
        let i = 0;
        while (i < result.length && !trouve) {
          if (result[i].codeOption === this.$store.state.currentForm.typeUsage) {
            trouve = true;
          } else {
            i += 1;
          }
        }

        if (i > 0 && trouve === true) {
          if (result[i].data.usageDetaille[0] !== '') {
            this.entries = result[i].data.usageDetaille;
          } else {
            this.entries = [];
          }
        }
        this.loading = false;
      });
    },
  },
};
</script>
