<template>
  <v-bottom-sheet
    v-model="sheet"
    fullscreen
  >
    <v-sheet class="sheet-wrapper">
      <h1>Ajout d'un nouveau niveau</h1>

      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-form v-if="sheet">
          <form-builder
            :item="defaultItem"
            :itemType="'niveaux'"
            :key="formBuilderKey"
            onlyRequired
            class="form-wrapper"
          >
          </form-builder>
        </v-form>


      <div class="bottom-sheet-footer">
        <v-btn
          class="action-button"
          text
          large
          color="primary"
          @click="cancel()"
        >
          Annuler
        </v-btn>

        <v-btn
          class="action-button"
          depressed
          large
          color="primary"
          @click.native="saveForm()"
          :disabled="invalid"
          :loading="saving"
        >
          Créer
        </v-btn>
      </div>
      </ValidationObserver>
    </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';

import FormBuilder from './form-builder.vue';

export default {
  components: {
    FormBuilder,
    ValidationObserver,
  },

  props: {
    sheet: { type: Boolean },
    defaultItem: { type: Object },
    batimentId: { type: String },
  },

  data() {
    return {
      saving: false,
      formBuilderKey: 0,
    };
  },

  computed: {
    ...mapState(['currentForm']),
    ...mapGetters({
      selectedBatiment: 'batiments/selected',
    }),
  },

  watch: {
    sheet(newValue, oldValue) {
      // on sheet oppening
      if (newValue && !oldValue) {
        // this.$store.dispatch('clearSelectedItem');
        // setTimeout(() => { this.$store.dispatch('clearCurrentForm'); }, 2000);
        // 500ms delay to remove after collect rework
        this.$store.dispatch('clearCurrentForm');
        this.formBuilderKey += 1;
      }
    },
  },

  methods: {
    saveForm() {
      this.saving = true;

      // convert all form fields to string
      const formCopy = JSON.parse(JSON.stringify(this.currentForm));

      this.$store.dispatch('niveaux/createNiveauOnBatiment', this.batimentId).then((newItem) => {
        this.$store.dispatch(
          'niveaux/save',
          {
            id: newItem.id,
            data: {
              ...formCopy,
              batiment: newItem.batiment,
            },
          },
        ).then((result) => {
          this.$store.dispatch('snackbar/displaySnack', { message: 'créé avec succès !', type: 'succes' });
          this.$emit('close-sheet');
          this.$store.dispatch('changeSelectedItem', { id: result.id, type: 'niveaux' });
        }).finally(() => {
          this.saving = false;
        });
      });
    },

    cancel() {
      this.$emit('close-sheet');
      this.$store.dispatch('changeSelectedItem', { id: this.selectedBatiment.id, type: 'batiments' });
    },
  },
};
</script>

<style lang="scss" scoped>
  .sheet-wrapper {
    padding: 1rem;
    height: 100%;
    overflow: auto;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .bottom-sheet-footer {
    width: 100%;
    height: 3rem;
    margin-bottom: 0;
    margin-top: auto;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
  }

  .action-button {
    margin-right: 1rem;
  }

  .form-wrapper {
    width: 23rem;
    max-width: 23rem;
  }
</style>
