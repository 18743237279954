import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      allBatiments: state => state.batiments.batiments,
      allEquipements: state => state.equipements.equipements,
      allInspections: state => state.inspections.inspections,
      allInterventions: state => state.interventions.interventions,
      allCompteurs: state => state.compteurs.compteurs,
      allRepartitions: state => state.repartitions.repartitions,
    }),
  },

  methods: {
    async majBatimentAlert(batimentId, typeItem) {
      const batiment = await this.$store.dispatch('batiments/getById', batimentId);
      const alertBati = batiment.inAlert;
      const before = this.loopAlert(alertBati);
      const payload = { };
      let findAlert = false;

      if (typeItem === 'equipements') {
        const listItem = await this.allEquipements.filter(eqp => eqp.batiment === batimentId
          && eqp.inAlert === true);

        if (listItem.length) {
          findAlert = true;
        }
        // populate data to save
        payload.data = {
          ...batiment,
          inAlert: {
            ...alertBati,
            inAlertCollectEquipement: findAlert,
          },
        };
      } else if (typeItem === 'inspections') {
        const listItem = await this.allInspections.filter(insp => insp.batiment === batimentId
          && insp.inAlert === true);

        if (listItem.length) {
          findAlert = true;
        }

        // populate data to save
        payload.data = {
          ...batiment,
          inAlert: {
            ...alertBati,
            inAlertCollectInspection: findAlert,
          },
        };
      } else if (typeItem === 'interventions') {
        const listItem = await this.allInterventions.filter(inter => inter.batiment === batimentId
          && inter.inAlert === true);

        if (listItem.length) {
          findAlert = true;
        }

        // populate data to save
        payload.data = {
          ...batiment,
          inAlert: {
            ...alertBati,
            inAlertIntervention: findAlert,
          },
        };
      } else if (typeItem === 'repartitions') {
        const listItem = await this.allRepartitions.filter(repart => repart.batiment === batimentId
          && repart.inAlert === true);

        if (listItem.length) {
          findAlert = true;
        }

        // populate data to save
        payload.data = {
          ...batiment,
          inAlert: {
            ...alertBati,
            inAlertRepartition: findAlert,
          },
        };
      }

      this.$store.dispatch(
        'batiments/save',
        {
          id: batimentId,
          ...payload,
        },
      ).then(async () => {
        await this.$store.dispatch('batiments/refreshItem', batiment.id).then((response) => {
          if (before !== this.loopAlert(response.inAlert)) {
            this.majSiteAlert(response.site, 'batiments');
          }
        });
      });
    },

    loopAlert(alerts) {
      let findAlert = false;
      // eslint-disable-next-line no-unused-vars
      Object.entries(alerts).forEach(([key, value]) => {
        if (value) {
          findAlert = true;
        }
      });
      return findAlert;
    },

    async majSiteAlert(siteId, typeItem) {
      const site = await this.$store.dispatch('sites/getById', siteId);
      const payload = { };
      let findAlert = false;
      if (typeItem === 'batiments') {
        const listItem = await this.allBatiments.filter(bat => bat.site === siteId
          && this.loopAlert(bat.inAlert) === true);

        if (listItem.length) {
          findAlert = true;
        }
        const alertSIte = site.inAlert;
        // populate data to save
        payload.data = {
          ...site,
          inAlert: {
            ...alertSIte,
            inAlertBatiment: findAlert,
          },
        };
      } else if (typeItem === 'inspections') {
        const listItem = await this.allInspections.filter(insp => insp.site === siteId
          && !insp.batiment && insp.inAlert === true);
        if (listItem.length) {
          findAlert = true;
        }

        const alertSite = site.inAlert;
        // populate data to save
        payload.data = {
          ...site,
          inAlert: {
            ...alertSite,
            inAlertCollectInspection: findAlert,
          },
        };
      }

      this.$store.dispatch(
        'sites/save',
        {
          id: siteId,
          ...payload,
        },
      ).then(() => {
        this.$store.dispatch('sites/refreshItem', siteId);
      });
    },
  },
};
