export const apiUrl = '/api';
export const refUrl = '/ref';


export const batimentsUrl = '/batiments';
export const batimentsEndpoint = `${apiUrl}${batimentsUrl}`;

export const clientsUrl = '/clients';
export const clientsEndpoint = `${apiUrl}${clientsUrl}`;

export const patrimoinesUrl = '/patrimoines';
export const patrimoinesEndpoint = `${apiUrl}${patrimoinesUrl}`;

export const planActionsUrl = '/plan-actions';
export const planActionsEndpoint = `${apiUrl}${planActionsUrl}`;

export const compteursUrl = '/compteurs';
export const compteursEndpoint = `${apiUrl}${compteursUrl}`;

export const equipementsUrl = '/equipements';
export const equipementsEndpoint = `${apiUrl}${equipementsUrl}`;

export const interventionsUrl = '/interventions';
export const interventionsEndpoint = `${apiUrl}${interventionsUrl}`;

export const interventionSitesUrl = '/intervention-sites';
export const interventionSitesEndpoint = `${apiUrl}${interventionSitesUrl}`;

// export const formTemplates = `${apiUrl}/compteurs`;


export const missionsUrl = '/missions';
export const missionsEndpoint = `${apiUrl}${missionsUrl}`;

export const niveauxUrl = '/niveaux';
export const niveauxEndpoint = `${apiUrl}${niveauxUrl}`;

export const repartitionsUrl = '/repartition-compteur-batiments';
export const repartitionsEndpoint = `${apiUrl}${repartitionsUrl}`;

export const sitesUrl = '/sites';
export const sitesEndpoint = `${apiUrl}${sitesUrl}`;

export const rapportStructuresEndpoint = `${apiUrl}/rapport-structures`;
export const facturesEndpoint = `${apiUrl}/factures`;

export const factureUrl = '/factures';
export const factureEndpoint = `${apiUrl}${factureUrl}`;

export const auditTypesEndpoint = `${apiUrl}${refUrl}/audit-types`;
export const equipementDomainesUrl = '/equipement-domaines';
export const equipementCategoriesUrl = '/equipement-categories';
export const equipementTypesUrl = '/equipement-types';

export const listeOptionsUrl = '/liste-options';

export const inspectionsUrl = '/inspections';
export const inspectionsEndpoint = `${apiUrl}${inspectionsUrl}`;
export const inspectionTypesEndpoint = `${apiUrl}${refUrl}/inspection-types`;

export const mediaUrl = '/media';
export const mediaEndpoint = `${apiUrl}${mediaUrl}`;

export const scenarioUrl = '/scenarios';
export const scenariosEndpoint = `${apiUrl}${scenarioUrl}`;

export const programmesUrl = '/programmes';
export const programmesEndpoint = `${apiUrl}${programmesUrl}`;

export const imageCataloguesUrl = '/image-catalogues';
export const imageCataloguesEndpoint = `${apiUrl}${imageCataloguesUrl}`;

export const meUrl = '/users/me';
export const meEndpoint = `${apiUrl}${meUrl}`;
