<template>
    <!-- Button to detect click and have ripple -->
    <div>
      <!-- image to display inside the button -->
      <v-img
        class="picture-object"
        height="200"
        contain
        :src="url"
        @click.native="takePicture()"
      >
        <!-- centered loader -->
        <template v-slot:placeholder>
          <div class="wrapper-center">
            <v-progress-circular v-if="loading" indeterminate color="primary"></v-progress-circular>
          </div>
        </template>
      </v-img>

      <!-- invisible input to handle file upload -->
      <input
        ref="inputFile"
        type=file
        accept="image/*"
        style="display: none;"
        @change="handleFileChange($event);"
      />
    </div>
</template>

<script>
import ImageResizeMixin from '../mixins/ImageResizeMixin';

export default {
  mixins: [
    ImageResizeMixin,
  ],
  data() {
    return {
      loading: true,
      defaultImage: './img/icons/method-draw-image.svg',
    };
  },
  props: {
    defaultValue: null,
  },
  computed: {

    url() {
      return this.defaultValue ? `${this.defaultValue}/file` : this.defaultImage;
    },
  },
  methods: {
    takePicture() {
      this.$refs.inputFile.click();
    },

    handleFileChange(evt) {
      const file = evt.target.files[0];
      this.resizeImageMx(file, 1200, 1200).then((image) => {
        this.loading = false;
        this.$emit('new-image', image);
        this.defaultImage = URL.createObjectURL(image);
        this.defaultValue = null;
      });
    },
  },
};
</script>

<style scoped>
.picture-frame {
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  /* margin-bottom: 2rem; */
}

.picture-object {
  border-radius: 5px;
}

.wrapper-center {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
