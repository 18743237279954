<template>
  <div class="inner-shutter-wrapper">
    <v-progress-linear
      :active="refreshGains"
      :indeterminate="refreshGains"
    >
    </v-progress-linear>

    <!-- FOOTER -->
    <div class="footer">
      <v-btn
        color="primary"
        raised
        @click="quit()"
      >
        QUITTER
      </v-btn>
    </div>
    <v-divider></v-divider>
    <!-- GAINS ENERGETIQUES -->
    <div class="bloc" v-if="this.sourceType === 'equipement'">
      <div class="title-icon">
        <v-icon class="icon" color="primary">mdi-lightning-bolt-circle</v-icon>
        <h2 class="title"> GAINS ÉNERGETIQUES</h2>
      </div>

      <!-- GAINS € -->
      <div class="section-data">
        <div class="featured-data">
          <div class="featured-text">{{parseFloat(this.featuredInter.calculations.gainCoutTtc).
            toLocaleString('fr')}}</div> € TTC
        </div>
        <div class="info-data">
          {{parseFloat(this.featuredInter.calculations.gainCoutTtcRelatif).
          toLocaleString('fr')}} %
        </div>
      </div>

      <!-- ENERGIE EF (Energie Finale) -->
      <div class="section-data">
        <div class="featured-data">
          <div class="featured-text">
            {{parseFloat(this.featuredInter.calculations.gainConsommationEf).
            toLocaleString('fr')}}
          </div> kWhEF
        </div>
        <div class="info-data">
          {{parseFloat(this.featuredInter.calculations.gainConsommationEfRelatif).
          toLocaleString('fr')}} %
        </div>
      </div>

      <!-- ENERGIE EP (Energie Primaire) -->
      <div class="section-data">
        <div class="featured-data">
          <div class="featured-text">
            {{parseFloat(this.featuredInter.calculations.gainConsommationEp).
            toLocaleString('fr')}}
          </div> kWhEP
        </div>
        <div class="info-data">
          {{parseFloat(this.featuredInter.calculations.gainConsommationEpRelatif).
          toLocaleString('fr')}} %
        </div>
      </div>

      <!-- CO2 -->
      <div class="section-data">
        <div class="featured-data">
          <div class="featured-text">{{parseFloat(this.featuredInter.calculations.gainEmissionCo2).
            toLocaleString('fr')}}</div> kgCO2
        </div>
        <div class="info-data">
          {{parseFloat(this.featuredInter.calculations.gainEmissionCo2Relatif).
          toLocaleString('fr')}} %
        </div>
      </div>
    </div>

    <!-- INVESTISSEMENTS -->
    <v-divider></v-divider>
    <div class="bloc">
      <div class="title-icon">
        <v-icon class="icon" color="primary">mdi-currency-eur</v-icon>
        <h2 class="title">INVESTISSEMENTS</h2>
      </div>

      <div class="section-data">
        <div class="featured-data">
          <div class="featured-text">
            {{parseFloat(this.featuredInter.calculations.investissementHt).
            toLocaleString('fr') }}
          </div> €HT
        </div>
      </div>
    </div>
    <!-- TRI -->
    <v-divider></v-divider>
    <div class="bloc">
      <div class="title-icon">
        <v-icon class="icon" color="primary">mdi-clock-fast</v-icon>
        <h2 class="title">TRI</h2>
      </div>

      <!-- TRI BRUT -->
      <div
       class="section-data"
       v-if="this.publicGains"
      >
        <div class="featured-data">
          <div class="featured-text">
            {{parseFloat(this.featuredInter.calculations.tempsRetourInvestissementBrut).
            toLocaleString('fr')}}
          </div> an(s)
        </div>
        <div class="info-data">
          brut
        </div>
      </div>

      <!-- TRI ACTUALISE -->
      <div
        class="section-data"
        v-if="this.publicGains"
      >
        <div class="featured-data">
          <div class="featured-text">
            {{
              parseFloat(this.featuredInter.calculations.
              tempsRetourInvestissementInterventionActualise).
            toLocaleString('fr')}}
          </div>an(s)
        </div>
        <div class="info-data">
          actualisé
        </div>
      </div>

      <!-- SI PAS DE GAINS -->
      <div v-else>Pas de gain attendu par l'intervention</div>

    </div>
    <v-divider></v-divider>
    <!-- SUBVENTIONS CEE -->
    <div class="bloc">
      <div class="title-icon">
        <v-icon class="icon" color="primary">mdi-cash</v-icon>
        <h2 class="title">SUBVENTIONS / CEE</h2>
      </div>

      <!-- Montant -->
      <div
       class="section-data"
        v-if="getCasIntervention === 8"
      >
        <div class="featured-data">
          <div class="featured-text">
            {{parseFloat(this.featuredInter.calculations.subventionHt).
            toLocaleString('fr')}}
          </div>€
        </div>
        <div class="info-data">
          Montant sub.
        </div>
      </div>

      <!-- Investissement -->
      <div
        class="section-data"
        v-if="this.publicGains && getCasIntervention === 8"
      >
        <div class="featured-data">
          <div class="featured-text">
            {{
              parseFloat(this.featuredInter.calculations.
              investissementAvecSubventionHt).
            toLocaleString('fr')}}
          </div>
          €HT
        </div>
        <div class="info-data">
          Investissement avec sub.
        </div>
      </div>
      <!-- VOLUME CEE -->
      <div
       class="section-data"
      >
        <div class="featured-data">
          <div class="featured-text">
            {{parseFloat(this.featuredInter.calculations.volumeCEE).
            toLocaleString('fr')}}
          </div>kWh cumac
        </div>
        <div class="info-data">
          Volume CEE
        </div>
      </div>
      <!-- VALORISATION CEE -->
      <div
       class="section-data"
      >
        <div class="featured-data">
          <div class="featured-text">
            {{parseFloat(this.featuredInter.calculations.ValorisationCEE).
            toLocaleString('fr')}}
          </div>€
        </div>
        <div class="info-data">
          Valorisation CEE
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
  props: ['intervention', 'refreshGains', 'sourceType'],

  computed: {
    ...mapState('interventions', ['interventions']),

    featuredInter() {
      if (this.interventions) {
        return this.interventions.find(i => i.id === this.intervention.id);
      }
      return this.intervention;
    },

    getCasIntervention() {
      if (this.intervention.referentielValues) {
        return parseInt(this.intervention.referentielValues.option_casIntervention, 10);
      }
      return null;
    },

    publicGains() {
      if (this.featuredInter
      && this.featuredInter.calculations
      && this.featuredInter.calculations.gainCoutTtc > 0) {
        return true;
      }
      return false;
    },
  },

  methods: {
    quit() {
      this.$emit('quit');
    },
  },
};
</script>
<style lang="scss" scoped>

.title {
  text-decoration: underline;
  text-decoration-color: #2D4388;
}
  .inner-shutter-wrapper{
    text-align: center;
    min-height: 800px;
    margin-bottom: 75px;

  }

  .section-data{
    padding: 0.4rem 1rem 0.4rem 4rem;
    text-align: left;
  }

  .featured-data{
    font-size: medium;
    display: flex;
  }

  .featured-text{
    font-size: x-large;
    margin-right: 5px;
  }

  .info-data{
    font-size: smaller;
  }

  .title-icon{
    display: flex;
  }

  .icon{
    margin-right: 0.4rem;
  }

  .bloc {
    margin:10px 0 10px 0;
  }
.footer {
  margin-bottom: 10px;
}
</style>
