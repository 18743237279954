import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import SynchronizeView from '../views/SynchronizeView.vue';
import PatrimoineDetailView from '../views/PatrimoineDetailView.vue';
import PlanActionsView from '../views/PlanActionsView.vue';

export default [
  {
    path: '/',
    name: 'home',
    meta: {
      libelle: 'SMART DIAG',
      requiresAuth: true,
    },
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { guest: true },
  },
  {
    path: '/patrimoine/:patrimoineId/planActions',
    name: 'planActions',
    meta: {
      libelle: 'selectedPatrimoine',
      requiresAuth: true,
    },
    component: PlanActionsView,
    props: true,
  },
  {
    path: '/synchronize',
    name: 'synchronize',
    meta: {
      libelle: 'SYNCHRONISATION',
      requiresAuth: true,
    },
    component: SynchronizeView,
  },
  {
    path: '*',
    redirect: '/',
  },
  {
    path: '/patrimoine/:patrimoineId',
    name: 'patrimoineDetail',
    meta: {
      libelle: 'selectedPatrimoine',
      requiresAuth: true,
    },
    // component: () => import(/* webpackChunkName:
    // "patrimoines" */ '@/views/PatrimoineDetailView'),
    component: PatrimoineDetailView,
    props: true,
  },
];
