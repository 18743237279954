<template>
  <div class="warning-button" v-if="messagesWarnings.length && !loading">
    <v-badge
      color="#2D4388"
      :content="messagesWarnings.length"
      overlap
      offset-x="18"
      offset-y="18"
    >
      <v-btn
        @click="warning = !warning"
        color="red"
        dark
        fab
      >
        <v-icon v-if="warning">
          mdi-close
        </v-icon>
        <v-icon v-else>
          mdi-alert-outline
        </v-icon>
      </v-btn>
    </v-badge>
    <v-alert
      :value="warning"
      color="red"
      dark
      icon="mdi-alert-outline"
      transition="scale-transition"
      class="warning-div"
    >
      <div class="warning-text" v-for="message in messagesWarnings"
      :key="message">
        {{ message }}
        <hr class="divider">
      </div>
    </v-alert>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState({
      loading: 'loading',
    }),
  },
  props: [
    'messages',
  ],
  data() {
    return {
      warning: false,
      messagesWarnings: [],
    };
  },
  created() {
    this.messagesWarnings = this.messages;
  },
  watch: {
    messages(newvalue) {
      this.messagesWarnings = newvalue;
    },
  },
};
</script>

<style lang="scss" scoped>
  .divider {
    border: none;
    border-top: 2px solid;
    color: white;
    overflow: visible;
    text-align: center;
    height: 5px;
  }
  .warning-div {
    position: fixed;
    right: 5rem;
    z-index: 12;
    top:5rem;
    max-width: 50rem;
  }
  .warning-button {
    position: fixed;
    right: 1rem;
    z-index: 12;
    top: 5rem;
  }
  .warning-text{
    color:white;
    font-size:15px;
    text-align: center;
  }
</style>
