<template>
  <div class="inspections-list-wrapper">
    <div
      class="empty-list-message"
      v-if="!loading && !itemInspections.length"
    >
      Il n'y a pas encore d'inspections pour ce {{nameItem}}.
    </div>
    <div
      v-for="inspection in itemInspections"
      :key="inspection.id">
      <ListItemWithButtons

        :selected="inspection.id === selectedInspectionId"
        noDuplicate
        @click.native="selectInspection(inspection.id)"
        @click-inter="$emit('create-inter', inspection)"
        @click-edit="$emit('edit-inspection', inspection.id)"
        @click-delete="$emit('delete-inspection', inspection.id)"
      >
        <template #title>
          <div class="inspection-item">
            <v-icon
              v-if="checkNecessaryFieldBlank(inspection)"
              color="orange">mdi-alert-circle
            </v-icon>
            <span class="nom-inspection">{{ inspection.nom }}</span>
          </div>
          <span class="type-inspection">type: {{ inspection.nomType }}</span>
        </template>
      </ListItemWithButtons>
    </div>
    <InspectionsCreateDialog
      :show="createDialog"
      :inspectionsMissing="missingInspectionsType"
      :parent="typeItem"
      @close="createDialog = false"
    >
    </InspectionsCreateDialog>

  </div>

</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ListItemWithButtons from './ListItemWithButtons.vue';
import InspectionsCreateDialog from './inspections/InspectionsCreateDialog.vue';

export default {
  components: {
    ListItemWithButtons,
    InspectionsCreateDialog,
  },
  props: ['typeItem'],
  data() {
    return {
      createDialog: false,
      missingInspectionsType: [],
      showFab: false,
    };
  },

  computed: {
    ...mapState({
      loading: 'loading',
    }),
    ...mapGetters({
      selectedBatiment: 'batiments/selected',
      batimentInspections: 'inspections/selectedBatimentInspections',
      batimentInspectionsType: 'inspections/selectedMissionBatimentInspectionTypes',
      siteInspectionsType: 'inspections/selectedMissionSiteInspectionTypes',
      selectedSite: 'sites/selected',
      siteInspections: 'inspections/selectedSiteInspections',
      selectedInspection: 'inspections/selected',
      selectedMission: 'missions/selected',
      getFormTemplate: 'formTemplates/getFormTemplate',
    }),

    selectedInspectionId() {
      if (this.selectedInspection) {
        return this.selectedInspection.id;
      }
      return null;
    },

    nameItem() {
      if (this.typeItem === 'batiments') {
        return 'bâtiment';
      }
      if (this.typeItem === 'sites') {
        return 'site';
      }
      return null;
    },

    itemInspections() {
      if (this.typeItem === 'batiments') {
        return this.batimentInspections;
      }
      if (this.typeItem === 'sites') {
        return this.siteInspections;
      }
      return null;
    },

    itemInspectionsType() {
      let authorizedInspection = [];
      if (this.typeItem === 'batiments') {
        authorizedInspection = this.selectedMission.authorizedInspectionBatiment;
        if (authorizedInspection === undefined) {
          const filteredInspectionsBatiment = this.batimentInspectionsType;
          return filteredInspectionsBatiment;
        }
        const filteredInspectionsBatiment = this.batimentInspectionsType
          .filter(type => authorizedInspection.includes(type.code));
        return filteredInspectionsBatiment;
      }
      if (this.typeItem === 'sites') {
        authorizedInspection = this.selectedMission.authorizedInspectionSite;
        if (authorizedInspection === undefined) {
          const filteredInspectionsSite = this.siteInspectionsType;
          return filteredInspectionsSite;
        }
        const filteredInspectionsSite = this.siteInspectionsType
          .filter(type => authorizedInspection.includes(type.code));
        return filteredInspectionsSite;
      }
      return null;
    },
  },
  watch: {
    batimentInspections(newValue) {
      if (newValue.length) {
        this.showFab = true;
        this.$emit('show-fabInspection', this.showFab);
      }
    },
    siteInspections(newValue) {
      if (newValue.length) {
        this.showFab = true;
        this.$emit('show-fabInspection', this.showFab);
      }
    },
  },
  async created() {
    this.$store.dispatch('changeLoading', true);
    if (this.typeItem === 'batiments') {
      await this.$store.dispatch('inspections/fetchAllByBatiment', this.selectedBatiment.id);
    } else if (this.typeItem === 'sites') {
      await this.$store.dispatch('inspections/fetchAllBySite', this.selectedSite.id);
    }
    this.$store.dispatch('changeLoading', false);
    this.missingInspectionsType = this.itemInspectionsType.filter(
      type => !this.itemInspections.some(insp => insp.codeType === type.code),
    );
    if (this.missingInspectionsType.length) {
      this.showFab = false;
      this.createDialog = true;
    } else {
      this.showFab = true;
    }
    this.$emit('show-fabInspection', this.showFab);
  },

  methods: {
    checkNecessaryFieldBlank(inspection) {
      return inspection.inAlert;
    },

    selectInspection(inspectionId) {
      this.$store.dispatch('changeSelectedItem', { type: 'inspections', id: inspectionId });
    },
  },
};
</script>

<style lang="scss" scoped>
  .inspection-item {
    display: flex;
    flex-flow: row nowrap;
    > * {
      margin-left: 1rem;
    }
  }

  .nom-inspection {
    width: 45rem;
    overflow: hidden;
    white-space: nowrap;
  }

  .type-inspection {
    margin-left: 3.5rem;
    font-size: 0.8rem;
  }

  .inspections-list-wrapper {
    height: 100%;
    width: 100%;
    padding: 1rem;

    background-color: $app-grey;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: stretch;
  }

  .inspections-list-wrapper > div:nth-last-child(2) {
    margin-bottom: 4rem;
  }

  .empty-list-message {
    margin: 4rem;
    padding: 2rem;
    font-weight: 300;
    font-size: 1.6rem;
    text-align: center;

    background-color: $app-white;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 15px;
  }
</style>
