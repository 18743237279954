<template>
  <div>
    <v-overlay v-if="loadingOneSite">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <div class="mission-detail-wrapper">
      <transition appear name="fade">
        <MissionFatCard
          v-if="mission"
          :mission="mission"
          :nomClient="nomClient"
          @show-edit-item="sheetEditStatus = true"
          :loading="loadingMission"
        ></MissionFatCard>
      </transition>
      <div class="inputSearch">
        <transition appear name="fade">
          <v-text-field
            v-model="search"
            outlined
            rounded
            placeholder="Rechercher un site dans la liste"
            label="Recherche"
            append-icon="mdi-magnify"
            class="missions-search-field"
            color="tertiary"
          >
          </v-text-field>
        </transition>
      </div>
      <div class="searchCounter" v-if="searchCounter >= 1"><h6>
        {{filteredMissions.length}}/{{searchCounter}}</h6></div>
      <transition appear name="slide-fade-down">
        <v-card
          class="big-card"
          :loading="loadingSites"
        >
          <v-card-subtitle>Sites</v-card-subtitle>

            <SiteList
              v-if="!loading && missions.length"
              :sites="filteredMissions"
              @site-clicked="goToSite($event)"
            ></SiteList>

          <p v-if="loadingSites" class="sites-card-message">
            <v-skeleton-loader
              v-for="(site, i) in mission.sites"
              :key="`skel_${i}`"
              type="list-item"
            >
            </v-skeleton-loader>
          </p>

          <p v-if="mission && mission.sites && !mission.sites.length" class="sites-card-message">
            Aucun site sur cette mission
          </p>

        </v-card>
      </transition>
    </div>

    <BaseFab
      class="add-button"
      :options="fabOptions"
      @option-selected="handlerFabButton"
      key='missionDetail_fab'
    >
    </BaseFab>
    <BottomSheetAddItem
      v-if="mission"
      :sheet="showBottomSheetNewSite"
      @close-sheet="showBottomSheetNewSite = false"
      typeItem="sites"
      :defaultItem="{ mission: mission.id }"
    >
    </BottomSheetAddItem>

    <BottomSheetEditItem
      :sheet="sheetEditStatus"
      @close-sheet="sheetEditStatus = false"
      :item="mission"
      typeItem="missions"
    >
    </BottomSheetEditItem>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { missionsEndpoint, sitesUrl } from '@/api/apiUrls';
import axiosInstance from '../plugins/axiosWithHydra';
import MissionFatCard from '../components/missions/MissionFatCard.vue';
import SiteList from '../components/sites/SiteList.vue';
import BaseFab from '../components/BaseFab.vue';
import BottomSheetAddItem from '../components/BottomSheetAddItem.vue';
import BottomSheetEditItem from '../components/BottomSheetEditItem.vue';


export default {
  components: {
    MissionFatCard,
    SiteList,
    BaseFab,
    BottomSheetAddItem,
    BottomSheetEditItem,
  },

  props: ['missionId'],

  data() {
    return {
      loadingMission: false,
      loadingSites: false,
      loadingOneSite: false,
      showBottomSheetNewSite: false,
      sheetEditStatus: false,
      needToSearch: false,
      searching: false,
      searchCounter: 0,
      missionsSite: [],
      items: [],
      search: '',
      fabOptions: [{
        icon: 'mdi-sitemap',
        name: 'sites',
        tooltip: 'Nouveau site',
        color: 'green',
      }],
    };
  },

  computed: {
    ...mapState('missions', ['missions']),
    ...mapState('sites', ['sites']),
    ...mapState('clients', ['clients']),
    mission() {
      return this.missions.find(mission => mission.id === this.missionId);
    },
    missionSites() {
      if (this.mission) {
        return this.sites.filter(site => site.mission === this.mission.id);
      }
      return [];
    },
    client() {
      if (this.mission) {
        return this.clients.find(client => client.missions
          && client.missions.includes(this.mission.id));
      }
      return null;
    },

    nomClient() {
      if (this.client) {
        return this.client.nom;
      }
      return '';
    },
    filteredMissions() {
      if (this.search && this.search.length > 2) {
        return this.items.filter(
          mission => mission.nom.toLowerCase().includes(this.search.toLowerCase())
            || mission.codeSite.toLowerCase().includes(this.search.toLowerCase())
            || mission.ville.toLowerCase().includes(this.search.toLowerCase())
            || mission.adresse.toLowerCase().includes(this.search.toLowerCase())
            || mission.codePostal.toLowerCase().includes(this.search.toLowerCase()),
        );
      }
      if (this.mission) {
        return this.sites.filter(site => site.mission === this.mission.id);
      }
      return [];
    },
    loading() {
      return this.loadingSites || this.loadingMission;
    },
  },

  async created() {
    this.$store.dispatch('clearSelectedView');
    this.loadingMission = true;
    const promisesList = [
      this.$store.dispatch('changeSelectedItem', { type: 'missions', id: this.missionId }),
    ];
    // if no types audits then load them from api
    if (!this.$store.state.missions.typesAudits.length) {
      promisesList.push(this.$store.dispatch('missions/fetchAllTypesAudits'));
    }

    await Promise.all([promisesList]);
    this.loadingMission = false;

    if (
      (this.mission.sites.length && !this.missionSites.length)
      || this.mission.sites.length !== this.missionSites.length
    ) {
      this.loadingSites = true;
      await this.$store.dispatch('sites/fetchAllByMission', this.missionId);
      this.loadingSites = false;
    }
  },
  watch: {
    search(newValue) {
      if (newValue.length <= 2) {
        this.searchCounter = 0;
      }
      if (newValue && newValue.length > 2) {
        // Items have already been requested
        if (this.searching) {
          this.needToSearch = true;
        } else {
          this.callSearch(newValue);
        }
      }
    },
  },

  methods: {
    // Handle multiple actions from BaseFab and redirect to right action
    handlerFabButton(val) {
      if (val === this.fabOptions[0].name) {
        this.showBottomSheetNewSite = true;
      }
    },
    async goToSite(site) {
      this.loadingOneSite = true;
      await this.$store.dispatch('sites/refreshItem', site.id).then(() => {
        this.loadingOneSite = false;
        this.$router.push({ name: 'site', params: { missionId: this.mission.id, siteId: site.id } });
      });
    },
    callSearch() {
      if (this.search && this.search.length > 2) {
        this.searching = true;
        const urlOptions = {
          headers: {
            accept: 'application/ld+json',
          },
          params: {
            searchField: this.search.toLowerCase(),
            pagination: true,
            page: 1,
          },
        };
        axiosInstance.get(`${missionsEndpoint}/${this.mission.id}${sitesUrl}`, urlOptions)
          .then(((response) => {
            this.searchCounter = response.data['hydra:totalItems'];
            if (this.searchCounter >= 20) {
              this.populateItems(response.data['hydra:member'].slice(0, 20));
            } else {
              this.populateItems(response.data['hydra:member']);
            }
          }))
          .finally(() => {
            this.searching = false;
            this.handleSearchQueue();
          });
      }
    },

    populateItems(searchResults) {
      if (searchResults.length) {
        this.items = searchResults.map(site => ({
          id: String(site.id),
          codeSite: site.codeSite,
          nom: site.nom,
          ville: site.ville,
          adresse: site.adresse,
          codePostal: site.codePostal,
          batiments: site.batiments,
          inAlert: site.inAlert,
        }));
      }
    },

    // launch a new save if needToSave is true
    handleSearchQueue() {
      if (!this.searching && this.needToSearch) {
        this.needToSearch = false;
        this.callSearch();
      }
    },

  },
};
</script>

<style lang="scss" scoped>
  .mission-detail-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    padding: 1rem;

    > * {
      max-width: 700px;
      width: 100%;
      margin-bottom: 1rem;
    }

    padding-bottom: 9rem;
  }

  .sites-card-message {
    margin: 0 auto 2rem auto;
    text-align: center;
  }
.inputSearch{
  padding-bottom: 0;
  margin-bottom: 0;
}
.searchCounter{
  text-align: right;
}
.add-button {
  position: fixed;
  right: 4rem;
  bottom: 4rem;
}

</style>
