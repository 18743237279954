<template>
  <v-card :loading="this.loading">
    <v-card-title>
      {{ patrimoine.nom }}
      <v-spacer></v-spacer>
    </v-card-title>
    <v-card-subtitle>Client : {{ nomClient }}</v-card-subtitle>
    <v-card-subtitle>Numéro : {{ patrimoine.numero }}</v-card-subtitle>
    <v-card-subtitle>Description : {{ patrimoine.description }}</v-card-subtitle>
    <v-divider></v-divider>
    <div class="conso-container">
      <div
        class="info-conso"
        v-for="(conso, index) in dataConso"
        :key="index"
      >
        <div class="item"><v-icon class="logo">{{conso.logo ? conso.logo: '-'}}</v-icon></div>
        <div class="item small">{{conso.label ? conso.label: '-'}}</div>
        <div class="item large">
          {{conso.value ? parseFloat(conso.value).toLocaleString('fr') : '-'}}
        </div>
        <div class="item font-unite">{{conso.unite ? conso.unite: '-'}}</div>
        <div class="item large">
          {{conso.valueShon ? parseFloat(conso.valueShon).toLocaleString('fr') : '-'}}
        </div>
        <div class="item font-unite">{{conso.uniteShon ? conso.uniteShon : '-'}}</div>
      </div>
    </div>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        class="pushed-right-bis"
        text
        @click="$emit('planify')"
      >
      Planifier
      </v-btn>
      <v-btn
        class="pushed-right"
        text
        @click="$emit('show-edit-item')"
      >
      Editer
      </v-btn>
      <v-btn
        color="red lighten-1"
        outlined
        @click="showDeleteDialog()"
      >
        supprimer
      </v-btn>
    </v-card-actions>

    <DialogDeleteConfirmation
      :item="patrimoine"
      :dialog="deleteDialog"
      @delete-item="deletePatrimoine()"
      @close-dialog="closeDeleteDialog()"
    >
    </DialogDeleteConfirmation>
  </v-card>
</template>

<script>
import DialogDeleteConfirmation from '../DialogDeleteConfirmation.vue';

export default {
  components: {
    DialogDeleteConfirmation,
  },
  props: ['patrimoine', 'nomClient', 'loading'],
  computed: {
    dataConso() {
      if (this.patrimoine
      && this.patrimoine.calculations) {
        return [
          {
            label: 'Conso',
            value: this.patrimoine.calculations.consommationEfAnneeReference,
            unite: 'kWh EF',
            // eslint-disable-next-line max-len
            valueShon: this.patrimoine.calculations.ratioConsommationEfSurfacePlancherAnneeReference,
            uniteShon: 'kWhEF/m²SP',
            logo: 'mdi-lightning-bolt-circle',
          },
          /* {
            label: 'Conso EP',
            value: this.featuredItem.calculations.consommationEpAnneeReference,
            unite: 'kWh EP',
            // eslint-disable-next-line max-len
            valueShon:
            this.featuredItem.calculations.ratioConsommationEpSurfaceDeclareAnneeReference,
            uniteShon: 'EP/m²',
          }, */
          {
            label: 'Emissions CO2',
            value: this.patrimoine.calculations.emissionCo2AnneeReference,
            unite: 'kgéqCO2',
            valueShon: this.patrimoine.calculations.ratioEmissionCo2SurfacePlancherAnneeReference,
            uniteShon: 'kgéqCO2/m²SP',
            logo: 'mdi-molecule-co2',
          },
          {
            label: 'Coût',
            value: this.patrimoine.calculations.coutTtcAnneeReference,
            unite: '€ TTC',
            valueShon: this.patrimoine.calculations.ratioCoutTtcSurfacePlancherAnneeReference,
            uniteShon: '€TTC/m²SP',
            logo: 'mdi-file-document-multiple-outline',
          },
        ];
      }
      return [];
    },
  },
  data() {
    return {
      deleteDialog: false,
    };
  },

  methods: {
    showDeleteDialog() {
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
    },

    deletePatrimoine() {
      this.$store.dispatch('patrimoines/delete', this.patrimoine).then(() => {
        this.$router.back();
        this.$store.dispatch('snackbar/displaySnack', { message: 'Patrimoine supprimée avec succès', type: 'succes' });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .pushed-right {
    // margin-left: auto !important;
    margin-right: 0;
  }
  .pushed-right-bis {
    margin-left: auto !important;
    margin-right: 0;
  }

  .diag-row-1 {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;

    margin-bottom: 2rem;
  }

  .conso-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    margin: 2rem;
  }

  .info-conso {
    // margin: auto;
    min-width: 7rem;
    text-align: center;
    // margin: 1rem;
  }

  .item{
    // padding: 0.5rem 2rem 0rem 2rem;
  }
  .large {
    font-size: 1.5rem;
    margin-top: 0.2rem;
  }

  .small {
    font-size: 0.9rem;
  }

  .font-unite {
    font-size: 0.7rem;
  }
  .logo {
    color: $app-blue;
  }
</style>
