<template>
  <ValidationProvider
    :name="formField.name"
    :rules="formField.rules"
    v-slot="{ errors }">
    <span @click="insideInput()">
    <v-autocomplete
      :id="`selectBollean_${id}`"
      :items="entries"
      :item-text="entries.text"
      :item-value="entries.value"
      v-bind="fieldOptions"
      :error-messages="errors"
      v-model="localValue"
      @change="$emit('change', localValue)"
      :loading="loading"
      ref="selectRef"
      attach
    >
      <template v-slot:label>
        <slot name="field-label"></slot>
      </template>
    </v-autocomplete>
    </span>
  </ValidationProvider>
</template>

<script>

import { ValidationProvider } from 'vee-validate';
import { mapGetters } from 'vuex';

export default {
  name: 'select-boolean-field',
  components: {
    ValidationProvider,
  },
  props: {
    commonOptions: Object,
    defaultValue: Boolean,
    formField: Object,
  },
  data() {
    return {
      localValue: this.defaultValue,
      entries: [
        {
          text: 'Oui',
          value: true,
        },
        {
          text: 'Non',
          value: false,
        }],
      loading: false,
      storeObject: false,
      id: 0,
      menuActive: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedMission: 'missions/selected',
      selectedBatiment: 'batiments/selected',
    }),
    fieldOptions() {
      return {
        ...this.commonOptions,
        placeholder: this.formField.placeholder,
        itemText: 'libelle',
        itemValue: 'codeOption',
        returnObject: this.storeObject,
      };
    },
  },
  getActivatedMenu() {
    return this.menuActive;
  },
  async mounted() {
    this.$parent.$parent
      .$parent.$parent.$parent.$el.addEventListener('change', () => {
        if (this.$refs.selectRef && this.$refs.selectRef.isMenuActive && this.$refs
          .selectRef.selectedValues.length > 0) {
          this.$refs.selectRef.isMenuActive = false;
          this.menuActive = false;
        }
        if (this.$refs.selectRef) {
          if (this.menuActive) {
            this.$refs.selectRef.isMenuActive = true;
          } else {
            this.$refs.selectRef.isMenuActive = false;
          }
        }
      });
  },
  watch: {
    defaultValue(newValue) {
      this.localValue = newValue;
    },
  },
  created() {
    this.id = Math.random();
  },
  methods: {
    insideInput() {
      this.menuActive = !this.menuActive;
    },

  },
};
</script>
