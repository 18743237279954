
/* eslint no-console: ["error", { allow: ["error", "log"] }] */
/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = {
  message: '',
  type: '',
};

const actions = {
  displaySnack({ commit }, payload) {
    commit('setType', payload.type);
    commit('setMessage', payload.message);
  },
};

const mutations = {
  setMessage(state, message) {
    state.message = message;
  },

  setType(state, type) {
    state.type = type;
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
