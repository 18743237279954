<template>
  <div>
    <div class="missions-view-wrapper">
      <!-- Search Field -->
      <transition appear name="fade">
        <v-text-field
          v-model="search"
          outlined
          rounded
          placeholder="Rechercher une mission dans la liste"
          label="Recherche"
          append-icon="mdi-magnify"
          class="missions-search-field"
          color="tertiary"
        >
        </v-text-field>
      </transition>
      <!-- Missions List Card -->
      <transition appear name="slide-fade-down">
        <v-card
          class="missions-list-card"
          :loading="loading"
        >
          <v-expand-transition>
            <MissionSortedList
              class="missions-list-wrapper"
              v-if="!loading && missions.length"
              :missions="filteredMissions"
              @mission-clicked="goToMission($event)"
            >
            </MissionSortedList>
          </v-expand-transition>

          <v-card-text
            v-if="loading"
            class="card-message"
          >
            Récuperation de la liste des missions
          </v-card-text>
          <v-card-text
            v-if="!loading && !missions.length"
            class="card-message"
          >
            Erreur lors de la récupération de la liste des missions
          </v-card-text>
        </v-card>
      </transition>
    </div>
    <BaseFab
      :options="fabOptions"
      @option-selected="handlerFabButton"
      key="missionView_fab"
      class="add-button"
    >
    </BaseFab>
    <!--<BottomSheetAddItem
      :sheet="showBottomSheet"
      @close-sheet="showBottomSheet = false"
      typeItem="missions"
      :defaultItem="{createdBy: me.username  }"
    >
    </BottomSheetAddItem>-->
    <BottomSheetAddMissionStepper
      :sheet="showBottomSheet"
      @close-sheet="showBottomSheet = false"
      :defaultItem="{ createdBy: me.username  }">
    </BottomSheetAddMissionStepper>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import BaseFab from '../components/BaseFab.vue';
// import BottomSheetAddItem from '../components/BottomSheetAddItem.vue';
import BottomSheetAddMissionStepper from '../components/missions/BottomSheetAddMissionStepper.vue';
import MissionSortedList from '../components/missions/MissionSortedList.vue';

export default {
  components: {
    BottomSheetAddMissionStepper,
    // BottomSheetAddItem,
    BaseFab,
    MissionSortedList,
  },
  data() {
    return {
      search: '',
      loading: false,
      showBottomSheet: false,
      fabOptions: [
        {
          icon: 'mdi-folder-marker', // wallet-outline
          name: 'Ajout',
          tooltip: 'Nouvelle mission',
          color: 'green',
        },
      ],
    };
  },
  computed: {
    ...mapState('missions', ['missions']),
    ...mapGetters({ me: 'me/me' }),

    filteredMissions() {
      if (this.search && this.search.length > 2) {
        return this.missions.filter(
          mission => mission.nom.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '').includes(this.search.toLowerCase()) || mission.nom.toLowerCase().includes(this.search.toLowerCase()),
        );
      }
      return this.missions;
    },
  },
  async created() {
    this.$store.dispatch('clearSelectedView');
    this.loading = true;
    await this.$store.dispatch('clearSelectedItem');
    await this.$store.dispatch('missions/fetchAll');
    this.loading = false;
  },
  methods: {
    // Handle multiple actions from BaseFab and redirect to right action
    handlerFabButton(val) {
      if (val === this.fabOptions[0].name) {
        this.addNewMission();
      }
    },
    // Show bottom sheet and add New Mission in Store
    addNewMission() {
      this.showBottomSheet = true;
    },

    async goToMission(mission) {
      await this.$store.dispatch('missions/refreshItem', mission.id)
        .then(() => {
          this.$router.push({ name: 'missionDetail', params: { missionId: mission.id } });
        });
    },
  },

};
</script>
<style lang="scss" scoped>
  .missions-view-wrapper {
    padding: 2rem 1rem;
    max-width: 1000px;
    margin: 0 auto;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;

    padding-bottom: 9rem;
  }

  .missions-list-wrapper {
    margin-top: 1rem;
  }

  .missions-list-card {
    border-radius: 18px !important;
    padding: 0 1rem 1rem 1rem;
  }

  .card-message {
    margin: 1rem auto;
    text-align: center;
  }

  .add-button {
    position: fixed;
    right: 4rem;
    bottom: 4rem;
  }
</style>
