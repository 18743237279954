/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  compteursEndpoint,
  factureUrl,
  factureEndpoint,
} from './apiUrls';


export default {
  db: new PouchDB('factures'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch
    const _id = obj._id || obj.id.toString();

    return {
      ...obj,
      _id,
      meta: obj.meta || {},
      id: String(obj.id),
      numeroCompteur: obj.numeroCompteur,
      numeroFacture: obj.numeroFacture,
      numeroFactureDeReference: obj.numeroFactureDeReference,
      dateDebut: obj.dateDebut,
      dateFin: obj.dateFin,
      consommationEf: obj.consommationEf,
      coutAbonnement: obj.coutAbonnement,
      coutTotal: obj.coutTotal,
      compteur: obj.client.replace(`${compteursEndpoint}/`, ''),
      sourceDonnee: obj.sourceDonnee,
    };
  },

  async create(facture, factureId) {
    // on créé un objet uniquement pour la sauvegarde locale si pas de connexion
    const localCompteur = {
      id: factureId,
      ...facture,
    };

    const factureToSave = { ...facture };
    let savedFacture = null;

    try {
      // on essaye de sauvegarder via l'API
      const response = await axiosInstance.axiosInstance.post(factureEndpoint, factureToSave);
      savedFacture = this.convertBackendObject(response.data);
    } catch (e) {
      if (e.response
          && e.response.status >= 400
          && e.response.status < 500) {
        throw new Error(e);
      }
      // si la sauvegarde en ligne a échouée, on enregistre les données dans
      // le cache avec le flag 'toCreate' à true afin de relancer la synchro
      // quand on aura à nouveau une connexion
      return pouchUtil.addDoc(localCompteur, this.db, { toCreate: true });
    }

    // si la sauvegarde en ligne a fonctionné
    // si on avait un equipement avec un id temporaire dans pouchDB on le supprime
    if (factureId.startsWith('temp')) {
      try {
        await pouchUtil.deleteDoc(factureId, this.db);
      } catch (e) {
        // do nothing
      }
    }
    // enfin on sauvegarde dans pouchDB le nouvel equipement et on le retourne
    return pouchUtil.addDoc(savedFacture, this.db);
  },

  update(factureId, facture) {
    return axiosInstance.axiosInstance.put(`${factureEndpoint}/${factureId}`, facture)
      .then(response => this.convertBackendObject(response.data));
  },
  /**
   * delete a facture from pouchDB and the server
   */
  async delete(factureId) {
    try {
      await pouchUtil.deleteDoc(factureId, this.db);
    } catch (e) {
      throw new Error(e);
    }
    return axiosInstance.axiosInstance.delete(`${factureEndpoint}/${factureId}`);
  },

  async get(factureId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${factureEndpoint}/${factureId}`);
      const facture = this.convertBackendObject(response.data);

      return pouchUtil.addDoc(facture, this.db);
    } catch (e) {
      console.error('facturesApi getAll error :', e);

      return pouchUtil.getDoc(factureId, this.db);
    }
  },

  /**
     * get all factures assigned to a compteur
     * @param {String} compteurId - id of the compteur
     */
  async getAllByCompteur(compteurId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${compteursEndpoint}/${compteurId}${factureUrl}`);
      const factures = response.data.map(facture => this.convertBackendObject(facture));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(factures, this.db);
    } catch (e) {
      console.error('facturesApi getAllByCompteur error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByField('site', compteurId, this.db);
    }
  },

};
