/* eslint-disable no-console */

import Vue from 'vue';
import Vuex from 'vuex';


import api from '@/api';
import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';
import matricePermission from '../plugins/matrice-permission';
import axiosInstance from '../plugins/axios';

import batiments from './modules/batiments';
import niveaux from './modules/niveaux';
import equipements from './modules/equipements';
import clients from './modules/clients';
import missions from './modules/missions';
import patrimoines from './modules/patrimoines';
import planActions from './modules/planActions';
import sites from './modules/sites';
import compteurs from './modules/compteurs';
import factures from './modules/factures';
import repartitions from './modules/repartitions';
import inspections from './modules/inspections';
import interventions from './modules/interventions';
import interventionSites from './modules/interventionSites';
import listeOptions from './modules/listeOptions';
import offlineMissionList from './modules/offlineMissionList';
import offlineSiteList from './modules/offlineSiteList';
import scenarios from './modules/scenarios';
import programmes from './modules/programmes';
import imageCatalogues from './modules/imageCatalogues';

import me from './modules/me';

import formTemplates from './modules/formTemplates';
import snackbar from './modules/snackbar';


Vue.use(Vuex);
Vue.use(matricePermission);

export default new Vuex.Store({
  // enable strict mode only on developement builds
  strict: process.env.NODE_ENV !== 'production',
  mixins: [
    UpdateItemAlertMixin,
  ],
  state: {
    selectedItemType: null,
    currentForm: {},
    selectedCompteur: null,
    selectedDomaine: '',
    selectedCategorie: '',
    selectedType: '',
    navigationDrawer: true,
    picturesToSync: [],
    linkedEquipemetsToSync: [],
    loading: false,
    loadingPerfVet: false,
    selectedView: null,
    sitesSelectedPatrimoines: [],
    isLoggedIn: axiosInstance.isloggedin(),
    pathToGo: null,
  },

  getters: {
    formField(state) {
      return (fieldName, nestedObj) => {
        if (nestedObj) {
          return state.currentForm[nestedObj][fieldName];
        }
        return state.currentForm[fieldName];
      };
    },

    selectedItem(state, getters) {
      if (state.selectedItemType) {
        return getters[`${state.selectedItemType}/selected`];
      }
      return null;
    },

    nbPicturesToSync(state) {
      return state.picturesToSync.length;
    },
    nbLinkedEquipemetsToSync(state) {
      return state.linkedEquipemetsToSync.length;
    },
  },

  mutations: {

    clearCurrentForm(state) {
      state.currentForm = {};
    },

    changeSelectedItemType(state, type) {
      state.selectedItemType = type;
    },

    clearSelectedItemType(state) {
      state.selectedItemType = null;
    },

    clearSelectedView(state) {
      state.selectedView = null;
    },

    createFormField(state, payload) {
      let newForm = {};
      const form = state.currentForm;
      const currentNested = form[payload.nestedObj];

      if (payload.nestedObj) {
        // if nested object allready exist in CurrentForm
        let newNested = {};
        if (currentNested) {
          // merge the new field in a new object
          newNested = { ...currentNested, [payload.name]: payload.value };
        } else {
          // if nested object doesn't exist in the form
          // just create it
          newNested = { [payload.name]: payload.value };
        }
        // and merge the new object in the form
        newForm = { ...form, [payload.nestedObj]: newNested };
      } else {
        newForm = { ...form, [payload.name]: payload.value };
      }

      state.currentForm = { ...newForm };
    },

    updateFormField(state, payload) {
      if (payload.nestedObj) {
        state.currentForm[payload.nestedObj][payload.name] = payload.value;
      } else {
        state.currentForm[payload.name] = payload.value;
      }
    },

    setSelectedDomaine(state, domaine) {
      state.selectedDomaine = domaine;
    },

    setSelectedCategorie(state, categorie) {
      state.selectedCategorie = categorie;
    },

    setSelectedType(state, type) {
      state.selectedType = type;
      state.currentForm = {};
    },

    setSelectedView(state, view) {
      state.selectedView = view;
    },

    setSelectedCompteur(state, compteur) {
      state.selectedCompteur = compteur;
    },

    setPathToGo(state, pathToGo) {
      state.pathToGo = pathToGo;
    },

    setNavigationDrawer(state, value) {
      state.navigationDrawer = value;
    },

    setPictureToSync(state, pictureList) {
      state.picturesToSync = pictureList;
    },
    setLinkedEquipementToSync(state, linkedEquipementList) {
      state.linkedEquipemetsToSync = linkedEquipementList;
    },

    setLoading(state, loading) {
      state.loading = loading;
    },

    setIsLoggedIn(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },

    setLoadingPerfVet(state, loadingPerfVet) {
      state.loadingPerfVet = loadingPerfVet;
    },

    setSitesSelectedPatrimoines(state, sitesSelectedPatrimoines) {
      state.sitesSelectedPatrimoines = sitesSelectedPatrimoines;
    },

  },
  methods: {

  },

  actions: {
    // ------ selected item ------
    clearSelectedView({ commit }) {
      commit('clearSelectedView');
    },
    async changeSelectedItem({ commit, dispatch }, item) {
      commit('clearCurrentForm');
      switch (item.type) {
        case 'clients':
          await Promise.all([
            dispatch('missions/clearSelected'),
            dispatch('patrimoines/clearSelected'),
            dispatch('planActions/clearSelected'),
            dispatch('sites/clearSelected'),
            dispatch('batiments/clearSelected'),
            dispatch('niveaux/clearSelected'),
            dispatch('equipements/clearSelected'),
            dispatch('inspections/clearSelected'),
            dispatch('interventions/clearSelected'),
            dispatch('interventionSites/clearSelected'),
          ]);
          await dispatch('clients/changeSelected', item.id);
          break;
        case 'missions':
          await Promise.all([
            dispatch('sites/clearSelected'),
            dispatch('batiments/clearSelected'),
            dispatch('niveaux/clearSelected'),
            dispatch('equipements/clearSelected'),
            dispatch('inspections/clearSelected'),
            dispatch('interventions/clearSelected'),
            dispatch('interventionSites/clearSelected'),
          ]);
          await dispatch('missions/changeSelected', item.id);
          break;
        case 'patrimoines':
          await Promise.all([
            dispatch('sites/clearSelected'),
            dispatch('batiments/clearSelected'),
            dispatch('niveaux/clearSelected'),
            dispatch('equipements/clearSelected'),
            dispatch('inspections/clearSelected'),
            dispatch('interventions/clearSelected'),
            dispatch('interventionSites/clearSelected'),
          ]);
          await dispatch('patrimoines/changeSelected', item.id);
          break;
        case 'planActions':
          await Promise.all([
            dispatch('sites/clearSelected'),
            dispatch('batiments/clearSelected'),
            dispatch('niveaux/clearSelected'),
            dispatch('equipements/clearSelected'),
            dispatch('inspections/clearSelected'),
            dispatch('interventions/clearSelected'),
            dispatch('interventionSites/clearSelected'),
          ]);
          await dispatch('planActions/changeSelected', item.id);
          break;
        case 'sites':
          await Promise.all([
            dispatch('batiments/clearSelected'),
            dispatch('niveaux/clearSelected'),
            dispatch('equipements/clearSelected'),
            dispatch('inspections/clearSelected'),
            dispatch('interventions/clearSelected'),
            dispatch('interventionSites/clearSelected'),
          ]);
          await dispatch('sites/changeSelected', item.id);
          break;
        case 'batiments':
          await Promise.all([
            dispatch('niveaux/clearSelected'),
            dispatch('equipements/clearSelected'),
            dispatch('inspections/clearSelected'),
            dispatch('interventions/clearSelected'),
          ]);
          await dispatch('batiments/changeSelected', item.id);
          break;
        case 'niveaux':
          await Promise.all([
            dispatch('equipements/clearSelected'),
            dispatch('inspections/clearSelected'),
            dispatch('interventions/clearSelected'),
          ]);
          await dispatch('niveaux/changeSelected', item.id);
          break;
        case 'equipements':
          await Promise.all([
            dispatch('niveaux/clearSelected'),
            dispatch('inspections/clearSelected'),
            dispatch('interventions/clearSelected'),
          ]);
          await dispatch('equipements/changeSelected', item.id);
          break;
        case 'inspections':
          await Promise.all([
            dispatch('niveaux/clearSelected'),
            dispatch('equipements/clearSelected'),
            dispatch('interventions/clearSelected'),
          ]);
          await dispatch('inspections/changeSelected', item.id);
          break;
        case 'interventions':
          await Promise.all([
            dispatch('niveaux/clearSelected'),
            dispatch('equipements/clearSelected'),
            dispatch('inspections/clearSelected'),
          ]);
          await dispatch('interventions/changeSelected', item.id);
          break;
        case 'interventionSites':
          await Promise.all([
            dispatch('niveaux/clearSelected'),
            dispatch('equipements/clearSelected'),
            dispatch('inspections/clearSelected'),
          ]);
          await dispatch('interventionSites/changeSelected', item.id);
          break;
        case 'imageCatalogues':
          await dispatch('imageCatalogues/changeSelected', item.id);
          break;
        default:
          console.error('selected item type not recognized');
      }
      commit('changeSelectedItemType', item.type);
    },

    async clearSelectedItem({ commit, dispatch }) {
      commit('clearCurrentForm');

      await Promise.all([
        dispatch('clients/clearSelected'),
        dispatch('missions/clearSelected'),
        dispatch('patrimoines/clearSelected'),
        dispatch('planActions/clearSelected'),
        dispatch('sites/clearSelected'),
        dispatch('batiments/clearSelected'),
        dispatch('niveaux/clearSelected'),
        dispatch('equipements/clearSelected'),
        dispatch('inspections/clearSelected'),
        dispatch('interventions/clearSelected'),
        dispatch('interventionSites/clearSelected'),
        dispatch('imageCatalogues/clearSelected'),
      ]);

      commit('clearSelectedItemType');
    },

    // ------ current form ------
    createFormField({ commit }, payload) {
      commit('createFormField', payload);
    },

    updateFormField({ commit }, payload) {
      commit('updateFormField', payload);
    },

    clearCurrentForm({ commit }) {
      commit('clearCurrentForm');
    },

    // ------ Selected compteur ------
    selectCompteur({ commit }, compteur) {
      commit('setSelectedCompteur', compteur);
    },

    async selectPathToGo({ commit, dispatch }, pathToGo) {
      if (!pathToGo.includes('rapport')) {
        if (pathToGo.includes('mission/') && !this.state.missions.selectedId) {
          const idMission = pathToGo.substr(pathToGo.indexOf('mission/') + 8).split('/');
          await dispatch('missions/changeSelected', idMission[0]);
        }
        if (pathToGo.includes('site/') && !this.state.sites.selectedId) {
          const idSite = pathToGo.substr(pathToGo.indexOf('site/') + 5).split('/');
          await dispatch('sites/changeSelected', idSite[0]);
        }
        if (pathToGo.includes('batiment/') && !this.state.batiments.selectedId) {
          const idBatiment = pathToGo.substr(pathToGo.indexOf('batiment/') + 9).split('/');
          await dispatch('batiments/changeSelected', idBatiment[0]);
        }
      }
      commit('setPathToGo', pathToGo);
    },

    selectDomaine({ commit }, domaine) {
      commit('setSelectedDomaine', domaine);
    },

    selectCategorie({ commit }, categorie) {
      commit('setSelectedCategorie', categorie);
    },

    selectType({ commit }, type) {
      commit('setSelectedType', type);
    },

    selectView({ commit }, view) {
      commit('setSelectedView', view);
    },

    updateNavigationDrawer({ commit }, value) {
      commit('setNavigationDrawer', value);
    },

    /**
     * Fetch all data needed ton complete a mission while offline
     */
    async fetchOffline({ dispatch, rootState, rootGetters }, site) {
      const mission = await dispatch('missions/refreshItem', rootGetters['missions/selected'].id);

      const indexFound = rootState.offlineMissionList.all
        .findIndex(item => item.id === mission.id && item.md5 === mission.referentielMd5Version);

      if (indexFound < 0) {
        await dispatch('fetchRefMissionOffline', mission);
        await dispatch('equipements/fetchAllRef', { missionId: mission.id, majRef: false });
      }
      await dispatch('fetchSiteOffline', site);
    },

    async fetchRefMissionOffline({ dispatch }, mission) {
      // on récupère les données indispensables au fonctionnement hors-ligne
      await Promise.all([
        /* api.refEquipementTypes.fetchOffline(mission.id),
        api.refEquipementDomaines.fetchOffline(mission.id),
        api.refEquipementCategories.fetchOffline(mission.id), // référentiels */

        api.refInspectionTypes.fetchOffline(mission.id), // inspection types
        api.clients.fetchOffline(mission.client), // client
        api.missions.fetchOffline(mission.id), // mission
        api.auditTypes.fetchOffline(mission.id), // audit-types
        api.sites.fetchOffline(mission.id), // sites
      ]);

      await api.listeOptions.fetchOffline(mission.id); // listes options
      await api.inspectionTypeForms.fetchOffline(mission.id);
      await api.equipementForms.fetchOffline(mission.id);

      await dispatch('offlineMissionList/add', {
        id: mission.id,
        md5: mission.referentielMd5Version,
      });
    },

    async fetchSiteOffline({ dispatch }, site) {
      const siteId = site.id;
      await api.inspections.fetchOffline(siteId); // inspections
      const siteBatiments = await api.batiments.fetchOffline(siteId); // batiments

      if (siteBatiments && siteBatiments.length) {
        await Promise.all(
          siteBatiments.map(batiment => Promise.all([
            api.niveaux.fetchOffline(batiment.id), // niveaux
            api.equipements.fetchOffline(batiment.id), // equipements
            api.interventions.fetchOffline(batiment.id), // interventions
          ])),
        );
      }

      await dispatch('offlineSiteList/add', siteId);
    },
    /**
     * Load all data stored offline in pouchDB
     */
    loadOffline({ dispatch }) {
      // dispatch('clients/loadOffline');
      // dispatch('missions/loadOffline');
      // dispatch('missions/loadAllTypesAuditsOffline');
      // dispatch('sites/loadOffline');
      dispatch('batiments/loadOffline');
      dispatch('equipements/loadOffline');
      // dispatch('equipements/loadAllRefOffline');
      dispatch('inspections/loadOffline');
      // dispatch('interventions/loadOffline');
      // dispatch('formTemplates/loadOffline');
      dispatch('niveaux/loadOffline');
      // dispatch('clients/loadOffline');
      // dispatch('listeOptions/loadOffline');
      // dispatch('updatePicturesToSync');
      dispatch('updatePicturesToSync');
      dispatch('updateEquipementLieToSync');
    },
    /* loop alert */
    loopAlert(alerts) {
      let findAlert = false;
      // eslint-disable-next-line no-unused-vars
      Object.entries(alerts).forEach(([key, value]) => {
        if (value) {
          findAlert = true;
        }
      });
      return findAlert;
    },
    /**
     * try to synchronise all items with the server in hierarchical order
     * throw an error message if the syncronization cannot be completed
     */
    async synchronise({
      dispatch, state, getters,
    }) {
      try {
        const selectedItemId = getters.selectedItem ? getters.selectedItem.id : null;
        const { selectedItemType } = state;

        const syncBatiments = await dispatch('batiments/synchroniseAll');
        syncBatiments.forEach(
          async (batiment) => {
            let i = 0;
            const site = await api.sites.get(batiment.savedItem.site);
            const payload = { };
            let findAlert = false;
            const listItem = await api.batiments.getAllBySite(site.id);
            // const batimentInALert = listItem.filter(bat => this.loopAlert(bat.inAlert) === true);
            while (i <= listItem.length && !findAlert) {
              // eslint-disable-next-line no-await-in-loop
              findAlert = await dispatch('loopAlert', listItem[i].inAlert);
              i += 1;
            }
            const alertSIte = site.inAlert;
            // populate data to save
            payload.data = {
              ...site,
              inAlert: {
                ...alertSIte,
                inAlertBatiment: findAlert,
              },
            };

            dispatch('sites/save',
              {
                id: site.id,
                ...payload,
              });
          },
        );
        const syncNiveaux = await dispatch('niveaux/synchroniseAll');
        const syncEquipements = await dispatch('equipements/synchroniseAll');
        const syncInspections = await dispatch('inspections/synchroniseAll');
        // await dispatch('interventions/synchroniseAll');

        // update selected Items with new ids
        if (selectedItemId && selectedItemId.startsWith('temp')) {
          let createdSelectedItem = null;
          switch (selectedItemType) {
            case 'batiments':
              createdSelectedItem = syncBatiments.find(bat => bat.oldId === selectedItemId);
              break;
            case 'niveaux':
              createdSelectedItem = syncNiveaux.find(niv => niv.oldId === selectedItemId);
              break;
            case 'equipements':
              createdSelectedItem = syncEquipements.find(eqp => eqp.oldId === selectedItemId);
              break;
            case 'inspections':
              createdSelectedItem = syncInspections.find(inspec => inspec.oldId === selectedItemId);
              break;
            default:
              break;
          }

          if (createdSelectedItem) {
            await dispatch('changeSelectedItem', { type: state.selectedItemType, id: createdSelectedItem.savedItem.id });
          }
        }

        await api.pictures.synchroniseAll();
        await api.equipementLie.synchroniseAll();
      } catch (e) {
        console.error(e);
        console.error('syncronisation intérompue');
        // call a snackbbar message to inform user that the sync have been interupted
      }
      await dispatch('updatePicturesToSync');
      await dispatch('updateEquipementLieToSync');
    },

    async updatePicturesToSync({ commit }) {
      const pictureList = await api.pictures.picturesToSync();
      commit('setPictureToSync', pictureList);
    },
    async updateEquipementLieToSync({ commit }) {
      const linkedEquipementList = await api.equipementLie.linkedEquipemetsToSync();
      commit('setLinkedEquipementToSync', linkedEquipementList);
    },

    changeLoading({ commit }, loading) {
      commit('setLoading', loading);
    },

    changeIsLoggedIn({ commit }, isLoggedIn) {
      commit('setIsLoggedIn', isLoggedIn);
    },

    changeLoadingPerfVet({ commit }, loading) {
      commit('setLoadingPerfVet', loading);
    },

    changeSitesSelectedPatrimoines({ commit }, sitesSelectedPatrimoines) {
      commit('setSitesSelectedPatrimoines', sitesSelectedPatrimoines);
    },
  },

  modules: {
    formTemplates,
    batiments,
    niveaux,
    snackbar,
    equipements,
    clients,
    missions,
    patrimoines,
    planActions,
    sites,
    compteurs,
    factures,
    repartitions,
    inspections,
    interventions,
    interventionSites,
    listeOptions,
    offlineMissionList,
    offlineSiteList,
    scenarios,
    programmes,
    imageCatalogues,
    me,
  },
});
