<template>
  <v-bottom-sheet
    v-model="sheet"
    persistent
    fullscreen
  >

    <v-sheet class="sheet-wrapper">
      <h1>Ajouter une nouvelle inspection</h1>
       <v-layout row wrap>
       <v-flex xs12 sm12>
      <TypeInspectionList
        :item="typeItem"
        class="card-list"
        v-if="afficherType"
        @type-inspection-selected="selectedTypeInspec($event)">
      </TypeInspectionList>

      <ValidationObserver ref="obs" v-slot="{ invalid }">
        <v-form v-if="selectedTypeInspection">
          <form-builder
            :itemType="'inspections'"
            :addInspections="true"
            :item="{ inspectionType: selectedTypeInspection.id, nom: selectedTypeInspection.nom }"
            :key="`form_${selectedTypeInspection.code}`"
          >
          </form-builder>
        </v-form>
        <div class="bottom-sheet-footer">
          <v-btn
            class="action-button"
            text
            color="primary"
            v-if="goBackTypeList()"
            @click="clearSelectedType()"
          >
            Retour
          </v-btn>
          <v-btn
            class="action-button"
            text
            color="primary"
            @click="cancel()"
          >
            Annuler
          </v-btn>
          <v-btn
            class="action-button"
            depressed
            color="primary"
            @click.native="saveForm(false)"
            :disabled="invalid"
            :loading="saving"
            v-if="goBackTypeList()"
          >
            Créer
          </v-btn>
          <v-btn
            class="action-button"
            depressed
            color="primary"
            @click.native="saveForm(true)"
            :disabled="invalid"
            :loading="saving"
            v-if="goBackTypeList()"
          >
            Créer et éditer
            </v-btn>
          </div>
      </ValidationObserver>
  </v-flex>
  </v-layout>
  </v-sheet>
  </v-bottom-sheet>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import api from '@/api';
import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';
import TypeInspectionList from './TypeInspectionList.vue';
import FormBuilder from '../form-builder.vue';

export default {
  mixins: [
    UpdateItemAlertMixin,
  ],
  computed: {
    ...mapState(['currentForm']),
    ...mapGetters({
      selectedMission: 'missions/selected',
      selectedBatiment: 'batiments/selected',
      selectedSite: 'sites/selected',
      getFormTemplate: 'formTemplates/getFormTemplate',
    }),

    afficherType() {
      return (!this.saving && !this.selectedTypeInspection);
    },
  },
  components: {
    TypeInspectionList,
    FormBuilder,
    ValidationObserver,
  },
  data() {
    return {
      saving: false,
      picturesList: Object,
      imagesId: [],
      selectedTypeInspection: null,
    };
  },
  props: ['sheet', 'typeItem'],
  methods: {
    selectedTypeInspec(inspec) {
      this.selectedTypeInspection = inspec;
    },
    saveForm(edit) {
      this.saving = true;

      // convert all form fields to string
      const formCopy = JSON.parse(JSON.stringify(this.currentForm));

      let payload = [];
      if (this.typeItem === 'site') {
        formCopy.site = this.selectedSite.id;
        payload = {
          siteId: this.selectedSite.id,
          inspectionType: this.selectedTypeInspection,
        };
      } else {
        formCopy.batiment = this.selectedBatiment.id;
        payload = {
          batimentId: this.selectedBatiment.id,
          inspectionType: this.selectedTypeInspection,
        };
      }

      this.$store.dispatch('inspections/createNew', payload)
        .then((newItem) => {
          this.$store.dispatch('formTemplates/fetchInspectionTemplate', this.selectedTypeInspection.id).then((response) => {
            const constatsInspection = [];
            const fieldNecessary = [];
            let findBlank = false;
            response.formTemplate.forEach((field) => {
              field.fields.forEach((constat) => {
                constatsInspection[constat.name] = null;
                if (constat.rules && constat.rules.includes('necessary')) {
                  fieldNecessary.push(constat.name);
                }
              });
            });
            let i = 0;
            if (edit) {
              while (i < fieldNecessary.length && !findBlank) {
                if (formCopy.constats
                  && formCopy.constats[fieldNecessary[i]] === null) {
                  findBlank = true;
                }
                i += 1;
              }
            } else if (fieldNecessary.length) {
              findBlank = true;
            }
            this.$store.dispatch(
              'inspections/save',
              {
                id: newItem.id,
                data: {
                  ...formCopy,
                  inAlert: findBlank,
                  constats: {
                    ...constatsInspection,
                  },
                  inspectionType: this.selectedTypeInspection.id,
                },
              },
            ).then((result) => {
              this.$store.dispatch('snackbar/displaySnack', { message: 'créé avec succès !', type: 'succes' });
              if (this.typeItem === 'site') {
                this.majSiteAlert(result.site, 'inspections');
              } else if (this.typeItem === 'batiment') {
                this.majBatimentAlert(result.batiment, 'inspections');
              }

              this.$store.dispatch('changeSelectedItem', { id: result.id, type: 'inspections' }).then(() => {
                if (edit) {
                  this.$emit('open-edit-modal', result.id);
                  this.$emit('close-sheet');
                } else {
                  this.$emit('close-sheet');
                }
                this.clearSelectedType();
              });
            }).finally(() => {
              this.saving = false;
            });
          });
        });
    },

    async saveImages(id, edit) {
      await Promise.all(this.pictures.map(async (picture) => {
        await api.pictures.create(picture, 'equipements', id);
      }));
      this.$store.dispatch('equipements/refreshItem', id);
      if (edit) {
        this.$emit('open-edit-modal', id);
      }
      this.$emit('close-sheet');
    },

    cancel() {
      this.clearSelectedType();
      this.$emit('close-sheet');
      // this.$store.dispatch('changeSelectedItem',
      // { id: this.selectedBatiment.id, type: 'batiments' });
    },

    clearSelectedType() {
      this.selectedTypeInspection = null;
    },

    goBackTypeList() {
      return (this.selectedTypeInspection);
    },
  },
};
</script>

<style lang="scss" scoped>
  .sheet-wrapper {
    padding: 1rem;
    height: 100%;
    overflow: auto;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .card-list {
    min-height: 70vh;
  }

  .bottom-sheet-footer {
    height: 3rem;
    margin-bottom: 0;
    margin-top: auto;

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }

  .action-button {
    margin-right: 1rem;
  }

  .search-field {
    max-height: 4rem;
  }
</style>
