<template>
  <div>
    <v-overlay v-if="loading">
      <v-progress-circular
        indeterminate
        size="100"
      ></v-progress-circular>
    </v-overlay>
    <div v-if="!loading" class="home-view">
      <div>
        <transition appear name="slide-fade-up">
          <v-img
            src="/img/smartdiag.png"
            max-width="50vw"
            max-height="100px"
            float="left"
            contain
            alt="Bienvenue"
          >
          </v-img>
        </transition>
      </div>

      <transition appear name="fade">
        <h3 class="compagnon">Votre compagnon d'audit</h3>
      </transition>
      <transition appear name="fade">
      <v-img
        src="/img/home_illustration.png"
        max-width="100vw"
        max-height="250px"
        contain
        alt="Bienvenue"
      >
      </v-img>
    </transition>

      <transition appear name="fade">
        <h2 class="font-weight-thin mt-7">Veuillez vous authentifier :</h2>
      </transition>
      <form @submit.prevent="submit" class="login-form">
        <p v-if="showError" id="error" class="log-error">Login ou mot de passe incorrect.</p>
        <v-text-field
          id="login"
          label="Login"
          outlined
          v-model="form.login"
        ></v-text-field>
        <v-text-field
          id="password"
          label="Mot de passe"
          outlined
          type="password"
          v-model="form.password"
        ></v-text-field>
        <v-btn
          elevation="2"
          color="primary"
          type="submit"
        > Connexion</v-btn>
      </form>
      <transition appear name="fade">
        <div class="container-logo">
          <p :class="RibonClass">Version : {{version}}</p>
          <h3 class="subtitle-1" >Une solution développée par Cyrisea.</h3>
          <v-img
            src="/img/logo_cyrisea_rvb.png"
            max-width="25vw"
            max-height="50px"
            contain
            alt="Bienvenue"
          >
          </v-img>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
// import { mapActions } from 'vuex';
import axiosInstance from '../plugins/axios';

export default {
  name: 'Login',
  data() {
    return {
      loading: false,
      version: '',
      environnement: '',
      RibonClass: '',
      form: {
        login: '',
        password: '',
      },
      showError: false,
    };
  },

  created() {
    this.$store.dispatch('clearSelectedView');
    this.$store.dispatch('clearSelectedItem');
    this.environnement = window.location.hostname;
    let RibonClass = 'blue';
    if (this.environnement.includes('dev')) {
      RibonClass = 'blue';
      this.environnement = 'Developpement';
    }
    if (this.environnement.includes('rec')) {
      RibonClass = 'red';
      this.environnement = 'Recette';
    }
    if (this.environnement.includes('preprod')) {
      RibonClass = 'green';
      this.environnement = 'Pré-Production';
    }
    this.RibonClass = `ribbon ${RibonClass}`;

    // retrieve the app version number from the json file in public folder
    axiosInstance.axiosInstance.get('/version.json').then((response) => {
      const { appVersion } = response.data;
      this.version = appVersion;
    });
  },

  methods: {
    // ...mapActions(['LogIn']),
    async submit() {
      this.loading = true;
      const user = { username: this.form.login, password: this.form.password };
      /* User.append('username', this.form.login);
      User.append('password', this.form.password); */
      try {
        await axiosInstance.login(user);
        await this.$store.dispatch('changeIsLoggedIn', true);
        await this.$store.dispatch('me/getMe');
        this.$router.push(this.$store.state.pathToGo);
        const searchParams = new URLSearchParams(window.location.search);

        if (searchParams.has('redirect')) {
          this.$router.push({ path: `${searchParams.get('redirect')}` });
        } else this.$router.push({ path: '/' });
        this.showError = false;
      } catch (error) {
        this.loading = false;
        this.showError = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .home-view {
    // width: 100vw;
    height: 100%;
    // padding: 3rem;

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }

  .page-title {
    text-align: center;
  }

  // .btn-search-client {
  //   margin-top: 4rem;
  //   margin-bottom: 1rem;
  // }

  .home-cards-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    > * {
      margin: 1rem;
      flex: 0 1 auto;
    }
  }
  .container-logo {

    .subtitle-1{
      text-align: center;
      font-weight: bold;
      margin-bottom: 0.5rem;
    }
  }
  .ribbon.blue{
    color: $app-blue;
    font-style: italic;
    text-align: center;
    padding: 0.5rem;
    margin-top: 0.5rem;
    background-color: transparent !important;
  }
  .ribbon.red{
    color: $nav-red;
    font-style: italic;
    text-align: center;
    padding: 0.5rem;
    margin-top: 0.5rem;
    background-color: transparent !important;
  }
  .ribbon.green{
    color: $nav-green;
    font-style: italic;
    text-align: center;
    padding: 0.5rem;
    margin-top: 0.5rem;
    background-color: transparent !important;
  }

  .login-form{
    text-align: center;
  }
  .log-error {
    color: red;
  }
  .compagnon{
    margin-top:26px;
  }
</style>
