<template>
  <v-sheet
    :loading="loading"
    class="header-site"
  >
    <!-- Photo et nom -->
    <v-img
      v-if="featuredItem"
      :src="imgUrl"
      class="white--text v-img-heigth"
    >
      <div class="site-card-title">
        <div class="site-title">
          {{this.featuredItem.nom}}
        </div>
      </div>


      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            :indeterminate="load"
            color="black"
          >
          </v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <div class="side-actions">
      <v-btn
        color="primary"
        class="white--text"
        @click="$emit('show-edit-item')"
      >
        Editer
        <v-icon right>
          mdi-circle-edit-outline
        </v-icon>
      </v-btn>

      <v-btn
        color="primary"
        class="white--text"
        @click="showSelectList = true"
      >
        zip images
        <v-icon right>
          mdi-folder-download
        </v-icon>
      </v-btn>

      <v-btn
        color="error"
        class="white--text"
        @click="showDeleteDialog()"
      >
        supprimer
        <v-icon right>
          mdi-delete
        </v-icon>
      </v-btn>

      <v-btn
        class="offline-button"
        outlined
        :color="isOffline ? 'success' : ''"
        :loading="retrievingReference"
        @click="handleClickOffline()"
      >
        hors ligne
        <v-icon right v-if="isOffline">
          mdi-chevron-down-circle
        </v-icon>
        <v-icon right v-else>
          mdi-checkbox-blank-circle-outline
        </v-icon>
      </v-btn>
      <v-dialog v-model="showOfflineDialog" max-width="600">
        <v-card>
          <v-card-title>
            Note d'information: Modèle hors ligne
          </v-card-title>
          <v-card-text>
            Si vous souhaitez pouvoir importer des données depuis le site modèle associé
            à votre mission, il est également nécessaire de mettre celui-ci hors ligne.
          </v-card-text>
          <v-card-actions>
            <v-btn @click="showOfflineDialog = false">Fermer</v-btn>
            <v-btn depressed
            color='primary'
            @click="goToSite()">Accèder au site modèle</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <DialogDeleteConfirmation
      :item="featuredItem"
      :dialog="deleteDialog"
      @delete-item="deleteSite()"
      @close-dialog="closeDeleteDialog()"
    >
    </DialogDeleteConfirmation>
    <v-dialog
      v-model="showSelectList"
      persistent
      max-width="650"
    >
      <v-card>
        <v-card-title class="headline">
          <span>
            <v-icon style="color:red;font-size:30px;">mdi-alert-outline</v-icon>
            Veuillez sélectionner la liste de paramètres à utiliser</span>
        </v-card-title>

        <v-card-text class="card-text">
          Les images sont triées selon les attributs définis
          dans les paramètres Gestion listes Paramètres de la mission.<br><br>
          <input type="radio" v-model="listParam" value="1">
          {{ selectedMission.organisationListeEquipement.paramUnListeUne.libelle }} /
          {{ selectedMission.organisationListeEquipement.paramDeuxListeUne.libelle }}<br>
          <input type="radio" v-model="listParam" value="2">
          {{ selectedMission.organisationListeEquipement.paramUnListeDeux.libelle }} /
          {{ selectedMission.organisationListeEquipement.paramDeuxListeDeux.libelle }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="closeSelectList()"
          >
            Annuler
          </v-btn>
          <v-btn
            class="delete-button"
            depressed
            color='primary'
            @click.native="downloadZipImages(listParam)"
            :disabled="!listParam || isDownload"
            :loading="isDownload"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-sheet>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import axiosInstance from '../../plugins/axiosWithHydra';

import DialogDeleteConfirmation from '../DialogDeleteConfirmation.vue';

export default {
  components: {
    DialogDeleteConfirmation,
  },

  props: ['featuredItem', 'loading'],

  data() {
    return {
      isDownload: false,
      isOffline: false,
      retrievingReference: false,
      deleteDialog: false,
      showSelectList: false,
      listParam: null,
      showOfflineDialog: false,
    };
  },

  computed: {
    ...mapState('offlineSiteList', { offlineSites: 'all' }),
    ...mapGetters({
      selectedMission: 'missions/selected',
      selectedSite: 'sites/selected',
    }),
    ...mapState('compteurs', ['compteurs']),

    imgUrl() {
      if (this.featuredItem && this.featuredItem.image) {
        return `${this.featuredItem.image}/file`;
      }
      return '/img/site_illustration.png';
    },

    load() {
      if (this.featuredItem) {
        return this.featuredItem.image !== null;
      }
      return false;
    },
  },

  methods: {
    async goToSite() {
      const missionId = await this.getModeleMission();
      this.$router.push(`/mission/${missionId.id}/site/${this.selectedMission.siteModele}`);
      if (navigator.onLine) {
        window.location.reload();
      }
    },
    async getModeleMission() {
      const selectedSiteModel = await this.$store.dispatch('sites/getById', this.selectedMission.siteModele);
      const mission = await this.$store.dispatch('missions/getById', selectedSiteModel.mission);
      return mission;
    },
    handleClickOffline() {
      if (navigator.onLine) {
        this.showOfflineDialog = true;
      }
      if (!this.isOffline) {
        this.retrievingReference = true;
        this.$store.dispatch('fetchOffline', this.selectedSite)
          .then(() => { this.isOffline = true; })
          .catch(() => {
            this.$store.dispatch('snackbar/displaySnack', {
              message: 'Echec de récupération du référentiel',
              type: 'error',
            });
          })
          .finally(() => { this.retrievingReference = false; });
      }
    },

    downloadZipImages(list) {
      this.isDownload = true;
      axiosInstance.get(`/api/sites/${this.selectedSite.id}/zipMedia/${list}`, { responseType: 'blob' }).then((response) => {
        // create a invisible 'a' tag with the file in the url and click on it
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `archives_${this.selectedSite.nom}.zip`);
        document.body.appendChild(link);
        link.click();
      }).then(() => {
        this.isDownload = false;
        this.showSelectList = false;
      });
    },

    showDeleteDialog() {
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
    },

    closeSelectList() {
      this.showSelectList = false;
    },


    deleteSite() {
      this.$store.dispatch('sites/delete', this.featuredItem).then(() => {
        // go back to home after delete
        this.$router.push({ path: `/mission/${this.selectedMission.id}` });
        this.$store.dispatch('snackbar/displaySnack', { message: 'Site supprimé avec succès', type: 'succes' });
      }).finally(() => {
        // refresh mission after delete site
        this.$store.dispatch('missions/refreshItem', this.selectedMission.id);
      });
    },
  },

  created() {
    // check if the mission is allready stored for offline use
    this.$store.dispatch('offlineSiteList/loadOffline').then(() => {
      const searchResult = this.offlineSites
        .findIndex(offlineSite => offlineSite.id === this.selectedSite.id);
      if (searchResult >= 0) {
        this.isOffline = true;
      }
    });
  },
};
</script>

<style lang="scss" scoped>
  .v-img-heigth{
    max-height: 20rem;
    max-width: 33rem;
  }

  .header-site {
    background-color: #b0c4de38;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    .side-actions {
      align-self: stretch;
      padding: 1rem;

      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: center;

      button {
        width: 150px;
        margin: 0.5rem 0 0.5rem 0;
      }

      .offline-button {
        margin-top: auto;
        margin-bottom: 0;
      }
    }
  }

  .site-card-title {
    padding:0;
    text-align: center;
    display: flex;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    word-break: break-all;
    position: absolute;
    bottom:0;
  }

  .site-title {
    background-color: #5e5e5e;
    height: 100%;
    padding: 0.2rem 1rem 0.2rem 1rem;
    border-top-right-radius: 4px;
    opacity: 0.85;
  }
</style>
