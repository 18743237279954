<template>
  <div class="imageBank-view">
    <v-row xs12>
      <v-col
        class="d-flex child-flex"
        cols="3"
      >
        <v-btn
          outlined
          height=100%
          color=primary
          @click="addImageCatalogue()"
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col
        v-for="imageCatalogue in imageCatalogues"
        :key="imageCatalogue.codeImageCatalogue"
        class="d-flex child-flex"
        cols="3"
      >
        <div>
          <v-img
            :src="getImageSource(imageCatalogue)"
            aspect-ratio="1"
            class="grey lighten-2 vignetteImage"
            @click="selectPicture(imageCatalogue)"
          >
            <template v-slot:placeholder>
              <v-row
                class="fill-height ma-"
                align="center"
                justify="center"
              >
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
          <div class="d-flex buttons">
            <div class="editImageButton">
              <v-btn icon
              color="primary"
              @click="openInformationDialog(imageCatalogue)"
              >
                <v-icon size="30px">mdi-information</v-icon>
              </v-btn>
            </div>
            <div class="editImageButton">
              <v-btn icon
                color="primary"
                @click.stop="updateImageCatalogue(imageCatalogue)"
              >
                <v-icon size="30px">mdi-circle-edit-outline</v-icon>
              </v-btn>
            </div>
            <div class="editImageButton">
              <v-btn icon
                color="red"
                @click.stop="setImageToDelete(imageCatalogue)"
              >
                <v-icon size="30px">mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
    <Gallery
      :arrayPics="picturesArray"
      :selectedPic="selectedPic"
      :openGalleryTwo="openGallerySheet"
      parentKey="bottom"
      @closeGalleryTwo="closeGallery"
      key="bottomSheetGallery">
    </Gallery>

    <BottomSheetEditItem
      v-if="showBottomSheetNewImage"
      :sheet="showBottomSheetNewImage"
      typeItem="imageCatalogues"
      @close-sheet="closeEditSheet()"
      @cancel-image="deleteImage(selectedImageCatalogue)"
      :item="selectedImageCatalogue"
    >
    </BottomSheetEditItem>

    <DialogInformations
      :dialog="dialogInformation"
      :object="selectedImageCatalogue"
      @close-dialog="closeInformationDialog()"
    >
    </DialogInformations>

    <DialogDeleteConfirmation
      :item="selectedImageCatalogue"
      :dialog="deleteDialog"
      @delete-item="deleteImage(selectedImageCatalogue)"
      @close-dialog="closeDeleteDialog()"
      >
      </DialogDeleteConfirmation>
    <!-- invisible input to handle file upload -->
    <input
      ref="inputFile"
      type=file
      accept="image/*"
      style="display: none;"
      @change="handleFileChange($event);"
    />

  </div>
</template>

<script>
import { mapState } from 'vuex';
import Gallery from '../../components/gallery-pictures.vue';
import BottomSheetEditItem from '../../components/BottomSheetEditItem.vue';
import DialogInformations from '../../components/DialogInformations.vue';
import DialogDeleteConfirmation from '../../components/DialogDeleteConfirmation.vue';
// import { imageCataloguesUrl } from '../../api/apiUrls';

export default {
  components: {
    Gallery,
    BottomSheetEditItem,
    DialogInformations,
    DialogDeleteConfirmation,
  },

  data() {
    return {
      openGallerySheet: false,
      pictureIdList: [],
      picturesArray: [],
      selectedPic: null,
      showBottomSheetNewImage: false,
      dialogInformation: false,
      selectedImageCatalogue: null,
      imageNotValidate: false,
      deleteDialog: false,
    };
  },

  computed: {
    ...mapState('imageCatalogues', ['imageCatalogues']),

    imgUrl(item) {
      if (item && item.image) {
        return `${item.image}/file`;
      }
      return '/img/site_illustration.png';
    },
  },

  async created() {
    await this.$store.dispatch('imageCatalogues/fetchAll');
    this.populatePictureArray(this.imageCatalogues);
  },

  methods: {
    closeGallery() {
      this.openGallerySheet = false;
    },
    openGallery() {
      this.openGallerySheet = true;
    },

    selectPicture(picture) {
      this.selectedPic = `${picture.image}/file`;
      this.openGallery();
    },

    closeEditSheet() {
      this.showBottomSheetNewImage = false;
      this.imageNotValidate = false;
      this.populatePictureArray(this.imageCatalogues);
    },

    getImageSource(element) {
      if (element.image) {
        return `${element.image}/file`;
      }
      return '/img/icons/pictoCamera.jpg';
    },

    populatePictureArray(imageCatalogues) {
      imageCatalogues.forEach((imageCatalogue) => {
        this.pictureIdList.push(imageCatalogue.image);
      });

      this.picturesArray = this.pictureIdList.map(picId => ({ id: picId, url: `${picId}/file` }));
    },

    setImageToDelete(imageCatalogue) {
      this.selectedImageCatalogue = imageCatalogue;
      this.imageNotValidate = true;
      this.deleteDialog = true;
    },

    deleteImage(imageCatalogue) {
      if (this.imageNotValidate) {
        this.$store.dispatch('imageCatalogues/delete', imageCatalogue).then(() => {
          this.showBottomSheetNewImage = false;
          this.$store.dispatch('clearSelectedItem');
          this.imageNotValidate = false;
          this.selectedImageCatalogue = null;
        });
      } else {
        this.showBottomSheetNewImage = false;
      }
    },

    openInformationDialog(item) {
      this.selectedImageCatalogue = item;
      this.dialogInformation = true;
    },

    closeInformationDialog() {
      this.dialogInformation = false;
      this.selectedImageCatalogue = null;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
    },

    updateImageCatalogue(imageCatalogue) {
      this.selectedImageCatalogue = imageCatalogue;
      this.$store.dispatch('changeSelectedItem', { id: imageCatalogue.id, type: 'imageCatalogues' }).then(() => {
        this.showBottomSheetNewImage = true;
      });
    },

    addImageCatalogue() {
      this.$store.dispatch('imageCatalogues/createNew').then((imageCataloguesObj) => {
        this.imageNotValidate = true;
        const imageCataloguesToSave = { ...imageCataloguesObj };
        delete imageCataloguesToSave.id;
        this.selectedImageCatalogue = imageCataloguesObj;
        this.$store.dispatch(
          'imageCatalogues/save',
          {
            data: imageCataloguesToSave,
            id: this.selectedImageCatalogue.id,
          },
        ).then((response) => {
          this.selectedImageCatalogue = response;
          this.$store.dispatch('changeSelectedItem', { id: response.id, type: 'imageCatalogues' }).then(() => {
            this.showBottomSheetNewImage = true;
          });
        }).catch((error) => {
          this.imageNotValidate = false;
          this.$store.dispatch('snackbar/displaySnack', {
            message: `impossible de sauvegarder l'image : ${error}`,
            type: 'error',
          });
        }).finally(() => {
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.imageBank-view{
  margin: 1rem;
}

.vignetteImage {
  cursor: pointer;
}

.editImageButton{
  margin-top: 0.5rem;
}

.buttons {
  justify-content: center;
}
</style>
