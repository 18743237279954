import api from '@/api';

/* eslint no-console: ["error", { allow: ["error", "log"] }] */
/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = {
  planActions: [],

  selectedId: null,
};

const getters = {
  selected: state => state.planActions.find(planAction => planAction.id === state.selectedId),
  selectedPatrimoinePlanActions: (state, getters, rootState, rootGetters) => {
    const patrimoine = rootGetters['patrimoines/selected'];
    if (patrimoine) {
      return state.planActions.filter(plan => plan.patrimoine === patrimoine.id);
    }
    return [];
  },
};

const actions = {

  /**
   * Create a new PlanAction and add it to the state
   */
  createPlanActionOnPatrimoine({ commit }, patrimoineId) {
    const newPlanAction = {
      id: `tempPlan${Math.random()}`,
      nom: 'Nouveau Plan d\'actions',
      numero: '',
      description: '',
      patrimoine: patrimoineId,
    };

    // Add new planAction and change featured item in Store
    commit('addPlanActions', [newPlanAction]);
    return newPlanAction;
  },

  save({ commit }, planAction) {
    return new Promise((resolve, reject) => {
      // if the programme doesn't exhist on the backend, create it by post
      if (planAction.id.startsWith('tempPlan')) {
        api.planActions.create(planAction)
          .then((response) => {
            commit('deletePlanAction', planAction.id);
            commit('addPlanActions', [response]);
            resolve({ ...response });
          })
          .catch((error) => {
            commit('deletePlanAction', planAction.id);
            reject(error);
          });
      // else update it by put
      } else {
        api.planActions.update(planAction)
          .then((response) => {
            commit('addPlanActions', [response]);
            resolve({ ...response });
          })
          .catch(error => reject(error));
      }
    });
  },

  fetchAllByPatrimoine({ commit }, patrimoineId) {
    return api.planActions.getAllByPatrimoine(patrimoineId)
      .then(response => commit('addPlanActions', response));
  },

  delete({ commit }, planAction) {
    return api.planActions.delete(planAction.id)
      .then(() => commit('deletePlanAction', planAction.id));
  },

  refreshItem({ commit }, planActionId) {
    return api.planActions.get(planActionId).then((response) => {
      commit('addPlanActions', [response]);
      return { ...response };
    });
  },

  /**
   * Load all planActions stored offline in pouchDB
   */
  loadOffline({ commit }) {
    return api.planActions.loadOffline().then(response => commit('addPlanActions', response));
  },

  async changeSelected({
    commit, getters, dispatch, state,
  },
  planActionId) {
    if (state.selectedId !== planActionId) {
      commit('changeSelectedId', planActionId);

      if (!getters.selected) {
        await dispatch('refreshItem', planActionId);
      }
    }
  },

  async clearSelected({ commit }) {
    await commit('changeSelectedId', null);
  },
};

const mutations = {
  addPlanActions(state, newPlanActions) {
    newPlanActions.forEach((newPlanAction) => {
      const stateIndex = state.planActions.findIndex(statePlanAction => statePlanAction.id
        === newPlanAction.id);
      if (stateIndex >= 0) {
        state.planActions.splice(stateIndex, 1, newPlanAction);
      } else {
        state.planActions.push(newPlanAction);
      }
    });
  },

  deletePlanAction(state, planActionId) {
    state.planActions.splice(state.planActions.findIndex(planAction => planAction.id
      === planActionId), 1);
  },

  changeSelectedId(state, planActionId) {
    state.selectedId = planActionId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
