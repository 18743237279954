import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState('inspections', { inspectionsTypes: 'types' }),
    ...mapState('equipements', { equipementsCategories: 'categories' }),
  },
  methods: {
    removeEmptyInspection(bloc) {
      if (bloc.defaultValues.inspectionTypeCode) {
        const inspection = this.inspectionsTypes.find(e => e.code === bloc
          .defaultValues.inspectionTypeCode);
        if (!inspection) {
          return false;
        }
      }
      return true;
    },
    getLevelTitle(bloc, structure) {
      if (bloc.defaultValues.title === '') {
        let found = false;
        let index = structure.findIndex(x => x.id === bloc.id);
        const len = structure.length;
        while (!found && index > 0) {
          const previousBloc = structure[(index + len - 1) % len];
          if (previousBloc.defaultValues.levelTitle && !previousBloc.defaultValues
            .hideTitle && previousBloc.defaultValues.title !== '') {
            found = true;
            return this.findLevelTitle(previousBloc);
          }
          found = false;
          index -= 1;
        }
      }
      return this.findLevelTitle(bloc);
    },

    getBlocTitle(bloc) {
      if (bloc.defaultValues.title) {
        return bloc.defaultValues.title;
      }
      return '';
    },

    findLevelTitle(bloc) {
      switch (bloc.defaultValues.levelTitle) {
        case 1:
          return '';
        case 2:
          return '\t';
        case 3:
          return '\t\t';
        case 4:
          return '\t\t\t';
        default:
          return '';
      }
    },
    displayBlocTitle(bloc) {
      return !bloc.defaultValues.hideTitle && typeof bloc.defaultValues.hideTitle !== 'undefined';
    },
    getBlocCodeLabel(bloc) {
      if (bloc.defaultValues.equipementCategorieCode) {
        const equipement = this.equipementsCategories.find(e => e.code === bloc
          .defaultValues.equipementCategorieCode);
        if (equipement) {
          return ` - ${equipement.nom}`;
        }
      } if (bloc.defaultValues.inspectionTypeCode) {
        const inspection = this.inspectionsTypes.find(e => e.code === bloc
          .defaultValues.inspectionTypeCode);
        if (inspection) {
          return ` - ${inspection.nom}`;
        }
      }
      return '';
    },
    getBlocContentComponent(blocType) {
      switch (blocType) {
        case '':
          return '';
        case 'Image':
          return 'ImageBlock';
        default:
          return 'BaseBlockForm';
      }
    },
  },
};
