<template>
<div>
  <div
    class="list-item"
    :class="{ 'background': typeItem === '',
    'selected-item': selected, 'unselected-item': !selected }"
  >

    <span class="item-avatar" v-if="withAvatar">
      <slot name="avatar"></slot>
    </span>

    <span class="item-title">
      <slot name="title"></slot>
    </span>

    <div class="item-property-a">
      <slot name="propertyA"></slot>
    </div>

    <div class="item-property-b">
      <slot name="propertyB"></slot>
    </div>

    <div class="item-property-c">
      <slot name="propertyC"></slot>
    </div>
    <div class="item-property-d">
      <slot name="propertyD"></slot>
    </div>
  </div>
    <v-expand-transition>
      <v-card
        class="list-item-actions"
        v-show="selected"
      >
      <v-btn icon
          v-if="!noInter && handlerDisable()"
          color="white"
          style="background-color: #2D4388; border-radius:0"
          @click.stop="$emit('click-inter')"
        >
          <v-icon size="30px">mdi-hammer-wrench</v-icon>
        </v-btn>
        <v-btn icon
          v-if="!noDuplicate"
          color="white"
          style="background-color: #2D4388; border-radius:0"
          @click.stop="$emit('click-duplicate')"
        >
          <v-icon size="30px">mdi-content-duplicate</v-icon>
        </v-btn>
        <v-btn icon
          color="white"
          style="background-color: #2D4388; border-radius:0"
          @click.stop="$emit('click-edit')"
        >
          <v-icon size="30px">mdi-circle-edit-outline</v-icon>
        </v-btn>
        <v-btn icon
          v-if="!noDelete"
          color="white"
          style="background-color: red; border-radius:0"
          @click.stop="$emit('click-delete')"
        >
          <v-icon size="30px">mdi-delete</v-icon>
        </v-btn>
      </v-card>
    </v-expand-transition>
  </div>
</template>

<script>

export default {
  props: {
    selected: {
      type: Boolean,
      default: false,
    },
    noDelete: {
      type: Boolean,
      default: false,
    },
    noDuplicate: {
      type: Boolean,
      default: false,
    },
    noInter: {
      type: Boolean,
      default: false,
    },
    withAvatar: {
      type: Boolean,
      default: false,
    },
    typeItem: {
      type: String,
      default: '',
    },
  },
  methods: {
    handlerDisable() {
      let show = true;
      if (!navigator.onLine) {
        show = false;
      }
      return show;
    },
  },
};
</script>

<style lang="scss" scoped>
  .list-item {
    /* height: 4rem;
    margin-bottom: 0.7rem;
    //padding: 0 1rem 0 1rem;

    background-color: $app-white;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 15px;
    font-weight: 500;

    transition: all 0.3s cubic-bezier(.25,.8,.25,1);

    display: grid;
    grid-template-rows: 100%;
    align-items: center; */
    display: flex;
    // justify-content: space-around;
    align-items: center;
    flex-flow: row nowrap;
    font-weight: 500;
    height: 4rem;
    width: 100%;
    // border: 1px solid rgb(226, 226, 226);
    //  border-radius: 15px;
    margin-bottom: 0.3rem;
  }
  .selected-item {
    border: 3px solid $app-blue;
    // box-shadow: 0 6px 10px rgba(0,0,0,0.19);
  }
  .unselected-item {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12);
  }

  .background {
    background-color: $app-white;
    // border: 1px solid rgb(226, 226, 226);
    // border-radius: 15px;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12);
  }

  .background.unselected-item {
    border: 1px solid rgb(226, 226, 226);
  }

  .equipement {
    // grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) repeat(7, 1fr);
  }
  .item-title {
    font-size: 1.2rem;
    font-weight: 300;
    flex-basis:100%;
    // flex-grow: 70%;
    margin-right: 1rem;
    /* transition: all 10.3s cubic-bezier(.25,.8,.25,1);
    transition: flex 10.4s; */
  }
  .item-avatar {
    margin-right: 1rem;
  }

  .item-property-c{
    margin-right: 1rem;
  }
  .item-property-d{
    justify-self: end;
    font-size: 1rem;
    font-weight: 300;
    margin-right: 2rem;
  }
  /* .inspection {
    grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) repeat(5, 1fr);
  }

  .equipement {
    grid-template-columns: minmax(1px, 1fr) minmax(1px, 1fr) repeat(7, 1fr);
  }

  .unselected-item {
    box-shadow: 0 1px 3px rgba(0,0,0,0.12);
  }

  .item-property-c{
    justify-self: end;

    font-size: 1rem;
    font-weight: 300;
  }

  .list-item-actions {
    justify-self: end;
    grid-column: span 2 / -1;
  } */
  .list-item-actions {
    z-index:12;
    position: absolute;
    right:0px;
    margin-top:-69px;
    display: flex;
    flex-flow:column nowrap;
    width:50px;
    // height: 180px;
    > * {
      width: 100%;
      height: 45px;
    }
  }
</style>
