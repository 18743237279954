<template>
  <v-dialog
    v-model="show"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <div class="dialog-wrapper">
      <div class="dialog-title">
        Importer des équipements
      </div>
      <div class="dialog-content">
        <div class="upper-fix-block">
          <p class="avertissement" v-if="fromMission && chosenMissionId && !sameRef">
            Les référentiels ne correspondent pas donc les attributs ne seront pas importés !</p>
          <div class="field-title" v-if="fromMission">Choisissez un client</div>
          <v-autocomplete
            v-if="fromMission"
            v-model="chosenClientId"
            :loading="loadingClient"
            :items="clients"
            item-value="id"
            item-text="nom"
            label="Client"
            @change="fetchMission($event)"
          >
          </v-autocomplete>
          <!-- chose site select -->
          <div v-if="chosenClientId && fromMission">
            <div class="field-title" v-if="chosenClientId" >Choisissez une mission</div>
            <v-select
              v-if="chosenClientId"
              v-model="chosenMissionId"
              :loading="loadingMissions"
              :items="missions"
              item-value="id"
              item-text="nom"
              label="Mission"
              @change="fetchSites($event)"
            >
            </v-select>
          </div>
          <!-- chose site select -->
          <p class="avertissement" v-if="!navigatorHandler() && chosenSiteId
          && !isOffline(chosenSiteId)">
            Les données de ce site n'ont pas été chargé sur la tablette,
            l'import est donc impossible tant que vous n'avez pas de connexion internet. !</p>
          <div v-if="fromMission">
            <div class="field-title" v-if="chosenMissionId" >Choisissez un site</div>
            <v-select
              v-if="chosenMissionId"
              v-model="chosenSiteId"
              :loading="loadingSites"
              :items="getSites"
              item-value="id"
              item-text="nom"
              label="Site"
              @change="fetchBatiments($event)"
            >
              </v-select>
          </div>
          <div v-else>
            <div class="field-title" >Choisissez un site</div>
            <v-select
              v-model="chosenSiteId"
              :loading="loadingSites"
              :items="getSites"
              item-value="id"
              item-text="nom"
              label="Site"
              @change="fetchBatiments($event)"
            >
            </v-select>
          </div>

          <!-- chose batiment select -->
          <div class="field-title" v-if="chosenSiteId">Choisissez un bâtiment</div>
          <v-select
            v-if="chosenSiteId"
            v-model="chosenBatimentId"
            :loading="loadingBatiments"
            :items="batiments"
            item-value="id"
            item-text="nom"
            label="Bâtiment"
            @change="fetchEquipements($event)"
          >
          </v-select>
        </div>

        <!-- chose equipements table select -->

        <v-card
          class="lower-scroll-block"
          v-if="chosenBatimentId"
        >
          <EquipementSelectTable
            v-if="!loadingEquipements"
            :equipements="equipements"
            @new-selection="chosenEquipements = $event"
            @images-selection="chosenImages = $event"
          >
          </EquipementSelectTable>
          <!-- loader for equipements -->
          <div v-else class="equipements-loader">
            <v-progress-circular
              width="7"
              size="70"
              indeterminate
              color="primary"
            ></v-progress-circular>
          </div>
        </v-card>
      </div>

      <div class="dialog-footer">
        <v-btn
          text
          large
          color="primary"
          @click="$emit('close')"
        >
          Annuler
        </v-btn>

        <v-btn
          depressed
          large
          color="primary"
          :disabled="!chosenEquipements.length"
          :loading="saving"
          @click.native="handleImport()"
        >
          Importer
        </v-btn>
      </div>
    </div>

  </v-dialog>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import api from '@/api';
import EquipementSelectTable from './EquipementSelectTable.vue';

export default {
  components: {
    EquipementSelectTable,
  },

  props: {
    show: {
      type: Boolean,
      default: false,
    },
    fromMission: {
      type: Boolean,
      default: false,
    },
    chosenClientParam: {
      default: null,
    },
    chosenMissionParam: {
      default: null,
    },
    chosenSiteParam: {
      default: null,
    },
  },

  data() {
    return {
      sameRef: false,
      saving: false,
      chosenClientId: null,
      chosenMissionId: null,
      chosenSiteId: null,
      chosenBatimentId: null,
      equipementTypes: [],
      chosenEquipements: [],
      loadingClient: false,
      loadingMissions: false,
      loadingSites: false,
      loadingBatiments: false,
      loadingEquipements: false,
      equipementTypesALL: [],
    };
  },

  computed: {
    ...mapState({
      allBatiments: state => state.batiments.batiments,
      allEquipements: state => state.equipements.equipements,
      allMissions: state => state.missions.missions,
      allSite: state => state.sites.sites,
      allClient: state => state.clients.clients,
    }),
    ...mapState('offlineSiteList', { offlineSites: 'all' }),
    ...mapGetters({
      sites: 'sites/selectedMissionSites',
      batiment: 'batiments/selected',
      selectedMission: 'missions/selected',
    }),
    clients() {
      if (this.allClient && this.allClient.length) {
        return this.allClient;
      }
      return [];
    },
    missions() {
      if (this.allMissions && this.allMissions.length && this.chosenClientId) {
        return this.allMissions
          .filter(mission => mission.client === this.chosenClientId);
      }
      return [];
    },

    batiments() {
      if (this.allBatiments && this.allBatiments.length && this.chosenSiteId) {
        return this.allBatiments
          .filter(bat => bat.site === this.chosenSiteId && bat.id !== this.batiment.id);
      }
      return [];
    },
    getSites() {
      if (this.fromMission) {
        if (this.allSite && this.allSite.length && this.chosenMissionId) {
          return this.allSite.filter(site => site.mission === this.chosenMissionId);
        }
        return [];
      }
      return this.sites;
    },
    equipements() {
      if (this.allEquipements && this.allEquipements.length
        && this.chosenSiteId && this.fromMission) {
        return this.allEquipements
          .filter(({ libelleType: id1, batiment: bat }) => this.equipementTypes
            .some(({ nom: id2 }) => id2 === id1) && bat === this.chosenBatimentId);
      }
      if (this.allEquipements && this.allEquipements.length
        && this.chosenBatimentId && !this.fromMission) {
        return this.allEquipements.filter(eqp => eqp.batiment === this.chosenBatimentId);
      }
      return [];
    },
  },
  watch: {
    async show(newValue) {
      // on modal oppening
      if (newValue && !this.fromMission) {
        this.loadingSites = true;
        await this.$store.dispatch('sites/fetchAllByMission');
        this.loadingSites = false;
      } else {
        // on modal closing
        // this.chosenSiteId = null;
        this.chosenBatimentId = null;
        this.chosenEquipements = [];
      }
    },
    async fromMission(newValue) {
      if (newValue) {
        this.loadingClient = true;
        this.equipementTypesALL = await api
          .refEquipementTypes.getAllByMission(this.sites[0].mission);
        this.equipementTypes = this.equipementTypesALL
          .filter(equi => equi.formTemplate && equi.formTemplate.length > 0);
        await this.$store.dispatch('clients/fetchAll');
        this.loadingClient = false;
      }
    },
    async chosenClientParam(newValue) {
      this.chosenClientId = newValue;
    },
    async chosenMissionParam(newValue) {
      this.chosenMissionId = newValue;
      this.checkRef();
    },
    async chosenSiteParam(newValue) {
      this.chosenSiteId = newValue;
      this.fetchBatiments(this.chosenSiteId);
    },
  },

  methods: {
    isOffline(siteId) {
      return this.offlineSites.findIndex(site => site.id === siteId) >= 0;
    },
    navigatorHandler() {
      return navigator.onLine;
    },
    async checkRef() {
      this.sameRef = false;
      if (this.chosenMissionId) {
        const missionModele = await this.$store.dispatch('missions/getById', this.chosenMissionId);
        if (this.selectedMission && this.selectedMission.refVersion === missionModele.refVersion) {
          this.sameRef = true;
        }
      }
    },
    async handleImport() {
      this.saving = true;
      let createdEquipements = null;
      try {
        if (this.fromMission && !this.sameRef) {
          // transform all equipements selected and save them on the batiment
          createdEquipements = await Promise.all(
            this.chosenEquipements.map(async (chosen) => {
              const tempEqp = { ...chosen };
              const files = [];
              if (this.chosenImages.indexOf(tempEqp.id) > -1) {
                await Promise.all(tempEqp.images.map(async (pic) => {
                  const file = await fetch(`${pic}/file`).then(r => r.blob());
                  files.push(file);
                }));
              }
              const type = tempEqp.type.replace(/missions\/.*?\//, `missions/${this.sites[0].mission}/`);
              tempEqp.id = `tempEqp${Math.random()}`;
              delete tempEqp._id;
              delete tempEqp._rev;
              delete tempEqp.meta;
              delete tempEqp.images;
              delete tempEqp.attributs;
              delete tempEqp.linkedEquipements;
              delete tempEqp.imagePrincipale;
              delete tempEqp.niveau;
              delete tempEqp.local;
              delete tempEqp.calculations;
              tempEqp.type = type;
              tempEqp.batiment = this.batiment.id;

              this.$store.commit('equipements/addEquipements', [tempEqp]);
              return this.$store.dispatch('equipements/save', {
                id: tempEqp.id,
                data: tempEqp,
                image: tempEqp.images,
              }).then(async (response) => {
                if (files.length) {
                  await Promise.all(files.map(async (file) => {
                    await api.pictures.create(file, 'equipements', response.id);
                  }));
                  this.$store.dispatch('equipements/refreshItem', response.id);
                  this.$store.dispatch('updatePicturesToSync');
                }
              });
            }),
          );
        } else {
          // transform all equipements selected and save them on the batiment
          createdEquipements = await Promise.all(
            this.chosenEquipements.map(async (chosen) => {
              const tempEqp = { ...chosen };
              const files = [];
              if (this.chosenImages.indexOf(tempEqp.id) > -1) {
                await Promise.all(tempEqp.images.map(async (pic) => {
                  const file = await fetch(`${pic}/file`).then(r => r.blob());
                  files.push(file);
                }));
              }
              const type = tempEqp.type.replace(/missions\/.*?\//, `missions/${this.sites[0].mission}/`);
              tempEqp.id = `tempEqp${Math.random()}`;
              delete tempEqp._id;
              delete tempEqp._rev;
              delete tempEqp.meta;
              delete tempEqp.images;
              delete tempEqp.imagePrincipale;
              delete tempEqp.niveau;
              // delete tempEqp.local;
              delete tempEqp.calculations;
              tempEqp.type = type;
              tempEqp.batiment = this.batiment.id;
              this.$store.commit('equipements/addEquipements', [tempEqp]);
              return this.$store.dispatch('equipements/save', {
                id: tempEqp.id,
                data: tempEqp,
              }).then(async (response) => {
                if (files.length) {
                  await Promise.all(files.map(async (file) => {
                    await api.pictures.create(file, 'equipements', response.id);
                  }));
                  this.$store.dispatch('equipements/refreshItem', response.id);
                  this.$store.dispatch('updatePicturesToSync');
                }
              });
            }),
          );
        }

        // get the last equipement created
        const [lastCreated] = createdEquipements.slice(-1);
        this.saving = false;
        this.$store.dispatch('snackbar/displaySnack', {
          message: 'importation terminée avec succès',
          type: 'succes',
        });
        if (!this.fromMission) {
          setTimeout(() => this.$emit('close', lastCreated), 1000);
        } else {
          setTimeout(() => this.$emit('close'), 1000);
        }
      } catch (error) {
        this.saving = false;
        this.$store.dispatch('snackbar/displaySnack', {
          message: 'erreur l\'ors de l\'importation',
          type: 'error',
        });

        setTimeout(() => this.$emit('close'), 1000);
      }
    },
    async fetchMission(clientId) {
      if (clientId) {
        this.chosenClientId = clientId;
        this.loadingMissions = true;
        await this.$store.dispatch('missions/fetchAllByClient', clientId);
        this.loadingMissions = false;
      }
    },
    async fetchSites(missionId) {
      this.checkRef();
      if (missionId) {
        this.chosenMissionId = missionId;
        this.loadingSites = true;
        await this.$store.dispatch('sites/fetchAllByMission', missionId);
        this.loadingSites = false;
      }
    },

    async fetchBatiments(siteId) {
      if (siteId) {
        this.loadingBatiments = true;
        await this.$store.dispatch('batiments/fetchAllBySite', siteId);
        this.loadingBatiments = false;
      }
    },

    async fetchEquipements(batimentId) {
      if (batimentId) {
        this.loadingEquipements = true;
        await this.$store.dispatch('equipements/fetchAllByBatiment', batimentId);
        this.loadingEquipements = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-wrapper {
  height: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 4rem 1fr 4rem;
  grid-template-areas:"header""content""footer";

  background-color: $app-grey;

  .dialog-title {
    grid-area: header;

    font-size: 2rem;
    font-weight: 500;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    background-color: $app-blue;
    color: $app-grey;
  }

  .dialog-content {
    grid-area: content;
    justify-self: center;
    overflow: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .upper-fix-block {
      max-height: 90%;
      margin-right: 30px;
      margin-left: 30px;

    }

    .lower-scroll-block {
      overflow-y: auto;
      max-height: calc(100vh - 400px);
      margin-left: 30px;
      margin-right: 30px;

    }

    .avertissement {
      color: red;
    }
  }

  .dialog-footer {
    grid-area: footer;

    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;

    padding: 0 1rem 0 1rem;

    >* {
      margin-right: 1rem;
    }
  }

  .field-title {
    font-weight: 350;
    font-size: 1.3rem;
    padding-left: 0.2rem;
  }

  .v-text-field {
    min-height: 80px;
  }
}
</style>
