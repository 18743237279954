<template>
  <ValidationProvider
    :name="formField.name"
    :rules="formField.rules"
  >
    <div class="btn-group-wrapper">
      <div class="label-field">{{ formField.label }} : </div>
      <div class="pushed">
        <v-btn-toggle
          v-bind="fieldOptions"
          :dense="true"
          v-model="localValue"
          color="primary"
          @change="$emit('change', localValue)"
          mandatory
        >
          <v-btn
            v-for="option in this.formField.options"
            :key="option"
            :value="option"
            :active-class="'button-active'"
          >
          {{option}}
          </v-btn>
        </v-btn-toggle>
      </div>
    </div>
  </ValidationProvider>
</template>
<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'button-group-field',
  components: {
    ValidationProvider,
  },

  props: {
    defaultValue: null,
    formField: Object,
  },

  data() {
    return {
      localValue: this.defaultValue,
    };
  },

  computed: {
    fieldOptions() {
      return {
        ...this.commonOptions,
      };
    },
  },

  watch: {
    defaultValue(newValue) {
      this.localValue = newValue;
    },
  },

  created() {
    if (this.defaultValue === null && this.formField.options) {
      // default option become first option in form.
      [this.localValue] = this.formField.options;
    }
  },
};
</script>
<style lang="scss" scoped>
  .btn-group-wrapper {
    display: flex;
    margin-bottom: 1rem;
    justify-content: center;
    align-items: center;
  }

  .pushed {
    margin-left: auto;
  }

  .label-field {
    vertical-align: middle !important;
  }

  .button-active {
    background-color: $app-blue !important;
    color: $app-white !important;
  }
</style>
