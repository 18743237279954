<template>
  <div>
    <h4>Inspection(s) site autorisée(s)</h4>
    <v-combobox
      class="combox"
      v-model="localValueSite"
      :items="siteInspectionsType"
      item-text="nom"
      item-value="code"
      label="Inspection(s) Site"
      multiple
      chips
      dense
      outlined
      deletable-chips
      :return-object="false"
      @change="save()"
    ></v-combobox>
    <h4>Inspection(s) bâtiment autorisée(s)</h4>
    <v-combobox
      class="combox"
      v-model="localValueBatiment"
      :items="batimentInspectionsType"
      item-value="code"
      item-text="nom"
      label="Inspection(s) Bâtiment"
      multiple
      chips
      dense
      outlined
      deletable-chips
      :return-object="false"
      @change="save()"
    ></v-combobox>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: ['mission'],
  computed: {
    ...mapGetters({
      batimentInspectionsType: 'inspections/selectedMissionBatimentInspectionTypes',
      siteInspectionsType: 'inspections/selectedMissionSiteInspectionTypes',
    }),
  },
  data() {
    return {
      localValueSite: [],
      localValueBatiment: [],
    };
  },
  async created() {
    this.localValueSite = this.mission.authorizedInspectionSite;
    this.localValueBatiment = this.mission.authorizedInspectionBatiment;
  },
  methods: {
    save() {
      const List = {
        siteInspection: this.localValueSite,
        batimentInspection: this.localValueBatiment,
      };
      this.$emit('listsChange', List);
    },
  },

};
</script>
