/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import pouchUtil from './pouchUtil';

export default {
  db: new PouchDB('offlineMissionList'),

  /**
   * add a mission to the list of offline missions
   * @param {String} missionId - the id of the mission to add
   */
  add(offlineMission) {
    return pouchUtil.addDoc(offlineMission, this.db);
  },

  /**
   * remove a mission from the list of offline missions
   * @param {String} missionId - the id of the mission to remove
   */
  async remove(missionId) {
    const _id = missionId.toString();
    const { _rev } = await this.db.get(_id);
    return this.db.remove(_id, _rev);
  },

  /**
   * Load the list of offline missions
   */
  async getAll() {
    return pouchUtil.getAllDocs(this.db);
  },
};
