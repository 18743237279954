<template>
  <div class="domain-wrapper">
    <p v-if="noDomainFound">Aucun domaine trouvé pour cette mission.</p>
    <v-sheet v-if="loading"
             color="transparent"
             class="message-sheet"
    >
    <v-progress-circular
      v-if="loading"
      :size="70"
      :width="7"
      color="primary"
      indeterminate
    ></v-progress-circular>
    </v-sheet>
    <DomaineCard :class="checkIfActif(domaine.id)
        ?'domaine-card domaine-card-enabled':'domaine-card domaine-card-disabled'"
      v-for="domaine in domaineByMission"
      :key="domaine.id"
      @click.native="selectDomaine(domaine)"
    >
      <template v-slot:icon>
        <img :src="domaine.icone" class="domain-icons" />
      </template>
      <template v-slot:title>
        {{ domaine.nom }}
      </template>

    </DomaineCard>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import DomaineCard from './DomaineCard.vue';

export default {
  data() {
    return {
      loading: false,
      equipementTypes: [],
      activeDomaines: [],
    };
  },
  computed: {
    ...mapState('equipements', { domaines: 'domaines' }),
    ...mapState('equipements', { activesDomaines: 'activesDomaines' }),
    ...mapGetters('missions', { selectedMission: 'selected' }),

    domaineByMission() {
      return this.domaines
        .filter(domaine => domaine.mission === this.selectedMission.id);
    },
    noDomainFound() {
      return !this.loading && (!this.domaineByMission || !this.domaineByMission.length);
    },
  },
  components: {
    DomaineCard,
  },
  async created() {
    if (!this.domaineByMission.length) {
      this.loading = true;
      await this.$store.dispatch('equipements/fetchAllDomaines', { missionId: this.selectedMission.id, majRef: false });
      this.loading = false;
    }
  },
  methods: {
    checkIfActif(id) {
      let actif = false;
      const actifDomain = this.activesDomaines.filter(domaine => domaine.id === id);
      if (actifDomain.length > 0) {
        actif = true;
      }
      return actif;
    },

    selectDomaine(domaine) {
      this.$store.dispatch('selectDomaine', domaine);
      this.$emit('domaine-selected');
    },
  },
};
</script>

<style lang="scss" scoped>
  .domain-icons {
    height: 2rem;
  }
  .message-sheet {
    width: 100px;
    height: 100px;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;

    margin: auto;
    font-weight: 300;
    font-size: 1.6rem;
    text-align: center;
    background-color: transparent !important;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 15px;
  }
  .domain-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;
  }
</style>
