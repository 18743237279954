import api from '@/api';

/* eslint no-console: ["error", { allow: ["error", "log"] }] */
/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = {
  sites: [],
  selectedId: null,
};

const getters = {
  selected: state => state.sites.find(site => site.id === state.selectedId),

  selectedMissionSites: (state, getters, rootState, rootGetters) => {
    const mission = rootGetters['missions/selected'];
    if (mission) {
      return state.sites.filter(site => site.mission === mission.id);
    }
    return [];
  },

  selectedPatrimoineSites: (state, getters, rootState, rootGetters) => {
    const patrimoine = rootGetters['patrimoines/selected'];
    if (patrimoine) {
      return state.sites.filter(site => patrimoine.sites.includes(site.id));
    }
    return [];
  },
};

const actions = {

  /**
   * Create a new Site and add it to the store
   */
  createNew({ commit }) {
    const newSite = {
      id: `tempSite${Math.random()}`,
      nom: 'Nouveau Site',
      image: null,
    };

    commit('addSites', [newSite]);
    return { ...newSite };
  },

  /**
   * Save a site on the server (automatically select POST or PUT) and update the store
   */
  save({ dispatch, commit }, payload) {
    return new Promise(async (resolve, reject) => {
      // if the site doesn't exhist on the backend, create it by post
      const saveSiteFunction = (form, siteId) => new Promise((resolveSite) => {
        if (siteId.startsWith('tempSite')) {
          api.sites.create(form, siteId)
            .then((response) => {
              commit('deleteSite', siteId);
              commit('addSites', [response]);
              dispatch('fetchAll', response.id);
              resolveSite({ ...response });
            })
            .catch(error => reject(error));
        // else update it by put
        } else {
          let formData = form;
          if (!form)formData = payload;
          api.sites.update(siteId, formData)
            .then((response) => {
              commit('addSites', [response]);
              resolveSite({ ...response });
            })
            .catch(error => reject(error));
        }
      });

      // save site and update store
      const savedSite = await saveSiteFunction(payload.data, payload.id);

      // resolve promise
      resolve(savedSite);
    });
  },

  /**
   * get site by his id
   */
  getById({ commit }, siteId) {
    return api.sites.get(siteId).then((response) => {
      commit('addSites', [response]);
      return response;
    });
  },
  /**
   * Fetch all Sites from the server and update the store
   */
  fetchAll({ commit }) {
    return api.sites.getAll().then(response => commit('addSites', response));
  },

  /**
   * Fetch all children sites of a mission
   */
  fetchAllByMission({ commit }, missionId) {
    return api.sites.getAllByMission(missionId).then((response) => {
      commit('addSites', response);
    });
  },

  /**
   * Fetch all children sites of a mission
   */
  fetchAllByPatrimoine({ commit }, patrimoineId) {
    return api.sites.getAllByPatrimoine(patrimoineId).then(response => commit('addSites', response));
  },

  /**
   * Load all sites stored offline in pouchDB
   */
  loadOffline({ commit }) {
    return api.sites.loadOffline().then(response => commit('addSites', response));
  },

  /**
   * Fetch one site by it's id fron the server and update the store
   */
  refreshItem({ commit }, siteId) {
    return api.sites.get(siteId).then((response) => {
      commit('addSites', [response]);
      return { ...response };
    });
  },

  /**
   * Delete a site from the server & from the store
   */
  delete({ commit }, site) {
    return new Promise((resolve, reject) => {
      api.sites.delete(site.id)
        .then(() => {
          commit('deleteSite', site.id);
          resolve();
        }).catch(error => reject(error));
    });
  },

  async changeSelected({
    state, getters, commit, dispatch, rootState,
  },
  siteId) {
    if (state.selectedId !== siteId) {
      commit('changeSelectedId', siteId);

      if (!getters.selected) {
        await dispatch('refreshItem', siteId);
      }

      if (!rootState.missions.selected
        || rootState.missions.selected.id !== getters.selected.mission) {
        await dispatch('missions/changeSelected', getters.selected.mission, { root: true });
      }
    } else {
      await dispatch('refreshItem', siteId);
    }
  },

  async clearSelected({ commit }) {
    await commit('changeSelectedId', null);
  },
};

const mutations = {
  updateSiteImage(state, payload) {
    const site = state.sites.find(item => item.id === payload.siteId);
    site.image = payload.image.id;
  },

  addSites(state, newSites) {
    newSites.forEach((newSite) => {
      const stateIndex = state.sites.findIndex(stateSite => stateSite.id === newSite.id);
      if (stateIndex >= 0) {
        state.sites.splice(stateIndex, 1, newSite);
      } else {
        state.sites.push(newSite);
      }
    });
  },

  deleteSite(state, siteId) {
    state.sites.splice(state.sites.findIndex(site => site.id === siteId), 1);
  },

  changeSelectedId(state, siteId) {
    state.selectedId = siteId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
