<template>
  <div class="content-wrapper">
    <!-- saving progress bar -->
    <v-progress-linear
      class="save-loader"
      :active="saving"
      :indeterminate="saving"
      absolute
      color="primary"
    ></v-progress-linear>

    <!-- form -->
    <div
      class="block-form"
      v-if="!loading && !fetchError"
    >
      <!-- block title -->
      <v-textarea
        outlined
        label="Titre du bloc"
        v-model="title"
        @blur="handleSave($event)"
      ></v-textarea>

      <!-- pre block comment -->
      <h3>Commentaires pré-contenu</h3>
      <vue-editor
        outlined
        :editor-toolbar='customToolbar'
        placeholder="Commentaires pré-contenu"
        v-model="preComment"
        @blur="handleSave($event)"
      ></vue-editor>
      <br/>
      <!-- post block comment -->
      <h3>Commentaires post-contenu</h3>
      <vue-editor
        outlined
        :editor-toolbar='customToolbar'
        placeholder="Commentaires post-contenu"
        v-model="postComment"
        @blur="handleSave($event)"
      ></vue-editor>
    </div>

    <!-- placeholder for 'no data' -->
    <div
      class="no-data"
      v-if="!loading && fetchError"
    >
      <p>Erreur lors de la récupération des donnés du bloc</p>
    </div>

    <!-- fetching spinner -->
    <v-progress-circular
      class="fetch-spinner"
      v-if="loading"
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import axiosInstance from '../plugins/axios';
// eslint-disable-next-line
import { VueEditor } from 'vue2-editor';

export default {
  props: ['block', 'objectIri', 'structureId', 'structure'],
  components: {
    VueEditor,
  },

  data() {
    return {
      rapportContenuId: null,
      title: '',
      preComment: '',
      postComment: '',
      loading: false,
      saving: false,
      fetchError: false,
      neverSaved: false,
      waitingLine: [],
      save: false,
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ['bold', 'italic', 'underline'],
        [{ list: 'ordered' }, { list: 'bullet' }],
      ],
    };
  },

  computed: {
    firstTimeSaving() {
      return this.saving && this.neverSaved;
    },

    updateInLine() {
      return this.waitingLine.includes(this.updateAction);
    },

    createAction() {
      return {
        type: 'create',
        action: () => this.genericSave(() => this.createContenu()),
      };
    },

    updateAction() {
      return {
        type: 'update',
        action: () => this.genericSave(() => this.updateContenu()),
      };
    },
  },

  created() {
    this.fetchData();
  },
  watch: {
    title(newValue, oldValue) {
      if ((!newValue || /^\s*$/.test(newValue)) && oldValue) {
        this.save = true;
      }
    },
  },
  methods: {
    // retrieve the object rapportContenu from the server
    fetchData() {
      this.loading = true;
      axiosInstance.axiosInstance.get('/api/rapport-contenus', {
        params: {
          objetIri: this.objectIri,
          blockId: this.block.id,
        },
      })
        .then((response) => {
          this.fetchError = false;
          // if the response is an empty array, then the contenu has never been saved
          if (response.data.length) {
            const { blockContenu, id } = response.data[0];
            this.rapportContenuId = id;
            // if title is empty, get the default value from the structure
            this.title = blockContenu.title ? blockContenu.title : this.block.defaultValues.title;
            this.preComment = blockContenu.preComment;
            this.postComment = blockContenu.postComment;
          } else {
            this.title = this.block.defaultValues.title;
            this.neverSaved = true;
          }
        })
        .catch(() => { this.fetchError = true; })
        .finally(() => { this.loading = false; });
    },

    // handle a form field loosing focus
    handleSave() {
      // if the form has never been saved and is not being saved right now
      // then add a creation order to the line
      if (this.neverSaved && !this.firstTimeSaving) {
        this.waitingLine.push(this.createAction);
      } else
      // only add an update order if there's not allready one in the line
      if (!this.updateInLine) {
        this.waitingLine.push(this.updateAction);
      }
      // trigger the handling of the line
      this.handleWaitingLine();
    },

    // handle the orders waiting line management
    handleWaitingLine() {
      // if no order is waiting for completion and some are in line
      if (!this.saving && this.waitingLine.length) {
        this.saving = true;
        // extract the next order from the line and launch it
        const order = this.waitingLine.shift();
        order.action().finally(() => {
          // lauch the next order in the line if there's one
          if (this.waitingLine.length) {
            this.handleWaitingLine();
          }
        });
      }
    },

    // wrap a specificSaveFunction
    // handling snackbar succes message and setting this.saving to false in the end
    genericSave(specificSaveFunction) {
      return specificSaveFunction()
        .then(() => {
          this.$store.dispatch('snackbar/displaySnack', { message: 'Sauvegardé avec succès !', type: 'succes' });
        })
        .finally(() => {
          this.saving = false;
        });
    },

    // make an API call to create a rapportContenu
    createContenu() {
      // if saving for the first time, make a post
      // then retrieve the objectId and turn neverSaved to false
      this.updateTitle();
      return axiosInstance.axiosInstance.post('/api/rapport-contenus', {
        objetIri: this.objectIri,
        blockId: this.block.id,
        blockContenu: {
          title: this.title,
          preComment: this.preComment,
          postComment: this.postComment,
        },
        rapportStructure: this.structureId,
      })
        .then((response) => {
          this.rapportContenuId = response.data.id;
          this.neverSaved = false;
        });
    },
    // make an API call to update a rapportContenu
    updateContenu() {
      this.updateTitle();
      return axiosInstance.axiosInstance.put(`/api/rapport-contenus/${this.rapportContenuId}`, {
        blockContenu: {
          title: this.title,
          preComment: this.preComment,
          postComment: this.postComment,
        },
      });
    },
    updateTitle() {
      const blockCopy = this.structure;
      blockCopy.forEach((e) => {
        if (e.id === this.block.id) {
          e.defaultValues.title = this.title;
        }
      });
      axiosInstance.axiosInstance.put(`/api/rapport-structures/${this.structureId}`, {
        structure: {
          ...blockCopy,
        },
      });
      this.save = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .content-wrapper {
    max-width: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
  }

  .block-form {
    margin-top: 2rem;
  }

  .save-loader {
    width: 100%;
  }

  .fetch-spinner {
    align-self: center;
  }
</style>
