<template>
  <div class="analyse-view" v-if="selectedBatiment">
    <div>
      <h3 class="title">Métré du bâtiment de {{nomBatiment}}</h3>
      <div class="alert-pt" v-if="!hasPontThermique">
        Attention, les Ponts Thermiques n'ont pas été relevés.
      </div>
      <v-data-table
        class="table-analyse"
        hide-default-footer
        disable-pagination
        disable-filtering
        disable-sort
        :headers="headersVolume"
        :items="calculsVolumes"
      >
      </v-data-table>
    </div>
    <div>
      <h3 class="title">Analyse des déperditions de {{nomBatiment}}</h3>
      <v-data-table
        class="table-analyse"
        hide-default-footer
        disable-pagination
        disable-filtering
        disable-sort
        :headers="headersDeperdiction"
        :items="calculsDeperditions"
      >
        <template
          v-slot:[`item.libelle`]="propsTitle">
          <div
            v-if="propsTitle.item.libelle==='Total'"
            class="total-td">{{propsTitle.item.libelle}}
          </div>
          <div
            v-else-if="propsTitle.item.libelle
            === 'Déperdition en kW (hors coefficient de relance)'"
            class="dimensionnement-td">{{propsTitle.item.libelle}}
          </div>
          <div
            v-else>{{propsTitle.item.libelle}}
          </div>
        </template>
        <template
          v-slot:[`item.valeur`]="propsValeur">
          <div
            v-if="propsValeur.item.libelle==='Total'"
            class="total-td">{{propsValeur.item.valeur}}
          </div>
          <div
            v-else-if="propsValeur.item.libelle
            === 'Déperdition en kW (hors coefficient de relance)'"
            class="dimensionnement-td">{{propsValeur.item.valeur}}
          </div>
          <div
            v-else>{{propsValeur.item.valeur}}
          </div>
        </template>
        <template
          v-slot:[`item.absolu`]="propsAbsolu">
          <div
            v-if="propsAbsolu.item.libelle==='Total'"
            class="total-td">{{propsAbsolu.item.absolu}}
          </div>
          <div
            v-else>{{propsAbsolu.item.absolu}}
          </div>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import { mapState, mapGetters } from 'vuex';

export default {
  props: ['missionId', 'batimentId'],

  data() {
    return {
      hasPontThermique: true,
      headersDeperdiction: [
        {
          text: 'Catégories',
          align: 'left',
          sortable: false,
          value: 'libelle',
        },
        { text: 'en W/K', value: 'valeur' },
        { text: 'en %', value: 'absolu' },
      ],
      headersVolume: [
        {
          text: 'Catégories',
          align: 'left',
          sortable: false,
          value: 'libelle',
        },
        { text: 'm/m²/m3', value: 'valeur' },
      ],
      loading: false,
    };
  },
  computed: {
    ...mapGetters('batiments', { selectedBatiment: 'selected' }),
    ...mapState('batiments', ['batiments']),

    selectedView: {
      get() {
        return this.$store.state.selectedView;
      },
      set(value) {
        this.$store.commit('setSelectedView', value);
      },
    },

    nomBatiment() {
      return this.selectedBatiment.nom;
    },

    calculsDeperditions() {
      // handle error if no bat or no calc
      if (!(this.selectedBatiment && this.selectedBatiment.calculations)) {
        return [];
      }
      return [
        { libelle: 'Plancher bas', valeur: parseFloat(this.selectedBatiment.calculations.deperditionPlancherBas).toLocaleString('fr'), absolu: parseFloat(this.selectedBatiment.calculations.tauxDeperditionPlancherBas).toLocaleString('fr') },
        { libelle: 'Plancher haut', valeur: parseFloat(this.selectedBatiment.calculations.deperditionPlancherHaut).toLocaleString('fr'), absolu: parseFloat(this.selectedBatiment.calculations.tauxDeperditionPlancherHaut).toLocaleString('fr') },
        { libelle: 'Paroi opaque verticale', valeur: parseFloat(this.selectedBatiment.calculations.deperditionParoiVerticaleOpaque).toLocaleString('fr'), absolu: parseFloat(this.selectedBatiment.calculations.tauxDeperditionParoiVerticaleOpaque).toLocaleString('fr') },
        { libelle: 'Paroi vitrée verticale', valeur: parseFloat(this.selectedBatiment.calculations.deperditionParoiVerticaleVitree).toLocaleString('fr'), absolu: parseFloat(this.selectedBatiment.calculations.tauxDeperditionParoiVerticaleVitree).toLocaleString('fr') },
        { libelle: 'Pont Thermique', valeur: parseFloat(this.selectedBatiment.calculations.deperditionPontThermique).toLocaleString('fr'), absolu: parseFloat(this.selectedBatiment.calculations.tauxDeperditionPontTermique).toLocaleString('fr') },
        { libelle: 'Renouvellement d\'air', valeur: parseFloat(this.selectedBatiment.calculations.deperditionDynamique).toLocaleString('fr'), absolu: parseFloat(this.selectedBatiment.calculations.tauxDeperditionDynamique).toLocaleString('fr') },
        { libelle: 'Total', valeur: parseFloat(this.selectedBatiment.calculations.deperditionTotale).toLocaleString('fr'), absolu: parseFloat(this.getTauxTotal()).toLocaleString('fr') },
        { libelle: 'Déperdition en kW (hors coefficient de relance)', valeur: parseFloat(this.selectedBatiment.calculations.dimensionnementChauffage).toLocaleString('fr'), absolu: '' },
      ];
    },
    calculsVolumes() {
      // handle error if no bat or no calc
      if (!(this.selectedBatiment && this.selectedBatiment.calculations)) {
        return [];
      }
      return [
        { libelle: 'Volume bâtiment', valeur: parseFloat(this.selectedBatiment.calculations.volume).toLocaleString('fr') },
        { libelle: 'Périmètre total', valeur: parseFloat(this.selectedBatiment.calculations.perimetreTotal).toLocaleString('fr') },
        { libelle: 'Surface plancher bas', valeur: parseFloat(this.selectedBatiment.calculations.surfacePlancherBas).toLocaleString('fr') },
        { libelle: 'Surface plancher haut', valeur: parseFloat(this.selectedBatiment.calculations.surfacePlancherHaut).toLocaleString('fr') },
        { libelle: 'Surface paroi opaque verticale', valeur: parseFloat(this.selectedBatiment.calculations.surfaceParoiVerticaleOpaque).toLocaleString('fr') },
        { libelle: 'Surface paroi vitrée verticale', valeur: parseFloat(this.selectedBatiment.calculations.surfaceParoiVerticaleVitree).toLocaleString('fr') },
        { libelle: 'Surface paroi Verticale', valeur: parseFloat(this.selectedBatiment.calculations.surfaceParoiVerticale).toLocaleString('fr') },
      ];
    },
  },
  methods: {
    getTauxTotal() {
      return Math.round(parseFloat(this.selectedBatiment
        .calculations.tauxDeperditionDynamique) + parseFloat(this.selectedBatiment
        .calculations.tauxDeperditionPontTermique) + parseFloat(this.selectedBatiment
        .calculations.tauxDeperditionParoiVerticaleVitree) + parseFloat(this.selectedBatiment
        .calculations.tauxDeperditionParoiVerticaleOpaque) + parseFloat(this.selectedBatiment
        .calculations.tauxDeperditionPlancherHaut) + parseFloat(this.selectedBatiment
        .calculations.tauxDeperditionPlancherBas));
    },
  },
  async created() {
    this.selectedView = 'Analyser';
    this.loading = true;
    await this.$store.dispatch('changeSelectedItem', { type: 'batiments', id: this.batimentId }).then(
      this.$store.dispatch('batiments/refreshItem', this.batimentId),
    );
    this.loading = false;
    if (this.selectedBatiment.calculations.deperditionPontThermique === '0'
    || this.selectedBatiment.calculations.deperditionPontThermique === 0) {
      this.hasPontThermique = false;
    }
  },
};
</script>
<style lang="scss" scoped>
  .analyse-view {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    padding: 1rem;

    > * {
      margin-bottom: 2rem;
    }
  }
  .table-analyse {
    border: 1px solid rgba(0, 0, 0, .1);
    margin-left: auto;
    margin-right: auto;
    width: 45rem;
  }
  .total-td {
    font-weight: bold;
    font-size: 14px;
  }
  .title{
    text-align: center;
  }
  .dimensionnement-td {
    font-style: italic;
  }

  .alert-pt{
    color:red;
    font-size:15px;
    text-align: center;
  }
</style>
