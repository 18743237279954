<template>
  <div class="card">
    <v-icon
      class="iconExpend"
      color="primary"
      @click="showForm = !showForm"
      large
      >
      {{ showForm ? `mdi-minus-circle-outline`:`mdi-plus-circle-outline` }}
    </v-icon>
    <ValidationObserver ref="observer">
      <TitleWithInformations
      title="Attributs de Subventions/CEE"
      :precision="refCee"
      :showParentInformation="showForm">
      </TitleWithInformations>
      <div class="form-wrapper">
        <v-form v-if="showForm">

          <!-- Taux de subvention -->
          <v-text-field
            v-if="getCasIntervention === 8"
            v-bind="fieldOptions"
            label="Taux de subventions"
            type='number'
            suffix='%'
            v-model.number="form.fields.subventionTaux"
            @change="
            handleNumberNull('subventionTaux',
            $event)"
          >
          </v-text-field>

          <!-- CEE : Quantitatif -->
          <ValidationProvider
            v-slot="{ errors }"
            name="quantitatifCEE"
            ref="ceequantitatif"
          >
            <v-text-field
              v-bind="fieldOptions"
              :label="libelleQuantitatif"
              type='number'
              :suffix="uniteQuantitatifCee"
              v-model.number="form.fields.quantitatifCEEChiffrage"
              :error-messages="errors"
              @change="
              handleNumberNull('quantitatifCEEChiffrage',
              $event)"
            >
              <!-- suffix refresh button -->
              <template v-slot:append-outer>
                <div class="refresh-icon-value">
                  <v-icon
                    class="rounded-icon"
                    color="primary"
                    v-ripple
                    @click="refreshQuantitatifCeeValue()"
                  >
                    mdi-refresh
                  </v-icon>
                  <span>{{ coeffQuantCee }}</span>
                </div>
              </template>
            </v-text-field>
          </ValidationProvider>
          <div v-for="(champs, index) in listParamCee"
          :key="index">
            <SelectField
              :listOption="champs"
              :commonOptions="fieldOptions"
              :defaultValue="getValParamCee(champs)"
              :equipId="equipid"
              :energieAp="energieAp"
              @changeParamCee="handleChangeParamCee($event)"
            >
            </SelectField>
          </div>

          <!-- CEE : Coefficient Unitaire -->
          <ValidationProvider
            v-slot="{ errors }"
            name="CoefficientUnitaireCEE"
            ref="ceecoefficientunitaire"
          >
            <v-text-field
              v-bind="fieldOptions"
              label="CEE : Coefficient Unitaire"
              type='number'
              suffix='kWhCUMAC/unité'
              v-model.number="form.fields.valeurUnitaireCEEChiffrage"
              @change="
              handleNumberNull('valeurUnitaireCEEChiffrage',
              $event)"
              :error-messages="errors"
            >
              <!-- suffix refresh button -->
              <template v-slot:append-outer>
                <div class="refresh-icon-value">
                  <v-icon
                    class="rounded-icon"
                    color="primary"
                    v-ripple
                    @click="refreshCoeffCeeValue()"
                  >
                    mdi-refresh
                  </v-icon>
                  <span>{{ coeffCeeUnit }}</span>
                </div>
              </template>
            </v-text-field>
          </ValidationProvider>

        </v-form>
      </div>
    </ValidationObserver>
    <v-btn
        v-if="hrefCee"
        color="primary"
        class="white--text"
        fab
        :href="hrefCee"
        target="_blank"
    >
    <v-icon>mdi-link-variant</v-icon>
    </v-btn>
  </div>

</template>
<script>
import { mapGetters, mapState } from 'vuex';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import TitleWithInformations from '../fields/TitleWithInformations-field.vue';
import SelectField from './select-param-cee.vue';

export default {
  components: {
    ValidationProvider,
    TitleWithInformations,
    ValidationObserver,
    SelectField,
  },
  data() {
    return {
      showForm: true,
      equipid: null,
      refCee: null,
      hrefCee: null,
      fieldOptions: {
        outlined: true,
        dense: true,
      },
      ficheCee: null,
      listParamCee: null,
      coeffCeeUnit: null,
      coeffQuantCee: null,
      libelleQuantitatif: 'CEE : Quantitatif',
      form: {
        valid: true,
        touched: true,
        fields: {
          quantitatifCEEChiffrage: null,
          valeurUnitaireCEEChiffrage: null,
          subventionTaux: null,
        },
      },
    };
  },
  props: ['intervention', 'creation', 'itemIdForCreation', 'energieAp'],
  computed: {
    ...mapState('interventions', ['interventions']),
    ...mapState('listeOptions', { listesOptions: 'all' }),
    ...mapState('equipements', ['equipements']),
    ...mapGetters({
      mission: 'missions/selected',
      site: 'sites/selected',
      batiment: 'batiments/selected',
    }),
    equipementInter() {
      return this.equipements.find(e => e.id === this.intervention.equipement);
    },
    interventionType() {
      if (this.listesOptions) {
        return this.listesOptions.find(opt => opt.codeOption === this.intervention.option);
      }
      return null;
    },
    getCasIntervention() {
      if (this.intervention.referentielValues) {
        return parseInt(this.intervention.referentielValues.option_casIntervention, 10);
      }
      return null;
    },
    uniteQuantitatifCee() {
      if (this.listesOptions && this.interventionType) {
        if (this.ficheCee && this.ficheCee.data) {
          return this.ficheCee.data.libelleMultipleCee;
        }
      }
      return 'unité suivant fiche CEE';
    },
  },
  async created() {
    if (!this.interventionType) {
      await this.$store.dispatch('listeOptions/fetchByCodeOption', {
        missionId: this.mission.id,
        codeOption: this.intervention.option,
      });
    }
    if (this.interventionType.data.refFicheCEEBatTertiaire) {
      this.ficheCee = await this.getListOptionByCodeOption(this.mission.id, this.interventionType
        .data.refFicheCEEBatTertiaire);
      if (this.ficheCee) {
        this.listParamCee = await this.ficheCee.data.parametreCee;
        const urlCee = await this.ficheCee.data.lienHypertexteFicheCee;
        this.hrefCee = urlCee;
      }
      const refCee = this.listesOptions.find(opt => opt.codeOption === this.interventionType
        .data.refFicheCEEBatTertiaire);
      if (refCee) {
        this.refCee = refCee.libelle;
      }
    }
    if (this.creation) {
      this.equipid = this.itemIdForCreation;
      // init fields with referential if creation
      await this.initFormValues();
    } else if (this.intervention && this.intervention.referentielValues) {
      this.equipid = this.intervention.equipement;
      this.form.fields = {
        quantitatifCEEChiffrage: this.intervention.quantitatifCEEChiffrage,
        libelleMultipleCee: this.intervention.libelleMultipleCee,
        valeurUnitaireCEEChiffrage: this.intervention.valeurUnitaireCEEChiffrage,
        subventionTaux: this.intervention.subventionTaux,
        parametreCee: this.intervention.parametreCee,
        ficheCee: this.ficheCee !== null ? this.ficheCee.codeOption : null,
      };
      this.coeffCeeUnit = await this.getCoeffCeeDefault();
      this.coeffQuantCee = await this.getQuantitatifChiffrageCee();
    }
  },
  mounted() {
    if (this.creation) {
      const coeffCeeQuantiValid = this.$refs.ceequantitatif.validate();
      const ceeCoeffUnitValid = this.$refs.ceecoefficientunitaire.validate();
      if (coeffCeeQuantiValid
      && ceeCoeffUnitValid) {
        this.form.valid = true;
      } else {
        this.form.valid = false;
      }
      this.$emit('form-updated', this.form);
    }
  },
  methods: {
    getValParamCee(champs) {
      if (this.form.fields.parametreCee && this.form.fields.parametreCee[champs]) {
        return this.form.fields.parametreCee[champs].libelle;
      }
      return null;
    },
    async handleChangeParamCee(item) {
      this.form.fields.parametreCee = {
        ...this.form.fields.parametreCee,
        [item.listOption]: item.localValue,
      };
      this.handleFormUpdate();
      this.coeffCeeUnit = await this.getCoeffCeeDefault();
      this.coeffQuantCee = await this.getQuantitatifChiffrageCee();
    },
    fetchListOptionByCodeType(missionId, codeType) {
      this.$store.dispatch('listeOptions/fetchAllByType', { missionId, codeType });
    },
    async getListOptionByCodeOption(missionId, codeOption) {
      if (codeOption !== null) {
        try {
          return new Promise((resolve, reject) => {
            this.$store.dispatch('listeOptions/fetchByCodeOption', { missionId, codeOption })
              .then((response) => {
                resolve(response);
              }).catch((error) => {
                reject(error);
              });
          });
        } catch (e) {
          return null;
        }
      }
      return null;
    },
    handleNumberNull(nameField, newValue) {
      if (!newValue) {
        this.form.fields[nameField] = null;
      }

      this.handleFormUpdate();
    },

    async handleFormUpdate() {
      if (!this.form.touched) {
        this.form.touched = true;
      }
      if (this.$refs.observer) {
        this.form.valid = await this.$refs.observer.validate({ silent: true });
      }
      this.$emit('form-updated', this.form);
    },

    async refreshQuantitatifCeeValue() {
      this.form.fields.quantitatifCEEChiffrage = await this.getQuantitatifChiffrageCee();
      this.handleFormUpdate();
    },

    async getQuantitatifChiffrageCee() {
      if (this.ficheCee && this.ficheCee.data.coeffMultiplicateur) {
        const refCoeffMultCee = await this.getListOptionByCodeOption(
          this.mission.id, this.ficheCee.data.coeffMultiplicateur.toLowerCase(),
        );
        if (refCoeffMultCee.data.cheminVariable) {
          this.libelleQuantitatif = refCoeffMultCee.libelle;
          let quantitatifChiffrageCee = null;
          const splitItem = refCoeffMultCee.data.cheminVariable.split('.');
          const attribute = refCoeffMultCee.data.cheminVariable
            .substr(splitItem[0].length + 1);

          const selectedEquipement = await this.$store.dispatch('equipements/refreshItem', this.equipid);
          switch (splitItem[0]) {
            case 'site':
              quantitatifChiffrageCee = attribute.split('.').reduce((prev, cur) => prev[cur], this.site);
              break;
            case 'batiment':
              quantitatifChiffrageCee = attribute.split('.').reduce((prev, cur) => prev[cur], this.batiment);
              break;
            case 'equipement':
              quantitatifChiffrageCee = attribute.split('.').reduce((prev, cur) => prev[cur], selectedEquipement);
              break;
            default:
              quantitatifChiffrageCee = null;
              break;
          }
          if (quantitatifChiffrageCee) {
            quantitatifChiffrageCee = parseFloat(quantitatifChiffrageCee);
          }
          return quantitatifChiffrageCee;
        }
      }
      return null;
    },

    async refreshCoeffCeeValue() {
      if (this.interventionType) {
        this.form.fields.valeurUnitaireCEEChiffrage = await this.getCoeffCeeDefault();
      }
      this.handleFormUpdate();
    },

    async getCoeffCeeDefault() {
      const concat = this.getConcatParamCee();
      const refCoeffCee = await this.getListOptionByCodeOption(this.mission.id, concat);
      if (refCoeffCee && refCoeffCee.data) {
        return parseFloat(refCoeffCee.data.coefficient);
      }
      return null;
    },

    getConcatParamCee() {
      if (this.ficheCee && this.listParamCee) {
        let concatParamCee = this.ficheCee.codeOption.replace('-fichecee', '');
        Object.entries(this.listParamCee).forEach(([, value]) => {
          if (this.form.fields.parametreCee[value]) {
            concatParamCee = `${concatParamCee}_${this.form.fields.parametreCee[value].code}`;
          }
        });
        return `${concatParamCee}-coefficientcee`;
      }
      return null;
    },
    async initFormValues() {
      if (this.interventionType) {
        let quantitatifCeeDefault = null;
        if (this.ficheCee && this.ficheCee.data) {
          quantitatifCeeDefault = await this.getQuantitatifChiffrageCee(
            this.ficheCee.data.variableMultipleCee,
          );
        }
        this.form.fields = {
          quantitatifCEEChiffrage: quantitatifCeeDefault,
          valeurUnitaireCEEChiffrage: await this.getCoeffCeeDefault(),
          subventionTaux: null,
          ficheCee: this.ficheCee !== null ? this.ficheCee.codeOption : null,
          parametreCee: {},
        };
      }
      this.$emit('form-updated', this.form);
    },
  },
};
</script>
<style lang="scss" scoped>
.iconExpend {
  float: right;
}
.card {
  width: 100%;
}
.formCard {
  display:flex;
  justify-content: center;
  flex-flow: column nowrap;
  align-items: center;
}
.rounded-icon {
  border-radius: 100%;
}
.form-wrapper {
  width: 100%;

  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: stretch;
}

.refresh-icon-value{
  display: flex;
  flex-flow: column;
  margin-top: -8px;
  font-size: 14px;
}

</style>
