/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  batimentsEndpoint,
  scenariosEndpoint,
  interventionsEndpoint,
} from './apiUrls';

export default {
  db: new PouchDB('scenarios'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch

    const _id = obj._id || obj.id.toString();
    const meta = {};
    const batiment = obj.batiment ? obj.batiment.replace(`${batimentsEndpoint}/`, '') : '';

    return {
      ...obj,
      _id,
      meta,
      id: String(obj.id),
      batiment,
      interventions: obj.interventions.map(inter => inter.replace(`${interventionsEndpoint}/`, '')),
    };
  },

  async create(scenario) {
    // si l'objet est dépendant d'un objet supérieur pas encore créé
    const batiment = scenario.batiment ? scenario.batiment : '';
    if (batiment.startsWith('temp')) {
      throw Error('vous ne pouvez sauvegarder un scénario sur un bâtiment non encore syncronisé');
    }

    const scenarioToCreate = { ...scenario };
    delete scenarioToCreate.id;
    delete scenarioToCreate._id;
    delete scenarioToCreate.meta;

    let savedScenario = null;
    const response = await axiosInstance.axiosInstance.post(scenariosEndpoint, scenarioToCreate);
    savedScenario = this.convertBackendObject(response.data);

    return pouchUtil.addDoc(savedScenario, this.db);
  },

  async update(scenario) {
    const scenarioToUpdate = { ...scenario };
    delete scenarioToUpdate.id;
    delete scenarioToUpdate._id;
    delete scenarioToUpdate.meta;

    const response = await axiosInstance.axiosInstance.put(`${scenariosEndpoint}/${scenario.id}`, scenarioToUpdate);
    const savedScenario = this.convertBackendObject(response.data);

    // si la sauvegarde en ligne à reussie, on sauvegarde dans pouchDB et on retourne le resultat
    return pouchUtil.addDoc(savedScenario, this.db, { updated: true });
  },

  /**
   * delete a scenario from pouchDB and the server
   */
  async delete(scenarioId) {
    // delete the intervention from pouchDB
    await pouchUtil.deleteDoc(scenarioId, this.db);
    // if the delete from pouchDB is sucessfull, delete from the server
    return axiosInstance.axiosInstance.delete(`${scenariosEndpoint}/${scenarioId}`);
  },

  /**
   * get all scénarios for a batiment
   * @param {String} batimentId - id of the parent batiment
   */
  async getAllByBatiment(batimentId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${scenariosEndpoint}?batiment=${batimentId}`);
      const scenarios = response.data
        .map(scenario => this.convertBackendObject(scenario));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(scenarios, this.db);
    } catch (e) {
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByField('batiment', batimentId, this.db);
    }
  },
};
