<template>
  <div
    class="scenarios-view"
    v-if="batiment"
  >
    <v-overlay v-if="loading">
    <v-progress-circular
      indeterminate
      size="64"
    ></v-progress-circular>
    </v-overlay>
    <h3>Scénarios du bâtiment {{ batiment.nom }}</h3>

    <div
      v-if="!loading && !scenarios.length"
    >
      Il n'y a pas encore de scénario pour ce batiment.
      Vous pouvez cliquer sur le bouton + en bas de page pour en créer un.
    </div>


    <v-expansion-panels
      popout
      multiple
      v-model="openedPanels"
      class="bloc-list"
    >
      <v-expansion-panel
        v-for="scenario in scenarios"
        :key="scenario.id"
      >
        <v-expansion-panel-header>
          <template v-slot:default="{ open }">
            <div class="scenario-header-wrapper">
              <span class="scenario-header-prefix">{{ scenario.numero }}</span>
              <span class="scenario-header-title">{{ scenario.nom }}</span>
              <div class="scenario-header-button-group" v-if="open">
                <v-btn icon
                       class="scenario-duplicate-button"
                       @click.stop="duplicateScenario(scenario)"
                >
                  <v-icon>mdi-content-duplicate</v-icon>
                </v-btn>
                <v-btn icon
                  class="scenario-edit-button"
                  @click.stop="editScenario(scenario)"
                >
                  <v-icon>mdi-circle-edit-outline</v-icon>
                </v-btn>
                <v-btn icon
                  @click.stop="handleDeleteRequest(scenario)"
                  class="scenario-delete-button"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>

          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ScenarioOverview
            :scenar="scenario"
            :key="`scenar_content_${scenario.id}`"
          >
          </ScenarioOverview>
        </v-expansion-panel-content>

      </v-expansion-panel>
    </v-expansion-panels>

    <v-fab-transition>
      <v-btn
        v-if="!deleteDialog && !editModal"
        color="primary"
        fab
        large
        class="fab-add-button"
        @click="createScenario()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <DialogDeleteConfirmation
      v-if="deleteDialog"
      :dialog="deleteDialog"
      :item="scenarToDelete"
      @delete-item="deleteScenario()"
      @close-dialog="deleteDialog = false; scenarToDelete = null;"
    >
    </DialogDeleteConfirmation>

    <ScenarioEditModal
      :show="editModal"
      :scenario="scenarToEdit"
      :create="modalCreateMode"
      :duplicate="modalDuplicateMode"
      @close="closeEditModal()"
    >
    </ScenarioEditModal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogDeleteConfirmation from '../components/DialogDeleteConfirmation.vue';
import ScenarioOverview from '../components/scenarios/ScenarioOverview.vue';
import ScenarioEditModal from '../components/scenarios/ScenarioEditModal.vue';

export default {
  props: ['missionId', 'batimentId'],

  components: {
    DialogDeleteConfirmation,
    ScenarioOverview,
    ScenarioEditModal,
  },

  data() {
    return {
      loading: false,
      deleteDialog: false,
      scenarToDelete: null,
      editModal: false,
      scenarToEdit: null,
      modalCreateMode: false,
      modalDuplicateMode: false,
      openedPanels: [],
    };
  },

  computed: {
    ...mapGetters({
      batiment: 'batiments/selected',
      scenarios: 'scenarios/selectedBatimentScenarios',
    }),
    selectedView: {
      get() {
        return this.$store.state.selectedView;
      },
      set(value) {
        this.$store.commit('setSelectedView', value);
      },
    },

  },

  async created() {
    this.selectedView = 'Scénariser';
    this.loading = true;
    await this.$store.dispatch('changeSelectedItem', { type: 'batiments', id: this.batimentId });
    await this.$store.dispatch('scenarios/fetchAllByBatiment', this.batimentId);
    let i = 0;
    while (i < this.scenarios.length) {
      this.openedPanels.push(i);
      i += 1;
    }
    this.loading = false;
  },

  methods: {
    createScenario() {
      this.scenarToEdit = {};
      this.modalCreateMode = true;
      this.editModal = true;
    },
    getNextNumber() {
      return Math.max(...this.scenarios.map(o => o.numero)) + 1;
    },
    deleteScenario() {
      this.$store.dispatch('scenarios/delete', this.scenarToDelete);
      this.scenarToDelete = null;
    },

    handleDeleteRequest(scenario) {
      this.scenarToDelete = scenario;
      this.deleteDialog = true;
    },

    editScenario(scenario) {
      this.scenarToEdit = scenario;
      this.editModal = true;
    },
    duplicateScenario(scenario) {
      this.scenarToEdit = scenario;
      this.scenarToEdit.nextValue = this.getNextNumber();
      this.modalDuplicateMode = true;
      this.editModal = true;
    },

    closeEditModal() {
      this.editModal = false;
      this.scenarToEdit = null;
      if (this.modalDuplicateMode) {
        this.modalDuplicateMode = false;
      }
      if (this.modalCreateMode) {
        this.modalCreateMode = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .scenarios-view {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    padding: 1rem;

    > * {
      margin-bottom: 2rem;
    }
  }

  .scenario-header-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    .scenario-header-prefix {
      width: 2rem;
    }

    .scenario-header-title {
      font-weight: 300;
      font-size: 1.3rem;
    }

    .scenario-header-button-group {
      margin-left: auto;
      margin-right: 5px;
      min-width: 75px;
      .scenario-edit-button {
        color: $app-blue !important;
      }
      .scenario-duplicate-button {
        color: $app-blue !important;
      }
      .scenario-delete-button {
        color: $custom-red;
      }
    }

  }

  .bloc-list {
    max-width: 1000px;
  }

  .fab-add-button {
    position: fixed;
    bottom: 2rem;
    right: 3rem;
    z-index: 500;
  }
</style>
