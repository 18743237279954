<template>
  <div class="list-wrapper">
    <p class="domaine-select">Domaine sélectionné: <u>{{ selectedDomaine.nom }}</u></p>
    <div class="categorie-wrapper">

      <p v-if="domaineVide">Aucune Catégorie n'est associée à ce domaine.</p>

      <v-sheet v-if="loading"
               color="transparent"
               class="message-sheet"
      >
        <v-progress-circular
          v-if="loading"
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-sheet>
      <!--  -->
      <CategorieCard
        :class="checkIfActif(categorie.id)
          ?'category-card category-card-enabled':'category-card category-card-disabled'"
        v-for="categorie in categoriesByDomaine"
        :key="categorie.id"
        @click.native="selectCategorie(categorie)"
      >
        <template v-slot:title>
          {{ categorie.nom }}
        </template>
        <template v-slot:content>
        </template>
      </CategorieCard>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CategorieCard from './CategorieCard.vue';

export default {
  data() {
    return {
      loading: false,
      equipementTypes: [],
      activeCategories: [],
    };
  },
  computed: {
    ...mapState(['selectedDomaine']),
    ...mapState('equipements', { categories: 'categories' }),
    ...mapState('equipements', { activesCategories: 'activesCategories' }),
    ...mapGetters('missions', { selectedMission: 'selected' }),

    categoriesByDomaine() {
      return this.categories.filter(
        categories => categories.domaine === this.selectedDomaine.id
        && categories.mission === this.selectedMission.id,
      );
    },

    domaineVide() {
      return !this.loading && (!this.categoriesByDomaine || !this.categoriesByDomaine.length);
    },
  },
  async created() {
    if (!this.categoriesByDomaine.length) {
      this.loading = true;
      await this.$store.dispatch('equipements/fetchAllCategories', { missionId: this.selectedMission.id, majRef: false });
      this.loading = false;
    }
  },
  components: {
    CategorieCard,
  },
  methods: {
    checkIfActif(id) {
      let actif = false;
      const actifCategorie = this.activesCategories.filter(categorie => categorie.id === id);
      if (actifCategorie.length > 0) {
        actif = true;
      }
      return actif;
    },

    selectCategorie(categorie) {
      this.$store.dispatch('selectCategorie', categorie);
      this.$emit('categorie-selected');
    },
  },
};
</script>

<style lang="scss" scoped>
  .list-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .categorie-wrapper {
    flex-grow: 1;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }
  .message-sheet {
    width: 100px;
    height: 100px;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;

    margin: auto;
    font-weight: 300;
    font-size: 1.6rem;
    text-align: center;
    background-color: transparent !important;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 15px;
  }
  .domaine-select {
    font-weight: 300;
    font-size: 23px;
    text-align: center;
  }
</style>
