<template>
  <div class="equipements-list-wrapper">
    <div
      class="empty-list-message"
      v-if="!batimentEquipements.length && !loading"
    >
      <v-sheet
        elevation="1"
        class="message-sheet"
      >
        Il n'y a pas encore d'équipements sur ce batiment.
      </v-sheet>
    </div>
      <v-tabs v-if="batimentEquipements.length"
      grow background-color="white"
      hide-slider active-class="active">
        <v-tab @change="changeList()" class="border-tab-1">Liste 1</v-tab>
        <v-tab @change="changeList()" class="border-tab-2">Liste 2</v-tab>
        <v-tab-item>
          <v-card flat>
            <EquipementsTreeView
              :parameters="parametersListUne"
              :whichList="1"
              :deselectItem="clearItem || listSwipe"
              @create-inter="$emit('create-inter', $event)"
              @edit-equipement="$emit('edit-equipement', $event)"
              @delete-equipement="$emit('delete-equipement', $event)"
              @duplicate-equipement="$emit('duplicate-equipement', $event)"
              @linked-equipements="$emit('linked-equipements', $event)"
              @delete-equipements="$emit('delete-equipements', $event)"
              @duplicate-equipements="$emit('duplicate-equipements', $event)"
              @loadingTreeView="handleLoading($event)"
              @showDialogPerfVet="$emit('showDialogPerfVet', $event)"
              @warnings="warnings = $event"
              @fabButtonLoading="$emit('fabButtonLoading', $event)"
              >
            </EquipementsTreeView>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <EquipementsTreeView
              :parameters="parametersListDeux"
              :deselectItem="clearItem || listSwipe"
              :whichList="2"
              @create-inter="$emit('create-inter', $event)"
              @edit-equipement="$emit('edit-equipement', $event)"
              @delete-equipement="$emit('delete-equipement', $event)"
              @duplicate-equipement="$emit('duplicate-equipement', $event)"
              @linked-equipements="$emit('linked-equipements', $event)"
              @delete-equipements="$emit('delete-equipements', $event)"
              @duplicate-equipements="$emit('duplicate-equipements', $event)"
              @loadingTreeView="handleLoading($event)"
              @showDialogPerfVet="$emit('showDialogPerfVet', $event)"
              @warnings="warnings = $event"
              @fabButtonLoading="$emit('fabButtonLoading', $event)"
              >
            </EquipementsTreeView>
          </v-card>
        </v-tab-item>
      </v-tabs>
      <FloatingWarning
        :messages="warnings"
      >
      </FloatingWarning>

  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import EquipementsTreeView from './EquipementsTreeView.vue';
import FloatingWarning from './FloatingWarning.vue';


export default {
  components: {
    EquipementsTreeView,
    FloatingWarning,
  },
  data() {
    return {
      loading: false,
      warnings: [],
      parametersListUne: [],
      parametersListDeux: [],
      listSwipe: false,
    };
  },
  computed: {
    ...mapGetters({
      batimentEquipements: 'equipements/selectedBatimentEquipements',
      selectedBatiment: 'batiments/selected',
    }),
  },
  props: ['clearItem'],
  created() {
    this.parametersListUne.paramUn = this.selectedBatiment
      .organisationListeEquipement.paramUnListeUne;
    this.parametersListUne.paramDeux = this.selectedBatiment
      .organisationListeEquipement.paramDeuxListeUne;
    this.parametersListDeux.paramUn = this.selectedBatiment
      .organisationListeEquipement.paramUnListeDeux;
    this.parametersListDeux.paramDeux = this.selectedBatiment
      .organisationListeEquipement.paramDeuxListeDeux;
  },
  methods: {
    changeList() {
      this.listSwipe = !this.listSwipe;
    },
    handleLoading(event) {
      this.loading = event;
      this.$emit('loadingEquipementList', event);
    },
  },
};
</script>

<style lang="scss" scoped>
  .border-tab-1{
    border-left: 2px solid #2D4388;
    border-top: 2px solid #2D4388;
    border-bottom: 2px solid #2D4388;
  }
  .border-tab-2 {
    border-right: 2px solid #2D4388;
    border-top: 2px solid #2D4388;
    border-bottom: 2px solid #2D4388;
  }
  .active {
    background-color: #2D4388;
    color: white;
  }
  .equipements-list-wrapper {
    height: 50%;
    width: 98%;
    padding: 1rem 0 6rem 0;
    background-color: $app-grey;
    margin: 10px;


    .no-data {
      height: 100%;
      padding: 20%  1rem 0 1rem;

      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .equipements-list-wrapper > div:last-child {
    margin-bottom: 4rem;
  }

  .message-sheet {
    margin: 4rem;
    padding: 2rem;
    font-weight: 300;
    font-size: 1.6rem;
    text-align: center;
    background-color: $app-white;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 15px;
  }
</style>
