<template>
  <BaseCard class="toolbar-card">
    <template v-slot:title>
      Actions
    </template>
    <template v-slot:content>
      <div class="toolbar-wrapper">
        <div class="action-button-wrapper">
          <div>
            <v-btn
            outlined fab small color="primary"
            @click.native="goToCollect()">
              <v-icon>mdi-clipboard-text </v-icon>
            </v-btn>
            <v-icon v-if="selectedSite.inAlert.inAlertCollectInspection" class="field-icon-warning">
                mdi-alert-circle
            </v-icon>
          </div>
          <div class="button-title">Collecter</div>
        </div>
        <div class="action-button-wrapper">
          <v-btn
          outlined fab small color="primary"
          @click.native="goToSiteAnalyse()">
            <v-icon>mdi-wall </v-icon>
          </v-btn>
          <div class="button-title">Analyser</div>
        </div>
        <div class="action-button-wrapper">
          <v-btn
          outlined fab small color="primary"
          @click.native="goToInter()">
            <v-icon>mdi-hammer-wrench </v-icon>
          </v-btn>
          <div class="button-title">Intervenir</div>
        </div>
        <div class="action-button-wrapper">
          <v-btn
          outlined fab small color="primary"
          @click.native="goToProgrammes()"
          >
            <v-icon>mdi-chart-line</v-icon>
          </v-btn>
          <div class="button-title">Programmer</div>
        </div>

        <div class="action-button-wrapper">
          <v-btn
          outlined fab small color="primary"
          @click.native="goToReport()"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <div class="button-title">Restituer</div>
        </div>
      </div>
    </template>
  </BaseCard>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import BaseCard from '../BaseCard.vue';


export default {
  components: { BaseCard },

  computed: {
    ...mapGetters({
      selectedSite: 'sites/selected',
      selectedMission: 'missions/selected',
      repartitions: state => state.repartitions.repartitions,
    }),

    ...mapState('repartitions', ['repartitions']),

    ...mapState({
      sites: state => state.sites.sites,
      compteurs: state => state.compteurs.compteurs,
    }),

    siteCompteurs() {
      if (this.compteurs && this.selectedSite) {
        return this.compteurs
          .filter(compteur => this.selectedSite.compteurs.includes(compteur.id));
      }
      return [];
    },
  },

  props: ['featuredItem', 'loading'],

  created() {
    this.$store.dispatch('compteurs/fetchAllBySite', this.selectedSite.id)
      .finally(() => {
        this.siteCompteurs.forEach((compteur) => {
          this.$store.dispatch('repartitions/fetchRepartitionByIdCompteur', compteur.id);
        });
      });
  },

  methods: {
    goToCollect() {
      this.$router.push({ name: 'collectSite' });
    },

    goToInter() {
      this.$router.push({ name: 'siteInterventions' });
    },

    goToReport() {
      this.$router.push({ name: 'siteRapport' });
    },

    goToProgrammes() {
      this.$router.push({
        name: 'programmes',
      });
    },

    goToSiteAnalyse() {
      this.$router.push({
        name: 'analyseSite',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.toolbar-card {
   width: 100%;
 }
 .toolbar-wrapper {
   width: 100%;
   display: flex;
   flex-flow: row wrap;
   justify-content: space-around;
   align-items: center;
 }

 .action-button-wrapper {
   display: flex;
   flex-flow: column nowrap;
   justify-content: center;
   align-items: center;
   text-align: center;
 }

 .button-title {
   margin-top: 0.2rem;
   color: #2D4388;
 }

.field-icon-warning{
  color:orange;
  bottom:15px;
  width:5%;
  // z-index: 10;
  position: relative;
}
</style>
