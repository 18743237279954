<template>
  <v-list v-if="missions.length">
    <template
      v-for="(mission, i) in missions"
    >
      <v-list-item
        ripple
        :key="`mission_${i}`"
        @click.native="$emit('mission-clicked', mission)"
      >
        <v-list-item-content>
          <v-list-item-title class="pointer">{{ mission.nom }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-list-item-action-text>
            {{ mission.nbSites }} sites
          </v-list-item-action-text>
        </v-list-item-action>
      </v-list-item>

      <v-divider
        v-if="i + 1 < missions.length"
        :key="`dvd_${i}`"
        class="mission-list-divider"
      ></v-divider>
    </template>
  </v-list>
</template>

<script>
export default {
  props: ['missions'],

  methods: {
    /* getMissionSites(mission) {
      return mission.sites ? mission.sites.length : 0;
    }, */
  },
};
</script>

<style lang="scss" scoped>
  .mission-list-divider {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .pointer {
    cursor: pointer;
  }
</style>
