/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import { imageCataloguesEndpoint } from './apiUrls';

export default {
  db: new PouchDB('imageCatalogues'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch
    const _id = obj._id || obj.id.toString();

    return {
      _id,
      meta: obj.meta || {},
      id: String(obj.id),
      nom: obj.nom,
      codeImageCatalogue: obj.codeImageCatalogue,
      image: obj.image,
    };
  },

  create(imageCatalogue) {
    return new Promise((resolve, reject) => {
      const imageCatalogueToSave = { ...imageCatalogue };
      delete imageCatalogueToSave.image;
      axiosInstance.axiosInstance.post(imageCataloguesEndpoint, imageCatalogueToSave)
        .then((response) => {
          resolve(this.convertBackendObject(response.data));
        })
        .catch((error) => { reject(error); });
    });
  },

  update(imageCatalogueId, imageCatalogue) {
    return new Promise((resolve, reject) => {
      const imageCatalogueToSave = { ...imageCatalogue };
      delete imageCatalogueToSave.image;
      axiosInstance.axiosInstance.put(`${imageCataloguesEndpoint}/${imageCatalogueId}`, imageCatalogueToSave)
        .then((response) => {
          resolve(this.convertBackendObject(response.data));
        })
        .catch((error) => { reject(error); });
    });
  },

  async delete(imageCatalogueId) {
    // delete the imageCatalogue from pouchDB
    try {
      await pouchUtil.deleteDoc(imageCatalogueId, this.db);
    } catch (e) {
      console.log(e.response);
    }
    // if the delete from pouchDB is sucessfull, delete from the server
    return axiosInstance.axiosInstance.delete(`${imageCataloguesEndpoint}/${imageCatalogueId}`);
  },

  /**
   * get a imageCatalogue by his id
   * @param {String} imageCatalogueId - the id of the imageCatalogue to get
   */
  async get(imageCatalogueId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${imageCataloguesEndpoint}/${imageCatalogueId}`);
      const imageCatalogue = this.convertBackendObject(response.data);

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDoc(imageCatalogue, this.db);
    } catch (e) {
      console.error('imageCataloguesApi get error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getDoc(imageCatalogueId, this.db);
    }
  },

  /**
   * get a imageCatalogue by his id for offline use
   * @param {String} imageCatalogueId - the id of the imageCatalogue to get
   */
  async fetchOffline(imageCatalogueId) {
    const response = await axiosInstance.axiosInstance.get(`${imageCataloguesEndpoint}/${imageCatalogueId}`);
    const imageCatalogue = this.convertBackendObject(response.data);
    // if get is successful,  store data into pouchdb and return it
    return pouchUtil.addDoc(imageCatalogue, this.db);
  },

  /**
   * get all imageCatalogues of the application
   */
  async getAll() {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${imageCataloguesEndpoint}`);
      const imageCatalogues = response.data.map(
        imageCatalogue => this.convertBackendObject(imageCatalogue),
      );

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(imageCatalogues, this.db);
    } catch (e) {
      console.error('imageCataloguesApi getAll error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocs(this.db);
    }
  },

  /**
   * Fetch all imageCatalogues stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocs(this.db);
  },
};
