<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div class="table-wrapper">

    <div class="table-title-block">
      <div class="table-title">Equipements</div>
      <div class="table-filter">
        <v-select
          v-model="filter"
          :items="['tous', 'sélectionnés']"
          label="Voir"
          outlined
          hide-details
          dense
          attach
        ></v-select>
      </div>
    </div>

    <v-data-table
      class="equipements-table"
      v-model="equipementsSelected"
      :items="tableEquipements.items"
      :headers="headers"
      :single-select="singleSelect"
      show-select
      disable-pagination
      hide-default-footer
      @item-selected="touched = true"
      @toggle-select-all=" touched = true"
      checkbox-color="green"
    >
    <template v-for="header in headers" v-slot:[`header.${header.value}`]>
  <span tabindex="0" v-if="header.value !== 'image'">
    {{ header.text }}
  </span>
  <template v-if="header.value === 'image'">
    <span tabindex="0" class="checkbox-image">
      <v-checkbox
        v-model="selectAllImages"
        @change="toggleAllImages"
        :indeterminate="indeterminateImages"
        color="green"
      ></v-checkbox>
      {{ header.text }}
    </span>
  </template>
</template>
  <template v-slot:[`item.image`]="{ item }">
    <v-checkbox
      v-model="equipementsImagesSelected"
      :value="item.id"
      color="green"
    ></v-checkbox>
  </template>

    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    equipements: {
      type: Array,
      default: () => [],
    },
    singleSelect: {
      type: Boolean,
      default: false,
    },
    initialList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      filter: 'toutes',
      headers: [
        { text: 'nom', value: 'nom' },
        { text: 'Avec image(s)', value: 'image', sortable: false },
      ],
      equipementsSelected: [],
      equipementsImagesSelected: [],
      touched: false,
      selectAllImages: false,
      indeterminateImages: false,
    };
  },

  computed: {
    mappedInterventions() {
      return this.interventions.map(inter => this.mapIntervention(inter));
    },

    tableEquipements() {
      if (this.filter === 'sélectionnés') {
        return this.equipements
          .filter(equipement => this.equipementsSelected
            .find(selectedEqp => selectedEqp.id === equipement.id));
      }
      const itemsList = { items: this.equipements, images: false };
      return itemsList;
    },
  },

  watch: {
    equipementsSelected() {
      this.$emit('new-selection', this.equipementsSelected);
      this.$emit('images-selection', this.equipementsImagesSelected);
    },
    equipementsImagesSelected() {
      this.$emit('new-selection', this.equipementsSelected);
      this.$emit('images-selection', this.equipementsImagesSelected);
    },
  },

  methods: {
    toggleAllImages() {
      if (this.selectAllImages) {
      // Select all images while maintaining order
        const selectedIdsSet = new Set(this.equipementsSelected.map(item => item.id));
        this.equipementsImagesSelected = this.tableEquipements.items
          .filter(item => selectedIdsSet.has(item.id))
          .map(item => item.id);
        this.indeterminateImages = false;
      } else {
      // Deselect all images
        this.equipementsImagesSelected = [];
        this.indeterminateImages = false;
      }
    },


  },
};
</script>

<style lang="scss" scoped>
  .table-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    height: 100%;

    .table-title-block {
      flex: 0 0 auto;
      padding: 0.5rem;
      height: 3.5rem;

      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;


      .table-title {
        font-weight: 300;
        font-size: 1.4rem;
      }

      .table-filter {
        margin-right: 0;
        margin-left: auto;
        width: 9.5rem;
      }
    }


    .equipements-table {
      flex: 1 1 auto;
      height: 100%;
      overflow-y: auto;
      padding-bottom: 5rem;
    }
  }
  .checkbox-image{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
  }
</style>
