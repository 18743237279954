/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';

import {
  equipementsEndpoint,
} from './apiUrls';

export default {
  db: new PouchDB('equipementsLieOffline'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch
    const _id = obj._id || obj.id.toString(); /* eslint-disable-line no-underscore-dangle */
    const meta = {};

    return {
      _id,
      meta,
      id: String(obj.id),
      nom: obj.nom,
      linkedEquipements: obj.linkedEquipements,
      done: false,
    };
  },

  async create(equipement, equipementId) {
    // on créé un objet uniquement pour la sauvegarde locale si pas de connexion
    const localEquipement = {
      id: equipementId,
      ...equipement,
    };

    const equipementToSave = { ...equipement };
    delete equipementToSave.id;
    delete equipementToSave._id;
    delete equipementToSave._rev;
    delete equipementToSave.meta;

    return pouchUtil.addDoc(localEquipement, this.db, { toCreate: true });
  },

  async update(equipement, equipementId) {
    let savedEquipement = null;
    const equipementToSave = { ...equipement };
    try {
      // on essaye de sauvegarder via l'API
      const response = await axiosInstance.axiosInstance.put(`${equipementsEndpoint}/${equipementId}`, equipementToSave);
      savedEquipement = this.convertBackendObject(response.data);
    } catch (e) {
      if (e.response
        && e.response.status >= 400
        && e.response.status < 500) {
        throw new Error(e);
      }
      // si la sauvegarde en ligne a échouée, on enregistre les données dans
      // le cache avec le flag 'toUpdate' à true afin de relancer la synchro
      // quand on aura à nouveau une connexion
      return pouchUtil.addDoc({ id: equipementId, ...equipement }, this.db, { toUpdate: true });
    }

    // si la sauvegarde en ligne à reussie, on sauvegarde dans pouchDB et on retourne le resultat
    return pouchUtil.addDoc(savedEquipement, this.db, { updated: true });
  },

  /**
   * synchronise a equipement with the server, creating or updating it
   */
  async synchronise(equipement) {
    // update
    try {
      const equipementToUpdate = { ...equipement };
      delete equipementToUpdate.nom;

      // update equipement on the server
      const response = await axiosInstance.axiosInstance.put(`${equipementsEndpoint}/${equipement.id}`, equipementToUpdate);
      const savedEquipement = this.convertBackendObject(response.data);
      await this.handleDuplicatedElement(equipement.id);
      savedEquipement.done = true;
      // save equipement in pouch and return it
      const pouchEquipement = await pouchUtil.addDoc(savedEquipement, this.db, { updated: true });
      return { savedItem: pouchEquipement };
    } catch (e) {
      // if update fail and functionnal error -> save on pouch with error
      if (e.response
          && e.response.status >= 400
          && e.response.status < 500) {
        const pouchEquipement = await pouchUtil.addDoc(equipement, this.db, {
          toUpdate: true,
          error: true,
          errorMessage: e.response.data.detail,
        });
        return { savedItem: pouchEquipement };
      }
      // if it's not a functionnal error, stop synchro and throw error
      throw new Error(e);
    }
  },

  async handleLinkCreated(tempId, newId) {
    let childrens;
    let updatedChildrens;
    // eslint-disable-next-line prefer-const
    childrens = await pouchUtil.getAllDocsByField('id', tempId, this.db);
    if (childrens.length) {
      updatedChildrens = childrens.map(children => ({
        ...children,
        id: newId,
      }));
      // save them back on PouchDB
      await this.db.bulkDocs(updatedChildrens);
    }
  },
  async handleLinkChildrenCreated(tempId, newId) {
    let childrens;
    let updatedChildrens;
    // eslint-disable-next-line prefer-const
    childrens = await pouchUtil.getAllDocs(this.db);
    if (childrens.length) {
      updatedChildrens = childrens.map((children) => {
        if (children.linkedEquipements.length && children.linkedEquipements.includes(tempId)) {
          const index = children.linkedEquipements.findIndex(el => el === tempId);
          // eslint-disable-next-line no-param-reassign
          children.linkedEquipements[index] = newId;
        }
        return children;
      });
      // save them back on PouchDB
      await this.db.bulkDocs(updatedChildrens);
    }
  },
  async handleDuplicatedElement(newId) {
    let linkedEquipements;
    let updatedEquipement;
    // eslint-disable-next-line prefer-const
    linkedEquipements = await pouchUtil.getAllDocs(this.db);
    if (linkedEquipements.length) {
      updatedEquipement = linkedEquipements.map((equipement) => {
        if (equipement.linkedEquipements.length && equipement.linkedEquipements.includes(newId)) {
          const index = equipement.linkedEquipements.indexOf(newId);
          equipement.linkedEquipements.splice(index, 1);
        }
        return equipement;
      });
      // save them back on PouchDB
      await this.db.bulkDocs(updatedEquipement);
    }
  },

  /**
   * synchronise with the server all equipement that need to be saved
   */
  async synchroniseAll() {
    // get all equipements in PouchDB with meta.create === true or meta.update === true
    const equipementsToSave = await pouchUtil.getAllDocsToSync(this.db);
    return Promise.all(equipementsToSave.map(equipement => this.synchronise(equipement)));
  },

  /**
   * delete a equipement from pouchDB and the server
   */
  async delete(equipementId) {
    // delete the equipement from pouchDB
    await pouchUtil.deleteDoc(equipementId, this.db);
    // delete any picture not yet syncronized
    if (!equipementId.startsWith('temp')) {
      // if the delete from pouchDB is sucessfull, delete from the server
      return axiosInstance.axiosInstance.delete(`${equipementsEndpoint}/${equipementId}`);
    }

    return true;
  },

  /**
   * get one equipement by his id
   * @param {String} equipementId - the id of the equipement to get
   */
  async get(equipementId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${equipementsEndpoint}/${equipementId}`);
      const equipement = this.convertBackendObject(response.data);

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDoc(equipement, this.db);
    } catch (e) {
      console.error('equipementsApi get error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getDoc(equipementId, this.db);
    }
  },

  /**
   * get all equipements of the application
   */
  async getAll() {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${equipementsEndpoint}`);
      const equipements = response.data.map(equipement => this.convertBackendObject(equipement));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(equipements, this.db);
    } catch (e) {
      console.error('equipementsApi getAll error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocs(this.db);
    }
  },
  async linkedEquipemetsToSync() {
    const linkedElement = (await pouchUtil.getAllDocs(this.db)).filter(element => !element.done);
    return linkedElement;
  },


  /**
   * Fetch all equipements stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocsToSync(this.db);
  },
};
