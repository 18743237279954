<template>
  <div class="domaine-card">
    <slot name="icon"></slot>
    <h2><slot name="title"></slot></h2>
  </div>
</template>

<script>
export default {
  props: ['domaine'],
};
</script>

<style lang="scss" scoped>

 .domaine-card {
    padding: 1rem 2rem 1rem 2rem;
    margin-bottom: 0.5rem;
    cursor: pointer;

    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;

    &:hover {
      box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;

    width: 21rem;
    height: 5rem;
  }

  .domaine-card h2 {
    font-weight: bold;
  }

  .domaine-card-enabled h2 {
    font-weight: bolder;
  }

  .domaine-card-disabled {
    display: none;
  }
</style>
