
import SettingsView from '../views/SettingsView.vue';
import ImageBankView from '../views/Settings/ImageBank.vue';
import ReferentialView from '../views/Settings/ReferentialView.vue';

export default [
  {
    path: '/settings',
    name: 'settings',
    meta: {
      libelle: 'PARAMETRES',
      requiresAuth: true,
    },
    component: SettingsView,
  },
  {
    path: '/imageBank',
    name: 'imageBank',
    meta: {
      libelle: 'BANQUE D\'IMAGES ',
      requiresAuth: true,
    },
    component: ImageBankView,
  },
  {
    path: '/referentialView',
    name: 'referentialView',
    meta: {
      libelle: 'Gestion des référentiels',
      requiresAuth: true,
    },
    component: ReferentialView,
  },
];
