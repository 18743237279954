/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  apiUrl,
  refUrl,
  missionsUrl,
  equipementCategoriesUrl,
  equipementTypesUrl,
} from './apiUrls';

export default {
  db: new PouchDB('equipementForms'),

  convertBackendObject(obj, missionId, equipementType) {
    // set an _id attribute for pouch
    const _id = obj._id || `${missionId}_${equipementType}_form`; /* eslint-disable-line no-underscore-dangle */
    const meta = {};

    return {
      _id,
      meta,
      id: _id,
      formTemplate: obj.formTemplate,
      categorie: obj.categorie.replace(`${apiUrl}${refUrl}${missionsUrl}/${missionId}${equipementCategoriesUrl}/`, ''),
    };
  },

  // /**
  //  * get all equipemnentForms for a mission
  //  * @param {String} missionId - id of the parent mission
  //  */
  // async getAllByMission(missionId) {
  //   try {
  //     const response =
  //  await axiosInstance.axiosInstance
  // .get(`${apiUrl}${refUrl}${missionsUrl}/${missionId}${listeOptionsUrl}`);
  //     const options = response.data
  //       .map(option => this.convertBackendObject(option, missionId));

  //     // if get is successful,  store data into pouchdb and return it
  //     return pouchUtil.addDocs(options, this.db);
  //   } catch (e) {
  //     console.error('listeOptions getAllByMission error : ', e);
  //     // if get from server fail
  //     // check if we have data in pouchdb and return it
  //     return pouchUtil.getAllDocsByIdPrefix(`${missionId}_`, this.db);
  //   }
  // },

  /**
   * get a equipement form from it's type and mission id
   * @param {String} missionId - id of the parent mission
   * @param {String} type - the equipementType to search for
   */
  async getByType(missionId, type) {
    const shortType = type.slice(type.lastIndexOf('/') + 1);
    try {
      const response = await axiosInstance.axiosInstance.get(`${apiUrl}${refUrl}${missionsUrl}/${missionId}${equipementTypesUrl}/${shortType}/form`);
      const form = this.convertBackendObject(response.data, missionId, shortType);

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDoc(form, this.db);
    } catch (e) {
      console.error('equipementForms getByType error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getDoc(`${missionId}_${shortType}_form`, this.db);
    }
  },

  /**
   * get all equipementTypeForms for a mission for offline use
   * @param {String} missionId - id of the parent mission
   */
  async fetchOffline(missionId) {
    const response = await axiosInstance.axiosInstance.get(`${apiUrl}${refUrl}${missionsUrl}/${missionId}${equipementTypesUrl}/form`);
    const equipementForms = response.data
      .map(form => this.convertBackendObject(form, missionId, form.code));
    // if get is successful,  store data into pouchdb and return it
    return pouchUtil.addDocs(equipementForms, this.db);
  },

  /**
   * Fetch all equipementDomaines stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocs(this.db);
  },
};
