<template>
  <div>
    <div class="title">
      <p class="fields-group-title"> {{ this.title }} </p>
      <v-btn
          color="primary"
          :outlined="!showInformationDialogue"
          class="white--text"
          fab
          x-small
          @click="changeShowInformationDialogue()"
        ><v-icon>
        mdi-information-variant
        </v-icon>
      </v-btn>
    </div>
    <v-textarea
      v-if="showInformationDialogue"
      outlined
      label="Informations"
      disabled
      v-model="precision"
      auto-grow
    >
    </v-textarea>
  </div>
</template>


<script>
export default {
  props: ['title', 'precision', 'showParentInformation'],
  data() {
    return {
      consoError: false,
      showInformationDialogue: true,
    };
  },
  methods: {
    changeShowInformationDialogue() {
      if (!this.showInformationDialogue) {
        this.showInformationDialogue = true;
      } else {
        this.showInformationDialogue = false;
      }
    },
    changeParentShowInformationDialogue(val) {
      this.showInformationDialogue = val;
    },
  },
  watch: {
    showParentInformation(val) {
      this.changeParentShowInformationDialogue(val);
    },
  },
};
</script>

<style lang="scss" scoped>

.title{
  display: flex;
  justify-content: center;
}
.fields-group-title {
  font-size: 1.5rem;
  font-weight: 300;
  margin-bottom: 1rem;
  padding-right: 0.5em;
  color: $app-blue;
}

</style>
