/* eslint-disable no-console */

import auditTypes from './auditTypesApi';
import clients from './clientsApi';
import compteurs from './compteursApi';
import factures from './facturesApi';
import missions from './missionsApi';
import patrimoines from './patrimoinesApi';
import planActions from './planActionsApi';
import equipements from './equipementsApi';
import equipementForms from './equipementFormsApi';
import equipementLie from './equipementLieApi';
import sites from './sitesApi';
import batiments from './batimentsApi';
import niveaux from './niveauxApi';
import repartitions from './repartitionsApi';
import listeOptions from './listeOptionsApi';
import offlineMissionList from './offlineMissionListApi';
import offlineSiteList from './offlineSiteListApi';
import inspections from './inspectionsApi';
import inspectionTypeForms from './inspectionTypeFormsApi';
import interventions from './interventionsApi';
import interventionSites from './interventionSitesApi';
import scenarios from './scenariosApi';
import programmes from './programmesApi';
import imageCatalogues from './imageCataloguesApi';
import me from './meApi';

import refEquipementDomaines from './refEquipementDomainesApi';
import refEquipementCategories from './refEquipementCategoriesApi';
import refEquipementTypes from './refEquipementTypesApi';
import refInspectionTypes from './refInspectionTypesApi';


import pictures from './picturesApi';

export default {
  auditTypes,
  clients,
  compteurs,
  factures,
  missions,
  patrimoines,
  planActions,
  sites,
  equipements,
  equipementForms,
  equipementLie,
  batiments,
  niveaux,
  inspections,
  inspectionTypeForms,
  interventions,
  interventionSites,
  refEquipementDomaines,
  refEquipementCategories,
  refEquipementTypes,
  repartitions,
  listeOptions,
  offlineMissionList,
  offlineSiteList,
  refInspectionTypes,
  pictures,
  scenarios,
  programmes,
  imageCatalogues,
  me,
};
