<template>
  <v-tabs vertical class="onglet" color="tertiary">
    <v-tab
    v-for="(struct,s) in structures"
      :key="s"
      class="onglet-item">
      <v-icon left>
        mdi-format-list-text
      </v-icon>
      {{ struct.nom }}
    </v-tab>
    <v-tab-item
    v-for="(struct,s) in structures"
      :key="s">
      <v-card flat>
        <v-card-text>
          <!-- Report Blocs -->
          <div class="en-tete-struct">
            <h4>{{ struct.nom }}</h4>
            <v-btn
              @click="$emit('download', struct.id)"
              color="primary"
              :loading="isDownload"
              :disabled="isDownload"
            >
              Télécharger
              <v-icon>
                mdi-download
              </v-icon>
            </v-btn>
          </div>
          <div class="bloc-list">
            <v-expansion-panels
              v-if="struct.structure.length"
              popout
              focusable

            >
              <v-expansion-panel
                v-for="(block,i) in struct.structure"
                :key="i"
              >
                <v-expansion-panel-header v-if="removeEmptyInspection(block)">
                  <div class="container">
                    <div class="parent">
                    <h5 class="subHeader inline-block-child">
                      {{getLevelTitle(block, struct.structure)}}</h5>
                      <h2 v-if="displayBlocTitle(block)"
                        class="titleHeader inline-block-child">
                    {{ getBlocTitle(block) }}</h2> </div>
                  <h5 class="subHeader">{{getLevelTitle(block, struct.structure)}}
                    {{ block.label }}
                    {{getBlocCodeLabel(block)}}</h5>
                  </div>
                  </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <component
                    :block="block"
                    :objectIri="itemUrl"
                    :is="getBlocContentComponent(block.blockType)"
                    :structureId="struct.id"
                    :structure="struct.structure"
                  >
                  </component>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </v-card-text>
      </v-card>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import MethodsStructureMixin from '@/mixins/MethodsStructureMixin';
import BaseBlockForm from '../BaseBlockForm.vue';
import ImageBlock from '../ImageBlock.vue';

export default {
  components: {
    BaseBlockForm,
    ImageBlock,
  },
  props: ['structures', 'isDownload', 'itemUrl'],
  data() {
    return {
      file: null,
      nom: null,
    };
  },
  mixins: [
    MethodsStructureMixin,
  ],
};

</script>
<style lang="scss" scoped>
  .onglet{
    max-width: 1000px;
  }
  .onglet-item{
    justify-content: left;
  }
  .en-tete-struct{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 5px;
    > * {
      margin: 10px;
    }
  }
  .bloc-list {
    height: 38rem;
    overflow-y: scroll;
    width: 104%;
  }
  .container{
    //white-space: pre;
    padding: 0;
    cursor: default !important;
  }
  .parent{
    cursor: default !important;
  }
  .subHeader{
    white-space: pre;
    font-style: italic;
    cursor: default !important;
  }
  .titleHeader{
    padding-left: 0;
    left: 0;
  }
</style>
