<template>
  <div>
    <div v-for="(travaux, index) in localValue"
    :key="'work' + index">
      <p class="annee-work-history"> {{ travaux.annee }} </p>
      <p class="nature-work-history"> {{ travaux.nature }} </p>
    </div>
    <v-btn
    @click="showBottomSheet = true"
    color="primary"
    class="btn-travaux">
    Editer l'historique
    </v-btn>
    <BottomSheetEditWorkHistory
    :sheet="showBottomSheet"
    :defaultValue="localValue"
    @close-sheet="showBottomSheet = false"
    @change="handleChange"
  >
  </BottomSheetEditWorkHistory>
  </div>
</template>

<script>
import BottomSheetEditWorkHistory from '../BottomSheetEditWorkHistory.vue';

export default {
  name: 'display-work-history-field',
  props: {
    defaultValue: null,
  },
  data() {
    return {
      localValue: this.defaultValue,
      showBottomSheet: false,
    };
  },
  methods: {
    handleChange(event) {
      this.localValue = event;
      this.showBottomSheet = false;
      this.$emit('change', this.localValue);
    },
  },
  components: {
    BottomSheetEditWorkHistory,
  },
  computed: {
    fieldOptions() {
      return {
        readonly: true,
        filled: true,
        dense: true,
      };
    },
  },
};
</script>

<style scoped>
.annee-work-history {
  margin-bottom: 0;
  font-weight: 500;
}
.nature-work-history {
  margin-bottom: 0;
}
.btn-travaux {
  margin-bottom:16px;
  margin-top:16px;
}
</style>
