/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  apiUrl,
  compteursEndpoint,
  repartitionsEndpoint,
  batimentsEndpoint,
  compteursUrl,
  batimentsUrl,
  repartitionsUrl,
} from './apiUrls';

export default {
  db: new PouchDB('repartitions'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch
    const _id = obj._id || obj.id.toString();
    let objPostes;
    if (obj.postes.length === 0) {
      objPostes = {};
    } else {
      objPostes = obj.postes;
    }

    return {
      ...obj,
      _id,
      meta: obj.meta || {},
      id: String(obj.id),
      batiment: obj.batiment.replace(`${batimentsEndpoint}/`, ''),
      compteur: obj.compteur.replace(`${compteursEndpoint}/`, ''),
      postes: objPostes,
      inAlert: obj.inAlert,
    };
  },

  create(repartition) {
    return new Promise((resolve, reject) => {
      axiosInstance.axiosInstance.post(repartitionsEndpoint, repartition)
        .then((response) => {
          resolve(this.convertBackendObject(response.data));
        })
        .catch((error) => { reject(error); });
    });
  },

  update(repartitionId, repartition) {
    return axiosInstance.axiosInstance.put(`${repartitionsEndpoint}/${repartitionId}`, repartition)
      .then(response => this.convertBackendObject(response.data));
  },

  /**
   * When a new batiment is created, if this batiment has unsaved repartitions children
   * update those repartitions to replace the tempBatimentId wit the new id
   */
  async handleParentCreated(tempBatimentId, newBatimentId) {
    // get all the niveaux that are in the batiment
    const childrens = await pouchUtil.getAllDocsByField('batiment', tempBatimentId, this.db);

    if (childrens.length) {
      // update the children with the new site id
      const updatedChildrens = childrens.map(children => ({
        ...children,
        batiment: newBatimentId,
      }));
      // save them back on PouchDB
      await this.db.bulkDocs(updatedChildrens);
    }
  },

  async delete(repartitionId) {
    try {
      const _id = repartitionId.toString();
      const { _rev } = await this.db.get(_id);
      await this.db.remove(_id, _rev);

      return axiosInstance.axiosInstance.delete(`${repartitionsEndpoint}/${repartitionId}`);
    } catch (e) {
      return e;
    }
  },

  /**
   * get one repartition by his id
   * @param {String} repartitionId - the id of the repartition to get
   */
  async get(repartitionId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${repartitionsEndpoint}/${repartitionId}`);
      const repartition = this.convertBackendObject(response.data);

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDoc(repartition, this.db);
    } catch (e) {
      console.error('batimentsApi get error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getDoc(repartitionId, this.db);
    }
  },

  /**
   * get all repartitions of the application
   */
  async getAll() {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${repartitionsEndpoint}`);
      const repartitions = response.data.map(repartition => this.convertBackendObject(repartition));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(repartitions, this.db);
    } catch (e) {
      console.error('repartitionsApi getAll error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocs(this.db);
    }
  },

  /**
   * get all children repartitions of a compteur
   * @param {String} compteurId - id of the parent compteur
   */
  async getAllByCompteur(compteurId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${apiUrl}${compteursUrl}/${compteurId}${repartitionsUrl}`);
      const repartitions = response.data.map(repartition => this.convertBackendObject(repartition));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(repartitions, this.db);
    } catch (e) {
      console.error('batimentsApi getAllByCompteur error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByField('compteur', compteurId, this.db);
    }
  },

  /**
   * get all children repartitions of a batiment
   * @param {String} BatimentId - id of the parent batiment
   */
  async getAllByBatiment(BatimentId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${apiUrl}${batimentsUrl}/${BatimentId}${repartitionsUrl}`);
      const repartitions = response.data.map(repartition => this.convertBackendObject(repartition));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(repartitions, this.db);
    } catch (e) {
      console.error('batimentsApi getAllByBatiment error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByField('batiment', BatimentId, this.db);
    }
  },

  /**
   * get all children repartitions of a compteur for offline use
   * @param {String} compteurId - id of the parent compteur
   */
  async fetchOffline(compteurId) {
    const response = await axiosInstance.axiosInstance.get(`${apiUrl}${compteursUrl}/${compteurId}${repartitionsUrl}`);
    const repartitions = response.data.map(repartition => this.convertBackendObject(repartition));
    // if get is successful,  store data into pouchdb and return it
    return pouchUtil.addDocs(repartitions, this.db);
  },

  /**
   * Fetch all repartitions stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocs(this.db);
  },
};
