<template>
  <ValidationProvider
    :name="formField.name"
    :rules="formField.rules"
    v-slot="{ errors }"
  >
    <div v-cloak>
        <v-row no-gutters>
          <v-col cols="9">

            <v-text-field
              class="gps-field"
              v-bind="fieldOptions"
              :error-messages="errors"
              v-model="localValue"
              @change="$emit('change', localValue)"
            >
              <template v-slot:label>
                <slot name="field-label"></slot>
              </template>
            </v-text-field>

          </v-col>
          <v-col cols="1">

            <v-btn
              color="primary"
              class="gps-btn"
              tile
              depressed
              height="40px"
              @click="getPosition()"
            >
              <v-icon>mdi-map-marker</v-icon>
            </v-btn>

          </v-col>
        </v-row>
    </div>
  </ValidationProvider>
</template>

<script>

import { ValidationProvider } from 'vee-validate';

export default {
  name: 'gps-field',
  components: {
    ValidationProvider,
  },
  props: {
    commonOptions: Object,
    defaultValue: null,
    formField: Object,
  },
  data() {
    return {
      gettingLocation: false,
      errorStr: null,
      marker: true,
      localValue: this.defaultValue,
    };
  },
  computed: {
    fieldOptions() {
      return {
        ...this.commonOptions,
        placeholder: this.formField.placeholder,
        suffix: this.formField.unit,
      };
    },
  },
  watch: {
    defaultValue(newValue) {
      this.localValue = newValue;
    },
  },
  methods: {
    getPosition() {
      // do we support geolocation
      if (!('geolocation' in navigator)) {
        this.$store.dispatch('snackbar/displaySnack', { message: 'La géolocalisation n\'est pas disponible', type: 'warn' });
        return;
      }

      this.gettingLocation = true;
      // get position
      navigator.geolocation.getCurrentPosition((pos) => {
        this.gettingLocation = false;
        // this.fieldValue = `${pos.coords.latitude}, ${pos.coords.longitude}`;
        const position = `${pos.coords.latitude}, ${pos.coords.longitude}`;
        this.localValue = position;
        this.$emit('change', this.localValue);
      }, (err) => {
        this.gettingLocation = false;
        this.errorStr = err.message;
        this.$store.dispatch('snackbar/displaySnack', { message: 'Impossible d\'accèder à la géolocalisation. Veuillez vérifier que l\'application est autorisée à utiliser votre géolocalisation dans vos paramètres.', type: 'warn' });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .gps-field {
    border-top-right-radius:0;
    border-bottom-right-radius:0;
    margin-bottom: 0;
  }
  .gps-btn {
    border-top-right-radius:5px;
    border-bottom-right-radius:5px;
  }
</style>
