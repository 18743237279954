<template>
  <v-bottom-sheet
    v-model="sheet"
    fullscreen
  >
    <v-sheet class="sheet-wrapper">

      <h1 class="bottom-sheet-title">Vos informations</h1>
      <v-card
        max-width="375"
        class="mx-auto"
      >
        <v-list two-line>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">
                mdi-account
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-subtitle>Login</v-list-item-subtitle>
              <v-list-item-title>{{user.username}}</v-list-item-title>
            </v-list-item-content>

            <!--<v-list-item-icon>
              <v-icon>mdi-message-text</v-icon>
            </v-list-item-icon>-->
          </v-list-item>

          <v-list-item>
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-subtitle>Prénom</v-list-item-subtitle>
              <v-list-item-title>{{user.givenName}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item>
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-subtitle>Nom</v-list-item-subtitle>
              <v-list-item-title>{{user.familyName}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">
                mdi-email
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-subtitle>Email</v-list-item-subtitle>
              <v-list-item-title>{{user.email}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>

          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">
                mdi-clipboard-text
              </v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-subtitle>Rôle</v-list-item-subtitle>
              <v-list-item-title>{{user.roles.toString().replace(/[_]/g,' ')}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card>
      <div class="bottom-sheet-footer">
        <v-btn
          class="bottom-sheet-button"
          depressed
          color="primary"
          @click="$emit('close-sheet')"
        >
          Fermer
        </v-btn>
      </div>
    </v-sheet>
  </v-bottom-sheet>
</template>
<script>

export default {
  props: {
    sheet: Boolean,
    user: Object,
  },
};
</script>
<style lang="scss" scoped>
.sheet-wrapper {
  padding: 1rem;
  height: 100%;
  max-height: 100%;
  overflow: auto;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
}

.bottom-sheet-title {
  flex: 0 0 auto;
}


.bottom-sheet-footer {
  flex: 0 0 auto;

  width: 100%;
  height: 3rem;
  margin-bottom: 0;
  margin-top: auto;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;

  .bottom-sheet-button {
    margin-right: 1rem;
  }
}


</style>
