<template>
  <BaseCard :loading="loading">
    <template v-slot:title>
      Bâtiments
    </template>
    <template v-slot:content>
      <v-list v-if="batimentsSite">
        <template
          v-for="(batiment, i) in batimentsSite"
        >
          <BatimentListItem
            :key="`bat_${i}`"
            :batiment="batiment"
            @click.native="goToBatiment(batiment)"
            :typeUsage="getUsageBatiment(batiment)"
          >
          </BatimentListItem>
          <v-divider
            v-if="i + 1 < batimentsSite.length"
            :key="`dvd_${i}`"
            inset
          ></v-divider>
        </template>
      </v-list>
    </template>
  </BaseCard>
</template>

<script>
import { mapGetters } from 'vuex';

import BaseCard from '../BaseCard.vue';
import BatimentListItem from '../BatimentListItem.vue';

export default {
  components: {
    BaseCard,
    BatimentListItem,
  },

  props: ['featuredItem', 'loading'],

  computed: {
    ...mapGetters({
      selectedMission: 'missions/selected',
      selectedSite: 'sites/selected',
      optionsUsages: 'listeOptions/selectedMissionTypeUsage',
      batimentsSite: 'batiments/selectedSiteBatiments',
    }),
  },

  methods: {
    goToBatiment(batiment) {
      this.$router.push({ name: 'batiment', params: { missionId: this.selectedMission.id, siteId: this.selectedSite.id, batimentId: batiment.id } });
    },
    // this is in fact used in both BatimentFatCard and BatimentListItem
    getUsageBatiment(batiment) {
      const usage = this.optionsUsages.find(c => c.codeOption === batiment.typeUsage);
      return usage ? usage.libelle : '';
    },
  },

};
</script>

<style  lang="scss" scoped>

</style>
