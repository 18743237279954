<template>
  <div class="wiew-wrapper">
    <v-overlay v-if="isDeleting">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <div>
      <div
        class="rapport-view"
        v-if="InterventionsEquipement.length"
      >
        <h3>Interventions liées aux <b><u>équipements</u></b> du bâtiment
          {{ selectedBatiment.nom }}</h3>
          <div class="header-button-group">
            <div class="delete-group">
              <v-checkbox
              v-model="selectAllInterventionEquipement"
              @click.native="selectAll($event)">
              </v-checkbox>
              <v-btn
              color="error"
              class="intervention-delete-selected"
              :disabled = "!selectedInterventions.length "
              @click.stop="handleDeleteRequests(selectedInterventions)"
              >
              supprimer
              <v-icon right>
                mdi-delete
              </v-icon>
            </v-btn>
          </div>
            <v-btn
            @click="openPanel(InterventionsEquipement)">
              <v-icon>
                {{arrow}}
              </v-icon>
            </v-btn>
          </div>

        <v-expansion-panels v-model='openedPanelDomaine'  accordion multiple>
          <v-expansion-panel
            v-for="domaine in InterventionsEquipement"
            :key="domaine.libelle"
          >
          <v-expansion-panel-header>
            <div class="panel-header-wrapper">
              <div class="icon-alert-contener">
                <v-icon
                  v-if="checkNecessaryNotBlankDomaine(domaine)"
                  class="alert-icon">mdi-alert-circle</v-icon>
              </div>
              <v-checkbox
                class="custom-checkbox"
                :value = "domaine.libelle"
                v-model="selectedDomaines"
                @click.native="selectDomaine($event, domaine)">
                >

              </v-checkbox>
              <span class="panel-header-title-big">
                {{ domaine.libelle }}
              </span>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels v-model='openedPanelCategorie[domaine.libelle]' accordion multiple>
              <v-expansion-panel
                v-for="categorie in domaine.categories"
                :key="categorie.libelle"
              >
                <v-expansion-panel-header>
                  <div class="panel-header-wrapper">
                    <div class="icon-alert-contener">
                      <v-icon
                        v-if="checkNecessaryNotBlankCategorie(categorie)"
                        class="alert-icon">mdi-alert-circle</v-icon>
                    </div>
                    <v-checkbox
                      class="custom-checkbox"
                      :value = "categorie.libelle"
                      v-model="selectedCategories"
                      @click.native="selectCategorie($event, categorie, domaine)">
                    >
                    </v-checkbox>
                    <span class="panel-header-title">
                      {{ categorie.libelle }}
                    </span>
                    <div class="panel-header-sufix">
                      <span>{{ categorie.interventions.length }}</span>
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-expansion-panels
                    v-model='openedPanelIntervention[categorie.libelle]'
                    accordion
                    multiple
                    popout
                  >
                    <v-expansion-panel
                      v-for="inter in categorie.interventions"
                      :key="inter.id"
                      class="exp-panel"
                    >
                      <v-expansion-panel-header>
                        <template v-slot:default="{ open }">
                          <div class="intervention-header-wrapper">
                            <div class="icon-alert-contener">
                              <v-icon
                                v-if="inter.inAlert"
                                class="alert-icon">mdi-alert-circle</v-icon>
                            </div>
                            <v-checkbox
                              class="custom-checkbox"
                              :value = "inter"
                              v-model="selectedInterventions"
                              @click.native="selectIntervention($event, inter, categorie, domaine)">
                            </v-checkbox>
                            <span class="intervention-header-title">
                              {{ inter.typeInterventionLibelle}}
                            </span>
                            <div class="intervention-header-button-group" v-if="open">
                              <v-btn icon
                                class="intervention-edit-button"
                                @click.stop="editIntervention(inter)"
                              >
                                <v-icon>mdi-circle-edit-outline</v-icon>
                              </v-btn>
                              <v-btn icon
                                @click.stop="handleDeleteRequest(inter)"
                                class="intervention-delete-button"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </div>
                          </div>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <InterventionOverview
                          :inter="inter"
                          :item="getInterventionSource(inter)"
                          :itemType="getInterventionSourceType(inter)"
                        >
                        </InterventionOverview>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      </div>
    </div>
    <div>
      <div
        class="rapport-view"
        v-if="InterventionsInspection.length"
      >
        <h3>Interventions liées aux <b><u>inspections</u></b> du bâtiment
          {{ selectedBatiment.nom }}</h3>
          <div class="header-button-group">
            <div class="delete-group">
              <v-checkbox
              v-model="selectAllInterventionEquipementInspection"
              @click.native="selectAllInspection($event)">
              </v-checkbox>
              <v-btn
              color="error"
              class="intervention-delete-selected"
              :disabled = "!selectedInterventionsInspection
              || !selectedInterventionsInspection.length "
              @click.stop="handleDeleteInspection()"
              >
              supprimer
              <v-icon right>
                mdi-delete
              </v-icon>
            </v-btn>
          </div>
            <v-btn
            @click="openPanelInspection">
              <v-icon>
                {{arrowInspection}}
              </v-icon>
            </v-btn>
          </div>

        <v-expansion-panels
          v-model="openedPanelInterventionInspection"
          multiple
          popout
        >
          <v-expansion-panel
            v-for="inter in InterventionsInspection"
            :key="inter.id"
            class="exp-panel"
          >
            <v-expansion-panel-header>
              <template v-slot:default="{ open }">
                <div class="intervention-header-wrapper">
                  <div class="icon-alert-contener">
                    <v-icon
                      v-if="inter.inAlert"
                      class="alert-icon">mdi-alert-circle</v-icon>
                  </div>
                    <v-checkbox
                      class="custom-checkbox"
                      :value = "inter.id"
                      v-model="selectedInterventionsInspection"
                      @click.native="selectInterventionInspection($event)">
                      >
                      </v-checkbox>
                  <span class="intervention-header-title">
                    {{ inter.typeInterventionLibelle}}
                  </span>
                  <div class="intervention-header-button-group" v-if="open">
                    <v-btn icon
                      class="intervention-edit-button"
                      @click.stop="editIntervention(inter)"
                    >
                      <v-icon>mdi-circle-edit-outline</v-icon>
                    </v-btn>
                    <v-btn icon
                      @click.stop="handleDeleteRequest(inter)"
                      class="intervention-delete-button"
                    >
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </div>
                </div>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <InterventionOverview
                :inter="inter"
                :item="getInterventionSource(inter)"
                :itemType="getInterventionSourceType(inter)"
              >
              </InterventionOverview>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
          <!-- Loaders and no data messages -->
      <div class="no-data" v-if="!batimentInterventions.length">
        <v-sheet v-if="loading"
          elevation="1"
          class="message-sheet"
        >
          <v-progress-circular
            indeterminate
            color="primary"
            width="4"
            size="45"
          ></v-progress-circular>
          Récupération des données du bâtiment...
        </v-sheet>

        <v-sheet v-else
          elevation="1"
          class="message-sheet"
        >
          Il n'y a pas encore d'interventions sur ce batiment
        </v-sheet>
      </div>
    <DialogDeleteConfirmation
      v-if="deleteDialog"
      :dialog="deleteDialog"
      :item="interToDelete"
      :items="intersToDelete"
      :multiple="isMultiple"
      @delete-item="deleteIntervention()"
      @delete-items="deleteInterventions()"
      @close-dialog="deleteDialog = false"
    >
    </DialogDeleteConfirmation>

    <BaseFab
      class="add-button"
      :options="fabOptions"
      @option-selected="handlerFabButton"
      @check-online="handlerDisable"
    >
    </BaseFab>
    <BottomSheetAddIntervention
      v-if="addSheet"
      :sheet="addSheet"
      @close-sheet="addSheet = false"
    >
    </BottomSheetAddIntervention>
     <BottomSheetEditIntervention
      v-if="editSheet"
      :sheet="editSheet"
      :inter="interToEdit"
      :interventions="interventions"
      @close-sheet="closeEditInterSheet()"
    >
    </BottomSheetEditIntervention>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';
import BaseFab from '../components/BaseFab.vue';
import BottomSheetAddIntervention from '../components/interventions/BottomSheetAddIntervention.vue';
import BottomSheetEditIntervention from '../components/interventions/BottomSheetEditIntervention.vue';
import InterventionOverview from '../components/interventions/InterventionOverview.vue';
import DialogDeleteConfirmation from '../components/DialogDeleteConfirmation.vue';


export default {
  props: ['missionId', 'batimentId'],
  mixins: [
    UpdateItemAlertMixin,
  ],
  components: {
    BaseFab,
    BottomSheetAddIntervention,
    BottomSheetEditIntervention,
    InterventionOverview,
    DialogDeleteConfirmation,
  },

  data() {
    return {
      isMultiple: false,
      deleteDialog: false,
      interToDelete: null,
      intersToDelete: [],
      addSheet: false,
      editSheet: false,
      interToEdit: null,
      fabOptions: [
        {
          icon: 'mdi-clipboard-flow',
          name: 'Ajout',
          tooltip: 'Ajouter une intervention',
          color: 'green',
        },
      ],
      arrow: 'mdi-chevron-down',
      arrowInspection: 'mdi-chevron-down',
      selectAllInterventionEquipement: false,
      selectAllInterventionEquipementInspection: false,
      selectedDomaines: [],
      selectedCategories: [],
      selectedInterventions: [],
      selectedInterventionsInspection: [],
      openedPanelDomaine: [],
      openedPanelCategorie: {},
      openedPanelIntervention: {},
      openedPanelInterventionInspection: [],
      isDeleting: false,
    };
  },
  computed: {
    ...mapState({
      loading: state => state.loading,
    }),
    ...mapGetters('batiments', { selectedBatiment: 'selected' }),
    ...mapState('inspections', { inspections: 'inspections' }),
    ...mapState('listeOptions', { listesOptions: 'all' }),
    ...mapState('equipements', ['equipements']),
    ...mapState('interventions', ['interventions']),
    selectedView: {
      get() {
        return this.$store.state.selectedView;
      },
      set(value) {
        this.$store.commit('setSelectedView', value);
      },
    },
    batimentInterventions() {
      if (this.interventions) {
        return this.interventions
          .filter(intervention => intervention.batiment === this.selectedBatiment.id);
      }
      return [];
    },

    InterventionsInspection() {
      if (!this.batimentInterventions.length) {
        return [];
      }
      const interventionsInspection = [];
      this.batimentInterventions.forEach((intervention) => {
        // extract domaine & categorie libelle
        if (intervention.inspection) {
          interventionsInspection.push(intervention);
        }
      });
      return interventionsInspection;
    },

    InterventionsEquipement() {
      if (!this.batimentInterventions.length) {
        return [];
      }
      const tree = [];
      const indexes = new Map();
      this.batimentInterventions.forEach((intervention) => {
        // extract domaine & categorie libelle
        if (intervention.equipement && this.getInterventionSource(intervention)) {
          const domaine = this.getInterventionSource(intervention).libelleDomaine;
          const categorie = this.getInterventionSource(intervention).libelleCategorie;
          const domaineCategorie = `${domaine}:${categorie}`;

          // if the domaine doesn't exist in the table
          // add it and store his index the map
          if (indexes.get(domaine) === undefined) {
            const domObject = {
              libelle: domaine,
              categories: [],
            };
            const domIndex = tree.push(domObject) - 1;
            indexes.set(domaine, domIndex);
          }

          // if the categorie doesn't exist in the table
          // add it and store his index in the map
          if (indexes.get(domaineCategorie) === undefined) {
            const catObject = {
              libelle: categorie,
              interventions: [],
            };
            const catIndex = tree[indexes.get(domaine)].categories.push(catObject) - 1;
            indexes.set(domaineCategorie, catIndex);
          }

          // add the equipement in the right domaine and in the right categorie
          tree[indexes.get(domaine)]
            .categories[indexes.get(domaineCategorie)]
            .interventions.push(intervention);
        }
      });
      return tree;
    },
  },
  async created() {
    this.selectedView = 'Intervenir';
    this.$store.dispatch('changeLoading', true);
    await Promise.all([
      this.$store.dispatch('changeSelectedItem', { type: 'batiments', id: this.batimentId }),
      this.$store.dispatch('interventions/fetchAllByBatiment', this.batimentId),
      this.$store.dispatch('inspections/fetchAllByBatiment', this.batimentId),
    ]);
    this.$store.dispatch('changeLoading', false);
    this.$store.dispatch('equipements/fetchAllByBatiment', this.selectedBatiment.id);
  },
  methods: {
    handlerDisable() {
      if (!navigator.onLine) {
        this.fabOptions[0].disable = true;
      }
    },
    getInterventionSource(inter) {
      if (inter.equipement) {
        return this.equipements.find(equipement => equipement.id === inter.equipement);
      }
      return this.inspections.find(inspection => inspection.id === inter.inspection);
    },

    getInterventionSourceType(inter) {
      return (inter.equipement !== undefined && inter.equipement !== '' && inter.equipement) ? 'equipement' : 'inspection';
    },

    handlerFabButton(val) {
      if (val === this.fabOptions[0].name) {
        this.addSheet = true;
      }
    },

    editIntervention(inter) {
      this.$store.dispatch('interventions/refreshItem', inter.id);
      const sourceType = this.getInterventionSourceType(inter);
      const source = this.getInterventionSource(inter);
      this.interToEdit = {
        item: inter,
        source: {
          type: sourceType,
        },
      };

      if (sourceType === 'equipement') {
        this.interToEdit.source.name = source.nom;
      } else if (sourceType === 'inspection') {
        this.interToEdit.source.name = source.nomType;
      }

      this.editSheet = true;
    },

    closeEditInterSheet() {
      this.editSheet = false;
      this.interToEdit = null;
    },

    deleteIntervention() {
      this.$store.dispatch('interventions/delete', this.interToDelete).then(() => {
        this.majBatimentAlert(this.selectedBatiment.id, 'interventions')
          .then(() => {
            this.$store.dispatch('snackbar/displaySnack',
              { message: 'L\'intervention a été supprimée avec succès !', type: 'succes' });
          });
      });
      this.interToDelete = null;
    },

    async deleteInterventions() {
      this.isDeleting = true;
      const deletePromises = [];
      for (let i = 0; i < this.intersToDelete.length; i += 1) {
        deletePromises.push(this.$store.dispatch('interventions/delete', this.intersToDelete[i]).then(() => {
          this.majBatimentAlert(this.selectedBatiment.id, 'interventions');
        }));
      }
      await Promise.all(deletePromises)
        .then(() => {
          this.$store.dispatch('snackbar/displaySnack',
            { message: 'Les interventions ont été supprimé avec succès !', type: 'succes' });
          this.isDeleting = false;
        });

      this.intersToDelete = [];
      this.selectedInterventions = [];
      this.selectedIntserventionsInspection = [];
    },

    checkNecessaryNotBlankDomaine(domaine) {
      let i = 0;
      let findAlert = false;
      while (i < domaine.categories.length && !findAlert) {
        if (this.checkNecessaryNotBlankCategorie(domaine.categories[i])) {
          findAlert = true;
        }
        i += 1;
      }
      return findAlert;
    },
    checkNecessaryNotBlankCategorie(categorie) {
      let i = 0;
      let findAlert = false;
      while (i < categorie.interventions.length && !findAlert) {
        if (categorie.interventions[i].inAlert) {
          findAlert = true;
        }
        i += 1;
      }
      return findAlert;
    },

    handleDeleteRequest(inter) {
      this.isMultiple = false;
      this.interToDelete = inter;
      this.deleteDialog = true;
    },

    handleDeleteRequests(inters) {
      if (inters.length > 1) {
        this.isMultiple = true;
        this.intersToDelete = inters;
        this.deleteDialog = true;
      } else {
        this.isMultiple = false;
        this.handleDeleteRequest(inters[0]);
      }
    },

    handleDeleteInspection() {
      const inspectionsToDelete = [];
      this.selectedInterventionsInspection.forEach((intervention) => {
        inspectionsToDelete.push(this.InterventionsInspection.find(int => int.id === intervention));
      });
      this.handleDeleteRequests(inspectionsToDelete);
    },

    selectAll(e) {
      e.cancelBubble = true;
      this.selectedDomaines = [];
      this.selectedCategories = [];
      this.selectedInterventions = [];
      if (this.selectAllInterventionEquipement) {
        this.InterventionsEquipement.forEach((domaine) => {
          this.selectedDomaines.push(domaine.libelle);
          this.selectDomaine(e, domaine);
        });
      }
    },

    selectDomaine(e, domaine) {
      e.cancelBubble = true;
      if (this.selectedDomaines.includes(domaine.libelle)) {
        domaine.categories.forEach((categorie) => {
          if (!this.selectedCategories.includes(categorie.libelle)) {
            this.selectedCategories.push(categorie.libelle);
            this.selectCategorie(e, categorie, domaine);
          }
        });
      } else {
        domaine.categories.forEach((categorie) => {
          this.selectedCategories.splice(this.selectedCategories.indexOf(categorie.libelle), 1);
          categorie.interventions.forEach((intervention) => {
            if (this.selectedInterventions.includes(intervention)) {
              this.selectedInterventions
                .splice(this.selectedInterventions.indexOf(intervention), 1);
            }
          });
          this.selectAllInterventionEquipement = false;
        });
      }
    },


    selectCategorie(e, categorie, domaine) {
      e.cancelBubble = true;
      if (this.selectedCategories.includes(categorie.libelle)) {
        categorie.interventions.forEach((intervention) => {
          if (!this.selectedInterventions.includes(intervention)) {
            this.selectedInterventions.push(intervention);
          }
        });
      } else {
        categorie.interventions.forEach((intervention) => {
          if (this.selectedInterventions.includes(intervention)) {
            this.selectedInterventions
              .splice(this.selectedInterventions.indexOf(intervention), 1);
          }
        });
        if (this.selectedDomaines.includes(domaine.libelle)) {
          this.selectedDomaines.splice(this.selectedDomaines.indexOf(domaine.libelle), 1);
        }
        this.selectAllInterventionEquipement = false;
      }
    },

    selectIntervention(e, intervention, categorie, domaine) {
      e.cancelBubble = true;
      if (!this.selectedInterventions.includes(intervention)) {
        this.selectedCategories.splice(this.selectedCategories.indexOf(categorie.libelle), 1);
        this.selectedDomaines.splice(this.selectedDomaines.indexOf(domaine.libelle), 1);
        this.selectAllInterventionEquipement = false;
      }
    },
    selectAllInspection(e) {
      e.cancelBubble = true;
      this.selectedInterventionsInspection = [];
      if (this.selectAllInterventionEquipementInspection) {
        this.InterventionsInspection.forEach((intervention) => {
          this.selectedInterventionsInspection.push(intervention.id);
        });
      }
    },
    selectInterventionInspection(e) {
      e.cancelBubble = true;
    },

    openPanel(InterventionsEquipement) {
      if (!this.panel) {
        for (let i = 0; i < InterventionsEquipement.length; i += 1) {
          const domaine = InterventionsEquipement[i];
          this.openedPanelDomaine.push(i);
          if (domaine.libelle) {
            for (let j = 0; j < domaine.categories.length; j += 1) {
              const categorie = domaine.categories[j];
              if (this.openedPanelCategorie[domaine.libelle]) {
                this.openedPanelCategorie[domaine.libelle].push(j);
              } else {
                this.openedPanelCategorie[domaine.libelle] = [j];
              }
              if (categorie.libelle) {
                for (let k = 0; k < categorie.interventions.length; k += 1) {
                  if (this.openedPanelIntervention[categorie.libelle]) {
                    this.openedPanelIntervention[categorie.libelle].push(k);
                  } else {
                    this.openedPanelIntervention[categorie.libelle] = [k];
                  }
                }
              }
            }
          }
        }
        this.panel = true;
        this.arrow = 'mdi-chevron-up';
      } else {
        this.openedPanelDomaine = [];
        this.openedPanelCategorie = {};
        this.openedPanelIntervention = {};
        this.panel = false;
        this.arrow = 'mdi-chevron-down';
      }
    },
    openPanelInspection() {
      if (!this.panelInspection) {
        for (let i = 0; i < this.InterventionsInspection.length; i += 1) {
          this.openedPanelInterventionInspection.push(i);
        }
        this.panelInspection = true;
        this.arrowInspection = 'mdi-chevron-up';
      } else {
        this.openedPanelInterventionInspection = [];
        this.panelInspection = false;
        this.arrowInspection = 'mdi-chevron-down';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .wiew-wrapper {
    height: 100%;

    .rapport-view {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;
      padding: 1rem;

      > * {
        margin-bottom:0.1rem;
      }
    }

    .icon-alert-contener{
    width: 30px;
    }

    .no-data {
      height: 100%;
      padding: 20%  1rem 0 1rem;

      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .exp-panel {
    margin-top: 1rem;
  }

  .delete-group {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    margin-left: 54px;
  }

  .custom-checkbox {
    padding-top: 0px;
    margin-top: 0px;
    height: 24px;
  }


  .intervention-header-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    .intervention-header-title {
      font-weight: 300;
      font-size: 1rem;
    }
    .intervention-header-button-group {
      margin-left: auto;
      margin-right: 5px;
      min-width: 75px;
      .intervention-edit-button {
        color: $app-blue !important;
      }
      .intervention-delete-button {
        color: $custom-red;
      }
    }

  }

  .header-button-group{
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      width: 100%;

  }
  .message-sheet {
    height: 8rem;
    border-radius: 10px;
    padding: 1rem;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-around;
    align-items: center;

    font-size: 1.2rem;
    font-weight: 300;
    text-align: center;
  }

  .add-button {
    z-index: 99;
    position: fixed;
    right: 4rem;
    bottom: 4rem;
  }

    .panel-header-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    .panel-header-title {
      font-weight: 300;
      font-size: 1.1rem;
    }

    .panel-header-title-big {
      font-weight: 400;
      font-size: 1.1rem;
    }

    .panel-header-sufix {
      margin-left: auto;
      margin-right: 5px;
      min-width: 150px;

      display: flex;
      flex-flow: row wrap;
      justify-content: space-around;
      align-items: center;
    }

  }
  .alert-icon{
    color:orange;
    margin-right:8px;
  }
</style>
