<template>
  <v-card :loading="this.loading">

    <v-img
      v-if="this.client"
      :src="imgUrl"
      class="white--text align-end v-img-heigth"
      width="100"
      height="100"
    >
    </v-img>
    <v-card-title class="client-title">
      {{ client.nom }}
    </v-card-title>

    <template v-slot:placeholder>
      <v-row
        class="fill-height ma-0"
        align="center"
        justify="center"
      >
        <v-progress-circular
          :indeterminate="load"
          color="black"
        >
        </v-progress-circular>
      </v-row>
    </template>


    <v-card-subtitle>{{ client.addresse }}</v-card-subtitle>
    <v-divider></v-divider>

    <v-card-text class="text--primary">
      <div class="font-weight-black">Contact</div>
      <div>Nom : {{ client.prenomContact }} {{ client.nomContact }}</div>
      <div>Fonction : {{ client.fonctionContact }}</div>
      <div>Téléphone : {{ client.telContact }}</div>
      <div>Email : {{ client.mailContact }}</div>
    </v-card-text>

    <v-card-actions>
      <v-btn
        color="primary"
        @click.native="triggerUpload()"
        :loading="importing"
      >
      Importer factures
      </v-btn>
      <input
        type="file"
        ref="fileInput"
        style="display:none"
        @change="handleFileChange($event)"
      >
      <v-btn
        class="pushed-right white--text"
        color="primary"
        @click="$emit('show-edit-item')"
      >
      Editer
        <v-icon right>
          mdi-circle-edit-outline
        </v-icon>
      </v-btn>
      <v-btn
        color="error"
        class="white--text"
        @click="showDeleteDialog()"
      >
        supprimer
      <v-icon right>
        mdi-delete
      </v-icon>
      </v-btn>
    </v-card-actions>

    <DialogDeleteConfirmation
      :item="client"
      :dialog="deleteDialog"
      @delete-item="deleteClient()"
      @close-dialog="closeDeleteDialog()"
    >
    </DialogDeleteConfirmation>
    <v-dialog
      v-model="show"
      width="500"
    >
    <v-card>
      <v-card-title class="headline">Votre fichier est en cours d'importation</v-card-title>

        <v-card-text>
          Votre fichier est en cours d'importation, ceci peut prendre plusieurs minutes.
          Vous pouvez continuer d'utiliser SMART DIAG en parallèle.
          Un fichier de log sera automatiquement téléchargé à la fin de
          l'importation pour vous informer si tout s'est bien déroulé.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="primary"
            depressed
            @click="show = false"
          >
            ok
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axiosInstance from '../../plugins/axios';

import DialogDeleteConfirmation from '../DialogDeleteConfirmation.vue';

export default {
  components: {
    DialogDeleteConfirmation,
  },
  props: ['client', 'loading'],
  data() {
    return {
      deleteDialog: false,
      importing: false,
      file: null,
      show: false,
    };
  },

  computed: {
    imgUrl() {
      if (this.client && this.client.image) {
        return `${this.client.image}/file`;
      }
      return '';
    },

    load() {
      if (this.client) {
        return this.client.image !== null;
      }
      return false;
    },
  },
  methods: {
    showDeleteDialog() {
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
    },

    triggerUpload() {
      this.$refs.fileInput.click();
    },

    deleteClient() {
      this.$store.dispatch('clients/delete', this.client).then(() => {
        this.$store.dispatch('snackbar/displaySnack', { message: 'Client supprimé avec succès', type: 'succes' });
      }).finally(() => {
        this.$router.push({ path: '/clients' });
      });
    },

    handleFileChange(event) {
      this.importing = true;
      this.show = true;
      // create a formData with the file selected
      const formData = new FormData();
      formData.append('file', event.target.files[0]);
      axiosInstance.axiosInstance.post(`/api/clients/${this.client.id}/import/facture`, formData).then(async (response) => {
        let code = {
          data: {
            exitCode: -1,
          },
        };

        const callExecJobs = () => { //  create a loop function
          setTimeout(async () => { //  call a 30s setTimeout when the loop is called
            code = await axiosInstance.axiosInstance.get(`/api/exec-jobs/${response.data.id}`);
            if (code.data.exitCode === null || code.data.exitCode === -1) {
              callExecJobs(); //  ..  again which will trigger another
            } else {
              if (code.data.exitCode === 0) {
                axiosInstance.axiosInstance.get(`/api/clients/import/log/${response.data.id}`, { responseType: 'blob' }).then((logResponse) => {
                  const blob = new Blob([logResponse.data], { type: 'text/plain' });
                  const link = document.createElement('a');
                  const url = window.URL.createObjectURL(blob);

                  // create a file with the report and download it
                  link.href = url;
                  link.setAttribute('download', `Logs_Intégration_Facture${new Date().getDate()}.txt`);
                  document.body.appendChild(link);
                  link.click();
                });
              } else {
                this.$store.dispatch('snackbar/displaySnack', { message: 'Erreur lors du téléchargement de votre rapport!', type: 'error' });
              }
              this.isDownload = false;
              this.importing = false;
            }
          }, 30000);
        };
        callExecJobs(); //  start the loop
      });


      // call the import api with the formData
      /* axiosInstance.axiosInstance.post(`/api/clients/${this.client.id}/import-facture`, formData)
        .then((responseImport) => {
          const blob = new Blob([responseImport.data], { type: 'text/plain' });
          const link = document.createElement('a');
          const url = window.URL.createObjectURL(blob);

          // create a file with the report and download it
          link.href = url;
          link.setAttribute('download', `Logs_Intégration_Facture${new Date().getDate()}.txt`);
          document.body.appendChild(link);
          link.click();

          if (responseImport.data.match(/Les données ont été correctement intégrées.)) {
            this.$store.dispatch('clients/refreshItem', this.client.id);
            this.$store.dispatch('snackbar/displaySnack',
            { message: 'Données importées avec succès !', type: 'succes' });
            // no longer import all compteurs
            // this.$store.dispatch('compteurs/fetchAllByClient', this.client.id);
          } else {
            this.$store.dispatch('snackbar/displaySnack', {
              message: 'Echec de l\'importation des données,
              veuillez consulter le fichiers de logs.',
              type: 'error',
            });
          }
        })
        .finally(() => {
          this.importing = false;
          const input = this.$refs.fileInput;
          input.type = 'text';
          input.type = 'file';
        }); */
    },
  },
};
</script>

<style lang="scss" scoped>
  .pushed-right {
    margin-left: auto !important;
    margin-right: 0;
  }

  .client-title {
    text-shadow: 1px 1px 2px rgba(0,0,0,0.5);
  }

</style>
