<template>
  <div class="poste-view">
    <h3>Postes du bâtiment {{this.selectedBatiment.nom}}</h3>
    <div class="button-switch">
      <v-chip
      class="button"
      dark
      :color="getColorRelatif()"
      @click="changeValue('relatif')">%</v-chip>
      <v-chip
      class="button"
      dark
      :color="getColorAbsolue()"
      @click="changeValue('absolue')">kWh</v-chip>
    </div>
    <div v-if="totalOk()===false && !absoluteValue" class="error-msg">
      <v-icon color="red">mdi-alert</v-icon>
      Attention la somme des répartitions d’au moins un compteur n’est pas égal à 100%.
    </div>
    <div type="text/x-template" id="grid-template">
      <v-data-table
        :key="componentKey"
        :headers="headers"
        :items="this.postesCompteur"
        :items-per-page="30"
        class="elevation-1"
        :loading="loading"
        loading-text="Récupération des postes de consommation"
        fixed-header
        height="60vh"
        hide-default-footer
      >
      <template v-for="header in headers"
      v-slot:[getheaderSlotName(header)]="headers">
        <th :key="header.value">
          <div class="d-flex justify-center">{{header.text}}</div>
          <div class="d-flex justify-center">
            <v-chip :color="getColorEnergies(header.energie)">{{header.energieLibelle}}</v-chip>
          </div>
          <div class="d-flex justify-center">{{header.num}}</div>
        </th>
      </template>
      <template
        v-slot:[`item.name`]="propsTitle">
          <div>{{propsTitle.item.name}}
          </div>
          <div v-if="!absoluteValue"
          class="total-poste">
            <!-- eslint-disable -->
            ({{
              Math.round(
                 propsTitle.item.valueTotal / selectedBatiment.calculations.consommationEfAnneeReference * 100
              )
            }} %)
            <!-- eslint-enable -->
          </div>
          <div v-else
          class="total-poste">
            ({{propsTitle.item.valueTotal}} kWh)
          </div>
      </template>
      <template
        v-for="compteur in listeCompteur"
        v-slot:[getSlotName(compteur)]="props">
          <div
            v-if="props.item.name!=='Total'"
            :key="compteur.compteur"
            class="input-td">
            <v-text-field
               class="centered-input center"
              v-model="props.item[`cpt_${compteur.compteur}`]"
              :value="props.item[`cpt_${compteur.compteur}`]"
              :key="compteur.compteur"
              :suffix="getSuffix()"
              @focus="$event.target.select()"
              @change="saveTemp(props, compteur)"
              :disabled="absoluteValue">
            </v-text-field>
          </div>
      </template>
      </v-data-table>
      <v-data-table
        :key="'A' + componentKey"
        :headers="headers"
        :items="this.postesCompteurTotal"
        :items-per-page="30"
        class="elevation-1"
        :loading="loading"
        hide-default-header
        hide-default-footer
        >
      <template
        v-slot:[`item.name`]="propsTitle">
          <div
            v-if="propsTitle.item.name==='Total'"
            class="total-td">{{propsTitle.item.name}}
          </div>
      </template>
      <template
        v-for="compteur in listeCompteur"
        v-slot:[getSlotName(compteur)]="props">
        <div
        v-if="props.item.name==='Total'"
        :key="compteur.compteur"
        >
          <v-chip
          v-if="!absoluteValue"
          :color="getColor(props.item[`cpt_${compteur.compteur}`])"
          dark>{{ props.item[`cpt_${compteur.compteur}`] }} %
          </v-chip>
          <div class="input-td bold" v-else>
            <v-text-field
              v-model="props.item[`cpt_${compteur.compteur}`]"
              :value="props.item[`cpt_${compteur.compteur}`]"
              :key="compteur.compteur"
              :suffix="getSuffix()"
              :readonly="absoluteValue">
            </v-text-field>
          </div>
        </div>
      </template>
    </v-data-table>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ColorEnergieMixing from '@/mixins/ColorEnergieMixing';
import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';

export default {
  props: ['missionId', 'batimentId'],
  mixins: [
    ColorEnergieMixing,
    UpdateItemAlertMixin,
  ],
  computed: {
    ...mapState({
      loading: state => state.loading,
      repartitions: state => state.repartitions.repartitions,
      compteurs: state => state.compteurs.compteurs,
    }),
    ...mapGetters({
      selectedMission: 'missions/selected',
      selectedBatiment: 'batiments/selected',
      postes: 'listeOptions/selectedMissionPostes',
    }),
    selectedView: {
      get() {
        return this.$store.state.selectedView;
      },
      set(value) {
        this.$store.commit('setSelectedView', value);
      },
    },
  },

  data() {
    return {
      absoluteValue: Boolean,
      saving: false,
      componentKey: 0,
      snack: false,
      snackColor: '',
      snackText: '',
      listeCompteur: [],
      postesCompteur: [],
      postesCompteurTotal: [],
      headers: [
        {
          text: 'Postes',
          num: '',
          energie: '',
          energieLibelle: '',
          align: 'start',
          sortable: false,
          value: 'name',
        },
      ],
    };
  },

  async created() {
    this.selectedView = 'Répartir';
    this.absoluteValue = false;
    this.$store.dispatch('changeLoading', true);
    await this.$store.dispatch('changeSelectedItem', { type: 'batiments', id: this.batimentId });
    await Promise.all([
      this.$store.dispatch('listeOptions/fetchAllByType',
        {
          missionId: this.selectedMission.id,
          codeType: 'poste',
        }),
      this.$store.dispatch('compteurs/fetchAllBySite', this.selectedBatiment.site),
    ]);
    this.$store.dispatch('changeLoading', false);
    this.populateTable();
  },
  methods: {
    changeValue(type) {
      if (type === 'relatif') {
        this.absoluteValue = false;
      } else {
        this.absoluteValue = true;
      }
      this.majTable();
    },

    forceRerender() {
      this.componentKey += 1;
    },
    getSlotName(compteur) {
      return `item.cpt_${compteur.compteur}`;
    },
    getheaderSlotName(header) {
      return `header.${header.value}`;
    },
    populateTable() {
      this.postes.forEach((poste) => {
        this.postesCompteur.push({
          name: `${poste.libelle}`,
          valueTotal: this.selectedBatiment.calculations.consommationEfPostes[poste.codeOption],
          code: poste.codeOption,
        });
      });
      this.postesCompteurTotal.push({
        name: 'Total',
      });
      this.$store.dispatch('repartitions/fetchRepartitionByIdBatiment', this.selectedBatiment._id)
        .then((repartitionsCompteur) => {
          this.listeCompteur = JSON.parse(JSON.stringify(repartitionsCompteur));
          repartitionsCompteur.forEach((compt) => {
            const columnCompteur = this.getCompteur(compt);
            const nameCompteur = columnCompteur.nomCompteur;
            const compteurId = columnCompteur.id;
            this.headers.push({
              text: nameCompteur,
              num: columnCompteur.numeroCompteur,
              energie: columnCompteur.energie,
              energieLibelle: columnCompteur.energieLibelle,
              align: 'center',
              sortable: false,
              value: `cpt_${compteurId}`,
            });

            let total = 0;
            for (let i = 0; i <= this.postesCompteur.length - 1; i += 1) {
              const postCode = this.postesCompteur[i].code;
              if (compt.postes && compt.postes[postCode]) {
                if (this.absoluteValue) {
                  this.postesCompteur[i][`cpt_${compteurId}`] = compt.calculations.consommationEfPostes[postCode];
                  total += parseFloat(compt.calculations.consommationEfPostes[postCode]);
                } else {
                  this.postesCompteur[i][`cpt_${compteurId}`] = compt.postes[postCode];
                  total += parseFloat(compt.postes[postCode]);
                }
              } else {
                this.postesCompteur[i][`cpt_${compteurId}`] = 0;
                total += 0;
              }
            }
            this.postesCompteurTotal[0][`cpt_${compteurId}`] = total;
          });
        });
      this.forceRerender();
    },
    majTable() {
      this.listeCompteur.forEach((compt) => {
        const compteur = this.getCompteur(compt);
        const compteurId = compteur.id;
        let total = 0;
        for (let i = 0; i < this.postes.length; i += 1) {
          const postCode = this.postes[i].codeOption;
          const objectPoste = compt;
          if (!objectPoste.postes[postCode] && this.absoluteValue) {
            objectPoste.postes[postCode] = 0;
            this.postesCompteur[i][`cpt_${compteurId}`] = compt.calculations.consommationEfPostes[postCode];
            total += 0;
          } else if (!objectPoste.postes[postCode] && !this.absoluteValue) {
            objectPoste.postes[postCode] = 0;
            this.postesCompteur[i][`cpt_${compteurId}`] = compt.postes[postCode];
            total += 0;
          } else if (objectPoste.postes[postCode] && this.absoluteValue) {
            this.postesCompteur[i][`cpt_${compteurId}`] = compt.calculations.consommationEfPostes[postCode];
            total += parseFloat(compt.calculations.consommationEfPostes[postCode]);
          } else {
            this.postesCompteur[i][`cpt_${compteurId}`] = compt.postes[postCode];
            total += parseFloat(compt.postes[postCode]);
          }
          this.postesCompteurTotal[0][`cpt_${compteurId}`] = parseFloat(total.toFixed(2));
        }
      });
      this.forceRerender();
    },
    saveTemp(props, compteur) {
      const compteurTemp = this.listeCompteur.find(
        compt => compt.compteur === compteur.compteur,
      );
      if (props.item[`cpt_${compteur.compteur}`].match('^[0-9.,]+$')) {
        compteurTemp.postes[props.item.code] = props.item[`cpt_${compteur.compteur}`].replace(/,/g, '.');
      } else {
        compteurTemp.postes[props.item.code] = 0;
      }
      this.majTable();
      this.saveForm();
    },
    getColor(total) {
      let color;
      if (total > 100) {
        color = 'red';
      } else if (total < 100) {
        color = 'orange';
      } else {
        color = 'green';
      }
      return color;
    },
    getColorEnergies(codeEnergie) {
      return this.getColorEnergie(codeEnergie);
    },
    getColorRelatif() {
      let color;
      if (!this.absoluteValue) {
        color = 'primary';
      }
      return color;
    },
    getColorAbsolue() {
      let color;
      if (this.absoluteValue) {
        color = 'primary';
      }
      return color;
    },
    getSuffix() {
      let suffix = '';
      if (this.absoluteValue) {
        suffix = 'kWh';
      } else {
        suffix = '%';
      }
      return suffix;
    },
    totalOk() {
      let ok = true;
      this.listeCompteur.forEach((compt) => {
        const compteur = this.getCompteur(compt);
        if (this.postesCompteurTotal[0][`cpt_${compteur.id}`] !== 100) {
          ok = false;
        }
      });
      return ok;
    },
    getCompteur(compt) {
      const columnCompteur = this.compteurs.find(
        compteur => compteur.id === compt.compteur,
      );
      return columnCompteur;
    },
    saveForm() {
      let inAlertRepart = false;
      this.saving = true;
      this.listeCompteur.forEach((compt) => {
        const compteur = this.getCompteur(compt);
        if (this.postesCompteurTotal[0][`cpt_${compteur.id}`] !== 100) {
          inAlertRepart = true;
        }
        this.$store.dispatch('repartitions/save',
          {
            data: {
              ...compt,
              inAlert: inAlertRepart,
            },
            id: compt._id,
          }).then(() => {
          this.$store.dispatch('snackbar/displaySnack', { message: 'enregistré avec succès !', type: 'succes' });
          this.saving = false;
          this.$store.dispatch('repartitions/fetchRepartitionByIdBatiment', this.selectedBatiment._id)
            .then((repartitionsCompteur) => {
              this.listeCompteur = JSON.parse(JSON.stringify(repartitionsCompteur));
              this.$store.dispatch('sites/refreshItem', this.selectedBatiment.site);
            });
          this.majBatimentAlert(this.selectedBatiment._id, 'repartitions');
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .headers {
    white-space: pre-line;
    color:red;
  }
  .total-td {
    font-weight: bold;
    padding-right:1rem;
  }
  .error-msg {
    color:red;
    height:50px;
  }
  .poste-view {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 1rem;
  }
  .input-td {
    width: 100px;
    font-size: 14px;
    display: inline-block;
  }

  .button-switch {
    display: flex;
    align-items: center;
  }

  .button {
    // padding: 0 2rem 0 2rem;
    width: 50px;
    justify-content: center;
    margin: 1rem;
  }

  .bold {
    font-weight: bold;
  }

  .total-poste {
    font-size: 14px;
  }

</style>

<style lang="scss">
  .centered-input input {
    text-align: center;
  }
</style>
