<template>
  <v-dialog
    v-model="dialog"
    width="500"
  >
    <v-card >
      <v-card-title class="headline grey lighten-2">
        Informations
      </v-card-title>
      <v-card-text>
        <div v-if="object">
          <div class="text">
            <b>Nom:</b> {{object.nom}}
          </div>
          <div class="text">
            <b>Code:</b> {{object.codeImageCatalogue}}
              <v-btn icon
              color="primary"
              @click="copyToClipboard()"
              >
                <v-icon size="30px">mdi-content-copy</v-icon>
              </v-btn>
              <input type="hidden" id="valueToCopy" :value="object.codeImageCatalogue">
          </div>

        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="close()"
        >
          Fermer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    object: {
      type: Object,
      default: null,
    },
  },

  methods: {
    close() {
      this.$emit('close-dialog');
    },
    copyToClipboard() {
      const valueToCopy = document.querySelector('#valueToCopy');
      valueToCopy.setAttribute('type', 'text');
      valueToCopy.select();
      document.execCommand('copy');
      valueToCopy.setAttribute('type', 'hidden');
      this.$store.dispatch('snackbar/displaySnack', { message: 'Le code est bien collé dans votre presse-papiers!', type: 'succes' });
    },
  },
};
</script>

<style lang="scss" scoped>
.text{
  margin-top:2rem;
  font-size: 22px !important;
}
</style>
