<template>
    <v-select
      v-bind="fieldOptions"
      :label="entries.libelle"
      :items="this.entries.data.valeursParametre.map(a => a.libelle)"
      v-model="localValue"
      @change="handleChange(localValue)"
      :loading="loading"
    >
    </v-select>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'select-field',
  props: {
    commonOptions: Object,
    defaultValue: null,
    equipId: String,
    listOption: String,
    energieAp: String,
  },
  data() {
    return {
      createValue: null,
      localValue: this.defaultValue,
      entries: {
        libelle: '',
        data: {
          valeursParametre: [],
        },
      },
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      selectedMission: 'missions/selected',
      selectedSite: 'sites/selected',
      selectedBatiment: 'batiments/selected',
    }),
    fieldOptions() {
      return {
        ...this.commonOptions,
        itemText: 'libelle',
        itemValue: 'codeOption',
      };
    },
  },
  watch: {
    defaultValue(newValue) {
      this.localValue = newValue;
    },
  },
  created() {
    this.populateEntries();
  },
  methods: {
    handleChange(value) {
      const item = this.entries.data.valeursParametre.filter(
        param => param.libelle === value,
      );
      this.$emit('changeParamCee', { listOption: this.listOption, localValue: item[0] });
    },
    populateEntries() {
      if (typeof this.listOption === 'string') {
        this.loading = true;

        const codeOption = `${this.listOption.toLowerCase()}-parametrecee`;
        const missionId = this.selectedMission.id;
        this.$store.dispatch('listeOptions/fetchByCodeOption', { missionId, codeOption })
          .then(async (response) => {
            this.entries = response;
            if (this.localValue === null || typeof this.localValue === 'undefined') {
              if (this.entries.data.variableValeurDefaut) {
                const splitItem = this.entries.data.variableValeurDefaut.split('.');
                const attribute = this.entries.data.variableValeurDefaut
                  .substr(splitItem[0].length + 1);
                const selectedEquipement = await this.$store.dispatch('equipements/refreshItem', this.equipId);
                switch (splitItem[0]) {
                  case 'site':
                    this.localValue = attribute.split('.').reduce((prev, cur) => prev[cur], this.selectedSite);
                    break;
                  case 'batiment':
                    this.localValue = attribute.split('.').reduce((prev, cur) => prev[cur], this.selectedBatiment);
                    break;
                  case 'equipement':
                    this.localValue = attribute.split('.').reduce((prev, cur) => prev[cur], selectedEquipement);
                    break;
                  default:
                    this.localValue = null;
                    break;
                }
                const item = this.entries.data.valeursParametre.filter(
                  param => param.libelle === this.localValue,
                );
                this.$emit('changeParamCee', { listOption: this.listOption, localValue: item[0] });
              }
            }
          })
          .finally(() => { this.loading = false; });
      }
    },
  },
};
</script>
