<template>
  <v-sheet
  :loading="loading"
  class="header-batiment">
    <!-- Photo et nom -->
    <v-img
      v-if="featuredItem"
      :src="imgUrl"
      class="white--text v-img-heigth"
    >
      <div class="batiment-card-title">
        <div class="batiment-title">
          {{this.featuredItem.nom}}
        </div>
      </div>

      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
        >
          <v-progress-circular
            :indeterminate="load"
            color="black"
          >
          </v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <div class="side-actions">
      <div class="btn">
        <v-btn
          color="primary"
          class="white--text"
          @click="$emit('show-edit-item')"
        >
          Editer
          <v-icon right>
            mdi-circle-edit-outline
          </v-icon>
        </v-btn>
        <v-icon v-if="this.alert" class="field-icon-warning">
          mdi-alert-circle
        </v-icon>
      </div>
      <div class="btn">
        <v-btn
          color="error"
          class="white--text"
          @click="showDeleteDialog()"
        >
          supprimer
          <v-icon right>
            mdi-delete
          </v-icon>
        </v-btn>
      </div>
    </div>

    <DialogDeleteConfirmation
      :item="featuredItem"
      :dialog="deleteDialog"
      @delete-item="deleteBatiment()"
      @close-dialog="closeDeleteDialog()"
    >
    </DialogDeleteConfirmation>


  </v-sheet>
</template>

<script>
import { mapGetters } from 'vuex';
import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';
import DialogDeleteConfirmation from '../DialogDeleteConfirmation.vue';

export default {
  mixins: [
    UpdateItemAlertMixin,
  ],
  components: {
    DialogDeleteConfirmation,
  },

  props: ['featuredItem', 'loading', 'alert'],

  data() {
    return {
      isOffline: false,
      retrievingReference: false,
      deleteDialog: false,
    };
  },

  computed: {
    ...mapGetters({
      selectedMission: 'missions/selected',
      selectedSite: 'sites/selected',
    }),

    imgUrl() {
      if (this.featuredItem && this.featuredItem.imagePrincipale) {
        return `${this.featuredItem.imagePrincipale}/file`;
      }
      return '/img/site_illustration.png';
    },

    load() {
      if (this.featuredItem) {
        return this.featuredItem.image !== null;
      }
      return false;
    },
  },

  methods: {

    showDeleteDialog() {
      this.deleteDialog = true;
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
    },

    async deleteBatiment() {
      await this.$store.dispatch('batiments/delete', this.featuredItem)
        .then(async () => {
          this.$store.dispatch('changeSelectedItem', { type: 'sites', id: this.selectedSite.id });
          await this.$store.dispatch('sites/refreshItem', this.selectedSite.id).then(async () => {
            await this.majSiteAlert(this.selectedSite.id, 'batiments');
          });
          this.$router.push({ path: `/mission/${this.selectedMission.id}/site/${this.selectedSite.id}` });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-img-heigth{
    height: 20rem;
    max-width: 33rem;
  }

  .header-batiment {
    background-color: #b0c4de38;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;

    .side-actions {
      align-self: stretch;
      padding: 1rem;

      display: flex;
      flex-flow: column wrap;
      justify-content: flex-start;
      align-items: center;

      button {
        width: 150px;
        margin: 0.5rem 0 0.5rem 0;
      }
    }
  }

  .batiment-card-title {
    padding:0;
    text-align: center;
    display: flex;
    font-size: 1.25rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    line-height: 2rem;
    word-break: break-all;
    position: absolute;
    bottom:0;
  }

  .batiment-title {
    background-color: #5e5e5e;
    height: 100%;
    padding: 0.2rem 1rem 0.2rem 1rem;
    border-top-right-radius: 4px;
    opacity: 0.85;
  }

  .icon {
    color:white;
  }

  .field-icon-warning{
    color:orange;
    margin-bottom:30px;
    //margin-left:5px;
    width:5%;
    z-index: 1;
    position: relative;
  }

  .btn {
    width:160px;
  }
</style>
