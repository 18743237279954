<template>
  <div>
  <!-- saving progress bar -->
    <v-progress-linear
      v-if="saving"
      absolute
      color="primary"
    ></v-progress-linear>
    <InterventionForm
      :intervention="intervention"
      @form-updated="handleFormUpdate($event)"
      :updatedCalulation="calculation"
      :errorConso="errorConso"
    ></InterventionForm>
  </div>
</template>

<script>
import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';
import { mapGetters } from 'vuex';
import { interventionsEndpoint } from '@/api/apiUrls';
import axiosInstance from '../../plugins/axios';
import InterventionForm from './InterventionForm.vue';


export default {
  components: {
    InterventionForm,
  },
  mixins: [
    UpdateItemAlertMixin,
  ],
  props: ['intervention', 'calculation'],
  data() {
    return {
      saving: false,
      needToSave: false,
      waitingLine: [],
      form: {},
      errorConso: false,
    };
  },
  computed: {
    ...mapGetters({
      batimentInterventions: 'interventions/selectedBatimentInterventions',
    }),
    updateInLine() {
      return this.waitingLine.includes(this.updateAction);
    },

    updateAction() {
      return {
        type: 'update',
        action: () => this.genericSave(() => this.updateContenu()),
      };
    },
  },

  watch: {
    saving(newValue) {
      this.$emit('saving', newValue);
    },
  },
  methods: {
    handleFormUpdate(newForm) {
      if (newForm.touched && newForm.valid) {
        this.handleTriggerSave(newForm);
      }
    },

    // launch a new save if needToSave is true
    handleSaveQueue() {
      if (!this.saving && this.needToSave) {
        this.needToSave = false;
        this.saveForm();
      }
    },

    // handle a saving demands, launch a save if no save is pending
    // or turn needToSave to true if a save is allready pending
    handleTriggerSave(newForm) {
      this.form = { ...newForm };
      if (!this.saving) {
        this.saveForm();
      } else {
        this.needToSave = true;
      }
    },
    // save the modified intervention
    // handling snackbar succes message and the saveQueue in the end
    saveForm() {
      // check if equipement is in alert
      let findBlank = false;
      if (this.form.fields.energieAp === null || this.form.fields.energieAv === null
        || this.form.fields.tauxCouverture === null || this.form.fields.usage === null) {
        findBlank = true;
      }
      if (this.form.fields
        .equipement && this.form.fields
        .referentielValues && this.form.fields
        .referentielValues.option_casIntervention === '6') {
        findBlank = false;
      }
      if (this.form.fields
        .inspection && !this.form.fields
        .equipement) {
        findBlank = false;
      }

      this.saving = true;
      // populate data to save
      const payload = {
        id: this.intervention.id,
        data: {
          ...this.intervention,
          ...this.form.fields,
          inAlert: findBlank,
        },
      };

      this.$store.dispatch('interventions/save', payload)
        .then(() => {
          this.$store.dispatch('snackbar/displaySnack', { message: 'Sauvegardé avec succès !', type: 'succes' });
          this.majBatimentAlert(this.intervention.batiment, 'interventions');
          let coeffVarEnerAv = 0;
          axiosInstance.axiosInstance.get(`${interventionsEndpoint}/${this.intervention.id}`)
            .then(((response) => {
              coeffVarEnerAv = response.data
                .calculations.coeffVariationProportionnelEnergieAvantIntervention;
              if (coeffVarEnerAv <= -1) {
                this.errorConso = true;
              } else {
                this.errorConso = false;
              }
            }));
        })
        .catch(() => {
          this.$store.dispatch('snackbar/displaySnack',
            {
              message: 'Impossible de sauvegarder l\'intervention.',
              type: 'error',
            });
        })
        .finally(() => {
          this.saving = false;
          this.handleSaveQueue();
        });
    },

  },
};

</script>

<style scoped>

</style>
