import api from '@/api';

/* eslint no-console: ["error", { allow: ["error", "log"] }] */
/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = {
  all: [],
};

const getters = { };

const actions = {

  /**
   * add a mission to the list of offline missions
   */
  add({ commit }, offlineMission) {
    return api.offlineMissionList.add(offlineMission).then((response) => {
      commit('addOfflineMission', [response]);
      return { ...response };
    });
  },

  /**
   * remove a mission from the list of offline missions
   */
  remove({ commit }, missionId) {
    return api.offlineMissionList.remove(missionId)
      .then(() => commit('removeOfflineMission', missionId));
  },

  /**
   * Load the list of missionId stored offline
   */
  loadOffline({ commit }) {
    return api.offlineMissionList.getAll().then(response => commit('addOfflineMission', response));
  },
};

const mutations = {
  addOfflineMission(state, newMissions) {
    newMissions.forEach((newMission) => {
      const stateIndex = state.all
        .findIndex(stateMission => stateMission.id === newMission.id);

      if (stateIndex >= 0) {
        state.all.splice(stateIndex, 1, newMission);
      } else {
        state.all.push(newMission);
      }
    });
  },

  removeOfflineMission(state, missionId) {
    state.all.splice(state.all.findIndex(mission => mission.id === missionId), 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
