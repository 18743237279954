<template>
  <div class="gestion-view">
    <v-overlay v-if="loadingModele">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <h3>Gestion des modèles de la mission</h3>
      <v-card
    class="mx-auto card-model"
    max-width="344"
  >
    <v-card-text v-if="selectedMission && selectedMission.siteModele && selectedSiteModel">
      <div>Modèle selectionné</div>
      <p class="text-h4 text--primary">
        {{ this.selectedSiteModel.nom }}
      </p>
      <p>Client: {{ this.modelClientName }}</p>
      <p>Mission: {{ this.modelMissionName }}</p>
    </v-card-text>
    <v-card-text v-else>
      <div>Aucun modèle selectionné</div>
    </v-card-text>
    <v-card-actions>
      <v-btn
        variant="text"
        color="deep-purple-accent-4"
        v-if="selectedMission && selectedMission.siteModele"
        @click="goToSite()"
      >
        Accéder au site
      </v-btn>
      <v-btn
        variant="text"
        color="deep-purple-accent-4"
        v-if="selectedMission && selectedMission.siteModele"
        @click="editModele()"
      >
        Modifier le site
      </v-btn>
      <v-btn
        variant="text"
        color="deep-purple-accent-4"
        @click="showDialogModele = true"
        v-else
      >
        Ajouter un site modèle
      </v-btn>
    </v-card-actions>
  </v-card>
      <v-dialog
      v-model="showDialogModele"
      persistent
      width="auto"
      content-class="elevation-0"
    >
      <v-card class="add-card-model">
        <v-card-title class="text-h5">
          Ajouter un site modèle
        </v-card-title>
        <div class="field-title">Choisissez un client</div>
          <v-autocomplete
            v-model="chosenClientId"
            :loading="loadingClient"
            :items="clients"
            item-value="id"
            item-text="nom"
            label="Client"
            solo
            @change="fetchMission($event)"
          >
          </v-autocomplete>
        <div v-if="chosenClientId">
            <div class="field-title" >Choisissez une mission</div>
            <v-select
              v-model="chosenMissionId"
              :loading="loadingMissions"
              :items="missions"
              item-value="id"
              item-text="nom"
              label="Mission"
              solo
              @change="fetchSites($event)"
            >
            </v-select>
          </div>
          <div v-if="chosenMissionId">
            <div class="field-title" >Choisissez un site</div>
            <v-select
              v-model="siteModel"
              :loading="loadingSites"
              :items="getSites"
              item-value="id"
              item-text="nom"
              label="Site"
              solo
            >
            </v-select>
          </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green-darken-1"
            variant="text"
            @click="showDialogModele = false"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            variant="text"
            @click="save(siteModel)"
          >
            Enregistrer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>


<script>
import { mapGetters, mapState } from 'vuex';

export default {
  components: {
  },
  data() {
    return {
      chosenClientId: null,
      chosenMissionId: null,
      saving: false,
      loadingModele: false,
      showDialogModele: false,
      loadingClient: false,
      loadingMissions: false,
      loadingSites: false,
      siteModel: null,
      selectedSiteModel: null,
      modelClientName: null,
      modelMissionName: null,
    };
  },
  computed: {
    ...mapState({
      allMissions: state => state.missions.missions,
      allSite: state => state.sites.sites,
      allClient: state => state.clients.clients,
    }),
    ...mapGetters('missions', { selectedMission: 'selected' }),
    clients() {
      if (this.allClient && this.allClient.length) {
        return this.allClient;
      }
      return [];
    },
    missions() {
      if (this.allMissions && this.allMissions.length && this.chosenClientId) {
        return this.allMissions
          .filter(mission => mission.client === this.chosenClientId);
      }
      return [];
    },
    getSites() {
      if (this.allSite && this.allSite.length && this.chosenMissionId) {
        return this.allSite.filter(site => site.mission === this.chosenMissionId);
      }
      return [];
    },
    selectedView: {
      get() {
        return this.$store.state.selectedView;
      },
      set(value) {
        this.$store.commit('setSelectedView', value);
      },
    },
  },

  async created() {
    await this.$store.dispatch('missions/refreshItem', this.selectedMission.id);
    this.loadingClient = true;
    this.loadingModele = true;
    this.selectedView = 'Gestion modèle(s)';
    if (this.selectedMission.siteModele) {
      this.selectedSiteModel = await this.getSelectedSiteModele();
      this.modelClientName = await this.getClientName();
      this.modelMissionName = await this.getMissionName();
    }
    await this.$store.dispatch('clients/fetchAll');
    this.loadingModele = false;
    this.loadingClient = false;
  },

  methods: {
    async editModele() {
      const clientModele = await this.getModeleClient();
      const missionModele = await this.getModeleMission();
      this.chosenClientId = clientModele.id;
      this.chosenMissionId = missionModele.id;
      this.siteModel = this.selectedMission.siteModele;
      this.showDialogModele = true;
    },
    async goToSite() {
      const missionId = await this.getModeleMission();
      this.$router.push(`/mission/${missionId.id}/site/${this.selectedMission.siteModele}`);
    },
    async  getSelectedSiteModele() {
      let site = null;
      if (this.selectedMission) {
        site = await this.$store.dispatch('sites/getById', this.selectedMission.siteModele);
      }
      return site;
    },
    async getMissionName() {
      const mission = await this.$store.dispatch('missions/getById', this.selectedSiteModel.mission);
      return mission.nom;
    },
    async getModeleMission() {
      const mission = await this.$store.dispatch('missions/getById', this.selectedSiteModel.mission);
      return mission;
    },
    async getClientName() {
      const mission = await this.$store.dispatch('missions/getById', this.selectedSiteModel.mission);
      const client = await this.$store.dispatch('clients/refreshItem', mission.client);
      return client.nom;
    },
    async getModeleClient() {
      const mission = await this.$store.dispatch('missions/getById', this.selectedSiteModel.mission);
      const client = await this.$store.dispatch('clients/refreshItem', mission.client);
      return client;
    },
    async fetchMission(clientId) {
      if (clientId) {
        this.chosenClientId = clientId;
        this.loadingMissions = true;
        await this.$store.dispatch('missions/fetchAllByClient', clientId);
        this.loadingMissions = false;
      }
    },
    async fetchSites(missionId) {
      if (missionId) {
        this.chosenMissionId = missionId;
        this.loadingSites = true;
        await this.$store.dispatch('sites/fetchAllByMission', missionId);
        this.loadingSites = false;
      }
    },
    save(event) {
      this.loadingModele = true;
      this.showDialogModele = false;
      this.saving = true;
      const payload = { };
      payload.id = this.selectedMission.id;
      payload.data = {
        siteModele: parseInt(event, 10),
      };

      this.$store.dispatch(
        'missions/save',
        {
          id: payload.id,
          ...payload,
        },
      ).then(async (res) => {
        if (res.isAxiosError) {
          const message = 'Impossible de sauvegarder les changements';
          this.$store.dispatch('snackbar/displaySnack',
            {
              message,
              type: 'error',
            });
        } else {
          this.selectedSiteModel = await this.$store.dispatch('sites/getById', this.selectedMission.siteModele);
          this.modelClientName = await this.getClientName();
          this.modelMissionName = await this.getMissionName();
          this.$store.dispatch('snackbar/displaySnack', { message: 'Sauvegardé avec succès !', type: 'succes' });
        }
      }).catch(() => {
        const message = 'Impossible de sauvegarder les changements';
        this.$store.dispatch('snackbar/displaySnack',
          {
            message,
            type: 'error',
          });
      }).finally(async () => {
        this.saving = false;
        this.loadingModele = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .gestion-view {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    padding: 1rem;

    > * {
      margin-bottom: 2rem;
    }
  }
  .combox {
    width:34em;
  }
  .add-card-model{
    height : auto;
    > * {
      margin: 1rem;
    }
  }
</style>
