<template>
  <div class="content-wrapper">
    <!-- saving progress bar -->
    <v-progress-linear
      class="save-loader"
      :active="saving"
      :indeterminate="saving"
      absolute
      color="primary"
    ></v-progress-linear>

    <!-- form -->
    <div
      class="block-form"
      v-if="!loading && !fetchError"
    >
      <!-- block title -->
      <v-text-field
        outlined
        label="Titre du bloc"
        v-model="title"
        @blur="handleSave($event)"
      ></v-text-field>

      <!-- pre block comment -->
      <v-textarea
        outlined
        label="Commentaires pré-contenu"
        v-model="preComment"
        @blur="handleSave($event)"
      ></v-textarea>

      <!-- image file -->
      <ImageUpload
        class="bloc-image"
        :default-value="imageIri"
        @new-image="handleNewImage($event)"
      >
      </ImageUpload>

      <!-- image caption -->
      <v-text-field
        outlined
        label="Légende de l'image"
        v-model="imageCaption"
        @blur="handleSave($event)"
      ></v-text-field>

      <!-- post block comment -->
      <v-textarea
        outlined
        label="Commentaires post-contenu"
        v-model="postComment"
        @blur="handleSave($event)"
      ></v-textarea>
    </div>

    <!-- placeholder for 'no data' -->
    <div
      class="no-data"
      v-if="!loading && fetchError"
    >
      <p>Erreur l'or de la récupération des donnés du bloc</p>
    </div>

    <!-- fetching spinner -->
    <v-progress-circular
      class="fetch-spinner"
      v-if="loading"
      :size="50"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
import axiosInstance from '../plugins/axios';


import ImageUpload from './ImageUpload.vue';

export default {
  components: {
    ImageUpload,
  },
  props: ['block', 'objectIri'],

  data() {
    return {
      rapportContenuId: null,
      title: '',
      preComment: '',
      postComment: '',
      imageFile: null,
      imageIri: '',
      imageCaption: '',
      loading: false,
      saving: false,
      fetchError: false,
      neverSaved: false,
      waitingLine: [],
    };
  },

  computed: {
    firstTimeSaving() {
      return this.saving && this.neverSaved;
    },

    updateInLine() {
      return this.waitingLine.includes(this.updateAction);
    },

    updateImageInLine() {
      return this.waitingLine.includes(this.updateImageAction);
    },

    createAction() {
      return {
        type: 'create',
        action: () => this.genericSave(() => this.createContenu()),
      };
    },

    updateAction() {
      return {
        type: 'update',
        action: () => this.genericSave(() => this.updateContenu()),
      };
    },

    updateImageAction() {
      return {
        type: 'imageUptade',
        action: () => this.genericSave(() => this.savePicture()),
      };
    },
  },

  created() {
    this.fetchData();
  },

  methods: {
    // retrieve the object rapportContenu from the server
    fetchData() {
      this.loading = true;
      axiosInstance.axiosInstance.get('/api/rapport-contenus', {
        params: {
          objetIri: this.objectIri,
          blockId: this.block.id,
        },
      })
        .then((response) => {
          this.fetchError = false;
          // if the response is an empty array, then the contenu has never been saved
          if (response.data.length) {
            const { blockContenu, id } = response.data[0];

            this.rapportContenuId = id;
            // if title is empty, get the default value from the structure
            this.title = blockContenu.title ? blockContenu.title : this.block.defaultValues.title;
            this.preComment = blockContenu.preComment;
            this.postComment = blockContenu.postComment;
            this.imageIri = blockContenu.image;
            this.imageCaption = blockContenu.imageCaption;
          } else {
            this.neverSaved = true;
          }
        })
        .catch(() => { this.fetchError = true; })
        .finally(() => { this.loading = false; });
    },

    // handle a form field loosing focus
    handleSave() {
      // if the form has never been saved and is not being saved right now
      // then add a creation order to the line
      if (this.neverSaved && !this.firstTimeSaving) {
        this.waitingLine.push(this.createAction);
      } else
      // only add an update order if there's not allready one in the line
      if (!this.updateInLine) {
        this.waitingLine.push(this.updateAction);
      }
      // trigger the handling of the line
      this.handleWaitingLine();
    },

    // handle a new image upload
    handleNewImage(newImage) {
      this.imageFile = newImage;
      // if the form has never been saved and is not being saved right now
      // then add a creation order to the line
      if (this.neverSaved && !this.firstTimeSaving) {
        this.waitingLine.push(this.createAction);
      }
      // only add an update image order if there's not allready one in the line
      if (!this.updateImageInLine) {
        this.waitingLine.push(this.updateImageAction);
      }
      // trigger the handling of the line
      this.handleWaitingLine();
    },

    // handle the orders waiting line management
    handleWaitingLine() {
      // if no order is waiting for completion and some are in line
      if (!this.saving && this.waitingLine.length) {
        this.saving = true;
        // extract the next order from the line and launch it
        const order = this.waitingLine.shift();
        order.action().finally(() => {
          // lauch the next order in the line if there's one
          if (this.waitingLine.length) {
            this.handleWaitingLine();
          }
        });
      }
    },

    // wrap a specificSaveFunction
    // handling snackbar succes message and setting this.saving to false in the end
    genericSave(specificSaveFunction) {
      return specificSaveFunction()
        .then(() => {
          this.$store.dispatch('snackbar/displaySnack', { message: 'Sauvegardé avec succès !', type: 'succes' });
        })
        .finally(() => {
          this.saving = false;
        });
    },

    // make an API call to create a rapportContenu
    createContenu() {
      // if saving for the first time, make a post
      // then retrieve the objectId and turn neverSaved to false
      return axiosInstance.axiosInstance.post('/api/rapport-contenus', {
        objetIri: this.objectIri,
        blockId: this.block.id,
        blockContenu: {
          title: this.title,
          preComment: this.preComment,
          postComment: this.postComment,
          imageCaption: this.imageCaption,
        },
      })
        .then((response) => {
          this.rapportContenuId = response.data.id;
          this.neverSaved = false;
        });
    },

    // make an API call to update a rapportContenu
    updateContenu() {
      return axiosInstance.axiosInstance.put(`/api/rapport-contenus/${this.rapportContenuId}`, {
        blockContenu: {
          title: this.title,
          preComment: this.preComment,
          postComment: this.postComment,
          imageCaption: this.imageCaption,
        },
      });
    },

    // make an API call to create a media and link it to the rapportContenu
    savePicture() {
      return new Promise((resolve, reject) => {
        // convert the image file into formData
        const formData = new FormData();
        formData.append('file', this.imageFile, this.imageFile.name);
        formData.append('content-type', 'multipart/form-data');
        // make the API call with the created formData
        axiosInstance.axiosInstance.post(`/api/media/rapport-contenu/${this.rapportContenuId}/image`, formData)
          .then((response) => {
            // update image Iri to display the newly saved image
            this.imageIri = response.data.id;
            resolve();
          })
          .catch((err) => { reject(err); });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .content-wrapper {
    max-width: 100%;

    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: stretch;
  }

  .block-form {
    margin-top: 2rem;
  }

  .bloc-image {
    max-height: 15rem;
    border: 1px solid;
    border-color: #00000036;
    border-radius: 5px;
    margin-bottom: 1rem;
  }

  .save-loader {
    width: 100%;
  }

  .fetch-spinner {
    align-self: center;
  }
</style>
