import api from '@/api';

/* eslint no-console: ["error", { allow: ["error", "log"] }] */
/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = {
  imageCatalogues: [],
  selectedId: null,
};

const getters = {
  selected: state => state.imageCatalogues.find(
    imageCatalogue => imageCatalogue.id === state.selectedId,
  ),
};

const actions = {

  /**
   * Create a new imageCatalogue and add it to the store
   */
  createNew({ commit }) {
    const newImageCatalogue = {
      id: `tempImageCatalogue${Math.random()}`,
      nom: 'Nouvelle Image',
      codeImageCatalogue: Math.random().toString(36).substring(2, 15)
      + Math.random().toString(36).substring(2, 15),
      image: null,
    };

    commit('addImageCatalogues', [newImageCatalogue]);
    return { ...newImageCatalogue };
  },

  /**
   * Save a imageCatalogue on the server (automatically select POST or PUT) and update the store
   */
  save({ commit }, payload) {
    return new Promise((resolve, reject) => {
      // if the imageCatalogue doesn't exhist on the backend, create it by post
      if (payload.id.startsWith('tempImageCatalogue')) {
        api.imageCatalogues.create(payload.data)
          .then((response) => {
            commit('deleteImageCatalogues', payload.id);
            commit('addImageCatalogues', [response]);
            resolve({ ...response });
          })
          .catch(error => reject(error));
      // else update it by put
      } else {
        api.imageCatalogues.update(payload.id, payload.data)
          .then((response) => {
            commit('addImageCatalogues', [response]);
            resolve({ ...response });
          })
          .catch(error => reject(error));
      }
    });
  },


  delete({ commit }, imageCatalogue) {
    return api.imageCatalogues.delete(imageCatalogue.id)
      .then(() => commit('deleteImageCatalogues', imageCatalogue.id));
  },

  /**
   * Fetch all imageCatalogues from the server and update the store
   */
  fetchAll({ commit }) {
    return api.imageCatalogues.getAll().then(response => commit('addImageCatalogues', response));
  },

  /**
   * Fetch one imageCatalogue by it's id fron the server and update the store
   */
  refreshItem({ commit }, imageCatalogueId) {
    return api.imageCatalogues.get(imageCatalogueId).then((response) => {
      commit('addImageCatalogues', [response]);
      return { ...response };
    });
  },

  async changeSelected({
    state, getters, commit, dispatch,
  },
  imageCatalogueId) {
    if (state.selectedId !== imageCatalogueId) {
      commit('changeSelectedId', imageCatalogueId);

      if (!getters.selected) {
        await dispatch('refreshItem', imageCatalogueId);
      }
    }
  },

  async clearSelected({ commit }) {
    await commit('changeSelectedId', null);
  },
};

const mutations = {
  addImageCatalogues(state, newImageCatalogues) {
    newImageCatalogues.forEach((newImageCatalogue) => {
      const stateIndex = state.imageCatalogues.findIndex(
        stateImageCatalogue => stateImageCatalogue.id === newImageCatalogue.id,
      );
      if (stateIndex >= 0) {
        state.imageCatalogues.splice(stateIndex, 1, newImageCatalogue);
      } else {
        state.imageCatalogues.push(newImageCatalogue);
      }
    });
  },

  deleteImageCatalogues(state, imageCatalogueId) {
    state.imageCatalogues.splice(
      state.imageCatalogues.findIndex(imageCatalogue => imageCatalogue.id === imageCatalogueId),
      1,
    );
  },

  changeSelectedId(state, imageCatalogueId) {
    state.selectedId = imageCatalogueId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
