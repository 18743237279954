<template>
<v-sheet>
    <v-overlay class="overlay" v-if="loading">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  <div class="equipment-form-wrapper">
    <div
      v-if="!loading && (!itemForm || !itemForm.fieldGroups.length)"
      class="no-form-message"
    >
      Désolé, nous n'avons trouvé aucun formulaire correspondant à cet objet.
    </div>

    <div
      v-for="(group, index) in itemForm.fieldGroups"
      :key="`grp_${itemId}_${index}`"
      :class="{ 'last-group': index === itemForm.fieldGroups.length - 1 }"
    >
    <div class="card">
      <v-icon
        class="iconExpend"
        color="primary"
        @click="toggleShowForm(index)"
        large
        >
        {{ showForm[index] ? `mdi-minus-circle-outline` : `mdi-plus-circle-outline` }}
      </v-icon>
      <p class="fields-group-title">{{ group.label }}
      </p>
    </div>
      <div class="card" v-if="showForm[index]">
        <div class="field" v-for="(field, i) in group.fields"
          :class="{'input-margin': field.hint}"
          :key="`fld_${itemId}_${index}_${i}`">
          <BaseField
            :duplicate="duplicate"
            class="content-field"
            :field="field"
            v-show="displayField(field)"
            :defaultValue="getFieldValue(field.name, field.nestedObj)"
            @fieldModified="$emit('trigger-save')"
            @changeAllEquipement="$emit('changeAllEquipement', $event)"
            :edit="onlyEdit"
          >
          </BaseField>
          </div>
      </div>
    </div>
    <!--<p
      class="required-fields-hint"
      v-if="itemForm && itemForm.fieldGroups.length"
    >
      * Champ requis
    </p>-->
  </div>
</v-sheet>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import BaseField from './fields/base-field.vue';

export default {
  name: 'form-builder',
  components: {
    BaseField,
  },
  props: {
    duplicate: {
      type: Boolean,
      default: false,
    },
    item: {
      type: Object,
      default: null,
    },
    itemType: {
      type: String,
      default: '',
    },
    onlyRequired: {
      type: Boolean,
      default: false,
    },
    addEquipements: {
      type: Boolean,
      default: false,
    },
    addInspections: {
      type: Boolean,
      default: false,
    },
    onlyEdit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      showForm: [],
    };
  },
  async created() {
    if (this.itemType === 'equipements') {
      this.loading = true;
      await this.$store.dispatch('formTemplates/fetchEquipementTemplate', {
        type: this.item.type,
        missionId: this.selectedMission.id,
      });
      this.loading = false;
    }
    if (this.itemType === 'inspections') {
      this.loading = true;
      await this.$store.dispatch('formTemplates/fetchInspectionTemplate', this.item.inspectionType);
      this.loading = false;
    }
    this.showForm = Array.from({ length: this.itemForm.fieldGroups.length }, () => true);
  },
  computed: {
    ...mapGetters({
      getFormTemplate: 'formTemplates/getFormTemplate',
      getEquipementFormTemplate: 'formTemplates/getEquipementFormTemplate',
      selectedMission: 'missions/selected',
    }),
    ...mapState(['currentForm']),

    itemForm() {
      let form;
      if (this.itemType === 'equipements') {
        const missionId = this.selectedMission.id;
        form = this.getEquipementFormTemplate(this.item, missionId);
      } else {
        form = this.getFormTemplate(this.itemType, this.item);
      }

      if (this.onlyRequired) {
        return this.filterRequired(form);
      }

      if (this.addEquipements) {
        return this.formAddEquipements(form);
      }

      if (this.addInspections) {
        return this.formAddInspections(form);
      }

      if (this.onlyEdit) {
        return this.filterOnlyCreate(form);
      }
      return form;
    },

    itemId() {
      if (this.item) {
        return this.item.id;
      }
      return `new_${this.itemType}_${Math.random()}`;
    },
  },
  methods: {
    toggleShowForm(index) {
      this.$set(this.showForm, index, !this.showForm[index]);
    },
    displayField(field) {
      let display = true;
      let parentField;
      if (typeof (field.interaction) === 'undefined') {
        parentField = null;
      } else if (field.interaction.indexOf('if') !== -1) {
        parentField = field.interaction.split(':');
        if (this.currentForm[parentField[1]]
        || (this.currentForm.attributs && this.currentForm.attributs[parentField[1]])) {
          display = true;
        } else {
          display = false;
          if (this.item.attributs && this.item.attributs[field.name]) {
            this.item.attributs[field.name] = null;
          }
          if (this.currentForm.attributs && typeof this.currentForm.attributs[field.name] !== 'boolean') {
            this.$store.dispatch('updateFormField', {
              value: null,
              name: field.name,
              nestedObj: 'attributs',
            });
          }
        }
      }
      return display;
    },
    // get the value of a field on an object
    // handle 1 level of nested via the nestedObj property
    getFieldValue(fieldName, nestedObj) {
      if (this.item) {
        if (nestedObj) {
          if (
            this.item[nestedObj]
            && this.item[nestedObj][fieldName] !== undefined
            && this.item[nestedObj][fieldName] !== null
          ) {
            return this.item[nestedObj][fieldName];
          }
        } else if (this.item[fieldName] !== undefined && this.item[fieldName] !== null) {
          return this.item[fieldName];
        }
      }
      return null;
    },

    // return a form without unrequired fields
    filterRequired(form) {
      return {
        id: form.id,
        name: form.name,
        fieldGroups: form.fieldGroups
          .map(fieldGroup => ({
            id: fieldGroup.id,
            name: fieldGroup.name,
            label: fieldGroup.label,
            fields: fieldGroup.fields.filter(field => field.rules && field.rules.includes('required')),
          }))
          .filter(fieldGroup => fieldGroup.fields.length),
      };
    },

    formAddEquipements(form) {
      return {
        id: form.id,
        name: form.name,
        fieldGroups: form.fieldGroups
          .map(fieldGroup => ({
            id: fieldGroup.id,
            name: fieldGroup.name,
            label: fieldGroup.label,
            fields: fieldGroup.fields.filter(field => field.name === 'description' || field.name === 'nom'),
          }))
          .filter(fieldGroup => fieldGroup.fields.length)
          .filter(fieldGroup => fieldGroup.name === 'general'),
      };
    },

    formAddInspections(form) {
      return {
        id: form.id,
        name: form.name,
        fieldGroups: form.fieldGroups
          .map(fieldGroup => ({
            id: fieldGroup.id,
            name: fieldGroup.name,
            label: fieldGroup.label,
            fields: fieldGroup.fields.filter(field => field.name === 'nom'),
          })).filter(fieldGroup => fieldGroup.fields.length),
      };
    },

    // return a form without 'create-only' fields
    filterOnlyCreate(form) {
      return {
        id: form.id,
        name: form.name,
        fieldGroups: form.fieldGroups
          .map(fieldGroup => ({
            id: fieldGroup.id,
            name: fieldGroup.name,
            label: fieldGroup.label,
            fields: fieldGroup.fields.filter((field) => {
              // to include fields without particular rules like images.
              const isAllowed = (field.rules && !field.rules.includes('create-only')) || !field.rules;
              return isAllowed;
            }),
          }))
          .filter(fieldGroup => fieldGroup.fields.length),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
    .overlay {
      z-index:100 !important;
    }
    .card{
      width: 100%;
      text-align: center;
    }
    .iconExpend {
      float: right;
    }
    .equipment-form-wrapper{
      display: flex;
      flex-flow: row wrap;
      align-items: flex-start;
      justify-content: space-evenly;
      width: 100%;
      background-color: #f9f9f9;
      > * {
      margin: 1rem;
      padding:1rem;
      flex-grow: 4;
      align-items: center;
      border-radius: 5px;

      display: flex;
      flex-flow: column nowrap;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
      background-color: white;
      }
    }
    .last-group {
      margin-bottom: 2rem;
    }

    .fields-group-title {
      font-size: 1.5rem;
      font-weight: 300;
      margin-bottom: 1rem;

      color: $app-blue;
    }

    .required-fields-hint {
      font-style: italic;
    }

    .form-loader {
      margin: 1rem auto 1rem auto;
      width: 100%;
      max-width: 400px;
    }

    .no-form-message {
      margin-bottom: 1rem;
      padding: 2rem;
      font-weight: 300;
      font-size: 1.4rem;
      text-align: center;

      background-color: $app-white;
      border: 1px solid rgb(226, 226, 226);
      border-radius: 15px;
  }

  .input-margin{
    margin-bottom: 10px;
  }

  .field{
    display: flex;
    max-width: 70em;
    min-width: 35em;
  }

  .content-field {
    width:100%;
  }
</style>
