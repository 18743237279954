/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  batimentsEndpoint,
  niveauxEndpoint,
  niveauxUrl,
} from './apiUrls';
import equipementsApi from './equipementsApi';

export default {
  db: new PouchDB('niveaux'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch
    const _id = obj._id || obj.id.toString();

    return {
      _id,
      meta: obj.meta || {},
      id: String(obj.id),
      nom: obj.nom,
      perimetre: obj.perimetre,
      hsp: obj.hsp,
      txPbExt: obj.txPbExt,
      txPhExt: obj.txPhExt,
      txSv: obj.txSv,
      batiment: obj.batiment.replace(`${batimentsEndpoint}/`, ''),
      calculations: obj.calculations,
    };
  },

  async create(niveau, niveauId) {
    // on créé un objet uniquement pour la sauvegarde locale si pas de connexion
    const localNiveau = {
      id: niveauId,
      ...niveau,
    };

    // si l'objet est dépendant d'un objet supérieur pas encore créé
    // alors on ne tente même pas une sauvegarde serveur
    // et on le sauvegarde directement sur PouchDB
    if (niveau.batiment.startsWith('temp')) {
      return pouchUtil.addDoc(localNiveau, this.db, { toCreate: true });
    }

    let savedNiveau = null;

    try {
      // on essaye de sauvegarder via l'API
      const response = await axiosInstance.axiosInstance.post(niveauxEndpoint, niveau);
      savedNiveau = this.convertBackendObject(response.data);
    } catch (e) {
      if (e.response
        && e.response.status >= 400
        && e.response.status < 500) {
        throw new Error(e);
      }
      // si la sauvegarde en ligne a échouée, on enregistre les données dans
      // le cache avec le flag 'toCreate' à true afin de relancer la synchro
      // quand on aura à nouveau une connexion
      return pouchUtil.addDoc(localNiveau, this.db, { toCreate: true });
    }

    // si la sauvegarde en ligne a fonctionné
    // si on avait un niveau avec un id temporaire dans pouchDB on le supprime
    if (niveauId.startsWith('temp')) {
      try {
        await pouchUtil.deleteDoc(niveauId, this.db);
      } catch (e) {
        // do nothing
      }
    }

    // update niveau equipements with the new id
    await this.updateEquipements(niveau._id, savedNiveau.id);

    // enfin on sauvegarde dans pouchDB le nouveau niveau et on le retourne
    return pouchUtil.addDoc(savedNiveau, this.db);
  },

  async update(niveau, niveauId) {
    let savedNiveau = null;

    try {
      // on essaye de sauvegarder via l'API
      const response = await axiosInstance.axiosInstance.put(`${niveauxEndpoint}/${niveauId}`, niveau);
      savedNiveau = this.convertBackendObject(response.data);
    } catch (e) {
      if (e.response
        && e.response.status >= 400
        && e.response.status < 500) {
        throw new Error(e);
      }
      // si la sauvegarde en ligne a échouée, on enregistre les données dans
      // le cache avec le flag 'toUpdate' à true afin de relancer la synchro
      // quand on aura à nouveau une connexion
      return pouchUtil.addDoc({ id: niveauId, ...niveau }, this.db, { toUpdate: true });
    }

    // si la sauvegarde en ligne à reussie, on sauvegarde dans pouchDB et on retourne le resultat
    return pouchUtil.addDoc(savedNiveau, this.db, { updated: true });
  },

  /**
   * When a new batiment is created, if this batiment has unsaved niveaux children
   * update those niveaux to replace the tempBatimentId wit the new id
   */
  async handleParentCreated(tempBatimentId, newBatimentId) {
    // get all the niveaux that are in the batiment
    const childrens = await pouchUtil.getAllDocsByField('batiment', tempBatimentId, this.db);

    if (childrens.length) {
      // update the children with the new site id
      const updatedChildrens = childrens.map(children => ({
        ...children,
        batiment: newBatimentId,
      }));
      // save them back on PouchDB
      await this.db.bulkDocs(updatedChildrens);
    }
  },

  /**
   * synchronise a niveau with the server, creating or updating it
   */
  async synchronise(niveau) {
    // creation
    if (niveau.meta.toCreate) {
      if (niveau.batiment.startsWith('temp')) {
        const pouchNiveau = await pouchUtil.addDoc(niveau, this.db, {
          toCreate: true,
          error: true,
          errorMessage: 'Bâtiment parent non synchronisé',
        });
        return { savedItem: pouchNiveau };
      }

      try {
        // make a copy of niveau with a removed id field for the POST request
        const niveauToCreate = { ...niveau };
        delete niveauToCreate.id;

        // create niveau on the server
        const response = await axiosInstance.axiosInstance.post(niveauxEndpoint, niveauToCreate);
        const savedNiveau = this.convertBackendObject(response.data);

        // delete temp niveau in pouch
        if (niveau._id.startsWith('temp')) {
          try {
            await pouchUtil.deleteDoc(niveau._id, this.db);
          } catch (e) {
            // do nothing
          }
        }

        // update niveau equipements with the new id
        await this.updateEquipements(niveau._id, savedNiveau.id);

        // save batiment in pouch and return it
        const pouchNiveau = await pouchUtil.addDoc(savedNiveau, this.db);
        return {
          oldId: niveau._id,
          savedItem: pouchNiveau,
        };
      } catch (e) {
        // if create fail and functionnal error -> save on pouch with error
        if (e.response
          && e.response.status >= 400
          && e.response.status < 500) {
          const pouchNiveau = await pouchUtil.addDoc(niveau, this.db, {
            toCreate: true,
            error: true,
            errorMessage: e.response.data.detail,
          });
          return { savedItem: pouchNiveau };
        }
        // if it's not a functionnal error, stop synchro and throw error
        throw new Error(e);
      }
    }

    // update
    if (niveau.meta.toUpdate) {
      try {
        // update niveau on the server
        const response = await axiosInstance.axiosInstance.put(`${niveauxEndpoint}/${niveau.id}`, niveau);
        const savedNiveau = this.convertBackendObject(response.data);

        // save niveau in pouch and return it
        const pouchNiveau = await pouchUtil.addDoc(savedNiveau, this.db, { updated: true });
        return { savedItem: pouchNiveau };
      } catch (e) {
        // if update fail and functionnal error -> save on pouch with error
        if (e.response
          && e.response.status >= 400
          && e.response.status < 500) {
          const pouchNiveau = await pouchUtil.addDoc(niveau, this.db, {
            toUpdate: true,
            error: true,
            errorMessage: e.response.data.detail,
          });
          return { savedItem: pouchNiveau };
        }
        // if it's not a functionnal error, stop synchro and throw error
        throw new Error(e);
      }
    }

    throw new Error('no update/create flag on this niveau');
  },

  /**
   * synchronise with the server all niveaux that need to be saved
   */
  async synchroniseAll() {
    // get all niveaux in PouchDB with meta.create === true or meta.update === true
    const niveauxToSave = await pouchUtil.getAllDocsToSync(this.db);
    return Promise.all(niveauxToSave.map(niveau => this.synchronise(niveau)));
  },

  async updateEquipements(tempNiveauId, newNiveauId) {
    await equipementsApi.handleNiveauCreated(tempNiveauId, newNiveauId);
  },

  /**
   * delete a niveau from pouchDB and the server
   */
  async delete(niveauId) {
    // delete the niveau from pouchDB
    await pouchUtil.deleteDoc(niveauId, this.db);

    if (!niveauId.startsWith('temp')) {
      // if the delete from pouchDB is sucessfull, delete from the server
      return axiosInstance.axiosInstance.delete(`${niveauxEndpoint}/${niveauId}`);
    }

    return true;
  },

  /**
   * get one niveau by his id
   * @param {String} niveauId - the id of the niveau to get
   */
  async get(niveauId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${niveauxEndpoint}/${niveauId}`);
      const niveau = this.convertBackendObject(response.data);

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDoc(niveau, this.db);
    } catch (e) {
      console.error('niveauxApi get error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getDoc(niveauId, this.db);
    }
  },

  /**
   * get all niveaux of the application
   */
  async getAll() {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${niveauxEndpoint}`);
      const niveaux = response.data.map(niveau => this.convertBackendObject(niveau));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(niveaux, this.db);
    } catch (e) {
      console.error('niveauxApi getAll error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocs(this.db);
    }
  },

  /**
   * get all children niveaux of a batiment
   * @param {String} batimentId - id of the parent batiment
   */
  async getAllByBatiment(batimentId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${batimentsEndpoint}/${batimentId}${niveauxUrl}`);
      const niveaux = response.data.map(niveau => this.convertBackendObject(niveau));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(niveaux, this.db);
    } catch (e) {
      console.error('niveauxApi getAllByBatiment error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByField('batiment', batimentId, this.db);
    }
  },

  /**
   * get all children niveaux of a batiment for offline use
   * @param {String} batimentId - id of the parent batiment
   */
  async fetchOffline(batimentId) {
    const response = await axiosInstance.axiosInstance.get(`${batimentsEndpoint}/${batimentId}${niveauxUrl}`);
    const niveaux = response.data.map(niveau => this.convertBackendObject(niveau));
    // if get is successful,  store data into pouchdb and return it
    return pouchUtil.addDocs(niveaux, this.db);
  },

  /**
   * Fetch all niveaux stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocsToSync(this.db);
  },
};
