<template>
  <BaseCard :loading="loading">
    <template v-slot:title>
      <div class="div-title">
        <div>
          Etat des lieux énergétiques
        </div>
        <div class="switch-div">
          <v-btn-toggle
            v-if="featuredItem.estConsommationEfImportee"
            :dense="true"
            v-model="localValue"
            color="primary"
            @change="switchSaveValue(localValue)"
            mandatory
          >
            <v-btn
            :value="false"
            >
            sur facture
            </v-btn>
            <v-btn
            :value="true"
            >
            importé
            </v-btn>
          </v-btn-toggle>
        </div>
      </div>
    </template>
    <template v-slot:content>
      <!-- <v-card-text class="text--primary flex"> -->
      <div class="conso-container">
        <div
          class="info-conso"
          v-for="(conso, index) in dataConso"
          :key="index"
        >
          <div class="item"><v-icon class="logo">{{conso.logo ? conso.logo: '-'}}</v-icon></div>
          <div class="item small">{{conso.label ? conso.label: '-'}}</div>
          <div class="item large">
            {{conso.value ? parseFloat(conso.value).toLocaleString('fr') : '-'}}
          </div>
          <div class="item font-unite">{{conso.unite ? conso.unite: '-'}}</div>
          <div class="item large">
            {{conso.valueShon ? parseFloat(conso.valueShon).toLocaleString('fr') : '-'}}
          </div>
          <div class="item font-unite">{{conso.uniteShon ? conso.uniteShon : '-'}}</div>
        </div>
      </div>
      <!-- </v-card-text> -->
    </template>
  </BaseCard>

</template>

<script>
import BaseCard from './BaseCard.vue';

export default {
  components: { BaseCard },

  props: ['featuredItem', 'loading', 'typeItem'],
  data() {
    return {
      test: true,
      localValue: this.featuredItem.estUtiliseeConsommationImportee,
    };
  },

  computed: {
    dataConso() {
      if (this.featuredItem
      && this.featuredItem.calculations) {
        return [
          {
            label: 'Conso',
            value: this.featuredItem.calculations.consommationEfAnneeReference,
            unite: 'kWh EF',
            // eslint-disable-next-line max-len
            valueShon: this.featuredItem.calculations.ratioConsommationEfSurfacePlancherAnneeReference,
            uniteShon: 'kWhEF/m²SP',
            logo: 'mdi-lightning-bolt-circle',
          },
          /* {
            label: 'Conso EP',
            value: this.featuredItem.calculations.consommationEpAnneeReference,
            unite: 'kWh EP',
            // eslint-disable-next-line max-len
            valueShon:
            this.featuredItem.calculations.ratioConsommationEpSurfaceDeclareAnneeReference,
            uniteShon: 'EP/m²',
          }, */
          {
            label: 'Emissions CO2',
            value: this.featuredItem.calculations.emissionCo2AnneeReference,
            unite: 'kgéqCO2',
            valueShon: this.featuredItem.calculations.ratioEmissionCo2SurfacePlancherAnneeReference,
            uniteShon: 'kgéqCO2/m²SP',
            logo: 'mdi-molecule-co2',
          },
          {
            label: 'Coût',
            value: this.featuredItem.calculations.coutTtcAnneeReference,
            unite: '€ TTC',
            valueShon: this.featuredItem.calculations.ratioCoutTtcSurfacePlancherAnneeReference,
            uniteShon: '€TTC/m²SP',
            logo: 'mdi-file-document-multiple-outline',
          },
        ];
      }
      return [];
    },
  },

  methods: {
    switchSaveValue(value) {
      // payload construction //
      const payload = { };
      const formCopy = JSON.parse(JSON.stringify(this.featuredItem));
      formCopy.estUtiliseeConsommationImportee = value;
      payload.data = formCopy;
      this.$store.dispatch(
        'sites/save',
        {
          id: this.featuredItem.id,
          ...payload,
        },
      ).then(() => {
        this.$store.dispatch('snackbar/displaySnack', { message: 'Modifcation sauvegardée avec succès !', type: 'succes' });
      }).catch(() => {
        this.$store.dispatch('snackbar/displaySnack',
          {
            message: 'Impossible de sauvegarder la modification',
            type: 'error',
          });
      });
    },
  },

};
</script>

<style lang="scss" scoped>
  .conso-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
  }

  .info-conso {
    // margin: auto;
    min-width: 7rem;
    text-align: center;
    // margin: 1rem;
  }

  .item{
    // padding: 0.5rem 2rem 0rem 2rem;
  }
  .large {
    font-size: 1.5rem;
    margin-top: 0.2rem;
  }

  .small {
    font-size: 0.9rem;
  }

  .font-unite {
    font-size: 0.7rem;
  }

  .div-title{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
  }

  .switch-div{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    font-size:1rem;
  }

  .left-switch {
    padding-right:1rem;
  }

  .right-switch{
    padding-left:1rem;
  }

  .logo {
    color: $app-blue;
  }

</style>
