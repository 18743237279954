<template>
  <div>
    <div class="liste-un">
      <h4>Paramètres par défaults de la liste 1</h4>
      <v-autocomplete
        :items="this.attributesList"
        return-object
        item-text="libelle"
        ref="selectRef"
        label="Paramètre 1"
        @change="changeLists()"
        v-model="ParamUnListUn"
      >
        <template v-slot:label>
          <slot name="field-label"></slot>
        </template>
      </v-autocomplete>
      <v-autocomplete
        :items="this.attributesList"
        return-object
        item-text="libelle"
        ref="selectRef"
        label="Paramètre 2"
        @change="changeLists()"
        v-model="ParamDeuxListUn"
      >
        <template v-slot:label>
          <slot name="field-label"></slot>
        </template>
      </v-autocomplete>
    </div>
    <div>
      <h4>Paramètres par défaults de la liste 2</h4>
      <v-autocomplete
        :items="this.attributesList"
        return-object
        item-text="libelle"
        ref="selectRef"
        label="Paramètre 1"
        @change="changeLists()"
        v-model="ParamUnListDeux"
      >
        <template v-slot:label>
          <slot name="field-label"></slot>
        </template>
      </v-autocomplete>
      <v-autocomplete
        :items="this.attributesList"
        return-object
        item-text="libelle"
        ref="selectRef"
        label="Paramètre 2"
        @change="changeLists()"
        v-model="ParamDeuxListDeux"
      >
        <template v-slot:label>
          <slot name="field-label"></slot>
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MissionChangeParamList',
  props: ['listTypeEquipement',
    'organisationListeEquipement'],
  computed: {
    attributesList() {
      const list = [];

      this.listTypeEquipement.forEach((type) => {
        if (type
          && type.formTemplate[0]) {
          Object.keys(type.formTemplate[0].fields).forEach((attribut) => {
            if (type.formTemplate[0].fields[attribut]) {
              list.push({
                typeAttribut: 'spécifique',
                libelle: type.formTemplate[0].fields[attribut].label,
                code: type.formTemplate[0].fields[attribut].name,
              });
            }
          });
        }
      });
      list.push({ typeAttribut: 'général', libelle: 'Description', code: 'description' });
      list.push({ typeAttribut: 'général', libelle: 'En alerte', code: 'inAlert' });
      list.push({ typeAttribut: 'général', libelle: 'Catégorie', code: 'libelleCategorie' });
      list.push({ typeAttribut: 'général', libelle: 'Domaine', code: 'libelleDomaine' });
      list.push({ typeAttribut: 'général', libelle: 'Type', code: 'libelleType' });
      list.push({ typeAttribut: 'général', libelle: 'Localisation local', code: 'local' });
      list.push({ typeAttribut: 'général', libelle: 'Niveau', code: 'niveau' });
      list.push({ typeAttribut: 'général', libelle: 'Performance', code: 'performance' });
      list.push({ typeAttribut: 'général', libelle: 'Vétusté', code: 'vetuste' });
      list.push({ typeAttribut: 'général', libelle: 'Nom', code: 'nom' });
      return list;
    },
  },
  data() {
    return {
      ParamUnListUn: null,
      ParamDeuxListUn: null,
      ParamUnListDeux: null,
      ParamDeuxListDeux: null,
    };
  },
  created() {
    this.ParamUnListUn = this.organisationListeEquipement.paramUnListeUne;
    this.ParamDeuxListUn = this.organisationListeEquipement.paramDeuxListeUne;
    this.ParamUnListDeux = this.organisationListeEquipement.paramUnListeDeux;
    this.ParamDeuxListDeux = this.organisationListeEquipement.paramDeuxListeDeux;
  },
  methods: {
    changeLists() {
      const List = {
        paramUnListeUne: this.ParamUnListUn,
        paramDeuxListeUne: this.ParamDeuxListUn,
        paramUnListeDeux: this.ParamUnListDeux,
        paramDeuxListeDeux: this.ParamDeuxListDeux,
      };
      this.$emit('listsChange', List);
    },
  },
};
</script>
