/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  inspectionTypesEndpoint,
  batimentsEndpoint,
  sitesEndpoint,
  inspectionsEndpoint,
} from './apiUrls';

import interventionsApi from './interventionsApi';
import picturesApi from './picturesApi';

export default {
  db: new PouchDB('inspections'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch
    const _id = obj._id || obj.id.toString();
    const meta = {};
    const site = obj.site ? obj.site.replace(`${sitesEndpoint}/`, '') : '';
    const batiment = obj.batiment ? obj.batiment.replace(`${batimentsEndpoint}/`, '') : '';

    return {
      _id,
      nom: obj.nom,
      meta,
      id: String(obj.id),
      nomType: obj.nomType,
      codeType: obj.codeType,
      inspectionType: obj.inspectionType.replace(`${inspectionTypesEndpoint}/`, ''),
      batiment,
      site,
      images: obj.images,
      constats: obj.constats,
      inAlert: obj.inAlert,
    };
  },

  async create(inspection, inspectionId) {
    // on créé un objet uniquement pour la sauvegarde locale si pas de connexion
    const localInspection = {
      id: inspectionId,
      ...inspection,
    };

    const inspectionToSave = { ...inspection };
    delete inspectionToSave.images;


    // si l'objet est dépendant d'un objet supérieur pas encore créé
    // alors on ne tente même pas une sauvegarde serveur
    // et on le sauvegarde directement sur PouchDB
    const batiment = inspection.batiment ? inspection.batiment : '';
    const site = inspection.site ? inspection.site : '';
    if (site.startsWith('temp') || batiment.startsWith('temp')) {
      return pouchUtil.addDoc(localInspection, this.db, { toCreate: true });
    }

    let savedInspection = null;

    try {
      // on essaye de sauvegarder via l'API
      const response = await axiosInstance.axiosInstance
        .post(inspectionsEndpoint, inspectionToSave);
      savedInspection = this.convertBackendObject(response.data);
    } catch (e) {
      if (e.response
        && e.response.status >= 400
        && e.response.status < 500) {
        throw new Error(e);
      }
      // si la sauvegarde en ligne a échouée, on enregistre les données dans
      // le cache avec le flag 'toCreate' à true afin de relancer la synchro
      // quand on aura à nouveau une connexion
      return pouchUtil.addDoc(localInspection, this.db, { toCreate: true });
    }

    // si la sauvegarde en ligne a fonctionné
    // si on avait un batiment avec un id temporaire dans pouchDB on le supprime
    if (inspectionId.startsWith('temp')) {
      try {
        await pouchUtil.deleteDoc(inspectionId, this.db);
      } catch (e) {
        // do nothing
      }
    }

    // update intervention & childrens with the new id
    await this.updateIntervention(inspectionId, savedInspection.id);
    await this.updateChildrens(inspectionId, savedInspection.id);

    // enfin on sauvegarde dans pouchDB le nouveau batiment et on le retourne
    return pouchUtil.addDoc(savedInspection, this.db);
  },

  async update(inspectionId, inspection) {
    let savedInspection = null;
    const inspectionToSave = { ...inspection };
    delete inspectionToSave.images;

    try {
      // on essaye de sauvegarder via l'API
      const response = await axiosInstance.axiosInstance.put(`${inspectionsEndpoint}/${inspectionId}`, inspectionToSave);
      savedInspection = this.convertBackendObject(response.data);
    } catch (e) {
      if (e.response
        && e.response.status >= 400
        && e.response.status < 500) {
        throw new Error(e);
      }
      // si la sauvegarde en ligne a échouée, on enregistre les données dans
      // le cache avec le flag 'toUpdate' à true afin de relancer la synchro
      // quand on aura à nouveau une connexion
      return pouchUtil
        .addDoc({ id: inspectionId, ...inspectionToSave }, this.db, { toUpdate: true });
    }
    // si la sauvegarde en ligne à reussie, on sauvegarde dans pouchDB et on retourne le resultat
    return pouchUtil.addDoc(savedInspection, this.db, { updated: true });
  },

  /**
   * synchronise a inspection with the server, creating or updating it
   */
  async synchronise(inspection) {
    // creation
    if (inspection.meta.toCreate) {
      if (inspection.batiment && inspection.batiment.startsWith('temp')) {
        const pouchInspection = await pouchUtil.addDoc(inspection, this.db, {
          toCreate: true,
          error: true,
          errorMessage: 'Bâtiment parent non synchronisé',
        });
        return { savedItem: pouchInspection };
      }

      if (inspection.site && inspection.site.startsWith('temp')) {
        const pouchInspection = await pouchUtil.addDoc(inspection, this.db, {
          toCreate: true,
          error: true,
          errorMessage: 'Site parent non synchronisé',
        });
        return { savedItem: pouchInspection };
      }

      try {
        // make a copy of inspection with a removed id field for the POST request
        const inspectionToCreate = { ...inspection };
        delete inspectionToCreate.id;
        delete inspectionToCreate.images;

        // create inspection on the server
        const response = await axiosInstance.axiosInstance
          .post(inspectionsEndpoint, inspectionToCreate);
        const savedInspection = this.convertBackendObject(response.data);

        // delete temp inspection in pouch
        if (inspection._id.startsWith('temp')) {
          try {
            await pouchUtil.deleteDoc(inspection._id, this.db);
          } catch (e) {
            // do nothing
          }
        }

        // update intervention & childrens with the new id
        await this.updateIntervention(inspection._id, savedInspection.id);
        await this.updateChildrens(inspection._id, savedInspection.id);

        // save inspection in pouch and return it
        const pouchInspection = await pouchUtil.addDoc(savedInspection, this.db);
        return {
          oldId: inspection._id,
          savedItem: pouchInspection,
        };
      } catch (e) {
        // if create fail and functionnal error -> save on pouch with error
        if (e.response
          && e.response.status >= 400
          && e.response.status < 500) {
          const pouchInspection = await pouchUtil.addDoc(inspection, this.db, {
            toCreate: true,
            error: true,
            errorMessage: e.response.data.detail,
          });
          return { savedItem: pouchInspection };
        }
        // if it's not a functionnal error, stop synchro and throw error
        throw new Error(e);
      }
    }

    // update
    if (inspection.meta.toUpdate) {
      try {
        const inspectionToUpate = { ...inspection };
        delete inspectionToUpate.images;

        // update inspection on the server
        const response = await axiosInstance.axiosInstance.put(`${inspectionsEndpoint}/${inspection.id}`, inspectionToUpate);
        const savedInspection = this.convertBackendObject(response.data);

        // save inspection in pouch and return it
        const pouchInspection = await pouchUtil.addDoc(savedInspection, this.db, { updated: true });
        return { savedItem: pouchInspection };
      } catch (e) {
        // if update fail and functionnal error -> save on pouch with error
        if (e.response
          && e.response.status >= 400
          && e.response.status < 500) {
          const pouchInspection = await pouchUtil.addDoc(inspection, this.db, {
            toUpdate: true,
            error: true,
            errorMessage: e.response.data.detail,
          });
          return { savedItem: pouchInspection };
        }
        // if it's not a functionnal error, stop synchro and throw error
        throw new Error(e);
      }
    }

    throw new Error('no update/create flag on this inspection');
  },

  /**
   * synchronise with the server all inspections that need to be saved
   */
  async synchroniseAll() {
    // get all inspections in PouchDB with meta.create === true or meta.update === true
    const inspectionsToSave = await pouchUtil.getAllDocsToSync(this.db);
    return Promise.all(inspectionsToSave.map(inspection => this.synchronise(inspection)));
  },


  async handleParentCreated(tempParentId, newParentId, entity) {
    let childrens;

    if (entity === 'batiment') {
      childrens = await pouchUtil.getAllDocsByField('batiment', tempParentId, this.db);
    } else if (entity === 'site') {
      childrens = await pouchUtil.getAllDocsByField('site', tempParentId, this.db);
    }

    let updatedChildrens;
    if (childrens.length) {
      if (entity === 'batiment') {
        updatedChildrens = childrens.map(children => ({
          ...children,
          batiment: newParentId,
        }));
      } else if (entity === 'site') {
        updatedChildrens = childrens.map(children => ({
          ...children,
          site: newParentId,
        }));
      }

      // save them back on PouchDB
      await this.db.bulkDocs(updatedChildrens);
    }
  },

  async updateIntervention(tempEquipementId, newEquipementId) {
    await interventionsApi.handleLinkCreated(tempEquipementId, newEquipementId, 'inspection');
  },

  async updateChildrens(tempInspectionId, newInspectionId) {
    await picturesApi.handleParentCreated(tempInspectionId, newInspectionId);
  },

  /**
   * get all inspections for a site
   * @param {String} siteId - id of the parent site
   */
  async getAllBySite(siteId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${inspectionsEndpoint}?site=${siteId}&inspectionType.active=true`);
      const inspections = response.data.filter(
        result => (result.batiment === null),
      ).map(inspection => this.convertBackendObject(inspection));
      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(inspections, this.db);
    } catch (e) {
      console.error('inspectionsApi getAllBySite error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByField('site', siteId, this.db);
    }
  },

  /**
   * get all inspections for a site
   * @param {String} batimentId - id of the parent batiment
   */
  async getAllByBatiment(batimentId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${inspectionsEndpoint}?batiment=${batimentId}&inspectionType.active=true`);
      const inspections = response.data
        .map(inspection => this.convertBackendObject(inspection));
      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(inspections, this.db);
    } catch (e) {
      console.error('inspectionsApi getAllByBatiment error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByField('batiment', batimentId, this.db);
    }
  },

  /**
   * get all inspections of the application
   */
  async getAll() {
    // first try to fetch data from API
    try {
      const response = await axiosInstance.axiosInstance.get(`${inspectionsEndpoint}?inspectionType.active=true`);
      const inspections = response.data.map(inspection => this.convertBackendObject(inspection));

      // if get is successful,  store data into pouchdb and return it
      const results = await pouchUtil.addDocs(inspections, this.db);

      return results;
    } catch (e) {
      console.error('inspectionsApi getAll error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocs(this.db);
    }
  },

  /**
   * get one inspection by his id
   * @param {String} inspectionId - the id of the inspection to get
   */
  async get(inspectionId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${inspectionsEndpoint}/${inspectionId}`);
      const inspection = this.convertBackendObject(response.data);

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDoc(inspection, this.db);
    } catch (e) {
      console.error('inspectionsApi get error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getDoc(inspectionId, this.db);
    }
  },
  /**
 * delete a inspection from pouchDB and the server
 */
  async delete(inspectionId) {
    // delete the equipement from pouchDB
    await pouchUtil.deleteDoc(inspectionId, this.db);
    // delete any picture not yet syncronized
    await picturesApi.handleParentDeleted(inspectionId);

    if (!inspectionId.startsWith('temp')) {
      // if the delete from pouchDB is sucessfull, delete from the server
      return axiosInstance.axiosInstance.delete(`${inspectionsEndpoint}/${inspectionId}`);
    }

    return true;
  },

  /**
   * get all inspections of a mission for offline use
   * @param {String} siteId - id of the parent site
   */
  async fetchOffline(missionId) {
    const siteInspectionsResponse = await axiosInstance.axiosInstance.get(`${inspectionsEndpoint}?site.mission=${missionId}`);
    const batimentInspectionsResponse = await axiosInstance.axiosInstance.get(`${inspectionsEndpoint}?batiment.site.mission=${missionId}`);

    const response = [...siteInspectionsResponse.data, ...batimentInspectionsResponse.data];
    const inspections = response.map(inspection => this.convertBackendObject(inspection));

    return pouchUtil.addDocs(inspections, this.db);
  },

  // TODO fetch offline Batiment ?

  /**
   * Fetch all equipementCategories stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocsToSync(this.db);
  },
};
