<template>
  <BaseCard :loading="loading">
    <template v-slot:title>
      Informations générales du bâtiment
    </template>
    <template v-slot:content>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-subtitle class="flex">
            <div class="flex-item">
              <div class="icon-info">
                <v-icon>mdi-information</v-icon>
              </div>
              <div>
                <div v-if="featuredItem.typeUsage">{{typeUsage}}</div>
                <div v-if="featuredItem.idClient">Identifiant: {{featuredItem.idClient}}</div>
                <div v-if="featuredItem.nbNiveau">{{featuredItem.nbNiveau}} niveaux</div>
                <div v-if="featuredItem.dateReleve">
                  {{getDisplayDate(featuredItem.dateReleve)}}
                </div>
                <div v-if="featuredItem.surfaceDeclaree">
                  {{featuredItem.surfaceDeclaree}} m2 {{featuredItem.typeSurface}}
                </div>
                <div v-if="featuredItem.surface2">
                  {{featuredItem.surface2}} m2 {{featuredItem.typeSurface2}}
                </div>
              </div>
            </div>
            <div class="flex-item">
              <div class="icon-adress">
                <v-icon>mdi-map-marker</v-icon>
              </div>
              <div>
                <div v-if="featuredItem.adresse">{{featuredItem.adresse}}</div>
              </div>
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
  </BaseCard>

</template>

<script>
import BaseCard from '../BaseCard.vue';


export default {
  components: { BaseCard },

  props: ['featuredItem', 'loading', 'typeUsage'],

  methods: {
    getDisplayDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day.substr(0, 2)}/${month.padStart(2, '0')}/${year.padStart(2, '0')}`;
    },
  },

};
</script>

<style lang="scss" scoped>

  .flex{
    display: flex;
    flex-flow: row wrap;
    padding: 0.5rem;
    justify-content: space-around;
    font-size: 18px;
  }

  .flex-item{
    display: flex;
    flex-flow: row nowrap;
    padding: 1rem;
    justify-content: space-around;
  }

  .icon-info{
    margin-right: 0.8rem;
    vertical-align: bottom;
  }

  .icon-adress{
    margin-right: 0.5rem;
  }

</style>
