<template>
  <div class="table-title-block">
    <v-icon
      class="iconExpend"
      color="primary"
      @click="showForm = !showForm"
      large
      >
      {{ showForm ? `mdi-minus-circle-outline`:`mdi-plus-circle-outline` }}
    </v-icon>
    <div class="title"><p>Equipements liés</p></div>
    <div class="table-filter" v-if="showForm">
      <v-progress-linear
        v-if="loading"
        class="form-loader"
        color="primary"
        indeterminate
        rounded
        height="6"
      ></v-progress-linear>

      <v-autocomplete
        class="linkedlist"
        v-model="value"
        :items="linkedEquipements"
        filled
        chips
        @change="$emit('linked-chosen',value)"
        color="blue-grey lighten-2"
        item-text="nom"
        item-value="id"
        multiple
        attach
      >
        <template v-slot:selection="equipments">
          <v-chip
            v-bind="equipments.attrs"
            :input-value="equipments.selected"
            @click="$emit('select-equipment', equipments.item)"
          >
           <template v-if="navigate">
             <a>{{ equipments.item.nom }}</a>
           </template>
            <template v-else>
              {{ equipments.item.nom }}
            </template>
          </v-chip>
        </template>
        <template v-slot:item="equipements">
          <template v-if="checkType(equipements.item)" >
            <v-list-item-content v-text="equipements.item.nom"></v-list-item-content>
          </template>
          <template v-else>
            <v-list-item-content>
              <v-list-item-title v-html="equipements.item.nom"></v-list-item-title>
            </v-list-item-content>
          </template>
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiSelectLinkedEquipement',
  props: {
    equipements: {
      type: Array,
    },
    selectedEquipement: {
      type: Array,
    },
    navigate: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
  },
  computed: {
    getValue() {
      if (this.selectedEquipement.length) {
        return this.selectedEquipement;
      }
      return [];
    },
  },
  data() {
    return {
      linkedEquipements: [],
      value: [],
      showForm: true,
    };
  },
  created() {
    this.value = this.selectedEquipement;
    this.linkedEquipements = this.equipements;
  },
  methods: {
    checkType(item) {
      return typeof item !== 'object';
    },
  },
};
</script>

<style lang="scss" scoped>
  .title {
    font-size: 1.5rem !important;
    font-weight: 300;
    margin-bottom: 1rem;
    color: #2D4388;
    text-align: center;
  }
  .linkedlist{
    width: 35em;
  }
  .iconExpend {
    float: right;
  }
</style>
