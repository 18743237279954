<template>
  <div>
    <v-scroll-x-transition>
      <BatimentHeader
        v-if="selectedBatiment && selectedMission"
        :loading="!(selectedBatiment && selectedMission)"
        class="header-card"
        :featuredItem="selectedBatiment"
        @show-edit-item="showEditSheet = true"
        :alert="selectedBatiment.inAlert.inAlertBatiment"
      >
      </BatimentHeader>
    </v-scroll-x-transition>
    <div class="column-wrapper">
      <div class="cards-column">
        <BatimentGlobalData
        v-if="selectedBatiment && selectedMission"
        :featuredItem="selectedBatiment"
        :loading="!(selectedBatiment && selectedMission)"
        :typeUsage="usageBatiment"
        >
        </BatimentGlobalData>

        <BatimentActions
        v-if="selectedBatiment && selectedMission"
        :featuredItem="selectedBatiment"
        :loading="!(selectedBatiment && selectedMission)"
        >
        </BatimentActions>
      </div>
      <div class="cards-column">
        <CalculateDataCard
          v-if="selectedBatiment && selectedMission"
          :featuredItem="selectedBatiment"
          :loading="!(selectedBatiment && selectedMission)"
        >
        </CalculateDataCard>

        <BatimentEnergieList
          v-if="selectedBatiment && selectedMission"
          :batiment="selectedBatiment"
          :loading="!(selectedBatiment && selectedMission)"
        >
        </BatimentEnergieList>
      </div>
    </div>
    <BottomSheetEditItem
      v-if="showEditSheet"
      :sheet="showEditSheet"
      :item="selectedBatiment"
      typeItem="batiments"
      @close-sheet="showEditSheet = false"
    >
    </BottomSheetEditItem>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import BatimentHeader from '../components/batiments/BatimentHeader.vue';
import BottomSheetEditItem from '../components/BottomSheetEditItem.vue';
import CalculateDataCard from '../components/CalculateDataCard.vue';
import BatimentGlobalData from '../components/batiments/BatimentGlobalData.vue';
import BatimentEnergieList from '../components/batiments/BatimentEnergieList.vue';
import BatimentActions from '../components/batiments/BatimentActions.vue';

export default {
  components: {
    BatimentHeader,
    BottomSheetEditItem,
    CalculateDataCard,
    BatimentGlobalData,
    BatimentEnergieList,
    BatimentActions,
  },
  props: ['batimentId'],

  data() {
    return {
      showEditSheet: false,
      alertCollect: false,
    };
  },
  computed: {
    ...mapState({
      batiments: state => state.batiments.batiments,
    }),
    ...mapGetters({
      getFormTemplate: 'formTemplates/getFormTemplate',
      selectedBatiment: 'batiments/selected',
      selectedMission: 'missions/selected',
      optionsUsages: 'listeOptions/selectedMissionTypeUsage',
    }),
    usageBatiment() {
      const usage = this.optionsUsages.find(c => c.codeOption === this.selectedBatiment.typeUsage);
      return usage ? usage.libelle : '';
    },
  },
  async created() {
    this.$store.dispatch('clearSelectedView');
    this.$store.dispatch('changeLoading', true);
    await this.$store.dispatch('changeSelectedItem', { type: 'batiments', id: this.batimentId });
    this.$store.dispatch('changeLoading', false);
  },

  methods: {
    isAlertCollect(findBlank) {
      this.alertCollect = findBlank;
    },

    checkNecessaryNotBlankBatiment(batiment) {
      // const findblank = batiment.inAlert.inAlertBatiment;
      /* const fieldNecessary = [];
      this.getFormTemplate('batiments', batiment).fieldGroups.forEach(
        fieldgroup => fieldgroup.fields.forEach(
          (field) => {
            if (field.rules && field.rules.includes('necessary')) {
              fieldNecessary.push(field.name);
            }
          },
        ),
      );
      let i = 0;
      let findBlank = false;
      while (i < fieldNecessary.length && !findBlank) {
        if (this.selectedBatiment[fieldNecessary[i]] === null) {
          findBlank = true;
        }
        i += 1;
      } */
      return batiment.inAlert.inAlertBatiment;
    },
  },
};
</script>

<style lang="scss" scoped>
  .column-wrapper {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: stretch;

    padding-top: 1rem;
    padding-bottom: 8rem;

    .cards-column {
      padding-left: 1rem;
      padding-right: 1rem;
      min-width: 750px;

      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      align-items: center;

      > * {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }

  .add-button {
    position: fixed;
    right: 1rem;
    bottom: 5rem;
  }
</style>
