
<template>
    <!-- Button to detect click and have ripple -->
    <v-btn
      class="picture-frame"
      width="100"
      height="100"
      @click.native="takePicture()"
    >
      <!-- image to display inside the button -->
      <v-img
        class="picture-object"
        width="100"
        height="100"
        :src="url"
      >
        <!-- centered loader -->
        <template v-slot:placeholder>
          <div class="wrapper-center">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
        </template>
      </v-img>

      <!-- invisible input to handle file upload -->
      <input
        ref="inputFile"
        type=file
        accept="image/*"
        style="display: none;"
        @change="handleFileChange($event);"
      />
    </v-btn>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import api from '@/api';
import ImageResizeMixin from '../../mixins/ImageResizeMixin';

export default {
  name: 'picture-field',
  mixins: [
    ImageResizeMixin,
  ],
  props: {
    defaultValue: null,
    formField: Object,
  },
  data() {
    return {
      url: '/img/icons/pictoCamera.jpg',
    };
  },
  computed: {
    ...mapGetters(['selectedItem']),
    ...mapState(['selectedItemType']),
  },
  created() {
    this.populateUrl(this.defaultValue);
  },
  watch: {
    defaultValue(newValue) {
      this.populateUrl(newValue);
    },
  },
  methods: {
    takePicture() {
      this.$refs.inputFile.click();
    },

    async handleFileChange(evt) {
      const file = evt.target.files[0];
      const resizedImage = await this.resizeImageMx(file, 1200, 1200);
      const savedImage = await api.pictures
        .create(resizedImage, this.selectedItemType, this.selectedItem.id);
      // if the save failed
      if (savedImage._id) {
        // get the local picture from pouchDB and udpate the url
        const blobFile = await api.pictures.getFile(savedImage._id);
        this.url = URL.createObjectURL(blobFile);
        this.$store.dispatch('updatePicturesToSync');
      } else {
        // else juste take the contentUrl field from the server response
        this.url = savedImage.contentUrl;
      }
    },

    async populateUrl(picture) {
      if (this.selectedItem) {
        const localPictureList = await api.pictures.getAllByItem(this.selectedItem.id);
        if (localPictureList.length) {
          this.url = URL.createObjectURL(localPictureList[0].blob);
        } else if (picture && !picture.startsWith('temp')) {
          this.url = `${picture}/file`;
        } else {
          this.url = '/img/icons/pictoCamera.jpg';
        }
      }
    },
  },
};
</script>

<style scoped>
.picture-frame {
  border-radius: 5px;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  margin-bottom: 2rem;
}

.picture-object {
  border-radius: 5px;
}

.wrapper-center {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
