<template>
  <BaseCard class="toolbar-card">
    <template v-slot:title>
      Actions
    </template>
    <template v-slot:content>
      <div class="toolbar-wrapper">
        <div class="action-button-wrapper">
          <div>
            <v-btn
            outlined fab small color="primary"
            @click.native="goToCollect()">
              <v-icon>mdi-clipboard-text </v-icon>
            </v-btn>
            <v-icon v-if="checkAlertInCollect()" class="field-icon-warning">
              mdi-alert-circle
            </v-icon>
          </div>
          <div class="button-title">Collecter</div>
        </div>
        <div class="action-button-wrapper">
          <div>
            <v-btn
            outlined fab small color="primary"
            @click.native="goToReparitionPoste()">
              <v-icon>mdi-scale-balance </v-icon>
            </v-btn>
            <v-icon v-if="selectedBatiment.inAlert.inAlertRepartition"
            class="field-icon-warning">
              mdi-alert-circle
            </v-icon>
          </div>
          <div class="button-title">Répartir</div>
        </div>
        <div class="action-button-wrapper">
          <v-btn
          outlined fab small color="primary"
          @click.native="goToBatimentAnalyse()">
            <v-icon>mdi-wall </v-icon>
          </v-btn>
          <div class="button-title">Analyser</div>
        </div>
        <div class="action-button-wrapper">
          <div>
            <v-btn
            outlined fab small color="primary"
            @click.native="goToInter()">
              <v-icon>mdi-hammer-wrench </v-icon>
            </v-btn>
            <v-icon v-if="selectedBatiment.inAlert.inAlertIntervention"
              class="field-icon-warning">
              mdi-alert-circle
            </v-icon>
          </div>
          <div class="button-title">Intervenir</div>
        </div>
        <div class="action-button-wrapper">
          <v-btn
          outlined fab small color="primary"
          @click.native="goToScenar()"
          >
            <v-icon>mdi-chart-line</v-icon>
          </v-btn>
          <div class="button-title">Scénariser</div>
        </div>
        <div class="action-button-wrapper">
          <v-btn
          outlined fab small color="primary"
          @click.native="goToReport()"
          >
            <v-icon>mdi-download</v-icon>
          </v-btn>
          <div class="button-title">Restituer</div>
        </div>
      </div>
    </template>
  </BaseCard>
</template>

<script>
import { mapGetters } from 'vuex';
import BaseCard from '../BaseCard.vue';


export default {
  components: { BaseCard },

  computed: {
    ...mapGetters({
      selectedBatiment: 'batiments/selected',
      selectedMission: 'missions/selected',
    }),
  },

  props: ['featuredItem', 'loading'],

  methods: {
    checkAlertInCollect() {
      let findAlert = false;

      if (this.selectedBatiment.inAlert.inAlertCollectEquipement
      || this.selectedBatiment.inAlert.inAlertCollectInspection) {
        findAlert = true;
      }

      return findAlert;
    },

    goToReport() {
      this.$router.push({ name: 'batimentRapport' });
    },

    goToCollect() {
      this.$router.push({ name: 'collect' });
    },

    goToInter() {
      this.$router.push({ name: 'batimentInterventions' });
    },

    goToScenar() {
      this.$router.push({
        name: 'scenarios',
      });
    },

    goToReparitionPoste() {
      this.$router.push({ name: 'batimentPostes' });
    },

    goToBatimentAnalyse() {
      this.$router.push({ name: 'batimentAnalyse' });
    },

  },
};
</script>

<style lang="scss" scoped>
.toolbar-card {
   width: 100%;
 }
 .toolbar-wrapper {
   width: 100%;
   display: flex;
   flex-flow: row wrap;
   justify-content: space-around;
   align-items: center;
 }

 .action-button-wrapper {
   display: flex;
   flex-flow: column nowrap;
   justify-content: center;
   align-items: center;
   text-align: center;
 }

 .button-title {
   margin-top: 0.2rem;
   color: #2D4388;
 }

  .field-icon-warning{
  color:orange;
  bottom:15px;
  width:5%;
  // z-index: 10;
  position: relative;
}
</style>
