<template>
  <v-bottom-sheet
    v-model="sheet"
    fullscreen
    persistent
  >
    <div class="observer-wrapper">
      <v-sheet class="sheet-wrapper">
          <h2 class="fixed-title">Edition de la trame de rapport</h2>
            <div class="content">
              <div>
              <div class="research">
                <v-text-field
                  v-model="search"
                  class="research-field"
                  prepend-inner-icon="mdi-magnify"
                  label="Rechercher un bloc"
                  flat
                  dense
                  hide-details
                  clearable
                  outlined
                  clear-icon="mdi-close-circle-outline"
                ></v-text-field>
              </div>
              <div class="catalogue">
                <v-expansion-panels
                  class="catalogue-bloc"
                  multiple
                >
                  <template
                    v-for="blockCatalogue in filteredCatalogue"
                  >
                    <v-expansion-panel :key="blockCatalogue.id" class="margin-block-catalogue">
                      <v-expansion-panel-header class="panel-header">
                        <span class="fields-group-title">
                          <p class="catalogue-block-title">{{ blockCatalogue.nom }}</p>
                          <span class="catalogue-block-code">
                            code: {{ blockCatalogue.codeBloc }}
                          </span>
                        </span>
                          <template v-slot:actions>
                          <v-icon color="white">
                            $expand
                          </v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                          <div class="first-line-bloc">
                            <img :src="blockCatalogue.image" class="image-cache"
                            @click="toggleFullscreen(blockCatalogue.image)">
                            <div v-if="selectedImage" class="overlay">
                            <v-img
                              class="mt-12"
                              :src="selectedImage"
                              alt=""
                              height="95vh"
                              contain
                              dark
                              @click.stop="selectedImage = null"
                          >
                            </v-img>
                          </div>
                            <div class="bloc-parameters">
                              <h3 class="parameter-title">Les paramètres</h3>
                              <div  class="parameters">
                              <template
                                v-for="parameter in
                                getBlockParameters(blockCatalogue.parametreBlocs)"
                              >
                              <div :key="parameter.id">
                                  <p><b>{{ parameter.nom }}</b> : {{ parameter.description }}</p>
                              </div>
                              </template>
                              </div>
                            </div>
                          </div>
                          <div class="second-line-bloc">
                            <div class="description"> {{ blockCatalogue.description }}
                            </div>
                            <v-btn color="success" class="add-button"
                            @click="AddBlock(blockCatalogue)">
                              <v-icon left>
                                mdi-plus-circle-outline
                              </v-icon>
                              Ajouter le bloc
                              <v-icon right>
                                mdi-chevron-double-right
                              </v-icon>
                            </v-btn>
                          </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </template>
                </v-expansion-panels>
              </div>
              </div>
              <v-divider
                class="divider"
                vertical
              ></v-divider>
              <div>
                <h3 class="trame-title">Trame du rapport {{ draggingInfo }}</h3>
                <div class="trame">
                <v-expansion-panels
                  v-if="struct"
                  class="trame-bloc"
                  multiple
                >
                  <draggable
                    :list="list"
                    :disabled="!enabled"
                    class="list-group"
                    ghost-class="ghost"
                    @start="dragging = true"
                    @end="dragging = false"
                  >
                  <template
                    v-for="block in list"
                  >
                  <div :key="block.id"
                  class="margin-block-trame">
                    <v-expansion-panel
                      :class="getClassLevelTitle(block.defaultValues.levelTitle)"
                    >
                      <v-expansion-panel-header class="panel-header-trame">
                        <span class="fields-group-title">
                          <span class="catalogue-block-title" v-if="block.defaultValues.title">
                            {{ getVisualisationLevelTitle(block.defaultValues.levelTitle) }}
                            {{ block.defaultValues.title }}
                          </span>
                          <span class="catalogue-block-title"
                          v-else-if="block.defaultValues.hideTitle">
                            -
                          </span>
                          <span class="catalogue-block-title"
                          v-else-if="getDefaultTitle(block.blockType) !== null">
                            {{ getVisualisationLevelTitle(block.defaultValues.levelTitle) }}
                            {{ getDefaultTitle(block.blockType) }}</span>
                          <span class="catalogue-block-title"
                          v-else>
                            {{ getVisualisationLevelTitle(block.defaultValues.levelTitle) }}
                            <i>&laquo; Veuillez ajouter un titre dans les paramètres &raquo; </i>
                            </span>
                          <p class="catalogue-block-code">
                          <v-icon color="red" @click="deleteBlock(block.id)">
                            mdi-delete
                          </v-icon> {{ block.blockType }} </p>
                        </span>
                          <template v-slot:actions>
                          <v-icon color="white">
                            $expand
                          </v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                          <div class="first-line-bloc-trame">
                            <div>
                              <div>
                                <template
                                  v-for="param in getBlockCatalogueParameters(block.blockType)"
                                  >
                                <div :key="param.id">
                                <BaseField
                                   v-if="param.nom !== 'blocks'"
                                  :field="getParameterForBaseField(param.nom)"
                                  :defaultValue="getDefaultValueForBaseField(block, param)"
                                  @fieldModified="handleChangeParameterValue($event,
                                  block.id, param.nom)">
                                </BaseField>
                                </div>
                                </template>
                              </div>
                            </div>
                          </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </div>
                </template>
              </draggable>
              </v-expansion-panels>

                </div>
            </div>
            </div>
            <!-- close and save buttons -->
            <div class="bottom-sheet-footer">
              <v-btn
                class="action-button"
                text
                color="primary"
                @click="confirmationCancel()"
              >
                Annuler
              </v-btn>

              <v-btn
                class="action-button"
                depressed
                large
                color="primary"
                @click.native="confirmationSave()"
              >
                Enregistrer
              </v-btn>
            </div>
      </v-sheet>
    </div>
    <v-dialog
      v-model="showDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="headline">Attention</v-card-title>

        <v-card-text>
          Etes-vous sur de vouloir enregistrer?
          En validant, cela écrasera définitivement la trame d'origine.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            @click="closeDialog()"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="saveForm()"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showCancelDialog"
      width="500"
    >
      <v-card>
        <v-card-title class="headline">Attention</v-card-title>

        <v-card-text>
          Etes-vous sur de vouloir annuler?
          En validant, toutes les modifications effectuées seront perdues.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            depressed
            @click="closeDeleteDialog()"
          >
            Annuler
          </v-btn>
          <v-btn
            color="primary"
            depressed
            @click="closeSheet()"
          >
            Valider
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-bottom-sheet>
</template>

<script>
import draggable from 'vuedraggable';
import BaseField from '../fields/base-field.vue';
import axiosInstance from '../../plugins/axios';


// const id = 1;
export default {
  name: 'simple',
  display: 'Simple',
  order: 0,
  components: {
    draggable,
    BaseField,
  },
  data() {
    return {
      enabled: true,
      dragging: false,
      catalogue: [],
      parameters: [],
      search: null,
      list: this.struct,
      showDialog: false,
      showCancelDialog: false,
      selectedImage: null,
    };
  },
  watch: {
    struct(newValue) {
      this.list = newValue;
    },
  },
  computed: {
    draggingInfo() {
      return this.dragging ? 'under drag' : '';
    },
    /* orignalList() {
      return Object.values(this.struct.structureValue);
    }, */
    filteredCatalogue() {
      if (this.search && this.search.length > 2) {
        return this.catalogue.filter(
          bloc => (bloc.typeParent === this.typeRapport || bloc.typeParent === 'All')
          && (bloc.nom.toLowerCase().includes(this.search.toLowerCase())
            || bloc.codeBloc.toLowerCase().includes(this.search.toLowerCase())
          ),
        );
      }
      return this.catalogue.filter(bloc => (bloc.typeParent === this.typeRapport || bloc.typeParent === 'All'));
    },
  },
  props: {
    sheet: {
      type: Boolean,
    },
    struct: {
      type: Array,
    },
    typeRapport: {
      type: String,
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    toggleFullscreen(elem) {
      this.selectedImage = elem;
    },
    confirmationSave() {
      this.showDialog = true;
    },
    confirmationCancel() {
      this.showCancelDialog = true;
    },
    closeDialog() {
      this.showDialog = false;
    },
    closeDeleteDialog() {
      this.showCancelDialog = false;
    },
    deleteBlock(id) {
      this.list.forEach((block, i) => {
        if (block.id === id) {
          this.list.splice(i, 1);
        }
      });
    },
    AddBlock(block) {
      this.list.push({
        blockType: block.codeBloc,
        id: this.createGuid(),
        defaultValues: {},
      });
    },
    getDefaultTitle(blocName) {
      const blocCatalogue = this.catalogue.filter(block => block.codeBloc === blocName);
      if (blocCatalogue.length > 0) {
        return blocCatalogue[0].titreParDefaut;
      }
      return null;
    },
    handleChangeParameterValue(newValue, id, param) {
      this.list.forEach((block, i) => {
        if (block.id === id) {
          this.list[i].defaultValues = {
            ...this.list[i].defaultValues,
            [param]: newValue,
          };
        }
      });
    },
    getBlockCatalogueParameters(blocName) {
      const blocCatalogue = this.catalogue.filter(block => block.codeBloc === blocName);
      if (blocCatalogue.length > 0) {
        return this.getBlockParameters(blocCatalogue[0].parametreBlocs);
      }
      return null;
    },
    getDefaultValueForBaseField(block, param) {
      if (param.nom === 'annees' && typeof block.defaultValues[param.nom] === 'string') {
        const defaultValue = block.defaultValues[param.nom].split(',');
        return defaultValue;
      }
      return block.defaultValues[param.nom];
    },
    getParameterForBaseField(code) {
      const parametre = this.parameters.filter(param => param.nom === code);
      let parameterDetails = {};
      if (parametre.length > 0) {
        if (parametre[0].nom === 'equipementCategorieCode') {
          parameterDetails = {
            ...parametre[0],
            label: parametre[0].nom,
            hint: parametre[0].description,
            isParameter: true,
            options: 'equipementCategorieCode',
          };
        } else if (parametre[0].nom === 'inspectionTypeCode' && this.typeRapport === 'Site') {
          parameterDetails = {
            ...parametre[0],
            label: parametre[0].nom,
            hint: parametre[0].description,
            isParameter: true,
            options: 'inspectionTypeCode-site',
          };
        } else if (parametre[0].nom === 'inspectionTypeCode' && this.typeRapport === 'Batiment') {
          parameterDetails = {
            ...parametre[0],
            label: parametre[0].nom,
            hint: parametre[0].description,
            isParameter: true,
            options: 'inspectionTypeCode-batiment',
          };
        } else if (parametre[0].nom === 'attribut1' && this.typeRapport === 'Batiment') {
          parameterDetails = {
            ...parametre[0],
            label: parametre[0].nom,
            hint: parametre[0].description,
            isParameter: true,
            options: 'attribut1',
          };
        } else if (parametre[0].nom === 'attribut2' && this.typeRapport === 'Batiment') {
          parameterDetails = {
            ...parametre[0],
            label: parametre[0].nom,
            hint: parametre[0].description,
            isParameter: true,
            options: 'attribut2',
          };
        } else if (parametre[0].nom === 'idLinkedStructBat') {
          parameterDetails = {
            ...parametre[0],
            label: parametre[0].nom,
            hint: parametre[0].description,
            isParameter: true,
            options: 'idLinkedStructBat',
          };
        } else if (parametre[0].nom === 'blocks') {
          // ne pas afficher
          parameterDetails = null;
        } else if (parametre[0].nom === 'annees') {
          parameterDetails = {
            ...parametre[0],
            label: parametre[0].nom,
            hint: parametre[0].description,
            isParameter: true,
            options: 'annees',
          };
        } else {
          parameterDetails = {
            ...parametre[0],
            label: parametre[0].nom,
            hint: parametre[0].description,
            isParameter: true,
          };
        }
        return parameterDetails;
      }
      return null;
    },
    getBlockParameters(BlocParametres) {
      const idParametres = BlocParametres.map(param => parseInt(param.replace('/api/parametre-blocs/', ''), 10));
      return this.parameters.filter(param => idParametres.includes(param.id));
    },
    // retrieve the object blocCatalogue from the server
    fetchData() {
      this.loading = true;
      axiosInstance.axiosInstance.get('/api/bloc-catalogues').then((response) => {
        this.fetchError = false;
        // if the response is an empty array, then the contenu has never been saved
        if (response.data.length) {
          this.catalogue = response.data;
        }
      })
        .catch(() => { this.fetchError = true; })
        .finally(() => { this.loading = false; });
      axiosInstance.axiosInstance.get('/api/parametre-blocs').then((response) => {
        this.fetchError = false;
        // if the response is an empty array, then the contenu has never been saved
        if (response.data.length) {
          this.parameters = response.data;
        }
      })
        .catch(() => { this.fetchError = true; })
        .finally(() => { this.loading = false; });
    },
    getClassLevelTitle(level) {
      switch (level) {
        case 2:
          return 'level-2';
        case 3:
          return 'level-3';
        case 4:
          return 'level-4';
        default:
          return null;
      }
    },
    getVisualisationLevelTitle(level) {
      switch (level) {
        case 2:
          return '1.1';
        case 3:
          return '1.1.1';
        case 4:
          return '    ';
        default:
          return '1';
      }
    },
    closeSheet() {
      this.$emit('close-sheet');
    },
    saveForm() {
      const newTrame = {};
      this.list.forEach((block) => {
        newTrame[block.id] = block;
      });
      const json = JSON.stringify(newTrame);
      const blob = new Blob([json], { type: 'application/JSON' });
      this.$emit('handleTrameEdit', blob);
      this.showDialog = false;
    },

    createGuid() {
      function _p8(s) {
        const p = (`${Math.random().toString(16)}000000000`).substring(2, 10);
        return s ? `-${p.substring(0, 4)}-${p.substring(4, 8)}` : p;
      }
      return _p8() + _p8(true) + _p8(true) + _p8();
    },
  },
};
</script>

<style lang="scss" scoped>
  .sheet-wrapper {
    overflow: hidden !important;
    padding: 100px 3rem 100px 0;
    overflow: auto;
    min-height: 100vh; /* Set a minimum height of the viewport height */
    width:100%;
    overscroll-behavior: none;
    background-color: #f9f9f9;
  }
  .fixed-title{
    position: fixed;
    height: 64px;
    top: 0;
    margin-top: 0;
    width:100%;
    background-color: #2D4388;
    z-index: 10;
    color:white !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 1.2rem;
  }
  .observer-wrapper {
    height: 100%;
    //width:100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
  }
  .bottom-sheet-footer {
    width: 100%;
    height: 64px;
    bottom: 0;
    margin-bottom: 0;
    position: fixed;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    background-color: white;
    margin-right: 1.2rem;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
  }
  .action-button {
    margin-right: 1rem;
  }
  .content{
    width:100%;
    height:100%;
    display: flex;
    flex-flow: row nowrap;
    > * {
      width: 50%;
    }
 }
 .first-line-bloc {
  display: flex;
  flex-flow: row nowrap;
  height: 12rem;
    > * {
      margin: 1rem;
    }
 }
.first-line-bloc-trame {
  display: flex;
  flex-flow: column nowrap;
    > * {
      margin: 1rem;
    }
 }
 .second-line-bloc{
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    > * {
      margin: 1rem;
    }
 }
 .image-cache {
  width: 40%;
  height: auto;
  object-fit: scale-down;
  cursor: zoom-in;
 }
 .overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
  /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}
  /* .image-cache:hover{
-ms-transform: scale(1.5);
-webkit-transform: scale(1.5);
transform: scale(1.5);
} */
 .parameters{
  overflow-y: scroll;
  height: 8rem;
  width: 100%;
  padding: 4px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
 }

 .bloc-parameters {
    height: 12rem;
    width: 100%;
 }

 .parameter-title {
  background-color: #2D4388;
  color:white;
  text-align: center;
 }
 .add-button {
  height: 3rem !important;
  margin: 1rem;
 }
 .fields-group-title {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;

}
.catalogue-block-title{
  font-size: 1.5rem;
  color: white;
}
.catalogue-block-code{
  color: white;
  font-size: 1rem;
}
.fields-group-title-trame{
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  // font-size: 1.5rem;

  color: white;
}
.panel-header {
  background-color: $app-blue;
}
.panel-header-trame {
  cursor: move;
  background-color: $app-blue;
}
.trame-bloc {
  display: flex;
  flex-flow: column nowrap;
  margin: auto;
  z-index: 0 !important;
}
.margin-block-trame {
  margin: .2rem;
}
.trame {
  height: 95%;
  overflow-y: scroll;
  margin: 2rem;
}
.level-2 {
  margin-left: 50px;
}
.level-3{
  margin-left: 100px;
}
.level-4{
  margin-left: 150px;
}
.catalogue {
  overflow-y: scroll;
  height: 95%;
  margin: 2rem;
}
.margin-block-catalogue{
  margin: .1rem;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.trame-title {
  margin-left: 2em;
}
</style>
