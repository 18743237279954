<template>
  <div class="table-wrapper">
    <v-data-table
      class="scenarios-table"
      v-model="scenariosSelected"
      :items="mappedBatimentScenarios"
      :headers="headers"
      :loading="loading"
      single-select
      show-select
      disable-pagination
      hide-default-footer
      @item-selected="touched = true"
    >
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    batiment: {
      type: Object,
      default: null,
    },
    initialSelection: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      filter: 'tous',
      headers: [
        { text: 'nom', value: 'nom' },
        { text: 'investissemnent (EUR HT)', value: 'invest' },
        { text: 'gain EF (%)', value: 'gainEf' },
        { text: 'TRI (brut)', value: 'tri' },
      ],
      scenariosSelected: [],
      touched: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      scenarios: 'scenarios/selectedSiteScenarios',
    }),

    batimentScenarios() {
      return this.scenarios.filter(scenar => scenar.batiment === this.batiment.id);
    },

    mappedBatimentScenarios() {
      return this.batimentScenarios.map(scenar => this.mapScenario(scenar));
    },

  },

  async created() {
    this.loading = true;
    await this.$store.dispatch('scenarios/fetchAllByBatiment', this.batiment.id);
    this.loading = false;

    // init selection
    if (this.initialSelection) {
      this.scenariosSelected = [this.mapScenario(this.initialSelection)];
    }
  },

  watch: {
    scenariosSelected() {
      const newSelectedId = this.scenariosSelected.length ? this.scenariosSelected[0].id : null;

      this.$emit('new-selection', {
        scenario: this.batimentScenarios.find(bat => bat.id === newSelectedId),
        touched: this.touched,
      });
    },
  },

  methods: {
    mapScenario(scenario) {
      return {
        id: scenario.id,
        nom: scenario.nom,
        batiment: scenario.batiment,
        invest: scenario.calculations.investissementHt,
        gainEf: scenario.calculations.gainConsommationEfRelatif,
        tri: scenario.calculations.tempsRetourBrut,
      };
    },
  },
};
</script>
