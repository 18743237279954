<template>
  <div class="settings-view">
      <v-list
        subheader
        three-line
      >
        <v-subheader>Général</v-subheader>
        <v-list-item ripple
          @click="goToImageBank()"
          class="pointer">
          <v-list-item-content>
            <v-list-item-title>Banque d'image</v-list-item-title>
            <v-list-item-subtitle>
              Banque d'image disponible dans les rapports des missions.
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item ripple
          @click="goToReferentiels()"
          class="pointer">
          <v-list-item-content>
            <v-list-item-title>Référentiel</v-list-item-title>
            <v-list-item-subtitle>
              Gérer les référentiels
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>

      <v-list
        flat
        subheader
        three-line
      >
        <v-subheader>Autre</v-subheader>

        <v-list-item-group
          multiple
          active-class=""
        >
          <!--<v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Notifications</v-list-item-title>
                <v-list-item-subtitle>
                  Notify me about updates to apps or games that I downloaded
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>

          <v-list-item>
            <template v-slot:default="{ active }">
              <v-list-item-action>
                <v-checkbox :input-value="active"></v-checkbox>
              </v-list-item-action>

              <v-list-item-content>
                <v-list-item-title>Auto-add widgets</v-list-item-title>
                <v-list-item-subtitle>
                  Automatically add home screen widgets when downloads complete
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>-->
        </v-list-item-group>
      </v-list>
  </div>
</template>

<script>

export default {
  methods: {
    goToImageBank() {
      this.$router.push({
        name: 'imageBank',
      });
    },
    goToReferentiels() {
      this.$router.push({
        name: 'referentialView',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .pointer {
    cursor: pointer;
  }
</style>
