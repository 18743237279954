<template>
  <div class="niveaux-list-wrapper">
    <div
      class="empty-list-message"
      v-if="!loading && !batimentNiveaux.length"
    >
      Il n'y a pas encore de niveau sur ce bâtiment.
    </div>
    <ListItemWithButtons
      v-for="niveau in batimentNiveaux"
      noDuplicate
      noInter
      :key="niveau.id"
      :selected="niveau.id === selectedNiveauId"
      @click.native="selectNiveau(niveau.id)"
      @click-edit="$emit('edit-niveau', niveau.id)"
      @click-delete="$emit('delete-niveau', niveau.id)"
    >
      <template #title>
        <span class="niveau-item">{{ niveau.nom }}</span>
      </template>

      <template #propertyA v-if="niveau.calculations">
        {{ niveau.calculations.surfaceChauffee }} m2
      </template>
    </ListItemWithButtons>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ListItemWithButtons from './ListItemWithButtons.vue';

export default {
  components: {
    ListItemWithButtons,
  },
  computed: {
    ...mapState({
      loading: 'loading',
    }),
    ...mapGetters({
      selectedBatiment: 'batiments/selected',
      batimentNiveaux: 'niveaux/selectedBatimentNiveaux',
      selectedNiveau: 'niveaux/selected',
    }),

    selectedNiveauId() {
      if (this.selectedNiveau) {
        return this.selectedNiveau.id;
      }
      return null;
    },
  },

  async created() {
    this.$store.dispatch('changeLoading', true);
    await this.$store.dispatch('niveaux/fetchAllByBatiment', this.selectedBatiment.id);
    this.$store.dispatch('changeLoading', false);
  },

  methods: {
    selectNiveau(niveauId) {
      this.$store.dispatch('changeSelectedItem', { type: 'niveaux', id: niveauId });
    },
  },
};
</script>

<style lang="scss" scoped>
  .niveaux-list-wrapper {
    height: 100%;
    width: 100%;
    padding: 1rem;

    background-color: $app-grey;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    align-items: stretch;
  }

  .niveau-item {
    margin-left: 2rem;
  }

  .niveaux-list-wrapper > div:last-child {
    margin-bottom: 4rem;
  }

  .empty-list-message {
    margin: 4rem;
    padding: 2rem;
    font-weight: 300;
    font-size: 1.6rem;
    text-align: center;

    background-color: $app-white;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 15px;
  }
</style>
