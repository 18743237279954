<template>
  <v-list v-if="patrimoines.length">
    <template
      v-for="(patrimoine, i) in patrimoines"
    >
      <v-list-item
        ripple
        :key="`patrimoine_${i}`"
        @click.native="$emit('patrimoine-clicked', patrimoine)"
      >
        <v-list-item-content>
          <v-list-item-title class="pointer">{{ patrimoine.nom }}</v-list-item-title>
        </v-list-item-content>

        <v-list-item-action>
          <v-list-item-action-text>
            {{ getPatrimoineSites(patrimoine) }} sites
          </v-list-item-action-text>
        </v-list-item-action>
      </v-list-item>

      <v-divider
        v-if="i + 1 < patrimoines.length"
        :key="`dvd_${i}`"
        class="patrimoine-list-divider"
      ></v-divider>
    </template>
  </v-list>
</template>

<script>
export default {
  props: ['patrimoines'],

  methods: {
    getPatrimoineSites(patrimoine) {
      return patrimoine.sites ? patrimoine.sites.length : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
  .patrimoine-list-divider {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  .pointer {
    cursor: pointer;
  }
</style>
