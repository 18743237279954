<template>
  <v-snackbar
    v-model="show"
    :color="color"
    :top=true
  >
    {{ message }}
    <v-btn
      text
      @click="close()"
    >
      Fermer
    </v-btn>
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      message: '',
      color: '',
    };
  },
  created() {
    // watch the snackbar message on state
    // and display the snackbar when a message is send
    this.$store.watch(state => state.snackbar.message, () => {
      const msg = this.$store.state.snackbar.message;
      if (msg !== '') {
        this.setColor(this.$store.state.snackbar.type);
        this.message = this.$store.state.snackbar.message;
        this.show = true;
      }
    });
  },
  methods: {
    close() {
      this.show = false;
    },

    setColor(msgType) {
      switch (msgType) {
        case 'error':
          this.color = '#e53935';
          break;
        case 'warn':
          this.color = '#ffa000';
          break;
        case 'succes':
          this.color = '#4caf50';
          break;
        default:
          this.color = '#f5f5f5';
      }
    },
  },
  watch: {
    // when the snackbar close, update the state message
    show(newValue, oldValue) {
      if (oldValue === true && newValue === false) {
        this.$store.dispatch('snackbar/displaySnack', { message: '', type: '' });
      }
    },
  },
};
</script>
