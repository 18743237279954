<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="240"
  >
    <!-- <template v-slot:activator="{ on }">
      <v-icon v-on="on">mdi-delete</v-icon>
    </template> -->
    <v-card class="cards">
      <v-card-text class="card-text">
        <v-btn
          x-large
          color="gray"
          dark
          :loading="loading"
          @click="save(codeValues[4].codeOption)"
        >
          {{values[4] ? values[4].libelle : ''}}
        </v-btn>
      <v-btn
        x-large
        color="red"
        dark
        :loading="loading"
        @click="save(codeValues[0].codeOption)"
      >
        {{values[0] ? values[0].libelle : ''}}
      </v-btn>
      <v-btn
        x-large
        color="orange"
        dark
        :loading="loading"
        @click="save(codeValues[1].codeOption)"
      >
        {{values[1] ? values[1].libelle : ''}}
      </v-btn>
                    <v-btn
        x-large
        color="yellow"
        dark
        :loading="loading"
        @click="save(codeValues[2].codeOption)"
      >
        {{values[2] ? values[2].libelle : ''}}
      </v-btn>
      <v-btn
        x-large
        color="success"
        dark
        :loading="loading"
        @click="save(codeValues[3].codeOption)"
      >
        {{values[3] ? values[3].libelle : ''}}
      </v-btn>
      </v-card-text>

      <v-card-actions class="actions">
        <v-btn
          text
          @click="close()"
        >
          Annuler
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';

export default {
  mixins: [
    UpdateItemAlertMixin,
  ],
  data() {
    return {
      offline: false,
      values: Array,
      codeValues: Array,
      loading: false,
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    item: {
      type: String,
      default: null,
    },
    selectedEquipement: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapGetters({
      selectedMission: 'missions/selected',
      selectedBatiment: 'batiments/selected',
      getEquipementFormTemplate: 'formTemplates/getEquipementFormTemplate',
    }),
  },

  watch: {
    dialog() {
      this.values = [];
      this.codeValues = [];
      if (this.item === 'vetuste') {
        this.loading = true;
        this.getEquipementFormTemplate(this.selectedEquipement).fieldGroups.forEach(
          fieldgroup => fieldgroup.fields.forEach(
            (field) => {
              if (field.name === 'vetuste') {
                this.values = field.options;
                this.codeValues = field.options;
                this.loading = false;
              }
            },
          ),
        );
      } else if (this.item === 'performance') {
        this.loading = true;
        this.getEquipementFormTemplate(this.selectedEquipement).fieldGroups.forEach(
          fieldgroup => fieldgroup.fields.forEach(
            (field) => {
              if (field.name === 'performance') {
                this.values = field.options;
                this.codeValues = field.options;
                this.loading = false;
              }
            },
          ),
        );
      }
    },

  },

  methods: {
    close() {
      this.values = [];
      this.codeValues = [];
      this.$emit('closeDialogPerfVet');
    },

    save(code) {
      this.$store.dispatch('changeLoadingPerfVet', true);
      let payload = { };
      if (this.item === 'performance') {
        payload = {
          performance: code,
        };
      } else if (this.item === 'vetuste') {
        payload = {
          vetuste: code,
        };
      }
      this.close();
      if (code !== null) {
        let findAlert = false;
        if (this.selectedEquipement.inAlert) {
          const fieldNecessary = [];
          this.getEquipementFormTemplate(this.selectedEquipement, this.selectedMission.id)
            .fieldGroups.forEach(
              fieldgroup => fieldgroup.fields.forEach(
                (field) => {
                  if (field.rules && field.rules.includes('necessary')) {
                    fieldNecessary.push(field.name);
                  }
                },
              ),
            );

          let i = 0;
          while (i < fieldNecessary.length && !findAlert) {
            if (fieldNecessary[i] !== 'performance'
              && fieldNecessary[i] !== 'vetuste'
              && this.selectedEquipement.attributs
              && this.selectedEquipement.attributs[fieldNecessary[i]] === null) {
              findAlert = true;
            } else if (!this.selectedEquipement.performance && this.item !== 'performance') {
              findAlert = true;
            } else if (!this.selectedEquipement.vetuste && this.item !== 'vetuste') {
              findAlert = true;
            }
            i += 1;
          }
        }
        payload = {
          ...payload,
          inAlert: findAlert,
        };
        this.$store.dispatch(
          'equipements/save',
          {
            id: this.selectedEquipement.id,
            data: {
              ...this.selectedEquipement,
              ...payload,
            },
          },
        ).then(() => {
          this.$store.dispatch('snackbar/displaySnack',
            { message: 'Sauvegardé avec succès !', type: 'succes' });
          this.$store.dispatch('changeLoadingPerfVet', false);
          this.majBatimentAlert(this.selectedEquipement.batiment, 'equipements');
          // this.$store.dispatch('equipements/fetchAllByBatiment', this.selectedBatiment.id);
        }).catch(() => {
          this.$store.dispatch('changeLoadingPerfVet', false);
          this.$store.dispatch('snackbar/displaySnack',
            {
              message: 'Sauvegarde impossible',
              type: 'error',
            });
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.actions{
  justify-content: center;
}
.card-text{
  display: flex;
  flex-flow: column;
  justify-content: center;
  > * {
    width: 200px;
    margin-top:1em;
  }
}
</style>
