<template>
  <div class="collec-view-wrapper">
    <div class="collect-view-tab-wrapper">

      <div class="collec-tab-container">
        <v-tabs-items v-model="currentTab" class="collect-tab-content">
          <v-tab-item>
            <InspectionsList
              v-if="currentTab === INSPECTIONS_TAB"
              typeItem="sites"
              @edit-inspection="handleEdit('inspections', $event)"
              @show-fabInspection="handleShowFab($event)"
              @delete-inspection="handleDelete('inspections', $event)"
            ></InspectionsList>
          </v-tab-item>
        </v-tabs-items>
        <BaseFab
          v-if="currentTab === INSPECTIONS_TAB && showFabInspection"
          class="fab-add-button"
          :options="inspestionFabOptions"
          :key="currentTab"
          @option-selected="handlerFabButton"
        >
        </BaseFab>
      </div>
      <v-tabs
        v-model="currentTab"
        grow
        class="collect-tab-bar"
      >
        <v-tab>
          inspections <v-icon right>mdi-notebook-outline</v-icon>
        </v-tab>
      </v-tabs>
    </div>
    <BottomSheetEditItem
      v-if="showEditSheet"
      :sheet="showEditSheet"
      :item="selectedItem"
      :typeItem="selectedItemType"
      @close-sheet="showEditSheet = false"
    >
    </BottomSheetEditItem>
    <ImportInspectionModal
      :show="showImportInspectionSheet"
      :inspectionType="inspectionType"
      @close="handleCloseImportInspectionModal($event)"
    >
    </ImportInspectionModal>
    <BottomSheetAddInspection
      :typeItem="'site'"
      :sheet="showInspectionSheet"
      v-if="showInspectionSheet"
      @close-sheet="showInspectionSheet = false"
      @open-edit-modal="handleEdit('inspections', $event)"
    >
    </BottomSheetAddInspection>
    <DialogDeleteConfirmation
      v-if="showDeleteDialog"
      :dialog="showDeleteDialog"
      :item="selectedItem"
      @delete-item="deleteItem()"
      @close-dialog="showDeleteDialog = false"
    >
    </DialogDeleteConfirmation>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';
import InspectionsList from '../components/InspectionsList.vue';
import BottomSheetEditItem from '../components/BottomSheetEditItem.vue';
import ImportInspectionModal from '../components/inspections/ImportInspectionModal.vue';
import BaseFab from '../components/BaseFab.vue';
import BottomSheetAddInspection from '../components/inspections/BottomSheetAddInspection.vue';
import DialogDeleteConfirmation from '../components/DialogDeleteConfirmation.vue';


export default {
  components: {
    InspectionsList,
    BottomSheetEditItem,
    ImportInspectionModal,
    BaseFab,
    BottomSheetAddInspection,
    DialogDeleteConfirmation,
  },
  mixins: [
    UpdateItemAlertMixin,
  ],
  props: ['siteId'],
  data() {
    return {
      showDeleteDialog: false,
      showInspectionSheet: false,
      currentTab: 0,
      INSPECTIONS_TAB: 0,
      showEditSheet: false,
      showImportInspectionSheet: false,
      showFabInspection: false,
      inspectionType: 'sites',
      inspestionFabOptions: [
        {
          icon: 'mdi-arrow-down-bold',
          name: 'importInspections',
          tooltip: 'Importer des inspections',
          color: 'yellow',
        },
        {
          icon: 'mdi-toolbox',
          name: 'createInspection',
          tooltip: 'Ajouter une Inspection',
          color: 'green',
          disable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      selectedItemType: state => state.selectedItemType,
    }),
    ...mapGetters({
      selectedItem: 'selectedItem',
      selectedSite: 'sites/selected',
    }),
    selectedView: {
      get() {
        return this.$store.state.selectedView;
      },
      set(value) {
        this.$store.commit('setSelectedView', value);
      },
    },

    selectedItemId() {
      if (this.selectedItem) {
        return this.selectedItem.id;
      }
      return '';
    },
  },
  async created() {
    this.selectedView = 'Collecter';
    this.$store.dispatch('changeLoading', true);
    await this.$store.dispatch('changeSelectedItem', { type: 'sites', id: this.siteId });
    this.$store.dispatch('changeLoading', false);
  },
  methods: {
    async deleteItem() {
      await this.$store.dispatch('inspections/delete', this.selectedItem);
      await this.majSiteAlert(this.siteId, 'inspections');
    },
    handleDelete(itemType, itemId) {
      if (
        itemType === this.selectedItemType
        && itemId === this.selectedItemId
      ) {
        this.showDeleteDialog = true;
      }
    },
    handleEdit(itemType, itemId) {
      if (
        itemType === this.selectedItemType
        && itemId === this.selectedItemId
      ) {
        this.showEditSheet = true;
      }
    },
    handlerFabButton(option) {
      switch (option) {
        case 'importInspections':
          this.showImportInspectionSheet = true;
          break;
        case 'createInspection':
          this.showInspectionSheet = true;
          break;
        default:
          this.$store.dispatch('snackbar/displaySnack', {
            message: 'option non reconnue',
            type: 'error',
          });
      }
    },
    handleShowFab(showFab) {
      this.showFabInspection = showFab;
    },
    async handleCloseImportInspectionModal(equipement) {
      this.showImportInspectionSheet = false;
      // if the import is a succès, automaticaly open edit modal for the last equipement created
      if (equipement && equipement.id) {
        await this.$store.dispatch('changeSelectedItem', { type: 'equipements', id: equipement.id });
        this.handleEdit('equipements', equipement.id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.collec-view-wrapper {
  height: 100%;
}

.collect-view-tab-wrapper {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: stretch;

  width: 100%;
  height: 100%;
}

.collec-tab-container {
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0px;

  .collect-tab-content {
    height: 100%;
    background-color: $app-grey;
  }
}

.collect-tab-bar {
  flex: 0 0 auto;
}

.fab-add-button {
  position: absolute;
  bottom: 4rem;
  right: 3rem;
  // background-color: red;
}

</style>
