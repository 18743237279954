<template>
  <div>
    <v-overlay v-if="loadingOneSite">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <div class="patrimoine-detail-wrapper">
      <transition appear name="fade">
        <PatrimoineFatCard
          v-if="patrimoine"
          :patrimoine="patrimoine"
          :nomClient="nomClient"
          @show-edit-item="sheetEditStatus = true"
          @planify="goToPlanActionView()"
          :loading="loadingPatrimoine"
        ></PatrimoineFatCard>
      </transition>
      <div class="inputSearch">
        <transition appear name="fade">
          <v-text-field
            v-model="search"
            outlined
            rounded
            placeholder="Rechercher un site dans la liste"
            label="Recherche"
            append-icon="mdi-magnify"
            class="patrimoines-search-field"
            color="tertiary"
          >
          </v-text-field>
        </transition>
      </div>
      <div class="searchCounter" v-if="searchCounter >= 1"><h6>
        {{filteredPatrimoines.length}}/{{searchCounter}}</h6></div>
      <transition appear name="slide-fade-down">
        <v-card
          class="big-card"
          :loading="loadingSites"
        >
          <v-card-subtitle>Sites</v-card-subtitle>

            <SiteList
              v-if="!loading && patrimoines.length"
              :sites="filteredPatrimoines"
              @site-clicked="goToSite($event)"
            ></SiteList>

          <p v-if="loadingSites" class="sites-card-message">
            <v-skeleton-loader
              v-for="(site, i) in patrimoine.sites"
              :key="`skel_${i}`"
              type="list-item"
            >
            </v-skeleton-loader>
          </p>

          <p v-if="patrimoine && patrimoine.sites
          && !patrimoine.sites.length" class="sites-card-message">
            Aucun site sur ce patrimoine
          </p>

        </v-card>
      </transition>
    </div>

    <BaseFab
      class="add-button"
      :options="fabOptions"
      @option-selected="handlerFabButton"
      key='patrimoineDetail_fab'
    >
    </BaseFab>
    <AssociateSiteModal
      v-if="patrimoine"
      :show="showAssociateSiteModal"
      :client="client"
      @close="showAssociateSiteModal = false"
      :patrimoine="patrimoine"
    >
    </AssociateSiteModal>

    <BottomSheetEditItem
      :sheet="sheetEditStatus"
      @close-sheet="sheetEditStatus = false"
      :item="patrimoine"
      typeItem="patrimoines"
    >
    </BottomSheetEditItem>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { patrimoinesEndpoint, sitesUrl } from '@/api/apiUrls';
import axiosInstance from '../plugins/axios';
import PatrimoineFatCard from '../components/patrimoines/PatrimoineFatCard.vue';
import SiteList from '../components/sites/SiteList.vue';
import BaseFab from '../components/BaseFab.vue';
import AssociateSiteModal from '../components/patrimoines/AssociateSiteModal.vue';
import BottomSheetEditItem from '../components/BottomSheetEditItem.vue';


export default {
  components: {
    PatrimoineFatCard,
    SiteList,
    BaseFab,
    AssociateSiteModal,
    BottomSheetEditItem,
  },

  props: ['patrimoineId'],

  data() {
    return {
      loadingPatrimoine: false,
      loadingSites: false,
      loadingOneSite: false,
      showAssociateSiteModal: false,
      sheetEditStatus: false,
      needToSearch: false,
      searching: false,
      searchCounter: 0,
      items: [],
      search: '',
      fabOptions: [{
        icon: 'mdi-sitemap',
        name: 'sites',
        tooltip: 'Associer des sites',
        color: 'green',
      }],
    };
  },

  computed: {
    ...mapState('patrimoines', ['patrimoines']),
    ...mapState('sites', ['sites']),
    ...mapState('clients', ['clients']),
    patrimoine() {
      return this.patrimoines.find(patrimoine => patrimoine.id === this.patrimoineId);
    },
    patrimoineSites() {
      if (this.patrimoine) {
        return this.sites.filter(site => site.patrimoine === this.patrimoine.id);
      }
      return [];
    },
    client() {
      if (this.patrimoine) {
        return this.clients.find(client => client.patrimoines
        && client.patrimoines.includes(this.patrimoine.id));
      }
      return null;
    },

    nomClient() {
      if (this.client) {
        return this.client.nom;
      }
      return '';
    },
    filteredPatrimoines() {
      if (this.search && this.search.length > 2) {
        return this.items.filter(
          site => site.nom.toLowerCase().includes(this.search.toLowerCase())
            || site.codeSite.toLowerCase().includes(this.search.toLowerCase())
            || site.ville.toLowerCase().includes(this.search.toLowerCase())
            || site.adresse.toLowerCase().includes(this.search.toLowerCase())
            || site.codePostal.toLowerCase().includes(this.search.toLowerCase()),
        );
      }
      if (this.patrimoine) {
        return this.sites.filter(site => this.patrimoine.sites.includes(site.id));
      }
      return [];
    },
    loading() {
      return this.loadingSites || this.loadingPatrimoine;
    },
  },

  async created() {
    this.$store.dispatch('clearSelectedView');
    this.loadingPatrimoine = true;
    const promisesList = [
      this.$store.dispatch('changeSelectedItem', { type: 'patrimoines', id: this.patrimoineId }),
    ];

    await Promise.all([promisesList]);
    this.loadingPatrimoine = false;

    if (
      (this.patrimoine.sites.length && !this.patrimoineSites.length)
      || this.patrimoine.sites.length !== this.patrimoineSites.length
    ) {
      this.loadingSites = true;
      await this.$store.dispatch('sites/fetchAllByPatrimoine', this.patrimoineId);
      this.loadingSites = false;
    }
  },
  watch: {
    search(newValue) {
      if (newValue.length <= 2) {
        this.searchCounter = 0;
      }
      if (newValue && newValue.length > 2) {
        // Items have already been requested
        if (this.searching) {
          this.needToSearch = true;
        } else {
          this.callSearch(newValue);
        }
      }
    },
  },

  methods: {
    goToPlanActionView() {
      this.$router.push({
        name: 'planActions',
        params: { siteId: this.patrimoine.id },
      });
    },
    // Handle multiple actions from BaseFab and redirect to right action
    handlerFabButton(val) {
      if (val === this.fabOptions[0].name) {
        this.showAssociateSiteModal = true;
      }
    },
    async goToSite(site) {
      // this.$router.push(`/patrimoines/${this.patrimoine.id}/sites/${site.id}`);
      this.loadingOneSite = true;
      await this.$store.dispatch('sites/refreshItem', site.id).then(() => {
        this.loadingOneSite = false;
        this.$router.push({ name: 'site', params: { patrimoineId: this.patrimoine.id, siteId: site.id } });
      });
    },
    callSearch() {
      if (this.search && this.search.length > 2) {
        this.searching = true;
        const urlOptions = {
          headers: {
            accept: 'application/ld+json',
          },
          params: {
            searchField: this.search,
            pagination: true,
            page: 1,
          },
        };
        axiosInstance.axiosInstance.get(`${patrimoinesEndpoint}/${this.patrimoine.id}${sitesUrl}`, urlOptions)
          .then(((response) => {
            this.searchCounter = response.data;
            if (this.searchCounter >= 20) {
              this.populateItems(response.data.slice(0, 20));
            } else {
              this.populateItems(response.data);
            }
          }))
          .finally(() => {
            this.searching = false;
            this.handleSearchQueue();
          });
      }
    },

    populateItems(searchResults) {
      if (searchResults.length) {
        this.items = searchResults.map(site => ({
          id: String(site.id),
          codeSite: site.codeSite,
          nom: site.nom,
          ville: site.ville,
          adresse: site.adresse,
          codePostal: site.codePostal,
          batiments: site.batiments,
          inAlert: site.inAlert,
        }));
      }
    },

    // launch a new save if needToSave is true
    handleSearchQueue() {
      if (!this.searching && this.needToSearch) {
        this.needToSearch = false;
        this.callSearch();
      }
    },

  },
};
</script>

<style lang="scss" scoped>
  .patrimoine-detail-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    padding: 1rem;

    > * {
      max-width: 700px;
      width: 100%;
      margin-bottom: 1rem;
    }

    padding-bottom: 9rem;
  }

  .sites-card-message {
    margin: 0 auto 2rem auto;
    text-align: center;
  }
.inputSearch{
  padding-bottom: 0;
  margin-bottom: 0;
}
.searchCounter{
  text-align: right;
}
.add-button {
  position: fixed;
  right: 4rem;
  bottom: 4rem;
}

</style>
