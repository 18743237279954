const roleHierarchy = {
  ROLE_COLLECTEUR: ['ROLE_USER'],
  ROLE_PARTENAIRE: ['ROLE_USER', 'ROLE_COLLECTEUR'],
  ROLE_AUDITEUR: ['ROLE_USER', 'ROLE_PARTENAIRE', 'ROLE_COLLECTEUR'],
  ROLE_ADMIN_PROJET: ['ROLE_USER', 'ROLE_PARTENAIRE', 'ROLE_COLLECTEUR', 'ROLE_AUDITEUR'],
  ROLE_ADMIN_GENERAL: ['ROLE_USER', 'ROLE_PARTENAIRE', 'ROLE_COLLECTEUR', 'ROLE_AUDITEUR', 'ROLE_ADMIN_PROJET'],
  ROLE_ADMIN_TECH: ['ROLE_USER', 'ROLE_PARTENAIRE', 'ROLE_COLLECTEUR', 'ROLE_AUDITEUR', 'ROLE_ADMIN_PROJET', 'ROLE_ADMIN_GENERAL'],
};

function getRoleHierarchy(role) {
  if (!roleHierarchy[role]) {
    // console.warn(`Le rôle "${role}" n'a pas de hiérarchie définie.`);
    return [];
  }

  return roleHierarchy[role];
}

export default {
  install(Vue) {
    // Utilisez une variable locale pour stocker Vue.prototype
    const vuePrototype = Vue.prototype;

    // Ajoutez la méthode $getRoleHierarchy à la variable locale
    vuePrototype.$getRoleHierarchy = getRoleHierarchy;
  },
};
