<template>
  <div class="field-wrapper">
    <Component
      class="content-field"
      v-if="getFieldComponent(field.type) !== null"
      :is="getFieldComponent(field.type).name"
      :formField="field"
      :commonOptions="commonOptions"
      :defaultValue="fieldValue"
      @change="handleChange"
      @keyup="handleKeyUp"
      :editItem="edit"
    >
      <template v-slot:field-label>
        <div>
          {{ field.label }}
          <span v-if="isRequired"> *</span>
        </div>
      </template>
    </Component>
    <v-icon v-if="field.rules && field.rules.includes('necessary')"
    :class="fieldValue ?
      'field-icon-success':'field-icon-warning'">
      mdi-alert-circle
    </v-icon>
  </div>
</template>

<script>
import axiosInstance from '../../plugins/axios';
import SelectLearningField from './select-learning-field.vue';
import TextField from './text-field.vue';
import NumberField from './number-field.vue';
import DateField from './date-field.vue';
import SelectField from './select-field.vue';
import DisplayField from './display-field.vue';
import DisplayWorkHistoryField from './display-work-history-field.vue';
import PictureField from './picture-field.vue';
import ComboboxField from './combobox-field.vue';
import TextAreaField from './text-area-field.vue';
import BooleanRadioField from './boolean-radio-field.vue';
import MultiRadioField from './multi-radio-field.vue';
import GpsField from './gps-field.vue';
import MenuSelectField from './menu-select-field.vue';
import ButtonGroupField from './button-group-field.vue';
import TypeUsageField from './type-usage-field.vue';
import MultiPicturesField from './multi-pictures-field.vue';
import SelectBooleanField from './select-boolean-field.vue';
import MultiPicturesFieldAddEqp from './multi-pictures-field-add-eqp.vue';
import TextAreaEditorField from './text-area-editor-field.vue';


export default {
  components: {
    TextField,
    NumberField,
    DateField,
    SelectField,
    DisplayField,
    DisplayWorkHistoryField,
    PictureField,
    ComboboxField,
    TextAreaField,
    BooleanRadioField,
    MultiRadioField,
    GpsField,
    MenuSelectField,
    ButtonGroupField,
    TypeUsageField,
    MultiPicturesField,
    SelectBooleanField,
    SelectLearningField,
    MultiPicturesFieldAddEqp,
    TextAreaEditorField,
  },
  props: {
    field: Object,
    defaultValue: null,
    duplicate: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    fieldValue: {
      get() {
        if (this.field.type !== 'display' && !this.field.isParameter) {
          return this.$store.getters.formField(this.field.name, this.field.nestedObj);
        }
        return this.defaultValue;
      },
      set(fieldValue) {
        if (this.field.type !== 'display') {
          let valueToSave = fieldValue;
          const multiselectField = [];
          // if the value comme form a ListeOption, only store the 'codeOption'
          if (
            typeof fieldValue === 'object'
            && fieldValue
            && fieldValue.codeOption) {
            valueToSave = fieldValue.codeOption;
          }

          // if field is multiSelect and values are listeOptions
          if (this.field.type === 'multiselect') {
            if (Array.isArray(fieldValue)) {
              fieldValue.forEach((element) => {
                if (element.codeOption) {
                  multiselectField.push(element.codeOption);
                } else {
                  multiselectField.push(element);
                }
              });
              valueToSave = multiselectField;
            }
          }

          // if number is null
          if (this.field.type === 'number') {
            if (valueToSave === '') valueToSave = null;
          }
          // update the formField on the store
          this.$store.dispatch('updateFormField', {
            value: valueToSave,
            name: this.field.name,
            nestedObj: this.field.nestedObj,
          });

          // detect and handle 'set:' interactions
          // TODO extract this logic in a watch command
          if (this.field.interaction) {
            // extract set command
            // ex : "if:someField|set:targetField,dataLocation.nested.object"
            const interSplit = this.field.interaction.split('|');

            for (let i = 0; i < interSplit.length; i += 1) {
              if (interSplit[i].includes('set:')) {
                const [
                  targetField,
                  dataLocation,
                ] = interSplit[i]
                  .slice(4)
                  .split(',');

                // get the data to set in the targetField
                let data = null;
                if (dataLocation.includes('concat')) {
                  const [
                    concat1,
                    concat2,
                    listeOption,
                  ] = dataLocation
                    .slice(7)
                    .split(';');

                  let name1 = null;
                  let name2 = null;
                  let nestedObj1 = null;
                  let nestedObj2 = null;
                  if (concat1.includes('.')) {
                    [
                      nestedObj1,
                      name1,
                    ] = concat1
                      .split('.');
                  }
                  if (concat2.includes('.')) {
                    [
                      nestedObj2,
                      name2,
                    ] = concat2
                      .split('.');
                  }
                  if (listeOption === 'lo_orientationinclinaison') {
                    const codeOption = this.getOne(name1, nestedObj1).replaceAll('-', '').replaceAll('/', '')
                      .concat(this.getOne(name2, nestedObj2), '-lo_orientationinclinaison');
                    axiosInstance.axiosInstance.get('/api/ref/liste-options?codeType=lo_orientationinclinaison').then((response) => {
                      const loData = response.data;
                      let trouve = false;
                      let j = 0;
                      while (j < loData.length && !trouve) {
                        if (loData[j].codeOption === codeOption.toLowerCase()) {
                          trouve = true;
                        } else {
                          j += 1;
                        }
                      }

                      if (j > 0 && trouve === true) {
                        data = loData[j].data.coeffOrientationInclinaison;
                      }

                      let nestedObj = null;
                      let fieldName = targetField;
                      if (targetField.includes('.')) {
                      // handle 1 level nested
                        [nestedObj, fieldName] = targetField.split('.');
                      }
                      // update the target formField on the store
                      this.$store.dispatch('updateFormField', {
                        value: data,
                        name: fieldName,
                        nestedObj,
                      });
                    });
                  }
                } else if (Array.isArray(fieldValue) || typeof fieldValue === 'object') {
                  if (dataLocation.includes('.')) {
                    // acces nested object
                    // ex : data.objectA.propertyB
                    data = dataLocation.split('.').reduce((prev, curr) => prev[curr], fieldValue);
                  } else {
                    data = fieldValue[dataLocation];
                  }

                  let nestedObj = null;
                  let fieldName = targetField;
                  if (targetField.includes('.')) {
                    // handle 1 level nested
                    [nestedObj, fieldName] = targetField.split('.');
                  }

                  // update the target formField on the store
                  this.$store.dispatch('updateFormField', {
                    value: data,
                    name: fieldName,
                    nestedObj,
                  });

                  if (dataLocation === 'data.inclinaison') {
                    if (this.getOne('orientation', 'attributs')) {
                      const codeOption = this.getOne('orientation', 'attributs').replaceAll('-', '').replaceAll('/', '')
                        .concat(this.getOne('systemedintegration_inclinaison', 'referentielValues'), '-lo_orientationinclinaison');
                      axiosInstance.axiosInstance.get('/api/ref/liste-options?codeType=lo_orientationinclinaison').then((response) => {
                        const loData = response.data;
                        let trouve = false;
                        let j = 0;
                        while (j < loData.length && !trouve) {
                          if (loData[j].codeOption === codeOption.toLowerCase()) {
                            trouve = true;
                          } else {
                            j += 1;
                          }
                        }
                        let data2 = 0;
                        if (j > 0 && trouve === true) {
                          data2 = loData[j].data.coeffOrientationInclinaison;
                        }

                        let nestedObj2 = null;
                        const targ = 'attributs.coefficientorientationinclinaison';
                        let fieldName2 = targ;
                        if (targ.includes('.')) {
                        // handle 1 level nested
                          [nestedObj2, fieldName2] = targ.split('.');
                        }
                        // update the target formField on the store
                        this.$store.dispatch('updateFormField', {
                          value: data2,
                          name: fieldName2,
                          nestedObj: nestedObj2,
                        });
                      });
                    }
                  }
                }
              }
            }
          }
        }
      },
    },

    isRequired() {
      // gestion attribut refVersion de mission non obligatoire en edition mais obligatoire en créa
      if (this.edit && this.field.options === 'allRefs') {
        return false;
      }
      return this.field.rules ? this.field.rules.includes('required') : false;
    },

    commonOptions() {
      if (this.field.type === 'display') {
        return {
          ...this.getFieldComponent(this.field.type).options,
          hint: this.field.hint,
        };
      }

      return {
        ...this.getFieldComponent(this.field.type).options,
        outlined: true,
        dense: true,
        persistentHint: true,
        clearable: true,
        disabled: this.field.readOnly,
        hint: this.field.hint,
      };
    },
  },
  watch: {
    defaultValue(newValue) {
      this.fieldValue = newValue;
    },
  },
  created() {
    if (this.field.type !== 'display') {
      let initValue = this.defaultValue;
      const templateDefault = this.field.default;

      const noInitialValue = initValue === null || initValue === undefined;
      const defaultValueInTemplate = templateDefault !== null && templateDefault !== undefined;
      // if init value is null and default is present in the form template
      // then update the field
      if (noInitialValue && defaultValueInTemplate) {
        initValue = this.field.default;
      }

      // handling date-field default value
      if (this.field.type === 'date') {
        if (initValue === 'today') {
          initValue = new Date();
        }
      }

      this.$store.dispatch('createFormField', {
        value: initValue,
        name: this.field.name,
        nestedObj: this.field.nestedObj,
      });
    }
  },
  methods: {
    getOne(fieldName, fieldNestedObj) {
      return this.$store.getters.formField(fieldName, fieldNestedObj);
    },
    handleChange(event) {
      if (this.field.type === 'select' && typeof event === 'undefined') {
        this.fieldValue = null;
      } else if (typeof event === 'object' && event.localValue) {
        this.fieldValue = event.localValue;
        if (event.modifyAll) {
          this.$emit('changeAllEquipement', { field: this.field.name, value: event.localValue });
        }
      } else if (typeof event === 'object' && event.localValue === null) {
        this.fieldValue = null;
      } else {
        this.fieldValue = event;
      }
      if (this.field.isParameter) {
        this.$emit('fieldModified', event);
      } else {
        this.$emit('fieldModified');
      }
    },
    handleKeyUp(event) {
      if (this.field.isParameter) {
        this.$emit('fieldModified', event);
      }
    },
    getFieldComponent(fieldType) {
      if (fieldType === 'number') {
        return { name: 'number-field' };
      }
      if (fieldType === 'date') {
        return { name: 'date-field' };
      }
      if (fieldType === 'select') {
        return { name: 'select-field' };
      }
      if (fieldType === 'selectLearning') {
        return { name: 'select-learning-field' };
      }
      if (fieldType === 'display') {
        return { name: 'display-field' };
      }
      if (fieldType === 'displayWorkHistory') {
        return { name: 'display-work-history-field' };
      }
      if (fieldType === 'picture') {
        return { name: 'picture-field' };
      }
      if (fieldType === 'combobox') {
        return { name: 'combobox-field' };
      }
      if (fieldType === 'textArea') {
        return { name: 'text-area-field' };
      }
      if (fieldType === 'booleanRadio') {
        return { name: 'boolean-radio-field' };
      }
      if (fieldType === 'multiRadio') {
        return { name: 'multi-radio-field' };
      }
      if (fieldType === 'gpsField') {
        return { name: 'gps-field' };
      }
      if (fieldType === 'menuSelect') {
        return { name: 'menu-select-field' };
      }
      if (fieldType === 'picturesDisplay') {
        if (!this.duplicate) {
          return { name: 'multi-pictures-field' };
        }
        return null;
      }
      if (fieldType === 'textAreaEditor') {
        return { name: 'text-area-editor-field' };
      }
      if (fieldType === 'btnGroup') {
        return { name: 'button-group-field' };
      }
      if (fieldType === 'selectBoolean') {
        return { name: 'select-boolean-field' };
      }
      if (fieldType === 'typeUsage') {
        return { name: 'type-usage-field' };
      }
      if (fieldType === 'multiselect') {
        return {
          name: 'select-field',
          options: {
            multiple: true,
            chips: true,
          },
        };
      }
      return { name: 'text-field' };
    },
  },
};
</script>

<style lang="scss" scoped>
  .field-icon-warning{
    color:orange;
    margin-bottom:30px;
    margin-left:5px;
    width:5%;
  }

  .field-icon-success{
    color:green;
    margin-bottom:30px;
    margin-left:5px;
    width:5%;
  }

  .field-wrapper{
    display: flex;
  }

  .content-field{
    width:95%;
  }
</style>
