<template>
  <div class="radio-field-wrapper">
    <slot name="field-label"></slot>
    <v-switch
      v-model="localValue"
    ></v-switch>
  </div>
</template>

<script>
export default {
  name: 'boolean-radio-field',
  props: {
    formField: Object,
    defaultValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      localValue: this.defaultValue,
    };
  },
  watch: {
    defaultValue(newValue) {
      this.localValue = newValue;
    },

    localValue(newValue) {
      this.$emit('change', newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
  .radio-field-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
  }
</style>
