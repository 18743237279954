<template>
  <div class="gestion-view">
    <v-overlay v-if="loadingParam">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <h3>Gestion des listes de paramètres de tri d'équipements</h3>
    <MissionChangeParamList
    :listTypeEquipement="equipementTypesALL"
    :organisationListeEquipement="this.selectedMission.organisationListeEquipement"
    @listsChange="save($event)">

    </MissionChangeParamList>
  </div>
</template>


<script>
import api from '@/api';
import { mapGetters, mapState } from 'vuex';
import MissionChangeParamList from '../components/missions/MissionChangeParamList.vue';

export default {
  components: {
    MissionChangeParamList,
  },
  data() {
    return {
      saving: false,
      localValueSite: [],
      localValueBatiment: [],
      equipementTypesALL: [],
      loadingParam: false,
    };
  },
  computed: {
    ...mapGetters('missions', { selectedMission: 'selected' }),
    ...mapState('sites', ['sites']),
    ...mapState('batiments', ['batiments']),

    selectedView: {
      get() {
        return this.$store.state.selectedView;
      },
      set(value) {
        this.$store.commit('setSelectedView', value);
      },
    },
  },

  async created() {
    this.loadingParam = true;
    await this.$store.dispatch('missions/refreshItem', this.selectedMission.id);
    this.selectedView = 'Gestion Listes Paramètres';
    this.equipementTypesALL = await api
      .refEquipementTypes.getAllByMission(this.selectedMission.id);

    this.loadingParam = false;
  },

  methods: {
    save(event) {
      this.saving = true;
      const payload = { };
      payload.id = this.selectedMission.id;
      payload.data = {
        organisationListeEquipement: event,
      };

      this.$store.dispatch(
        'missions/save',
        {
          id: payload.id,
          ...payload,
        },
      ).then(async (res) => {
        if (res.isAxiosError) {
          const message = 'Impossible de sauvegarder les changements';
          this.$store.dispatch('snackbar/displaySnack',
            {
              message,
              type: 'error',
            });
        } else {
          this.$store.dispatch('snackbar/displaySnack', { message: 'Sauvegardé avec succès !', type: 'succes' });
          await this.$store.dispatch('sites/fetchAllByMission', this.missionId);
          await this.sites.forEach(async (site) => {
            await this.$store.dispatch('batiments/fetchAllBySite', site.id);
          });
          this.batiments.forEach((bat) => {
            this.$store.dispatch(
              'batiments/save',
              {
                id: bat.id,
                data: {
                  ...bat,
                  organisationListeEquipement: event,
                },
              },
            );
          });
        }
      }).catch(() => {
        const message = 'Impossible de sauvegarder les changements';
        this.$store.dispatch('snackbar/displaySnack',
          {
            message,
            type: 'error',
          });
      }).finally(() => {
        this.saving = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .gestion-view {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    padding: 1rem;

    > * {
      margin-bottom: 2rem;
    }
  }
  .combox {
    width:34em;
  }
</style>
