import { render, staticRenderFns } from "./DialogDeleteConfirmation.vue?vue&type=template&id=08b58850&scoped=true&"
import script from "./DialogDeleteConfirmation.vue?vue&type=script&lang=js&"
export * from "./DialogDeleteConfirmation.vue?vue&type=script&lang=js&"
import style0 from "./DialogDeleteConfirmation.vue?vue&type=style&index=0&id=08b58850&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08b58850",
  null
  
)

export default component.exports