/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  equipementTypesUrl,
  equipementCategoriesUrl,
  // missionsEndpoint,
  apiUrl,
  refUrl,
  missionsUrl,
} from './apiUrls';

export default {
  db: new PouchDB('refEquipementTypes'),

  convertBackendObject(obj, missionId) {
    // const idMission = obj.mission.replace(`${missionsEndpoint}/`, '');
    const idMission = missionId;
    const id = `${apiUrl}${refUrl}${missionsUrl}/${missionId}${equipementTypesUrl}/${obj.code}`;
    // set an _id attribute for pouch
    const _id = obj._id || `${idMission}_${id.toString()}`; /* eslint-disable-line no-underscore-dangle */
    const meta = {};

    return {
      _id,
      meta,
      id,
      nom: obj.nom,
      code: obj.code,
      categorie: obj.categorie.replace(`${apiUrl}${refUrl}${missionsUrl}/${missionId}${equipementCategoriesUrl}/`, ''),
      // attrDefinition: obj.attrDefinition,
      mission: idMission,
      formTemplate: obj.formTemplate,
    };
  },

  /**
   * get all equipementTypes for a mission
   * @param {String} missionId - id of the parent mission
   */
  async getAllByMission(missionId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${apiUrl}${refUrl}${missionsUrl}/${missionId}${equipementTypesUrl}/form`);
      const equipementTypes = response.data
        .map(equipementType => this.convertBackendObject(equipementType, missionId));
      const test = equipementTypes.filter(tes => tes.formTemplate.length > 0);
      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(test, this.db);
    } catch (e) {
      console.error('refEquipementTypesApi getAllByMission error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByIdPrefix(`${missionId}_`, this.db);
    }
  },

  /**
   * get all equipementTypes for a mission for offline use
   * @param {String} missionId - id of the parent mission
   */
  async fetchOffline(missionId) {
    const response = await axiosInstance.axiosInstance.get(`${apiUrl}${refUrl}${missionsUrl}/${missionId}${equipementTypesUrl}/form`);
    const equipementTypes = response.data
      .map(equipementType => this.convertBackendObject(equipementType, missionId));
    // if get is successful,  store data into pouchdb and return it
    return pouchUtil.addDocs(equipementTypes, this.db);
  },

  /**
   * Fetch all equipementTypes stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocs(this.db);
  },
};
