import api from '@/api';

/* eslint no-console: ["error", { allow: ["error", "log"] }] */
/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = {
  missions: [],
  typesAudits: [],
  selectedId: null,
};

const getters = {
  allTypesAudits() {
    return state.typesAudits;
  },

  selected: state => state.missions.find(mission => mission.id === state.selectedId),
};

const actions = {
  /**
   * Create a new Mission and add it to the state
   */
  createNew({ commit }) {
    const newMission = {
      id: `tempMis${Math.random()}`,
      nom: 'Nouvelle mission',
    };

    // Add new Mission and change featured item in Store
    commit('addMissions', [newMission]);
    return { ...newMission };
  },

  /**
   * Save a mission on the server (automaticly select POST or PUT) and update the store
   */
  save({ commit, state }, payload) {
    return new Promise((resolve, reject) => {
      // if the mission doesn't exist on the backend, create it by post
      if (payload.id.startsWith('tempMis')) {
        api.missions.create(payload.data)
          .then((response) => {
            commit('deleteMission', payload.id);
            commit('addMissions', [response]);

            if (state.selectedId === payload.id) {
              commit('changeSelectedId', response.id);
            }
            resolve({ ...response });
          })
          .catch(error => reject(error));
      // else update it by put
      } else if (payload.byController) {
        api.missions.updateController(payload.id, payload.data)
          .then((response) => {
            commit('addMissions', [response]);
            resolve({ ...response });
          })
          .catch(error => reject(error));
      } else {
        api.missions.update(payload.id, payload.data)
          .then((response) => {
            commit('addMissions', [response]);
            resolve({ ...response });
          })
          .catch(error => reject(error));
      }
    });
  },


  /**
   * Fetch all Missions from the server and update the store
   */
  fetchAll({ commit }) {
    return api.missions.getAll()
      .then(response => commit('addMissions', response));
  },

  /**
   * Fech all mission of a parent client
   * @param {String} clientId - id of the parent client
   */
  fetchAllByClient({ commit }, clientId) {
    return api.missions.getAllByClient(clientId)
      .then(response => commit('addMissions', response));
  },

  /**
   * Delete a mission on the server and from the store
   */
  delete({ commit }, mission) {
    return api.missions.delete(mission.id).then(() => commit('deleteMission', mission.id));
  },

  /**
   * Fetch one mission by it's id fron the server and update the store
   */
  refreshItem({ commit }, missionId) {
    return api.missions.get(missionId)
      .then((response) => {
        commit('addMissions', [response]);
        return { ...response };
      });
  },

  /**
   * get mission by his id
   */
  getById({ commit }, missionId) {
    return api.missions.get(missionId).then((response) => {
      commit('addMissions', [response]);
      return response;
    });
  },

  /**
   * Fetch all the typeAutits from the server and update the store
   */
  fetchAllTypesAudits({ commit }) {
    return api.auditTypes.getAll().then(response => commit('setTypesAudits', response));
  },

  /**
   * Load all missions stored offline in pouchDB
   */
  loadOffline({ commit }) {
    return api.missions.loadOffline().then(response => commit('addMissions', response));
  },

  /**
   * Load all types audits stored offline in pouchDB
   */
  loadAllTypesAuditsOffline({ commit }) {
    return api.auditTypes.loadOffline().then(response => commit('setTypesAudits', response));
  },

  async changeSelected({
    state, getters, commit, dispatch, rootState,
  },
  missionId) {
    if (state.selectedId !== missionId) {
      commit('changeSelectedId', missionId);

      if (!getters.selected) {
        await dispatch('refreshItem', missionId);
      }

      if (!rootState.clients.selected
        || rootState.clients.selected.id !== getters.selected.client) {
        await dispatch('clients/changeSelected', getters.selected.client, { root: true });
      }
    }
  },

  async clearSelected({ commit }) {
    await commit('changeSelectedId', null);
  },
};

const mutations = {
  setTypesAudits(state, typesAudits) {
    state.typesAudits = typesAudits;
  },

  addMissions(state, newMissions) {
    newMissions.forEach((newMission) => {
      const stateIndex = state.missions
        .findIndex(stateMission => stateMission.id === newMission.id);

      if (stateIndex >= 0) {
        state.missions.splice(stateIndex, 1, newMission);
      } else {
        state.missions.push(newMission);
      }
    });
  },

  deleteMission(state, missionId) {
    state.missions.splice(state.missions.findIndex(mission => mission.id === missionId), 1);
  },

  changeSelectedId(state, missionId) {
    state.selectedId = missionId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
