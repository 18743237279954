 <template>
  <v-dialog
    v-model="show"
    width="500"
  >
    <v-card>
      <v-card-title class="headline">Nouvelles inspections disponibles</v-card-title>

      <v-card-text>
        Il reste des inspections qui n'ont pas encore été créées pour ce {{ parentLabel }}.
        Voulez-vous les créer ?
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
          color="primary"
          text
          @click="$emit('close')"
        >
          ne rien faire
        </v-btn>

        <v-btn
          color="primary"
          depressed
          :loading="loading"
          @click="createInspections()"
        >
          les créer
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapGetters } from 'vuex';
import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';

export default {
  mixins: [
    UpdateItemAlertMixin,
  ],
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    inspectionsMissing: {
      type: Array,
    },
    parent: {
      type: String,
      validator(value) {
        // The value must match one of these strings
        return ['sites', 'batiments'].indexOf(value) !== -1;
      },
    },
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      site: 'sites/selected',
      batiment: 'batiments/selected',
    }),

    parentLabel() {
      return this.parent === 'sites' ? 'site' : 'bâtiment';
    },
  },

  methods: {
    async createInspections() {
      this.loading = true;

      if (this.inspectionsMissing.length) {
        await Promise.all(this.inspectionsMissing.map(type => this.createOneInspection(type)));
      }

      this.loading = false;
      this.$emit('close');
    },

    async createOneInspection(inspectionType) {
      let findBlank = false;
      await this.$store.dispatch('formTemplates/fetchInspectionTemplate', inspectionType.id).then((response) => {
        const constatsInspection = [];
        response.formTemplate.forEach((field) => {
          field.fields.forEach((constat) => {
            constatsInspection[constat.name] = null;
            if (constat.rules && constat.rules.includes('necessary')) {
              findBlank = true;
            }
          });
        });
      });
      const payload = {
        idType: inspectionType.id,
        nomType: inspectionType.nom,
        codeType: inspectionType.code,
      };

      // handle site of batiment inspections
      if (this.parent === 'batiments') {
        payload.idBatiment = this.batiment.id;
      } else if (this.parent === 'sites') {
        payload.idSite = this.site.id;
      }

      const newInspection = await this.$store.dispatch('inspections/createNew', payload);

      const inspectionToSave = {
        ...newInspection,
        inAlert: findBlank,
      };
      inspectionToSave.nom = inspectionType.nom;
      delete inspectionToSave.id;

      this.$store.dispatch('inspections/save', {
        data: inspectionToSave,
        id: newInspection.id,
      }).then(() => {
        if (this.parent === 'batiments') {
          this.majBatimentAlert(this.batiment.id, 'inspections');
        } else if (this.parent === 'sites') {
          this.majSiteAlert(this.site.id, 'inspections');
        }
      });
    },
  },
};
</script>
