<template>
  <div class="list-wrapper">

    <div class="list-title-block">
      <div class="list-title">Programmes</div>
    </div>

    <v-expansion-panels
      accordion
      class="sites-list"
    >
      <v-expansion-panel
        v-for="site in patrimoineSites"
        :key="`site_${site.id}`"
      >
        <v-expansion-panel-header>
          <div class="header-wrapper">
            <div class="header-title">{{ site.nom }}</div>
            <div class="header-programme-name">{{ getProgrammeName(site.id) }}</div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <ProgrammeSelectTable
           :site="site"
           :initialSelection="getProgramme(site.id)"
           @new-selection="handleChangeSelected(site.id, $event)"
          >
          </ProgrammeSelectTable>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ProgrammeSelectTable from './ProgrammeSelectTable.vue';

export default {
  components: {
    ProgrammeSelectTable,
  },
  props: {
    initialList: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      programmesSelected: [],
      touched: false,
    };
  },

  computed: {
    ...mapGetters({
      patrimoine: 'patrimoines/selected',
      patrimoineSites: 'sites/selectedPatrimoineSites',
    }),
    ...mapState('sites', ['sites']),
    ...mapState('programmes', ['programmes']),
  },

  async created() {
    this.loading = true;
    await Promise.all(
      this.patrimoineSites.map(site => this.$store.dispatch('programmes/fetchAllBySite', site.id)),
    );
    this.loading = false;

    if (this.initialList.length) {
      this.programmesSelected = this.programmes
        .filter(prog => this.initialList.includes(prog.id));
    }
  },

  watch: {
    programmesSelected() {
      this.$emit('new-selection', {
        programmes: this.programmesSelected,
        touched: this.touched,
      });
    },
  },

  methods: {
    getProgramme(siteId) {
      return this.programmesSelected.find(prog => prog.site === siteId);
    },

    getProgrammeName(siteId) {
      const programme = this.getProgramme(siteId);
      if (programme) {
        return programme.nom;
      }
      return '';
    },

    handleChangeSelected(siteId, { programme, touched }) {
      // first remove previous selection if there is one
      const previousSelectionIndex = this.programmesSelected
        .findIndex(prog => prog.site === siteId);
      if (previousSelectionIndex >= 0) {
        this.programmesSelected.splice(previousSelectionIndex, 1);
      }

      // then update selection list and touched boolean
      this.touched = this.touched || touched;
      if (programme) {
        this.programmesSelected.push(programme);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .list-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    height: 100%;

    .list-title-block {
      flex: 0 0 auto;
      padding: 0.5rem;
      height: 3.5rem;

      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;


      .list-title {
        font-weight: 300;
        font-size: 1.4rem;
      }
    }

    .sites-list {
      overflow-y: auto;
      padding-bottom: 5rem;
    }

    .header-wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;

      width: 100%;

      .header-programme-name {
        margin-left: auto;
        margin-right: 1rem;
        width: 7rem;
        text-align: right;

        color: $app-blue;
      }
    }
  }
</style>
