<template>
  <v-bottom-sheet
    v-model="sheet"
    persistent
    fullscreen
  >

    <v-sheet class="sheet-wrapper">
      <h1>Ajouter une nouvelle intervention</h1>
        <p class="p-inter" v-if="!this.idAddItem">Souhaitez-vous creer une intervention en lien avec
           un équipement ou pour une inspection: </p>

        <v-btn-toggle
          v-if="!this.idAddItem"
          @change="clearList()"
          v-model="EquipOrInspect"
          tile
          color="primary"
          group
          class="source-choice-buttons"
        >
          <v-btn value="equipement">
            équipement
          </v-btn>

          <v-btn value="inspection">
            inspection
          </v-btn>
        </v-btn-toggle>

        <div v-if="EquipOrInspect==='equipement'">
          <p class="p-inter">Selectionner un équipement pour cette intervention:</p>
          <v-autocomplete
            outlined
            :items="this.batimentEquipements"
            item-text="nom"
            return-object
            v-model="selectedCodeEquipement"
            @change="handleItemSeleted(selectedCodeEquipement, 'equipement')"
            :disabled="this.idAddItem ? true : false"
          >
            <template v-slot:label>
              <slot name="field-label"></slot>
            </template>

            <template
              slot="item"
              slot-scope="data"
            >
              <template>
                  <v-list>{{data.item.nom}}</v-list>
              </template>
            </template>
          </v-autocomplete>
        </div>
          <div v-if="EquipOrInspect==='inspection'">
          <p class="p-inter">Selectionner une inspection pour cette intervention:</p>
          <v-autocomplete
            outlined
            :items="this.batimentInspections"
            item-text="nomType"
            return-object
            v-model="selectedCodeInspection"
            @change="handleItemSeleted(selectedCodeInspection, 'inspection')"
          >
            <template v-slot:label>
              <slot name="field-label"></slot>
            </template>
          </v-autocomplete>
        </div>
        <div v-if="itemSelected && computedOptions.length && !loading">
          <p class="p-inter">Selectionner une intervention:</p>
          <v-autocomplete
            outlined
            :items="this.computedOptions"
            item-text="libelle"
            item-value="codeOption"
            v-model="codeIntervention"
            @change="interventionIsSelected = true"
          >
            <template v-slot:label>
              <slot name="field-label"></slot>
            </template>
          </v-autocomplete>
        </div>
        <div v-if="EquipOrInspect==='equipement' && interventionIsSelected &&
        itemSelected && computedOptions.length && !loading">
          <p class="p-inter">Vous pouvez selectionner d'autres équipements
             sur lesquels créer la même intervention:</p>
          <v-autocomplete
            outlined
            :items="populateEquipSameType()"
            item-text="nom"
            return-object
            multiple
            chips
            v-model="equipList"
          >
          </v-autocomplete>
        </div>
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
        >
        </v-progress-circular>
        <div v-if="itemSelected && !computedOptions.length && !loading">
          <p class="no-inter">Il n'y a pas d'interventions disponible
            pour l'équipement ou l'inspection selectionné(e) !</p>
        </div>

        <InterventionForm
          v-if="interventionIsSelected"
          :key="`inter_form_${codeIntervention}`"
          :intervention="getIntervetionObject()"
          :itemIdForCreation="itemSelectedId"
          creation="true"
          @form-updated="handleFormUpdate($event)"
        >

        </InterventionForm>

      <div class="bottom-sheet-footer">
        <v-btn
          class="action-button"
          text
          color="primary"
          @click="clearList(), $emit('close-sheet');"
        >
          Annuler
        </v-btn>
        <v-btn
          class="action-button"
          text
          color="primary"
          @click="validate()"
          :disabled="!interventionIsSelected || !this.form  || !this.form.valid"
        >
          Valider
        </v-btn>
      </div>
    </v-sheet>


  </v-bottom-sheet>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';
import InterventionForm from './InterventionForm.vue';


export default {
  components: {
    InterventionForm,
  },
  mixins: [
    UpdateItemAlertMixin,
  ],
  props: ['sheet', 'idAddItem', 'typeAddItem'],
  data() {
    return {
      options: null,
      selectedCodeEquipement: null,
      selectedCodeInspection: null,
      EquipOrInspect: null,
      itemSelected: false,
      interventionIsSelected: false,
      codeIntervention: null,
      selectedType: null,
      selectedCode: null,
      loading: false,
      form: null,
      itemSelectedId: null,
      equipList: [],
    };
  },
  computed: {
    ...mapState('inspections', { inspections: 'inspections' }),
    ...mapState('equipements', { equipements: 'equipements' }),
    ...mapState('listeOptions', { listeOptions: 'all' }),
    ...mapGetters({
      selectedMission: 'missions/selected',
      selectedBatiment: 'batiments/selected',
      batimentInterventions: 'interventions/selectedBatimentInterventions',
    }),

    computedOptions() {
      if (!this.selectedMission || !this.selectedCode) {
        return [];
      }
      return this.listeOptions.filter(option => option.id.startsWith(`${this.selectedMission.id}_${this.selectedCode}`));
    },

    batimentEquipements() {
      const orderedEquipements = [];
      const equipements = this.equipements
        .filter(equipement => equipement.batiment === this.selectedBatiment.id);

      //  Create list header items (domains)
      equipements.map((equipement) => {
        // eslint-disable-next-line max-len
        if (!orderedEquipements.find(domaine => domaine.header === equipement.libelleDomaine)) {
          orderedEquipements.push({ header: equipement.libelleDomaine });
        }
        return true;
      });
      //  Order domains list by name
      orderedEquipements.sort((a, b) => a.header.localeCompare(b.header));
      const orderedDomaines = JSON.parse(JSON.stringify(orderedEquipements));

      //  Order equipments list by category and name
      equipements.sort((a, b) => {
        const patternA = `(${a.libelleCategorie}) ${a.nom}`;
        const patternB = `(${b.libelleCategorie}) ${b.nom}`;
        return patternB.localeCompare(patternA);
      });
      //  Create list items (items)
      equipements.map((equipement) => {
        // eslint-disable-next-line max-len
        const index = orderedEquipements.findIndex(domaine => domaine.header === equipement.libelleDomaine);
        if (index !== -1) {
          orderedEquipements.splice(index + 1, 0, { id: equipement.id, nom: `${equipement.nom} (${equipement.libelleCategorie})`, type: equipement.type });
        }
        return true;
      });
      //  Add dividers
      orderedDomaines.map((orderedDomaine) => {
        const index = orderedEquipements.findIndex(item => orderedDomaine.header === item.header);
        if (index > 0) orderedEquipements.splice(index, 0, { divider: true });
        return true;
      });

      return orderedEquipements;
    },
    batimentInspections() {
      return this.inspections
        .filter(inspection => inspection.batiment === this.selectedBatiment.id);
    },
  },
  created() {
    if (this.idAddItem !== null && typeof this.idAddItem !== 'undefined') {
      this.handleItemSeleted(this.idAddItem, this.typeAddItem);
    }
    this.$store.dispatch('inspections/fetchTypesInspectionByMission',
      {
        idMission: this.selectedMission.id,
        objectType: 'batiment',
      }).then(() => {
      this.$store.dispatch('inspections/fetchAllByBatiment', this.selectedBatiment.id);
    });
    // this.$store.dispatch('interventions/fetchAllByBatiment', this.selectedBatiment.id);
    this.$store.dispatch('equipements/fetchAllByBatiment', this.selectedBatiment.id);
  },
  methods: {
    populateEquipSameType() {
      return this.batimentEquipements.filter(equip => equip.type
      === this.selectedCodeEquipement.type && equip.id !== this.selectedCodeEquipement.id);
    },
    handleItemSeleted(item, itemType) {
      this.loading = true;
      this.itemSelected = true;
      this.selectedType = itemType;
      this.itemId = item.id;
      this.interventionIsSelected = false;
      this.codeIntervention = null;
      if (itemType === 'equipement') {
        const shortType = item.type.slice(item.type.lastIndexOf('/') + 1);
        this.selectedCode = `intervention-${shortType}`;
        this.itemSelectedId = item.id;
      } else if (itemType === 'inspection') {
        this.selectedCode = `intervention-${item.codeType}`;
      }
      this.getListeInterventionByItem();
    },

    getListeInterventionByItem() {
      const missionId = this.selectedMission.id;
      const codeType = this.selectedCode;
      this.$store.dispatch('listeOptions/fetchAllByType', { missionId, codeType })
        .finally(() => { this.loading = false; });
    },

    getIntervetionObject() {
      if (this.interventionIsSelected) {
        if (this.EquipOrInspect === 'equipement' || this.typeAddItem === 'equipement') {
          return {
            equipement: this.itemId,
            option: this.codeIntervention,
          };
        }
        return {
          inspection: this.itemId,
          option: this.codeIntervention,
        };
      }
      return null;
    },

    handleFormUpdate(newForm) {
      this.form = newForm;
    },

    clearList() {
      this.itemSelected = false;
      this.selectedCodeEquipement = null;
      this.selectedCodeInspection = null;
      this.interventionIsSelected = false;
    },
    validate() {
      this.$store.dispatch('interventions/createInterventionOnBatiment', {
        batimentId: this.selectedBatiment.id,
        codeType: this.codeIntervention,
        itemType: this.selectedType,
        itemId: this.itemId,
        inAlert: true,
      }).then((response) => {
        // check if equipement is in alert
        let findBlank = false;
        if (this.form.fields.energieAp === null || this.form.fields.energieAv === null
          || this.form.fields.tauxCouverture === null || this.form.fields.usage === null
          || this.form.fields.quantitatifChiffrage === null) {
          findBlank = true;
        }
        if (this.form.fields.quantitatifChiffrage !== null && this.form.fields
          .equipement && this.form.fields
          .referentielValues && this.form.fields
          .referentielValues.option_casIntervention === '6') {
          findBlank = false;
        }
        if (this.form.fields.quantitatifChiffrage !== null && this.form.fields
          .inspection && !this.form.fields
          .equipement) {
          findBlank = false;
        }
        const idInterv = response.id;
        // eslint-disable-next-line no-param-reassign
        delete response.id;
        this.$store.dispatch('interventions/save', {
          data: {
            ...response,
            ...this.form.fields,
            inAlert: findBlank,
          },
          id: idInterv,
        }).then(() => {
          this.equipList.forEach((equip) => {
            this.$store.dispatch('interventions/createInterventionOnBatiment', {
              batimentId: this.selectedBatiment.id,
              codeType: this.codeIntervention,
              itemType: this.selectedType,
              itemId: equip.id,
              inAlert: true,
            }).then((responseLoop) => {
              // check if equipement is in alert
              let findBlankLoop = false;
              if (this.form.fields.energieAp === null || this.form.fields.energieAv === null
                || this.form.fields.tauxCouverture === null || this.form.fields.usage === null
                || this.form.fields.quantitatifChiffrage === null) {
                findBlankLoop = true;
              }
              if (this.form.fields.quantitatifChiffrage !== null && this.form.fields
                .equipement && this.form.fields
                .referentielValues && this.form.fields
                .referentielValues.option_casIntervention === '6') {
                findBlankLoop = false;
              }
              if (this.form.fields.quantitatifChiffrage !== null && this.form.fields
                .inspection && !this.form.fields
                .equipement) {
                findBlankLoop = false;
              }
              const idIntervLoop = responseLoop.id;
              // eslint-disable-next-line no-param-reassign
              delete responseLoop.id;
              this.$store.dispatch('interventions/save', {
                data: {
                  ...responseLoop,
                  ...this.form.fields,
                  inAlert: findBlankLoop,
                },
                id: idIntervLoop,
              });
            });
          });
          this.$store.dispatch('snackbar/displaySnack', { message: 'Intervention créée avec succès !', type: 'succes' });
        });
      }).catch(() => {
        this.$store.dispatch('snackbar/displaySnack',
          {
            message: 'Impossible de sauvegarder l\'intervention.',
            type: 'error',
          });
      }).finally(() => {
        this.$store.dispatch('interventions/fetchAllByBatiment', this.selectedBatiment.id);
        this.majBatimentAlert(this.selectedBatiment.id, 'interventions');
      });
      this.clearList();
      this.$emit('close-sheet');
    },
  },

};
</script>

<style lang="scss" scoped>
.sheet-wrapper {
  padding: 1rem;
  height: 100%;
  overflow: auto;

  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;

  > * {
    width: 100%;
    max-width: 700px;
    text-align: center;
  }
}

.source-choice-buttons {
  align-self: center;
  justify-content: center;
}

.bottom-sheet-footer {
  width: 100%;
  height: 3rem;
  margin-bottom: 0;
  margin-top: auto;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
}

.p-inter {
  font-weight: bold;
  text-align: center;
}

.no-inter {
  font-weight: bold;
  color:red;
}
</style>
