import api from '@/api';

/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
/* eslint no-console: ["error", { allow: ["error", "log"] }] */

// initial state
const state = {
  scenarios: [],
};

const getters = {
  selectedBatimentScenarios: (state, getters, rootState, rootGetters) => {
    const batiment = rootGetters['batiments/selected'];
    if (batiment) {
      return state.scenarios.filter(scenar => scenar.batiment === batiment.id);
    }
    return [];
  },

  selectedSiteScenarios: (state, getters, rootState, rootGetters) => {
    const site = rootGetters['sites/selected'];
    if (site) {
      return state.scenarios.filter(scenar => site.batiments.includes(scenar.batiment));
    }
    return [];
  },
};

// actions
const actions = {
  /**
   * Create a new scenario and add it to the store
   */
  createScenarioOnBatiment({ commit }, batimentId) {
    const newScenario = {
      id: `tempScenar_${Math.random()}`,
      batiment: batimentId,
    };
    commit('addScenarios', [newScenario]);
    return { ...newScenario };
  },

  /**
   * Save a scenario on the server (automatically select POST or PUT) and update the store
   */
  save({ commit }, scenario) {
    return new Promise((resolve, reject) => {
      // if the scenario doesn't exhist on the backend, create it by post
      if (scenario.id.startsWith('tempScenar')) {
        api.scenarios.create(scenario)
          .then((response) => {
            commit('deleteScenario', scenario.id);
            commit('addScenarios', [response]);
            resolve({ ...response });
          })
          .catch((error) => {
            commit('deleteScenario', scenario.id);
            reject(error);
          });
      // else update it by put
      } else {
        api.scenarios.update(scenario)
          .then((response) => {
            commit('addScenarios', [response]);
            resolve({ ...response });
          })
          .catch(error => reject(error));
      }
    });
  },

  /**
   * Fetch all children scenarios of a batiment
   * @param {String} batimentId - id of the parent batiment
   */
  fetchAllByBatiment({ commit }, batimentId) {
    return api.scenarios.getAllByBatiment(batimentId)
      .then(response => commit('addScenarios', response));
  },

  /**
   * Fetch one scenario by it's id fron the server and update the store
   */
  refreshItem({ commit }, scenarioId) {
    return new Promise((resolve) => {
      api.scenarios.get(scenarioId).then((response) => {
        commit('addScenarios', [response]);
        resolve({ ...response.data });
      });
    });
  },

  /**
   * Delete a scenario from the server & from the store
   */
  delete({ commit }, scenario) {
    api.scenarios.delete(scenario.id)
      .then(() => {
        commit('deleteScenario', scenario.id);
      });
  },
};

// mutations
const mutations = {
  addScenarios(state, newScenarios) {
    newScenarios.forEach((newScenario) => {
      const stateIndex = state.scenarios
        .findIndex(stateScenario => stateScenario.id === newScenario.id);
      if (stateIndex >= 0) {
        state.scenarios.splice(stateIndex, 1, newScenario);
      } else {
        state.scenarios.push(newScenario);
      }
    });
  },

  deleteScenario(state, scenarioId) {
    state.scenarios
      .splice(state.scenarios.findIndex(scenario => scenario.id === scenarioId), 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
