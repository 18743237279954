/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  compteursEndpoint,
  clientsEndpoint,
  facturesEndpoint,
  repartitionsEndpoint,
  compteursUrl,
  sitesEndpoint,
} from './apiUrls';

export default {
  db: new PouchDB('compteurs'),
  factureDb: new PouchDB('factures'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch
    const _id = obj._id || obj.id.toString();

    return {
      ...obj,
      _id,
      meta: obj.meta || {},
      id: String(obj.id),
      client: obj.client.replace(`${clientsEndpoint}/`, ''),
      factures: obj.factures.map(facture => facture.replace(`${facturesEndpoint}/`, '')),
      facturesMois: obj.facturesMois,
      facturesAnnees: obj.facturesAnnees,
      repartitionCompteurBatiments: obj.repartitionCompteurBatiments
        .map(repartition => repartition.replace(`${repartitionsEndpoint}/`, '')),
      inAlert: obj.inAlert,
      rcuPrefere: obj.rcuPrefere,
    };
  },

  async create(compteur, compteurId) {
    // on créé un objet uniquement pour la sauvegarde locale si pas de connexion
    const localCompteur = {
      id: compteurId,
      ...compteur,
    };

    const compteurToSave = { ...compteur };
    let savedCompteur = null;

    try {
      // on essaye de sauvegarder via l'API
      const response = await axiosInstance.axiosInstance.post(compteursEndpoint, compteurToSave);
      savedCompteur = this.convertBackendObject(response.data);
    } catch (e) {
      if (e.response
        && e.response.status >= 400
        && e.response.status < 500) {
        throw new Error(e);
      }
      // si la sauvegarde en ligne a échouée, on enregistre les données dans
      // le cache avec le flag 'toCreate' à true afin de relancer la synchro
      // quand on aura à nouveau une connexion
      return pouchUtil.addDoc(localCompteur, this.db, { toCreate: true });
    }

    // si la sauvegarde en ligne a fonctionné
    // si on avait un equipement avec un id temporaire dans pouchDB on le supprime
    if (compteurId.startsWith('temp')) {
      try {
        await pouchUtil.deleteDoc(compteurId, this.db);
      } catch (e) {
        // do nothing
      }
    }
    // enfin on sauvegarde dans pouchDB le nouvel equipement et on le retourne
    return pouchUtil.addDoc(savedCompteur, this.db);
  },

  async update(compteurId, compteur) {
    console.log('compteur', compteur);
    const response = await axiosInstance.axiosInstance.put(`${compteursEndpoint}/${compteurId}`, compteur);
    const compt = this.convertBackendObject(response.data);
    return pouchUtil.addDoc(compt, this.db);
  },

  async deleteFactures(compteurId, factureIds) {
    try {
      try {
        factureIds.facture.forEach(async (factureId) => {
          await pouchUtil.deleteDoc(factureId.toString(), this.factureDb);
        });
      } catch (e) {
        // do nothing
      }
      const response = await axiosInstance.axiosInstance.put(`${compteursEndpoint}/${compteurId}/delete-factures`, factureIds);
      return response.data;
    } catch (e) {
      console.error('compteursApi deleteFactures error : ', e);
      return null;
    }
  },

  async get(compteurId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${compteursEndpoint}/${compteurId}`);
      const compteur = this.convertBackendObject(response.data);

      return pouchUtil.addDoc(compteur, this.db);
    } catch (e) {
      console.error('compteursApi get error :', e);

      return pouchUtil.getDoc(compteurId, this.db);
    }
  },

  /**
   * get all compteurs of the application
   */
  async getAll() {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${compteursEndpoint}`);
      const compteurs = response.data.map(compteur => this.convertBackendObject(compteur));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(compteurs, this.db);
    } catch (e) {
      console.error('compteursApi getAll error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocs(this.db);
    }
  },

  /**
   * get all children compteurs of a client
   * @param {String} clientId - id of the parent client
   */
  async getAllByClient(clientId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${clientsEndpoint}/${clientId}${compteursUrl}`);
      const compteurs = response.data.map(compteur => this.convertBackendObject(compteur));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(compteurs, this.db);
    } catch (e) {
      console.error('compteursApi getAllByClient error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByField('client', clientId, this.db);
    }
  },

  /**
   * get all compteurs assigned to a site
   * @param {String} siteId - id of the site
   */
  async getAllBySite(siteId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${sitesEndpoint}/${siteId}${compteursUrl}`);
      const compteurs = response.data.map(compteur => this.convertBackendObject(compteur));

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(compteurs, this.db);
    } catch (e) {
      console.error('compteursApi getAllBySite error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByField('site', siteId, this.db);
    }
  },

  /**
   * get all children compteurs of a client for offline use
   * @param {String} clientId - id of the parent client
   */
  async fetchOffline(clientId) {
    const response = await axiosInstance.axiosInstance.get(`${clientsEndpoint}/${clientId}${compteursUrl}`);
    const compteurs = response.data.map(compteur => this.convertBackendObject(compteur));
    // if get is successful,  store data into pouchdb and return it
    return pouchUtil.addDocs(compteurs, this.db);
  },

  /**
   * Fetch all compteurs stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocs(this.db);
  },
};
