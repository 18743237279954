/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  equipementsEndpoint,
  batimentsEndpoint,
  inspectionsEndpoint,
  interventionsEndpoint,
  interventionsUrl,
} from './apiUrls';

export default {
  db: new PouchDB('interventions'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch
    const _id = obj._id || obj.id.toString();

    const convertedObject = {
      ...obj,
      _id,
      meta: obj.meta || {},
      id: String(obj.id),
      option: obj.option,
      batiment: obj.batiment ? obj.batiment.replace(`${batimentsEndpoint}/`, '') : '',
      precisions: obj.precisions,
      noteInterne: obj.noteInterne,
      inAlert: obj.inAlert,
    };

    if (obj.equipement) {
      convertedObject.equipement = obj.equipement.replace(`${equipementsEndpoint}/`, '');
    }

    if (obj.inspection) {
      convertedObject.inspection = obj.inspection.replace(`${inspectionsEndpoint}/`, '');
    }
    return convertedObject;
  },

  async create(intervention, interventionId) {
    // on créé un objet uniquement pour la sauvegarde locale si pas de connexion
    const localIntervention = {
      id: interventionId,
      ...intervention,
    };

    // si l'objet est dépendant d'un objet supérieur pas encore créé
    // alors on ne tente même pas une sauvegarde serveur
    // et on le sauvegarde directement sur PouchDB
    if (intervention.batiment.startsWith('temp')) {
      return pouchUtil.addDoc(localIntervention, this.db, { toCreate: true });
    }

    let savedIntervention = null;

    try {
      // on essaye de sauvegarder via l'API
      const response = await axiosInstance.axiosInstance.post(interventionsEndpoint, intervention);
      savedIntervention = this.convertBackendObject(response.data);
    } catch (e) {
      if (e.response
        && e.response.status >= 400
        && e.response.status < 500) {
        throw new Error(e);
      }
      // si la sauvegarde en ligne a échouée, on enregistre les données dans
      // le cache avec le flag 'toCreate' à true afin de relancer la synchro
      // quand on aura à nouveau une connexion
      return pouchUtil.addDoc(localIntervention, this.db, { toCreate: true });
    }

    // si la sauvegarde en ligne a fonctionné
    // si on avait un intervention avec un id temporaire dans pouchDB on le supprime
    if (interventionId.startsWith('temp')) {
      try {
        await pouchUtil.deleteDoc(interventionId, this.db);
      } catch (e) {
        // do nothing
      }
    }

    // enfin on sauvegarde dans pouchDB le nouveau intervention et on le retourne
    return pouchUtil.addDoc(savedIntervention, this.db);
  },

  /* async update(interventionId, intervention) {
    let savedIntervention = null;

    try {
      // on essaye de sauvegarder via l'API
      const response = await axiosInstance.axiosInstance.
      put(`${interventionsEndpoint}/${interventionId}`, intervention);
      savedIntervention = this.convertBackendObject(response.data);
    } catch (e) {
      if (e.response
        && e.response.status >= 400
        && e.response.status < 500) {
        throw new Error(e);
      }
      // si la sauvegarde en ligne a échouée, on enregistre les données dans
      // le cache avec le flag 'toUpdate' à true afin de relancer la synchro
      // quand on aura à nouveau une connexion
      return pouchUtil.addDoc({ id: interventionId, ...intervention }, this.db, { toUpdate: true });
    }

    // si la sauvegarde en ligne à reussie, on sauvegarde dans pouchDB et on retourne le resultat
    return pouchUtil.addDoc(savedIntervention, this.db);
  }, */

  update(interventionId, intervention) {
    return new Promise((resolve, reject) => {
      const interventionToSave = { ...intervention };
      axiosInstance.axiosInstance.put(`${interventionsEndpoint}/${interventionId}`, interventionToSave)
        .then((response) => {
          resolve(this.convertBackendObject(response.data));
        })
        .catch((error) => { reject(error); });
    });
  },

  /**
   * synchronise a intervention with the server, creating or updating it
   */
  async synchronise(intervention) {
    let savedIntervention = null;

    // creation
    if (intervention.meta.toCreate) {
      if (intervention.batiment.startsWith('temp')) {
        throw new Error('parent site not created for this intervention');
      }

      // make a copy of intervention with a removed id field for the POST request
      const interventionToCreate = { ...intervention };
      delete interventionToCreate.id;

      // create intervention on the server
      const response = await axiosInstance.axiosInstance
        .post(interventionsEndpoint, interventionToCreate);
      savedIntervention = this.convertBackendObject(response.data);

      // delete temp intervention in pouch
      if (intervention._id.startsWith('temp')) {
        try {
          await pouchUtil.deleteDoc(intervention._id, this.db);
        } catch (e) {
          // do nothing
        }
      }

      // update intervention childrens with the new id
      // await this.updateChildren(intervention._id, savedIntervention.id);

      // save intervention in pouch and return it
      return pouchUtil.addDoc(savedIntervention, this.db);
    }

    // update
    if (intervention.meta.toUpdate) {
      // update intervention on the server
      const response = await axiosInstance.axiosInstance.put(`${interventionsEndpoint}/${intervention.id}`, intervention);
      savedIntervention = this.convertBackendObject(response.data);

      // save intervention in pouch and return it
      return pouchUtil.addDoc(savedIntervention, this.db, { updated: true });
    }

    throw new Error('no update/create flag on this intervention');
  },

  /**
   * synchronise with the server all interventions that need to be saved
   */
  async synchroniseAll() {
    // get all interventions in PouchDB with meta.create === true or meta.update === true
    const interventionsToSave = await pouchUtil.getAllDocsToSync(this.db);
    return Promise.all(interventionsToSave.map(intervention => this.synchronise(intervention)));
  },

  /**
   * When a newSite is created, if this site has unsaved interventions children
   * update those interventions to replace the tempSiteId wit the new id
   */
  async handleParentCreated(tempBatimentId, newBatimentId) {
    // get all the interventions that are in the site
    const childrens = await pouchUtil.getAllDocsByField('batiment', tempBatimentId, this.db);

    if (childrens.length) {
      // update the children with the new site id
      const updatedChildrens = childrens.map(children => ({
        ...children,
        site: newBatimentId,
      }));
      // save them back on PouchDB
      await this.db.bulkDocs(updatedChildrens);
    }
  },

  async handleLinkCreated(tempId, newId, entity) {
    let childrens;

    if (entity === 'equipement') {
      childrens = await pouchUtil.getAllDocsByField('equipement', tempId, this.db);
    } else if (entity === 'inspection') {
      childrens = await pouchUtil.getAllDocsByField('inspection', tempId, this.db);
    }

    let updatedChildrens;
    if (childrens.length) {
      if (entity === 'equipement') {
        updatedChildrens = childrens.map(children => ({
          ...children,
          equipement: newId,
        }));
      } else if (entity === 'inspection') {
        updatedChildrens = childrens.map(children => ({
          ...children,
          inspection: newId,
        }));
      }

      // save them back on PouchDB
      await this.db.bulkDocs(updatedChildrens);
    }
  },


  /**
   * delete a intervention from pouchDB and the server
   */
  async delete(InterventionId) {
    // delete the intervention from pouchDB
    await pouchUtil.deleteDoc(InterventionId, this.db);
    // if the delete from pouchDB is sucessfull, delete from the server
    return axiosInstance.axiosInstance.delete(`${interventionsEndpoint}/${InterventionId}`);
  },

  /**
   * get one intervention by his id
   * @param {String} interventionId - the id of the intervention to get
   */
  async get(interventionId) {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${interventionsEndpoint}/${interventionId}`);
      const intervention = this.convertBackendObject(response.data);

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDoc(intervention, this.db);
    } catch (e) {
      console.error('interventionsApi get error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getDoc(interventionId, this.db);
    }
  },

  /**
   * get all interventions of the application
   */
  async getAll() {
    // first try to fetch data from API
    try {
      const response = await axiosInstance.axiosInstance.get(`${interventionsEndpoint}`);
      const interventions = response.data.map(
        intervention => this.convertBackendObject(intervention),
      );

      // if get is successful,  store data into pouchdb and return it
      const results = await pouchUtil.addDocs(interventions, this.db);

      return results;
    } catch (e) {
      console.error('interventionsApi getAll error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocs(this.db);
    }
  },

  /**
   * get all children interventions of a batiment
   * @param {String} batimentId - id of the parent batiment
   */
  async getAllByBatiment(batimentId) {
    try {
      const response = await axiosInstance.axiosInstance.get(`${batimentsEndpoint}/${batimentId}${interventionsUrl}`);
      const interventions = response.data.map(
        intervention => this.convertBackendObject(intervention),
      );

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDocs(interventions, this.db);
    } catch (e) {
      console.error('interventionsApi getAllByBatiment error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getAllDocsByField('batiment', batimentId, this.db);
    }
  },

  /**
   * get all children interventions of a batiment for offline use
   * @param {String} batimentId - id of the parent site
   */
  async fetchOffline(batimentId) {
    const response = await axiosInstance.axiosInstance.get(`${batimentsEndpoint}/${batimentId}${interventionsUrl}`);
    const interventions = response.data.map(
      intervention => this.convertBackendObject(intervention),
    );
    return pouchUtil.addDocs(interventions, this.db);
  },

  /**
   * Fetch all interventions stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocs(this.db);
  },

  /**
   * delete all children interventions linked to an equipment
   * for offline use (local storage)
   * @param {string} equipementId - id of the equipment
   * @returns {boolean|Error} return TRUE if success else Error
   */
  async deleteByEquipementOffline(equipementId) {
    try {
      const interventions = await pouchUtil.getAllDocsByField('equipement', equipementId, this.db);
      interventions
        .forEach(intervention => pouchUtil.deleteDoc(intervention.id, this.db));
      return true;
    } catch (e) {
      throw new Error(`interventionApi.deleteByEquipementOffline : ${e}`);
    }
  },
};
