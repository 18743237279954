<template>
  <v-bottom-sheet
    v-model="sheet"
    fullscreen
  >
   <v-sheet class="sheet-wrapper">
      <h1>{{ title }}</h1>
      <v-progress-circular
        v-if="loading"
        :indeterminate="loading"
        color="primary"
        :width="5"
      >
      </v-progress-circular>
      <div v-if="!loading">
        <div class="grid-container"
          v-for="(batiment, i) in batsSite"
          :key="'bat' + i"
        >
          <div class="grid-element">
            {{ batiment.nom }} :
          </div>
          <div class="grid-element-second">
            <!-- TODO modifier gestion bati ID -->
            <v-text-field
              v-model="form[batiment.id]"
              :value="form[batiment.id]"
              type="number"
              suffix="%"
              placeholder="Saisissez la répartition"
            >
            </v-text-field>
          </div>
        </div>
        <div class="grid-mode-repartition grid-container">
          <div class="grid-element">
            Mode de repartition
          </div>
          <v-select
            :items="repartitionModes"
            v-model="selectedRepartitionMode"
            :value="selectedRepartitionMode"
            clearable
            attach
          >
          </v-select>
        </div>
      </div>

      <!-- close and save buttons -->
        <div class="bottom-sheet-footer">
          <v-btn
            class="action-button"
            text
            color="primary"
            @click="closeSheet()"
          >
            Annuler
          </v-btn>

          <v-btn
            class="action-button"
            depressed
            large
            color="primary"
            @click.native="confirm()"
            :loading="saving"
            :disabled="disabledBtn"
          >
            Répartir
          </v-btn>
        </div>
   </v-sheet>
  </v-bottom-sheet>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import UpdateItemAlertMixin from '@/mixins/UpdateItemAlertMixin';

export default {
  props: {
    sheet: {
      type: Boolean,
    },
    compteur: {
      type: Object,
    },
  },
  mixins: [
    UpdateItemAlertMixin,
  ],

  data() {
    return {
      saving: false,
      form: [],
      loading: true,
      repartitionModes: ['Relevés compteurs', 'Relevés des sous-compteurs', 'Surfacique', 'Déperditions'],
      selectedRepartitionMode: '',
    };
  },

  computed: {
    ...mapGetters({
      selectedSite: 'sites/selected',
    }),
    ...mapState({ selectedCompteur: 'selectedCompteur' }),
    ...mapState('batiments', ['batiments']),
    ...mapState('repartitions', ['repartitions']),

    batsSite() {
      return this.batiments.filter((batiment => batiment.site === this.selectedSite.id));
    },

    title() {
      if (this.compteur) {
        return `Ventilation des bâtiments sur ${this.compteur.numeroCompteur} - ${this.compteur.energieLibelle}`;
      }
      return 'Aucun compteur sélectionné';
    },

    disabledBtn() {
      return this.selectedRepartitionMode === '' || this.selectedRepartitionMode === undefined;
    },
  },

  methods: {
    closeSheet() {
      this.$emit('close-sheet');
    },

    // Populate existing repartition compteur for each batiment on this site
    populateRepartition() {
      if (this.selectedCompteur) {
        this.$store.dispatch('repartitions/fetchRepartitionByIdCompteur', this.selectedCompteur.id)
          .then((repartitionsCompteur) => {
            // for each bat add his repartition in form
            this.batsSite.forEach((bat) => {
              if (repartitionsCompteur) {
                const repartition = repartitionsCompteur.filter(rep => rep.batiment === bat.id)[0];
                this.form[bat.id] = repartition ? repartition.repartition : null;
              } else {
                this.form[bat.id] = null;
              }
            });
            this.selectedRepartitionMode = this.selectedCompteur.modeRepartition;
          }).finally(() => { this.loading = false; });
      }
    },

    // confirm btn
    confirm() {
      this.saveForm().then(() => {
        this.$store.dispatch('snackbar/displaySnack', { message: 'Ventilation sauvegardée avec succès', type: 'succes' });
        this.$emit('refresh-batiments');
      }).finally(() => {
        this.saving = false;
        this.closeSheet();
      });
    },

    // Save or create repartition to backEnd
    saveForm() {
      return new Promise(async (resolve, reject) => {
        this.saving = true;
        const promiseTab = [];
        this.batsSite.forEach(async (bat) => {
          const repartitionObj = this.repartitions
            .find(rep => (rep.batiment === bat.id)
            && (rep.compteur === this.selectedCompteur.id));
          const repartitionRate = parseFloat(this.form[bat.id]);
          const isNew = typeof repartitionObj === 'undefined';
          const isValid = !(repartitionRate === 0 || Number.isNaN(repartitionRate));
          /* in case of repartition does not exist, create and commit it in DB,
          otherwise just update and push */
          if (isNew && isValid) {
            try {
              promiseTab[bat.id] = this.createRepartition(bat);
            } catch (error) {
              reject(error);
            }
          }

          if (!isNew) {
            if (isValid) {
              const repartitionCopy = { ...repartitionObj, repartition: repartitionRate };
              try {
                promiseTab[bat.id] = this.pushSave(repartitionCopy);
              } catch (error) {
                reject(error);
              }
            } else { // delete if not valid and not new
              try {
                promiseTab[bat.id] = await this.$store.dispatch('repartitions/delete', repartitionObj).then(() => {
                  this.majBatimentAlert(bat.id, 'repartitions');
                });
              } catch (error) {
                reject(error);
              }
            }
          }
        });
        await Promise.all(promiseTab);
        const myCompteur = {
          ...this.selectedCompteur,
          modeRepartition: this.selectedRepartitionMode,
        };
        try {
          await this.$store.dispatch('compteurs/save', myCompteur);
        } catch (error) {
          reject(error);
        }
        this.$store.dispatch('sites/refreshItem', this.selectedSite.id);
        resolve();
      });
    },

    // create new repartition object and push it into DB
    createRepartition(bat) {
      return new Promise(async (resolve, reject) => {
        try {
          const repartitionObj = await this.$store.dispatch('repartitions/createNew');
          repartitionObj.batiment = bat.id;
          repartitionObj.compteur = this.selectedCompteur.id;
          repartitionObj.repartition = parseFloat(this.form[bat.id]);
          repartitionObj.inAlert = true;
          await this.pushSave(repartitionObj).then(() => {
            this.majBatimentAlert(bat.id, 'repartitions');
          });
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },

    // Saving in database repartition obj
    pushSave(repartitionObj) {
      const repartitionToSave = { ...repartitionObj };
      delete repartitionToSave.id;
      return this.$store.dispatch('repartitions/save',
        {
          data: repartitionToSave,
          id: repartitionObj.id,
        });
    },
  },

  watch: {
    // each time sheet is open populate existing repartition
    sheet() {
      this.loading = true;
      this.populateRepartition();
    },
  },
};
</script>
<style lang="scss" scoped>
  .sheet-wrapper {
    padding: 1rem;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }

  .bottom-sheet-footer {
    width: 100%;
    height: 3rem;
    margin-bottom: 0;
    margin-top: auto;

    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
  }

  .grid-container{
    display: grid;
    grid-template-columns: 15rem 15rem;
    border-bottom: 1px solid #dddddd;
    grid-row-gap: 1rem;
    width: 30rem;

    > * {
      padding: 1rem;
    }
  }

  .grid-mode-repartition{
    border: none;
    margin-top: 1rem;
  }

  .grid-element{
    grid-column-start: 1;
    grid-column-end: 2;
    align-self: center;
    text-align: end;
  }

  .grid-element-second{
    width: 5rem;
  }

</style>
