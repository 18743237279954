<template>
  <div>
    <!--<v-btn
      text
      icon
      color="white"
    >
      <v-icon>mdi-account</v-icon>
    </v-btn>-->
  <v-menu
    bottom
    min-width="200px"
    rounded
    offset-y
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        x-large
        v-on="on"
      >
        <v-avatar
          color="tertiary"
          size="40"
        >
          <span class="white--text text-h7">
            {{ `${user.givenName.charAt(0)}${user.familyName.charAt(0)}`}}
          </span>
        </v-avatar>
      </v-btn>
    </template>
    <v-card>
      <v-list-item-content class="justify-center">
        <div class="mx-auto text-center">
          <h3>{{ `${user.givenName} ${user.familyName}`}}</h3>
          <p class="text-caption mt-1">
            {{user.username}}
          </p>
          <v-divider class="my-3"></v-divider>
          <v-btn
            depressed
            rounded
            text
            @click="$emit('show-user-settings');"
          >
            Voir profil
          </v-btn>
          <v-divider class="my-3"></v-divider>
          <v-btn
            depressed
            rounded
            text
            @click="$emit('log-out');"
          >
            Se déconnecter
          </v-btn>
        </div>
      </v-list-item-content>
    </v-card>
  </v-menu>
  </div>

</template>

<script>

export default {
  props: ['user'],
};
</script>

<style lang="scss" scoped>

</style>
