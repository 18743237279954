<template>
  <div class="list-wrapper">
    <p class="category-select">Catégorie sélectionnée: <u>{{ selectedCategorie.nom }}</u></p>
    <div class="type-wrapper">

      <p v-if="categorieVide">Aucun type n'est associé à cette catégorie.</p>
      <v-sheet v-if="loading"
               color="transparent"
               class="message-sheet"
      >
      <v-progress-circular
        v-if="loading"
        :size="70"
        :width="7"
        color="primary"
        indeterminate
      ></v-progress-circular>
      </v-sheet>
      <CategorieCard
        v-for="type in typesByCategorie"
        :key="type.id"
        @click.native="selectType(type)"
      >
        <template v-slot:title>
          {{ type.nom }}
        </template>
        <template v-slot:content>
        </template>
      </CategorieCard>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import CategorieCard from './CategorieCard.vue';

export default {
  data() {
    return {
      loading: false,
      equipementTypes: [],
      activeTypes: [],
    };
  },
  computed: {
    ...mapState('equipements', { types: 'types' }),
    ...mapState('equipements', { categories: 'categories' }),
    ...mapState(['selectedCategorie', 'selectedType']),
    ...mapGetters({
      selectedMission: 'missions/selected',
    }),

    typesByCategorie() {
      return this.types.filter(
        types => types.categorie === this.selectedCategorie.id
        && types.mission === this.selectedMission.id,
      );
    },
    categorieVide() {
      return !this.loading && (!this.typesByCategorie || !this.typesByCategorie.length);
    },
  },
  async created() {
    if (!this.typesByCategorie.length) {
      this.loading = true;
      await this.$store.dispatch('equipements/fetchAllTypes', { missionId: this.selectedMission.id, majRef: false });
      this.loading = false;
    }
  },
  components: {
    CategorieCard,
  },
  methods: {
    selectType(type) {
      this.$store.dispatch('selectType', type);
    },
  },
};
</script>

<style lang="scss" scoped>
  .list-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
  }

  .type-wrapper {
    flex-grow: 1;

    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
  }
  .header {
  background: tomato;
  }
  .category-select {
    font-weight: 300;
    font-size: 23px;
    text-align: center;
  }

  .selected {
    border: 2px solid;
    border-color: #2D4388;
  }
  .message-sheet {
    width: 100px;
    height: 100px;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;

    margin: auto;
    font-weight: 300;
    font-size: 1.6rem;
    text-align: center;
    background-color: transparent !important;
    border: 1px solid rgb(226, 226, 226);
    border-radius: 15px;
  }
</style>
