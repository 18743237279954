import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import { meEndpoint } from './apiUrls';

export default {
  db: new PouchDB('me'),

  convertBackendObject(obj) {
    // set an _id attribute for pouch
    const _id = obj._id || obj.id.toString();

    return {
      _id,
      meta: obj.meta || {},
      id: String(obj.id),
      email: obj.email,
      username: obj.username,
      roles: obj.roles,
      familyName: obj.familyName,
      givenName: obj.givenName,
      authorizedMissions: obj.authorizedMissions,
      lastConnection: obj.lastConnection,
    };
  },

  /**
   * get me
   */
  async get() {
    try {
      // first try to fetch data from API
      const response = await axiosInstance.axiosInstance.get(`${meEndpoint}`);
      const me = this.convertBackendObject(response.data);

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDoc(me, this.db);
    } catch (e) {
      // if get from server fail
      // check if we have data in pouchdb and return it
      // return pouchUtil.addDoc(this.db);
      return null;
    }
  },

  /**
   * destroy 'Me' from pouchDB and the server
   */
  async destroy() {
    // destroy db Me from pouchDB
    return pouchUtil.destroyDb(this.db).then(() => {
      this.db = new PouchDB('me');
    });
  },

  /**
   * Fetch me on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocs(this.db);
  },
};
