<template>
  <div class="list-wrapper">

    <div class="list-title-block">
      <div class="list-title"></div>
    </div>

    <v-expansion-panels
      accordion
      class="batiments-list"
    >
      <v-expansion-panel
        v-for="mission in clientMissions"
        :key="`mission_${mission.id}`"
      >
        <v-expansion-panel-header>
          <div class="header-wrapper">
            <div class="header-title">{{ mission.nom }}</div>
            <div class="header-scenario-name"></div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <SiteSelectTable
            :mission="mission"
            :patrimoineSites="sitesSelectedPatrimoines"
            @new-selection="associateSite($event)"
          >
          </SiteSelectTable>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import SiteSelectTable from './SiteSelectTable.vue';

export default {
  computed: {
    ...mapState({
      sitesSelectedPatrimoines: 'sitesSelectedPatrimoines',
    }),
  },

  components: {
    SiteSelectTable,
  },
  props: ['clientMissions', 'patrimoine'],

  async created() {
    this.loading = true;
    this.$store.dispatch('changeSitesSelectedPatrimoines', this.patrimoine.sites);
    this.loading = false;
  },

  methods: {
    associateSite(event) {
      this.$store.dispatch('changeSitesSelectedPatrimoines', event.map(pat => pat.id));
      this.$emit('associate', this.sitesSelectedPatrimoines);
    },
  },
};
</script>

<style lang="scss" scoped>
  .list-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    height: 100%;

    .list-title-block {
      flex: 0 0 auto;
      padding: 0.5rem;
      height: 3.5rem;

      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;


      .list-title {
        font-weight: 300;
        font-size: 1.4rem;
      }
    }
    .header-wrapper {
      display: flex;
      flex-flow: row wrap;
      justify-content: flex-start;
      align-items: center;

      width: 100%;

      .header-scenario-name {
        margin-left: auto;
        margin-right: 1rem;
        width: 7rem;
        text-align: right;

        color: $app-blue;
      }
    }
  }
</style>
