<template>
  <div class="synchronize-view">
    <h2 class="view-title">Objets à synchroniser</h2>

    <v-list class="sync-list" v-if="syncList.length">
      <template v-for="(item) in syncList">
        <template v-if="item.divider">
          <v-subheader
            :key="`subHeader_${item.title}` "
          >
            {{ item.title }} ({{item.counter}})
          </v-subheader>
          <v-divider
            :key="`divider_${item.title}` "
          ></v-divider>
        </template>

        <v-list-item
          v-else
          :key="item.id"
        >
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>
                <template v-if="item.type !== 'pictures'">
                  <a class="wrapper-title-link" @click="goToItem(item)"
                    >{{item.name}}<v-icon color="primary">mdi-link-variant</v-icon></a>
                </template>
                <template v-else>parent : {{item.parentTypeLabel}} - {{item.parentName}}</template>
              </v-list-item-title>
              <v-list-item-subtitle
                v-if="item.errorMessage"
                class="error-message"
              >
                {{item.errorMessage}}
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-icon v-if="item.action === 'create'"
                color="grey lighten-1"
              >
                mdi-alpha-c-circle
              </v-icon>
              <v-icon v-else
                color="grey lighten-1"
              >
                mdi-alpha-m-circle
              </v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list-item>

      </template>
    </v-list>

    <v-sheet v-else
      elevation="1"
      rounded
      height="64"
      width="250"
      class="message-card"
    >
      Aucun objet à synchroniser
    </v-sheet>

    <v-fab-transition>
      <v-btn
        color="primary"
        fab
        large
        class="fab-sync-button"
        :loading="syncing"
        @click="synchronise()"
      >
        <v-icon>mdi-cloud-refresh</v-icon>
      </v-btn>
    </v-fab-transition>
    <BottomSheetEditItem
      v-if="showEditSheet"
      :sheet="showEditSheet"
      :item="selectedItem"
      :typeItem="selectedItemType"
      @close-sheet="handleOnClose()"
    >
    </BottomSheetEditItem>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BottomSheetEditItem from '@/components/BottomSheetEditItem.vue';

export default {
  data() {
    return {
      syncing: false,
      showEditSheet: false,
    };
  },
  components: {
    BottomSheetEditItem,
  },
  computed: {
    ...mapState(['picturesToSync']),
    ...mapState(['linkedEquipemetsToSync']),
    ...mapState({
      linkedEquipemetsToSync: state => state.linkedEquipemetsToSync,
      picturesToSync: state => state.picturesToSync,
      sites: state => state.sites.sites,
      batiments: state => state.batiments.batiments,
      equipements: state => state.equipements.equipements,
      inspections: state => state.inspections.inspections,
      selectedItemType: state => state.selectedItemType,
    }),
    ...mapGetters({
      batimentsToSync: 'batiments/toSync',
      niveauxToSync: 'niveaux/toSync',
      equipementsToSync: 'equipements/toSync',
      inspectionsToSync: 'inspections/toSync',
      selectedEquipement: 'equipements/selected',
      selectedItem: 'selectedItem',
    }),

    batimentsList() {
      if (this.batimentsToSync.length) {
        return [
          {
            divider: true,
            title: 'Bâtiments',
            counter: this.batimentsToSync.length,
          },
          ...this.batimentsToSync.map(bat => ({
            id: bat.id,
            action: bat.meta.toCreate ? 'create' : 'update',
            name: bat.nom,
            type: 'batiments',
            error: bat.meta.error,
            errorMessage: bat.meta.errorMessage || null,
          })),
        ];
      }
      return [];
    },

    niveauxList() {
      if (this.niveauxToSync.length) {
        return [
          {
            divider: true,
            title: 'Niveaux',
            counter: this.niveauxToSync.length,
          },
          ...this.niveauxToSync.map(niv => ({
            id: niv.id,
            action: niv.meta.toCreate ? 'create' : 'update',
            name: niv.nom,
            type: 'niveaux',
            error: niv.meta.error,
            errorMessage: niv.meta.errorMessage || null,
          })),
        ];
      }
      return [];
    },

    equipementsList() {
      if (this.equipementsToSync.length) {
        return [
          {
            divider: true,
            title: 'Equipements',
            counter: this.equipementsToSync.length,
          },
          ...this.equipementsToSync.map(eqp => ({
            id: eqp.id,
            action: eqp.meta.toCreate ? 'create' : 'update',
            name: eqp.nom,
            type: 'equipements',
            error: eqp.meta.error,
            errorMessage: eqp.meta.errorMessage || null,
          })),
        ];
      }
      return [];
    },

    inspectionsList() {
      if (this.inspectionsToSync.length) {
        return [
          {
            divider: true,
            title: 'Inspections',
            counter: this.inspectionsToSync.length,
          },
          ...this.inspectionsToSync.map(insp => ({
            id: insp.id,
            action: insp.meta.toCreate ? 'create' : 'update',
            name: insp.nomType,
            type: 'inspections',
            error: insp.meta.error,
            errorMessage: insp.meta.errorMessage || null,
          })),
        ];
      }
      return [];
    },

    pictureList() {
      if (this.picturesToSync.length) {
        return [
          {
            divider: true,
            title: 'Images',
            counter: this.picturesToSync.length,
          },
          ...this.picturesToSync.map((pic) => {
            const parentInfo = this.getParentInfo(pic.itemType, pic.itemId);
            return {
              id: pic.id,
              action: 'create',
              type: 'pictures',
              error: pic.mete ? pic.meta.error : false,
              errorMessage: pic.meta ? pic.meta.errorMessage : null,
              parentTypeLabel: parentInfo.parentTypeLabel,
              parentName: parentInfo.parentName,
            };
          }),
        ];
      }
      return [];
    },
    lindedEquipementList() {
      if (this.linkedEquipemetsToSync.length) {
        return [
          {
            divider: true,
            title: 'Equipements liés',
            counter: this.linkedEquipemetsToSync.length,
          },
          ...this.linkedEquipemetsToSync.map(eqp => ({
            id: eqp.id,
            action: eqp.meta.toCreate ? 'create' : 'update',
            name: eqp.nom,
            type: 'equipementLie',
            error: eqp.meta.error,
            errorMessage: eqp.meta.errorMessage || null,
          })),
        ];
      }
      return [];
    },

    syncList() {
      return [
        ...this.batimentsList,
        ...this.niveauxList,
        ...this.equipementsList,
        ...this.inspectionsList,
        ...this.pictureList,
      ];
    },
  },

  created() {
    this.$store.dispatch('updatePicturesToSync');
  },

  methods: {
    async goToItem(item) {
      if (item.type === 'equipements') {
        await this.$store.dispatch('changeSelectedItem', { type: item.type, id: item.id }).then(() => {
          this.showEditSheet = true;
        });
      }
    },
    handleOnClose() {
      this.showEditSheet = false;
      this.$emit('close-sheet');
      this.closeModal();
    },
    async synchronise() {
      if (!this.syncing) {
        this.syncing = true;
        await this.$store.dispatch('synchronise');
        this.syncing = false;
      }
    },

    getParentInfo(itemType, itemId) {
      let parentTypeLabel = '';
      let parentName = '';
      let parent = null;
      switch (itemType) {
        case 'sites':
          parentTypeLabel = 'site';
          parent = this.sites.find(site => site.id === itemId);
          parentName = parent ? parent.nom : 'non trouvé';
          break;
        case 'batiments':
          parentTypeLabel = 'bâtiment';
          parent = this.batiments.find(bat => bat.id === itemId);
          parentName = parent ? parent.nom : 'non trouvé';
          break;
        case 'equipements':
          parentTypeLabel = 'équipement';
          parent = this.equipements.find(eqp => eqp.id === itemId);
          parentName = parent ? parent.nom : 'non trouvé';
          break;
        case 'inspections':
          parentTypeLabel = 'inspection';
          parent = this.inspections.find(insp => insp.id === itemId);
          parentName = parent ? parent.nomType : 'non trouvé';
          break;
        default:
          parentTypeLabel = 'inconnu';
          parentName = 'N/A';
      }

      return {
        parentTypeLabel,
        parentName,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
 .synchronize-view {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    padding: 1rem;
 }

 .view-title {
   margin-bottom: 1rem;
 }

 .error-message {
   color: $nav-red !important;
 }

 .sync-list {
   width: 100%;
   max-width: 800px;
   margin-bottom: 8rem;
 }

 .fab-sync-button {
    position: fixed;
    bottom: 4rem;
    right: 3rem;
  }

  .message-card {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
  }
</style>
