<template>
  <div class="table-wrapper">
    <v-data-table
      class="programme-table"
      v-model="programmesSelected"
      :items="mappedSiteProgrammes"
      :headers="headers"
      :loading="loading"
      single-select
      show-select
      disable-pagination
      hide-default-footer
      @item-selected="touched = true"
    >
    </v-data-table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    site: {
      type: Object,
      default: null,
    },
    initialSelection: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      filter: 'tous',
      headers: [
        { text: 'nom', value: 'nom' },
        { text: 'investissemnent (EUR HT)', value: 'invest' },
        { text: 'gain EF (%)', value: 'gainEf' },
        { text: 'TRI (brut)', value: 'tri' },
      ],
      programmesSelected: [],
      touched: false,
      loading: false,
    };
  },

  computed: {
    ...mapGetters({
      programmes: 'programmes/selectedPatrimoineProgrammes',
    }),

    siteProgrammes() {
      return this.programmes.filter(prog => prog.site === this.site.id);
    },

    mappedSiteProgrammes() {
      return this.siteProgrammes.map(prog => this.mapProgramme(prog));
    },

  },

  async created() {
    this.loading = true;
    await this.$store.dispatch('programmes/fetchAllBySite', this.site.id);
    this.loading = false;

    // init selection
    if (this.initialSelection) {
      this.programmesSelected = [this.mapProgramme(this.initialSelection)];
    }
  },

  watch: {
    programmesSelected() {
      const newSelectedId = this.programmesSelected.length ? this.programmesSelected[0].id : null;

      this.$emit('new-selection', {
        programme: this.siteProgrammes.find(bat => bat.id === newSelectedId),
        touched: this.touched,
      });
    },
  },

  methods: {
    mapProgramme(programme) {
      return {
        id: programme.id,
        nom: programme.nom,
        batiment: programme.site,
        invest: programme.calculations.investissementHt,
        gainEf: programme.calculations.gainConsommationEfRelatif,
        tri: programme.calculations.tempsRetourBrut,
      };
    },
  },
};
</script>
