<template>
  <ValidationProvider
  :name="formField.name"
  :rules="formField.rules"
  v-slot="{ errors }">
    <div>
      <v-autocomplete
        v-bind="fieldOptions"
        :items="formField.options"
        item-text="name"
        item-value="name"
        color="blue-grey lighten-2"
        :error-messages="errors"
        v-model="localValue"
        @change="$emit('change', localValue)"
        attach
      >
        <template v-slot:label>
          <slot name="field-label"></slot>
        </template>

        <template
          slot="item"
          slot-scope="data"
        >
          <template>
              <v-list>{{data.item.name}}</v-list>
          </template>
        </template>
      </v-autocomplete>
    </div>
  </ValidationProvider>
</template>

<script>

import { ValidationProvider } from 'vee-validate';

export default {
  name: 'menu-select-field',
  components: {
    ValidationProvider,
  },
  props: {
    commonOptions: Object,
    defaultValue: null,
    formField: Object,
  },
  data() {
    return {
      localValue: this.defaultValue,
    };
  },
  computed: {
    fieldOptions() {
      return {
        ...this.commonOptions,
        placeholder: this.formField.placeholder,
      };
    },
  },
  watch: {
    defaultValue(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>

<style scoped>
  .title-menu-select {
    color: black;
  }
</style>
