<template>
  <BaseCard :loading="loading">
    <template v-slot:title>
      Répartition par énergie
    </template>
    <template v-slot:content>
      <v-data-table
        :headers="headers"
        hide-default-footer
        :items="eneregiesBat"
        class="energie-table"
        dense
      >
        <template
          v-slot:[`item.textEnergy`]="{ item }">
          <div v-if="item.textEnergy">
            <v-chip :color="getColor(item.energie)">{{item.textEnergy}}</v-chip></div>
        </template>

      </v-data-table>
    </template>
  </BaseCard>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import ColorEnergieMixing from '@/mixins/ColorEnergieMixing';
import BaseCard from '../BaseCard.vue';

export default {
  components: { BaseCard },
  mixins: [
    ColorEnergieMixing,
  ],
  props: ['batiment', 'loading'],
  data() {
    return {
      headers: [
        {
          text: 'Energie', value: 'textEnergy', width: '25%', align: 'end',
        },
        { text: 'Consommations (kWh)', value: 'conso', align: 'end' },
        { text: 'Coût (€TTC)', value: 'cout', align: 'end' },
        { text: 'PU (c€TTC/kWh)', value: 'pu', align: 'end' },
      ],
    };
  },

  computed: {
    ...mapGetters('missions', { selectedMission: 'selected' }),
    ...mapState('listeOptions', { listeOptions: 'all' }),

    listeOptionsEnergies() {
      return this.listeOptions.filter(c => c.codeType === 'energie');
    },

    eneregiesBat() {
      if (this.batiment
      && this.batiment.calculations.coutTtcEnergiesAnneeReference
      && this.batiment.calculations.consommationEfEnergiesAnneeReference) {
        return Object
          .keys(this.batiment.calculations.consommationEfEnergiesAnneeReference)
          .map(energy => ({
            textEnergy: this.getLibelleEnergie(energy),
            conso: parseFloat(this.batiment.calculations.consommationEfEnergiesAnneeReference[energy]).toLocaleString('fr'),
            cout: parseFloat(this.batiment.calculations.coutTtcEnergiesAnneeReference[energy]).toLocaleString('fr'),
            energie: energy,
            pu: this.batiment.calculations.prixUnitaireEnergiesCents[energy],
          }));
      }
      return [];
    },
  },

  methods: {
    getLibelleEnergie(codeEnergie) {
      if (this.listeOptionsEnergies) {
        const energie = this.listeOptionsEnergies.find(option => option.codeOption === codeEnergie);
        return (energie) ? energie.libelle : '';
      }
      return '';
    },
    getColor(codeEnergie) {
      return this.getColorEnergie(codeEnergie);
    },
  },
  created() {
    this.$store.dispatch('listeOptions/fetchAllByType',
      {
        missionId: this.selectedMission.id,
        codeType: 'energie',
      });
  },
};
</script>

<style lang="scss" scoped>
  .energie-table {
    border: 1px solid rgba(0, 0, 0, .1);
    margin-left: auto;
    margin-right: auto;
  }
</style>
