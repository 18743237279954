export default {
  methods: {
    getColorEnergie(color) {
      switch (color) {
        case 'electricite-energie':
          return '#FF0000';
        case 'gaznaturel-energie':
          return '#FFC000';
        case 'fioul-energie':
          return '#A6A6A6';
        case 'propane-energie':
          return '#B87C0A';
        case 'boispelletsgranules-energie':
          return '#00AF50';
        case 'boisplaquette-energie':
          return '#92D14F';
        case 'boisbuche-energie':
          return '#538234';
        case 'reseaudechaleur-energie':
          return '#5A9AD7';
        case 'reseaudefroid-energie':
          return '#87CEFA';
        case 'electricitereventepinf3-energie':
          return 'C0C0C1';
        case 'electricitereventepinf9-energie':
          return 'A8A8A9';
        case 'electricitereventepinf36-energie':
          return '909091';
        case 'electricitereventepinf100-energie':
          return '787879';
        case 'electricitereventepinf500-energie':
          return '606060';
        case 'electricitereventepinf2500-energie':
          return '484848';
        case 'electriciterefroidissement-energie':
          return 'E9967A';
        case 'electricitechauffage-energie':
          return 'F08080';
        case 'electriciteecs-energie':
          return 'CD5C5C';
        case 'electriciteautresusages-energie':
          return 'DC143C';
        case 'electricitereventepsup2500-energie':
          return '303030';
        default:
          return '#FEFEFE';
      }
    },
  },
};
