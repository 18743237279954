<template>
  <div class="table-wrapper">

    <div class="table-title-block">
      <div class="table-title">Sites</div>
      <div class="table-filter">
        <v-select
          v-model="filter"
          :items="['toutes', 'selectionées']"
          label="Voir"
          outlined
          hide-details
          dense
          attach
        ></v-select>
      </div>
    </div>
    <p v-if="loadingSites">
      <v-skeleton-loader
        type="table"
      >
      </v-skeleton-loader>
    </p>
    <v-data-table
      class="sites-table"
      v-model="sitesSelected"
      :items="missionSites"
      :headers="headers"
      show-select
      disable-pagination
      hide-default-footer
      @item-selected="touched = true"
      @toggle-select-all=" touched = true"
    >
    </v-data-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: ['mission', 'patrimoineSites'],

  data() {
    return {
      loadingSites: false,
      filter: 'toutes',
      headers: [
        { text: 'source', value: 'source' },
        { text: 'Nom', value: 'nom' },
        { text: 'Code site', value: 'codeSite' },
        { text: 'Ville', value: 'ville' },
        { text: 'Code Postal', value: 'codePostal' },
      ],
      sitesSelected: [],
      touched: false,
    };
  },

  computed: {
    ...mapState('sites', ['sites']),

    missionSites() {
      if (this.mission) {
        return this.sites.filter(site => site.mission === this.mission.id);
      }
      return [];
    },
  },

  async created() {
    this.loadingSites = true;
    await this.$store.dispatch('sites/fetchAllByMission', this.mission.id);
    if (this.patrimoineSites.length) {
      this.sitesSelected = this.sites
        .filter(site => this.patrimoineSites.includes(site.id));
    }
    this.loadingSites = false;
  },

  watch: {
    patrimoineSites() {
      this.sitesSelected = this.sites
        .filter(site => this.patrimoineSites.includes(site.id));
    },

    sitesSelected() {
      if (this.touched === true) {
        this.$emit('new-selection', this.sitesSelected);
        this.touched = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .table-wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    height: 100%;
    max-height: 400px;

    .table-title-block {
      flex: 0 0 auto;
      padding: 0.5rem;
      height: 3.5rem;

      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;


      .table-title {
        font-weight: 300;
        font-size: 1.4rem;
      }

      .table-filter {
        margin-right: 0;
        margin-left: auto;
        width: 9.5rem;
      }
    }

    .sites-table {
      flex: 1 1 auto;
      height: 100%;
      padding-bottom: 5rem;
      overflow-y: auto;
    }
  }
</style>
