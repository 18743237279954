
export default {
  methods: {
    resizeImageMx(file, maxWidth, maxHeight) {
      return new Promise((resolve) => {
        const reader = new FileReader();
        const fileName = file.name;
        let image;

        reader.readAsDataURL(file);
        reader.onload = (event) => {
          const img = new Image();
          img.src = event.target.result;

          img.onload = () => {
            const elem = document.createElement('canvas');

            // resize image to a 800px max, keeping aspect ratio
            const ratio = Math.min(maxWidth / img.width, maxHeight / img.height);
            elem.width = img.width * ratio;
            elem.height = img.height * ratio;
            const ctx = elem.getContext('2d');
            ctx.drawImage(img, 0, 0, elem.width, elem.height);

            // create file for the resized image and return it
            ctx.canvas.toBlob((blob) => {
              image = new File([blob], fileName, {
                type: 'image/jpeg',
                lastModified: Date.now(),
              });
              resolve(image);
            }, 'image/jpeg', 1);
          };
        };
      });
    },
  },
};
