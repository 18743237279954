<template>
  <v-list
  >
  <!-- <v-fade-transition group> -->
    <template
    v-for="(letter) in alphabet"
    >
      <template
        v-if="clientsByLetter(letter).length"
      >
        <v-divider class="client-divider" :key="`div_${letter}`" inset></v-divider>

        <v-subheader class="client-subheader" :key="`sub_${letter}`" inset>
          {{ letter.toUpperCase() }}
        </v-subheader>
      <!-- <v-expand-transition group :key="`${letter}_cli_${i}`"> -->
        <v-list-item
          v-for="(client, i) in clientsByLetter(letter)"
          :key="`${letter}_cli_${i}`"
          @click="$emit('client-clicked', client)"
        >
          <v-avatar tile v-if="client.image">
            <img
              :src="`${client.image}/file`"
            >
          </v-avatar>
          <v-avatar tile v-else>
            <v-icon>mdi-account</v-icon>
          </v-avatar>
          <v-list-item-content>
            <v-list-item-title class="client-name">{{ client.nom }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action-text>
            {{ getClientMissionsNumber(client) }} mission(s)
          </v-list-item-action-text>
        </v-list-item>
      <!-- </v-expand-transition> -->

      </template>
    </template>
  <!-- </v-fade-transition> -->
  </v-list>
</template>
<script>
export default {
  props: ['clients'],

  data() {
    return {
      alphabet: Array.from('abcdefghijklmnopqrstuvwxyz123456789"!&_/-@%.?,*#():;'),
    };
  },

  computed: {
    sortedClients() {
      return [...this.clients].sort((a, b) => a.nom.localeCompare(b.nom, a - b, 'fr', { ignorePunctuation: true }));
    },
  },

  methods: {
    getClientMissionsNumber(client) {
      if (client.missions) {
        return client.missions.length;
      }
      return 0;
    },

    clientsByLetter(letter) {
      return this.sortedClients.filter(client => client.nom.toLowerCase().startsWith(letter));
    },
  },
};
</script>

<style lang="scss" scoped>
  .client-divider {
    margin-left: 0 !important;
    padding: 0 !important;
    max-width: 100% !important;
  }

  .client-subheader {
    margin-left: 0 !important;
    padding: 0 !important;
  }

  .v-avatar > img{
    margin-right: 15px;
  }
</style>
