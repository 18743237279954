<template>
  <div
    class="plan-view"
    v-if="patrimoine"
  >
    <h3>Plans d'actions du patrimoine {{ patrimoine.nom }}</h3>

    <div
      v-if="!loading && !planActions.length"
    >
      Il n'y a pas encore de plan d'actions pour ce site.
      Vous pouvez cliquer sur le bouton + en bas de page pour en créer un.
    </div>


    <v-expansion-panels
      popout
      multiple
      v-model="openedPanels"
      class="bloc-list"
    >
      <v-expansion-panel
        v-for="planAction in planActions"
        :key="planAction.id"
      >
        <v-expansion-panel-header>
          <template v-slot:default="{ open }">
            <div class="plan-header-wrapper">
              <span class="plan-header-prefix">{{ planAction.numero }}</span>
              <span class="plan-header-title">{{ planAction.nom }}</span>
              <div class="plan-header-button-group" v-if="open">
                <v-btn icon
                  class="plan-edit-button"
                  @click.stop="editPlanAction(planAction)"
                >
                  <v-icon>mdi-circle-edit-outline</v-icon>
                </v-btn>
                <v-btn icon
                  @click.stop="handleDeleteRequest(planAction)"
                  class="plan-delete-button"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </div>

          </template>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
        <PlanActionOverview
            :planAction="planAction"
            :key="`plan_content_${planAction.id}`"
          >
          </PlanActionOverview>
        </v-expansion-panel-content>

      </v-expansion-panel>
    </v-expansion-panels>

    <v-fab-transition>
      <v-btn
        v-if="!deleteDialog && !editModal"
        color="primary"
        fab
        large
        class="fab-add-button"
        @click="createPlanAction()"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <DialogDeleteConfirmation
      v-if="deleteDialog"
      :dialog="deleteDialog"
      :item="planToDelete"
      @delete-item="deletePlanAction()"
      @close-dialog="deleteDialog = false; progToDelete = null;"
    >
    </DialogDeleteConfirmation>

    <PlanActionEditModal
      :show="editModal"
      :planAction="planToEdit"
      :create="modalCreateMode"
      @close="closeEditModal()"
    >
    </PlanActionEditModal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import DialogDeleteConfirmation from '../components/DialogDeleteConfirmation.vue';
import PlanActionOverview from '../components/planActions/PlanActionOverview.vue';
import PlanActionEditModal from '../components/planActions/PlanActionEditModal.vue';

export default {
  props: ['patrimoineId'],

  components: {
    DialogDeleteConfirmation,
    PlanActionOverview,
    PlanActionEditModal,
  },

  data() {
    return {
      loading: false,
      deleteDialog: false,
      planToDelete: null,
      editModal: false,
      planToEdit: null,
      modalCreateMode: false,
      openedPanels: [],
    };
  },

  computed: {
    ...mapGetters({
      patrimoine: 'patrimoines/selected',
      planActions: 'planActions/selectedPatrimoinePlanActions',
    }),
    selectedView: {
      get() {
        return this.$store.state.selectedView;
      },
      set(value) {
        this.$store.commit('setSelectedView', value);
      },
    },

  },

  async created() {
    this.selectedView = 'Planifier';
    this.loading = true;
    await this.$store.dispatch('changeSelectedItem', { type: 'patrimoines', id: this.patrimoineId });
    await this.$store.dispatch('planActions/fetchAllByPatrimoine', this.patrimoineId);
    this.loading = false;
  },

  methods: {
    createPlanAction() {
      this.planToEdit = {};
      this.modalCreateMode = true;
      this.editModal = true;
    },

    deletePlanAction() {
      this.$store.dispatch('planActions/delete', this.planToDelete);
      this.planToDelete = null;
    },

    handleDeleteRequest(planAction) {
      this.planToDelete = planAction;
      this.deleteDialog = true;
    },

    editPlanAction(planAction) {
      this.planToEdit = planAction;
      this.editModal = true;
    },

    closeEditModal() {
      this.editModal = false;
      this.planToEdit = null;
      if (this.modalCreateMode) {
        this.modalCreateMode = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .plan-view {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;

    padding: 1rem;

    > * {
      margin-bottom: 2rem;
    }
  }

  .plan-header-wrapper {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    .plan-header-prefix {
      width: 2rem;
    }

    .plan-header-title {
      font-weight: 300;
      font-size: 1.3rem;
    }

    .plan-header-button-group {
      margin-left: auto;
      margin-right: 5px;
      min-width: 75px;
      .plan-edit-button {
        color: $app-blue !important;
      }
      .plan-delete-button {
        color: $custom-red;
      }
    }

  }

  .bloc-list {
    max-width: 1000px;
  }

  .fab-add-button {
    position: fixed;
    bottom: 2rem;
    right: 3rem;
    z-index: 500;
  }
</style>
