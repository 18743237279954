<template>
  <div class="date-field-wrapper">
    <div class="label-field">{{ formField.label }} : </div>
    <v-menu
        v-model="pickerOpen"
        offset-y
        max-width="290px"
        min-width="290px"
        transition="scale-transition"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <ValidationProvider
            :name="formField.name"
            :rules="formField.rules"
            v-slot="{ errors }">
            <v-text-field
              :error-messages="errors"
              :value="displayDate"
              v-bind="fieldOptions"
              prepend-icon="mdi-calendar"
              readonly
              slot="activator"
              v-on="on"
              clearable
              v-model="localValue"
              @change="handleDateSelected()"
            ></v-text-field>
          </ValidationProvider>
        </template>

      <v-date-picker
        :value="displayDate"
        v-model="localValue"
        locale="fr"
        @input="handleDateSelected()"
      ></v-date-picker>
    </v-menu>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'date-field',
  components: {
    ValidationProvider,
  },
  data() {
    return {
      pickerOpen: false,
      localValue: null,
      date: null,
    };
  },
  props: {
    formField: Object,
    defaultValue: null,
    commonOptions: Object,
  },

  created() {
    if (this.defaultValue) {
      this.localValue = this.toPickerFormat(this.defaultValue);
    }
  },

  methods: {
    // handle a new date selected on the date picker
    handleDateSelected() {
      // close the picker
      this.pickerOpen = false;
      // emit the new value
      if (this.localValue != null) {
        this.$emit('change', new Date(this.localValue));
      } else {
        this.$emit('change', this.localValue);
      }
    },

    // format a date in the following string format dd/mm/yyyy
    toDateString(input) {
      if (!input) {
        return null;
      }
      const date = new Date(input);
      return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    },

    // format a date in the following string format yyyy-mm-dd
    toPickerFormat(input) {
      if (!input) {
        return null;
      }
      return new Date(input).toISOString().substr(0, 10);
    },
  },

  computed: {
    fieldOptions() {
      return {
        ...this.commonOptions,
        placeholder: this.formField.placeholder,
      };
    },
    displayDate() {
      if (!this.localValue) return null;
      const [year, month, day] = this.localValue.split('-');
      return `${day}/${month}/${year}`;
    },
  },

  watch: {
    defaultValue(newValue) {
      this.localValue = this.toPickerFormat(newValue);
    },

    localValue(newValue) {
      if (newValue != null) {
        this.localValue = this.toPickerFormat(newValue);
      } else {
        this.localValue = null;
      }
    },
  },
};
</script>
