import api from '@/api';

/* eslint no-underscore-dangle: ["error", { "allow": ["_id"] }] */
/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */
/* eslint no-console: ["error", { allow: ["error", "log"] }] */

// initial state
const state = {
  all: [],
};

// getters
const getters = {
  selectedMissionPostes: (state, getters, rootState, rootGetters) => {
    const mission = rootGetters['missions/selected'];
    if (mission) {
      return state.all.filter(opt => opt.codeType === 'poste' && opt.mission === mission.id);
    }
    return [];
  },


  selectedMissionEnergies: (state, getters, rootState, rootGetters) => {
    const mission = rootGetters['missions/selected'];
    if (mission) {
      return state.all.filter(opt => opt.codeType === 'energie' && opt.mission === mission.id);
    }
    return [];
  },

  selectedMissionMotivations: (state, getters, rootState, rootGetters) => {
    const mission = rootGetters['missions/selected'];
    if (mission) {
      return state.all.filter(opt => opt.codeType === 'motivation' && opt.mission === mission.id);
    }
    return [];
  },

  selectedMissionTypeUsage: (state, getters, rootState, rootGetters) => {
    const mission = rootGetters['missions/selected'];
    if (mission) {
      return state.all.filter(opt => opt.codeType === 'ref_typeusage' && opt.mission === mission.id);
    }
    return [];
  },
};

// actions
const actions = {

  /**
   * Fetch all listeOptions from the server for one mission and update the store
   * @param {String} missionId - id of the parent mission
   */
  fetchAllByMission({ commit }, missionId) {
    return api.listeOptions.getAllByMission(missionId)
      .then(response => commit('addListeOptions', response));
  },

  /**
   * Fetch all listeOptions of a certain code type for one mission,
   * from the server and update de store
   * @param {String} missionId - id of the parent mission
   * @param {String} codeType - codeType to filter
   */
  fetchAllByType(
    { commit, rootGetters },
    {
      missionId = rootGetters['missions/selected'].id,
      codeType = '',
    },
  ) {
    return api.listeOptions.getAllByType(missionId, codeType)
      .then((response) => {
        commit('addListeOptions', response);
        return response;
      });
  },

  /**
   * Fetch one option for certain code option and a given mission,
   * from the server and update de store
   * @param {String} missionId - id of the parent mission
   * @param {String} codeOption - codeOption to search  for
   */
  fetchByCodeOption({ commit }, { missionId, codeOption }) {
    return api.listeOptions.getByCodeOption(missionId, codeOption)
      .then((response) => {
        commit('addListeOptions', [response]);
        return response;
      });
  },

  /**
   * Load all listeOptions stored offline in pouchDB
   */
  loadOffline({ commit }) {
    return api.listeOptions.loadOffline()
      .then(response => commit('addListeOptions', response));
  },
};

// mutations
const mutations = {
  addListeOptions(state, newListeOptions) {
    newListeOptions.forEach((newOption) => {
      if (newOption) {
        const stateIndex = state.all
          .findIndex(stateOption => stateOption.id === newOption.id);
        if (stateIndex >= 0) {
          state.all.splice(stateIndex, 1, newOption);
        } else {
          state.all.push(newOption);
        }
      }
    });
  },

  clearListeOptions(state) {
    state.all = [];
  },

  setListeOptions(state, items) {
    state.all = items;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
