import api from '@/api';

/* eslint no-console: ["error", { allow: ["error", "log"] }] */
/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = {
  patrimoines: [],

  selectedId: null,
};

const getters = {
  selected: state => state.patrimoines.find(patrimoine => patrimoine.id === state.selectedId),
};

const actions = {

  /**
   * Create a new Patrimoine and add it to the state
   */
  createNew({ commit }) {
    const newPatrimoine = {
      id: `tempPat${Math.random()}`,
      nom: 'Nouveau Patrimoine',
      numero: '',
      description: '',
    };

    // Add new patrimoine and change featured item in Store
    commit('addPatrimoines', [newPatrimoine]);
    return newPatrimoine;
  },

  save({ commit }, payload) {
    return new Promise((resolve, reject) => {
      // if the patrimoine doesn't exhist on the backend, create it by post
      if (payload.id.startsWith('tempPat')) {
        api.patrimoines.create(payload.data)
          .then((response) => {
            commit('deletePatrimoine', payload.id);
            commit('addPatrimoines', [response]);
            resolve({ ...response });
          })
          .catch(error => reject(error));
      // else update it by put
      } else {
        api.patrimoines.update(payload.id, payload.data)
          .then((response) => {
            commit('addPatrimoines', [response]);
            resolve({ ...response });
          })
          .catch(error => reject(error));
      }
    });
  },

  /**
   * Fetch all patrimoines from the backend and update the store
   */
  fetchAll({ commit }) {
    return api.patrimoines.getAll().then(response => commit('addPatrimoines', response));
  },

  /**
 * Fech all patrimoine of a parent client
 * @param {String} clientId - id of the parent client
 */
  fetchAllByClient({ commit }, clientId) {
    return api.patrimoines.getAllByClient(clientId)
      .then(response => commit('addPatrimoines', response));
  },

  delete({ commit }, patrimoine) {
    return api.patrimoines.delete(patrimoine.id)
      .then(() => commit('deletePatrimoine', patrimoine.id));
  },

  refreshItem({ commit }, patrimoineId) {
    return api.patrimoines.get(patrimoineId).then((response) => {
      commit('addPatrimoines', [response]);
      return { ...response };
    });
  },

  /**
   * Load all patrimoines stored offline in pouchDB
   */
  loadOffline({ commit }) {
    return api.patrimoines.loadOffline().then(response => commit('addPatrimoines', response));
  },

  async changeSelected({
    commit, getters, dispatch, state,
  },
  patrimoineId) {
    if (state.selectedId !== patrimoineId) {
      commit('changeSelectedId', patrimoineId);

      if (!getters.selected) {
        await dispatch('refreshItem', patrimoineId);
      }
    }
  },

  async clearSelected({ commit }) {
    await commit('changeSelectedId', null);
  },
};

const mutations = {
  addPatrimoines(state, newPatrimoines) {
    newPatrimoines.forEach((newPatrimoine) => {
      const stateIndex = state.patrimoines.findIndex(statePatrimoine => statePatrimoine.id
        === newPatrimoine.id);
      if (stateIndex >= 0) {
        state.patrimoines.splice(stateIndex, 1, newPatrimoine);
      } else {
        state.patrimoines.push(newPatrimoine);
      }
    });
  },

  deletePatrimoine(state, patrimoineId) {
    state.patrimoines.splice(state.patrimoines.findIndex(patrimoine => patrimoine.id
      === patrimoineId), 1);
  },

  changeSelectedId(state, patrimoineId) {
    state.selectedId = patrimoineId;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
