<template>
  <ValidationProvider
    :name="formField.name"
    :rules="formField.rules"
    v-slot="{ errors }">

    <v-text-field
      v-bind="fieldOptions"
      :error-messages="errors"
      v-model.number="localValue"
      @change="$emit('change', localValue)"
    >

      <template v-slot:label>
        <slot name="field-label"></slot>
      </template>
    </v-text-field>

  </ValidationProvider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

export default {
  name: 'number-field',
  components: {
    ValidationProvider,
  },
  props: {
    commonOptions: Object,
    defaultValue: null,
    formField: Object,
  },
  data() {
    return {
      localValue: this.defaultValue,
    };
  },
  computed: {
    fieldOptions() {
      return {
        ...this.commonOptions,
        placeholder: this.formField.placeholder,
        type: 'number',
        suffix: this.formField.unit,
      };
    },
  },
  watch: {
    defaultValue(newValue) {
      this.localValue = newValue;
    },
  },
};
</script>
