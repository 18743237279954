import api from '@/api';

/* eslint no-console: ["error", { allow: ["error", "log"] }] */
/* eslint no-shadow: ["error", { "allow": ["state", "getters"] }] */

const state = {
  all: [],
};

const getters = { };

const actions = {

  /**
   * add a Site to the list of offline Sites
   */
  add({ commit }, siteId) {
    return api.offlineSiteList.add(siteId).then((response) => {
      commit('addOfflineSite', [response]);
      return { ...response };
    });
  },

  /**
   * remove a Site from the list of offline Sites
   */
  remove({ commit }, siteId) {
    return api.offlineSiteList.remove(siteId)
      .then(() => commit('removeOfflineSite', siteId));
  },

  /**
   * Load the list of siteId stored offline
   */
  loadOffline({ commit }) {
    return api.offlineSiteList.getAll().then(response => commit('addOfflineSite', response));
  },
};

const mutations = {
  addOfflineSite(state, newSites) {
    newSites.forEach((newSite) => {
      const stateIndex = state.all
        .findIndex(stateSite => stateSite.id === newSite.id);

      if (stateIndex >= 0) {
        state.all.splice(stateIndex, 1, newSite);
      } else {
        state.all.push(newSite);
      }
    });
  },

  removeOfflineSite(state, siteId) {
    state.all.splice(state.all.findIndex(Site => Site.id === siteId), 1);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
