/* eslint-disable no-console */

import PouchDB from 'pouchdb-browser';
import axiosInstance from '../plugins/axios';
import pouchUtil from './pouchUtil';
import {
  inspectionTypesEndpoint,
} from './apiUrls';

export default {
  db: new PouchDB('inspectionTypesForms'),

  convertBackendObject(obj, inspectionType) {
    // set an _id attribute for pouch
    const _id = obj._id || `${inspectionType}`; /* eslint-disable-line no-underscore-dangle */
    const meta = {};

    return {
      _id,
      meta,
      id: _id,
      formTemplate: obj.formTemplate,
    };
  },

  /**
   * get a inspection form from it's type and mission id
   * @param {String} type - the equipementType to search for
   */
  async getByType(type) {
    // const shortType = type.slice(type.lastIndexOf('/') + 1);
    try {
      const response = await axiosInstance.axiosInstance.get(`${inspectionTypesEndpoint}/${type}/form`);
      const form = this.convertBackendObject(response.data, type);

      // if get is successful,  store data into pouchdb and return it
      return pouchUtil.addDoc(form, this.db);
    } catch (e) {
      console.error('inspectionTypeForms getByType error : ', e);
      // if get from server fail
      // check if we have data in pouchdb and return it
      return pouchUtil.getDoc(`${type}`, this.db);
    }
  },

  /**
   * get all inspectionTypeForms for a mission for offline use
   * @param {String} missionId - id of the parent mission
   */
  async fetchOffline(missionId) {
    const response = await axiosInstance.axiosInstance.get(`${inspectionTypesEndpoint}/form?mission=${missionId}`);
    const inspectionForms = response.data
      .map(form => this.convertBackendObject(form, form.id));
    // if get is successful,  store data into pouchdb and return it
    return pouchUtil.addDocs(inspectionForms, this.db);
  },

  /**
   * Fetch all equipementDomaines stored on pouchDB
   */
  async loadOffline() {
    return pouchUtil.getAllDocs(this.db);
  },
};
