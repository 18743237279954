<template>
  <v-btn
    text
    icon
    color="white"
    @click="gotToSyncView()"
  >
    <v-icon>mdi-cloud-refresh</v-icon>
    {{ nbItemsToSync }}
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  computed: {
    ...mapGetters(['nbPicturesToSync']),
    ...mapGetters('batiments', { nbBatimentsToSync: 'nbToSync' }),
    ...mapGetters('niveaux', { nbNiveauxToSync: 'nbToSync' }),
    ...mapGetters('equipements', { nbEquipementsToSync: 'nbToSync' }),
    ...mapGetters('inspections', { nbInspectionsToSync: 'nbToSync' }),
    // number of item to sync whit the server
    nbItemsToSync() {
      return this.nbBatimentsToSync
      + this.nbNiveauxToSync
      + this.nbEquipementsToSync
      + this.nbPicturesToSync
      + this.nbInspectionsToSync;
    },
  },

  methods: {

    gotToSyncView() {
      this.$router.push({
        name: 'synchronize',
      });
    },
  },
};
</script>
